import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  CreateOrganizationAddressMutation,
  useCreateOrganizationAddressMutation,
  CreateOrganizationAddressMutationVariables,
  GetOrganizationAddressesDocument,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { useCallback, useMemo } from "react";

export default function useCreateOrganizationAddress() {
  const { offset } = usePaginatedVariables();
  const [mutation, values] = useCreateOrganizationAddressMutation({
    refetchQueries: [
      {
        query: GetOrganizationAddressesDocument,
        variables: {
          page: offset.page,
          first: offset.first,
        },
      },
    ],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = (data: CreateOrganizationAddressMutation) => {
    return !!data?.createOrganizationAddress;
  };

  const getData = ({ data }: { data?: CreateOrganizationAddressMutation }) => {
    return data?.createOrganizationAddress ?? null;
  };

  const createOrganizationAddress = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          CreateOrganizationAddressMutation,
          CreateOrganizationAddressMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.create-address-success"),
        failureMessage: getLocalString("organization.create-address-failure"),
      }
    ),
    [mutation]
  );

  const success = useMemo(() => {
    return isSuccess(values.data);
  }, [values.data]);

  const data = useMemo(() => {
    return getData({ data: values.data });
  }, [values.data]);

  return [
    createOrganizationAddress,
    { data, error: values.error, loading: values.loading, success, isSuccess, getData },
  ] as const;
}
