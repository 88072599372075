const labels = {
  labels: {
    address1: "Address",
    address2: "Apartment, suite, etc. (optional)",
    city: "City",
    company: "Company (optional)",
    country: "Country/region",
    first_name: "First name (optional)",
    last_name: "Last name",
    phone: "Phone",
    province: "Region",
    zip: "Postal code",
  },
  address_format: {
    edit: {
      "0": "{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{country}{zip}_{phone}",
      "1": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{country}_{phone}",
      "2": "{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "3": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{province}{country}_{phone}",
      "4": "{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{country}{province}{zip}_{phone}",
      "5": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{province}_{country}_{phone}",
      "6": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{country}_{phone}",
      "7": "{firstName}{lastName}_{company}_{address1}_{address2}_{country}{zip}_{phone}",
      "8": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{country}_{phone}",
      "9": "{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{country}{province}_{phone}",
      "10": "{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{province}{zip}_{country}_{phone}",
      "11": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{country}_{phone}",
      "12": "{lastName}{firstName}_{company}_{country}{zip}_{province}_{city}_{address1}_{address2}_{phone}",
      "13": "{zip}{city}_{address2}_{address1}_{company}_{firstName}{lastName}_{country}_{phone}",
      "14": "{firstName}{lastName}_{company}_{address1}_{address2}_{city}{country}_{phone}",
      "15": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "16": "{firstName}{lastName}_{company}_{address1}_{address2}_{zip}_{country}_{phone}",
      "17": "{company}_{lastName}{firstName}_{zip}_{country}_{province}{city}_{address1}_{address2}_{phone}",
      "18": "{zip}{city}_{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      "19": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{zip}_{phone}",
      "20": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{phone}",
      "21": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{phone}",
      "22": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}{province}_{phone}",
      "23": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}{zip}_{phone}",
      "24": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}{city}_{province}_{phone}",
      "25": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}_{phone}",
      "26": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}{province}_{phone}",
      "27": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{province}{zip}_{phone}",
      "28": "{country}_{lastName}{firstName}_{company}_{zip}{province}_{city}_{address1}_{address2}_{phone}",
      "29": "{country}_{zip}{city}_{address2}_{address1}_{company}_{firstName}{lastName}_{phone}",
      "30": "{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{zip}_{city}_{phone}",
      "31": "{country}_{company}_{lastName}{firstName}_{zip}_{province}{city}_{address1}_{address2}_{phone}",
      "32": "{country}_{zip}{city}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
    },
  },
};
const countries = {
  Afghanistan: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "AF",
    flag_path:
      "/assets/flags/af-eb8e279df9906ed3fd391df59a7a475f754748edaa733071b57c0a641b8d24fd.svg",
    provinces: null,
  },
  "Aland Islands": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "AX",
    flag_path:
      "/assets/flags/ax-d46239a9579497bc01912679a9ec11a38899af9741fbb50f520a1bb0075a4055.svg",
    provinces: null,
  },
  Albania: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "AL",
    flag_path:
      "/assets/flags/al-77334b551675f1722f013e89984e0650736057dfa3969e70fd268ff9d61886bb.svg",
    provinces: null,
  },
  Algeria: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "DZ",
    flag_path:
      "/assets/flags/dz-9812959bac8cecc27f421e0651c970978d33b90f2b9e3ec6ba189331708e906f.svg",
    provinces: null,
  },
  Andorra: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "AD",
    flag_path:
      "/assets/flags/ad-2d3299fc054096c47ab0c45f9d10614bb0d939ccc4d1c688078c7b1bfbec2a01.svg",
    provinces: null,
  },
  Angola: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "AO",
    flag_path:
      "/assets/flags/ao-02cfc2219fd71679d14ef2c7afc3e6122ae0d7685e28281322aa8ea66d1dc4a8.svg",
    provinces: null,
  },
  Anguilla: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "AI",
    flag_path:
      "/assets/flags/ai-7b0902b0d75c927173f2768307ed089e9b5a991c62d841fb51063f8aa1ed65a6.svg",
    provinces: null,
  },
  "Antigua And Barbuda": {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "AG",
    flag_path:
      "/assets/flags/ag-b912d2a04490401a66441bdc4a702fdd0de83098dbc81e82dd7ba249d801d46a.svg",
    provinces: null,
  },
  Argentina: {
    address_format: { edit: 3, edit_experiment: 22 },
    code: "AR",
    flag_path:
      "/assets/flags/ar-dc0a5ed2f626c59955a9ac31509fb91cced47ccc437dfae53d60a38005249afc.svg",
    labels: { province: "Province" },
    provinces: {
      "Ciudad Autónoma de Buenos Aires": {
        label: "Buenos Aires (Autonomous City)",
        alternate_names: ["Ciudad Autonoma de Buenos Aires"],
        code: "C",
      },
      "Buenos Aires": { label: "Buenos Aires Province", code: "B" },
      Catamarca: { code: "K" },
      Chaco: { code: "H" },
      Chubut: { code: "U" },
      Córdoba: { alternate_names: ["Cordoba"], code: "X" },
      Corrientes: { code: "W" },
      "Entre Ríos": { alternate_names: ["Entre Rios"], code: "E" },
      Formosa: { code: "P" },
      Jujuy: { code: "Y" },
      "La Pampa": { code: "L" },
      "La Rioja": { code: "F" },
      Mendoza: { code: "M" },
      Misiones: { code: "N" },
      Neuquén: { alternate_names: ["Neuquen"], code: "Q" },
      "Río Negro": { alternate_names: ["Rio Negro"], code: "R" },
      Salta: { code: "A" },
      "San Juan": { code: "J" },
      "San Luis": { code: "D" },
      "Santa Cruz": { code: "Z" },
      "Santa Fe": { code: "S" },
      "Santiago Del Estero": { label: "Santiago del Estero", code: "G" },
      "Tierra Del Fuego": { label: "Tierra del Fuego", code: "V" },
      Tucumán: { alternate_names: ["Tucuman"], code: "T" },
    },
  },
  Armenia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "AM",
    flag_path:
      "/assets/flags/am-a9a3a54409e1d2bef4ea0c53a2b66dc7432254fd1580326d0e5a9fb7b18f5dac.svg",
    provinces: null,
  },
  Aruba: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "AW",
    flag_path:
      "/assets/flags/aw-3728e43855e00765ef2e40f197946649fde06f07a0fb33a124dbbdd94b793e25.svg",
    provinces: null,
  },
  "Ascension Island": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "AC",
    flag_path:
      "/assets/flags/ac-a56a872340f515db36b0ae3d9a9d16c9b39bea101e09a67ef6cba9169d8dce7f.svg",
    provinces: null,
  },
  Australia: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "AU",
    flag_path:
      "/assets/flags/au-13873c845dd7bb5655a50d96e393b1e5c08af1bd431f14a91d1d616cc46f192c.svg",
    labels: { city: "Suburb", province: "State/territory", zip: "Postcode" },
    provinces: {
      "Australian Capital Territory": { code: "ACT" },
      "New South Wales": { code: "NSW" },
      "Northern Territory": { code: "NT" },
      Queensland: { code: "QLD" },
      "South Australia": { code: "SA" },
      Tasmania: { code: "TAS" },
      Victoria: { code: "VIC" },
      "Western Australia": { code: "WA" },
    },
  },
  Austria: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "AT",
    flag_path:
      "/assets/flags/at-dba77f84783603578980c0ae9801d617e27ff9acca6c45be81264a6b1d7df8b6.svg",
    labels: {
      address1: "Street and house number",
      address2: "Additional address (optional)",
    },
    provinces: null,
  },
  Azerbaijan: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "AZ",
    flag_path:
      "/assets/flags/az-1085ba921a81e661397f00206ace09ae5719643cabee59ffa0674cfee62f2691.svg",
    provinces: null,
  },
  Bahamas: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "BS",
    flag_path:
      "/assets/flags/bs-bcc952e638d0e3de6f5e595b461b9629e6291330e73a81e857bb8645978dbb81.svg",
    provinces: null,
  },
  Bahrain: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BH",
    flag_path:
      "/assets/flags/bh-0ca8149dfcdd96887846d04bfecd9f27e700ad5f7118a5385db4ba2cc3f16893.svg",
    provinces: null,
  },
  Bangladesh: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BD",
    flag_path:
      "/assets/flags/bd-e191b65efc29531da2809f91b04414a5487be88ebaaff3615b7a32da8c3d6c4e.svg",
    provinces: null,
  },
  Barbados: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BB",
    flag_path:
      "/assets/flags/bb-ddc91bc3a20cfc3ea3a5618caf4bfc400aa6b25e131fb191f3c65e2cccd0def4.svg",
    provinces: null,
  },
  Belarus: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "BY",
    flag_path:
      "/assets/flags/by-bdf871401d4ab7ef2781e80954d67d108f7756b0d89ce2acfc216d4ae2c20d6b.svg",
    provinces: null,
  },
  Belgium: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "BE",
    flag_path:
      "/assets/flags/be-806dd1aa913ace4035ededeac4c1708e323909c7bf213b6b3e4245109aa8ba02.svg",
    provinces: null,
  },
  Belize: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "BZ",
    flag_path:
      "/assets/flags/bz-00a3d96a2ac76b7edaedcbc5c654b620e332c16774aac64fb00e290135dadec3.svg",
    provinces: null,
  },
  Benin: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "BJ",
    flag_path:
      "/assets/flags/bj-6a2811523006a49b45b675777d43dba45e925136493ee3a03391a56a97fe9b8e.svg",
  },
  Bermuda: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BM",
    flag_path:
      "/assets/flags/bm-8de9be055ea3598b4125e7cc07f6582582649174f35f2a5c43190bd1038f7dea.svg",
  },
  Bhutan: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BT",
    flag_path:
      "/assets/flags/bt-2268a30a79e240aff419159cdd73e7b2098b36bcffbfd69ee03a17efaa8221be.svg",
  },
  Bolivia: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "BO",
    flag_path:
      "/assets/flags/bo-cfd48d23d6bfdb99c5d89fc035ebceae2e331b4802fbe531a38d7c6bf12772b9.svg",
  },
  "Bosnia And Herzegovina": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "BA",
    flag_path:
      "/assets/flags/ba-adf57e4f70d15236df96717a20c40aa56d8da5dc897c118fad2b61664642d5c0.svg",
  },
  Botswana: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BW",
    flag_path:
      "/assets/flags/bw-be023689fbd86bd7d217a975913a57418ea617c6d0c525078f556d3276f5b2a7.svg",
  },
  "Bouvet Island": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BV",
    flag_path:
      "/assets/flags/bv-d605fb1b71590285722a2f8ceba677f258d528cd3cff5000ba64a9473c62bfa5.svg",
  },
  Brazil: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "BR",
    flag_path:
      "/assets/flags/br-c0141f79405d80a7da70de1b45f6f9ce24e52f65c6c7b4dfa2e4b4728aa02660.svg",
    labels: { address1: "Street and house number", province: "State" },
    provinces: {
      Acre: { code: "AC" },
      Alagoas: { code: "AL" },
      Amapá: { alternate_names: ["Amapa"], code: "AP" },
      Amazonas: { code: "AM" },
      Bahia: { code: "BA" },
      Ceará: { alternate_names: ["Ceara"], code: "CE" },
      "Espírito Santo": { alternate_names: ["Espirito Santo"], code: "ES" },
      "Distrito Federal": { label: "Federal District", code: "DF" },
      Goiás: { alternate_names: ["Goias"], code: "GO" },
      Maranhão: { alternate_names: ["Maranhao"], code: "MA" },
      "Mato Grosso": { code: "MT" },
      "Mato Grosso do Sul": { code: "MS" },
      "Minas Gerais": { code: "MG" },
      Pará: { alternate_names: ["Para"], code: "PA" },
      Paraíba: { alternate_names: ["Paraiba"], code: "PB" },
      Paraná: { alternate_names: ["Parana"], code: "PR" },
      Pernambuco: { code: "PE" },
      Piauí: { alternate_names: ["Piaui"], code: "PI" },
      "Rio Grande do Norte": { code: "RN" },
      "Rio Grande do Sul": { code: "RS" },
      "Rio de Janeiro": { code: "RJ" },
      Rondônia: { alternate_names: ["Rondonia"], code: "RO" },
      Roraima: { code: "RR" },
      "Santa Catarina": { code: "SC" },
      "São Paulo": { alternate_names: ["Sao Paulo"], code: "SP" },
      Sergipe: { code: "SE" },
      Tocantins: { code: "TO" },
    },
  },
  "British Indian Ocean Territory": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "IO",
    flag_path:
      "/assets/flags/io-67bf3cf21d1e1e4c1c5245deb86cfb21d3791bd63f6a33a2adfd8d7f6c9d90a0.svg",
  },
  Brunei: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BN",
    flag_path:
      "/assets/flags/bn-554e32337da87f4f9bfded90be19893b36cc5d55361847836eb5d7d7db0ec468.svg",
  },
  Bulgaria: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "BG",
    flag_path:
      "/assets/flags/bg-bbbd0544dea156ec45b7713db5e66f0efc768482f5a61ddf0cdc9a928f194661.svg",
  },
  "Burkina Faso": {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "BF",
    flag_path:
      "/assets/flags/bf-66c389db1137ee09851de2e89d0b103f7917b5f055da530b23460eee7dac0e47.svg",
  },
  Burundi: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BI",
    flag_path:
      "/assets/flags/bi-78effa77b600a9a6f9b24bbffbc36b873250ad5af579b69925a81ca20840ac4b.svg",
  },
  Cambodia: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KH",
    flag_path:
      "/assets/flags/kh-35c33da07e85eddd75f6fb715d54e8524be755b7b3dfa9fc20176c0b291f8022.svg",
  },
  Canada: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "CA",
    flag_path:
      "/assets/flags/ca-0d78c57d795e496c1419de30c2af44b0d9b3cb96299cf879dcbf08cf9bbf41a4.svg",
    labels: { province: "Province" },
    provinces: {
      Alberta: { code: "AB" },
      "British Columbia": { code: "BC" },
      Manitoba: { code: "MB" },
      "New Brunswick": { code: "NB" },
      "Newfoundland and Labrador": {
        alternate_names: ["Newfoundland"],
        code: "NL",
      },
      "Northwest Territories": { code: "NT" },
      "Nova Scotia": { code: "NS" },
      Nunavut: { code: "NU" },
      Ontario: { code: "ON" },
      "Prince Edward Island": { code: "PE" },
      Quebec: { alternate_names: ["Québec"], code: "QC" },
      Saskatchewan: { code: "SK" },
      Yukon: { code: "YT" },
    },
  },
  "Cape Verde": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "CV",
    flag_path:
      "/assets/flags/cv-d0389eb5253d58f3b9135acf4690bd8112fcb64d943e1d96d37d49ad79d85329.svg",
  },
  "Caribbean Netherlands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "BQ",
    flag_path:
      "/assets/flags/bq-a31b3f056508b2cc92348384b059e973ed934eb471064d7e98e95856a05c7f75.svg",
  },
  "Cayman Islands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KY",
    flag_path:
      "/assets/flags/ky-ed26b486d95eccb95f93b4ef6e052ce1a84c14a08c270909440f444def3a13d2.svg",
  },
  "Central African Republic": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CF",
    flag_path:
      "/assets/flags/cf-31abdd130dde03f612d658ff986e274b7aa7cea1cd24f287b24b363783ef3b10.svg",
  },
  Chad: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "TD",
    flag_path:
      "/assets/flags/td-cf60b0660fef033f1e1c42303e90cd49c31595c755a9885499a0610b55cf32db.svg",
  },
  Chile: {
    address_format: { edit: 5, edit_experiment: 24 },
    code: "CL",
    flag_path:
      "/assets/flags/cl-dce51159690a7a5026eec5515d46b167d5d025913a66adf0171ed3981c5bd1c1.svg",
    provinces: {
      "Arica and Parinacota": {
        label: "Arica y Parinacota",
        alternate_names: [
          "Arica y Parinacota",
          "Región de Arica y Parinacota",
          "Region de Arica y Parinacota",
        ],
        code: "AP",
      },
      Tarapacá: {
        alternate_names: ["Tarapaca", "Región de Tarapacá", "Region de Tarapaca"],
        code: "TA",
      },
      Antofagasta: {
        alternate_names: ["Región de Antofagasta", "Region de Antofagasta"],
        code: "AN",
      },
      Atacama: {
        alternate_names: ["Región de Atacama", "Region de Atacama"],
        code: "AT",
      },
      Coquimbo: {
        alternate_names: ["Región de Coquimbo", "Region de Coquimbo"],
        code: "CO",
      },
      Valparaíso: {
        alternate_names: ["Valparaiso", "Región de Valparaíso", "Region de Valparaiso"],
        code: "VS",
      },
      Santiago: {
        label: "Santiago Metropolitan",
        alternate_names: [
          "Región Metropolitana",
          "Region Metropolitana",
          "Región Metropolitana de Santiago",
          "Region Metropolitana de Santiago",
        ],
        code: "RM",
      },
      "O'Higgins": {
        label: "Libertador General Bernardo O’Higgins",
        alternate_names: [
          "Región del Libertador General Bernardo O'Higgins",
          "Region del Libertador General Bernardo O'Higgins",
        ],
        code: "LI",
      },
      Maule: {
        alternate_names: ["Región del Maule", "Region del Maule"],
        code: "ML",
      },
      Ñuble: {
        alternate_names: ["Nuble", "Región de Ñuble", "Region de Nuble"],
        code: "NB",
      },
      Biobío: {
        label: "Bío Bío",
        alternate_names: ["Biobio", "Región del Biobío", "Region del Biobio"],
        code: "BI",
      },
      Araucanía: {
        alternate_names: ["Araucania", "Región de La Araucanía", "Region de La Araucania"],
        code: "AR",
      },
      "Los Ríos": {
        alternate_names: ["Los Rios", "Región de Los Ríos", "Region de Los Rios"],
        code: "LR",
      },
      "Los Lagos": {
        alternate_names: ["Región de Los Lagos", "Region de Los Lagos"],
        code: "LL",
      },
      Aysén: {
        alternate_names: [
          "Aysen",
          "Región Aysén del General Carlos Ibáñez del Campo",
          "Region Aysen del General Carlos Ibanez del Campo",
        ],
        code: "AI",
      },
      Magallanes: {
        label: "Magallanes Region",
        alternate_names: [
          "Región de Magallanes y de la Antártica Chilena",
          "Region de Magallanes y de la Antartica Chilena",
        ],
        code: "MA",
      },
    },
  },
  China: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "CN",
    flag_path:
      "/assets/flags/cn-2153644e96e86a433f6157a6a75327d8f5b74c72e3b9c744bb9c53b125c15143.svg",
    labels: { address1: "Full address", province: "Province" },
    provinces: {
      Anhui: { alternate_names: ["安徽"], code: "AH" },
      Beijing: { alternate_names: ["北京"], code: "BJ" },
      Chongqing: { alternate_names: ["重庆"], code: "CQ" },
      Fujian: { alternate_names: ["福建"], code: "FJ" },
      Gansu: { alternate_names: ["甘肃"], code: "GS" },
      Guangdong: { alternate_names: ["广东"], code: "GD" },
      Guangxi: { alternate_names: ["广西"], code: "GX" },
      Guizhou: { alternate_names: ["贵州"], code: "GZ" },
      Hainan: { alternate_names: ["海南"], code: "HI" },
      Hebei: { alternate_names: ["河北"], code: "HE" },
      Heilongjiang: { alternate_names: ["黑龙江"], code: "HL" },
      Henan: { alternate_names: ["河南"], code: "HA" },
      Hubei: { alternate_names: ["湖北"], code: "HB" },
      Hunan: { alternate_names: ["湖南"], code: "HN" },
      "Inner Mongolia": { alternate_names: ["内蒙古", "Nei Mongol"], code: "NM" },
      Jiangsu: { alternate_names: ["江苏"], code: "JS" },
      Jiangxi: { alternate_names: ["江西"], code: "JX" },
      Jilin: { alternate_names: ["吉林"], code: "JL" },
      Liaoning: { alternate_names: ["辽宁"], code: "LN" },
      Ningxia: { alternate_names: ["宁夏"], code: "NX" },
      Qinghai: { alternate_names: ["青海"], code: "QH" },
      Shaanxi: { alternate_names: ["陕西"], code: "SN" },
      Shandong: { alternate_names: ["山东"], code: "SD" },
      Shanghai: { alternate_names: ["上海"], code: "SH" },
      Shanxi: { alternate_names: ["山西"], code: "SX" },
      Sichuan: { alternate_names: ["四川"], code: "SC" },
      Tianjin: { alternate_names: ["天津"], code: "TJ" },
      Xizang: { label: "Tibet", alternate_names: ["西藏", "Tibet"], code: "YZ" },
      Xinjiang: { alternate_names: ["新疆"], code: "XJ" },
      Yunnan: { alternate_names: ["云南"], code: "YN" },
      Zhejiang: { alternate_names: ["浙江"], code: "ZJ" },
    },
  },
  "Christmas Island": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CX",
    flag_path:
      "/assets/flags/cx-b88b54564b2548cf3372a7d5765c326807c5f12bff8188847f1e9cf0eff9f4ce.svg",
  },
  "Cocos (Keeling) Islands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CC",
    flag_path:
      "/assets/flags/cc-5a2493732775d48804c70cd39578c71af089cc4c4ce84c995a9d0a0f52ad0ba2.svg",
  },
  Colombia: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "CO",
    flag_path:
      "/assets/flags/co-a37a6bc1fe59000adbe09ea8ac216e2b66ba728742e119349bab34d1fd1b3c11.svg",
    labels: { province: "Province" },
    provinces: {
      "Bogotá, D.C.": {
        label: "Capital District",
        alternate_names: [
          "Bogota, D.C.",
          "Bogotá",
          "Bogota",
          "Capital District",
          "Distrito Capital de Bogotá",
          "Distrito Capital de Bogota",
        ],
        code: "DC",
      },
      Amazonas: { code: "AMA" },
      Antioquia: { code: "ANT" },
      Arauca: { code: "ARA" },
      Atlántico: { alternate_names: ["Atlantico"], code: "ATL" },
      Bolívar: { alternate_names: ["Bolivar"], code: "BOL" },
      Boyacá: { alternate_names: ["Boyaca"], code: "BOY" },
      Caldas: { code: "CAL" },
      Caquetá: { alternate_names: ["Caqueta"], code: "CAQ" },
      Casanare: { code: "CAS" },
      Cauca: { code: "CAU" },
      Cesar: { code: "CES" },
      Chocó: { alternate_names: ["Choco"], code: "CHO" },
      Córdoba: { alternate_names: ["Cordoba"], code: "COR" },
      Cundinamarca: { code: "CUN" },
      Guainía: { alternate_names: ["Guainia"], code: "GUA" },
      Guaviare: { code: "GUV" },
      Huila: { code: "HUI" },
      "La Guajira": { code: "LAG" },
      Magdalena: { code: "MAG" },
      Meta: { code: "MET" },
      Nariño: { alternate_names: ["Narino"], code: "NAR" },
      "Norte de Santander": { code: "NSA" },
      Putumayo: { code: "PUT" },
      Quindío: { alternate_names: ["Quindio"], code: "QUI" },
      Risaralda: { code: "RIS" },
      "San Andrés, Providencia y Santa Catalina": {
        label: "San Andrés \u0026 Providencia",
        alternate_names: [
          "San Andres, Providencia y Santa Catalina",
          "San Andrés y Providencia",
          "San Andres y Providencia",
        ],
        code: "SAP",
      },
      Santander: { code: "SAN" },
      Sucre: { code: "SUC" },
      Tolima: { code: "TOL" },
      "Valle del Cauca": { code: "VAC" },
      Vaupés: { alternate_names: ["Vaupes"], code: "VAU" },
      Vichada: { code: "VID" },
    },
  },
  Comoros: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KM",
    flag_path:
      "/assets/flags/km-2976c3376cf684fa5901f144705e758b85a218ac83842b9b1db49cba5be381a0.svg",
  },
  Congo: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CG",
    flag_path:
      "/assets/flags/cg-2b740c3fdc86df76a4f4ead09ccc6664e0163137207bbb61e7b57e0d4c9be547.svg",
  },
  "Congo, The Democratic Republic Of The": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CD",
    flag_path:
      "/assets/flags/cd-ae468a43e22f29834d1cf7ec146de566208cd4c080b7e41f89da062869286bb2.svg",
  },
  "Cook Islands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CK",
    flag_path:
      "/assets/flags/ck-8e6b1d3b6c387fadb92652588fee211d070cd282c0aa3fb0fbbd9e4b9ccbb542.svg",
  },
  "Costa Rica": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CR",
    flag_path:
      "/assets/flags/cr-7f230d3c663b681efbe66f7c17cf462b8e4654df5b2dc9ed2d4c042481bd6303.svg",
  },
  Croatia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "HR",
    flag_path:
      "/assets/flags/hr-b0f2f2fb13f5432879442bcae39e125a360f552dd02fe58c9a48fa44a4a9afca.svg",
  },
  Cuba: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "CU",
    flag_path:
      "/assets/flags/cu-aa4bcfbbe8e82a584fe8350cf0d88f69466686babcb4576dbf93b64be9537d07.svg",
  },
  Curaçao: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "CW",
    flag_path:
      "/assets/flags/cw-8321fddf0fbdd74f630838df754e17b329b19e18a0a5eee518d2b5c866f8a0f9.svg",
  },
  Cyprus: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "CY",
    flag_path:
      "/assets/flags/cy-2fc766b0049ba792171dd5c20731609ab879a457a0657167d3a9827a2189bd4e.svg",
  },
  "Czech Republic": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "CZ",
    flag_path:
      "/assets/flags/cz-c7cf8a8de7e5f6bc9ae2f80f1d99272031c8ea2841ca79de86476ef9ae4a901b.svg",
  },
  "Côte d'Ivoire": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CI",
    flag_path:
      "/assets/flags/ci-03c70c3f8d18a25facb7c9db9704b9318bd388453bd3b5fc68e8e11a708e978f.svg",
  },
  Denmark: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "DK",
    flag_path:
      "/assets/flags/dk-d15b90eddd91956b7de55019da0479b09a3342d998f38acd5847d3c0be528eaa.svg",
    labels: { address1: "Street and house number" },
  },
  Djibouti: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "DJ",
    flag_path:
      "/assets/flags/dj-9fdbecaccd3869bac5684ab654f4d2adfad0cb8670b593d59792c1e08db9c8e2.svg",
  },
  Dominica: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "DM",
    flag_path:
      "/assets/flags/dm-c9dc4a0b36fe5c2c0f18a2d13d6f44239fd9f495cd89558cac8645393a64f5b1.svg",
  },
  "Dominican Republic": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "DO",
    flag_path:
      "/assets/flags/do-cac884ee8edbc3a89dcae1494b2bdef504ab78c590f7075cc7dbb5fb1c2b6cf6.svg",
  },
  Ecuador: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "EC",
    flag_path:
      "/assets/flags/ec-c596bab16fe217175364df9f15750a6feccab34afdaa8c8c423e3094e06c62a3.svg",
  },
  Egypt: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "EG",
    flag_path:
      "/assets/flags/eg-5b2eda03efba7ebfb1c548ca444b4b406b8f80d1b1e68935de595f7ef9b1a5af.svg",
    labels: { province: "Governorate" },
    provinces: {
      "6th of October": {
        alternate_names: ["As Sādis min Uktūbar", "As Sadis min Uktubar"],
        code: "SU",
      },
      "Al Sharqia": {
        alternate_names: ["Ash Sharqīyah", "Ash Sharqiyah"],
        code: "SHR",
      },
      Alexandria: {
        alternate_names: ["Al Iskandarīyah", "Al Iskandariyah"],
        code: "ALX",
      },
      Aswan: { alternate_names: ["Aswān"], code: "ASN" },
      Asyut: { alternate_names: ["Asyūţ"], code: "AST" },
      Beheira: { alternate_names: ["Al Buḩayrah", "Al Buayrah"], code: "BH" },
      "Beni Suef": {
        alternate_names: ["Banī Suwayf", "Bani Suwayf"],
        code: "BNS",
      },
      Cairo: { alternate_names: ["Al Qāhirah", "Al Qahirah"], code: "C" },
      Dakahlia: {
        alternate_names: ["Ad Daqahlīyah", "Ad Daqahliyah"],
        code: "DK",
      },
      Damietta: { alternate_names: ["Dumyāţ", "Dumyat"], code: "DT" },
      Faiyum: { alternate_names: ["Al Fayyūm", "Al Fayyum"], code: "FYM" },
      Gharbia: { alternate_names: ["Al Gharbīyah", "Al Gharbiyah"], code: "GH" },
      Giza: { alternate_names: ["Al Jīzah", "Al Jizah"], code: "GZ" },
      Helwan: { alternate_names: ["Ḩulwān", "ulwan"], code: "HU" },
      Ismailia: {
        alternate_names: ["Al Ismāٰīlīyah", "Al Ismailiyah"],
        code: "IS",
      },
      "Kafr el-Sheikh": { alternate_names: ["Kafr ash Shaykh"], code: "KFS" },
      Luxor: { alternate_names: ["Al Uqşur", "Al Uqsur"], code: "LX" },
      Matrouh: { alternate_names: ["Maţrūḩ", "Matru"], code: "MT" },
      Minya: { alternate_names: ["Al Minyā", "Al Minya"], code: "MN" },
      Monufia: { alternate_names: ["Al Minūfīyah", "Al Minufiyah"], code: "MNF" },
      "New Valley": {
        alternate_names: ["Al Wādī al Jadīd", "Al Wadi al Jadid"],
        code: "WAD",
      },
      "North Sinai": {
        alternate_names: ["Shamāl Sīnā", "Shamal Sina"],
        code: "SIN",
      },
      "Port Said": { alternate_names: ["Būr Saٰīd", "Bur Said"], code: "PTS" },
      Qalyubia: {
        alternate_names: ["Al Qalyūbīyah", "Al Qalyubiyah"],
        code: "KB",
      },
      Qena: { alternate_names: ["Qinā", "Qina"], code: "KN" },
      "Red Sea": {
        alternate_names: ["Al Baḩr al Aḩmar", "Al Bar al Amar"],
        code: "BA",
      },
      Sohag: { alternate_names: ["Sūhāj", "Suhaj"], code: "SHG" },
      "South Sinai": {
        alternate_names: ["Janūb Sīnā", "Janub Sina"],
        code: "JS",
      },
      Suez: { alternate_names: ["As Suways"], code: "SUZ" },
    },
  },
  "El Salvador": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SV",
    flag_path:
      "/assets/flags/sv-006db52e2d7a1c487539ae6c1e78e3155566ee2f7929c1e03223e6b737ac6ab9.svg",
  },
  "Equatorial Guinea": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GQ",
    flag_path:
      "/assets/flags/gq-9c100a177dc61bad19c2a062b125adcf18666ac150313b441ed1e3718d0e47ae.svg",
  },
  Eritrea: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "ER",
    flag_path:
      "/assets/flags/er-b6b91d43ee051cc4d28acdabdeede03d0459fae3b5c58a0cece76ff97ebf6a0b.svg",
  },
  Estonia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "EE",
    flag_path:
      "/assets/flags/ee-cf276fa448f6bc2db954c646d66ad95094b3196c1ba3eb4c2cf4de9a5fec8440.svg",
  },
  Eswatini: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SZ",
    flag_path:
      "/assets/flags/sz-92d32ce32d08e4c21ad37388cc11bf6f99250b5ae5ec0fc152b9cefe09447715.svg",
  },
  Ethiopia: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "ET",
    flag_path:
      "/assets/flags/et-88bc1ebc97fb568afd75c3d34ce56c3c689134d8c3bdd42c4bcd1c33ded0e622.svg",
  },
  "Falkland Islands (Malvinas)": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "FK",
    flag_path:
      "/assets/flags/fk-b035f39f06bfca7c5ddb552910884b207c348675be064d1a9acb38a09fd46537.svg",
  },
  "Faroe Islands": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "FO",
    flag_path:
      "/assets/flags/fo-ba7ea8e10960ce78eb1a627faba52b8efcdd937bcf83acfb35f54635d79639c3.svg",
  },
  Fiji: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "FJ",
    flag_path:
      "/assets/flags/fj-95a085e87c958f72664e1048cc0cf71b3a4e29bfccd6f3a8186f955c3766ca13.svg",
  },
  Finland: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "FI",
    flag_path:
      "/assets/flags/fi-8c4f1b318e181809c96b510c9d4c27b0b0146e9e01b2a0914c5bf374d11e2886.svg",
  },
  France: {
    address_format: { edit: 6, edit_experiment: 20 },
    code: "FR",
    flag_path:
      "/assets/flags/fr-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg",
  },
  "French Guiana": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "GF",
    flag_path:
      "/assets/flags/gf-e5758afce89dedfa86013c413f6b1770f6b6f29bbdfcf8c9ec67c0727a8dbebe.svg",
  },
  "French Polynesia": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "PF",
    flag_path:
      "/assets/flags/pf-5aeb49aac473f8e2718d7f668538451a52b6040c0f2b5c49dc0459e098ae6dbd.svg",
  },
  "French Southern Territories": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "TF",
    flag_path:
      "/assets/flags/tf-b25e7bbb1fe82f1d734fe3d40f3cbd2291d2a72cbc2c3af37da4118a3ec9b974.svg",
  },
  Gabon: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GA",
    flag_path:
      "/assets/flags/ga-4c7cdf1af94111f99e53c48e3af3326180a9a03b71d032f683bfdd74d116a066.svg",
  },
  Gambia: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GM",
    flag_path:
      "/assets/flags/gm-242e323d8c81ae460bd10e89d45d718b995451c6ab77f3833ae7248cbca76e0e.svg",
  },
  Georgia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "GE",
    flag_path:
      "/assets/flags/ge-7abbfeb26a91671f1e6f6f1ac9bbe7ff98315929241ae5aae4d891244c4fc1d9.svg",
  },
  Germany: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "DE",
    flag_path:
      "/assets/flags/de-cb66769cd2af2a363f35a0f1da6c7a5c584c35a6391f2dd4878c6f9005f3ecb6.svg",
    labels: {
      address1: "Street and house number",
      address2: "Additional address (optional)",
    },
  },
  Ghana: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "GH",
    flag_path:
      "/assets/flags/gh-52bd561ba8efc114ad5eb0cdeeca772dc097457b480ecd1e04fb44fc38f24643.svg",
  },
  Gibraltar: {
    address_format: { edit: 7, edit_experiment: 25 },
    code: "GI",
    flag_path:
      "/assets/flags/gi-fcca3661db81ea804e6e295390699f9b12381cdeb9e89e6452844a9f47326b23.svg",
  },
  Greece: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "GR",
    flag_path:
      "/assets/flags/gr-8df55bbaf97f7ec255c4edd00172fa549fda7c6b0f805a3f5bfda56244e4064d.svg",
  },
  Greenland: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "GL",
    flag_path:
      "/assets/flags/gl-95144c3e191cd567fee82bf0f223f4f5729a2b7a9d9115ab713411c61fb59a12.svg",
  },
  Grenada: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GD",
    flag_path:
      "/assets/flags/gd-b00fe4a1299babda4b4926408d6335e9de55ee2a43906a80ca5092a2f7bba53b.svg",
  },
  Guadeloupe: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "GP",
    flag_path:
      "/assets/flags/gp-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg",
  },
  Guatemala: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "GT",
    flag_path:
      "/assets/flags/gt-6b7c52001bc25b596e05cc2730f93d6976271bd7fda1667a0a6171573a5eb0cf.svg",
    provinces: {
      "Alta Verapaz": { code: "AVE" },
      "Baja Verapaz": { code: "BVE" },
      Chimaltenango: { code: "CMT" },
      Chiquimula: { code: "CQM" },
      "El Progreso": { code: "EPR" },
      Escuintla: { code: "ESC" },
      Guatemala: { code: "GUA" },
      Huehuetenango: { code: "HUE" },
      Izabal: { code: "IZA" },
      Jalapa: { code: "JAL" },
      Jutiapa: { code: "JUT" },
      Petén: { alternate_names: ["Peten"], code: "PET" },
      Quetzaltenango: { code: "QUE" },
      Quiché: { alternate_names: ["Quiche"], code: "QUI" },
      Retalhuleu: { code: "RET" },
      Sacatepéquez: { alternate_names: ["Sacatepequez"], code: "SAC" },
      "San Marcos": { code: "SMA" },
      "Santa Rosa": { code: "SRO" },
      Sololá: { alternate_names: ["Solola"], code: "SOL" },
      Suchitepéquez: { alternate_names: ["Suchitepequez"], code: "SUC" },
      Totonicapán: { alternate_names: ["Totonicapan"], code: "TOT" },
      Zacapa: { code: "ZAC" },
    },
  },
  Guernsey: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GG",
    flag_path:
      "/assets/flags/gg-df748401d5d3ec0c564ccf54ff192a6bffb34e6af25dae6a379639269eedc2eb.svg",
  },
  Guinea: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GN",
    flag_path:
      "/assets/flags/gn-52a9dc98e6122cd57cc813c4da100240ab7814f724a66b0954a0c34c145a4ddf.svg",
  },
  "Guinea Bissau": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "GW",
    flag_path:
      "/assets/flags/gw-68a5ffd842fed63a8ffcd151180b4b19155ab7cd13987cd3a31d86f6ac51763a.svg",
  },
  Guyana: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GY",
    flag_path:
      "/assets/flags/gy-da90cfa08127e416de3191deecfc4347da6431b60583c685e424aba6be5681e9.svg",
  },
  Haiti: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "HT",
    flag_path:
      "/assets/flags/ht-ce3ae14b69acfda0e166b359341be5344b484d47531a517247f3871277aaee48.svg",
  },
  "Heard Island And Mcdonald Islands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "HM",
    flag_path:
      "/assets/flags/hm-e1cd313272f5073da89c733dce47268634c84d0a7656a8593446dda2682594a4.svg",
  },
  "Holy See (Vatican City State)": {
    address_format: { edit: 8, edit_experiment: 25 },
    code: "VA",
    flag_path:
      "/assets/flags/va-5fb5774b8de2849de4208dceaeef0f1705f4dbd5de11c024285f3cb174c47c65.svg",
  },
  Honduras: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "HN",
    flag_path:
      "/assets/flags/hn-3c0b0aaff2299cdcb42f8c8d39aec9c24831332914032cedb89f09d52d2dc0a6.svg",
  },
  "Hong Kong": {
    address_format: { edit: 9, edit_experiment: 26 },
    code: "HK",
    flag_path:
      "/assets/flags/hk-6eedf73300bf96c4ffb2528905eb62da02456b532d08545de303aa55cd0cf057.svg",
    labels: { city: "District" },
    provinces: {
      "Hong Kong Island": {
        alternate_names: ["Hong Kong Province", "Hong Kong", "香港", "香港島"],
        code: "HK",
      },
      Kowloon: { alternate_names: ["九龍"], code: "KL" },
      "New Territories": { alternate_names: ["新界"], code: "NT" },
    },
  },
  Hungary: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "HU",
    flag_path:
      "/assets/flags/hu-5b54a899b7a3fd5dfbd4dbe9ed320af2c58162267c9c16cf89ae1fa92d9bb38f.svg",
  },
  Iceland: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "IS",
    flag_path:
      "/assets/flags/is-7d6167f029623e57ee7b030708b18ae267ff961a4f829b37cbc246a0c9311474.svg",
  },
  India: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "IN",
    flag_path:
      "/assets/flags/in-0e84df729f1dd51112bd51e4523e7f5416aa65ade27b7f66056bcb13e1f933c4.svg",
    labels: { province: "State", zip: "PIN code" },
    provinces: {
      "Andaman and Nicobar Islands": {
        alternate_names: ["Andaman and Nicobar"],
        code: "AN",
      },
      "Andhra Pradesh": { code: "AP" },
      "Arunachal Pradesh": { code: "AR" },
      Assam: { code: "AS" },
      Bihar: { code: "BR" },
      Chandigarh: { code: "CH" },
      Chhattisgarh: { alternate_names: ["Chattisgarh", "CT"], code: "CG" },
      "Dadra and Nagar Haveli": { code: "DN" },
      "Daman and Diu": { code: "DD" },
      Delhi: { code: "DL" },
      Goa: { code: "GA" },
      Gujarat: { code: "GJ" },
      Haryana: { code: "HR" },
      "Himachal Pradesh": { code: "HP" },
      "Jammu and Kashmir": { code: "JK" },
      Jharkhand: { code: "JH" },
      Karnataka: { code: "KA" },
      Kerala: { code: "KL" },
      Ladakh: { code: "LA" },
      Lakshadweep: { code: "LD" },
      "Madhya Pradesh": { code: "MP" },
      Maharashtra: { code: "MH" },
      Manipur: { code: "MN" },
      Meghalaya: { code: "ML" },
      Mizoram: { code: "MZ" },
      Nagaland: { code: "NL" },
      Odisha: { alternate_names: ["OD", "Orissa"], code: "OR" },
      Puducherry: { code: "PY" },
      Punjab: { code: "PB" },
      Rajasthan: { code: "RJ" },
      Sikkim: { code: "SK" },
      "Tamil Nadu": { code: "TN" },
      Telangana: { code: "TS" },
      Tripura: { code: "TR" },
      "Uttar Pradesh": { code: "UP" },
      Uttarakhand: { code: "UK" },
      "West Bengal": { code: "WB" },
    },
  },
  Indonesia: {
    address_format: { edit: 10, edit_experiment: 27 },
    code: "ID",
    flag_path:
      "/assets/flags/id-af8ae4bcec649b476a512871baf313544722c3dc3b9336e6bc15496e1e158cfb.svg",
    labels: { province: "Province" },
    provinces: {
      Aceh: { code: "AC" },
      Bali: { code: "BA" },
      "Bangka Belitung": { label: "Bangka–Belitung Islands", code: "BB" },
      Banten: { code: "BT" },
      Bengkulu: { code: "BE" },
      "Jawa Tengah": { label: "Central Java", code: "JT" },
      "Kalimantan Tengah": { label: "Central Kalimantan", code: "KT" },
      "Sulawesi Tengah": { label: "Central Sulawesi", code: "ST" },
      "Jawa Timur": { label: "East Java", code: "JI" },
      "Kalimantan Timur": { label: "East Kalimantan", code: "KI" },
      "Nusa Tenggara Timur": { label: "East Nusa Tenggara", code: "NT" },
      Gorontalo: { code: "GO" },
      Jakarta: { code: "JK" },
      Jambi: { code: "JA" },
      Lampung: { code: "LA" },
      Maluku: { code: "MA" },
      "Kalimantan Utara": { label: "North Kalimantan", code: "KU" },
      "Maluku Utara": { label: "North Maluku", code: "MU" },
      "Sulawesi Utara": { label: "North Sulawesi", code: "SA" },
      "North Sumatra": { alternate_names: ["Sumatra Utara"], code: "SU" },
      Papua: { code: "PA" },
      Riau: { code: "RI" },
      "Kepulauan Riau": { label: "Riau Islands", code: "KR" },
      "Kalimantan Selatan": { label: "South Kalimantan", code: "KS" },
      "Sulawesi Selatan": { label: "South Sulawesi", code: "SN" },
      "South Sumatra": { alternate_names: ["Sumatra Selatan"], code: "SS" },
      "Sulawesi Tenggara": { label: "Southeast Sulawesi", code: "SG" },
      "Jawa Barat": { label: "West Java", code: "JB" },
      "Kalimantan Barat": { label: "West Kalimantan", code: "KB" },
      "Nusa Tenggara Barat": { label: "West Nusa Tenggara", code: "NB" },
      "Papua Barat": { label: "West Papua", code: "PB" },
      "Sulawesi Barat": { label: "West Sulawesi", code: "SR" },
      "West Sumatra": { alternate_names: ["Sumatra Barat"], code: "SB" },
      Yogyakarta: { code: "YO" },
    },
  },
  "Iran, Islamic Republic Of": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "IR",
    flag_path:
      "/assets/flags/ir-680330c54ee3ba2f3528855ea02611ed16adcc63cf7f98cb5e8f2f10cb7a5743.svg",
  },
  Iraq: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "IQ",
    flag_path:
      "/assets/flags/iq-2fc377138af11be17c6f5e45da8dd971b237fab2bc7f273a01d5609796b66369.svg",
  },
  Ireland: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "IE",
    flag_path:
      "/assets/flags/ie-8147f9c27f0e03a7873cd3183607439337bc9b49b8d2578c3819df5cd76bf02e.svg",
    labels: { province: "County" },
    provinces: {
      Carlow: {
        alternate_names: ["Ceatharlach", "Contae Cheatharlach", "County Carlow", "Co. Carlow"],
        code: "CW",
      },
      Cavan: {
        alternate_names: [
          "An Cabhán",
          "An Cabhan",
          "Contae an Chabháin",
          "Contae an Chabhain",
          "County Cavan",
          "Co. Cavan",
        ],
        code: "CN",
      },
      Clare: {
        alternate_names: [
          "An Clár",
          "An Clar",
          "Contae an Chláir",
          "Contae an Chlair",
          "County Clare",
          "Co. Clare",
        ],
        code: "CE",
      },
      Cork: {
        alternate_names: [
          "Corcaigh",
          "Contae Chorcaí",
          "Contae Chorcai",
          "County Cork",
          "Co. Cork",
        ],
        code: "CO",
      },
      Donegal: {
        alternate_names: [
          "Dún na nGall",
          "Dun na nGall",
          "Contae Dhún na nGall",
          "Contae Dhun na nGall",
          "County Donegal",
          "Co. Donegal",
        ],
        code: "DL",
      },
      Dublin: {
        alternate_names: ["Baile Átha Cliath", "Baile Atha Cliath", "County Dublin", "Co. Dublin"],
        code: "D",
      },
      Galway: {
        alternate_names: ["Gaillimh", "Contae na Gaillimhe", "County Galway", "Co. Galway"],
        code: "G",
      },
      Kerry: {
        alternate_names: [
          "Ciarraí",
          "Ciarrai",
          "Contae Chiarraí",
          "Contae Chiarrai",
          "County Kerry",
          "Co. Kerry",
        ],
        code: "KY",
      },
      Kildare: {
        alternate_names: ["Cill Dara", "Contae Chill Dara", "County Kildare", "Co. Kildare"],
        code: "KE",
      },
      Kilkenny: {
        alternate_names: [
          "Cill Chainnigh",
          "Contae Chill Chainnigh",
          "County Kilkenny",
          "Co. Kilkenny",
        ],
        code: "KK",
      },
      Laois: {
        alternate_names: ["Contae Laoise", "County Laois", "Co. Laois"],
        code: "LS",
      },
      Leitrim: {
        alternate_names: ["Liatroim", "Contae Liatroma", "County Leitrim", "Co. Leitrim"],
        code: "LM",
      },
      Limerick: {
        alternate_names: ["Luimneach", "Contae Luimnigh", "County Limerick", "Co. Limerick"],
        code: "LK",
      },
      Longford: {
        alternate_names: ["An Longfort", "Contae an Longfoirt", "County Longford", "Co. Longford"],
        code: "LD",
      },
      Louth: {
        alternate_names: ["Lú", "Lu", "Contae Lú", "Contae Lu", "County Louth", "Co. Louth"],
        code: "LH",
      },
      Mayo: {
        alternate_names: ["Maigh Eo", "Contae Mhaigh Eo", "County Mayo", "Co. Mayo"],
        code: "MO",
      },
      Meath: {
        alternate_names: [
          "An Mhí",
          "An Mhi",
          "Contae na Mí",
          "Contae na Mi",
          "County Meath",
          "Co. Meath",
        ],
        code: "MH",
      },
      Monaghan: {
        alternate_names: [
          "Muineachán",
          "Muineachan",
          "Contae Mhuineacháin",
          "Contae Mhuineachain",
          "County Monaghan",
          "Co. Monaghan",
        ],
        code: "MN",
      },
      Offaly: {
        alternate_names: [
          "Uíbh Fhailí",
          "Uibh Fhaili",
          "Contae Uíbh Fhailí",
          "Contae Uibh Fhaili",
          "County Offaly",
          "Co. Offaly",
        ],
        code: "OY",
      },
      Roscommon: {
        alternate_names: [
          "Ros Comáin",
          "Ros Comain",
          "Contae Ros Comáin",
          "Contae Ros Comain",
          "County Roscommon",
          "Co. Roscommon",
        ],
        code: "RN",
      },
      Sligo: {
        alternate_names: ["Sligeach", "Contae Shligigh", "County Sligo", "Co. Sligo"],
        code: "SO",
      },
      Tipperary: {
        alternate_names: [
          "Tiobraid Árann",
          "Tiobraid Arann",
          "Contae Thiobraid Árann",
          "Contae Thiobraid Arann",
          "County Tipperary",
          "Co. Tipperary",
        ],
        code: "TA",
      },
      Waterford: {
        alternate_names: [
          "Port Láirge",
          "Port Lairge",
          "Contae Phort Láirge",
          "Contae Phort Lairge",
          "County Waterford",
          "Co. Waterford",
        ],
        code: "WD",
      },
      Westmeath: {
        alternate_names: [
          "An Iarmhí",
          "An Iarmhi",
          "Contae na hIarmhí",
          "Contae na hIarmhi",
          "County Westmeath",
          "Co. Westmeath",
        ],
        code: "WH",
      },
      Wexford: {
        alternate_names: ["Loch Garman", "Contae Loch Garman", "County Wexford", "Co. Wexford"],
        code: "WX",
      },
      Wicklow: {
        alternate_names: [
          "Cill Mhantáin",
          "Cill Mhantain",
          "Contae Chill Mhantáin",
          "Contae Chill Mhantain",
          "County Wicklow",
          "Co. Wicklow",
        ],
        code: "WW",
      },
    },
  },
  "Isle Of Man": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "IM",
    flag_path:
      "/assets/flags/im-eeb3c6dc669cddbb33432b2429d9c85242e77b353164a48a16466d05f935e0d2.svg",
  },
  Israel: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "IL",
    flag_path:
      "/assets/flags/il-e2f8bc4dab5fcdf4901092f3e50d733859dbf9afc960b2ff159ef4042f58b39c.svg",
  },
  Italy: {
    address_format: { edit: 11, edit_experiment: 22 },
    code: "IT",
    flag_path:
      "/assets/flags/it-df6faa9601d03e30d30e9a0e630d0286bcdc4306a0570f217034077e7f5875f8.svg",
    labels: { address1: "Street and house number", province: "Province" },
    provinces: {
      Agrigento: { code: "AG" },
      Alessandria: { code: "AL" },
      Ancona: { code: "AN" },
      Aosta: { code: "AO" },
      Arezzo: { code: "AR" },
      "Ascoli Piceno": { code: "AP" },
      Asti: { code: "AT" },
      Avellino: { code: "AV" },
      Bari: { code: "BA" },
      "Barletta-Andria-Trani": { code: "BT" },
      Belluno: { code: "BL" },
      Benevento: { code: "BN" },
      Bergamo: { code: "BG" },
      Biella: { code: "BI" },
      Bologna: { code: "BO" },
      Brescia: { code: "BS" },
      Brindisi: { code: "BR" },
      Cagliari: { code: "CA" },
      Caltanissetta: { code: "CL" },
      Campobasso: { code: "CB" },
      "Carbonia-Iglesias": { code: "CI" },
      Caserta: { code: "CE" },
      Catania: { code: "CT" },
      Catanzaro: { code: "CZ" },
      Chieti: { code: "CH" },
      Como: { code: "CO" },
      Cosenza: { code: "CS" },
      Cremona: { code: "CR" },
      Crotone: { code: "KR" },
      Cuneo: { code: "CN" },
      Enna: { code: "EN" },
      Fermo: { code: "FM" },
      Ferrara: { code: "FE" },
      Firenze: { label: "Florence", code: "FI" },
      Foggia: { code: "FG" },
      "Forlì-Cesena": { alternate_names: ["Forli-Cesena"], code: "FC" },
      Frosinone: { code: "FR" },
      Genova: { label: "Genoa", code: "GE" },
      Gorizia: { code: "GO" },
      Grosseto: { code: "GR" },
      Imperia: { code: "IM" },
      Isernia: { code: "IS" },
      "La Spezia": { code: "SP" },
      Latina: { code: "LT" },
      Lecce: { code: "LE" },
      Lecco: { code: "LC" },
      Livorno: { code: "LI" },
      Lodi: { code: "LO" },
      Lucca: { code: "LU" },
      "L'Aquila": { label: "L’Aquila", code: "AQ" },
      Macerata: { code: "MC" },
      Mantova: { label: "Mantua", code: "MN" },
      "Massa-Carrara": { label: "Massa and Carrara", code: "MS" },
      Matera: { code: "MT" },
      "Medio Campidano": { code: "VS" },
      Messina: { code: "ME" },
      Milano: { label: "Milan", code: "MI" },
      Modena: { code: "MO" },
      "Monza e Brianza": { label: "Monza and Brianza", code: "MB" },
      Napoli: { label: "Naples", code: "NA" },
      Novara: { code: "NO" },
      Nuoro: { code: "NU" },
      Ogliastra: { code: "OG" },
      "Olbia-Tempio": { code: "OT" },
      Oristano: { code: "OR" },
      Padova: { label: "Padua", code: "PD" },
      Palermo: { code: "PA" },
      Parma: { code: "PR" },
      Pavia: { code: "PV" },
      Perugia: { code: "PG" },
      "Pesaro e Urbino": { label: "Pesaro and Urbino", code: "PU" },
      Pescara: { code: "PE" },
      Piacenza: { code: "PC" },
      Pisa: { code: "PI" },
      Pistoia: { code: "PT" },
      Pordenone: { code: "PN" },
      Potenza: { code: "PZ" },
      Prato: { code: "PO" },
      Ragusa: { code: "RG" },
      Ravenna: { code: "RA" },
      "Reggio Calabria": { alternate_names: ["Calabria"], code: "RC" },
      "Reggio Emilia": { code: "RE" },
      Rieti: { code: "RI" },
      Rimini: { code: "RN" },
      Roma: { label: "Rome", code: "RM" },
      Rovigo: { code: "RO" },
      Salerno: { code: "SA" },
      Sassari: { code: "SS" },
      Savona: { code: "SV" },
      Siena: { code: "SI" },
      Sondrio: { code: "SO" },
      Bolzano: { label: "South Tyrol", code: "BZ" },
      Siracusa: { label: "Syracuse", code: "SR" },
      Taranto: { code: "TA" },
      Teramo: { code: "TE" },
      Terni: { code: "TR" },
      Trapani: { code: "TP" },
      Trento: { label: "Trentino", code: "TN" },
      Treviso: { code: "TV" },
      Trieste: { code: "TS" },
      Torino: { label: "Turin", code: "TO" },
      Udine: { code: "UD" },
      Varese: { code: "VA" },
      Venezia: { label: "Venice", code: "VE" },
      "Verbano-Cusio-Ossola": { code: "VB" },
      Vercelli: { code: "VC" },
      Verona: { code: "VR" },
      "Vibo Valentia": { code: "VV" },
      Vicenza: { code: "VI" },
      Viterbo: { code: "VT" },
    },
  },
  Jamaica: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "JM",
    flag_path:
      "/assets/flags/jm-0e3edd42bd4bf86be415d5dc3689bdcb816b00eab77d0b56552091b85e775114.svg",
  },
  Japan: {
    address_format: { edit: 12, edit_experiment: 28 },
    code: "JP",
    flag_path:
      "/assets/flags/jp-51834ceb282af719ada5d7477c503a77711d1e57d0b0a5665d78497e6c9521c8.svg",
    labels: { city: "City/ward/town/village", province: "Prefecture" },
    provinces: {
      Hokkaidō: {
        label: "Hokkaido",
        alternate_names: ["Hokkaido", "Hokkaido Prefecture", "北海道"],
        code: "JP-01",
      },
      Aomori: {
        alternate_names: ["Aomori Prefecture", "Aomori-ken", "青森県", "青森"],
        code: "JP-02",
      },
      Iwate: {
        alternate_names: ["Iwate Prefecture", "Iwate-ken", "岩手県", "岩手"],
        code: "JP-03",
      },
      Miyagi: {
        alternate_names: ["Miyagi Prefecture", "Miyagi-ken", "宮城県", "宮城"],
        code: "JP-04",
      },
      Akita: {
        alternate_names: ["Akita Prefecture", "Akita-ken", "秋田県", "秋田"],
        code: "JP-05",
      },
      Yamagata: {
        alternate_names: ["Yamagata Prefecture", "Yamagata-ken", "山形県", "山形"],
        code: "JP-06",
      },
      Fukushima: {
        alternate_names: ["Fukushima Prefecture", "Fukushima-ken", "福島県", "福島"],
        code: "JP-07",
      },
      Ibaraki: {
        alternate_names: ["Ibaraki Prefecture", "Ibaraki-ken", "茨城県", "茨城"],
        code: "JP-08",
      },
      Tochigi: {
        alternate_names: ["Tochigi Prefecture", "Tochigi-ken", "栃木県", "栃木"],
        code: "JP-09",
      },
      Gunma: {
        alternate_names: ["Gunma Prefecture", "Gunma-ken", "群馬県", "群馬"],
        code: "JP-10",
      },
      Saitama: {
        alternate_names: ["Saitama Prefecture", "Saitama-ken", "埼玉県", "埼玉"],
        code: "JP-11",
      },
      Chiba: {
        alternate_names: ["Chiba Prefecture", "Chiba-ken", "千葉県", "千葉"],
        code: "JP-12",
      },
      Tōkyō: {
        label: "Tokyo",
        alternate_names: ["Tokyo", "Tokyo Prefecture", "Tōkyō-to", "Tokyo-to", "東京都", "東京"],
        code: "JP-13",
      },
      Kanagawa: {
        alternate_names: ["Kanagawa Prefecture", "Kanagawa-ken", "神奈川県", "神奈川"],
        code: "JP-14",
      },
      Niigata: {
        alternate_names: ["Niigata Prefecture", "Niigata-ken", "新潟県", "新潟"],
        code: "JP-15",
      },
      Toyama: {
        alternate_names: ["Toyama Prefecture", "Toyama-ken", "富山県", "富山"],
        code: "JP-16",
      },
      Ishikawa: {
        alternate_names: ["Ishikawa Prefecture", "Ishikawa-ken", "石川県", "石川"],
        code: "JP-17",
      },
      Fukui: {
        alternate_names: ["Fukui Prefecture", "Fukui-ken", "福井県", "福井"],
        code: "JP-18",
      },
      Yamanashi: {
        alternate_names: ["Yamanashi Prefecture", "Yamanashi-ken", "山梨県", "山梨"],
        code: "JP-19",
      },
      Nagano: {
        alternate_names: ["Nagano Prefecture", "Nagano-ken", "長野県", "長野"],
        code: "JP-20",
      },
      Gifu: {
        alternate_names: ["Gifu Prefecture", "Gifu-ken", "岐阜県", "岐阜"],
        code: "JP-21",
      },
      Shizuoka: {
        alternate_names: ["Shizuoka Prefecture", "Shizuoka-ken", "静岡県", "静岡"],
        code: "JP-22",
      },
      Aichi: {
        alternate_names: ["Aichi Prefecture", "Aichi-ken", "愛知県", "愛知"],
        code: "JP-23",
      },
      Mie: {
        alternate_names: ["Mie Prefecture", "Mie-ken", "三重県", "三重"],
        code: "JP-24",
      },
      Shiga: {
        alternate_names: ["Shiga Prefecture", "Shiga-ken", "滋賀県", "滋賀"],
        code: "JP-25",
      },
      Kyōto: {
        label: "Kyoto",
        alternate_names: ["Kyoto", "Kyoto Prefecture", "Kyōto-fu", "Kyoto-fu", "京都府", "京都"],
        code: "JP-26",
      },
      Ōsaka: {
        label: "Osaka",
        alternate_names: ["Osaka", "Osaka Prefecture", "Ōsaka-fu", "Osaka-fu", "大阪府", "大阪"],
        code: "JP-27",
      },
      Hyōgo: {
        label: "Hyogo",
        alternate_names: ["Hyogo", "Hyogo Prefecture", "Hyōgo-ken", "Hyogo-ken", "兵庫県", "兵庫"],
        code: "JP-28",
      },
      Nara: {
        alternate_names: ["Nara Prefecture", "Nara-ken", "奈良県", "奈良"],
        code: "JP-29",
      },
      Wakayama: {
        alternate_names: ["Wakayama Prefecture", "Wakayama-ken", "和歌山県", "和歌山"],
        code: "JP-30",
      },
      Tottori: {
        alternate_names: ["Tottori Prefecture", "Tottori-ken", "鳥取県", "鳥取"],
        code: "JP-31",
      },
      Shimane: {
        alternate_names: ["Shimane Prefecture", "Shimane-ken", "島根県", "島根"],
        code: "JP-32",
      },
      Okayama: {
        alternate_names: ["Okayama Prefecture", "Okayama-ken", "岡山県", "岡山"],
        code: "JP-33",
      },
      Hiroshima: {
        alternate_names: ["Hiroshima Prefecture", "Hiroshima-ken", "広島県", "広島"],
        code: "JP-34",
      },
      Yamaguchi: {
        alternate_names: ["Yamaguchi Prefecture", "Yamaguchi-ken", "山口県", "山口"],
        code: "JP-35",
      },
      Tokushima: {
        alternate_names: ["Tokushima Prefecture", "Tokushima-ken", "徳島県", "徳島"],
        code: "JP-36",
      },
      Kagawa: {
        alternate_names: ["Kagawa Prefecture", "Kagawa-ken", "香川県", "香川"],
        code: "JP-37",
      },
      Ehime: {
        alternate_names: ["Ehime Prefecture", "Ehime-ken", "愛媛県", "愛媛"],
        code: "JP-38",
      },
      Kōchi: {
        label: "Kochi",
        alternate_names: ["Kochi", "Kochi Prefecture", "Kōchi-ken", "Kochi-ken", "高知県", "高知"],
        code: "JP-39",
      },
      Fukuoka: {
        alternate_names: ["Fukuoka Prefecture", "Fukuoka-ken", "福岡県", "福岡"],
        code: "JP-40",
      },
      Saga: {
        alternate_names: ["Saga Prefecture", "Saga-ken", "佐賀県", "佐賀"],
        code: "JP-41",
      },
      Nagasaki: {
        alternate_names: ["Nagasaki Prefecture", "Nagasaki-ken", "長崎県", "長崎"],
        code: "JP-42",
      },
      Kumamoto: {
        alternate_names: ["Kumamoto Prefecture", "Kumamoto-ken", "熊本県", "熊本"],
        code: "JP-43",
      },
      Ōita: {
        label: "Oita",
        alternate_names: ["Oita", "Oita Prefecture", "Ōita-ken", "Oita-ken", "大分県", "大分"],
        code: "JP-44",
      },
      Miyazaki: {
        alternate_names: ["Miyazaki Prefecture", "Miyazaki-ken", "宮崎県", "宮崎"],
        code: "JP-45",
      },
      Kagoshima: {
        alternate_names: ["Kagoshima Prefecture", "Kagoshima-ken", "鹿児島県", "鹿児島"],
        code: "JP-46",
      },
      Okinawa: {
        alternate_names: ["Okinawa Prefecture", "Okinawa-ken", "沖縄県", "沖縄"],
        code: "JP-47",
      },
    },
  },
  Jersey: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "JE",
    flag_path:
      "/assets/flags/je-ed7839ff4b12fae6c676427feb0a2ca599a9f66bd97f887a5ab84443af5a38b9.svg",
  },
  Jordan: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "JO",
    flag_path:
      "/assets/flags/jo-dff53aefd172f5a6d6e325609fdb4a07d83e0debd66e5c38352f49ad1bd8160c.svg",
  },
  Kazakhstan: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KZ",
    flag_path:
      "/assets/flags/kz-b5f40935a6b8bd057a0ede54b93916f4707a5c90ffe0199397bb4f5664a35876.svg",
  },
  Kenya: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KE",
    flag_path:
      "/assets/flags/ke-edc7377bd7abe9e998476174f05dd510af1eadd5f4d010ebac91df6686b2762e.svg",
  },
  Kiribati: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KI",
    flag_path:
      "/assets/flags/ki-27438e76ea03d1a7ef2d083b8f15de1c7459db5089d28812bacd8ac627028d94.svg",
  },
  "Korea, Democratic People's Republic Of": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KP",
    flag_path:
      "/assets/flags/kp-b28bc1d8a19853619340a9d9cb7031b444b370331b5844c4c43d2960426e9e9c.svg",
  },
  Kosovo: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "XK",
    flag_path:
      "/assets/flags/xk-25d720dd9c8d9a9cb2f9904824d183054d4e01a8d7c06c2bf2412ea341e26e85.svg",
  },
  Kuwait: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KW",
    flag_path:
      "/assets/flags/kw-12b68de9c29ff5e79c9e92c8a31f21de9654122dff304fe90c07c797f994dcd8.svg",
  },
  Kyrgyzstan: {
    address_format: { edit: 13, edit_experiment: 29 },
    code: "KG",
    flag_path:
      "/assets/flags/kg-9eec999003db08c23990f0a7f5f7f3cff5cd24161a70fbaff7679b784add8da0.svg",
  },
  "Lao People's Democratic Republic": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LA",
    flag_path:
      "/assets/flags/la-144b105e19bcb19e4420dc87b12b9d39e03f80d3cf1224061f283d626433c892.svg",
  },
  Latvia: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LV",
    flag_path:
      "/assets/flags/lv-b4dedfbd55b8f448602419e83c40ed6e3ac1cc9752c62b7905ac9800cee715af.svg",
  },
  Lebanon: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LB",
    flag_path:
      "/assets/flags/lb-c63097230534fca80f65af98f1465625baf6b4f05a977d19d8833f89bad4557d.svg",
  },
  Lesotho: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LS",
    flag_path:
      "/assets/flags/ls-69b000e98f9ddb08fc8e38d1b2d5217f122265d4cc544930a63b489f4c411a44.svg",
  },
  Liberia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "LR",
    flag_path:
      "/assets/flags/lr-d0286d2e904808cc882b263cf582c3e6f390582fd9ac8aa450b2e69ebd9bc84d.svg",
  },
  "Libyan Arab Jamahiriya": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LY",
    flag_path:
      "/assets/flags/ly-3de463fa8f7de627b85cba38c090a0e69d1ae88fcb62b78e162842d84ac4c273.svg",
  },
  Liechtenstein: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "LI",
    flag_path:
      "/assets/flags/li-bd0573b7eee6b796f5e1ce27eeebd8176fe19c7e64dfca90071179e8428ebec5.svg",
  },
  Lithuania: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LT",
    flag_path:
      "/assets/flags/lt-7e19f8f51065107f5f9cb2b71e74617a4b158ed990f22a5b66bd1c663454c9f8.svg",
  },
  Luxembourg: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "LU",
    flag_path:
      "/assets/flags/lu-a71df8b19ddbb2961172c98762fd06c1046b3cad36b63dd45436a2896b0067cf.svg",
  },
  Macao: {
    address_format: { edit: 14, edit_experiment: 21 },
    code: "MO",
    flag_path:
      "/assets/flags/mo-9f244f9cd5f2279bc8ac1f4e5d01f7c0afaff6b64e566346740a102340cc0f35.svg",
  },
  Madagascar: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MG",
    flag_path:
      "/assets/flags/mg-2b91613e51c09ec0dcd2fa8d3d53cd4140eb1a204676c00cdd5fda1a762363a6.svg",
  },
  Malawi: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "MW",
    flag_path:
      "/assets/flags/mw-e9c9476184afa84a0bde62516e0078ff0594b516cd838119ca7a93d273db81ee.svg",
  },
  Malaysia: {
    address_format: { edit: 3, edit_experiment: 22 },
    code: "MY",
    flag_path:
      "/assets/flags/my-62b6693d248cda235b57a98a890d31675b9f374ae77d1c6432a80509d9708d5e.svg",
    labels: { province: "State/territory", zip: "Postcode" },
    provinces: {
      Johor: { code: "JHR" },
      Kedah: { code: "KDH" },
      Kelantan: { code: "KTN" },
      "Kuala Lumpur": { code: "KUL" },
      Labuan: { code: "LBN" },
      Melaka: { label: "Malacca", code: "MLK" },
      "Negeri Sembilan": { code: "NSN" },
      Pahang: { code: "PHG" },
      Penang: { alternate_names: ["Pulau Pinang"], code: "PNG" },
      Perak: { code: "PRK" },
      Perlis: { code: "PLS" },
      Putrajaya: { code: "PJY" },
      Sabah: { code: "SBH" },
      Sarawak: { code: "SWK" },
      Selangor: { code: "SGR" },
      Terengganu: { code: "TRG" },
    },
  },
  Maldives: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "MV",
    flag_path:
      "/assets/flags/mv-c2eb047ffa827867bd29048b939c3f81a94aad885f738b00504624a254161dd4.svg",
  },
  Mali: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "ML",
    flag_path:
      "/assets/flags/ml-ceed9317fbbb708971d76b1f0f5979130ce44f7c99b829dd226c777094c135e5.svg",
  },
  Malta: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "MT",
    flag_path:
      "/assets/flags/mt-a5effdb9c54a7168fced0465607d245ab70ba7d19cc72bb47c847178750eead9.svg",
  },
  Martinique: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MQ",
    flag_path:
      "/assets/flags/mq-29cf42ac8ce58f180821b06aba8015ecd77bcd8a84d7e6f5c4cba71410662ecb.svg",
  },
  Mauritania: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "MR",
    flag_path:
      "/assets/flags/mr-7394cb78de686b7b2b308c26afaeeec859ca44135429edaf704aaa4c0f25fad8.svg",
  },
  Mauritius: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "MU",
    flag_path:
      "/assets/flags/mu-7554fbc2982cdb1b6537c8e8d87758cb3efa5dfc83c5f7a37e4333e97868cdb6.svg",
  },
  Mayotte: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "YT",
    flag_path:
      "/assets/flags/yt-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg",
  },
  Mexico: {
    address_format: { edit: 3, edit_experiment: 22 },
    code: "MX",
    flag_path:
      "/assets/flags/mx-7c49b8206aa19f98841540b81f52c02bc28e0058528a8c0b2af4b6178de83f10.svg",
    labels: { address1: "Street and house number", province: "State" },
    provinces: {
      Aguascalientes: { code: "AGS" },
      "Baja California": { code: "BC" },
      "Baja California Sur": { code: "BCS" },
      Campeche: { code: "CAMP" },
      Chiapas: { code: "CHIS" },
      Chihuahua: { code: "CHIH" },
      "Ciudad de México": {
        label: "Ciudad de Mexico",
        alternate_names: ["Ciudad de Mexico", "CDMX"],
        code: "DF",
      },
      Coahuila: { code: "COAH" },
      Colima: { code: "COL" },
      Durango: { code: "DGO" },
      Guanajuato: { code: "GTO" },
      Guerrero: { code: "GRO" },
      Hidalgo: { code: "HGO" },
      Jalisco: { alternate_names: ["Jal."], code: "JAL" },
      México: { label: "Mexico State", alternate_names: ["Mexico"], code: "MEX" },
      Michoacán: { alternate_names: ["Michoacan"], code: "MICH" },
      Morelos: { code: "MOR" },
      Nayarit: { code: "NAY" },
      "Nuevo León": { alternate_names: ["Nuevo Leon"], code: "NL" },
      Oaxaca: { code: "OAX" },
      Puebla: { code: "PUE" },
      Querétaro: { alternate_names: ["Queretaro"], code: "QRO" },
      "Quintana Roo": { code: "Q ROO" },
      "San Luis Potosí": { alternate_names: ["San Luis Potosi"], code: "SLP" },
      Sinaloa: { code: "SIN" },
      Sonora: { code: "SON" },
      Tabasco: { code: "TAB" },
      Tamaulipas: { code: "TAMPS" },
      Tlaxcala: { code: "TLAX" },
      Veracruz: { code: "VER" },
      Yucatán: { alternate_names: ["Yucatan"], code: "YUC" },
      Zacatecas: { code: "ZAC" },
    },
  },
  "Moldova, Republic of": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MD",
    flag_path:
      "/assets/flags/md-e35529c79c00076bd2150fd41f4b1cba0e999f82d5c43ae955cc578a361faa16.svg",
  },
  Monaco: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MC",
    flag_path:
      "/assets/flags/mc-c11b3585a056936dd491686fd8d89e6b07488a803348fc5875d64d2a89b8ff05.svg",
  },
  Mongolia: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "MN",
    flag_path:
      "/assets/flags/mn-88753edc378f38487765464cff5fc819e36a65effb6dd1424d45ece6a1647e1d.svg",
  },
  Montenegro: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "ME",
    flag_path:
      "/assets/flags/me-6e3e694cab938cc673f3380a3e2b5baafe2c795cd73c9a5c3708e054b6778b13.svg",
  },
  Montserrat: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "MS",
    flag_path:
      "/assets/flags/ms-e4bf8dedd7c10ae7ceff1bac1af7c79ad9e86a2dad24407f8e913ee35585f4d8.svg",
  },
  Morocco: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MA",
    flag_path:
      "/assets/flags/ma-35e1c070d6c6783bf105a3ad485320813e8d45651636d2a83f6fa0f363877abc.svg",
  },
  Mozambique: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MZ",
    flag_path:
      "/assets/flags/mz-1c38e23f096018a517296eeb728ce615b81fd4609f54d21a5d78a8e047788dd3.svg",
  },
  Myanmar: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "MM",
    flag_path:
      "/assets/flags/mm-e02730c89f8713dca8a8f14101887d6188415613803ab3bb29c8514590909985.svg",
  },
  Namibia: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "NA",
    flag_path:
      "/assets/flags/na-3cc917f4965db4ec44f11752ccedd8e1b7d5ba4a3a4b9d3aa57c77fbfa09b674.svg",
  },
  Nauru: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "NR",
    flag_path:
      "/assets/flags/nr-e508ea0235490f99c9324cc8fd18d4d6880b4eb7c31dfda6bd355f6589e4045b.svg",
  },
  Nepal: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "NP",
    flag_path:
      "/assets/flags/np-fea701a8d05db31af7d284add0dc81d3dc1d97e7094ae9c6848ee9b5ebeb45d8.svg",
  },
  Netherlands: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "NL",
    flag_path:
      "/assets/flags/nl-1e00d26bd585c1484559fa8676f9b81bce9578ae149b454921f60392a106a328.svg",
    labels: { address1: "Street and house number" },
  },
  "Netherlands Antilles": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "AN",
    flag_path:
      "/assets/flags/an-674c77b804f2adf1488993b5a8cbbb0501da5fcc8a8b88a3835f3338a1865ecc.svg",
  },
  "New Caledonia": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "NC",
    flag_path:
      "/assets/flags/nc-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg",
  },
  "New Zealand": {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "NZ",
    flag_path:
      "/assets/flags/nz-067fcad818cb43f94a1b4912fc8aabba2ebf649c0012a155227b7b9d7bff5e2a.svg",
    provinces: {
      Auckland: { code: "AUK" },
      "Bay of Plenty": { code: "BOP" },
      Canterbury: { code: "CAN" },
      "Chatham Islands": { code: "CIT" },
      Gisborne: { code: "GIS" },
      "Hawke's Bay": { label: "Hawke’s Bay", code: "HKB" },
      "Manawatu-Wanganui": { code: "MWT" },
      Marlborough: { code: "MBH" },
      Nelson: { code: "NSN" },
      Northland: { code: "NTL" },
      Otago: { code: "OTA" },
      Southland: { code: "STL" },
      Taranaki: { code: "TKI" },
      Tasman: { code: "TAS" },
      Waikato: { code: "WKO" },
      Wellington: { code: "WGN" },
      "West Coast": { code: "WTC" },
    },
  },
  Nicaragua: {
    address_format: { edit: 15, edit_experiment: 30 },
    code: "NI",
    flag_path:
      "/assets/flags/ni-c33af5e3c1ebb00ea0fb53466f0ee0b69a808cf4c7f77fe1d5272a1233198cc3.svg",
  },
  Niger: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "NE",
    flag_path:
      "/assets/flags/ne-36d7acf7411e112f62f261fd0ada6e4fe2852220f0725a0b9bd241c6427ba102.svg",
  },
  Nigeria: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "NG",
    flag_path:
      "/assets/flags/ng-57301401e20c7ebd6687c930caa9c10c227b593bd38b15a217c45f3f2c36a166.svg",
    labels: { province: "State" },
    provinces: {
      Abia: { code: "AB" },
      Adamawa: { code: "AD" },
      "Akwa Ibom": { code: "AK" },
      Anambra: { code: "AN" },
      Bauchi: { code: "BA" },
      Bayelsa: { code: "BY" },
      Benue: { code: "BE" },
      Borno: { code: "BO" },
      "Cross River": { code: "CR" },
      Delta: { code: "DE" },
      Ebonyi: { code: "EB" },
      Edo: { code: "ED" },
      Ekiti: { code: "EK" },
      Enugu: { code: "EN" },
      "Abuja Federal Capital Territory": {
        label: "Federal Capital Territory",
        alternate_names: ["Abuja"],
        code: "FC",
      },
      Gombe: { code: "GO" },
      Imo: { code: "IM" },
      Jigawa: { code: "JI" },
      Kaduna: { code: "KD" },
      Kano: { code: "KN" },
      Katsina: { code: "KT" },
      Kebbi: { code: "KE" },
      Kogi: { code: "KO" },
      Kwara: { code: "KW" },
      Lagos: { code: "LA" },
      Nasarawa: { code: "NA" },
      Niger: { code: "NI" },
      Ogun: { code: "OG" },
      Ondo: { code: "ON" },
      Osun: { code: "OS" },
      Oyo: { code: "OY" },
      Plateau: { code: "PL" },
      Rivers: { code: "RI" },
      Sokoto: { code: "SO" },
      Taraba: { code: "TA" },
      Yobe: { code: "YO" },
      Zamfara: { code: "ZA" },
    },
  },
  Niue: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "NU",
    flag_path:
      "/assets/flags/nu-7142fbe1478d82ebd2380fc0fe2acbadc34428bb4abc81776a5438094f7e8ac2.svg",
  },
  "Norfolk Island": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "NF",
    flag_path:
      "/assets/flags/nf-d73d4a2d8abc36919f3710b84e623ad689f3ce7db90ef418e507754240c85cab.svg",
  },
  "North Macedonia": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MK",
    flag_path:
      "/assets/flags/mk-9605dc33ee8848f82ec61c57f7e802f32137e6ca31caa907918f1edc44d9ba47.svg",
  },
  Norway: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "NO",
    flag_path:
      "/assets/flags/no-037c0f21f9ad53589902ddc411e836a94298641b2870ceba0bfac9ffee62352e.svg",
    labels: { address1: "Street and house number" },
  },
  Oman: {
    address_format: { edit: 15, edit_experiment: 20 },
    code: "OM",
    flag_path:
      "/assets/flags/om-808a57ba4c58867066e263520e9d79f90e0c9d460687ab9a370160fb56e1c049.svg",
  },
  Pakistan: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "PK",
    flag_path:
      "/assets/flags/pk-5d1ad05b80296a094512e6edbd43b27a96bef01c0f9852dd540b56debb61d18a.svg",
  },
  "Palestinian Territory, Occupied": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "PS",
    flag_path:
      "/assets/flags/ps-5ff1dd310f5ee66d301158ab56261ada3af2d99d26073023eaa09d5b8002d0b2.svg",
  },
  Panama: {
    address_format: { edit: 9, edit_experiment: 26 },
    code: "PA",
    flag_path:
      "/assets/flags/pa-ceb96947effb2596e74c37fa80a049626b71dee595bf21ba5754ac103ac4c476.svg",
    provinces: {
      "Bocas del Toro": { code: "PA-1" },
      Chiriquí: { alternate_names: ["Chiriqui"], code: "PA-4" },
      Coclé: { alternate_names: ["Cocle"], code: "PA-2" },
      Colón: { alternate_names: ["Colon"], code: "PA-3" },
      Darién: { alternate_names: ["Darien"], code: "PA-5" },
      Emberá: { alternate_names: ["Embera"], code: "PA-EM" },
      "Kuna Yala": { label: "Guna Yala", code: "PA-KY" },
      Herrera: { code: "PA-6" },
      "Los Santos": { code: "PA-7" },
      "Ngöbe-Buglé": { alternate_names: ["Ngobe-Bugle"], code: "PA-NB" },
      Panamá: { alternate_names: ["Panama"], code: "PA-8" },
      Veraguas: { code: "PA-9" },
      "Panamá Oeste": {
        label: "West Panamá",
        alternate_names: ["Panama Oeste"],
        code: "PA-10",
      },
    },
  },
  "Papua New Guinea": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "PG",
    flag_path:
      "/assets/flags/pg-647ab0c92d7f9f6a629d99658f5d956c8424d50eca562d4d33bf2911aa5c13f4.svg",
  },
  Paraguay: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "PY",
    flag_path:
      "/assets/flags/py-67ce02f858a28ad984018f57b2080ee3b45f62ee9fba1a7ffead412ccbd6c5be.svg",
  },
  Peru: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "PE",
    flag_path:
      "/assets/flags/pe-0e5601aa50fdf7ab9f060114fd66def0f9b37b04177c5d043bb8fe46bef7f90a.svg",
    provinces: {
      Amazonas: { code: "PE-AMA" },
      Áncash: {
        label: "Ancash",
        alternate_names: ["Ancash", "Ancash"],
        code: "PE-ANC",
      },
      Apurímac: { alternate_names: ["Apurimac", "Apurimac"], code: "PE-APU" },
      Arequipa: { code: "PE-ARE" },
      Ayacucho: { code: "PE-AYA" },
      Cajamarca: { code: "PE-CAJ" },
      Cuzco: { label: "Cusco", code: "PE-CUS" },
      Callao: { label: "El Callao", code: "PE-CAL" },
      Huancavelica: { code: "PE-HUV" },
      Huánuco: { alternate_names: ["Huanuco", "Huanuco"], code: "PE-HUC" },
      Ica: { code: "PE-ICA" },
      Junín: { alternate_names: ["Junin", "Junin"], code: "PE-JUN" },
      "La Libertad": { code: "PE-LAL" },
      Lambayeque: { code: "PE-LAM" },
      "Lima (provincia)": { label: "Lima", code: "PE-LMA" },
      "Lima (departamento)": { label: "Lima Region", code: "PE-LIM" },
      Loreto: { code: "PE-LOR" },
      "Madre de Dios": { code: "PE-MDD" },
      Moquegua: { code: "PE-MOQ" },
      Pasco: { code: "PE-PAS" },
      Piura: { code: "PE-PIU" },
      Puno: { code: "PE-PUN" },
      "San Martín": {
        alternate_names: ["San Martin", "San Martin"],
        code: "PE-SAM",
      },
      Tacna: { code: "PE-TAC" },
      Tumbes: { code: "PE-TUM" },
      Ucayali: { code: "PE-UCA" },
    },
  },
  Philippines: {
    address_format: { edit: 5, edit_experiment: 24 },
    code: "PH",
    flag_path:
      "/assets/flags/ph-b4958dc8963871a24b0ca6ddec26c436eeee34c0bf34e8e14f9717c708a2c606.svg",
    provinces: {
      Abra: { code: "PH-ABR" },
      "Agusan del Norte": { code: "PH-AGN" },
      "Agusan del Sur": { code: "PH-AGS" },
      Aklan: { code: "PH-AKL" },
      Albay: { code: "PH-ALB" },
      Antique: { code: "PH-ANT" },
      Apayao: { code: "PH-APA" },
      Aurora: { code: "PH-AUR" },
      Basilan: { code: "PH-BAS" },
      Bataan: { code: "PH-BAN" },
      Batanes: { code: "PH-BTN" },
      Batangas: { code: "PH-BTG" },
      Benguet: { code: "PH-BEN" },
      Biliran: { code: "PH-BIL" },
      Bohol: { code: "PH-BOH" },
      Bukidnon: { code: "PH-BUK" },
      Bulacan: { code: "PH-BUL" },
      Cagayan: { code: "PH-CAG" },
      "Camarines Norte": { code: "PH-CAN" },
      "Camarines Sur": { code: "PH-CAS" },
      Camiguin: { code: "PH-CAM" },
      Capiz: { code: "PH-CAP" },
      Catanduanes: { code: "PH-CAT" },
      Cavite: { code: "PH-CAV" },
      Cebu: { code: "PH-CEB" },
      "Davao de Oro": { label: "Compostela Valley", code: "PH-COM" },
      Cotabato: { code: "PH-NCO" },
      "Davao Occidental": { code: "PH-DVO" },
      "Davao Oriental": { code: "PH-DAO" },
      "Davao del Norte": { code: "PH-DAV" },
      "Davao del Sur": { code: "PH-DAS" },
      "Dinagat Islands": { code: "PH-DIN" },
      "Eastern Samar": { code: "PH-EAS" },
      Guimaras: { code: "PH-GUI" },
      Ifugao: { code: "PH-IFU" },
      "Ilocos Norte": { code: "PH-ILN" },
      "Ilocos Sur": { code: "PH-ILS" },
      Iloilo: { code: "PH-ILI" },
      Isabela: { code: "PH-ISA" },
      Kalinga: { code: "PH-KAL" },
      "La Union": { code: "PH-LUN" },
      Laguna: { code: "PH-LAG" },
      "Lanao del Norte": { code: "PH-LAN" },
      "Lanao del Sur": { code: "PH-LAS" },
      Leyte: { code: "PH-LEY" },
      Maguindanao: { code: "PH-MAG" },
      Marinduque: { code: "PH-MAD" },
      Masbate: { code: "PH-MAS" },
      "Metro Manila": { code: "PH-00" },
      "Misamis Occidental": { code: "PH-MSC" },
      "Misamis Oriental": { code: "PH-MSR" },
      "Mountain Province": { label: "Mountain", code: "PH-MOU" },
      "Negros Occidental": { code: "PH-NEC" },
      "Negros Oriental": { code: "PH-NER" },
      "Northern Samar": { code: "PH-NSA" },
      "Nueva Ecija": { code: "PH-NUE" },
      "Nueva Vizcaya": { code: "PH-NUV" },
      "Occidental Mindoro": { code: "PH-MDC" },
      "Oriental Mindoro": { code: "PH-MDR" },
      Palawan: { code: "PH-PLW" },
      Pampanga: { code: "PH-PAM" },
      Pangasinan: { code: "PH-PAN" },
      Quezon: { code: "PH-QUE" },
      Quirino: { code: "PH-QUI" },
      Rizal: { code: "PH-RIZ" },
      Romblon: { code: "PH-ROM" },
      Samar: { code: "PH-WSA" },
      Sarangani: { code: "PH-SAR" },
      Siquijor: { code: "PH-SIG" },
      Sorsogon: { code: "PH-SOR" },
      "South Cotabato": { code: "PH-SCO" },
      "Southern Leyte": { code: "PH-SLE" },
      "Sultan Kudarat": { code: "PH-SUK" },
      Sulu: { code: "PH-SLU" },
      "Surigao del Norte": { code: "PH-SUN" },
      "Surigao del Sur": { code: "PH-SUR" },
      Tarlac: { code: "PH-TAR" },
      "Tawi-Tawi": { code: "PH-TAW" },
      Zambales: { code: "PH-ZMB" },
      "Zamboanga Sibugay": { code: "PH-ZSI" },
      "Zamboanga del Norte": { code: "PH-ZAN" },
      "Zamboanga del Sur": { code: "PH-ZAS" },
    },
  },
  Pitcairn: {
    address_format: { edit: 16, edit_experiment: 25 },
    code: "PN",
    flag_path:
      "/assets/flags/pn-2bcc3ca847019caa228a5819854684d463d2b60f25359dd49ceb47dc66f9ab28.svg",
  },
  Poland: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "PL",
    flag_path:
      "/assets/flags/pl-ca69810c60aacd2e0d17005df7ea4a2318fafa7385b580e33cdfbdb16fc3e2f1.svg",
  },
  Portugal: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "PT",
    flag_path:
      "/assets/flags/pt-6607776592531b9043b8733f61f360acee1731a1ae6b797583ec6f4b3b2843dc.svg",
    provinces: {
      Aveiro: { code: "PT-01" },
      Açores: { label: "Azores", alternate_names: ["Acores"], code: "PT-20" },
      Beja: { code: "PT-02" },
      Braga: { code: "PT-03" },
      Bragança: { alternate_names: ["Braganca"], code: "PT-04" },
      "Castelo Branco": { code: "PT-05" },
      Coimbra: { code: "PT-06" },
      Évora: { alternate_names: ["Evora"], code: "PT-07" },
      Faro: { code: "PT-08" },
      Guarda: { code: "PT-09" },
      Leiria: { code: "PT-10" },
      Lisboa: { label: "Lisbon", code: "PT-11" },
      Madeira: { code: "PT-30" },
      Portalegre: { code: "PT-12" },
      Porto: { code: "PT-13" },
      Santarém: { alternate_names: ["Santarem"], code: "PT-14" },
      Setúbal: { alternate_names: ["Setubal"], code: "PT-15" },
      "Viana do Castelo": { code: "PT-16" },
      "Vila Real": { code: "PT-17" },
      Viseu: { code: "PT-18" },
    },
  },
  Qatar: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "QA",
    flag_path:
      "/assets/flags/qa-8ee0871f5fa6f32717beeef3b9a58d4e45c34583ee2934ee752b803fa88456b9.svg",
  },
  "Republic of Cameroon": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "CM",
    flag_path:
      "/assets/flags/cm-f9fdc4bee29cf5536258eeeb8ad6be79b014a96724a24faa7edb3d5b240cc682.svg",
  },
  Reunion: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "RE",
    flag_path:
      "/assets/flags/re-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg",
  },
  Romania: {
    address_format: { edit: 3, edit_experiment: 22 },
    code: "RO",
    flag_path:
      "/assets/flags/ro-15755637dd265e88f60a53a6e62d8daf8ad893dfa4e42f51085b9c778f0d2e91.svg",
    labels: { province: "County" },
    provinces: {
      Alba: { code: "AB" },
      Arad: { code: "AR" },
      Argeș: { alternate_names: ["Arge?"], code: "AG" },
      Bacău: { alternate_names: ["Bacau"], code: "BC" },
      Bihor: { code: "BH" },
      "Bistrița-Năsăud": {
        label: "Bistriţa-Năsăud",
        alternate_names: ["Bistri?a-Nasaud"],
        code: "BN",
      },
      Botoșani: { label: "Botoşani", alternate_names: ["Boto?ani"], code: "BT" },
      Brăila: { alternate_names: ["Braila"], code: "BR" },
      Brașov: { label: "Braşov", alternate_names: ["Bra?ov"], code: "BV" },
      București: {
        label: "Bucharest",
        alternate_names: ["Bucure?ti"],
        code: "B",
      },
      Buzău: { alternate_names: ["Buzau"], code: "BZ" },
      "Caraș-Severin": { alternate_names: ["Cara?-Severin"], code: "CS" },
      Cluj: { code: "CJ" },
      Constanța: { alternate_names: ["Constan?a"], code: "CT" },
      Covasna: { code: "CV" },
      Călărași: { alternate_names: ["Calara?i"], code: "CL" },
      Dolj: { code: "DJ" },
      Dâmbovița: { alternate_names: ["Dambovi?a"], code: "DB" },
      Galați: { alternate_names: ["Gala?i"], code: "GL" },
      Giurgiu: { code: "GR" },
      Gorj: { code: "GJ" },
      Harghita: { code: "HR" },
      Hunedoara: { code: "HD" },
      Ialomița: { alternate_names: ["Ialomi?a"], code: "IL" },
      Iași: { alternate_names: ["Ia?i"], code: "IS" },
      Ilfov: { code: "IF" },
      Maramureș: {
        label: "Maramureş",
        alternate_names: ["Maramure?"],
        code: "MM",
      },
      Mehedinți: { alternate_names: ["Mehedin?i"], code: "MH" },
      Mureș: { label: "Mureş", alternate_names: ["Mure?"], code: "MS" },
      Neamț: { label: "Neamţ", alternate_names: ["Neam?"], code: "NT" },
      Olt: { code: "OT" },
      Prahova: { code: "PH" },
      Sălaj: { alternate_names: ["Salaj"], code: "SJ" },
      "Satu Mare": { code: "SM" },
      Sibiu: { code: "SB" },
      Suceava: { code: "SV" },
      Teleorman: { code: "TR" },
      Timiș: { alternate_names: ["Timi?"], code: "TM" },
      Tulcea: { code: "TL" },
      Vâlcea: { alternate_names: ["Valcea"], code: "VL" },
      Vaslui: { code: "VS" },
      Vrancea: { code: "VN" },
    },
  },
  Russia: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "RU",
    flag_path:
      "/assets/flags/ru-6963edca3433f981a1b431600ddee98cc8451da01629c9bf394835a859291bf1.svg",
    provinces: {
      "Republic of Adygeya": { label: "Adygea", code: "AD" },
      "Altai Republic": { label: "Altai", code: "AL" },
      "Altai Krai": { code: "ALT" },
      "Amur Oblast": { label: "Amur", code: "AMU" },
      "Arkhangelsk Oblast": { label: "Arkhangelsk", code: "ARK" },
      "Astrakhan Oblast": { label: "Astrakhan", code: "AST" },
      "Republic of Bashkortostan": { label: "Bashkortostan", code: "BA" },
      "Belgorod Oblast": { label: "Belgorod", code: "BEL" },
      "Bryansk Oblast": { label: "Bryansk", code: "BRY" },
      "Republic of Buryatia": { label: "Buryat", code: "BU" },
      "Chechen Republic": { label: "Chechen", code: "CE" },
      "Chelyabinsk Oblast": { label: "Chelyabinsk", code: "CHE" },
      "Chukotka Autonomous Okrug": { label: "Chukotka Okrug", code: "CHU" },
      "Chuvash Republic": { label: "Chuvash", code: "CU" },
      "Republic of Dagestan": { label: "Dagestan", code: "DA" },
      "Republic of Ingushetia": { label: "Ingushetia", code: "IN" },
      "Irkutsk Oblast": { label: "Irkutsk", code: "IRK" },
      "Ivanovo Oblast": { label: "Ivanovo", code: "IVA" },
      "Jewish Autonomous Oblast": { label: "Jewish", code: "YEV" },
      "Kabardino-Balkarian Republic": { label: "Kabardino-Balkar", code: "KB" },
      "Kaliningrad Oblast": { label: "Kaliningrad", code: "KGD" },
      "Republic of Kalmykia": { label: "Kalmykia", code: "KL" },
      "Kaluga Oblast": { label: "Kaluga", code: "KLU" },
      "Kamchatka Krai": { code: "KAM" },
      "Karachay–Cherkess Republic": {
        label: "Karachay-Cherkess",
        alternate_names: ["Karachay?Cherkess Republic"],
        code: "KC",
      },
      "Republic of Karelia": { label: "Karelia", code: "KR" },
      "Kemerovo Oblast": { label: "Kemerovo", code: "KEM" },
      "Khabarovsk Krai": { code: "KHA" },
      "Republic of Khakassia": { label: "Khakassia", code: "KK" },
      "Khanty-Mansi Autonomous Okrug": { label: "Khanty-Mansi", code: "KHM" },
      "Kirov Oblast": { label: "Kirov", code: "KIR" },
      "Komi Republic": { label: "Komi", code: "KO" },
      "Kostroma Oblast": { label: "Kostroma", code: "KOS" },
      "Krasnodar Krai": { code: "KDA" },
      "Krasnoyarsk Krai": { code: "KYA" },
      "Kurgan Oblast": { label: "Kurgan", code: "KGN" },
      "Kursk Oblast": { label: "Kursk", code: "KRS" },
      "Leningrad Oblast": { label: "Leningrad", code: "LEN" },
      "Lipetsk Oblast": { label: "Lipetsk", code: "LIP" },
      "Magadan Oblast": { label: "Magadan", code: "MAG" },
      "Mari El Republic": { label: "Mari El", code: "ME" },
      "Republic of Mordovia": { label: "Mordovia", code: "MO" },
      Moscow: { code: "MOW" },
      "Moscow Oblast": { label: "Moscow Province", code: "MOS" },
      "Murmansk Oblast": { label: "Murmansk", code: "MUR" },
      "Nizhny Novgorod Oblast": { label: "Nizhny Novgorod", code: "NIZ" },
      "Republic of North Ossetia–Alania": {
        label: "North Ossetia-Alania",
        alternate_names: ["Republic of North Ossetia?Alania"],
        code: "SE",
      },
      "Novgorod Oblast": { label: "Novgorod", code: "NGR" },
      "Novosibirsk Oblast": { label: "Novosibirsk", code: "NVS" },
      "Omsk Oblast": { label: "Omsk", code: "OMS" },
      "Orenburg Oblast": { label: "Orenburg", code: "ORE" },
      "Oryol Oblast": { label: "Oryol", code: "ORL" },
      "Penza Oblast": { label: "Penza", code: "PNZ" },
      "Perm Krai": { code: "PER" },
      "Primorsky Krai": { code: "PRI" },
      "Pskov Oblast": { label: "Pskov", code: "PSK" },
      "Rostov Oblast": { label: "Rostov", code: "ROS" },
      "Ryazan Oblast": { label: "Ryazan", code: "RYA" },
      "Saint Petersburg": { code: "SPE" },
      "Sakha Republic (Yakutia)": { label: "Sakha", code: "SA" },
      "Sakhalin Oblast": { label: "Sakhalin", code: "SAK" },
      "Samara Oblast": { label: "Samara", code: "SAM" },
      "Saratov Oblast": { label: "Saratov", code: "SAR" },
      "Smolensk Oblast": { label: "Smolensk", code: "SMO" },
      "Stavropol Krai": { code: "STA" },
      "Sverdlovsk Oblast": { label: "Sverdlovsk", code: "SVE" },
      "Tambov Oblast": { label: "Tambov", code: "TAM" },
      "Republic of Tatarstan": { label: "Tatarstan", code: "TA" },
      "Tomsk Oblast": { label: "Tomsk", code: "TOM" },
      "Tula Oblast": { label: "Tula", code: "TUL" },
      "Tyva Republic": { label: "Tuva", code: "TY" },
      "Tver Oblast": { label: "Tver", code: "TVE" },
      "Tyumen Oblast": { label: "Tyumen", code: "TYU" },
      Udmurtia: { label: "Udmurt", code: "UD" },
      "Ulyanovsk Oblast": { label: "Ulyanovsk", code: "ULY" },
      "Vladimir Oblast": { label: "Vladimir", code: "VLA" },
      "Volgograd Oblast": { label: "Volgograd", code: "VGG" },
      "Vologda Oblast": { label: "Vologda", code: "VLG" },
      "Voronezh Oblast": { label: "Voronezh", code: "VOR" },
      "Yamalo-Nenets Autonomous Okrug": {
        label: "Yamalo-Nenets Okrug",
        code: "YAN",
      },
      "Yaroslavl Oblast": { label: "Yaroslavl", code: "YAR" },
      "Zabaykalsky Krai": { code: "ZAB" },
    },
  },
  Rwanda: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "RW",
    flag_path:
      "/assets/flags/rw-b754c3c7705c8fe8778dadfa53d699fe8225b070aa6d698e10ce2e14cb0ed9c9.svg",
  },
  "Saint Barthélemy": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "BL",
    flag_path:
      "/assets/flags/bl-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg",
  },
  "Saint Helena": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SH",
    flag_path:
      "/assets/flags/sh-552b60ab5b2ab564591cf7591ec641e8eb071331603154e72da5f5ab80f2784a.svg",
  },
  "Saint Kitts And Nevis": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "KN",
    flag_path:
      "/assets/flags/kn-0694b6730f80c2ec3f9ca57a83238f58185dd35b1172a2d3880820fd0d0b204d.svg",
  },
  "Saint Lucia": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LC",
    flag_path:
      "/assets/flags/lc-bc26d1a97a4c2c573e0410dc094fd332dc750579895013c640bed592ab2d768c.svg",
  },
  "Saint Martin": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "MF",
    flag_path:
      "/assets/flags/mf-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg",
  },
  "Saint Pierre And Miquelon": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "PM",
    flag_path:
      "/assets/flags/pm-53a8a7d8c53d71036c24aebb45e0192f98cad78b4ea3cfdeb695bd2f9bf2b7f5.svg",
  },
  Samoa: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "WS",
    flag_path:
      "/assets/flags/ws-ed786f3d202d03dc7b6e6231733f2bbf617e7555aacc7013af7fd7d50299ff74.svg",
  },
  "San Marino": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "SM",
    flag_path:
      "/assets/flags/sm-b645203f31e0e6dfd5e43e79d6acf49a95ee8db79d400dd2c701434d1b86e453.svg",
  },
  "Sao Tome And Principe": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "ST",
    flag_path:
      "/assets/flags/st-2a60ae15e8283bf8b4c7cc10010134f9879a190f5d13908b06363d7dafb312bd.svg",
  },
  "Saudi Arabia": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SA",
    flag_path:
      "/assets/flags/sa-fcd2ba12b40a6274fcc752f0706cbdca180c32b54b16aef548b71eaaa08c789f.svg",
  },
  Senegal: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "SN",
    flag_path:
      "/assets/flags/sn-0fb14fcca74b1a937d3a0cc8212cd1aff8e7ea84d57d2e5531e77aea5eb8e5ca.svg",
  },
  Serbia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "RS",
    flag_path:
      "/assets/flags/rs-5787eba463522ac15c429e67c02fd8236d5d54022976e21069bd7cb257aa388b.svg",
  },
  Seychelles: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SC",
    flag_path:
      "/assets/flags/sc-f2daa196b5cd641ce75ba77827f0409ae4e70ce8ef002e63527447faed966ac9.svg",
  },
  "Sierra Leone": {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "SL",
    flag_path:
      "/assets/flags/sl-84063287da3e78722221088294e0712687a1d1253f44a64d7af08eb7f78861f7.svg",
  },
  Singapore: {
    address_format: { edit: 7, edit_experiment: 25 },
    code: "SG",
    flag_path:
      "/assets/flags/sg-5fdb715a18e2ea6f9708451ed8e6ffb76a4217be74140d367764fc2bc095983f.svg",
  },
  "Sint Maarten": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SX",
    flag_path:
      "/assets/flags/sx-46763d9e7c08598233a9704bdd61add3a321e92351a42ef7f27aed769eb91fc3.svg",
  },
  Slovakia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "SK",
    flag_path:
      "/assets/flags/sk-7b588350e9e58c72c948ad3d24c2e748aab007a0e07a7b672073ad3a58d5991c.svg",
  },
  Slovenia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "SI",
    flag_path:
      "/assets/flags/si-8febfd67858cd45d6bd4c044688021b5bdc4ccbe75decd4d34f0415f9a8c5322.svg",
  },
  "Solomon Islands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SB",
    flag_path:
      "/assets/flags/sb-fc5ef4948d6d695b1bb614a61fba0a3562ad39d49409898509a7e9d98c410f71.svg",
  },
  Somalia: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SO",
    flag_path:
      "/assets/flags/so-2cb90d8922733b823d9cd3de361f63f0d8f61335157978547d9718305c652826.svg",
  },
  "South Africa": {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "ZA",
    flag_path:
      "/assets/flags/za-2bc4ce21e2f155186022d608ae17af208ac7929dae2975c8e8d3fe0567ecf761.svg",
    labels: { address2: "Suburb (optional)", province: "Province" },
    provinces: {
      "Eastern Cape": { code: "EC" },
      "Free State": { code: "FS" },
      Gauteng: { code: "GP" },
      "KwaZulu-Natal": { code: "NL" },
      Limpopo: { code: "LP" },
      Mpumalanga: { code: "MP" },
      "North West": { code: "NW" },
      "Northern Cape": { code: "NC" },
      "Western Cape": { code: "WC" },
    },
  },
  "South Georgia And The South Sandwich Islands": {
    address_format: { edit: 7, edit_experiment: 25 },
    code: "GS",
    flag_path:
      "/assets/flags/gs-cd2b98530322bea8861c74fcfe2d292454ecdb905ec2622387e48bf7d43346b2.svg",
  },
  "South Korea": {
    address_format: { edit: 17, edit_experiment: 31 },
    code: "KR",
    flag_path:
      "/assets/flags/kr-226f316d7ae7a184e23d015e3982bd9d685ac8071fc2ee19906d0f6031489f19.svg",
    labels: { province: "Province" },
    provinces: {
      Busan: { code: "KR-26" },
      Daegu: { code: "KR-27" },
      Daejeon: { code: "KR-30" },
      Gangwon: { code: "KR-42" },
      Gwangju: { label: "Gwangju City", code: "KR-29" },
      Gyeonggi: { code: "KR-41" },
      Incheon: { code: "KR-28" },
      Jeju: { code: "KR-49" },
      Chungbuk: { label: "North Chungcheong", code: "KR-43" },
      Gyeongbuk: { label: "North Gyeongsang", code: "KR-47" },
      Jeonbuk: { label: "North Jeolla", code: "KR-45" },
      Sejong: { code: "KR-50" },
      Seoul: { code: "KR-11" },
      Chungnam: { label: "South Chungcheong", code: "KR-44" },
      Gyeongnam: { label: "South Gyeongsang", code: "KR-48" },
      Jeonnam: { label: "South Jeolla", code: "KR-46" },
      Ulsan: { code: "KR-31" },
    },
  },
  "South Sudan": {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "SS",
    flag_path:
      "/assets/flags/ss-b49e661cf296c152618a52366f684416abf90be360cb507d8c783c7be78647bb.svg",
  },
  Spain: {
    address_format: { edit: 11, edit_experiment: 22 },
    code: "ES",
    flag_path:
      "/assets/flags/es-65865b592449f3b8d056283310c88f3bbfc439a23ff1df1a25c811b07a54f1e8.svg",
    labels: { address1: "Street and house number", province: "Province" },
    provinces: {
      "A Coruña": { alternate_names: ["A Coruna"], code: "C" },
      Álava: { alternate_names: ["Alava", "Araba"], code: "VI" },
      Albacete: { code: "AB" },
      Alicante: { code: "A" },
      Almería: { alternate_names: ["Almeria"], code: "AL" },
      Asturias: {
        label: "Asturias Province",
        alternate_names: ["Principado de Asturias"],
        code: "O",
      },
      Ávila: { alternate_names: ["Avila"], code: "AV" },
      Badajoz: { code: "BA" },
      Balears: {
        label: "Balears Province",
        alternate_names: ["Baleares", "Illes Balears", "Islas Baleares"],
        code: "PM",
      },
      Barcelona: { code: "B" },
      Vizcaya: {
        label: "Biscay",
        alternate_names: ["Biscay", "Bizkaia"],
        code: "BI",
      },
      Burgos: { code: "BU" },
      Cáceres: { alternate_names: ["Caceres"], code: "CC" },
      Cádiz: { alternate_names: ["Cadiz"], code: "CA" },
      Cantabria: { label: "Cantabria Province", code: "S" },
      Castellón: {
        alternate_names: ["Castellon", "Castelló", "Castello"],
        code: "CS",
      },
      Ceuta: { code: "CE" },
      "Ciudad Real": { alternate_names: ["Cdad. Real"], code: "CR" },
      Córdoba: { alternate_names: ["Cordoba", "Cordova"], code: "CO" },
      Cuenca: { code: "CU" },
      Guipúzcoa: {
        label: "Gipuzkoa",
        alternate_names: ["Guipuzcoa", "Gipuzkoa"],
        code: "SS",
      },
      Girona: { alternate_names: ["Gerona"], code: "GI" },
      Granada: { code: "GR" },
      Guadalajara: { code: "GU" },
      Huelva: { code: "H" },
      Huesca: { alternate_names: ["Uesca", "Osca"], code: "HU" },
      Jaén: { alternate_names: ["Jaen"], code: "J" },
      "La Rioja": { label: "La Rioja Province", code: "LO" },
      "Las Palmas": { code: "GC" },
      León: { alternate_names: ["Leon"], code: "LE" },
      Lleida: { alternate_names: ["Lérida", "Lerida"], code: "L" },
      Lugo: { code: "LU" },
      Madrid: {
        label: "Madrid Province",
        alternate_names: ["Comunidad de Madrid", "Community of Madrid"],
        code: "M",
      },
      Málaga: { alternate_names: ["Malaga"], code: "MA" },
      Melilla: { code: "ML" },
      Murcia: {
        alternate_names: ["Región de Murcia", "Region de Murcia"],
        code: "MU",
      },
      Navarra: { alternate_names: ["Nafarroa", "Navarre"], code: "NA" },
      Ourense: { code: "OR" },
      Palencia: { code: "P" },
      Pontevedra: { code: "PO" },
      Salamanca: { code: "SA" },
      "Santa Cruz de Tenerife": { alternate_names: ["Santa Cruz"], code: "TF" },
      Segovia: { code: "SG" },
      Sevilla: { label: "Seville", alternate_names: ["Seville"], code: "SE" },
      Soria: { code: "SO" },
      Tarragona: { code: "T" },
      Teruel: { code: "TE" },
      Toledo: { code: "TO" },
      Valencia: { alternate_names: ["València"], code: "V" },
      Valladolid: { code: "VA" },
      Zamora: { code: "ZA" },
      Zaragoza: { alternate_names: ["Saragossa"], code: "Z" },
    },
  },
  "Sri Lanka": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "LK",
    flag_path:
      "/assets/flags/lk-32c19b7c89621aa502cfa10d9ac68bac79aff958dec7a36577aeb25000114ec1.svg",
  },
  "St. Vincent": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "VC",
    flag_path:
      "/assets/flags/vc-56c6993a134c703097809cafaace9c453980f968219454d41487644bfc865256.svg",
  },
  Sudan: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "SD",
    flag_path:
      "/assets/flags/sd-9fdbc80d9c14c98b5326e45e2e54ee336caa91935f318b6658fa02bd455e28f4.svg",
  },
  Suriname: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SR",
    flag_path:
      "/assets/flags/sr-75625d0edd7b19901ec4f72c023f389fa8b97913bb2cd2fa77819942fc7a4fe2.svg",
  },
  "Svalbard And Jan Mayen": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "SJ",
    flag_path:
      "/assets/flags/sj-0cc92b26396ffd50b4c4e7aaafc1e1bbdf362cb56776721cf33053b936edd1da.svg",
  },
  Sweden: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "SE",
    flag_path:
      "/assets/flags/se-8dd8cf5605b332f5a7abcc1ac83452c8f22ca5befb74160a835f0ffde46bfe81.svg",
    labels: { address1: "Street and house number", city: "City/town" },
  },
  Switzerland: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "CH",
    flag_path:
      "/assets/flags/ch-ac08fa33836b67eb7fbcaad7a0244a685a2dd367555f34de139f668a9f6c7885.svg",
    labels: {
      address1: "Street and house number",
      address2: "Additional address (optional)",
    },
  },
  Syria: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "SY",
    flag_path:
      "/assets/flags/sy-f4d88efb4e76b18f1596ce1153462d530ff46d92bcd7a5e82b45eba279aa8144.svg",
  },
  Taiwan: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "TW",
    flag_path:
      "/assets/flags/tw-526a10ea3b6b5e10f2b4f503cda2934bda6ecd6f6893db9220dd6142cb29f346.svg",
  },
  Tajikistan: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "TJ",
    flag_path:
      "/assets/flags/tj-9dc0cc9585d29dee01fe90ca31a0c577dea714393b1e38b0cba65104a983d820.svg",
  },
  "Tanzania, United Republic Of": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "TZ",
    flag_path:
      "/assets/flags/tz-c3022d5c1c240c83479ffb29f575ba787ccb9e28143daf98cba002a1fb1757d5.svg",
  },
  Thailand: {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "TH",
    flag_path:
      "/assets/flags/th-ffe662e5a63774ffd37330c5a5b08336e788e68148c2f63e9bdc8ab9d98c9f88.svg",
    labels: { province: "Province" },
    provinces: {
      "Amnat Charoen": { code: "TH-37" },
      "Ang Thong": { code: "TH-15" },
      Bangkok: { alternate_names: ["Krung Thep Maha Nakhon"], code: "TH-10" },
      "Bueng Kan": { code: "TH-38" },
      Buriram: {
        label: "Buri Ram",
        alternate_names: ["Buri Ram"],
        code: "TH-31",
      },
      Chachoengsao: { code: "TH-24" },
      "Chai Nat": { code: "TH-18" },
      Chaiyaphum: { code: "TH-36" },
      Chanthaburi: { code: "TH-22" },
      "Chiang Mai": { code: "TH-50" },
      "Chiang Rai": { code: "TH-57" },
      "Chon Buri": { code: "TH-20" },
      Chumphon: { code: "TH-86" },
      Kalasin: { code: "TH-46" },
      "Kamphaeng Phet": { code: "TH-62" },
      Kanchanaburi: { code: "TH-71" },
      "Khon Kaen": { code: "TH-40" },
      Krabi: { code: "TH-81" },
      Lampang: { code: "TH-52" },
      Lamphun: { code: "TH-51" },
      Loei: { code: "TH-42" },
      Lopburi: { alternate_names: ["Lop Buri"], code: "TH-16" },
      "Mae Hong Son": { code: "TH-58" },
      "Maha Sarakham": { code: "TH-44" },
      Mukdahan: { code: "TH-49" },
      "Nakhon Nayok": { code: "TH-26" },
      "Nakhon Pathom": { code: "TH-73" },
      "Nakhon Phanom": { code: "TH-48" },
      "Nakhon Ratchasima": { code: "TH-30" },
      "Nakhon Sawan": { code: "TH-60" },
      "Nakhon Si Thammarat": { code: "TH-80" },
      Nan: { code: "TH-55" },
      Narathiwat: { code: "TH-96" },
      "Nong Bua Lam Phu": { code: "TH-39" },
      "Nong Khai": { code: "TH-43" },
      Nonthaburi: { code: "TH-12" },
      "Pathum Thani": { code: "TH-13" },
      Pattani: { code: "TH-94" },
      Pattaya: { code: "TH-S" },
      Phangnga: { label: "Phang Nga", code: "TH-82" },
      Phatthalung: { code: "TH-93" },
      Phayao: { code: "TH-56" },
      Phetchabun: { code: "TH-67" },
      Phetchaburi: { code: "TH-76" },
      Phichit: { code: "TH-66" },
      Phitsanulok: { code: "TH-65" },
      "Phra Nakhon Si Ayutthaya": { code: "TH-14" },
      Phrae: { code: "TH-54" },
      Phuket: { code: "TH-83" },
      "Prachin Buri": { code: "TH-25" },
      "Prachuap Khiri Khan": { code: "TH-77" },
      Ranong: { code: "TH-85" },
      Ratchaburi: { code: "TH-70" },
      Rayong: { code: "TH-21" },
      "Roi Et": { code: "TH-45" },
      "Sa Kaeo": { code: "TH-27" },
      "Sakon Nakhon": { code: "TH-47" },
      "Samut Prakan": { code: "TH-11" },
      "Samut Sakhon": { code: "TH-74" },
      "Samut Songkhram": { code: "TH-75" },
      Saraburi: { code: "TH-19" },
      Satun: { code: "TH-91" },
      Sisaket: {
        label: "Si Sa Ket",
        alternate_names: ["Si Sa Ket"],
        code: "TH-33",
      },
      "Sing Buri": { code: "TH-17" },
      Songkhla: { code: "TH-90" },
      Sukhothai: { code: "TH-64" },
      "Suphan Buri": { label: "Suphanburi", code: "TH-72" },
      "Surat Thani": { code: "TH-84" },
      Surin: { code: "TH-32" },
      Tak: { code: "TH-63" },
      Trang: { code: "TH-92" },
      Trat: { code: "TH-23" },
      "Ubon Ratchathani": { code: "TH-34" },
      "Udon Thani": { code: "TH-41" },
      "Uthai Thani": { code: "TH-61" },
      Uttaradit: { code: "TH-53" },
      Yala: { code: "TH-95" },
      Yasothon: { code: "TH-35" },
    },
  },
  "Timor Leste": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "TL",
    flag_path:
      "/assets/flags/tl-67d5ac594d36742a7056023b8323cc9ca6d45076973ce3a377b9b2f7be65c227.svg",
  },
  Togo: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "TG",
    flag_path:
      "/assets/flags/tg-3d32336a265064b50f45029e913cca70087811aca9b4043d94c2aa6909ddf403.svg",
  },
  Tokelau: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "TK",
    flag_path:
      "/assets/flags/tk-53658460c1ddd4ecdf442a1eeec862bf1141183559d2603575c2fc7881b29c42.svg",
  },
  Tonga: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "TO",
    flag_path:
      "/assets/flags/to-c281f572c6de0cc1f92136acf977298c7a8472d377a43e8475b4e76cf6a45263.svg",
  },
  "Trinidad and Tobago": {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "TT",
    flag_path:
      "/assets/flags/tt-f7628af308d4b6a5e30370d173ac6b8b1151dfea4415faeaf21965ed19b8b09d.svg",
  },
  "Tristan da Cunha": {
    address_format: { edit: 7, edit_experiment: 25 },
    code: "TA",
    flag_path:
      "/assets/flags/ta-390f7b76dab7f5d1c8693e2fb6eaaa23ea49c51cad8fb40adcf8eafa5704ec3f.svg",
  },
  Tunisia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "TN",
    flag_path:
      "/assets/flags/tn-1d5ad3bb6e975fc7bfc718d6e04fd59da5381b6ea75eced8e14197e897fc1ee9.svg",
  },
  Turkey: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "TR",
    flag_path:
      "/assets/flags/tr-07c4650655213ff4393d7be2563d3e95eb26cdc0f08f208951e8cba9e860d062.svg",
  },
  Turkmenistan: {
    address_format: { edit: 18, edit_experiment: 32 },
    code: "TM",
    flag_path:
      "/assets/flags/tm-343dd2b01707a6539c32ef6b8e6e9c6740465049f507dc4c4ac21eadc3c9fccb.svg",
  },
  "Turks and Caicos Islands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "TC",
    flag_path:
      "/assets/flags/tc-2e92bdaae7481ce4fa86a7252290875e0c552c13a57b1b818278b10e38f796b4.svg",
  },
  Tuvalu: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "TV",
    flag_path:
      "/assets/flags/tv-6c3d868cb703f91a90b338a6b6c4f7aab35314815e1841655edf4f2d805bf014.svg",
  },
  Uganda: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "UG",
    flag_path:
      "/assets/flags/ug-0debf20222e989183ca2349363c9193224982d1d942e4c0b99e40a2c10d32b0f.svg",
  },
  Ukraine: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "UA",
    flag_path:
      "/assets/flags/ua-b58d698f846fbffd708df03df9b6ef4613038935f064539d914e6e4754c4fb6c.svg",
  },
  "United Arab Emirates": {
    address_format: { edit: 9, edit_experiment: 26 },
    code: "AE",
    flag_path:
      "/assets/flags/ae-3cce5239a0c9c329a8f2f0e6f900a7d58e463bd79ff4dd8cc9f2d89e057777d4.svg",
    labels: { province: "Emirate" },
    provinces: {
      "Abu Dhabi": { code: "AZ" },
      Ajman: { code: "AJ" },
      Dubai: { code: "DU" },
      Fujairah: { code: "FU" },
      "Ras al-Khaimah": { code: "RK" },
      Sharjah: { code: "SH" },
      "Umm al-Quwain": { code: "UQ" },
    },
  },
  "United Kingdom": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "GB",
    flag_path:
      "/assets/flags/gb-de46013c87c1d6b0e4804a6bd8be50f2c5f961c5adbc42ba40b8913af33afe75.svg",
    labels: { zip: "Postcode" },
    provinces: {
      "British Forces": { code: "BFP" },
      England: { code: "ENG" },
      "Northern Ireland": { code: "NIR" },
      Scotland: { code: "SCT" },
      Wales: { code: "WLS" },
    },
  },
  "United States": {
    address_format: { edit: 4, edit_experiment: 23 },
    code: "US",
    flag_path:
      "/assets/flags/us-1c64c14fa68916dba409ddf0e38ca5dc8bd262b959a5814ecb6667096b35efa5.svg",
    labels: { province: "State", zip: "ZIP code" },
    provinces: {
      Alabama: { code: "AL" },
      Alaska: { code: "AK" },
      "American Samoa": { code: "AS" },
      Arizona: { code: "AZ" },
      Arkansas: { code: "AR" },
      California: { code: "CA" },
      Colorado: { code: "CO" },
      Connecticut: { code: "CT" },
      Delaware: { code: "DE" },
      Florida: { code: "FL" },
      Georgia: { code: "GA" },
      Guam: { code: "GU" },
      Hawaii: { code: "HI" },
      Idaho: { code: "ID" },
      Illinois: { code: "IL" },
      Indiana: { code: "IN" },
      Iowa: { code: "IA" },
      Kansas: { code: "KS" },
      Kentucky: { code: "KY" },
      Louisiana: { code: "LA" },
      Maine: { code: "ME" },
      "Marshall Islands": { code: "MH" },
      Maryland: { code: "MD" },
      Massachusetts: { code: "MA" },
      Michigan: { code: "MI" },
      "Federated States of Micronesia": { label: "Micronesia", code: "FM" },
      Minnesota: { code: "MN" },
      Mississippi: { code: "MS" },
      Missouri: { code: "MO" },
      Montana: { code: "MT" },
      Nebraska: { code: "NE" },
      Nevada: { code: "NV" },
      "New Hampshire": { code: "NH" },
      "New Jersey": { code: "NJ" },
      "New Mexico": { code: "NM" },
      "New York": { code: "NY" },
      "North Carolina": { code: "NC" },
      "North Dakota": { code: "ND" },
      "Northern Mariana Islands": { code: "MP" },
      Ohio: { code: "OH" },
      Oklahoma: { code: "OK" },
      Oregon: { code: "OR" },
      Palau: { code: "PW" },
      Pennsylvania: { code: "PA" },
      "Puerto Rico": { code: "PR" },
      "Rhode Island": { code: "RI" },
      "South Carolina": { code: "SC" },
      "South Dakota": { code: "SD" },
      Tennessee: { code: "TN" },
      Texas: { code: "TX" },
      "Virgin Islands": { label: "U.S. Virgin Islands", code: "VI" },
      Utah: { code: "UT" },
      Vermont: { code: "VT" },
      Virginia: { code: "VA" },
      Washington: { code: "WA" },
      "District of Columbia": { label: "Washington DC", code: "DC" },
      "West Virginia": { code: "WV" },
      Wisconsin: { code: "WI" },
      Wyoming: { code: "WY" },
      "Armed Forces Americas": { code: "AA" },
      "Armed Forces Europe": { code: "AE" },
      "Armed Forces Pacific": { code: "AP" },
    },
  },
  "United States Minor Outlying Islands": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "UM",
    flag_path:
      "/assets/flags/um-1c64c14fa68916dba409ddf0e38ca5dc8bd262b959a5814ecb6667096b35efa5.svg",
  },
  Uruguay: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "UY",
    flag_path:
      "/assets/flags/uy-ba9858ec07e8aefb8ff27c8398dc213b80fd69379d834546231d48aa62c435bb.svg",
  },
  Uzbekistan: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "UZ",
    flag_path:
      "/assets/flags/uz-0123edd5c4db7c4b1044825299925de140f7929dd91ec3be2764980ee234d134.svg",
  },
  Vanuatu: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "VU",
    flag_path:
      "/assets/flags/vu-cb29e23b988fe42de8c05858f8b7f4609bc9e6b9c96ab7a0b70ae10a4df09e11.svg",
  },
  Venezuela: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "VE",
    flag_path:
      "/assets/flags/ve-94d27d5388c9ecd6afd677573c8eb50eead088ce86d031f19affbb10b8db1ed4.svg",
  },
  Vietnam: {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "VN",
    flag_path:
      "/assets/flags/vn-90fae192a098f9bda964d4c594a32e53c8fc3242f5d6591973bcf959c5c4d8b3.svg",
  },
  "Virgin Islands, British": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "VG",
    flag_path:
      "/assets/flags/vg-c90981b23d2d3856297e5aa445ac5046ef0f03d7ff4242324c327abe5ab79a81.svg",
  },
  "Wallis And Futuna": {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "WF",
    flag_path:
      "/assets/flags/wf-10d09cbc2a8b2219da141bbacc940ec8f45a55b36b0dd8f92d07c6c93331f85f.svg",
  },
  "Western Sahara": {
    address_format: { edit: 0, edit_experiment: 19 },
    code: "EH",
    flag_path:
      "/assets/flags/eh-e5dfc140ba81111d3b3346124cedd198477b2f5a61a7a63bc0c1dacdc64c9dae.svg",
  },
  Yemen: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "YE",
    flag_path:
      "/assets/flags/ye-d9453819872a7de3c717436f9d1a7e6650a3bec2d72bdfc1f581b80cf6bcc278.svg",
  },
  Zambia: {
    address_format: { edit: 1, edit_experiment: 20 },
    code: "ZM",
    flag_path:
      "/assets/flags/zm-873696887f25527b132857132aea373dda7d1de1e02803dcbbe784e382bcf7d8.svg",
  },
  Zimbabwe: {
    address_format: { edit: 2, edit_experiment: 21 },
    code: "ZW",
    flag_path:
      "/assets/flags/zw-22a4ab7855acfb0b92ae062631162e4a3b9944ace38a168eb383644e71a0a863.svg",
  },
} as const;

export type CountryName = keyof typeof countries;

export type CountryCode = (typeof countries)[CountryName]["code"];

type ValueOf<T> = T[keyof T];

type Countries = typeof countries;

export type Country = ValueOf<Countries> & {
  provinces: undefined | Record<string, { code: string }>;
};

const countriesByCode = Object.keys(countries).reduce((acc, val: CountryName) => {
  const country = countries[val];

  return {
    ...acc,
    [country.code]: {
      ...country,
      name: val,
    },
  };
}, {} as unknown as Record<CountryCode, Country>);

export { countries, labels, countriesByCode };
