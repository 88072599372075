import {
  CustomGetTimeEntriesQuery,
  CustomGetTimeEntriesQueryVariables,
  useCustomGetTimeEntriesQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetTimeEntries = ({
  variables,
}: {
  variables: CustomGetTimeEntriesQueryVariables;
}) => {
  const {
    data: graphqlData,
    error,
    loading,
    fetchMore: more,
  } = useCustomGetTimeEntriesQuery({
    variables,
  });

  const getData = useCallback(({ data }: { data: CustomGetTimeEntriesQuery }) => {
    return data?.getTimeEntries?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data: CustomGetTimeEntriesQuery }) => {
    return data?.getTimeEntries?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
      });
    },
    [more]
  );

  return { loading, data, error, getData, getPaginatorInfo, paginatorInfo, fetchMore } as const;
};
