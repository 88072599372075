import MDAlert from "components/MDAlert";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useTransferTrucksTable from "hooks/organization/useTransferTrucksTable";

const Index = () => {
  const { data, loading, error, tableData } = useTransferTrucksTable();

  return (
    <>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={data?.length || 10} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && <DataTable table={tableData} />}
    </>
  );
};

export default Index;
