import React, { useCallback } from "react";
import { useBitumioContext } from "providers/BitumioContext";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import getLocalString from "constants/Localization";

export default function useRemoveFromQueueList() {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();

  const removeFromQueue = useCallback(async (itemId) => {
    try {
      showLoader();
      const response = await fetch(
        import.meta.env.VITE_BACKEND_API_BASE_URL + `/api/quickbooks/${itemId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (response.ok) {
        show({ message: getLocalString("organization.remove-job-from-queue-success") });
        return true;
      }
      const data = await response.json();
      const errorMessage = data?.message ?? "";
      show({
        message: errorMessage || getLocalString("organization.remove-job-from-queue-failure"),
        options: { variant: "error" },
      });
      return false;
    } catch (error) {
      show({
        message: getLocalString("generic.failure"),
        options: { variant: "error" },
      });
      return false;
    } finally {
      hideLoader();
    }
  }, []);

  const removeJobFromQueueList = useCallback(
    (itemId) => {
      return removeFromQueue(itemId);
    },
    [removeFromQueue]
  );

  return [removeJobFromQueueList];
}
