import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { Link } from "react-router-dom";
import { getVerificationResendRoute } from "utils/routing/getRoute";

export default function EmailUnverified({ email }: { email: string }) {
  const resendRoute = getVerificationResendRoute(email);

  return (
    <MDBox>
      <MDTypography textAlign="center">
        Your account is unverified. If you haven't received your verification email, please{" "}
        <MDTypography component={Link} to={resendRoute} fontWeight="bold" color="info" textGradient>
          click here
        </MDTypography>{" "}
        to resend it.
      </MDTypography>
    </MDBox>
  );
}
