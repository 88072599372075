import { Proposal, ProposalProduct } from "generated/graphql";
import React from "react";

export interface ProposalContextValue {
  proposal: Proposal;
  writeProposalProduct: (proposalProduct: ProposalProduct) => void;
  inViewMode: boolean;
}

export const ProposalContext = React.createContext<ProposalContextValue>(
  {} as ProposalContextValue
);

export default ProposalContext;
