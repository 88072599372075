import { MenuItem } from "@mui/material";
import { Proposal } from "generated/graphql";
import { Link } from "react-router-dom";

export default function InfoProposalMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: Proposal["id"];
}) {
  return (
    <MenuItem
      data-test-id="action-info"
      component={Link}
      onClick={closeMenu}
      to={`/proposals/${id}/info`}
    >
      Proposal Info
    </MenuItem>
  );
}
