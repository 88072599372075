import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./styles/global.css";

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";

import { createApolloClient } from "services/apolloclient";

import * as Sentry from "@sentry/react";
import ErrorFallback from "components/ErrorPage";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.25,
  beforeSend(event, hint) {
    const error = hint.originalException || hint.syntheticException;

    // @ts-ignore
    if (error && error.message) {
      // @ts-ignore
      if (error.message.indexOf("_leaflet_pos") > -1) {
        return null;
      }

      // @ts-ignore
      if (error.message.indexOf("Invalid credentials") > -1) {
        return null;
      }

      // @ts-ignore
      if (error.message.indexOf("Turnstile") > -1) {
        return null;
      }
    }

    return event;
  },
});

const apolloClient = createApolloClient();

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={({ error }) => <ErrorFallback error={error} />}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App apolloClient={apolloClient} />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
