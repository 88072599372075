import { GetTimeEntriesSortKey, SortDirection } from "generated/graphql";

export const timeEntriesSortKeyMap = {
  forDate: GetTimeEntriesSortKey.DATE,
  "user.name": GetTimeEntriesSortKey.USER,
  "jobPhase.externalId": GetTimeEntriesSortKey.JOB_PHASE,
  startTime: GetTimeEntriesSortKey.START,
  endTime: GetTimeEntriesSortKey.END,
  timeTrackedSeconds: GetTimeEntriesSortKey.HOURS,
};

export const timeEntriesInitialSortDirectionKey = "desc";
export const timeEntriesInitialSortDirectionValue = SortDirection.DESC;
export const timeEntriesInitialSortKeyKey = "forDate";
export const timeEntriesInitialSortKeyValue = GetTimeEntriesSortKey.DATE;
