import { sentenceCase } from "change-case";
import { Company } from "generated/graphql";
import { assoc, compose, converge, head, map, omit, pick, tail, toPairs } from "ramda";
import { renameKeysWith } from "ramda-adjunct";
import { getFullName } from "utils/people/getFullName";

export const getContactInfo = compose(
  map(converge((label: string, value: string) => ({ label, value }), [head, tail])),
  toPairs,
  renameKeysWith(sentenceCase),
  pick(["title", "name", "role", "phone", "email"]),
  // create Name
  (o: Company["contacts"][number]) => assoc("name", getFullName(o))(o)
);
