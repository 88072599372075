import getLocalString from "constants/Localization";
import { useCustomUpdateJobProjectManagerMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateJobProjectManager() {
  const [mutation, { loading, data, error }] = useCustomUpdateJobProjectManagerMutation();
  const [updateJobProjectManager, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateJobProjectManager",
    },
    {
      successMessage: getLocalString("jobs.update-job-project-manager-success"),
      failureMessage: getLocalString("jobs.update-job-project-manager-failure"),
    }
  );

  return [updateJobProjectManager, context] as const;
}
