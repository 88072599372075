import { Box, Icon, IconButton, Popper } from "@mui/material";
import { isNilOrEmpty } from "ramda-adjunct";
import React from "react";

export default function TableFilter({
  value,
  id,
  children,
}: {
  children: React.ReactNode;
  value: any;
  id: string;
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <div>
      <div>
        <IconButton sx={{ p: 0 }} onClick={handleClick}>
          <Icon fontSize="small">{isNilOrEmpty(value) ? "filter_alt_off" : "filter_alt"}</Icon>
        </IconButton>
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box
          sx={{ borderRadius: "4px", border: "1px solid grey", p: 1, bgcolor: "background.paper" }}
        >
          {children}
        </Box>
      </Popper>
    </div>
  );
}
