import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";
import { useCustomCreateProposalFromOpportunityMutation } from "../../generated/graphql";

export default function useCreateProposalFromOpportunity() {
  const [mutation, { loading, data, error }] = useCustomCreateProposalFromOpportunityMutation();

  const [createProposalFromOpportunity, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "createProposalFromOpportunity",
    },
    {
      successMessage: getLocalString("opportunities.proposal-success"),
      failureMessage: getLocalString("opportunities.proposal-failure"),
    }
  );

  return [createProposalFromOpportunity, context] as const;
}
