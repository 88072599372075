import MDBox from "components/MDBox";
import useLogin from "hooks/signin/useLogin";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodPasswordValidator } from "utils/validations/isValidPassword";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { zodEmailValidator } from "utils/validations/isValidEmail";
import { LoginInput, User } from "generated/graphql";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDPasswordInput from "components/MDPasswordInput/MDPasswordInput";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import { useBitumioContext } from "providers/BitumioContext";
import { useRedirectAfterAuthentication } from "modules/AuthWall/AuthWall";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

const schema = z.object({
  password: zodPasswordValidator,
  email: zodEmailValidator,
});

export default function SignInForm({ email }: { email: LoginInput["email"] }) {
  const [login, { error, loading, success, user }] = useLogin();
  const [{ setSignedInUser }] = useBitumioContext();
  const { handleRedirectAfterSignin } = useRedirectAfterAuthentication();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setFocus,
  } = useForm<LoginInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      email,
      password: "",
    },
  });

  useEffect(() => {
    if (success && user) {
      // It means we have a valid user
      setSignedInUser(user as User);
      // redirect to home
      handleRedirectAfterSignin();
    }
  }, [success, user]);

  useEffect(() => {
    setFocus("password");
  }, [setFocus]);

  return (
    <div data-testid="sign-in-form">
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <MDBox component="form" role="form" onSubmit={handleSubmit(login)}>
        <MDBox mb={2}>
          <MDInput
            inputProps={{ readOnly: true }}
            type="email"
            label="Email"
            variant="standard"
            required
            fullWidth
            {...register("email")}
            error={errors.email}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDPasswordInput
            label="Password"
            variant="standard"
            placeholder="Password"
            fullWidth
            {...register("password")}
            errror={errors.password}
          />
          {errors.password && (
            <MDTypography color="error" variant="caption">
              {errors.password.message}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
            Login
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
}
