import { useBitumioContext } from "providers/BitumioContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import useGetCheckoutUrl from "./useGetCheckoutUrl";
import useGetManageSubscriptionUrl from "./useGetManageSubscriptionUrl";

export default function useSubscriptionUrl() {
  const [{ signedInUser }, { getTrialType }] = useBitumioContext();
  const [
    getManageSubscriptionUrl,
    { loading: loadingManageSubscriptionUrl, error: errorManageSubscriptionUrl },
  ] = useGetManageSubscriptionUrl();
  const [getCheckoutUrl, { loading: loadingCheckoutUrl, error: errorCheckoutUrl }] =
    useGetCheckoutUrl();
  const loading = loadingManageSubscriptionUrl || loadingCheckoutUrl;
  const error = errorManageSubscriptionUrl || errorCheckoutUrl;

  const trialType = useMemo(() => {
    return getTrialType(signedInUser);
  }, []);

  const figureOutUrl = useCallback(async () => {
    // When this page loads lets figure out which url we need
    let url = "";
    if (!signedInUser.organization?.subscribed) {
      // We need to send the user to the checkoutUrl
      url = await getCheckoutUrl();
    } else if (signedInUser.organization?.subscribed && trialType === "generic") {
      url = await getCheckoutUrl();
    } else {
      // We need to send the user to the manage subscription url
      url = await getManageSubscriptionUrl();
    }
    return url;
  }, [getCheckoutUrl, getManageSubscriptionUrl]);
  const label = useMemo(() => {
    return signedInUser?.organization?.subscribed ? "Manage Subscription" : "Subscribe";
  }, [signedInUser]);

  return { label, figureOutUrl, loading, error };
}
