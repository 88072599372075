import {
  CustomGetJobsQuery,
  CustomGetJobsQueryVariables,
  useCustomGetJobsQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetJobs = ({ variables }: { variables: CustomGetJobsQueryVariables }) => {
  const {
    data: graphqlData,
    error,
    loading,
    fetchMore: more,
  } = useCustomGetJobsQuery({
    variables,
  });

  const getData = useCallback(({ data }: { data: CustomGetJobsQuery }) => {
    return data?.getJobs?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data: CustomGetJobsQuery }) => {
    return data?.getJobs?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
      });
    },
    [more]
  );

  return { loading, data, error, getData, getPaginatorInfo, paginatorInfo, fetchMore } as const;
};
