import {
  OrganizationLinkedServiceName,
  useGetQuickbooksIncomeAccountsQuery,
} from "generated/graphql";
import useGetLinkedServiceAuthUrl from "./useGetLinkedServiceAuthUrl";

const useGetQuickbooksIncomeAccounts = () => {
  const [getLinkedServiceAuthUrl] = useGetLinkedServiceAuthUrl();

  const { data, loading, error } = useGetQuickbooksIncomeAccountsQuery({
    onError: (e) => {
      if (e.message === "auth_needed") {
        getLinkedServiceAuthUrl({
          variables: {
            service: OrganizationLinkedServiceName.QUICKBOOKS,
          },
        });
      }
    },
  });

  const incomeAccounts = data?.getQuickbooksIncomeAccounts.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return { incomeAccounts, loading, error };
};

export default useGetQuickbooksIncomeAccounts;
