import { User } from "generated/graphql";
import React, { useCallback, useContext } from "react";

interface BitumioContext {
  signedInUser: User | null;
  setSignedInUser: React.Dispatch<React.SetStateAction<User | null>>;
  signOff: () => void;
  loading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

const BitumioContext = React.createContext<BitumioContext>({
  signedInUser: null,
  setSignedInUser: () => {},
  signOff: () => {},
  loading: true,
  showLoader: () => {},
  hideLoader: () => {},
});

export const useBitumioContext = () => {
  const bitumioContext = useContext(BitumioContext);

  // Check if a user under evaluation is the same as the signed in
  const isSignedInUser = useCallback(({ id }: { id: User["id"] }): boolean => {
    return id === bitumioContext.signedInUser?.id;
  }, []);

  // Evaluate if the user is under a trial, and provide the type
  const getTrialType = useCallback(({ organization }: User): false | TrialType => {
    if (!organization.onTrial) {
      return false;
    }

    return organization.onTrial && organization.subscription ? "normal" : "generic";
  }, []);

  return [bitumioContext, { isSignedInUser, getTrialType }] as const;
};

export default BitumioContext;
