export const Colors = [
  "#F26419",
  "#e91e63",
  "#0094c6",
  "#4CAF50",
  "#fb8c00",
  "#F44335",
  "#344767",
  "#a8f9fa",
  "#000000",
  "#0094C6",
  "#7b809a",
  "#4e50a3",
];
