import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import getLocalString from "constants/Localization";
import { OrganizationDocument } from "generated/graphql";
import { GlobalMessage } from "hooks/notifications/useGlobalMessage";
import useDeleteOrganizationDocument from "hooks/organization/useDeleteOrganizationDocument";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export const getOrganizationDocumentName = (document) => {
  // doing all these covfefe cos of backend bugs where files don't have names
  // or the filenames dont have file extensions, or no period separating the filename and file extension
  const nameToDisplay = !!document.name
    ? document.name?.split(".")
    : document.path.split("/").pop().split(".");
  nameToDisplay.length > 1 && nameToDisplay.pop();
  return nameToDisplay[0];
};

export default function DocumentItem({
  document,
  index,
}: {
  document: OrganizationDocument;
  index: number;
}) {
  const { id } = document;
  const fileSrc = `${import.meta.env.VITE_CDN_BASE_URL}/${document.path}`;

  // doing all these covfefe cos of backend bugs where files don't have names
  // or the filenames dont have file extensions, or no period separating the filename and file extension
  const nameToDisplay = !!document.name
    ? document.name?.split(".")
    : document.path.split("/").pop().split(".");
  nameToDisplay.length > 1 && nameToDisplay.pop();

  const [deleteOrganizationDocument, { isSuccess: deleteSuccess }] =
    useDeleteOrganizationDocument();

  const acceptFn = useCallback(async (): Promise<GlobalMessage> => {
    try {
      const { data } = await deleteOrganizationDocument({ id: id });
      if (deleteSuccess(data)) {
        return { message: getLocalString("organization.delete-document-success") };
      }
    } catch (e) {
      return {
        message: e.message ?? getLocalString("organization.delete-document-failure"),
        options: { variant: "error" },
      };
    }
  }, [deleteOrganizationDocument, id]);

  const cancelFn = useCallback(async () => {
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <MDBox>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you want to delete this document?"
      />
      <Grid container spacing={3} alignItems="center">
        {/* {document.name && (
          <Grid item xs={4} lg={4}>
            <MDTypography variant="body">{document.name}</MDTypography>
          </Grid>
        )}
         */}
        <Grid item xs={6} lg={6}>
          <a href={fileSrc} target="_blank" rel="noreferrer">
            <MDTypography variant="body">{getOrganizationDocumentName(document)}</MDTypography>
          </a>
        </Grid>
        <Grid item xs={2} lg={2} color="negative">
          <DeleteIcon onClick={() => setOpen(true)} color="inherit" />
        </Grid>
      </Grid>
    </MDBox>
  );
}
