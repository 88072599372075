import React from "react";
import { SvgIcon, Link } from "@mui/material";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ViewProposalIcon from "../../../../assets/images/view-proposal.svg";

const ViewProposalButton = ({ proposal }) => {
  const href = `${import.meta.env.VITE_PROPOSALS_BASE_URL}/${proposal.uuid}`;

  return (
    <Tooltip title="View Proposal">
      <Link href={href} target="_blank" underline="none">
        <IconButton>
          <img src={ViewProposalIcon} alt="View Proposal" />
        </IconButton>
      </Link>
    </Tooltip>
  );
};

export default ViewProposalButton;
