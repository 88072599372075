import getLocalString from "constants/Localization";
import { useCreateEmployeeMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreateEmployee() {
  const [mutation, { loading, data, error }] = useCreateEmployeeMutation({
    refetchQueries: ["getEmployees", "customGetEmployees"],
  });
  const [updateJobNotes, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createEmployee",
    },
    {
      successMessage: getLocalString("organization.create-employee-success"),
      failureMessage: getLocalString("organization.create-employee-failure"),
    }
  );

  return [updateJobNotes, context] as const;
}
