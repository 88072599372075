import React, { useCallback } from "react";
import { useBitumioContext } from "providers/BitumioContext";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import getLocalString from "constants/Localization";

export default function useLinkQuickbooksDesktop() {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();

  const linkQuickbooksDesktopWithIncomeAccount = useCallback(async (incomeAccount) => {
    try {
      showLoader();
      const response = await fetch(
        import.meta.env.VITE_BACKEND_API_BASE_URL + `/api/quickbooks/link-service`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            income_account: incomeAccount,
          }),
        }
      );

      if (response.ok) {
        show({
          message: getLocalString("organization.create-job-invoice-on-linked-service-success"),
        });
        return true;
      }
      const data = await response.json();
      const errorMessage = data?.message ?? "";
      show({
        message:
          errorMessage ||
          getLocalString("organization.create-job-invoice-on-linked-service-failure"),
        options: { variant: "error" },
      });
      return false;
    } catch (error) {
      show({
        message: getLocalString("generic.failure"),
        options: { variant: "error" },
      });
      return false;
    } finally {
      hideLoader();
    }
  }, []);

  const linkQuickbooksDesktop = useCallback(
    (incomeAccount) => {
      return linkQuickbooksDesktopWithIncomeAccount(incomeAccount);
    },
    [linkQuickbooksDesktopWithIncomeAccount]
  );

  return [linkQuickbooksDesktop];
}
