import { useCustomUnscheduleJobPhaseMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUnscheduleJobPhase() {
  const [mutation, { loading, data, error }] = useCustomUnscheduleJobPhaseMutation({
    refetchQueries: [
      "customGetJobSchedules",
      "customGetJobPhase",
      "getJobSchedules",
      "getJobPhase",
    ],
  });

  const [unscheduleJobPhase, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "unscheduleJobPhase",
    },
    {
      successMessage: "Removed from calendar",
      failureMessage: "Failure to unschedule",
    }
  );

  return [unscheduleJobPhase, context] as const;
}
