import getLocalString from "constants/Localization";
import { Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CreateEquipmentPieceInput, EquipmentPieceChargeType } from "generated/graphql";
import useCreateEquipmentPiece from "hooks/organization/useCreateEquipmentPiece";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Create from "modules/OrganizationEquipmentPieces/Create";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function EquipmentPieceCreatePage() {
  const [createEquipmentPiece, { loading, error, isSuccess, getData }] = useCreateEquipmentPiece();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (values: CreateEquipmentPieceInput) => {
      const formattedInput = {
        ...values,
        rate: Math.floor(values.rate * 100),
        fuelSurcharge: Math.floor(values.fuelSurcharge * 100),
      };
      const result = await createEquipmentPiece({
        variables: { input: formattedInput },
      });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (success) {
        navigate(getRoute("organization.equipment-pieces"));
      }
      return { success, data };
    },
    [createEquipmentPiece]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={12} mb={3}>
        {error && (
          <MDAlert color="error">
            {getLocalString("organization.create-equipment-piece-failure")}
          </MDAlert>
        )}
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Create Equipment Piece
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Create onSubmit={onSubmit} loading={loading} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
