import getLocalString from "constants/Localization";
import {
  useGenerateUploadOrganizationLogoUrlMutation,
  useGenerateUploadProposalFileUrlMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useGenerateUploadOrganizationLogoUrl() {
  const [mutation, { loading, error, data }] = useGenerateUploadOrganizationLogoUrlMutation();
  const [generateUploadOrganizationLogoUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "generateUploadOrganizationLogoUrl",
    },
    {
      hideMessage: true,
    }
  );

  return [generateUploadOrganizationLogoUrl, context] as const;
}
