import tooltipLengthEndAdornment from "constants/tootipLengthEndAdornment";
import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "applicationRate",
    label: "Application Rate in Inches",
    inputProps: { ...tooltipLengthEndAdornment },
  },
  {
    name: "lineWidth",
    label: "Line Width in Inches",
  },
];

export default function StripingForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
