import { GetProjectFileQueryVariables, useGetProjectFileLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetProjectFile = () => {
  const [query, { loading, error, data }] = useGetProjectFileLazyQuery({
    fetchPolicy: "cache-first",
  });

  const graphqlData = useMemo(() => {
    return data?.getProjectFile || null;
  }, [data]);

  const getProjectFile = useCallback(
    ({ id }: GetProjectFileQueryVariables) => {
      query({ variables: { id } });
    },
    [query]
  );

  return [getProjectFile, { loading, error, graphqlData }] as const;
};
