import { zodResolver } from "@hookform/resolvers/zod";
import { OrganizationProductType, ProductProductionRateType, ProductType } from "generated/graphql";
import { FormProvider, useForm } from "react-hook-form";
import {
  baseProductSchemaSubcontracted,
  getDefaultValues,
  getProductCreateSchema,
  concreteProducts,
  requiresPlant,
  baseProductSchemaUnitPrice,
} from "DDD/action-objects/ProductCreate";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { useCallback, useState } from "react";
import ProductFormFields from "./ProductFormFields";
import MDButton from "components/MDButton";
import { useMemo } from "react";

export default function OrganizationProductTypeProductCreateForm({
  onSubmit,
  loading,
  materialCostTypeOptions,
  jobMeasurementMethodOptions,
  labourCostTypeOptions,
  productType,
}: {
  onSubmit: any;
  loading: any;
  materialCostTypeOptions: SelectOptions;
  jobMeasurementMethodOptions: SelectOptions;
  labourCostTypeOptions: SelectOptions;
  productType: OrganizationProductType;
}) {
  const [isSubcontracted, setIsSubcontracted] = useState<boolean>(false);
  const [isUnitPrice, setIsUnitPrice] = useState<boolean>(false);
  const schema = useMemo(() => {
    if (isSubcontracted) {
      return baseProductSchemaSubcontracted;
    }
    if (isUnitPrice) {
      return baseProductSchemaUnitPrice;
    }

    return getProductCreateSchema({ productType: productType.type as ProductType });
  }, [isSubcontracted, isUnitPrice]);

  const productRequiresPlant = requiresPlant.includes(ProductType[productType.type]);
  const isConcreteProduct = concreteProducts.includes(productType.type as ProductType);
  const canBeMeasuredInTons = productRequiresPlant && !isConcreteProduct;

  const methods = useForm<typeof schema>({
    shouldUnregister: false,
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues({
      organizationProductTypeId: productType.id,
      productType: productType.type as ProductType, // TODO: investigate weird type issue
      markupPercentage: productType.markupPercentage,
      ...(canBeMeasuredInTons && { productionRateType: true }),
    }),
  });

  const {
    handleSubmit: zodHandleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = methods;

  const handleSubmit = useCallback(
    async ({ plantId, ...values }) => {
      const input = {
        ...values,
        subcontracted: values.subcontracted ?? false,
        unitPriceProduct: values?.unitPriceProduct ?? false,
        ...(productRequiresPlant && {
          productionRateType: values?.productionRateType
            ? ProductProductionRateType.TONS_PER_HOUR
            : ProductProductionRateType.SQ_FT_SQ_YD_PER_HOUR,
        }),
        equipmentPieces: {
          connect: values?.equipmentPieces?.connect?.map(({ id, quantity }) => ({ id, quantity })),
        },
        additionalCosts: {
          connect: values?.additionalCosts?.connect?.map(({ id, quantity }) => ({ id, quantity })),
        },
      };
      if (isSubcontracted || isUnitPrice) {
        delete input.equipmentPieces;
        delete input.additionalCosts;
      }
      const result = await onSubmit(input);
      if (result.success) {
        reset();
      }
    },
    [isSubcontracted, isUnitPrice]
  );

  return (
    <Card>
      <FormProvider {...methods}>
        <MDBox
          p={3}
          component="form"
          role="form"
          id="product-create-form"
          onSubmit={(e) => {
            // There are forms inside modals that trigger the same event because of the vdom
            // do not submit if it's not for this form
            if ((e.target as HTMLFormElement).id === "product-create-form") {
              zodHandleSubmit(handleSubmit)(e);
            } else {
              e.preventDefault();
            }
          }}
        >
          <ProductFormFields
            action="create"
            materialCostTypeOptions={materialCostTypeOptions}
            jobMeasurementMethodOptions={jobMeasurementMethodOptions}
            labourCostTypeOptions={labourCostTypeOptions}
            productType={productType}
            isSubcontracted={isSubcontracted}
            isUnitPrice={isUnitPrice}
            setIsUnitPrice={setIsUnitPrice}
            setIsSubcontracted={setIsSubcontracted}
            canBeMeasuredInTons={canBeMeasuredInTons}
          />
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <MDButton
              type="submit"
              variant="gradient"
              color="success"
              disabled={loading}
              form="product-create-form"
              name="product-create-form"
            >
              Create Product
            </MDButton>
          </Grid>
        </MDBox>
      </FormProvider>
    </Card>
  );
}
