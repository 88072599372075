import MDButton from "components/MDButton";
import {
  CustomDuplicateProposalMutationVariables,
  Proposal,
  ProposalStatus,
} from "generated/graphql";
import useDuplicateProposal from "hooks/proposals/useDuplicateProposal";
import { useNavigate } from "react-router";
import { RoutesKeys } from "routes";
import { getRoute } from "utils/routing";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DuplicateIcon from "../../../../assets/images/duplicate-proposal.svg";

export default function DuplicateProposal({
  id,
  status,
  variables,
  text,
  normalText,
  useIcon,
  redirectRoute,
}: {
  id: Proposal["id"];
  status: Proposal["status"];
  variables?: Partial<CustomDuplicateProposalMutationVariables>;
  text?: string;
  normalText?: boolean;
  useIcon?: boolean;
  redirectRoute?: RoutesKeys;
}) {
  const [duplicateProposal, { isSuccess, getData }] = useDuplicateProposal();
  const validStatuses = ["DRAFT", "SENT", "CLOSED_WON", "CLOSED_LOST", "CANCELED"];
  const navigate = useNavigate();
  const isDisabled = !validStatuses.includes(status);

  const styles: React.CSSProperties = {
    minWidth: "190px",
    ...(normalText && {
      textTransform: "none",
      fontSize: "0.875rem",
      fontWeight: 400,
      padding: 1.5,
      minWidth: "unset",
    }),
  };

  const variant = normalText ? (useIcon ? "gradient" : "text") : "gradient";

  const handleClick = async () => {
    const result = await duplicateProposal({
      variables: {
        id,
        ...variables,
      },
    });

    if (isSuccess(result.data)) {
      const data = getData(result);
      navigate(getRoute(redirectRoute || "proposals.draft", [["proposalId", data.newProposal.id]]));
    }
  };

  return normalText ? (
    <MDButton
      sx={styles}
      disabled={isDisabled}
      variant={variant}
      color="secondary"
      onClick={handleClick}
    >
      {text || "Duplicate"}
    </MDButton>
  ) : (
    <Tooltip title="Duplicate Proposal">
      <IconButton disabled={isDisabled} onClick={handleClick}>
        <img
          src={DuplicateIcon}
          alt="Duplicate Proposal"
          style={{
            filter: isDisabled ? "invert(70%) hue-rotate(120deg)" : "invert(0%) hue-rotate(120deg)",
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
