import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
} from "@apollo/client";
import { Exact } from "generated/graphql";
import { useCallback, useMemo } from "react";
import useMutationAction, { MessageProps } from "./app/useMutationAction";

interface BitumioMutationProps<T extends Record<string, unknown>, R, K extends string> {
  mutation: (
    options: MutationFunctionOptions<R, Exact<T>, DefaultContext, ApolloCache<any>>
  ) => Promise<FetchResult<R, Record<string, any>, Record<string, any>>>;
  key: K;
  loading: boolean;
  error: ApolloError | null;
  data: R;
}

const useBitumioMutation = <
  T extends Record<string, unknown>,
  R extends Record<K, unknown> | Record<any, unknown>,
  K extends string
>(
  { mutation, data: graphqlData, loading, error, key }: BitumioMutationProps<T, R, K>,
  {
    hideMessage,
    successMessage,
    failureMessage,
    showValidationErrors,
    hideSpecificError,
  }: MessageProps
) => {
  const withMutationAction = useMutationAction({ hideMessage });

  const isSuccess = useCallback((data: R) => {
    return !!data?.[key];
  }, []);

  const getData = useCallback(({ data }: { data?: R }) => {
    return data?.[key] ?? null;
  }, []);

  const connectedMutation = useCallback(
    withMutationAction(
      async (values: { variables: T }) => {
        const result = await mutation(values);
        return result;
      },
      {
        isSuccess,
        successMessage,
        failureMessage,
        showValidationErrors,
        hideSpecificError,
      }
    ),
    [mutation]
  );

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, []);

  return [connectedMutation, { data, loading, error, getData, isSuccess }] as const;
};

export default useBitumioMutation;
