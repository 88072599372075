import { InputAdornment, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const tooltipEndAdornment = {
  InputProps: {
    endAdornment: (
      <Tooltip title="50 Mils = .05 inches" placement="top">
        <InputAdornment position="end" sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}>
          <InfoIcon />
        </InputAdornment>
      </Tooltip>
    ),
  },
};

export default tooltipEndAdornment;
