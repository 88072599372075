import { Link } from "react-router-dom";
import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Index from "modules/OrganizationEquipmentPieces/Index";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import { getRoute } from "utils/routing";

const OrganizationEquipmentPiecesPage = () => {
  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                p={3}
                lineHeight={1}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <MDTypography variant="h4" fontWeight="medium">
                  Organization Equipment Pieces
                </MDTypography>
                <MDTypography as={Link} to={getRoute("organization.create-equipment-piece")}>
                  <MDButton color="blue">
                    <Icon>add</Icon>Create Equipment Piece
                  </MDButton>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Index />
        </Grid>
      </Grid>
    </DashboardPage>
  );
};

export default OrganizationEquipmentPiecesPage;
