import { capitalCase } from "change-case";
import { PlantProductType } from "generated/graphql";

export const mapPlantProductTypeOptions = (key: keyof typeof PlantProductType | string) => {
  switch (key) {
    case "ASPHALT_COMMERCIAL_MIX_1_2":
      return `Asphalt Commercial Mix 1/2"`;
    case "ASPHALT_COMMERCIAL_MIX_3_4":
      return `Asphalt Commercial Mix 3/4"`;
    case "ASPHALT_1_2_APWA_SPEC":
      return `Asphalt 1/2" APWA Spec`;
    case "ASPHALT_3_4_APWA_SPEC":
      return `Asphalt 3/4" APWA Spec`;
    case "ROAD_BASE_1_APWA_SPEC":
      return `Road Base 1" APWA Spec`;
    case "ROAD_BASE_1_2_APWA_SPEC":
      return `Road Base 1/2" APWA Spec`;
    case "ROAD_BASE_3_4_APWA_SPEC":
      return `Road Base 3/4" APWA Spec`;
    case "ROAD_BASE_1":
      return `Road Base 1"`;
    case "ROAD_BASE_1_2":
      return `Road Base 1/2"`;
    case "ROAD_BASE_3_4":
      return `Road Base 3/4"`;
    case "CRUSHED_CONCRETE_1_APWA":
      return `Crushed Concrete 1" APWA`;
    case "RIP_RAP_12_MAX":
      return `Rip Rap 12" Max`;
    case "ROCK_UNWASHED_3_4_OR_1_MAXIMUM":
      return `Rock Unwashed. 3/4" or 1" Maximum`;
    case "ROCK_UNWASHED_1_1_2_MAXIMUM":
      return `Rock Unwashed 1 1/2" Маximum`;
    case "CHIP_WASHED_STANDARD_3_8":
      return `Chip Washed Standard 3/8"`;

    default:
      return capitalCase(key);
  }
};

export const plantProductTypesSelectOptions = Object.keys(PlantProductType).map((key) => ({
  label: mapPlantProductTypeOptions(key as keyof typeof PlantProductType),
  value: PlantProductType[key as keyof typeof PlantProductType].toLowerCase(),
}));
