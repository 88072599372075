import { useMemo } from "react";

import usePaginatedResources from "hooks/usePaginatedResources";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import useGetEmployees from "./useGetEmployees";
import ActionsCell from "modules/OrganizationEmployees/ActionsCell";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { Link } from "react-router-dom";

export default function useEmployeesTable() {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo, fetchMore } = useGetEmployees({
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sort: sortDirectionMap[sorting.sortDirection],
  });
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => (
          <Link
            style={{ color: "inherit" }}
            to={`/organization/employees/${row.original.id}/update`}
          >
            {value}
          </Link>
        ),
      },
      { Header: "Rate", accessor: "rate", Cell: (props) => formatCentsToUSD(props.value) },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ActionsCell,
        align: "right",
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    fetchMore({
      page: offset.page,
    });
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    paginatorInfo,
    filtering,
  } as const;
}
