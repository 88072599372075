import getLocalString from "constants/Localization";
import { useAddProposalEquipmentPiecesMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddProposalEquipmentPieces() {
  const [mutation, { loading, data, error }] = useAddProposalEquipmentPiecesMutation({
    refetchQueries: ["customGetProposal"],
  });
  const [addProposalEquipmentPieces, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "addProposalEquipmentPieces",
    },
    {
      successMessage: getLocalString("proposals.add-proposal-equipment-pieces-success"),
      failureMessage: getLocalString("proposals.add-proposal-equipment-pieces-failure"),
    }
  );

  return [addProposalEquipmentPieces, context] as const;
}
