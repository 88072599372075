import { useCreateTimeEntryMutation } from "generated/graphql";
import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useCreateTimeEntry = () => {
  const [mutation, { loading, error, data }] = useCreateTimeEntryMutation({
    refetchQueries: ["customGetTimeEntries", "getTimeEntries"],
    fetchPolicy: "network-only",
  });

  const [createTimeEntry, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createTimeEntry",
    },
    {
      successMessage: getLocalString("time-tracking.create-time-entry-success"),
      failureMessage: getLocalString("time-tracking.create-time-entry-failure"),
      showValidationErrors: true,
    }
  );

  return [createTimeEntry, context] as const;
};
