import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { Job, OrganizationLinkedServiceName } from "generated/graphql";
import useGetJobLazy from "hooks/jobs/useGetJobLazy";
import useGetOrganization from "hooks/organization/useGetOrganization";
import View from "modules/jobs/View";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function ViewJobPage() {
  const { jobId } = useParams();
  const [getJob, { loading, error, job }] = useGetJobLazy();

  useEffect(() => {
    getJob(jobId);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load job details. Please refresh this page or try again later.
        </MDAlert>
      )}
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Update Job {job && `#${job.externalId}`}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {job && <View job={job as Job} />}
            {!job && <Skeleton />}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
