import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { TransferTruck } from "generated/graphql";
import useGetTransferTrucks from "hooks/organization/useGetTransferTrucks";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function TransferTruckOptions({
  children,
}: {
  children: ({
    transferTruckOptions,
    loading,
    search,
    transferTrucksById,
  }: {
    transferTrucksById: Record<SelectOptions[number]["value"], TransferTruck>;
    transferTruckOptions: SelectOptions;
    loading: boolean;
    search: (v: string) => void;
  }) => React.ReactNode;
}) {
  const { filtering } = usePaginatedVariables();

  const { loading, data, error } = useGetTransferTrucks();

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const transferTrucks = data ?? [];

  const transferTruckOptions = transferTrucks.map((transferTruck) => ({
    //@ts-ignore
    label: transferTruck.name,
    value: transferTruck.id,
  }));

  const transferTrucksById = transferTrucks.reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val,
    };
  }, {});

  return (
    <>
      {children({
        transferTrucksById,
        transferTruckOptions,
        loading,
        search: filtering.setSearchTerm,
      })}
    </>
  );
}
