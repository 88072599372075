import {
  Typography,
  Box,
  Select,
  MenuItem,
  Icon,
  IconButton,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Proposal, Job, Organization, SaveQuickbooksIntegrationInput } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import FormField from "components/FormField/FormField";
import { StyledTableRow } from "modules/jobs/JobsSchedules/JobsSchedules";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import { stripHtml } from "utils/strings/shortenLongDescription";

export default function QuickbooksEditableInformation({
  proposalId,
  job,
  organization,
  onChange,
}: {
  proposalId: Proposal["id"];
  job: Job;
  organization: Organization;
  onChange: (data: SaveQuickbooksIntegrationInput) => void;
}) {
  const initialFormDataInformation: SaveQuickbooksIntegrationInput = {
    customerName: job?.company?.name || "",
    customerPhone: job?.company?.phone || "",
    customerEmail: job?.company?.email || "",
    customerBillingAddressLine1: job?.company?.addressLine1 || "",
    customerBillingAddressLine2: job?.company?.addressLine2 || "",
    customerBillingAddressZip: job?.company?.addressZip || "",
    customerBillingAddressCity: job?.company?.addressCity || "",
    customerBillingAddressState: job?.company?.addressState || "",
    customerBillingAddressCountry: job?.company?.addressCountry || "",
    invoiceNumber: job?.externalId || "",
    taxReference: "",
    jobName: job?.proposalStage?.name || "",
    shipAddressAddressLine1: job?.address?.line1 || "",
    shipAddressAddressLine2: job?.address?.line2 || "",
    shipAddressAddressZip: job?.address?.zip || "",
    shipAddressAddressCity: job?.address?.city || "",
    shipAddressAddressState: job?.address?.state || "",
    items: job?.jobPhases.map((phase) => ({
      itemsName:
        phase?.proposalProduct?.material.externalProductName ??
        phase?.proposalProduct?.material.name ??
        "",
      itemsDescription: phase?.proposalProduct?.statementOfWork
        ? stripHtml(phase?.proposalProduct?.statementOfWork)
        : `Job ID: ${job?.id}, Job Phase ID: ${phase?.id}, Job Name: ${job?.proposalStage?.name}`,
      itemsDescriptionFallback: 
        `Job ID: ${job?.id}, Job Phase ID: ${phase?.id}, Job Name: ${job?.proposalStage?.name}`,
      itemAmount:
        typeof phase?.proposedOverallTotal === "number"
          ? phase.proposedOverallTotal
          : parseFloat(phase.proposedOverallTotal || "0"),
    })),
    totalAmountItems:
      job?.jobPhases.reduce(
        (sum, phase) =>
          sum +
          (typeof phase?.proposedOverallTotal === "number"
            ? phase.proposedOverallTotal
            : parseFloat(phase.proposedOverallTotal || "0")),
        0
      ) || 0,
  };

  const [formFieldsData, setFormFieldsData] = useState<SaveQuickbooksIntegrationInput>(
    initialFormDataInformation
  );
  const salesTaxes = organization?.salesTaxes;

  useEffect(() => {
    onChange(formFieldsData);
  }, [formFieldsData, initialFormDataInformation]);

  const calculateTotalAmountItems = (items) => {
    return items.reduce((sum, item) => sum + parseFloat(item.itemAmount.toString() || "0"), 0);
  };

  const handleFieldDataChange = (key: string, value: string, index?: number) => {
    let newValue = value;
    if (key == "items.itemAmount" && value) {
      newValue = String(parseFloat(value) * 100);
    }
    setFormFieldsData((prevState) => {
      if (index !== undefined && key.startsWith("items.")) {
        const field = key.split(".")[1];
        const newItems = [...prevState.items];
        newItems[index] = {
          ...newItems[index],
          [field]: newValue.length ? newValue : initialFormDataInformation["items"][index][field],
        };
        const total = calculateTotalAmountItems(newItems);
        return {
          ...prevState,
          items: newItems,
          totalAmountItems: total,
        };
      } else {
        return {
          ...prevState,
          [key]: newValue || initialFormDataInformation[key],
        };
      }
    });
  };

  const handleRemove = (index: number) => {
    setFormFieldsData((prevState) => {
      const newItems = prevState.items.filter((_, i) => i !== index);
      const total = calculateTotalAmountItems(newItems);
      initialFormDataInformation.items = newItems;
      return {
        ...prevState,
        items: newItems,
        totalAmountItems: total,
      };
    });
  };

  return (
    <Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow sx={{ backgroundColor: "grey.300" }}>
              <TableCell>Customer Information</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow className={"outer even"}>
              <TableCell sx={{ width: "35%" }}>Customer Name</TableCell>
              <TableCell>
                <FormField
                  placeholder={job?.company?.name}
                  onChange={(e) => handleFieldDataChange("customerName", e.target.value)}
                />
              </TableCell>
            </StyledTableRow>

            <StyledTableRow className={"outer odd"}>
              <TableCell sx={{ width: "35%" }}>Customer Phone</TableCell>
              <TableCell>
                <FormField
                  placeholder={job?.company?.phone}
                  onChange={(e) => handleFieldDataChange("customerPhone", e.target.value)}
                />
              </TableCell>
            </StyledTableRow>

            <StyledTableRow className={"outer even"}>
              <TableCell sx={{ width: "35%" }}>Customer Email</TableCell>
              <TableCell>
                <FormField
                  type="email"
                  placeholder={job?.company?.email}
                  onChange={(e) => handleFieldDataChange("customerEmail", e.target.value)}
                />
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer style={{ marginTop: "20px" }}>
        <Table aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow sx={{ backgroundColor: "grey.300" }}>
              <TableCell>Customer Address</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow className={"outer even"}>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Address Line 1</div>
                  <FormField
                    placeholder={job?.company?.addressLine1}
                    onChange={(e) =>
                      handleFieldDataChange("customerBillingAddressLine1", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Address Line 2</div>
                  <FormField
                    placeholder={job?.company?.addressLine2}
                    onChange={(e) =>
                      handleFieldDataChange("customerBillingAddressLine2", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
            </StyledTableRow>
            <StyledTableRow className={"outer odd"}>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Address City</div>
                  <FormField
                    placeholder={job?.company?.addressCity}
                    onChange={(e) =>
                      handleFieldDataChange("customerBillingAddressCity", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Address Zip</div>
                  <FormField
                    placeholder={job?.company?.addressZip}
                    onChange={(e) =>
                      handleFieldDataChange("customerBillingAddressZip", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
            </StyledTableRow>

            <StyledTableRow className={"outer even"}>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Address State</div>
                  <FormField
                    placeholder={job?.company?.addressState}
                    onChange={(e) =>
                      handleFieldDataChange("customerBillingAddressState", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
              <TableCell></TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer style={{ marginTop: "20px" }}>
        <Table aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow sx={{ backgroundColor: "grey.300" }}>
              <TableCell colSpan={4}>Invoice Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow className={"outer even"}>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Invoice Number</div>
                  <FormField
                    placeholder={job?.externalId || ""}
                    onChange={(e) => handleFieldDataChange("invoiceNumber", e.target.value)}
                    fullWidth
                  />
                </TableCell>
              </TableCell>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Job Name</div>
                  <FormField
                    placeholder={job?.proposalStage?.name}
                    onChange={(e) => handleFieldDataChange("jobName", e.target.value)}
                    fullWidth
                  />
                </TableCell>
              </TableCell>
            </StyledTableRow>

            <StyledTableRow className={"outer odd"}>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Ship Address Line1</div>
                  <FormField
                    placeholder={job?.address?.line1}
                    onChange={(e) =>
                      handleFieldDataChange("shipAddressAddressLine1", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Ship Address Line2</div>
                  <FormField
                    placeholder={job?.address?.line2}
                    onChange={(e) =>
                      handleFieldDataChange("shipAddressAddressLine2", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
            </StyledTableRow>

            <StyledTableRow className={"outer even"}>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Ship Address Zip</div>
                  <FormField
                    placeholder={job?.address?.zip}
                    onChange={(e) => handleFieldDataChange("shipAddressAddressZip", e.target.value)}
                    fullWidth
                  />
                </TableCell>
              </TableCell>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Ship Address City</div>
                  <FormField
                    placeholder={job?.address?.city}
                    onChange={(e) =>
                      handleFieldDataChange("shipAddressAddressCity", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
            </StyledTableRow>

            <StyledTableRow className={"outer odd"}>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Ship Address State</div>
                  <FormField
                    placeholder={job?.address?.state}
                    onChange={(e) =>
                      handleFieldDataChange("shipAddressAddressState", e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
              </TableCell>
              <TableCell>
                <TableCell colSpan={2} sx={{ width: "50%", padding: "16px", borderBottom: "none" }}>
                  <div style={{ color: "rgba(0, 0, 0, 0.87)" }}>Tax Reference</div>
                  <FormControl fullWidth>
                    {formFieldsData.taxReference === "" && (
                      <InputLabel
                        id="sales-taxes-select"
                        style={{ marginLeft: "-1em", color: "#b3b3b3" }}
                      >
                        Please select
                      </InputLabel>
                    )}
                    <Select
                      id="sales-taxes-select"
                      labelId="sales-taxes-select-label"
                      variant="standard"
                      style={{ width: "100%" }}
                      value={formFieldsData.taxReference}
                      onChange={(e) =>
                        handleFieldDataChange("taxReference", e.target.value as string)
                      }
                    >
                      {salesTaxes?.map((taxOption) => (
                        <MenuItem key={taxOption.id} value={taxOption.id}>
                          {taxOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer style={{ marginTop: "20px" }}>
        <Table aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow sx={{ backgroundColor: "grey.300" }}>
              <TableCell sx={{ width: "40%" }}>Item</TableCell>
              <TableCell sx={{ width: "40%" }}>Item Description</TableCell>
              <TableCell sx={{ width: "20%" }}>Item Amount</TableCell>
              <TableCell sx={{ width: "5%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formFieldsData.items.map((item, index) => (
              <StyledTableRow key={index} className={index % 2 === 0 ? "outer even" : "outer odd"}>
                <TableCell>
                  <FormField
                    placeholder={formFieldsData?.items[index]?.itemsName}
                    onChange={(e) =>
                      handleFieldDataChange(`items.itemsName`, e.target.value, index)
                    }
                  />
                </TableCell>
                <TableCell>
                  <FormField
                    placeholder={formFieldsData?.items[index]?.itemsDescription}
                    onChange={(e) => {
                      handleFieldDataChange(
                        "items.itemsDescription",
                        e.target.value as string,
                        index
                      );
                    }}
                  />
                </TableCell>
                <TableCell>
                  <FormField
                    align="right"
                    placeholder={formatCentsToUSD(formFieldsData?.items[index]?.itemAmount) || ""}
                    onChange={(e) =>
                      handleFieldDataChange(`items.itemAmount`, e.target.value, index)
                    }
                  />
                </TableCell>
                <IconButton onClick={() => handleRemove(index)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </StyledTableRow>
            ))}
          </TableBody>

          <StyledTableRow className={job?.jobPhases.length % 2 === 0 ? "outer even" : "outer odd"}>
            <TableCell>
              <Typography variant="body1">Total</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1"></Typography>
            </TableCell>
            <TableCell>
              <FormField
                align="right"
                placeholder={formatCentsToUSD(formFieldsData.totalAmountItems) || ""}
                onChange={(e) => {
                  handleFieldDataChange("totalAmountItems", e.target.value);
                }}
              />
            </TableCell>
            <TableCell sx={{ width: "5%" }}></TableCell>
          </StyledTableRow>
        </Table>
      </TableContainer>
    </Box>
  );
}
