import { Icon } from "@mui/material";
import { UserPermissions } from "generated/graphql";
import AuthWall from "modules/AuthWall/AuthWall";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";
import ProposalCreatePage from "pages/proposals/ProposalCreatePage";
import ProposalInfoPage from "pages/proposals/ProposalInfoPage";
import ProposalDraftPage from "pages/proposals/ProposalsDraftPage";
import ProposalsListPage from "pages/proposals/ProposalsListPage";
import ProposalViewPage from "pages/proposals/ProposalViewPage";

const proposalRoutes = [
  {
    type: "link",
    name: "Proposals",
    key: "proposals",
    route: "/proposals",
    icon: <Icon fontSize="medium">article</Icon>,
    permission: [UserPermissions.VIEW_ALL_PROPOSALS, UserPermissions.VIEW_ONLY_ASSIGNED_PROPOSALS],
    component: (
      <AuthWall
        permission={[
          UserPermissions.VIEW_ALL_PROPOSALS,
          UserPermissions.VIEW_ONLY_ASSIGNED_PROPOSALS,
        ]}
      >
        <AuthWallSubscriber>
          <ProposalsListPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Info",
    key: "proposals.info",
    route: "/proposals/:proposalId/info",
    icon: <Icon fontSize="medium"></Icon>,
    permission: UserPermissions.MANAGE_PROPOSALS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PROPOSALS}>
        <AuthWallSubscriber>
          <ProposalInfoPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "List",
    key: "proposals.list",
    route: "/proposals",
    icon: <Icon fontSize="medium"></Icon>,
    permission: [UserPermissions.VIEW_ALL_PROPOSALS, UserPermissions.VIEW_ONLY_ASSIGNED_PROPOSALS],
    component: (
      <AuthWall
        permission={[
          UserPermissions.VIEW_ALL_PROPOSALS,
          UserPermissions.VIEW_ONLY_ASSIGNED_PROPOSALS,
        ]}
      >
        <AuthWallSubscriber>
          <ProposalsListPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Create",
    key: "proposals.create",
    route: "/proposals/create",
    permission: UserPermissions.MANAGE_PROPOSALS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PROPOSALS}>
        <AuthWallSubscriber>
          <ProposalCreatePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Draft",
    key: "proposals.draft",
    route: "/proposals/:proposalId/draft",
    permission: UserPermissions.MANAGE_PROPOSALS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PROPOSALS}>
        <AuthWallSubscriber>
          <ProposalDraftPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Proposal View",
    key: "proposals.view",
    route: "/proposals/:proposalId",
    permission: UserPermissions.MANAGE_PROPOSALS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PROPOSALS}>
        <AuthWallSubscriber>
          <ProposalViewPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
] as const;

export default proposalRoutes;
