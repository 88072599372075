import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { UserPermissions } from "generated/graphql";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import ListProposals from "modules/proposals/ListProposals";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function ProposalsListPage() {
  const { hasPermissions } = useCheckPermissions();
  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                p={3}
                lineHeight={1}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <MDTypography variant="h4" fontWeight="medium">
                  Proposals
                </MDTypography>
                {hasPermissions(UserPermissions.MANAGE_PROPOSALS) && (
                  <MDTypography as={Link} to={getRoute("proposals.create")}>
                    <MDButton color="blue">
                      <Icon>add</Icon>&nbsp; Create Proposal
                    </MDButton>
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ListProposals />
        </Grid>
      </Grid>
    </DashboardPage>
  );
}
