import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Company } from "generated/graphql";
import { useGetCompany } from "hooks/companies/useGetCompany";
import React from "react";

const MergeSourceCompany = ({ id }: { id: Company["id"] }) => {
  const { loading, error, data: company } = useGetCompany({ id });

  if (loading) {
    return (
      <MDBox display="flex" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
      </MDBox>
    );
  }

  return (
    <div>
      <MDBox display="flex" flexDirection="column">
        <MDBox display="flex" justifyContent="center" mb={4}>
          <Icon fontSize="large" sx={{ transform: "scale(2)" }}>
            business
          </Icon>
        </MDBox>
        <MDTypography variant="h6" component="h6" py={2}>
          {company.name}
        </MDTypography>
        <MDTypography variant="body2">Proposals: {company.proposalsCount}</MDTypography>
        <MDTypography variant="body2">Jobs: {company.jobsCount}</MDTypography>
      </MDBox>
    </div>
  );
};

export default MergeSourceCompany;
