import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { Company } from "generated/graphql";
import useArchiveCompany from "hooks/companies/useArchiveCompany";
import useGetCompanies from "hooks/companies/useGetCompanies";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export default function ArchiveCompanyMenuItem(props: BitumioMenuItem<Company>) {
  const { closeMenu, id } = props;
  const [archiveCompany, { isSuccess }] = useArchiveCompany();

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const { data } = await archiveCompany({ variables: { id } });
    if (isSuccess(data)) {
      closeMenu();
      return true;
    } else {
      return false;
    }
  }, []);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Are you sure you want to continue?"
        content="This company as well as all of it's contacts, proposals and jobs will be archived. Are you sure you want to continue?"
        acceptText="Archive Company"
      />
      <MenuItem onClick={() => setOpen(true)}>Archive</MenuItem>
    </>
  );
}
