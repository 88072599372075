import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

export default function InvitedEmail() {
  return (
    <MDBox>
      <MDTypography textAlign="center">
        Please accept your invitation to continue. If you haven't received an invitation via email,
        please contact your manager.
      </MDTypography>
    </MDBox>
  );
}
