import { useValidateUserInviteTokenLazyQuery } from "generated/graphql";
import React, { useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import isValidEmail from "utils/validations/isValidEmail";

export default function useValidateUserInviteToken() {
  const [searchParams] = useSearchParams();
  const [validateUserInviteTokenQuery, { data, loading, error }] =
    useValidateUserInviteTokenLazyQuery();
  const [localError, setLocalError] = useState<Error | null>(null);
  const email = useRef<null | string>(null);
  const token = useRef<null | string>(null);

  const validateUserTokenInvite = useCallback(() => {
    email.current = searchParams.get("email");
    token.current = searchParams.get("token");

    if (!email.current || !isValidEmail(email.current) || !token.current) {
      setLocalError(new Error("Missing email or token to validate"));
      return;
    }

    validateUserInviteTokenQuery({
      variables: { email: email.current, inviteToken: token.current },
    });
  }, [validateUserInviteTokenQuery]);

  const success = data && !!data.validateUserInviteToken === true;
  const failure = data && !!data.validateUserInviteToken === false;

  return [
    validateUserTokenInvite,
    {
      data,
      loading,
      error: error || localError,
      success,
      failure,
      email: email.current,
      token: token.current,
    },
  ] as const;
}
