import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { useMemo } from "react";
import useGetDataUploads from "./useGetDataUploads";
import organizationDataImportTypeMapper from "../../../constants/organizationDataImportTypeMapper";
import organizationDataImportStatusMapper from "../../../constants/organizationDataImportStatusMapper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export default function useDataUploadsTable() {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo, fetchMore, getData, getPaginatorInfo } =
    useGetDataUploads({
      page: offset.page,
      first: offset.first,
    });
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }) => `${organizationDataImportTypeMapper[value]}`,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => `${organizationDataImportStatusMapper[value]}`,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ value }) => dayjs.utc(value).local().format("MM-DD-YYYY h:mm A"),
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        Cell: ({ row, value }) =>
          row.original.user ? `${row.original.user.firstName} ${row.original.user.lastName}` : "",
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
  } as const;
}
