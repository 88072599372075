import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function Title({ text }: { text: string }) {
  return (
    <MDBox bgColor="#f5f5f5" py={2} px={4}>
      <MDTypography variant="h4">{text}</MDTypography>
    </MDBox>
  );
}
