import getLocalString from "constants/Localization";
import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";
import { OrganizationProductType } from "generated/graphql";
import useDeleteOrganizationProductType from "hooks/organization/product-types/useDeleteOrganizationProductType";

export default function Delete({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: OrganizationProductType["id"];
}) {
  const [deleteOrganizationProductType, { isSuccess }] = useDeleteOrganizationProductType();

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const { data } = await deleteOrganizationProductType({ variables: { id } });

    if (isSuccess(data)) {
      closeMenu();
      return true;
    } else {
      return false;
    }
  }, [deleteOrganizationProductType, id]);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content={getLocalString("product-types.delete-confirm")}
      />
      <MenuItem onClick={() => setOpen(true)}>Delete</MenuItem>
    </>
  );
}
