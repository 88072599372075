import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  schema as OpportunityCreateSchema,
  getDefaultValues as createGetDefaultValues,
} from "DDD/action-objects/OpportunityCreate";
import {
  schema as OpportunityUpdateSchema,
  getDefaultValues as updateGetDefaultValues,
} from "DDD/action-objects/OpportunityUpdate";
import {
  Opportunity,
  CreateOpportunityInput,
  UpdateOpportunityInput,
  OpportunitySource,
  CreateCompanyInput,
  CustomCreateProposalFromOpportunityMutationVariables,
  OpportunityStatus,
  UserRole,
  Company,
  UpsertOpportunityProductInput,
  CreateOpportunityProductInput,
} from "generated/graphql";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import CompanyOptions from "../../../modules/companies/CompanyOptions";
import ScrollAsyncAutocomplete from "../../ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import AutoComplete from "../../../modules/AutoComplete/AutoComplete";
import CustomSelect, { SelectOptions } from "../../Shared/CustomSelect/CustomSelect";
import { enumToValueOptions } from "../../../utils/enums/enumToValueOptions";
import OrganizationUsersOptions from "../../../modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import OrganizationProductTypeIdsOptions from "../../../modules/OrganizationProductTypes/OrganizationProductTypeIdsOptions/OrganizationProductTypeIdsOptions";
import WYSIWYG from "../../WYSIWYG/WYSIWYG";
import useCreateCompany from "../../../hooks/companies/useCreateCompany";
import { getAddressObject } from "../../../helpers/map";
import useModalState from "../../../hooks/useModalState";
import Modal from "../../../modules/Modal/Modal";
import CreateCompany from "../../../modules/companies/CreateCompany/CreateCompany";
import useCreateProposalFromOpportunity from "../../../hooks/opportunities/useCreateProposalFromOpportunity";
import { useNavigate } from "react-router";
import { getRoute } from "../../../utils/routing";
import useGetOpportunityStatuses from "hooks/opportunities/useGetOpportunityStatuses";
import useCreateOpportunityStatus from "hooks/opportunities/useCreateOpportunityStatus";
import CreateOpportunityStatus from "../CreateOpportunityStatus";
import { useGetCompanyLazy } from "hooks/companies/useGetCompanyLazy";
import ProjectFileExplorer from "modules/ProjectFiles/ProjectFileExplorer";
import { useGetProjectFile } from "hooks/projectFiles/useGetProjectFile";
import ProjectFileExplorerInput from "modules/ProjectFiles/ProjectFileExplorerInput";
import MDInput from "components/MDInput";
import CustomMultipleSelect from "components/Shared/CustomMultipleSelect/CustomMultipleSelect";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";

type Action = "create" | "upsert";

interface IOpportunityFormProps {
  action: Action;
  opportunity?: Opportunity | null;
  onSubmit: SubmitHandler<any>;
  onSuccess: (data: Opportunity) => void;
  loading: boolean;
}

type FormData = {
  create: CreateOpportunityInput;
  upsert: UpdateOpportunityInput;
};

const schemas = {
  create: OpportunityCreateSchema,
  upsert: OpportunityUpdateSchema,
};

const getDefaultValues = (action: Action) => (initial: Opportunity) => {
  if (action === "create") {
    return createGetDefaultValues(initial);
  } else {
    return updateGetDefaultValues(initial);
  }
};

const OpportunityForm = ({
  action,
  opportunity,
  onSubmit,
  onSuccess,
  loading,
}: IOpportunityFormProps) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const navigate = useNavigate();

  const { open, onClose, onOpen } = useModalState();
  const {
    open: statusModalOpen,
    onClose: onCloseStatusModal,
    onOpen: onOpenStatusModal,
  } = useModalState();
  const {
    open: projectFilesModalOpen,
    onClose: onCloseProjectFilesModal,
    onOpen: onOpenProjectFilesModal,
  } = useModalState();
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [projectFileExplorerOptions, setProjectFileExplorerOptions] = useState({
    folderView: false,
    fileView: false,
    folderId: "",
  });

  const [showAddressFields, setShowAddressFields] = useState<boolean>(action === "upsert");
  const { selectOptions } = useGetOpportunityStatuses();
  const [newlyCreatedCompany, setNewlyCreatedCompany] = useState<Company | null>(null);

  const defaultValues: CreateOpportunityInput | UpdateOpportunityInput = useMemo(() => {
    return getDefaultValues(action)(opportunity);
  }, [opportunity]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<CreateOpportunityInput | UpdateOpportunityInput>({
    defaultValues,
    resolver: zodResolver(schemas[action]),
  });
  const companyId = watch("companyId");
  const contactId = watch("contactId");

  const [getCompany, { company }] = useGetCompanyLazy();

  useEffect(() => {
    getCompany({ id: companyId });
  }, [getCompany, companyId]);

  const [createCompany, { loading: createCompanyLoading, getData, isSuccess }] = useCreateCompany();
  const [
    createOpportunityStatus,
    {
      loading: createOpportunityStatusLoading,
      getData: createOpportunityStatusData,
      isSuccess: createOpportunityStatusSuccess,
    },
  ] = useCreateOpportunityStatus();

  const onCreateCompanySubmit = useCallback(
    async (input: CreateCompanyInput) => {
      const result = await createCompany({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (success) {
        setTriggerRefetch(true);
        setNewlyCreatedCompany(data);
        setValue("companyId", data.id, { shouldDirty: true, shouldTouch: true });
        setValue("contactId", data.contacts?.[0]?.id, { shouldDirty: true, shouldTouch: true });
        setValue("addressLine1", data.addressLine1);
        setValue("addressCity", data.addressCity);
        setValue("addressState", data.addressState);
        setValue("addressCountry", data.addressCountry);
        setValue("addressZip", data.addressZip);
        setShowAddressFields(true);
        onClose();
      }
      return { success, data };
    },
    [createCompany]
  );

  const onCreateOpportunityStatusSubmit = useCallback(
    async (input: { name: string }) => {
      const result = await createOpportunityStatus({ variables: { name: input.name } });
      const success = createOpportunityStatusSuccess(result.data);
      const data = createOpportunityStatusData(result);
      if (success) {
        if (action === "upsert") {
          // @ts-ignore
          setValue("statusId", data.id, { shouldDirty: true, shouldTouch: true });
        }
        onCloseStatusModal();
      }
      return { success, data };
    },
    [createOpportunityStatus]
  );

  const folderId = watch("projectFilesFolderId");

  const [
    createProposalFromOpportunity,
    {
      loading: createProposalFromOpportunityLoading,
      getData: createProposalFromOpportunityGetData,
      isSuccess: createProposalFromOpportunityIsSuccess,
    },
  ] = useCreateProposalFromOpportunity();

  const onCreateProposalFromOpportunitySubmit = useCallback(
    async (input: CustomCreateProposalFromOpportunityMutationVariables) => {
      const result = await createProposalFromOpportunity({
        variables: input,
      });
      const success = createProposalFromOpportunityIsSuccess(result.data);
      const data = createProposalFromOpportunityGetData(result);
      if (isSuccess) {
        navigate(
          `${getRoute("proposals.draft", [["proposalId", data.id]])}?stageName=${encodeURIComponent(
            opportunity.name
          )}`
        );
      }
      return { success, data };
    },
    [createProposalFromOpportunity]
  );

  useEffect(() => {
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("addressLine1") as HTMLInputElement,
      {
        componentRestrictions: { country: ["USA", "CA"] },
        strictBounds: false,

        fields: ["address_components", "geometry"],
      }
    );

    if (autoCompleteRef.current) {
      // @ts-ignore
      autoCompleteRef.current.addListener("place_changed", async function () {
        // @ts-ignore
        const place = await autoCompleteRef.current.getPlace();
        if (place?.name) {
          setValue("addressLine1", "");
          return;
        }

        const addressParts = getAddressObject(place.address_components);
        setValue("addressLine1", `${addressParts.home} ${addressParts.street}`);
        setValue("addressCity", addressParts.city);
        setValue("addressState", addressParts.region);
        setValue("addressCountry", addressParts.country);
        setValue("addressZip", addressParts.postal_code);
        setShowAddressFields(true);
      });
    }
  }, []);
  useEffect(() => {
    if (action === "upsert") {
      setTriggerRefetch(true);
    }
  }, []);

  const checkedProducts: string[] = useMemo(() => {
    return opportunity?.products?.map((product) => product.id);
  }, [opportunity]);
  const productKey = action === "upsert" ? "sync" : "connect";

  const handleProjectFilesFolderSelect = (folderId: string) => {
    setValue("projectFilesFolderId", folderId, { shouldDirty: true });
  };
  function filterInput(input) {
    const { company, externalId, status, __typename, ...filteredInput } = input;

    return filteredInput;
  }

  const handleProductsValue = (value: any, options: SelectOptions) => {
    return (
      value.map((x) => ({
        label: options.find((y) => y.value === x?.id || y.value === x)?.label,
        value: x?.id || x,
      })) ?? []
    );
  };
  return (
    <div style={{ position: "relative", zIndex: 1 }}>
      {loading && (
        <MDBox
          position="absolute"
          left={0}
          top={0}
          right={0}
          bottom={0}
          zIndex={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ background: "rgba(50, 50, 100, 0.15)" }}
          borderRadius="12px"
        >
          <CircularProgress color="inherit" />
        </MDBox>
      )}
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={(e) => {
          if (!showAddressFields) {
            setShowAddressFields(true);
          }

          handleSubmit(async (data: CreateOpportunityInput | UpdateOpportunityInput) => {
            console.log("data", data);
            const filterData = filterInput(data);
            const processedData = {
              ...filterData,
              products: {
                // @ts-ignore
                [productKey]: data.products?.[productKey]
                  ?.filter((item) => Boolean(item)) // remove falsy values
                  ?.map((product) => ({ id: product })),
              },
            };

            if (action === "upsert") {
              // @ts-ignore
              processedData.id = opportunity.id;
              // @ts-ignore
            }

            const result = await onSubmit(processedData);
            // @ts-expect-error: FIX update types00
            if (result.success) {
              // reset();
              // @ts-expect-error: FIX update types
              onSuccess(result.data);
            }
          })(e);
        }}
      >
        <Grid container spacing={0}>
          {action === "create" && (
            <Grid item xs={12} md={6}>
              <MDTypography variant="h5">Opportunity Information</MDTypography>
            </Grid>
          )}
          {action === "upsert" && (
            <Grid item xs={12} md={6}>
              <MDTypography variant="h5">Opportunity Number: {opportunity.externalId}</MDTypography>
            </Grid>
          )}
          {action === "upsert" && opportunity.status === OpportunityStatus.OPEN && (
            <Grid item xs={12} md={6} textAlign={"right"}>
              <MDButton
                sx={{ minWidth: "190px" }}
                variant="gradient"
                color="info"
                disabled={createProposalFromOpportunityLoading}
                onClick={async () => {
                  await onCreateProposalFromOpportunitySubmit({
                    id: opportunity.id,
                  });
                }}
              >
                Move to Proposal
              </MDButton>
            </Grid>
          )}
        </Grid>
        <MDBox mt={1}>
          {action === "upsert" && <input type="hidden" name="id" {...register("id")} />}
          <Grid container spacing={3} mt={1}>
            <CompanyOptions
              triggerRefetch={triggerRefetch}
              selectedCompany={newlyCreatedCompany || company}
              setTriggerRefetch={(trigger) => setTriggerRefetch(trigger)}
            >
              {({
                companyOptions,
                companyContactOptions,
                loading,
                search,
                pagination,
                selectedContactDetails,
              }) => {
                return (
                  <>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="companyId"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <ScrollAsyncAutocomplete
                              {...field}
                              value={companyOptions?.find((x) => x.value === field.value)}
                              options={companyOptions ?? []}
                              loading={loading}
                              label="Select Client"
                              search={search}
                              onLoadMore={pagination.loadMore}
                              hasMore={pagination.hasNextPage}
                            />
                          </>
                        )}
                      />
                      {errors?.companyId?.message && (
                        <MDTypography variant="caption" color="error">
                          {errors?.companyId?.message}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox mt={0}>
                        <MDButton
                          type="button"
                          size="small"
                          variant="gradient"
                          color="secondary"
                          onClick={() => {
                            onOpen();
                          }}
                        >
                          Create Client
                        </MDButton>
                      </MDBox>
                    </Grid>

                    {companyId && (
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="contactId"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <AutoComplete
                              options={
                                companyId && companyContactOptions[companyId]
                                  ? companyContactOptions[companyId]
                                  : []
                              }
                              disabled={!companyId}
                              field={field}
                              loading={loading}
                              error={error?.message}
                              label={`Select Contact ${companyId ? "" : "(Select Client First)"}`}
                            />
                          )}
                        />
                        {contactId && (
                          <>
                            <MDTypography variant="caption">
                              Email: {selectedContactDetails(companyId, contactId)?.email},
                            </MDTypography>
                            <MDTypography variant="caption">
                              {" "}
                              Phone: {selectedContactDetails(companyId, contactId)?.phone}
                            </MDTypography>
                          </>
                        )}
                        {errors?.contactId?.message && (
                          <MDTypography variant="caption" color="error">
                            {errors?.contactId?.message}
                          </MDTypography>
                        )}
                      </Grid>
                    )}
                  </>
                );
              }}
            </CompanyOptions>
            <Grid item xs={12}>
              <CustomSelect
                control={control}
                name={"source"}
                data={enumToValueOptions(OpportunitySource)}
                label={"Opportunity Source"}
                size="small"
                fullWidth
              />
              {errors?.source?.message && (
                <MDTypography variant="caption" color="error">
                  {errors?.source?.message}
                </MDTypography>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormField
                type="text"
                label="Opportunity Name"
                placeholder="Opportunity Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            {action === "upsert" && selectOptions && (
              <>
                <Grid item xs={12} lg={6}>
                  <CustomSelect
                    control={control}
                    name={"statusId"}
                    data={selectOptions}
                    label={"Status"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6} mt={1}>
                  <MDButton
                    onClick={() => {
                      onOpenStatusModal();
                    }}
                  >
                    Create New Status
                  </MDButton>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Address"
                placeholder="Address"
                id={"addressLine1"}
                error={errors.addressLine1}
                {...register("addressLine1")}
              />
              {!showAddressFields && (
                <MDButton
                  sx={{
                    marginTop: "10px",
                  }}
                  variant="gradient"
                  color="secondary"
                  onClick={() => setShowAddressFields(true)}
                >
                  Manually set address
                </MDButton>
              )}
            </Grid>
            {showAddressFields && (
              <>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    label="City"
                    placeholder="City"
                    error={errors.addressCity}
                    {...register("addressCity")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    label="State"
                    placeholder="State"
                    error={errors.addressState}
                    {...register("addressState")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    label="Zip"
                    placeholder="Zip"
                    error={errors.addressZip}
                    {...register("addressZip")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    label="Country"
                    placeholder="Country"
                    id={"addressCountry"}
                    error={errors.addressCountry}
                    {...register("addressCountry")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <FormField
                type="date"
                label="Due At"
                placeholder="Due At"
                error={errors.dueAt}
                {...register("dueAt")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MDBox>
                <OrganizationUsersOptions
                  queryOverride={{
                    first: 1000,
                    roles: [UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.SALES_PERSON],
                  }}
                >
                  {({ organizationUsersOptions, loading, search }) => {
                    if (loading) {
                      return <InputLabel>Sales Person...</InputLabel>;
                    }

                    return (
                      <>
                        <InputLabel size="small" shrink={!!watch("userId")}>
                          Sales Person
                        </InputLabel>
                        <Controller
                          control={control}
                          name="userId"
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <AutoComplete
                                options={organizationUsersOptions}
                                field={field}
                                loading={loading}
                                error={error?.message}
                                search={search}
                                disabled={false}
                              />
                            );
                          }}
                        />
                      </>
                    );
                  }}
                </OrganizationUsersOptions>
              </MDBox>
              {errors?.userId?.message && (
                <MDTypography variant="caption" color="error">
                  {errors?.userId?.message}
                </MDTypography>
              )}
            </Grid>

            <OrganizationProductTypeIdsOptions sortByName>
              {({ organizationProductTypeIdsOptions, loading }) => (
                <Grid item xs={12} md={6}>
                  <InputLabel size="small" shrink={watch(`products.${productKey}`)?.length > 0}>
                    Products{loading ? "..." : ""}
                  </InputLabel>
                  <Controller
                    name={`products.${productKey}`}
                    control={control}
                    render={({ field }) => (
                      <CustomMultipleSelect
                        {...field}
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e?.map((x) => x.value));
                        }}
                        name={`products.${productKey}`}
                        disableCloseOnSelect
                        label=""
                        value={handleProductsValue(field.value, organizationProductTypeIdsOptions)}
                        options={organizationProductTypeIdsOptions}
                      />
                    )}
                  />
                  {errors?.products?.message && (
                    <MDTypography variant="caption" color="error">
                      {errors?.products?.message}
                    </MDTypography>
                  )}
                </Grid>
              )}
            </OrganizationProductTypeIdsOptions>
            <Grid item xs={12} md={6}>
              <CurrencyFormField
                control={control}
                name="estimatedOpportunityValue"
                label="Estimated Opportunity Value"
                placeholder="value in dollars"
                inputProps={{ min: "0", step: "0.01" }}
                {...currencyEndAdornment}
              />
            </Grid>
            <Grid item xs={12}>
              <ProjectFileExplorerInput
                submitFn={handleProjectFilesFolderSelect}
                folderId={folderId}
                showLabel
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <>
                    <InputLabel>Notes</InputLabel>
                    <WYSIWYG onChange={(e) => field.onChange(e)} title="" content={field.value} />
                    {errors?.notes?.message && (
                      <MDTypography variant="caption" color="error">
                        {errors?.notes?.message}
                      </MDTypography>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="space-between">
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                {action === "create" ? "Create" : "Save"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Modal open={open} onClose={onClose} styles={{ overflow: "auto" }}>
        <MDTypography variant="h6" p={3}>
          Create New Client
        </MDTypography>
        <CreateCompany onSubmit={onCreateCompanySubmit} loading={createCompanyLoading} />
      </Modal>
      <Modal open={statusModalOpen} onClose={onCloseStatusModal} styles={{ overflow: "auto" }}>
        <MDTypography variant="h6" p={3}>
          Create New Opportunity Status
        </MDTypography>
        <CreateOpportunityStatus
          onSubmit={onCreateOpportunityStatusSubmit}
          loading={createOpportunityStatusLoading}
        />
      </Modal>
    </div>
  );
};

export default OpportunityForm;
