import getLocalString from "constants/Localization";
import { useAddTruckToProposalProductMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

const useAddTruckToProposalProduct = () => {
  const [mutation, { loading, data, error }] = useAddTruckToProposalProductMutation({
    refetchQueries: ["customGetProposal"],
  });

  const [addTruckToProposalProduct, context] = useBitumioMutation(
    {
      mutation,
      data,
      error,
      loading,
      key: "addTruckToProposalProduct",
    },
    {
      successMessage: getLocalString("proposals.add-truck-to-proposal-product-success"),
      failureMessage: getLocalString("proposals.add-truck-to-proposal-product-failure"),
    }
  );

  return [addTruckToProposalProduct, context] as const;
};

export default useAddTruckToProposalProduct;
