import { Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useGetOrganizationProductType from "hooks/organization/product-types/useGetOrganizationProductType";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import OrganizationProductTypeProducts from "modules/OrganizationProductTypes/OrganizationProductTypeProducts";
import { Link, useParams } from "react-router-dom";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getRoute } from "utils/routing";

export default function OrganizationProductTypePage() {
  const { productTypeId } = useParams();
  const { data: productType, error } = useGetOrganizationProductType({ id: productTypeId });

  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <MDTypography variant="h4" fontWeight="medium">
              {productType?.name ?? ""}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
            <MDButton
              component={Link}
              type="button"
              to={getRoute("product-types.products.create", [["productTypeId", productTypeId]])}
              variant="gradient"
              color="info"
            >
              Create Product
            </MDButton>
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>
          </Grid>
        )}
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OrganizationProductTypeProducts id={productTypeId} />
        </Grid>
      </Grid>
    </DashboardPage>
  );
}
