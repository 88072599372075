import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Divider, Icon, Paper } from "@mui/material";
import { useContext } from "react";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import Modal from "modules/Modal/Modal";
import ProposalContext from "./providers/ProposalContextProvider";
import useModalState from "hooks/useModalState";
import ProposalSalesTax from "./ProposalSalesTax";

export default function ProposalSummary() {
  const { proposal } = useContext(ProposalContext);
  const { open, onClose, onOpen } = useModalState();

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" mb={3}>
        <MDTypography variant="h6">Proposal Summary</MDTypography>
      </MDBox>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell align="right">Direct Costs</TableCell>
              <TableCell align="right">Overhead Costs</TableCell>
              <TableCell align="right">Indirect Costs</TableCell>
              <TableCell align="right">Total Cost</TableCell>
              <TableCell align="right">Profit({proposal.profitPercentage}%)</TableCell>
              <TableCell align="right">Subtotal</TableCell>
              <TableCell align="right" sx={{ textDecoration: "underline" }} onClick={onOpen}>
                Tax <Icon color="secondary">edit</Icon>
              </TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="right">{formatCentsToUSD(proposal.costDirect)}</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.costOverhead)}</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.costIndirect)}</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.costTotal)}</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.profit)}</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.overallTotal)}</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.costTaxes)}</TableCell>
              <TableCell align="right">
                {formatCentsToUSD(proposal.overallTotalAfterTaxes)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell align="right">Gross Margin</TableCell>
              <TableCell align="right">Gross Margin %</TableCell>
              <TableCell align="right">Operating Margin</TableCell>
              <TableCell align="right">Operating %</TableCell>
              <TableCell align="right">Net Margin</TableCell>
              <TableCell align="right">Net Margin%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="right">{formatCentsToUSD(proposal.grossMargin)}</TableCell>
              <TableCell align="right">{proposal.grossMarginPercentage}%</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.operatingMargin)}</TableCell>
              <TableCell align="right">{proposal.operatingMarginPercentage}%</TableCell>
              <TableCell align="right">{formatCentsToUSD(proposal.netMargin)}</TableCell>
              <TableCell align="right">{proposal.netMarginPercentage}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={open} onClose={onClose} styles={{ overflow: "auto", maxWidth: "500px" }}>
        <MDTypography variant="h6" p={3}>
          Edit Tax rates
        </MDTypography>
        <ProposalSalesTax onClose={onClose} />
      </Modal>
    </>
  );
}
