import { Icon } from "@mui/material";
import { UserPermissions } from "generated/graphql";
import AuthWall from "modules/AuthWall/AuthWall";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";
import TimeEntriesPage from "pages/timeTracking/timeEntries";
import TimeBucketsPage from "pages/timeTracking/timeBuckets";
import DatePickerTest from "modules/TimeEntries/DatePickerTest";

const timeTrackingRoutes = [
  {
    type: "collapse",
    name: "Time Tracking",
    key: "time-tracking",
    icon: <Icon fontSize="medium">schedule</Icon>,
    permission: [UserPermissions.VIEW_TIME_TRACKING, UserPermissions.MANAGE_TIME_TRACKING],
    collapse: [
      {
        type: "link",
        name: "Time Entries",
        key: "time-entries",
        route: "/time-entries",
        permission: [
          UserPermissions.VIEW_TIME_TRACKING,
          UserPermissions.MANAGE_TIME_TRACKING,
          UserPermissions.ADD_MANUAL_TIME,
        ],
        component: (
          <AuthWall
            permission={[
              UserPermissions.VIEW_TIME_TRACKING,
              UserPermissions.MANAGE_TIME_TRACKING,
              UserPermissions.ADD_MANUAL_TIME,
            ]}
          >
            <AuthWallSubscriber>
              <TimeEntriesPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "link",
        name: "Time Buckets",
        key: "time-buckets",
        route: "/time-buckets",
        permission: UserPermissions.MANAGE_TIME_TRACKING,
        component: (
          <AuthWall permission={UserPermissions.MANAGE_TIME_TRACKING}>
            <TimeBucketsPage />
          </AuthWall>
        ),
      },
    ],
  },
  {
    route: "/date-picker-test",
    component: (
      <AuthWall permission={UserPermissions.MANAGE_TIME_TRACKING}>
        <DatePickerTest />
      </AuthWall>
    ),
  },
] as const;

export default timeTrackingRoutes;
