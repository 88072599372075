import MDAlert from "components/MDAlert";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useProductsTable from "hooks/products/useProductsTable";

export default function OrganizationProductTypeProducts({ id }: { id: string }) {
  const { loading, error, tableData, pagination, filtering } = useProductsTable({
    productTypeId: id,
  });

  return (
    <>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && (
        <DataTable table={tableData} canSearch pagination={pagination} filtering={filtering} />
      )}
    </>
  );
}
