import Container from "@mui/material/Container";
import PageLayout from "layouts/PageLayout/PageLayout";
import Header from "partials/Header/Header";
import PageFooter from "partials/Footer/Footer";

export default function TermsAndConditionsPage(): JSX.Element {
  return (
    <PageLayout>
      <Header>
        <Container
          style={{ paddingBlock: "70px" }}
          sx={{
            h3: {
              fontSize: "22px",
            },
            p: {
              fontSize: "16px",
            },
          }}
        >
          <h3>Introduction</h3>
          <p>
            These terms and conditions (&quot;Terms&quot;) apply to the use of the software as a
            service (&quot;SaaS&quot;) offered by Bitumio (&quot;Company&quot;) through its website
            located at <a href="https://app.bitumio.com">www.app.bitumio.com</a>{" "}
            (&quot;Website&quot;). By accessing or using the SaaS, you and anyone affiliated under
            your license (&quot;Users&quot;) agree to be bound by these Terms. If you do not agree
            to these Terms, you may not use the SaaS.
          </p>
          <br /> <h3>Description of Service</h3>
          <p>
            The SaaS is a software application that can be accessed and used over the internet. The
            SaaS may include various features, such as data storage, data analysis, and other tools.
            The Company reserves the right to change, suspend, or discontinue the SaaS or any
            portion thereof at any time, for any reason, and without notice.
          </p>
          <br />
          <h3>User Accounts</h3>
          <p>
            In order to access and use the SaaS, User must create a user account
            (&quot;Account&quot;). Users are responsible for maintaining the confidentiality of
            User’s Account and for all activities that occur under User’s Account. User agrees to
            notify the Company immediately of any unauthorized use of User’s Account or any other
            breach of security. The Company will not be liable for any loss or damage arising from
            User’s failure to comply with this Section.
          </p>
          <br />
          <h3>Payment</h3>
          <p>
            The SaaS is offered on a subscription basis. User will be required to provide a valid
            payment method in order to use the SaaS. The Company may change the fees for the SaaS at
            any time, and will provide User with notice of any such change.
          </p>
          <br /> <h3>Content</h3>
          <p>
            You are responsible for all content that you upload, post, or otherwise make available
            through the SaaS (&quot;Content&quot;). You represent and warrant that you have the
            right to use and distribute all Content that you make available through the SaaS, and
            that such Content does not infringe or violate the rights of any third party.
          </p>
          <br /> <h3>Intellectual Property</h3>
          <p>
            The SaaS and all intellectual property rights in the SaaS are owned by the Company. You
            may not use the SaaS or any intellectual property rights in the SaaS for any purpose not
            expressly permitted by these Terms.
          </p>
          <br /> <h3>Disclaimer of Warranties</h3>
          <p>
            THE SaaS IS PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; WITHOUT WARRANTIES
            OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
            THE COMPANY MAKES NO WARRANTIES THAT THE SaaS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
            ERROR-FREE.
          </p>
          <br />
          <h3>Limitation of Liability</h3>
          <p>
            IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
            DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES,
            RESULTING FROM <br />
            (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SaaS; <br />
            (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SaaS; OR <br />
            (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT OR INFORMATION.
          </p>
          <p>
            Users are solely responsible for the accuracy, completeness, and legality of the
            estimates they generate and send through the Bitumio platform. Bitumio shall not be
            liable for any inaccuracies, errors, or omissions in the estimates created and sent by
            Users.
          </p>
          <p>
            Bitumio shall not be held responsible for any losses, financial or otherwise, incurred
            by Users as a result of sending inaccurate estimates to their customers through the
            Bitumio platform. Users acknowledge that they are solely responsible for the
            consequences of using the estimates generated through the Bitumio platform, and Bitumio
            shall not be liable for any negative impact on Users' business, financial standing, or
            relationships with their customers.
          </p>
          <br /> <h3>Indemnification</h3>
          <p>
            You agree to indemnify, defend, and hold harmless the Company and its affiliate sand
            their respective officers, directors, employees, and agents, from and against any and
            all claims, liabilities, damages, losses, and expenses, including reasonable
            attorneys&apos; fees and costs, arising out of or in any way connected with:
            <br />
            (a) your access to or use of the SaaS; <br />
            (b) your violation of these Terms;
            <br />
            (c) your Content; or <br />
            (d) your violation of any rights of any third party.
          </p>
          <p>
            Users agree to indemnify, defend, and hold harmless the Company and its affiliates and
            their respective officers, directors, employees, and agents, from and against any and
            all claims, liabilities, damages, losses, and expenses, including reasonable attorneys'
            fees and costs, arising out of or in any way connected with inaccuracies or errors in
            the estimates created and sent by Users using the Bitumio platform.
          </p>
          <br /> <h3>Term and Termination</h3>
          <p>
            These Terms will remain in effect until terminated. The Company may terminate these
            Terms at any time if Users violate any of the provisions of these Terms. Upon
            termination, User’s Account and access to the SaaS will be terminated and all Content
            will be deleted.
          </p>
          <br />
          <h3>Governing Law</h3>
          <p>
            These Terms and your use of the SaaS will be governed by and construed in accordance
            with the laws of the United States, without giving effect to any principles of conflicts
            of law.
          </p>
          <br /> <h3>Dispute Resolution</h3>
          <p>
            Any dispute arising out of or related to these Terms or the SaaS will be resolved
            through binding arbitration in accordance with the United States Arbitration
            Association&apos;s rules. The arbitration will be held in Ogden, Utah.
          </p>
          <br /> <h3>Miscellaneous</h3>
          <p>
            These Terms constitute the entire agreement between you and the Company regarding the
            use of the SaaS. If any provision of these Terms is held to be invalid or unenforceable,
            such provision will be struck and the remaining provisions will be enforced. The failure
            of the Company to enforce any right or provision in these Terms will not be deemed a
            waiver of such right or provision unless acknowledged and agreed to by the Company in
            writing.
          </p>
          <br /> <h3>Contact Information</h3>
          <p>
            If you have any questions about these Terms, please contact the Company at{" "}
            <a href="mailto:info@bitumio.com">info@bitumio.com.</a>
          </p>
        </Container>
      </Header>
      <PageFooter />
    </PageLayout>
  );
}
