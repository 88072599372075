import { ApolloError } from "@apollo/client";
import {
  useValidateLoginLazyQuery,
  ValidateLoginInput,
  ValidateLoginQuery,
} from "generated/graphql";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

type LoginStatus = "idle" | "validated" | "invalid" | "invited" | "unverified";

const getStatus = (
  data: ValidateLoginQuery | undefined,
  error: ApolloError | undefined
): LoginStatus => {
  return (
    (data?.validateLogin && "validated") ||
    (error?.message as "invalid" | "invited" | "unverified") ||
    undefined
  );
};

export default function useValidateLogin() {
  const [searchParams] = useSearchParams();
  const [validateLoginQuery, { data, loading, error }] = useValidateLoginLazyQuery();
  const [email, setEmail] = useState<string | null>(searchParams.get("email") || null);

  const validateLogin = useCallback(
    (values: ValidateLoginInput) => {
      setEmail(values.email);
      validateLoginQuery({
        variables: {
          input: values,
        },
      });
    },
    [validateLoginQuery, setEmail]
  );

  const status: LoginStatus = getStatus(data, error) || "idle";

  return [
    validateLogin,
    {
      data,
      loading,
      error: ["invalid", "invited", "unverified"].includes(status) ? null : error,
      status,
      email,
    },
  ] as const;
}
