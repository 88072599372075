function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const images = ["/assets/images/bg1.jpg", "/assets/images/bg2.jpg"];

export default function getRandomBackgroundImage() {
  const rndInt = randomIntFromInterval(0, images.length - 1);
  return images[rndInt];
}
