import { Proposal } from "generated/graphql";
import useCancelProposal from "hooks/proposals/useCancelProposal";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CancelDuplicateIcon from "../../../../assets/images/cancelduplicate-proposal.svg";

export default function CancelAndDuplicateProposal({
  id,
  status,
}: {
  id: Proposal["id"];
  status: Proposal["status"];
}) {
  const [cancelProposal, { isSuccess, getData }] = useCancelProposal();
  const validStatuses = ["SENT", "CLOSED_WON", "CLOSED_LOST"];
  const navigate = useNavigate();
  const isDisabled = !validStatuses.includes(status);

  return (
    <>
      <Tooltip title="Cancel and Duplicate Proposal">
        <IconButton
          disabled={isDisabled}
          onClick={async () => {
            const result = await cancelProposal({
              variables: {
                id,
                duplicate: true,
              },
            });

            if (isSuccess(result.data)) {
              const data = getData(result);
              navigate(getRoute("proposals.draft", [["proposalId", data.newProposal.id]]));
            }
          }}
        >
          <img
            src={CancelDuplicateIcon}
            alt="Duplicate Proposal"
            style={{
              filter: isDisabled
                ? "invert(70%) hue-rotate(120deg)"
                : "invert(0%) hue-rotate(120deg)",
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}
