import getLocalString from "constants/Localization";
import { Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CreateAdditionalCostInput, CreateAdditionalCostMutationResult } from "generated/graphql";
import useCreateAdditionalCost from "hooks/organization/useCreateAdditionalCost";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Create from "modules/OrganizationAdditionalCosts/Create";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function AdditionalCostCreatePage() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={12} mb={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Create Additional Cost
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Create />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
