import {
  CustomGetProposalQuery,
  CustomGetProposalQueryVariables,
  useCustomGetProposalQuery,
} from "generated/graphql";

export type CustomGetProposalQueryData = CustomGetProposalQuery["getProposal"] | null;

export type CustomProposalStage = CustomGetProposalQuery["getProposal"]["stages"][number];

export default function useCustomGetProposal({ id }: CustomGetProposalQueryVariables) {
  const { loading, data: graphqlData, error } = useCustomGetProposalQuery({ variables: { id } });

  return { loading, error, data: graphqlData?.getProposal ?? null } as const;
}
