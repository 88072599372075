import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "components/FormField/FormField";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import React from "react";
import { ControllerRenderProps } from "react-hook-form";

const AutoComplete: React.FC<{
  field: ControllerRenderProps<any, any>;
  error: string | null | object;
  options: SelectOptions;
  loading: boolean;
  label?: string;
  placeholder?: string;
  variant?: string;
  search?: (v: string) => void;
  disabled?: boolean;
  getOptionDisabled?: (option: SelectOptions[number]) => boolean;
}> = ({
  disabled,
  field,
  error,
  options,
  loading,
  label,
  search,
  placeholder,
  variant = "standard",
  getOptionDisabled,
}) => {
  const extraProps = {
    ...(search ? { filterOptions: (x) => x } : {}),
    ...(options?.every((x) => !!x?.category)
      ? {
          groupBy: (option: any) => option.category,
        }
      : {}),
  };

  return (
    <Autocomplete
      {...field}
      {...extraProps}
      loading={loading}
      options={options}
      onChange={(e, value: (typeof options)[number]) => field.onChange(value?.value || null)}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.label}-${option.value}`}>
            {option.label ?? ""}
          </li>
        );
      }}
      getOptionLabel={(option) =>
        option === ""
          ? ""
          : typeof option === "string"
          ? options?.find((opt) => opt.value === option)?.label ?? ""
          : option.label
      }
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      getOptionDisabled={getOptionDisabled}
      renderInput={(params) => (
        <FormField
          {...field}
          {...params}
          disabled={disabled}
          inputProps={{
            ...params.inputProps,
            disabled,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onChange={(e) => {
            params.inputProps.onChange(e);
            search?.(e.target.value);
          }}
          label={label}
          variant={variant}
          error={error}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default AutoComplete;
