import { Alert, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Proposal } from "generated/graphql";
import useCustomGetProposal from "hooks/proposals/useCustomGetProposal";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import ChangeOrders from "modules/proposals/ChangeOrders/ChangeOrders";
import ProposalActivityLog from "modules/proposals/ProposalActivityLog/ProposalActivityLog";
import ProposalInfo from "modules/proposals/ProposalInfo";
import ProposalRecipients from "modules/proposals/ProposalRecipients/ProposalRecipients";
import Versions from "modules/proposals/Versions/Versions";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export default function ProposalInfoPage() {
  const { proposalId } = useParams();
  const { loading, data: proposal, error } = useCustomGetProposal({ id: proposalId });

  const latestProposalVersion =
    proposal && !proposal?.activeVersion && proposal.versions.find((p) => p.activeVersion)?.id;

  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          {proposal && !proposal?.activeVersion && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="warning">
                You are on an older version of this proposal.{" "}
                <Link to={`/proposals/${latestProposalVersion}/info`}>Click here</Link> to go to
                latest version.{" "}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Proposal Info {proposal ? `#${proposal.version}` : ""}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        {!loading && !error && (
          <>
            <Grid item xs={12}>
              <ProposalInfo proposal={proposal as Proposal} />
            </Grid>
            <Grid item xs={12} mb={3}>
              <Versions proposal={proposal as Proposal} />
            </Grid>
            <Grid item xs={12} mb={3}>
              <ChangeOrders proposal={proposal as Proposal} />
            </Grid>
            <Grid item xs={12}>
              <ProposalRecipients proposal={proposal as Proposal} />
            </Grid>
            <Grid item xs={12}>
              <ProposalActivityLog proposal={proposal as Proposal} />
            </Grid>
          </>
        )}
      </Grid>
    </DashboardPage>
  );
}
