import { useCreateTimeBucketMutation } from "generated/graphql";
import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useCreateTimeBucket = () => {
  const [mutation, { loading, error, data }] = useCreateTimeBucketMutation({
    refetchQueries: ["getTimeBuckets"],
  });

  const [createTimeBucket, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "createTimeBucket",
    },
    {
      successMessage: getLocalString("time-tracking.create-time-bucket-success"),
      failureMessage: getLocalString("time-tracking.create-time-bucket-failure"),
      showValidationErrors: true,
    }
  );

  return [createTimeBucket, context] as const;
};
