import {
  GetOrganizationAddressesQuery,
  GetOrganizationAddressesQueryVariables,
  useGetOrganizationAddressesQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetOrganizationAddresses";

export default function useGetOrganizationAddresses({
  page = 1,
  first = 10,
}: GetOrganizationAddressesQueryVariables) {
  const {
    data: graphqlData,
    loading,
    error,
    fetchMore: more,
    refetch,
  } = useGetOrganizationAddressesQuery({
    variables: {
      page,
      first,
    },
  });

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
      });
    },
    [more]
  );

  const getData = useCallback(({ data }: { data?: GetOrganizationAddressesQuery }) => {
    return data?.getOrganizationAddresses?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: GetOrganizationAddressesQuery }) => {
    return data?.getOrganizationAddresses?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return {
    data,
    loading,
    error,
    paginatorInfo,
    fetchMore,
    getData,
    getPaginatorInfo,
    refetch,
  } as const;
}
