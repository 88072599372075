import DatePicker from "react-datepicker";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import dayjs from "dayjs";
import MDTypography from "components/MDTypography";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import { useState } from "react";

export default function DatePickerTest() {
  const [date1, setDate1] = useState<string | null>(null);
  const [date2, setDate2] = useState<string | null>(null);
  const [date3, setDate3] = useState<string | null>(null);
  const [date4, setDate4] = useState<string | null>(null);
  const [date5, setDate5] = useState<string | null>(null);
  return (
    <DashboardPage>
      <MDBox mb={3}>
        <MDTypography>Date picker test</MDTypography>
        <MDBox mb={3}>
          <DatePicker
            selected={date1 ? new Date(date1) : null}
            onChange={(date) => {
              setDate1(date ? dayjs(date).format("YYYY-MM-DD") : null);
            }}
            dateFormat="yyyy-MM-dd"
            isClearable
            placeholderText="Date picker 1"
            customInput={
              <MDInput
                variant="outlined"
                label="Filter by Date"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                    {
                      height: "53px",
                      maxWidth: "100%",
                      width: "100%",
                    },
                }}
              />
            }
          />
        </MDBox>
        <MDBox mb={3}>
          <DatePicker
            selected={date2 ? new Date(date2) : null}
            shouldCloseOnSelect={false}
            onChange={(e) => {
              setDate2(e ? dayjs(e).format("YYYY-MM-DD HH:mm:ss") : null);
            }}
            showTimeSelect
            timeIntervals={15}
            dateFormat="yyyy-MM-dd HH:mm:ss"
            className="date-input"
            placeholderText="Date picker 2"
          />
        </MDBox>
        <MDBox mb={3}>
          <DatePicker
            selected={date3 ? new Date(date3) : null}
            onChange={(date) => {
              setDate3(date ? dayjs(date).format("YYYY-MM-DD") : null);
            }}
            dateFormat="yyyy-MM-dd HH:mm:ss"
            isClearable
            placeholderText="Date picker 3"
            customInput={
              <MDInput
                variant="outlined"
                label="Filter by Date"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                    {
                      height: "53px",
                      maxWidth: "100%",
                      width: "100%",
                    },
                }}
              />
            }
          />
        </MDBox>
        <MDBox mb={3}>
          <DatePicker
            selected={date4 ? new Date(date4) : null}
            onChange={(date) => {
              setDate4(date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null);
            }}
            dateFormat="yyyy-MM-dd HH:mm:ss"
            isClearable
            placeholderText="Date picker 4"
            customInput={
              <MDInput
                variant="outlined"
                label="Filter by Date"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                    {
                      height: "53px",
                      maxWidth: "100%",
                      width: "100%",
                    },
                }}
              />
            }
          />
        </MDBox>
        <MDBox mb={3}>
          <DatePicker
            selected={date5 ? new Date(date5) : null}
            onChange={(date) => {
              setDate5(date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null);
            }}
            dateFormat="yyyy-MM-dd"
            isClearable
            placeholderText="Date picker 5"
            customInput={
              <MDInput
                variant="outlined"
                label="Filter by Date"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                    {
                      height: "53px",
                      maxWidth: "100%",
                      width: "100%",
                    },
                }}
              />
            }
          />
        </MDBox>
      </MDBox>
    </DashboardPage>
  );
}
