import { useCallback, useContext, useRef, useState } from "react";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import { DragDrop, useUppy } from "@uppy/react";
import useGenerateUploadJobPhaseCostItemFileUrl from "hooks/jobs/job-phases/useGenerateUploadJobPhaseFileUrl";
import useAddJobPhaseFile from "hooks/jobs/job-phases/useAddJobPhaseFile";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import { useFormContext } from "react-hook-form";
import { JobPhaseContext } from "./context/JobPhaseContext";

export default function AddFiles() {
  const { handleSubmit } = useFormContext();
  const { jobPhase } = useContext(JobPhaseContext);
  const [generateUploadJobPhaseCostItemFileUrl, { getData, isSuccess }] =
    useGenerateUploadJobPhaseCostItemFileUrl();
  const [addJobPhaseFile] = useAddJobPhaseFile();
  const pathRef = useRef("");
  const [preview, setPreview] = useState("");
  const [status, setStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");

  const onSuccess = useCallback(async () => {
    const result = await addJobPhaseFile({
      variables: {
        path: pathRef.current,
        jobPhaseId: jobPhase.id,
      },
    });
  }, [jobPhase?.id]);

  const readImage = useCallback((file) => {
    const blob = new Blob([file.data], { type: file.type });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setPreview(reader.result.toString());
    };
  }, []);

  const uppy = useUppy(() => {
    const _uppy = new Uppy({
      autoProceed: true,

      restrictions: {
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const result = await generateUploadJobPhaseCostItemFileUrl({
          variables: {
            contentType: file.type,
            filename: file.name,
            jobPhaseId: jobPhase.id,
          },
        });
        const success = isSuccess(result.data);
        if (success) {
          const data = getData(result);
          pathRef.current = data.path;
          return {
            method: data.method as "PUT",
            url: data.url,
            fields: {},
            headers: {
              "Content-Type": file.type,
            },
          };
        } else {
          throw new Error("Could not upload the file");
        }
      },
    });

    _uppy.on("upload", () => {
      setStatus("pending");
    });

    _uppy.on("upload-success", (file, response) => {
      if (response.status === 200) {
        onSuccess();
        setStatus("fuilfilled");
        readImage(file);
        _uppy.removeFile(file.id);
      }
    });

    _uppy.on("restriction-failed", (file, error) => {
      if (error) {
        setStatus("rejected");
        setErrorMessage(error.message);
      }
    });

    _uppy.on("upload-error", (file) => {
      if (file) {
        setErrorMessage("An error has ocurred while uploading this file");
        setStatus("rejected");
        _uppy.removeFile(file.id);
      }
    });

    return _uppy;
  });

  return (
    <DragDrop
      width="100%"
      height="100%"
      uppy={uppy}
      locale={{
        strings: {
          // Text to show on the droppable area.
          // `%{browse}` is replaced with a link that opens the system file selection dialog.
          dropHereOr: "Drop here or %{browse}",
          // Used as the label for the link that opens the system file selection dialog.
          browse: "browse",
        },
      }}
    />
  );
}
