import getLocalString from "constants/Localization";
import { useAddJobPhaseCostsMutation, useUpdateWorkOrderItemsMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateWorkOrderItems() {
  const [mutation, { loading, data, error }] = useUpdateWorkOrderItemsMutation({
    refetchQueries: ["getJobPhase", "customGetJobPhase", "customGetJobPhaseCostItem"],
    fetchPolicy: "no-cache",
  });
  const [addJobPhaseCosts, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateWorkOrderItems",
    },
    {
      successMessage: getLocalString("jobPhases.update-work-order-items-success"),
      failureMessage: getLocalString("jobPhases.update-work-order-items-failure"),
    }
  );

  return [addJobPhaseCosts, context] as const;
}
