import lengthEndAdornment from "constants/lenghtEndAdornment";
import tooltipLengthEndAdornment from "constants/tootipLengthEndAdornment";
import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "crackDepth",
    label: "Crack Depth in Inches",
    inputProps: { ...lengthEndAdornment },
  },
  {
    name: "crackWidth",
    label: "Crack Width in Inches",
    inputProps: { ...lengthEndAdornment },
  },
  {
    name: "overbandWidth",
    label: "Overband Width in Inches",
    inputProps: { ...lengthEndAdornment },
  },
  {
    name: "overbandThickness",
    label: "Overband Thickness in Inches (i.e 50 Mils = .05 inches)",
    inputProps: { ...tooltipLengthEndAdornment },
  },
];

export default function MasticForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
