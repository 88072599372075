import MDAlert from "components/MDAlert";
import {
  CreateProductInput,
  OrganizationProductType,
  ProductLabourCostType,
  ProductType,
} from "generated/graphql";
import useCreateProduct from "hooks/products/useCreateProduct";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import getJobMeasurementMethodOptions from "utils/product-types/getJobMeasurementMethodOptions";
import { getMaterialCostTypeOptions } from "utils/product-types/getMaterialCostTypeOptions";
import { getRoute } from "utils/routing";
import OrganizationProductTypeProductCreateForm from "./forms/OrganizationProductTypeProductCreateForm";

export default function OrganizationProductTypeProductCreate({
  productType,
}: {
  productType: OrganizationProductType;
}) {
  const navigate = useNavigate();
  const [createProduct, { loading, error, isSuccess, getData }] = useCreateProduct({
    productTypeId: productType.id,
  });

  const handleSubmit = useCallback(
    async (input: CreateProductInput) => {
      const result = await createProduct({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      // Navigate to the recently created Product
      if (success) {
        navigate(getRoute("product-types.products.list", [["productTypeId", productType.id]]));
      }
      return { success, data };
    },
    [createProduct]
  );

  const materialCostTypeOptions = useMemo(() => {
    return getMaterialCostTypeOptions(productType.type as ProductType); // Unexpected type issue because of codegen generating an extra enum field
  }, [productType]);

  const jobMeasurementMethodOptions = useMemo(() => {
    return getJobMeasurementMethodOptions(productType.type as ProductType);
  }, [productType]);

  const labourCostTypeOptions = useMemo(() => {
    return enumToValueOptions(ProductLabourCostType);
  }, []);

  return (
    <div>
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <OrganizationProductTypeProductCreateForm
        productType={productType}
        onSubmit={handleSubmit}
        loading={loading}
        materialCostTypeOptions={materialCostTypeOptions}
        jobMeasurementMethodOptions={jobMeasurementMethodOptions}
        labourCostTypeOptions={labourCostTypeOptions}
      />
    </div>
  );
}
