export default function PendingChangesRoot() {
  return (
    <div
      id="pending-changes"
      style={{
        position: "fixed",
        zIndex: "10000",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    ></div>
  );
}
