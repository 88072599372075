import { CustomGetAllCrewsQuery, useCustomGetAllCrewsQuery } from "generated/graphql";

export type CustomCrews = CustomGetAllCrewsQuery["getAllCrews"];
export type CustomCrew = CustomCrews[number];

export default function useGetAllCrews() {
  const { loading, data, error } = useCustomGetAllCrewsQuery();

  return { loading, data: data?.getAllCrews || null, error };
}
