import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import dayjs from "dayjs";
import { useMemo } from "react";
import useGetOrganization from "hooks/organization/useGetOrganization";

export default function TrialEndsSubscriptionRenewsCard() {
  const { data: organization } = useGetOrganization();

  const planInfo: { title: string; date: string | undefined } = useMemo(() => {
    // Is it a normal trial?
    if (organization?.subscription && organization?.subscription.stripeStatus === "trialing") {
      return {
        title:
          dayjs().diff(organization?.subscription.trialEndsAt, "day") >= 1
            ? "Trial ended"
            : "Trial Ends",
        date: organization?.subscription.trialEndsAt,
      };
    }

    // Is there a regular .subscription
    if (organization?.subscription && organization?.subscription.renewsAt) {
      return {
        title: "Monthly Renewal",
        date: organization?.subscription.renewsAt,
      };
    }

    // It must be a generic trial then
    return {
      title: dayjs().diff(organization?.trialEndsAt, "day") >= 1 ? "Trial ended" : "Trial Ends",
      date: organization?.trialEndsAt || undefined,
    };
  }, [organization?.subscription]);

  return (
    <DefaultInfoCard
      icon="today"
      title={planInfo.title}
      description="&nbsp;"
      value={planInfo.date ? dayjs(planInfo.date).format("YYYY/MM/DD") : "N/A"}
    />
  );
}
