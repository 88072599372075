import getLocalString from "constants/Localization";
import { useDeleteProposalMapImageMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteProposalMapImage() {
  const [mutation, { loading, data, error }] = useDeleteProposalMapImageMutation();

  const [deleteProposalMapImage, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteProposalMapImage",
    },
    {
      successMessage: getLocalString("proposals.delete-map-image-success"),
      failureMessage: getLocalString("proposals.delete-map-image-failure"),
    }
  );

  return [deleteProposalMapImage, context] as const;
}
