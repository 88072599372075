import { UpdateProposalProductInputSchema } from "DDD/action-objects/StageProductUpdate";
import { Plant, ProposalProduct, ProposalStage } from "generated/graphql";
import React from "react";

export interface StageProductContextValues {
  product: ProposalProduct;
  defaultValues: UpdateProposalProductInputSchema;
  transferTrucksById: Record<string, ProposalProduct["transferTrucks"][number]>;
  onSubmit: (values: UpdateProposalProductInputSchema) => void;
  stage: ProposalStage;
}

const StageProductContext = React.createContext<StageProductContextValues>(
  {} as StageProductContextValues
);

export default StageProductContext;
