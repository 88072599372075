import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Organization } from "generated/graphql";
import { useState } from "react";
import DocumentInput from "./DocumentInput";
import DocumentItem from "./DocumentItem";

export default function OrganizationDocuments({ organization }: { organization: Organization }) {
  const { documents } = organization;
  const [inputList, setInputList] = useState([]);
  const addInput = () => {
    setInputList(
      inputList.concat(<DocumentInput index={inputList.length} key={inputList.length} />)
    );
  };

  return (
    <Card>
      <MDBox padding={4}>
        <MDTypography variant="h5" fontWeight="medium" mb={2}>
          Organization Documents
        </MDTypography>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} lg={12}>
            {documents.map((d, i) => (
              <MDBox key={i} mb={1}>
                <DocumentItem document={d} index={i} />
              </MDBox>
            ))}
            {inputList}
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDButton variant="contained" color="success" onClick={addInput}>
              Add New Document
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
