import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import { MODAL_LOADER_Z_INDEX } from "constants/Zindex";

const AbsoluteCenterProgressLoader = () => {
  return (
    <MDBox
      position="absolute"
      left={0}
      bottom={0}
      right={0}
      top={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgColor="rgba(25, 25, 50, 0.2)"
      zIndex={MODAL_LOADER_Z_INDEX}
    >
      <CircularProgress color="info" />
    </MDBox>
  );
};

export default AbsoluteCenterProgressLoader;
