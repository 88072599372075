import { Checkbox, Divider, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import ProposalContext from "./providers/ProposalContextProvider";
import useGetOrganization from "hooks/organization/useGetOrganization";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useUpdateProposalSalesTaxes from "hooks/proposals/useUpdateProposalSalesTaxes";

export default function ProposalSalesTax({ onClose }) {
  const { proposal } = useContext(ProposalContext);
  const { data } = useGetOrganization();
  const [mutation, { isSuccess }] = useUpdateProposalSalesTaxes();

  const [checked, setChecked] = useState<Record<string, { checked: boolean } | undefined>>(() => {
    return proposal.salesTaxes.reduce((acc, val) => {
      return {
        ...acc,
        [val.organizationSalesTaxId]: {
          checked: true,
        },
      };
    }, {});
  });

  const handleChecked = useCallback(
    (id, checked) => {
      setChecked((prevValues) => ({ ...prevValues, [id]: { checked } }));
    },
    [setChecked]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const ids = [];
      for (const [key, value] of Object.entries(checked)) {
        if (value.checked) {
          ids.push(key);
        }
      }
      const result = await mutation({
        variables: {
          input: {
            id: proposal.id,
            salesTaxes: ids,
          },
        },
      });
      const success = isSuccess(result.data);
      if (success) {
        onClose();
      }
    },
    [mutation, checked]
  );

  return (
    <MDBox p={3} component="form" onSubmit={handleSubmit}>
      {data?.salesTaxes?.map((tax) => {
        return (
          <FormGroup key={tax.id}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  sx={{
                    position: "relative",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      left: "10px",
                      right: 0,
                      height: "2px",
                      bottom: "0",
                    },
                  }}
                  control={
                    <Checkbox
                      checked={checked[tax.id]?.checked ?? false}
                      onChange={(event) => {
                        handleChecked(tax.id, event.target.checked);
                      }}
                    />
                  }
                  label={tax.name}
                />
              </Grid>
              <Grid item xs={6}>
                <MDTypography variant="body1" component="body1" fontSize="1rem">
                  {tax.percentage}%
                </MDTypography>
              </Grid>
            </Grid>
            <Divider />
          </FormGroup>
        );
      })}
      <MDButton color="success" type="submit">
        Save
      </MDButton>
    </MDBox>
  );
}
