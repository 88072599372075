import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import { getRoute } from "utils/routing";
import useGetLinkedServiceAuthUrl from "hooks/organization/linkedServices/useGetLinkedServiceAuthUrl";
import { OrganizationLinkedServiceName } from "generated/graphql";
import useGetOrganization from "hooks/organization/useGetOrganization";
import useUnlinkService from "hooks/organization/linkedServices/useUnlinkService";
import useDisplayPass from "hooks/quickbooksDesktop/useDisplayPass";
import useDownloadQbcFile from "hooks/quickbooksDesktop/useDownloadQbcFile";
import useIncomeAccount from "hooks/quickbooksDesktop/useIncomeAccount";
import useLinkQuickbooksDesktop from "hooks/quickbooksDesktop/useLinkQuickbooksDesktop";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import ApiKeys from "./apikeys";

const OrganizationLinkedServices = () => {
  const [getLinkedServiceAuthUrl] = useGetLinkedServiceAuthUrl();
  const [unlinkService] = useUnlinkService();

  const { data } = useGetOrganization();

  const [getQbcPass] = useDisplayPass();
  const [downloadQbcFile] = useDownloadQbcFile();
  const [getIncomeAccount] = useIncomeAccount();
  const [linkQuickbooksDesktop] = useLinkQuickbooksDesktop();
  const [displayQuickbooksSetup, setDisplayQuickbooksSetup] = useState(false);
  const [passToDisplay, setPassToDisplay] = useState("****");
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(true);
  const [incomeAccount, setIncomeAccount] = useState("");
  const [inputIncomeAccount, setInputIncomeAccount] = useState("");
  const [hasLinkedQuickbooksDesktop, setHasLinkedQuickbooksDesktop] = useState(false);
  const [open, setOpen] = useState(false);

  const hasLinkedQuickbooksOnline = !!data?.linkedServices?.find(
    (service) => service.service === OrganizationLinkedServiceName.QUICKBOOKS && service.active
  );

  const actionFnOnline = hasLinkedQuickbooksOnline ? unlinkService : getLinkedServiceAuthUrl;

  const acceptFn = () => {
    setDisplayQuickbooksSetup(false);
    unlinkService({
      variables: { service: OrganizationLinkedServiceName.QUICKBOOKS_DESKTOP },
    });
    setHasLinkedQuickbooksDesktop(false);
    setIncomeAccount("");
    setInputIncomeAccount("");
    setOpen(false);
  };

  const cancelFn = () => {
    setOpen(false);
  };

  const enableDisableQuickbooksDesktop = () => {
    if (hasLinkedQuickbooksDesktop) {
      setOpen(true);
    } else {
      setDisplayQuickbooksSetup(true);
    }
  };

  const handleDisplayButtonClick = async () => {
    const pass = await getQbcPass();
    setPassToDisplay(pass);
    setTimeout(() => {
      setPassToDisplay("****");
    }, 5000);
  };

  const handleOnchangeInput = ({ target: { value } }: any) => {
    setInputIncomeAccount(value);
    activeDeactiveDownloadButton(value);
  };

  const activeDeactiveDownloadButton = (value) => {
    if (value) {
      setDisableDownloadBtn(false);
    } else {
      setDisableDownloadBtn(true);
    }
  };

  const loadIncomeAccount = async () => {
    if (hasLinkedQuickbooksDesktop) {
      const incomeAccount = await getIncomeAccount();
      setIncomeAccount(incomeAccount);
      setInputIncomeAccount(incomeAccount);
    }
  };

  useEffect(() => {
    const hasQuickbooksDesktop = !!data?.linkedServices?.find(
      (service) =>
        service.service === OrganizationLinkedServiceName.QUICKBOOKS_DESKTOP && service.active
    );
    setHasLinkedQuickbooksDesktop(hasQuickbooksDesktop);

    loadIncomeAccount();
  }, [data]);

  useEffect(() => {
    activeDeactiveDownloadButton(incomeAccount);
  }, [incomeAccount]);

  useEffect(() => {
    loadIncomeAccount();
  }, [hasLinkedQuickbooksDesktop]);

  const handleDownloadQbcFileButtonClick = async () => {
    const response = await linkQuickbooksDesktop(inputIncomeAccount);
    if (response) {
      setHasLinkedQuickbooksDesktop(true);
      setIncomeAccount(inputIncomeAccount);
      // Download QBC File
      downloadQbcFile();
    }
  };

  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h4" fontWeight="medium">
                Organization Linked Services
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <MDTypography variant="h4">Quickbooks Online</MDTypography>
          <MDTypography variant="body2">
            You can link your Bitumio account with Quickbooks Online to automatically create
            invoices when a job is complete
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={3} textAlign={{ xs: "right", lg: "left" }}>
          <MDButton
            color="blue"
            onClick={() =>
              actionFnOnline({
                variables: { service: OrganizationLinkedServiceName.QUICKBOOKS },
              })
            }
          >
            {hasLinkedQuickbooksOnline ? `Disable` : `Setup`}
          </MDButton>
          <br />
          {hasLinkedQuickbooksOnline && (
            <MDTypography
              component={Link}
              to={getRoute("organization-settings.QuickbooksSettings")}
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Edit Settings
            </MDTypography>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} lg={9}>
          <MDTypography variant="h4">Quickbooks Desktop</MDTypography>
          <MDTypography variant="body2">
            You can link your Bitumio account with Quickbooks Desktop to automatically create
            invoices when a job is complete
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={3} textAlign={{ xs: "right", lg: "left" }}>
          <MDButton color="blue" onClick={() => enableDisableQuickbooksDesktop()}>
            {hasLinkedQuickbooksDesktop || displayQuickbooksSetup ? `Disable` : `Setup`}
          </MDButton>
          <ActionConfirmDialog
            open={open}
            setOpen={setOpen}
            handleAccept={acceptFn}
            handleCancel={cancelFn}
            title="Confirmation"
            content="Disable Quickbooks Desktop integration?"
          />
        </Grid>
        {(hasLinkedQuickbooksDesktop || displayQuickbooksSetup) && (
          <>
            <Grid item xs={6}>
              <MDTypography variant="body2" ml={2}>
                Income Account
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              {!incomeAccount ? (
                <MDInput
                  placeholder="Income Account..."
                  size="small"
                  value={inputIncomeAccount}
                  disabled={incomeAccount ? true : false}
                  onChange={(event: any) => {
                    handleOnchangeInput(event);
                  }}
                />
              ) : (
                <MDTypography variant="h6">{incomeAccount}</MDTypography>
              )}
            </Grid>
            <Grid item xs={6}>
              <MDTypography variant="body2" ml={2}>
                Quickbooks Web connector set up file:
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDButton
                color="blue"
                disabled={disableDownloadBtn}
                onClick={() => handleDownloadQbcFileButtonClick()}
              >
                Download QBC File
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDTypography variant="body2" ml={2}>
                Quickbooks Web connector password:
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              {!disableDownloadBtn && (
                <Grid container>
                  <Grid item xs={3} textAlign={"center"}>
                    <MDTypography variant="h5">{passToDisplay} </MDTypography>
                  </Grid>
                  <Grid item xs={3}>
                    <VisibilityRoundedIcon
                      color="inherit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleDisplayButtonClick()}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <MDButton
                component={Link}
                to={getRoute("organization-settings.QuickbooksDesktopQueueList")}
                variant="contained"
                color="info"
              >
                View Sync Queue
              </MDButton>
            </Grid>
          </>
        )}
        <Grid container spacing={3} mt={2} ml={1} sx={{ overflow: "hidden" }}>
          <Grid item lg={10}>
            <ApiKeys />
          </Grid>
        </Grid>
      </Grid>
    </DashboardPage>
  );
};

export default OrganizationLinkedServices;
