import getLocalString from "constants/Localization";
import {
  CustomGetProposalDocument,
  Proposal,
  useCustomAddProposalStageMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCustomAddProposalStage({ proposalId }: { proposalId: Proposal["id"] }) {
  const [mutation, { loading, error, data }] = useCustomAddProposalStageMutation({
    refetchQueries: [
      {
        query: CustomGetProposalDocument,
        variables: { id: proposalId },
      },
    ],
  });
  const [addProposalStage, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "addProposalStage",
    },
    {
      successMessage: getLocalString("proposals.add-stage-success"),
      failureMessage: getLocalString("proposals.add-stage-failure"),
    }
  );

  return [addProposalStage, context] as const;
}
