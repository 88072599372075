import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import MDAlert from "components/MDAlert";
import useGetJobWorkOrder from "hooks/jobs/useGetJobWorkOrder";
import { Skeleton } from "@mui/material";
import { JobPhase } from "generated/graphql";
import WorkOrderComponent from "pages/jobs/WorkOrderComponent";

export default function WorkOrderModal({ jobPhaseId }: { jobPhaseId: string }) {
  const { open, onClose, onOpen } = useModalState();

  const { loading, error, data } = useGetJobWorkOrder(jobPhaseId);

  return (
    <>
      <MDBox
        sx={{
          button: {
            padding: "6px",
            width: "120px",
          },
        }}
      >
        <MDButton size="small" type="button" color="blue" onClick={onOpen}>
          Work Order
        </MDButton>
      </MDBox>
      <Modal open={open} onClose={onClose}>
        <MDBox p={3}>
          {data && !loading && (
            <WorkOrderComponent data={data as JobPhase} isModal>
              {loading && <Skeleton />}
              {error && <MDAlert>{error}</MDAlert>}
            </WorkOrderComponent>
          )}
        </MDBox>
      </Modal>
    </>
  );
}
