import lengthEndAdornment from "constants/lenghtEndAdornment";
import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "applicationRate",
    label: "Depth of Excavation in Inches",
    inputProps: { ...lengthEndAdornment },
  },
];

export default function ExcavateOutForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
