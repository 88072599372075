import { AddCircle } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { TransferTruck } from "generated/graphql";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import { useState } from "react";
import { Box, Card } from "@mui/material";
import TransferTruckOptions from "modules/transferTrucks/TransferTruckOptions";
import MDBox from "components/MDBox";
import AutoComplete from "modules/AutoComplete/AutoComplete";

export default function AddTransferTrucks({
  onAdd,
  allowCreate = true,
}: {
  onAdd: (transferTruck: TransferTruck) => void;
  allowCreate?: boolean;
}) {
  const { open, onClose, onOpen } = useModalState();
  const [transferTruckId, setTransferTruckId] = useState<string | null>(null);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <MDBox p={3}>
          <MDTypography variant="h6" py={3}>
            Add Transfer Truck
          </MDTypography>

          <Box sx={{ width: "100%" }}>
            <TransferTruckOptions>
              {({ transferTrucksById, transferTruckOptions, loading, search }) => (
                <Card>
                  <MDBox p={3}>
                    <MDBox minWidth="160px">
                      <MDTypography variant="h6">Transfer Truck</MDTypography>
                    </MDBox>
                    <AutoComplete
                      options={transferTruckOptions}
                      field={{
                        onChange: setTransferTruckId,
                        value: transferTruckId,
                        onBlur: () => {},
                        name: "transferTruckId",
                        ref: null,
                      }}
                      loading={loading}
                      error={null}
                      label="Transfer Truck"
                      search={search}
                    />
                    <MDBox textAlign="right">
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          const transferTruck = transferTrucksById[transferTruckId];
                          if (transferTruck) {
                            onAdd(transferTruck);
                            onClose();
                          }
                        }}
                        disabled={!transferTruckId}
                      >
                        Add
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              )}
            </TransferTruckOptions>
          </Box>
        </MDBox>
      </Modal>
      <MDButton variant="gradient" startIcon={<AddCircle />} onClick={onOpen}>
        Add Transfer Truck
      </MDButton>
    </>
  );
}
