import getLocalString from "constants/Localization";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { CustomGetProposalsDocument, useCustomDeleteProposalMutation } from "generated/graphql";
import {
  companiesInitialSortDirectionValue,
  companiesInitialSortKeyValue,
  companiesSortKeyMap,
} from "hooks/companies/useCompaniesTable";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteProposal() {
  const [mutation, { loading, data, error }] = useCustomDeleteProposalMutation({
    fetchPolicy: "no-cache",
    refetchQueries: ["getProposals", "customGetProposals"],
    awaitRefetchQueries: true,
  });
  const [deleteProposal, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteProposal",
    },
    {
      failureMessage: getLocalString("proposals.delete-failure"),
      successMessage: getLocalString("proposals.delete-success"),
    }
  );
  return [deleteProposal, context] as const;
}
