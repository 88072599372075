import { z } from "zod";
import dayjs from "dayjs";
import getLocalString from "constants/Localization";

export const schema = z
  .object({
    uuid: z.string().uuid(),
    startTime: z
      .date()
      .or(z.string())
      .refine((date) => dayjs(date).isValid(), {
        message: "Invalid start time",
      })
      .refine((date) => dayjs(date).isBefore(dayjs()), {
        message: "Start time cannot be in the future",
      }),
    endTime: z
      .date()
      .or(z.string())
      .optional()
      .refine((date) => (date ? dayjs(date).isValid() : true), {
        message: "Invalid end time",
      })
      .refine((date) => (date ? dayjs(date).isBefore(dayjs()) : true), {
        message: "End time cannot be in the future",
      }),
    jobPhaseId: z.string().nullable().optional(),
    timeBucketId: z.string().nullable().optional(),
    notes: z.string().optional(),
  })
  .refine(
    (data) => {
      const start = dayjs(data.startTime);
      const end = dayjs(data.endTime);
      return end.isAfter(start);
    },
    {
      message: "End time must be after start time",
      path: ["endTime"],
    }
  )
  .refine(
    (data) => {
      const start = dayjs(data.startTime);
      const end = dayjs(data.endTime);
      return end.diff(start, "hours") <= 24;
    },
    {
      message: "End time cannot be more than 24 hours after start time",
      path: ["endTime"],
    }
  );

export type TimeEntryUpdateSchema = z.infer<typeof schema>;
