import * as React from "react";
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import SortIcon from "@mui/icons-material/Sort";
import { SortDirection } from "generated/graphql";

export default function SearchAndSortFiles({
  query,
  sortBy,
  sortDirection,
  handleInputChange,
  handleSortChange,
}: {
  query: string;
  sortBy: string;
  sortDirection: SortDirection;
  handleInputChange: (e: any) => void;
  handleSortChange: (c: string) => void;
}) {
  return (
    <MDBox textAlign="right" display="flex" alignItems="flex-end">
      <MDInput
        label={"Search"}
        variant="standard"
        size="small"
        value={query}
        onChange={handleInputChange}
      />
      <MDBox>
        <Tooltip title="Sort by name">
          <MDButton iconOnly onClick={() => handleSortChange("name")}>
            {sortBy === "name" ? (sortDirection === SortDirection.ASC ? "↑" : "↓") : ""}
            <SortByAlphaIcon />
          </MDButton>
        </Tooltip>
        <Tooltip title="Sort by date">
          <MDButton iconOnly onClick={() => handleSortChange("createdAt")}>
            {sortBy === "created_at" ? (sortDirection === SortDirection.ASC ? "↑" : "↓") : ""}
            <SortIcon />
          </MDButton>
        </Tooltip>
      </MDBox>
    </MDBox>
  );
}
