import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Crew } from "generated/graphql";
import useGetCrew from "hooks/organization/useGetCrew";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Update from "modules/OrganizationCrews/Update";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function CrewEditPage() {
  const { crewId } = useParams();
  const [getCrew, { loading, error, crew }] = useGetCrew();

  useEffect(() => {
    getCrew({ id: crewId });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load crew details. Please refresh this page or try again later.
        </MDAlert>
      )}
      {!error && (
        <MDBox my={3}>
          <MDBox mb={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h4" fontWeight="medium">
                  Updating Crew
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {crew && <Update crew={crew as Crew} />}
              {!crew && <Skeleton />}
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
