import { Card, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import { Employee, JobPhase, JobPhaseEmployee } from "generated/graphql";
import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { CustomJobPhaseCostItem } from "hooks/jobs/job-phases/useGetJobPhaseCostItem";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

export default function JobCostingSection({ jobPhase }: { jobPhase: CustomJobPhase }) {
  return (
    <Card>
      <MDBox sx={{ p: 4, overflow: "auto", maxWidth: "100%" }}>
        <MDTypography>Job Costing Summary</MDTypography>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Person</TableCell>
              <TableCell>Material Units</TableCell>
              <TableCell>Total Material Cost</TableCell>
              <TableCell>Total Labor Cost</TableCell>
              <TableCell>Total Equipment Cost</TableCell>
              <TableCell>Total Additional Cost</TableCell>
              <TableCell>Total Trucking Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobPhase?.costItems?.map((costItem, i) => (
              <TableRow key={i}>
                <TableCell>{dayjs(costItem?.date).format("MM/DD/YYYY")}</TableCell>
                <TableCell>{costItem?.user?.name ?? ""}</TableCell>
                <TableCell>{costItem?.material?.materialNeeded ?? 0}</TableCell>
                <TableCell>{formatCentsToUSD(costItem?.costMaterial ?? 0)}</TableCell>
                <TableCell>{formatCentsToUSD(costItem?.costLabour ?? 0)}</TableCell>
                <TableCell>{formatCentsToUSD(costItem?.costEquipment ?? 0)}</TableCell>
                <TableCell>{formatCentsToUSD(costItem?.costAdditional ?? 0)}</TableCell>
                <TableCell>{formatCentsToUSD(costItem?.costTransferTruck ?? 0)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MDBox>
    </Card>
  );
}
