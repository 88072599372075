import { useGetJob } from "hooks/jobs/useGetJob";
import { useParams } from "react-router";
import WorkOrderComponent from "./WorkOrderComponent";
import { JobPhase } from "generated/graphql";
import MDBox from "components/MDBox";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import MDButton from "components/MDButton";
import { Print } from "@mui/icons-material";
import { useRef } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { Card } from "@mui/material";

export default function JobWorkOrderPage() {
  const { jobId } = useParams();
  const contentRef = useRef<HTMLDivElement>(null);
  const { loading, data, error } = useGetJob({
    variables: {
      id: jobId,
    },
  });
  return (
    <DashboardLayout>
      <MDBox my={2} id="job-print" ref={contentRef} className="job-work-order-print">
        <MDBox display="flex" justifyContent="flex-end" px={2}>
          <MDButton
            className="no-print"
            variant="contained"
            color="blue"
            onClick={() => {
              window.print();
            }}
          >
            <Print />
          </MDButton>
        </MDBox>
        {data?.jobPhases?.map((jobPhase, index) => (
          <MDBox my={3} key={index}>
            <Card>
              <MDBox p={3}>
                <WorkOrderComponent data={jobPhase as JobPhase} isJob key={index} />
              </MDBox>
            </Card>
          </MDBox>
        ))}
      </MDBox>
    </DashboardLayout>
  );
}
