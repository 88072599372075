import { Grid, InputLabel } from "@mui/material";
import MDBox from "components/MDBox";
import MDSelect from "components/MDSelect/MDSelect";
import MDTypography from "components/MDTypography";
import MuiLink from "@mui/material/Link";
import { AddOrganizationDataUploadInput, OrganizationDataUploadType } from "generated/graphql";
import { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import FormField from "components/FormField/FormField";
import * as z from "zod";

import useGenerateUploadOrganizationDataUploadUrl from "hooks/organization/dataUploads/useGenerateUploadOrganizationDataUploadUrl";

import MDButton from "components/MDButton";
import { capitalCase } from "change-case";
import { zodResolver } from "@hookform/resolvers/zod";
import Link from "@mui/material/Link";
import { FileInput, useUppy } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";

interface DataUploadImportFormProps {
  onSubmit: (values: AddOrganizationDataUploadInput) => void;
  loading?: boolean;
  fileError?: boolean;
}
const optionalObject = z
  .object({
    sendToContact: z.boolean(),
    sendToUser: z.boolean(),
    sendToAllRecipients: z.boolean(),
  })
  .partial();

const schema = z.object({
  type: z.string().min(1, "Type is required"),
  path: z.string().min(1, "File is required"),
});

const templates: {
  [key in OrganizationDataUploadType]: string;
} = {
  [OrganizationDataUploadType.ADDITIONAL_COSTS]: "Additional Costs.xlsx",
  [OrganizationDataUploadType.COMPANIES]: "Companies.xlsx",
  [OrganizationDataUploadType.CONTACTS]: "Contacts.xlsx",
  [OrganizationDataUploadType.CREWS]: "Crews.xlsx",
  [OrganizationDataUploadType.EQUIPMENT_PIECES]: "Equipment Pieces.xlsx",
  [OrganizationDataUploadType.PLANTS]: "Plants.xlsx",
  [OrganizationDataUploadType.TRANSFER_TRUCKS]: "Transfer Trucks.xlsx",
};

export default function DataUploadImportForm({
  onSubmit,
  loading,
  fileError,
}: DataUploadImportFormProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    watch,
  } = useForm<AddOrganizationDataUploadInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      // @ts-ignore
      type: "",
      path: "",
    },
  });

  const [filename, setFilename] = useState("");
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const pathRef = useRef("");

  const [generateUploadOrganizationDataUploadUrl, { getData, isSuccess }] =
    useGenerateUploadOrganizationDataUploadUrl();

  useEffect(() => {
    if (fileError) {
      // @ts-ignore
      setError("path", { type: "custom", message: "Invalid Columns" });
    }
  }, [fileError]);

  const uppy = useUppy(() => {
    const _uppy = new Uppy({
      id: `file`,
      autoProceed: true,
      allowMultipleUploads: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          "text/csv",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        setFileUploading(true);
        const result = await generateUploadOrganizationDataUploadUrl({
          variables: {
            contentType: file.type,
            filename: file.name,
          },
        });
        const success = isSuccess(result.data);
        setFilename(file.name);
        if (success) {
          // setBtnColor("success");
          const data = getData(result);
          pathRef.current = data.path;
          return {
            method: data.method as "PUT",
            url: data.url,
            fields: {},
            headers: {
              "Content-Type": file.type,
            },
          };
        } else {
          throw new Error("Could not upload document");
        }
      },
    });

    _uppy.on("upload-success", (file, response) => {
      if (response.status === 200) {
        setFileUploading(false);
        setValue("path", pathRef.current);
        _uppy.removeFile(file.id);
      }
    });

    _uppy.on("restriction-failed", (file, error) => {
      if (error) {
        setFileUploading(false);
      }
    });

    _uppy.on("upload-error", (file) => {
      setFileUploading(false);
      setValue("path", "");

      if (file) {
        _uppy.removeFile(file.id);
      }
    });

    return _uppy;
  });

  const selectedType = watch("type");

  return (
    <MDBox
      role="form"
      component="form"
      onSubmit={handleSubmit(async (data) => await onSubmit(data))}
      px={4}
    >
      <MDBox mt={2}>
        <Grid spacing={3} container py={3}>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <MDSelect
                  {...field}
                  error={!!errors?.type?.message}
                  name="type"
                  value={field.value}
                  fullWidth
                  label="Select Type"
                  options={Object.keys(OrganizationDataUploadType).map((k) => ({
                    label: capitalCase(k),
                    value: k,
                  }))}
                />
              )}
            />

            {errors.type && (
              <MDTypography color="error" variant="caption">
                {errors.type.message}
              </MDTypography>
            )}

            {selectedType && (
              <MDBox mt={1}>
                <MuiLink
                  href={`${import.meta.env.VITE_CDN_BASE_URL}/data-import-templates/${
                    templates[selectedType]
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <MDButton variant="gradient" color="info">
                    Download {capitalCase(selectedType)} Template
                  </MDButton>
                </MuiLink>
              </MDBox>
            )}
          </Grid>
        </Grid>
        <Grid spacing={3} container py={3}>
          <Grid item xs={4} lg={4}>
            <MDBox>
              <InputLabel>File</InputLabel>
              <FormField
                {...register("path")}
                disabled={true}
                value={(fileUploading ? "Uploading... " : "") + filename}
              />
            </MDBox>
            {errors.path && (
              <MDTypography color="error" variant="caption">
                {errors.path.message}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={3} lg={3}>
            <MDBox display="flex">
              <Grid
                xs={4}
                lg={4}
                sx={{
                  ".uppy-FileInput-container": {
                    marginBottom: 0,
                  },
                }}
              >
                <FileInput
                  uppy={uppy}
                  pretty
                  locale={{
                    strings: {
                      chooseFiles: "Select File",
                    },
                  }}
                />
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
        <Grid spacing={3} container py={3}>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="flex-end" mt={2}>
              <MDButton
                variant="gradient"
                color="success"
                type="submit"
                disabled={loading || fileUploading}
              >
                Queue Import
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
