import getLocalString from "constants/Localization";
import { Proposal, useSendProposalMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSendProposal({ id }: { id: Proposal["id"] }) {
  const [mutation, { loading, data, error }] = useSendProposalMutation({
    refetchQueries: ["customGetProposal", "getProposal", "customGetProposals"],
  });

  const [sendProposal, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "sendProposal",
    },
    {
      successMessage: getLocalString("proposals.send-success"),
      failureMessage: getLocalString("proposals.send-failure"),
      showValidationErrors: true,
      hideSpecificError: "Please input a job size.",
    }
  );

  return [sendProposal, context] as const;
}
