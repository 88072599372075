import { ApolloError } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, Icon } from "@mui/material";
import AbsoluteCenterProgressLoader from "components/AbsoluteCenterProgressLoader/AbsoluteCenterProgressLoader";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Company } from "generated/graphql";

import { MergeCompanyInput } from "hooks/companies/useMergeCompany";

import Modal from "modules/Modal/Modal";

import { SubmitHandler, useForm } from "react-hook-form";
import * as z from "zod";
import MergeSourceCompany from "../MergeSourceCompany/MergeSourceCompany";
import MergeTargetCompany from "../MergeTargetcompany/MergeTargetCompany";

interface CompanyMergeModalProps {
  onClose: () => void;
  open: boolean;
  onSubmit: SubmitHandler<MergeCompanyInput>;
  id: Company["id"];
  loading: boolean;
  error: ApolloError;
}

export const schema = z.object({
  sourceCompany: z.string().min(1, "Source Company is Required"),
  targetCompany: z
    .string({ invalid_type_error: "Target Company is Required" })
    .min(1, "Target Company is Required"),
});

const CompanyMergeModal: React.FC<CompanyMergeModalProps> = ({
  onClose,
  open,
  onSubmit,
  id,
  loading,
  error,
}) => {
  const { control, handleSubmit } = useForm<MergeCompanyInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      sourceCompany: id,
      targetCompany: "",
    },
  });

  return (
    <Modal onClose={onClose} open={open}>
      <div style={{ position: "relative" }}>
        {loading && <AbsoluteCenterProgressLoader />}
        <MDBox
          style={{ height: "48px", backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)" }}
          display="flex"
          alignItems="center"
          p={2}
        >
          <MDTypography variant="body2" color="white">
            Merge Companies
          </MDTypography>
        </MDBox>
        {error && (
          <MDBox p={2}>
            <MDAlert color="error">{error.message}</MDAlert>
          </MDBox>
        )}
        <MDBox p={2}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems="center" position="relative">
              <Grid item xs={12} md={5} mt={6} minHeight={{ md: "200px" }}>
                <MergeSourceCompany id={id} />
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                my={2}
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight={{ md: "200px" }}
              >
                <Icon
                  fontSize="large"
                  sx={{
                    transform: { xs: "rotate(180deg) scale(1.5)", md: "rotate(90deg) scale(1.5)" },
                  }}
                >
                  call_merge
                </Icon>
              </Grid>
              <Grid item xs={12} md={5} mt={6} minHeight={{ md: "200px" }}>
                <MergeTargetCompany id={id} control={control} />
              </Grid>
            </Grid>
            <MDBox display="flex" width="100%" justifyContent="flex-end">
              <MDButton variant="gradient" color="info" type="submit">
                Merge
              </MDButton>
              <MDButton variant="gradient" color="white" onClick={onClose}>
                Cancel
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </div>
    </Modal>
  );
};

export default CompanyMergeModal;
