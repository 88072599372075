import {
  GetEmployeeQueryVariables,
  useGetEmployeeLazyQuery,
  useGetUserOrganizationQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetOrganization() {
  const { data, loading, error } = useGetUserOrganizationQuery();

  const organization = useMemo(() => {
    return (data && data.getUserOrganization) || null;
  }, [data]);

  return { data: organization, loading, error } as const;
}
