import { useUpdateTimeEntryMutation } from "generated/graphql";
import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useUpdateTimeEntry = () => {
  const [mutation, { loading, error, data }] = useUpdateTimeEntryMutation({
    refetchQueries: ["getTimeEntries"],
  });

  const [updateTimeEntry, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateTimeEntry",
    },
    {
      successMessage: getLocalString("time-tracking.update-time-entry-success"),
      failureMessage: getLocalString("time-tracking.update-time-entry-failure"),
      showValidationErrors: true,
    }
  );

  return [updateTimeEntry, context] as const;
};
