import FormField from "components/FormField/FormField";
import Decimal from "decimal.js";
import { Control, Controller } from "react-hook-form";

interface CurrencyFormFieldProps {
  control: Control<any, any>;
  name: string;
  label: string;
  inputProps: any;
  sendEmptyValuesAsNull: boolean;
}

export default function CurrencyFormField({
  control,
  name,
  label,
  inputProps,
  sendEmptyValuesAsNull,
  ...rest
}: CurrencyFormFieldProps & any) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormField
          align="right"
          label={label}
          defaultValues={0}
          inputProps={{
            type: "number",
            min: "0",
            step: "0.01",
            inputMode: "decimal",
            ...inputProps,
          }}
          error={error}
          {...field}
          {...rest}
          onChange={
            (event) =>
              !event.target.value
                ? sendEmptyValuesAsNull
                  ? field.onChange(null)
                  : field.onChange("")
                : field.onChange(parseFloat(new Decimal(event.target.value).times(100).toString())) // using decimal.js here because js cannot do floating point multiplication properly
          }
          value={field.value ? field.value / 100 : field.value}
        />
      )}
    />
  );
}
