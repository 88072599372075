import { Opportunity, UserPermissions } from "generated/graphql";
import UpdateOpportunityMenuItem from "modules/Opportunities/UpdateOpportunityMenuItem/UpdateOpportunityMenuItem";
import CancelOpportunityMenuItem from "modules/Opportunities/CancelOpportunityMenuItem/CancelOpportunityMenuItem";
import DeleteOpportunityMenuItem from "../DeleteOpportunityMenuItem/DeleteOpportunityMenuItem";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export default function OpportunityMenuItems({
  opportunity,
  closeMenu,
}: {
  opportunity: Opportunity;
  closeMenu: () => void;
}) {
  const { hasPermissions } = useCheckPermissions();
  return (
    <>
      {hasPermissions(UserPermissions.MANAGE_OPPORTUNITIES) && (
        <>
          <UpdateOpportunityMenuItem closeMenu={closeMenu} object={opportunity} />
          <CancelOpportunityMenuItem closeMenu={closeMenu} object={opportunity} />
          <DeleteOpportunityMenuItem closeMenu={closeMenu} object={opportunity} />
        </>
      )}
    </>
  );
}
