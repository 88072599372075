import getLocalString from "constants/Localization";
import { useAddProposalFileMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import React from "react";

export default function useAddProposalFile() {
  const [mutation, { loading, data, error }] = useAddProposalFileMutation();
  const [addProposalFile, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "addProposalFile",
    },
    {
      successMessage: getLocalString("proposals.add-proposal-file-success"),
      failureMessage: getLocalString("proposals.add-proposal-file-failure"),
    }
  );

  return [addProposalFile, context] as const;
}
