import { Card, Grid, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import {
  cleanAddJobPhaseCostsInput,
  JobPhaseCostItemInputSchema,
} from "DDD/action-objects/JobPhaseCostItemCreate";
import { UpdateJobPhaseNotesInputSchema } from "DDD/action-objects/JobPhaseNotesUpdate";
import { AddJobPhaseCostsInput, JobPhaseStatus, UpdateJobPhaseNotesInput } from "generated/graphql";

import useUpdateJobPhaseStatus from "hooks/jobs/job-phases/useUpdateJobPhaseStatus";
import useAddJobPhaseCosts from "hooks/jobs/job-phases/useAddJobPhaseCosts";
import React, { useCallback, useState } from "react";

import { JobPhaseContext } from "./context/JobPhaseContext";
import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";

import useGetJobPhaseCostItem from "hooks/jobs/job-phases/useGetJobPhaseCostItem";
import JobPhaseCost from "./forms/JobPhaseCost";
import JobPhaseNotesAndFiles from "./forms/JobPhaseNotesAndFiles";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import MDAlert from "components/MDAlert";
import useUpdateJobPhaseNotes from "hooks/jobs/job-phases/useUpdateJobPhaseNotes";
import JobCostingSection from "./JobCostingSection";

const View = React.memo(function ({
  jobPhase,
  addPhaseCostCompleted,
}: {
  jobPhase: CustomJobPhase;
  addPhaseCostCompleted: () => void;
}) {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const today = new Date(Date.now() - tzoffset).toISOString().slice(0, 10);
  const [date, setDate] = useState(today);
  const {
    loading: loadingJobPhaseCostItem,
    data: jobPhaseCostItem,
    error: errorJobPhaseCostItem,
  } = useGetJobPhaseCostItem({ date, jobPhaseId: jobPhase.id });

  const [updateJobPhaseStatus, { loading: loadingStatus }] = useUpdateJobPhaseStatus({
    id: jobPhase.id,
  });
  const [updateJobPhaseNotes, { loading: loadingNotes }] = useUpdateJobPhaseNotes();
  const [addJobPhaseCosts, { loading: loadingCosts }] = useAddJobPhaseCosts(addPhaseCostCompleted);

  const onSubmitJobPhaseCosts = useCallback(async (values: JobPhaseCostItemInputSchema) => {
    const cleanedValues = cleanAddJobPhaseCostsInput(values);
    await addJobPhaseCosts({
      variables: { input: cleanedValues as AddJobPhaseCostsInput },
    });
  }, []);

  const onSubmitJobPhaseNotes = useCallback(async (values: UpdateJobPhaseNotesInputSchema) => {
    await updateJobPhaseNotes({
      variables: { input: values as UpdateJobPhaseNotesInput },
    });
  }, []);

  const changeDate = async (selectedDate: Array<Date>) => {
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const formattedDate = new Date(selectedDate[0].getTime() - tzoffset).toISOString().slice(0, 10);
    setDate(formattedDate);
  };

  const updateStatus = () => {
    updateJobPhaseStatus({
      variables: {
        id: jobPhase?.id,
        status: JobPhaseStatus[jobPhase?.applicableStatuses[0].toUpperCase()],
      },
    });
  };

  return (
    <JobPhaseContext.Provider value={{ jobPhase, jobPhaseCostItem, date }}>
      <Grid item xs={12} display="flex" justifyContent="flex-end" paddingRight={3}>
        {jobPhase.status !== JobPhaseStatus.COMPLETE && (
          <MDButton
            type="button"
            variant="contained"
            color="info"
            onClick={() => updateStatus()}
            disabled={loadingStatus}
          >
            {loadingStatus ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              `Move to ${jobPhase?.applicableStatuses[0].replace("_", " ")}`
            )}
          </MDButton>
        )}
      </Grid>
      <JobPhaseNotesAndFiles onSubmit={onSubmitJobPhaseNotes} saving={loadingNotes} />
      <Card>
        <MDBox p={4}>
          <MDBox my={4}>
            <JobCostingSection jobPhase={jobPhase} />
          </MDBox>
          <MDBox>
            <MDTypography variant="h5">Date</MDTypography>
            <MDDatePicker defaultValue={today} onChange={changeDate}></MDDatePicker>
          </MDBox>
          {loadingJobPhaseCostItem && <CircularProgress color="inherit" size={20} />}
          {errorJobPhaseCostItem && (
            <MDAlert color="error">{parseGraphQLError(errorJobPhaseCostItem)}</MDAlert>
          )}
          {!loadingJobPhaseCostItem && !errorJobPhaseCostItem && (
            <JobPhaseCost key={date} saving={loadingCosts} onSubmit={onSubmitJobPhaseCosts} />
          )}
        </MDBox>
      </Card>
    </JobPhaseContext.Provider>
  );
});

View.displayName = "JobPhaseView";

export default View;
