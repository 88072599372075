import { TransferTruck } from "generated/graphql";
import * as z from "zod";

export const schema = z.object({
  id: z.string().optional().or(z.null()),
  name: z.string().min(1, { message: "Name is required" }),
  defaultTonnage: z
    .number()
    .min(1, { message: "Default Tonnage is required" })
    .max(255, { message: "Max value is 255" }),
  defaultRateHourly: z.number().min(0, { message: "Default Hourly Rate is required" }),
  defaultRateTon: z.number().min(0, { message: "Default Rate per Ton is required" }),
});

export const defaultValues = {
  name: "",
  defaultTonnage: 0,
  defaultRateHourly: 0,
  defaultRateTon: 0,
};

export function getDefaultValues(transferTruck: TransferTruck) {
  return {
    ...defaultValues,
    ...transferTruck,
  };
}
