import {
  Control,
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  SubmitHandler,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import CountrySelect from "components/CountrySelect/CountrySelect";
import { getDefaultValues, schema } from "DDD/action-objects/AddressEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateOrganizationAddressInput, OrganizationAddress } from "generated/graphql";
import { Card, Checkbox, FormControlLabel, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import StateSelect from "components/StateSelect/StateSelect";

export interface AddressFormProps {
  onSubmit: SubmitHandler<UpdateOrganizationAddressInput>;
  loading: boolean;
  address: OrganizationAddress;
}

export default function AddressForm({ onSubmit, loading, address }: AddressFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm<UpdateOrganizationAddressInput>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(address),
  });

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (rest: UpdateOrganizationAddressInput) => {
          // @ts-expect-error: FIX update types
          const { success, data: updatedAddress } = await onSubmit({
            ...rest,
          });
          if (success) {
            reset(getDefaultValues(updatedAddress));
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <FormFields
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Controller
                    name={"isPrimary"}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label="Primary Address"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

function FormFields({
  register,
  control,
  errors,
  watch,
  setValue,
}: {
  register: UseFormRegister<UpdateOrganizationAddressInput>;
  control: Control<UpdateOrganizationAddressInput, any>;
  errors: FieldErrorsImpl<DeepRequired<UpdateOrganizationAddressInput>>;
  watch: UseFormWatch<UpdateOrganizationAddressInput>;
  setValue: UseFormSetValue<UpdateOrganizationAddressInput>;
}) {
  return (
    <>
      <input type="hidden" {...register("id")} />
      <Grid item xs={12} sm={6}>
        <FormField
          type="text"
          label="Address Line 1"
          placeholder="Address Line 1"
          error={errors.line1}
          {...register("line1")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          type="text"
          label="Address Line 2"
          placeholder="Address Line 2"
          error={errors.line2}
          {...register("line2")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField type="text" label="Address City" error={errors.city} {...register("city")} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StateSelect
          name="state"
          label="Address State"
          control={control}
          countryFieldName="country"
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField type="text" label="Address Zip" error={errors.zip} {...register("zip")} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CountrySelect
          name="country"
          label="Address Country"
          defaultValue="US"
          required
          control={control}
        />
      </Grid>
    </>
  );
}
