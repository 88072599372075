import MDButton from "components/MDButton";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export default function DeleteStage({ acceptFn }) {
  const cancelFn = useCallback(async () => {
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you want to delete this Stage from the Proposal?"
        acceptText="Delete Stage"
      />
      <MDButton
        sx={{ minWidth: "190px" }}
        //@ts-ignore
        color="negative"
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Delete Stage
      </MDButton>
    </>
  );
}
