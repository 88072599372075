import getLocalString from "constants/Localization";
import { useToggleProposalProductOptionalityMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useToggleProposalProductOptionality() {
  const [mutation, { loading, error, data }] = useToggleProposalProductOptionalityMutation();

  const [toggleProposalProductOptionality, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "toggleProposalProductOptionality",
    },
    {
      successMessage: getLocalString("proposals.toggle-proposal-product-optionality-success"),
      failureMessage: getLocalString("proposals.toggle-proposal-product-optionality-failure"),
    }
  );

  return [toggleProposalProductOptionality, context] as const;
}
