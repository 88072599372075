import { FormControl, InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import { CustomPaginatorInfo, PaginatorInfo } from "generated/graphql";
import { clamp, range } from "ramda";
import { useCallback, useMemo, useState } from "react";

export interface Pagination {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  loadMore: () => void;
  pageOptions: number[];
  pages: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: () => void;
  prevPage: () => void;
  total: number;
}

export default function usePaginatedResources({
  paginate,
  paginatorInfo,
}: {
  paginate: ({ page, first }: { page?: number; first?: number }) => void;
  paginatorInfo: PaginatorInfo | CustomPaginatorInfo;
}) {
  const {
    currentPage = 1,
    hasMorePages = false,
    perPage = 10,
    total = 0,
    lastPage = 0,
  } = paginatorInfo ?? {};

  const [loadMorePageSize, setLoadMorePageSize] = useState(perPage);

  const setCurrentPage = useCallback(
    (page: number) => {
      paginate({ page });
    },
    [paginate]
  );

  const setPageSize = useCallback(
    (first: number) => {
      setLoadMorePageSize(first);
      paginate({ first });
    },
    [paginate]
  );

  const loadMore = useCallback(() => {
    paginate({ first: perPage + loadMorePageSize });
  }, [loadMorePageSize, paginate, perPage]);

  const handleRowsPerPageChange = (value: number) => setPageSize(clamp(10, 100, value));

  const pages = useMemo(() => {
    return lastPage;
  }, [lastPage]);

  const pageOptions = useMemo(() => {
    return range(1, pages + 1);
  }, [pages]);

  const hasNextPage = useMemo(() => {
    return hasMorePages;
  }, [hasMorePages]);

  const hasPrevPage = useMemo(() => {
    return currentPage > 1;
  }, [currentPage]);

  const nextPage = useCallback(() => {
    paginate({ page: currentPage + 1 });
  }, [paginate, currentPage]);

  const prevPage = useCallback(() => {
    paginate({ page: currentPage - 1 });
  }, [paginate, currentPage]);

  const component = useMemo(() => {
    return (
      <>
        <FormControl>
          <InputLabel id="rows-per-page-label">Rows Per Page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            id="rows-per-page-select"
            value={perPage}
            label="Rows Per Page"
            variant="standard"
            sx={{ minWidth: "110px", mr: 2 }}
            onChange={(event) => handleRowsPerPageChange(+event.target.value)}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={pages}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
        />
      </>
    );
  }, [pages, currentPage, perPage]);

  return {
    currentPage,
    setCurrentPage,
    pageSize: perPage,
    setPageSize,
    loadMore,
    pageOptions,
    pages,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    total,
    component,
    paginatorInfo,
  };
}
