import { ReactNode } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Company } from "generated/graphql";

function CompanyHeader({
  children,
  company,
}: {
  children?: ReactNode;
  company: Company;
}): JSX.Element {
  return (
    <MDBox position="relative" mb={5}>
      <Grid container spacing={3} alignItems="center">
        {company?.logo && (
          <Grid item>
            <MDAvatar
              src={`${import.meta.env.VITE_CDN_BASE_URL}/${company?.logo}`}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
        )}
        <Grid item>
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {company.name}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      {children}
    </MDBox>
  );
}

export default CompanyHeader;
