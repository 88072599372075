import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import {
  getDefaultValues as createGetDefaultValues,
  schema as AdditionalCostCreateSchema,
} from "DDD/action-objects/AdditionalCostCreate";
import {
  getDefaultValues as updateGetDefaultValues,
  schema as AdditionalCostUpdateSchema,
} from "DDD/action-objects/AdditionalCostUpdate";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AdditionalCost,
  AdditionalCostFeeType,
  CreateAdditionalCostInput,
  UpdateAdditionalCostInput,
} from "generated/graphql";
import {
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MDBox from "components/MDBox";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import { getRoute } from "utils/routing";
import feeTypeMapper from "constants/feeTypeMapper";
import currencyEndAdornment from "constants/currencyEndAdornment";
import { useEffect, useState } from "react";

type Action = "create" | "upsert";

export interface AdditionalCostFormProps {
  action: Action;
  onSubmit: SubmitHandler<any>;
  isOnModal?: boolean;
  loading: boolean;
  additionalCost?: AdditionalCost;
}

type FormData = {
  create: CreateAdditionalCostInput;
  upsert: UpdateAdditionalCostInput;
};

const schemas = {
  create: AdditionalCostCreateSchema,
  upsert: AdditionalCostUpdateSchema,
};

const getDefaultValues = (action: Action) => (initial: AdditionalCost) => {
  if (action === "create") {
    return createGetDefaultValues(initial);
  } else {
    return updateGetDefaultValues(initial);
  }
};

export default function AdditionalCostForm({
  action,
  onSubmit,
  loading,
  additionalCost,
  isOnModal = false,
}: AdditionalCostFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm<FormData[typeof action]>({
    resolver: zodResolver(schemas[action]),
    defaultValues: getDefaultValues(action)(additionalCost),
  });
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);
  const watchFeeType = watch("feeType");
  let minMaxProps = { min: 0, step: 0.01 };

  if (watchFeeType !== AdditionalCostFeeType.FLAT) {
    minMaxProps["max"] = 100;
  }

  useEffect(() => {
    if (
      [AdditionalCostFeeType.JOB_LENGTH, AdditionalCostFeeType.FLAT].includes(
        watchFeeType as AdditionalCostFeeType
      )
    ) {
      setDisabled(true);
      setValue("feeAmount", 1);
    } else {
      setValue("feeAmount", 100);
      setDisabled(false);
    }
  }, [watchFeeType]);

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (values) => {
          // @ts-expect-error: FIX update types
          const { success } = await onSubmit(values);
          if (success && !isOnModal) {
            navigate(getRoute("organization.additional-costs"));
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            {action === "upsert" && <input type="hidden" {...register("id")} />}
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                sx={{ m: 1.2, minWidth: "100%", marginLeft: "-3px" }}
                error={"feeType" in errors}
              >
                <InputLabel id="fee-type-select-label" sx={{ marginLeft: "-12px" }}>
                  Fee Type
                </InputLabel>
                <Select
                  labelId="fee-type-select-label"
                  label="Fee Type"
                  placeholder="Please select"
                  variant="standard"
                  fullWidth
                  defaultValue={getValues("feeType")}
                  sx={{
                    "&": {
                      marginTop: "13px",
                    },
                  }}
                  {...register("feeType")}
                >
                  {Object.keys(feeTypeMapper)
                    .filter(
                      (type) =>
                        action === "create" ||
                        (action === "upsert" &&
                          (type !== "JOB_COST" ||
                            (type === "JOB_COST" &&
                              additionalCost.feeType === AdditionalCostFeeType.JOB_COST)))
                    )
                    .map((k, i) => (
                      <MenuItem value={k} key={i}>
                        {feeTypeMapper[k]}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText sx={{ marginLeft: "0px" }}>
                  {errors?.feeType?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                inputProps={minMaxProps}
                label={watchFeeType === AdditionalCostFeeType.FLAT ? "Fee Amount" : "Percentage"}
                error={errors.feeAmount}
                disabled={disabled}
                {...register("feeAmount", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                align="right"
                label="Cost"
                error={errors.cost}
                {...register("cost", { valueAsNumber: true })}
                {...currencyEndAdornment}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
