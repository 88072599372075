import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateOpportunityMutation,
  UpdateOpportunityMutationVariables,
  useUpdateOpportunityMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

const useUpdateOpportunity = () => {
  const [mutation, { loading, data: graphqlData, error }] = useUpdateOpportunityMutation();
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateOpportunityMutation) => {
    return !!data?.updateOpportunity?.id;
  }, []);

  const updateOpportunity = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>
      ) => {
        return mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("opportunities.update-success"),
        failureMessage: getLocalString("opportunities.update-failure"),
      }
    ),
    [mutation]
  );

  const getData = useCallback(({ data }: { data?: UpdateOpportunityMutation }) => {
    return data?.updateOpportunity || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  return [updateOpportunity, { loading, data, error, isSuccess, getData }] as const;
};

export default useUpdateOpportunity;
