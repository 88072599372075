// @ts-nocheck

import * as L from "leaflet";
import { CirclePicker } from "react-color";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { MapAddress } from "interfaces/interfaces";
import { MAP_MODE } from "types/types";
import Line from "assets/images/line-segment.svg";
import Marker from "assets/images/map-marker.svg";
import Polygon from "assets/images/shape-square.svg";
import { ProductMeasurementJob } from "generated/graphql";

interface Props {
  map: L.Map;
  featureGroup: L.FeatureGroup | null;
  mapMode: MAP_MODE | null;
  currentDrawShape: L.PM.SUPPORTED_SHAPES | null;
  color: string;
  name: string;
  setColor: (color: string) => void;
  setName: (name: string) => void;
  isEditing: boolean;
  onSave: () => void;
  showSave: boolean;
  isDragging: boolean;
  toggleDrag: () => void;
  deleteLayer: () => void;
  total: {
    area: {
      feet: number;
      yards: number;
    };
    perimeter: {
      feet: number;
      yards: number;
    };
  };
  showLineLength: boolean;
  setShowLineLength: (showLineLength: boolean) => void;
  editingLayer: object;
  editingLayerType: string | null;
  toggleDrawMode: (
    map: L.Map,
    drawShape: L.PM.SUPPORTED_SHAPES,
    layerColor: string,
    isEditing: boolean,
    currentDrawShape: string | null
  ) => void;
  isTouch: boolean;
  selectPlace: (place: any) => void;
  clearMap: () => void;
  selectedAddress: MapAddress | null;
  hideLocation?: boolean;
  goBack?: boolean;
  showTools?: [keyof typeof ProductMeasurementJob];
  close?: () => void;
}

export default function Controls({
  map,
  currentDrawShape,
  color,
  setColor,
  name,
  setName,
  isEditing,
  onSave,
  isDragging,
  toggleDrag,
  deleteLayer,
  total,
  showLineLength,
  setShowLineLength,
  editingLayer,
  editingLayerType,
  toggleDrawMode,
  isTouch,
  selectPlace,
  clearMap,
  selectedAddress,
  showSave,
  hideLocation = false,
  goBack = false,
  showTools = ["LINEAR_FOOT", "SQUARE_FOOT", "SQUARE_YARD"],
  close,
}: Props) {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const options = {
    componentRestrictions: { country: ["USA", "CA"] },
    fields: ["address_components", "geometry"],
  };
  useEffect(() => {
    if (!map) {
      return;
    }

    autoCompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, options);

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      if (place?.name) {
        alert("Invalid address");
        return;
      }
      inputRef.current.value = "";

      selectPlace(place);
    });
  }, [map]);

  return (
    <>
      <div className={"map-controls-container"}>
        <div>{!hideLocation && <input ref={inputRef} className="map-control-input" />}</div>
        {selectedAddress && (
          <div
            style={{
              fontSize: "12px",
              fontFamily: "Arial",
            }}
          >
            {selectedAddress.line1}, {selectedAddress.city}, {selectedAddress.state},{" "}
            {selectedAddress.zip}, {selectedAddress.country}
          </div>
        )}

        <div className="map-controls">
          {showTools.some((x) => x === "SQUARE_FOOT" || x === "SQUARE_YARD" || x === "UNIT") && (
            <button
              title={`${currentDrawShape === "Polygon" ? "Disable" : "Enable"} Polygon mode`}
              className={`map-control ${
                currentDrawShape === "Polygon" ? "map-control-active" : ""
              }`}
              onClick={() => toggleDrawMode(map, "Polygon", color, isEditing, currentDrawShape)}
            >
              <img src={Polygon} alt="Polygon Icon" />
              Measure Area
            </button>
          )}
          {showTools.some((x) => x === "LINEAR_FOOT" || x === "UNIT") && (
            <button
              title={`${currentDrawShape === "Line" ? "Disable" : "Enable"} Line mode`}
              className={`map-control ${currentDrawShape === "Line" ? "map-control-active" : ""}`}
              onClick={() => toggleDrawMode(map, "Line", color, isEditing, currentDrawShape)}
            >
              <img src={Line} alt="Line Icon" />
              Measure Length
            </button>
          )}
          <button
            title={`${currentDrawShape === "Marker" ? "Disable" : "Enable"} Marker mode`}
            className={`map-control ${currentDrawShape === "Marker" ? "map-control-active" : ""}`}
            onClick={() => toggleDrawMode(map, "Marker", color, isEditing, currentDrawShape)}
          >
            <img src={Marker} alt="Map Marker Icon" />
            Add Map Marker
          </button>
        </div>
        {editingLayerType != "label" && (
          <div className={"map-controls-color-picker"}>
            <CirclePicker
              color={color}
              onChangeComplete={(color) => {
                setColor(color.hex);
              }}
              circleSize={20}
              circleSpacing={5}
            />
          </div>
        )}
        {isEditing && editingLayerType != "label" && (
          <input
            type={"text"}
            placeholder="Name"
            value={name}
            onKeyUp={(e) => e.stopPropagation()}
            onChange={(e) => setName(e.target.value)}
          />
        )}
        {isEditing && !(editingLayer.layer instanceof L.Marker) && (
          <>
            <input
              type={"checkbox"}
              name="showLineLength"
              onChange={() => setShowLineLength(!showLineLength)}
              checked={showLineLength}
            />
            <label htmlFor="showLineLength"> Show line length</label>
          </>
        )}
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <p style={{ fontSize: "16px" }}>
              <strong>Total Area: </strong>
            </p>{" "}
            <p style={{ fontSize: "16px" }}>
              {total.area.feet}ft<sup>2</sup> / {total.area.yards}yd
              <sup>2</sup>
            </p>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <p style={{ fontSize: "16px" }}>
              <strong>Total Perimeter: </strong>{" "}
            </p>
            <p style={{ fontSize: "16px" }}>
              {total.perimeter.feet}ft / {total.perimeter.yards}yd
            </p>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          {isTouch && isEditing && (
            <>
              {(editingLayerType == "label" || editingLayer.layer instanceof L.Marker) && (
                <>
                  <button onClick={toggleDrag} className="map-contol-buttons">
                    {isDragging ? "Stop Drag" : "Drag"}
                  </button>
                  &nbsp;
                </>
              )}
              {editingLayerType != "label" && (
                <>
                  <button onClick={deleteLayer} className="map-contol-buttons">
                    Delete
                  </button>
                  &nbsp;
                </>
              )}
            </>
          )}
          {showSave && editingLayerType != "label" && (
            <button
              onClick={onSave}
              className="map-contol-buttons"
              style={{
                marginRight: "10px",
              }}
            >
              Save
            </button>
          )}
          <button onClick={clearMap} className="map-contol-buttons">
            Clear Map
          </button>
          {goBack && (
            <Link to={"/"}>
              <button
                style={{
                  margin: "0 10px",
                }}
                className="map-contol-buttons"
              >
                Go to Dashboard
              </button>
            </Link>
          )}
          {close && (
            <button
              style={{
                margin: "0 10px",
              }}
              className="map-contol-buttons"
              onClick={close}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </>
  );
}
