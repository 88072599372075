import React from "react";
import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Company } from "generated/graphql";
import useGetAllCompanies from "hooks/companies/useGetAllCompanies";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import { useMemo } from "react";
import { Controller } from "react-hook-form";

const MergeTargetCompany: React.FC<{ control: any; id: Company["id"] }> = ({ control, id }) => {
  const {
    loading: loadingCompanies,
    error: errorCompanies,
    data: companies,
  } = useGetAllCompanies();

  const options = useMemo(() => {
    if (!companies) {
      return [];
    }

    return (
      companies
        // @ts-ignore
        .map((company: Company) => {
          if (company.id === id) {
            return null;
          }

          return {
            label: `${company.name} - Proposals: ${company.proposalsCount} - Jobs: ${company.jobsCount}`,
            value: company.id,
          };
        })
        .filter(Boolean)
        .sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? 1 : -1))
    );
  }, [companies]);

  if (loadingCompanies) {
    return (
      <MDBox display="flex" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress color="inherit" />
      </MDBox>
    );
  }

  return (
    <div>
      <MDBox display="flex" flexDirection="column">
        <MDBox display="flex" justifyContent="center" mb={4}>
          <Icon fontSize="large" sx={{ transform: "scale(2)" }}>
            business
          </Icon>
        </MDBox>
        <MDTypography variant="h6" component="h6" py={2}>
          Target Company to merge into
        </MDTypography>
        <Controller
          name="targetCompany"
          control={control}
          render={({ field, formState: { errors } }) => {
            const error = errors.targetCompany;
            return (
              <>
                <AutoComplete
                  options={options}
                  field={field}
                  loading={loadingCompanies}
                  error={(error?.message ?? "") as string}
                  label="Target Company"
                />
              </>
            );
          }}
        />
      </MDBox>
    </div>
  );
};

export default MergeTargetCompany;
