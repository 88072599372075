import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { User } from "generated/graphql";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import useDeleteUserInvite from "hooks/users/useDeleteUserInvite";
import { useCallback } from "react";

export default function DeleteInviteMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: User["id"];
}) {
  const [deleteUserInvite, { isSuccess }] = useDeleteUserInvite();

  const acceptFn = useCallback(async () => {
    const { data } = await deleteUserInvite({ id });
    if (isSuccess(data)) {
      closeMenu();
      return { message: "Successfully deleted user invite." };
    } else {
      return { message: "Failed to delete user invite. Please try again in a couple of minutes." };
    }
  }, [deleteUserInvite, id]);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you would like to delete this invite?"
      />
      <MenuItem onClick={() => setOpen(true)}>Delete Invite</MenuItem>
    </>
  );
}
