import getLocalString from "constants/Localization";

import { GetPlantsDocument, useCreatePlantMutation } from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreatePlant() {
  const { offset, filtering } = usePaginatedVariables();
  const [mutation, { loading, data, error }] = useCreatePlantMutation({
    refetchQueries: [
      {
        query: GetPlantsDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
        },
      },
    ],
  });

  const [createPlant, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createPlant",
    },
    {
      successMessage: getLocalString("organization.create-plant-success"),
      failureMessage: getLocalString("organization.create-plant-failure"),
    }
  );

  return [createPlant, context] as const;
}
