import { useCustomMeLazyQuery, useMeLazyQuery, User } from "generated/graphql";
import React, { useCallback } from "react";

export default function useMe() {
  const [getMe, { data, loading, error }] = useCustomMeLazyQuery();

  const me = useCallback(() => {
    getMe();
  }, []);

  const user = ((data && data?.me) || null) as User | null;

  return [me, { data, loading, error, user }] as const;
}
