import {
  GetOrganizationEmailTemplateQueryVariables,
  useCustomGetOrganizationEmailTemplateQuery,
} from "generated/graphql";
import { useMemo } from "react";

export default function useGetOrganizationEmailTemplate(
  variables: GetOrganizationEmailTemplateQueryVariables
) {
  const {
    data: graphqlData,
    loading,
    error,
  } = useCustomGetOrganizationEmailTemplateQuery({
    variables,
  });

  const data = useMemo(() => {
    return graphqlData?.getOrganizationEmailTemplate || null;
  }, [graphqlData]);
  return { data, loading, error } as const;
}
