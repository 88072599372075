import InfoProposalMenuItem from "components/proposals/MenuItems/InfoProposalMenuItem";
import ViewProposalMenuItem from "components/proposals/MenuItems/ViewProposalMenuItem";
import { Proposal, ProposalStatus } from "generated/graphql";
import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import DuplicateProposal from "../buttons/DuplicateProposal/DuplicateProposal";

export default function VersionMenuItems({
  proposal,
  closeMenu,
}: {
  proposal: Proposal;
  closeMenu: () => void;
}) {
  return (
    <>
      <ViewProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      <MenuItem data-test-id="action-info" onClick={closeMenu}>
        <DuplicateProposal
          id={proposal.id}
          status={proposal.status}
          normalText
          text="Use Version"
          variables={{ createNewVersion: true }}
          redirectRoute="proposals.info"
        />
      </MenuItem>
    </>
  );
}
