import { MenuItem } from "@mui/material";
import { Company } from "generated/graphql";
import { Link } from "react-router-dom";

export default function UpdateCompanyMenuItem(props: BitumioMenuItem<Company>) {
  const { id, closeMenu } = props;

  return (
    <MenuItem component={Link} onClick={closeMenu} to={`/companies/${id}/update`}>
      Update
    </MenuItem>
  );
}
