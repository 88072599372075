import getLocalString from "constants/Localization";
import MDAlert from "components/MDAlert";
import AddressForm from "components/organization/AddressForm";
import { UpdateOrganizationAddressInput, OrganizationAddress } from "generated/graphql";
import useUpdateOrganizationAddress from "hooks/organization/useUpdateOrganizationAddress";
import { useCallback } from "react";
import { getRoute } from "utils/routing";
import { useNavigate } from "react-router";

export default function Update({ address }: { address: OrganizationAddress }) {
  const [
    updateOrganizationAddress,
    { loading, error, address: updatedAddress, isSuccess, getAddress },
  ] = useUpdateOrganizationAddress();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (input: UpdateOrganizationAddressInput) => {
    const { data } = await updateOrganizationAddress({ variables: { input } });
    const success = isSuccess(data);
    if (success) {
      navigate(getRoute("organization-settings.organization.settings"));
    }
    return { success, data: getAddress(data) };
  }, []);

  return (
    <>
      {error && (
        <MDAlert color="error">{getLocalString("organization.update-address-failure")}</MDAlert>
      )}
      <AddressForm
        address={(updatedAddress ?? address) as OrganizationAddress}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </>
  );
}
