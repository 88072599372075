import { GetAdditionalCostQueryVariables, useGetAdditionalCostLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetAdditionalCost() {
  const [query, { data, loading, error }] = useGetAdditionalCostLazyQuery();

  const getAdditionalCost = useCallback(
    (values: GetAdditionalCostQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const additionalCost = useMemo(() => {
    return (data && data.getAdditionalCost) || null;
  }, [data]);

  return [getAdditionalCost, { data, loading, error, additionalCost }] as const;
}
