import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { EquipmentPiece, SortDirection } from "generated/graphql";
import useGetEquipmentPieces from "hooks/organization/useGetEquipmentPieces";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources, { Pagination } from "hooks/usePaginatedResources";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function EquipmentPieceOptions({
  children,
}: {
  children: ({
    equipmentPieceOptions,
    loading,
    search,
    equipmentPiecesById,
    pagination,
  }: {
    equipmentPiecesById: Record<SelectOptions[number]["value"], EquipmentPiece>;
    equipmentPieceOptions: SelectOptions;
    loading: boolean;
    search: (v: string) => void;
    pagination: Pagination;
  }) => React.ReactNode;
}) {
  const { filtering, offset } = usePaginatedVariables();

  const { loading, data, error, paginatorInfo } = useGetEquipmentPieces(
    {
      first: offset.first,
      page: offset.page,
      sort: SortDirection.ASC,
      search: filtering.debouncedSearchTerm,
    },
    { fetchPolicy: "network-only" }
  );

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const equipmentPieces = data ?? [];

  const equipmentPieceOptions = equipmentPieces.map((equipmentPiece) => ({
    label: equipmentPiece.name,
    value: equipmentPiece.id,
  }));

  const equipmentPiecesById = equipmentPieces.reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val,
    };
  }, {});

  return (
    <>
      {children({
        equipmentPiecesById,
        equipmentPieceOptions,
        loading,
        search: filtering.setSearchTerm,
        pagination,
      })}
    </>
  );
}
