import { useMemo } from "react";
import UserActionsCell from "modules/users/UserActionsCell/UserActionsCell";
import UserRolesCell from "modules/users/UserRolesCell/UserRolesCell";
import usePaginatedResources from "hooks/usePaginatedResources";
import useGetOrganizationUsers from "./useGetOrganizationUsers";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import UserStatusCell from "modules/users/UserStatusCell/UserStatusCell";

export default function useOrganizationUsersTable() {
  const { filtering, offset } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo } = useGetOrganizationUsers({
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
  });
  const pagination = usePaginatedResources({
    paginatorInfo,
    paginate: offset.paginate,
  });

  const columns = useMemo(() => {
    return [
      { Header: "First Name", accessor: "firstName", width: "20%" },
      { Header: "Last Name", accessor: "lastName", width: "20%" },
      { Header: "Email", accessor: "email" },
      { Header: "Roles", accessor: "roles", Cell: UserRolesCell },
      { Header: "Status", accessor: "status", Cell: UserStatusCell },
      {
        Header: "Actions",
        accessor: "id",
        Cell: UserActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
  } as const;
}
