import getLocalString from "constants/Localization";
import { useGenerateUploadProjectFileUrlMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import React from "react";

export default function useGenerateUploadProjectFileUrl() {
  const [mutation, { loading, error, data }] = useGenerateUploadProjectFileUrlMutation();
  const [generateUploadProjectFileUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "generateUploadProjectFileUrl",
    },
    {
      hideMessage: true,
    }
  );

  return [generateUploadProjectFileUrl, context] as const;
}
