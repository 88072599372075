import { useValidateRegistrationTokenMutation } from "generated/graphql";
import { useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import isValidEmail from "utils/validations/isValidEmail";

export default function useValidateRegistrationToken() {
  // Get email and email_verification_token from query string
  const [getSearchParams] = useSearchParams();
  const [validateRegistrationTokenMutation, { data, loading, error }] =
    useValidateRegistrationTokenMutation();
  const [localError, setLocalError] = useState<string | null>(null);
  const email = useRef<null | string>(null);
  const token = useRef<null | string>(null);
  const success = data && data.validateRegistrationToken === true;
  const failure = data && data.validateRegistrationToken === false;

  const validateRegistrationToken = useCallback(() => {
    email.current = getSearchParams.get("email");
    token.current = getSearchParams.get("email_verification_token");

    if (!email.current || !isValidEmail(email.current) || !token.current) {
      setLocalError("Missing Email or Token to validate.");
      return;
    }

    validateRegistrationTokenMutation({
      variables: {
        input: {
          email: email.current,
          token: token.current,
        },
      },
    });
  }, [getSearchParams]);

  return [
    validateRegistrationToken,
    {
      data,
      loading,
      success,
      failure,
      error: error || localError,
      email: email.current,
      token: token.current,
    },
  ] as const;
}
