import { useCallback, useMemo, useState, useEffect } from "react";
import { useGetTimeEntries } from "./useGetTimeEntries";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { sortDirectionMap } from "constants/sortDirectionMap";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  timeEntriesInitialSortDirectionKey,
  timeEntriesInitialSortKeyKey,
  timeEntriesInitialSortKeyValue,
  timeEntriesSortKeyMap,
} from "constants/timeEntries/sortKeys";
import TimeEntryActionsCell from "modules/TimeEntries/TimeEntryActionsCell";
import { JobPhaseAutocomplete } from "components/JobPhaseScrollAsyncAutocomplete/JobPhaseAutocomplete";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import MDTypography from "components/MDTypography";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

dayjs.extend(duration);

export const useTimeEntriesData = () => {
  const { filtering, offset, sorting } = usePaginatedVariables({
    initialSortDirection: timeEntriesInitialSortDirectionKey,
    initialSortKey: timeEntriesInitialSortKeyKey,
    initialJobPhaseId: null,
    initialUserID: null,
    initialForDate: null,
    initialForDateStart: null,
    initialForDateEnd: null,
  });

  const stripTime = (date: string) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  const { data, loading, error, paginatorInfo } = useGetTimeEntries({
    variables: {
      page: offset.page,
      first: offset.first,
      sortKey: timeEntriesSortKeyMap[sorting.sortKey] || timeEntriesInitialSortKeyValue,
      sortDirection: sortDirectionMap[sorting.sortDirection],
      jobPhaseId: filtering.jobPhaseId,
      userId: filtering.userID,
      forDate: filtering.forDate ? stripTime(filtering.forDate) : null,
      forDateStart: filtering.forDateStart ? stripTime(filtering.forDateStart) : null,
      forDateEnd: filtering.forDateEnd ? stripTime(filtering.forDateEnd) : null,
    },
  });

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const JobPhaseFilter = useCallback(() => {
    return (
      <JobPhaseAutocomplete>
        {({ options, queryLoading, pagination, handleSearch }) => (
          <>
            <MDBox
              display="flex"
              width="100%"
              alignItems="center"
              sx={{ "& > div:first-of-type": { width: filtering.jobPhaseId ? "96%" : "100%" } }}
            >
              <ScrollAsyncAutocomplete
                label="Filter by Job Phase"
                value={options.find((x) => x.value === filtering.jobPhaseId)}
                onChange={(value) => filtering.setJobPhaseId(value as string)}
                options={options}
                loading={queryLoading}
                hasMore={pagination.hasNextPage}
                search={handleSearch}
                onLoadMore={pagination.loadMore}
              />
              {filtering.jobPhaseId && (
                <MDBox ml={1}>
                  <MDTypography
                    component="i"
                    variant="body2"
                    color="secondary"
                    onClick={() => filtering.setJobPhaseId(null)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "error.main",
                      },
                    }}
                  >
                    &#x2715;
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </>
        )}
      </JobPhaseAutocomplete>
    );
  }, [filtering]);

  const UserFilter = useCallback(() => {
    return (
      <OrganizationUsersOptions queryOverride={{ first: 1000 }}>
        {({ organizationUsersOptions, loading }) => (
          <AutoComplete
            options={organizationUsersOptions}
            loading={loading}
            variant="outlined"
            field={{
              onChange: (value) => filtering.setUserID(value as string),
              onBlur: () => {},
              value: filtering.userID,
              name: "userID",
              ref: null,
            }}
            error={null}
            placeholder="Select User"
          />
        )}
      </OrganizationUsersOptions>
    );
  }, [filtering.setUserID, filtering.userID]);

  const DateFilter = useCallback(() => {
    const [filterType, setFilterType] = useState<"single" | "range">("single");
    const [tempStartDate, setTempStartDate] = useState<string | null>(filtering.forDateStart);
    const [tempEndDate, setTempEndDate] = useState<string | null>(filtering.forDateEnd);

    useEffect(() => {
      if (filtering.forDate) {
        setFilterType("single");
      } else if (filtering.forDateStart || filtering.forDateEnd) {
        setFilterType("range");
      }
    }, []);

    const handleRangeDateChange = (date: Date | null, isStart: boolean) => {
      const formattedDate = date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null;

      if (isStart) {
        setTempStartDate(formattedDate);
        if (formattedDate && tempEndDate) {
          filtering.setForDateStart(formattedDate);
          filtering.setForDateEnd(tempEndDate);
        }
      } else {
        setTempEndDate(formattedDate);
        if (tempStartDate && formattedDate) {
          filtering.setForDateStart(tempStartDate);
          filtering.setForDateEnd(formattedDate);
        }
      }
    };

    return (
      <MDBox width="100%" display="flex" gap={2}>
        <MDBox flex="1" sx={{ "& .react-datepicker-wrapper": { width: "100%", maxWidth: "100%" } }}>
          {filterType === "single" ? (
            <DatePicker
              selected={filtering.forDate ? new Date(filtering.forDate) : null}
              onChange={(date) => {
                filtering.setForDate(date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null);
              }}
              dateFormat="yyyy-MM-dd"
              isClearable
              placeholderText="Select Single Date"
              customInput={
                <MDInput
                  variant="outlined"
                  label="Filter by Date"
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                      {
                        height: "53px",
                        maxWidth: "100%",
                        width: "100%",
                      },
                  }}
                />
              }
            />
          ) : (
            <MDBox display="flex" gap={1}>
              <DatePicker
                selected={tempStartDate ? new Date(tempStartDate) : null}
                onChange={(date) => handleRangeDateChange(date, true)}
                dateFormat="yyyy-MM-dd"
                isClearable
                placeholderText="Start Date"
                customInput={
                  <MDInput
                    variant="outlined"
                    label="Start Date"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                        {
                          height: "53px",
                          maxWidth: "100%",
                          width: "100%",
                        },
                    }}
                  />
                }
              />
              <DatePicker
                selected={tempEndDate ? new Date(tempEndDate) : null}
                onChange={(date) => handleRangeDateChange(date, false)}
                dateFormat="yyyy-MM-dd"
                isClearable
                placeholderText="End Date"
                customInput={
                  <MDInput
                    variant="outlined"
                    label="End Date"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                        {
                          height: "53px",
                          maxWidth: "100%",
                          width: "100%",
                        },
                    }}
                  />
                }
              />
            </MDBox>
          )}
        </MDBox>

        <MDBox display="flex" flexDirection="column" gap={1} justifyContent="center" pr={2}>
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={filterType === "single" ? "#1A73E8" : "text"}
            sx={{
              color: filterType === "single" ? "#1A73E8" : "text",
              cursor: "pointer",
              textDecoration: filterType === "single" ? "underline" : "none",
              "&:hover": { color: "#1A73E8" },
            }}
            onClick={() => {
              setFilterType("single");
              setTempStartDate(null);
              setTempEndDate(null);
              filtering.setForDateStart(null);
              filtering.setForDateEnd(null);
            }}
          >
            SINGLE DATE
          </MDTypography>
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={filterType === "range" ? "#1A73E8" : "text"}
            sx={{
              color: filterType === "range" ? "#1A73E8" : "text",
              cursor: "pointer",
              textDecoration: filterType === "range" ? "underline" : "none",
              "&:hover": { color: "#1A73E8" },
            }}
            onClick={() => {
              setFilterType("range");
              filtering.setForDate(null);
            }}
          >
            DATE RANGE
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }, [filtering]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Date",
        accessor: "forDate",
      },
      {
        Header: "User",
        accessor: "user.name",
      },
      {
        Header: "Job ID",
        accessor: "jobPhase.externalId",
        Cell: ({ value, row }) => (
          <MDTypography
            component="a"
            href={`/jobs/${row.original.jobPhase?.job.id}`}
            color="info"
            sx={{
              cursor: "pointer",
            }}
            variant="button"
          >
            {value}
          </MDTypography>
        ),
      },
      {
        Header: "Job Name",
        accessor: "jobPhase.proposalStage.name",
        disableSortBy: true,
      },
      {
        Header: "Job Address",
        accessor: "jobPhase.address",
        disableSortBy: true,
      },
      {
        Header: "Clock In Time",
        accessor: "startTime",
      },
      {
        Header: "Clock Out Time",
        accessor: "endTime",
      },
      {
        Header: "Total Hours",
        accessor: "timeTrackedSeconds",
        Cell: ({ value }) => {
          return dayjs.duration(value, "seconds").format("H:mm");
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        align: "right",
        disableSortBy: true,
        Cell: TimeEntryActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
    sorting,
    JobPhaseFilter,
    UserFilter,
    DateFilter,
  } as const;
};
