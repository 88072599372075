import getLocalString from "constants/Localization";

import {
  UpdateRolesWithPermissionsMutationVariables,
  useUpdateRolesWithPermissionsMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateRolesPermissions() {
  const [mutation, { loading, data, error }] = useUpdateRolesWithPermissionsMutation({
    refetchQueries: ["getRolesWithPermissions"],
  });

  const [updateRolesWithPermissions, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateRolesWithPermissions",
    },
    {
      showValidationErrors: true,
      successMessage: getLocalString("organization.update-roles-with-permissions-success"),
      failureMessage: getLocalString("organization.update-roles-with-permissions-failure"),
    }
  );

  return [updateRolesWithPermissions, context] as const;
}
