import { FormHelperText, FormLabel, Grid } from "@mui/material";
import { useState } from "react";
import { CompactPicker } from "react-color";
import { Controller } from "react-hook-form";
import reactCSS from "reactcss";
import MDTypography from "components/MDTypography";
import CustomMultipleSelect from "components/Shared/CustomMultipleSelect/CustomMultipleSelect";
import CustomSelect, { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import CrewOptions from "modules/crews/CrewOptions";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import { ColorPickerProps, colors } from "modules/OrganizationCrews/Create";
import { convertHexToRGB } from "utils/object/getRandomColor";
import "react-datepicker/dist/react-datepicker.css";

type AssignTo = "crew" | "individual";
type Props = {
  colorPicker: ColorPickerProps;
  setColorPicker: React.Dispatch<React.SetStateAction<ColorPickerProps>>;
  methods: any;
  errors: any;
};
export default function SelectCrewOrIndividual({
  colorPicker,
  setColorPicker,
  methods,
  errors,
}: Props) {
  const getRecipientsLabel = (id: string, organizationUserOptions: SelectOptions) => {
    const organizationUser = organizationUserOptions.find((x) => x.value === id);
    return organizationUser?.label;
  };

  const [assignTo, setAssignTo] = useState<AssignTo | null>(
    (methods.getValues("assignTo") as AssignTo) || null
  );
  const handleClick = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: !colorPicker.displayColorPicker });
  };

  const handleClose = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: false });
  };

  const handleChange = (color) => {
    setColorPicker({
      ...colorPicker,
      color: {
        hex: color.hex,
        rgb: convertHexToRGB(color.hex),
      },
    });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${colorPicker.color?.rgb?.r}, ${colorPicker.color?.rgb?.g}, ${colorPicker.color?.rgb?.b}, 1)`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        maxWidth: "45px",
        marginTop: "20px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <>
      <Grid container spacing={1} alignItems="center" width="100%">
        <Grid item xs={2}>
          <FormLabel>Assign To</FormLabel>
        </Grid>
        <Grid item xs={3} mb={1}>
          <CustomSelect
            control={methods.control}
            placeholder="Assign to"
            fullWidth
            selectVariant="standard"
            data={[
              { label: "Crew", value: "crew" },
              { label: "Individual", value: "individual" },
            ]}
            name="assignTo"
            onChange={(e) => {
              setAssignTo(e.target.value as AssignTo);
              methods.setValue("crewId", "");
              methods.setValue("userIDs", []);
            }}
          />
        </Grid>
      </Grid>
      {assignTo === "crew" && (
        <Grid container spacing={1} alignItems="center" width="100%">
          <Grid item xs={2}>
            <FormLabel>Select Crew</FormLabel>
          </Grid>
          <Grid item xs={3}>
            <CrewOptions>
              {({ crewOptions }) => (
                <CustomSelect
                  placeholder="Select Crew"
                  control={methods.control}
                  fullWidth
                  selectVariant="standard"
                  data={crewOptions}
                  name="crewId"
                  {...methods.register("crewId")}
                />
              )}
            </CrewOptions>
          </Grid>
        </Grid>
      )}

      {errors?.crewId?.message && (
        <FormHelperText error sx={{ marginLeft: "0px" }}>
          {errors?.crewId?.message}
        </FormHelperText>
      )}
      {assignTo === "individual" && (
        <>
          <Grid container spacing={1} alignItems="center" width="100%">
            <Grid item xs={2}>
              <FormLabel>Select Individuals</FormLabel>
            </Grid>

            <Grid item xs={3}>
              <OrganizationUsersOptions queryOverride={{ first: 1000 }}>
                {({ organizationUsersOptions, loading }) => (
                  <Grid>
                    <Grid>
                      <Controller
                        name="userIDs"
                        control={methods.control}
                        render={({ field }) => (
                          <CustomMultipleSelect
                            {...field}
                            fullWidth
                            onChange={(e) => {
                              field.onChange(e?.map((x) => x.value as string));
                            }}
                            name={"userIDs"}
                            label=""
                            value={
                              field.value?.map((s) => ({
                                label: getRecipientsLabel(s as string, organizationUsersOptions),
                                value: s,
                              })) ?? []
                            }
                            options={organizationUsersOptions}
                          />
                        )}
                      />
                    </Grid>

                    {errors?.userIDs?.message && (
                      <MDTypography variant="caption" color="error">
                        {errors?.userIDs?.message}
                      </MDTypography>
                    )}
                  </Grid>
                )}
              </OrganizationUsersOptions>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center" width="100%">
            <Grid item xs={2}>
              <FormLabel>Color</FormLabel>
            </Grid>
            <Grid item xs={10}>
              <div style={styles.swatch} onClick={handleClick} id="colorpicker">
                <div style={styles.color} />
              </div>
              {colorPicker.displayColorPicker ? (
                // @ts-expect-error
                <div style={styles.popover}>
                  {/* @ts-expect-error */}
                  <div style={styles.cover} onClick={handleClose} />
                  <CompactPicker
                    color={colorPicker.color?.hex}
                    onChange={handleChange}
                    colors={colors}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
