import getLocalString from "constants/Localization";
import { Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CreateTransferTruckInput, CreateTransferTruckMutationResult } from "generated/graphql";
import useCreateTransferTruck from "hooks/organization/useCreateTransferTruck";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Create from "modules/OrganizationTransferTrucks/Create";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function TransferTruckCreatePage() {
  const [createTransferTruck, { loading, error, isSuccess }] = useCreateTransferTruck();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: CreateTransferTruckInput
    ): Promise<FormSubmitResult<CreateTransferTruckMutationResult>> => {
      const result = await createTransferTruck({ variables: { input: values } });
      const data = result.data;
      const success = isSuccess(data);
      if (success) {
        navigate(getRoute("organization.transfer-trucks"));
      }
      return { success, data };
    },
    [createTransferTruck]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {error && (
        <MDAlert color="error">
          {getLocalString("organization.create-transfer-truck-failure")}
        </MDAlert>
      )}
      <MDBox mt={12} mb={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Create Transfer Truck
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Create onSubmit={onSubmit} loading={loading} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
