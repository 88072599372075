import getLocalString from "constants/Localization";
import { useCustomUpdateJobNotesMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateJobNotes() {
  const [mutation, { loading, data, error }] = useCustomUpdateJobNotesMutation();
  const [updateJobNotes, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateJobNotes",
    },
    {
      successMessage: getLocalString("jobs.update-job-notes-success"),
      failureMessage: getLocalString("jobs.update-job-notes-failure"),
    }
  );

  return [updateJobNotes, context] as const;
}
