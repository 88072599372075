import { Card } from "@mui/material";
import CompanyForm from "components/companies/CompanyForm/CompanyForm";
import MDAlert from "components/MDAlert";
import { Company, UpdateCompanyInput } from "generated/graphql";
import useUpdateCompany from "hooks/companies/useUpdateCompany";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getRoute } from "utils/routing";

const UpdateCompany: React.FC<{ company: Company }> = ({ company }) => {
  const [updateCompany, { loading, data: updatedCompany, isSuccess, getData, error }] =
    useUpdateCompany();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (input: UpdateCompanyInput) => {
      const result = await updateCompany({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      return { success, data };
    },
    [updateCompany]
  );

  const handleSuccess = useCallback(
    (data) => {
      navigate(getRoute("companies.view", [["companyId", data.id]]));
    },
    [navigate]
  );

  return (
    <>
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <Card>
        <CompanyForm
          action="upsert"
          loading={loading}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          company={updatedCompany ?? company}
        />
      </Card>
    </>
  );
};

export default UpdateCompany;
