import { useBitumioContext } from "providers/BitumioContext";
import { useEffect } from "react";
import { getRoute } from "utils/routing";
import { Navigate } from "react-router-dom";

export default function SignOutPage() {
  const [{ signOff, signedInUser }] = useBitumioContext();

  useEffect(() => {
    // Before signoff check if there is actually a logged in user
    if (signedInUser) {
      signOff();
    }
  }, [signedInUser]);

  return signedInUser ? (
    <div>Signing you out</div>
  ) : (
    <Navigate to={getRoute("signin")} replace={true} />
  );
}
