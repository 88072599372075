import getLocalString from "constants/Localization";
import { useCustomScheduleJobPhaseBlockMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useScheduleJobPhaseBlock() {
  const [mutation, { loading, data, error }] = useCustomScheduleJobPhaseBlockMutation({
    refetchQueries: [
      "customGetJobSchedules",
      "customGetJobPhase",
      "getJobSchedules",
      "getJobPhase",
    ],
  });

  const [scheduleJobPhaseBlock, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "scheduleJobPhaseBlock",
    },
    {
      successMessage: getLocalString("jobPhases.reordering-job-phase-success"),
      failureMessage: getLocalString("jobPhases.reordering-job-phase-failure"),
    }
  );

  return [scheduleJobPhaseBlock, context] as const;
}
