import { GetTransferTruckQueryVariables, useGetTransferTruckLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetTransferTruck() {
  const [query, { data: graphqlData, loading, error }] = useGetTransferTruckLazyQuery();

  const getTransferTruck = useCallback(
    (values: GetTransferTruckQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const data = useMemo(() => {
    return graphqlData?.getTransferTruck ?? null;
  }, [graphqlData]);

  return [getTransferTruck, { data, loading, error }] as const;
}
