import { Proposal } from "generated/graphql";
import { useCallback } from "react";
import ProposalContext from "./providers/ProposalContextProvider";
import { ProposalFormsContext } from "./providers/ProposalFormsContextProvider";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { headerCase } from "change-case";
import ProposalSummary from "./ProposalSummary";
import ProposalStages from "./ProposalStages";
import { Grid } from "@mui/material";

export default function ViewProposal({ proposal }: { proposal: Proposal }) {
  const writeProposalProduct = useCallback(() => {}, []);
  const { stages, company } = proposal;

  return (
    <ProposalFormsContext.Provider
      value={{
        productsFormsStatus: {},
        productsSubmitFunctions: {},
        setProductSubmitFunction: (proposalProductId, submitFn) => {},
        setProductDirtyStatus: (productId, isDirty) => {},
        setProductFormStatus: (productId, status) => {},
      }}
    >
      <ProposalContext.Provider
        value={{
          proposal,
          writeProposalProduct,
          inViewMode: true,
        }}
      >
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h5">
            Proposal Client:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </MDTypography>
          <MDTypography variant="body">{company.name}</MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h5">Proposal Number:&nbsp;&nbsp;</MDTypography>
          <Grid item xs={12} md={2}>
            {proposal.version}
          </Grid>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={3}>
          <MDTypography variant="h5">Proposal Source:&nbsp;&nbsp;&nbsp;&nbsp;</MDTypography>
          <MDTypography variant="body">{headerCase(proposal.source || "")}</MDTypography>
        </MDBox>
        {stages?.length > 0 && <ProposalStages />}
        <ProposalSummary />
      </ProposalContext.Provider>
    </ProposalFormsContext.Provider>
  );
}
