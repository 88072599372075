import getLocalString from "constants/Localization";

import {
  GetOrganizationDataUploadsDocument,
  useAddOrganizationDataUploadMutation,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddOrganizationDataUpload() {
  const { offset } = usePaginatedVariables();
  const [mutation, { loading, data, error }] = useAddOrganizationDataUploadMutation({
    refetchQueries: [
      {
        query: GetOrganizationDataUploadsDocument,
        variables: {
          page: offset.page,
          first: offset.first,
        },
      },
    ],
  });

  const [addOrganizationDataUploadMutation, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "addOrganizationDataUpload",
    },
    {
      successMessage: getLocalString("organization.create-data-upload-success"),
      failureMessage: getLocalString("organization.create-data-upload-failure"),
    }
  );

  return [addOrganizationDataUploadMutation, context] as const;
}
