import { Icon } from "@mui/material";
import AuthWall from "modules/AuthWall/AuthWall";
import ForgotPasswordPage from "pages/forgot-password";
import ResetPasswordPage from "pages/reset-password";
import SignInPage from "pages/signin";
import SignOutPage from "pages/signout";
import SignUpPage from "pages/signup";
import SignupResendPage from "pages/signup/resend";
import SignupVerifyPage from "pages/signup/verify";

export const authRoutes = [
  {
    name: "Sign Up",
    key: "signup",
    route: "/signup",
    component: <SignUpPage />,
  },
  {
    name: "Sign Up Resend Confirmation",
    key: "signup.resend",
    route: "/signup/resend",
    component: <SignupResendPage />,
  },
  {
    name: "Sign Up Verify",
    key: "signup.verify",
    route: "/signup/verify",
    component: <SignupVerifyPage />,
  },
  {
    name: "Forgot Password",
    key: "forgot-password",
    route: "/forgot-password",
    component: <ForgotPasswordPage />,
  },
  {
    name: "Reset Password",
    key: "reset-password",
    route: "/reset-password",
    component: <ResetPasswordPage />,
  },
  {
    name: "Sign In",
    key: "signin",
    route: "/signin",
    component: <SignInPage />,
  },
  {
    type: "collapse",
    name: "User",
    key: "user",
    collapse: [
      {
        name: "Sign Out",
        key: "signout",
        route: "/signout",
        component: <SignOutPage />,
      },
    ],
  },
  { type: "divider" },
] as const;
