import { Route } from "routes";

export const getRoutesKeys = (arrayOfRoutes: Route[], prefix = "") => {
  return arrayOfRoutes.reduce((acc: Record<string, string>, val): Record<string, string> => {
    if (val.type === "collapse") {
      return {
        ...acc,
        ...getRoutesKeys(val.collapse ?? [], val.key),
      };
    }

    return {
      ...acc,
      [`${prefix !== "" ? prefix + "." : prefix}${val.key}`]: val.route ?? val.href ?? "",
    };
  }, {});
};

export default getRoutesKeys;
