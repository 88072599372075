import { useCustomUpdateProposalProductMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateProposalProduct() {
  const [mutation, { loading, data, error }] = useCustomUpdateProposalProductMutation({
    refetchQueries: ["getProposal", "getProduct"],
  });
  const [updateProposalProduct, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateProposalProduct",
    },
    {
      hideMessage: true,
    }
  );

  return [updateProposalProduct, context] as const;
}
