import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { UserRole, UserStatus } from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import useGetOrganizationUsers from "hooks/users/useGetOrganizationUsers";
import { uniq } from "ramda";
import React from "react";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getFullName } from "utils/people/getFullName";

const userMap = (user) => ({
  label: getFullName(user),
  value: user.id,
  hasEmployee: !!user.hasEmployee,
});

export default function OrganizationUsersOptions({
  children,
  selected,
  queryOverride,
}: {
  children: ({
    organizationUsersOptions,
    loading,
    search,
  }: {
    organizationUsersOptions: SelectOptions;
    loading: boolean;
    search: (v: string) => void;
  }) => React.ReactNode;
  selected?: SelectOptions[number];
  queryOverride?: { first?: number; roles?: UserRole[]; status?: UserStatus };
}) {
  const { filtering } = usePaginatedVariables();

  const { loading, data, error } = useGetOrganizationUsers({
    page: 1,
    first: 10,
    search: filtering.debouncedSearchTerm,
    ...(queryOverride && queryOverride),
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const users = data ?? [];

  const organizationUsersOptions = selected
    ? uniq([...users.map(userMap), { ...selected, hasEmployee: selected.hasEmployee ?? false }])
    : users.map(userMap);

  return (
    <>
      {children({
        organizationUsersOptions,
        loading,
        search: filtering.setSearchTerm,
      })}
    </>
  );
}
