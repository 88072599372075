import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import useAddress from "hooks/address/useAddress";
import { Controller, useFormState } from "react-hook-form";

export default function CountrySelect({
  name,
  label,
  control,
  required,
  defaultValue,
}: {
  name: string;
  label: string;
  control: any;
  required?: boolean;
  defaultValue?: string;
}) {
  const {
    countries: { selectOptions: options },
  } = useAddress(null);
  const { errors } = useFormState({ control });
  const error = errors[name];

  return (
    <div>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={options}
            onChange={(e, value: (typeof options)[number]) => field.onChange(value?.value || null)}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? options.find((o) => o.value === option || o.label === option)?.label || ""
                : option.label
            }
            isOptionEqualToValue={(option, value) =>
              option.label === value.label || option.value === value || option.label === value
            }
            renderInput={(params) => (
              <>
                <MDInput
                  {...params}
                  label={label}
                  required={required}
                  variant="standard"
                  error={error}
                />
                {error && (
                  <MDTypography color="error" variant="caption">
                    {error.message}
                  </MDTypography>
                )}
              </>
            )}
          />
        )}
      />
    </div>
  );
}
