import {
  useCustomGetJobPhasesForDropdownLazyQuery,
  useCustomGetJobPhasesLazyQuery,
  useCustomGetJobPhasesScheduleBlocksLazyQuery,
} from "generated/graphql";
import { useEffect } from "react";
import dayjs from "dayjs";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { jobPhasesSortKeyMap, jobPhasesInitialSortKeyValue } from "../useJobPhasesTableData";

export default function useGetJobPhases({
  filtering,
  sorting,
  offset,
  searchByName = false,
  isForDropdown = false,
}) {
  const fn = isForDropdown
    ? useCustomGetJobPhasesForDropdownLazyQuery
    : useCustomGetJobPhasesLazyQuery;
  const [getJobPhases, { loading, data: graphqlDataPhases, error }] = fn();

  const [
    getJobPhasesScheduleBlocks,
    {
      loading: jobPhasesScheduleBlocksLoading,
      error: jobPhasesScheduleBlocksError,
      data: graphqlScheduleBlocksData,
    },
  ] = useCustomGetJobPhasesScheduleBlocksLazyQuery();

  useEffect(() => {
    if (filtering.startsAt) {
      getJobPhasesScheduleBlocks({
        variables: {
          search: filtering.debouncedSearchTerm,
          sortDirection: sortDirectionMap[sorting.sortDirection],
          sortKey:
            jobPhasesSortKeyMap[sorting.sortKey.toLowerCase()] || jobPhasesInitialSortKeyValue,
          first: offset.first,
          page: offset.page,
          companyId: filtering.companyId ?? null,
          userId: filtering.userID ?? null,
          crewId: filtering.crewID ?? null,
          foremanUserId: filtering.foremanUserId ?? null,
          date: filtering.startsAt
            ? dayjs
                .utc(new Date(filtering.startsAt + " " + "00:00:00"))
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        },
        fetchPolicy: "no-cache",
      });
    }
    if (!filtering.startsAt) {
      getJobPhases({
        variables: {
          search: filtering.debouncedSearchTerm,
          sortDirection: sortDirectionMap[sorting.sortDirection],
          sortKey:
            jobPhasesSortKeyMap[sorting.sortKey.toLowerCase()] || jobPhasesInitialSortKeyValue,
          first: offset.first,
          page: offset.page,
          companyId: filtering.companyId ?? null,
          billingStatuses: filtering.billingStatuses,
          userId: filtering.userID ?? null,
          crewId: filtering.crewID ?? null,
          foremanUserId: filtering.foremanUserId ?? null,
          searchByName,
          date: filtering.startsAt
            ? dayjs
                .utc(new Date(filtering.startsAt + " " + "00:00:00"))
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
        },
        fetchPolicy: "no-cache",
      });
    }
  }, [
    filtering.startsAt,
    filtering.companyId,
    filtering.userID,
    filtering.crewID,
    filtering.foremanUserId,
    filtering.billingStatuses,
    offset.page,
    offset.first,
    filtering.debouncedSearchTerm,
    sorting.sortDirection,
    sorting.sortKey,
  ]);

  const jobPhasesData = filtering.startsAt
    ? graphqlScheduleBlocksData?.getJobPhases.data
    : graphqlDataPhases?.getJobPhases.data;

  const paginationData = filtering.startsAt
    ? graphqlScheduleBlocksData?.getJobPhases.paginatorInfo
    : graphqlDataPhases?.getJobPhases?.paginatorInfo;

  const queryLoading = filtering.startsAt ? jobPhasesScheduleBlocksLoading : loading;
  const queryError = filtering.startsAt ? jobPhasesScheduleBlocksError : error;

  return {
    jobPhasesData,
    paginationData,
    queryLoading,
    queryError,
  };
}
