import * as z from "zod";

export const zodEmailValidator = z.string().trim().email();

export const isValidEmail = (email: string): boolean => {
  try {
    zodEmailValidator.parse(email);
    return true;
  } catch {
    return false;
  }
};

export default isValidEmail;
