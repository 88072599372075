import { FileInput, useUppy } from "@uppy/react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useRef, useState } from "react";
import { ProjectFile, ProjectFileType } from "generated/graphql";
import { CircularProgress, Icon, IconButton, Tooltip } from "@mui/material";
import { useCreateProjectFileFolder } from "hooks/projectFiles/useCreateProjectFileFolder";
import useGenerateUploadProjectFileUrl from "hooks/projectFiles/useGenerateUploadProjectFileUrl";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import useAddProjectFile from "hooks/projectFiles/useAddProjectFile";

const AddFileOrFolder = ({
  fileView,
  loading,
  lastSelectedItem,
  fileById,
  folderView,
}: {
  fileView?: boolean;
  loading?: boolean;
  lastSelectedItem: string;
  fileById: ProjectFile;
  folderView?: boolean;
}) => {
  const [showAddFolderInput, setShowAddFolderInput] = useState(false);
  const fileByIdRef = useRef<null | ProjectFile>();
  const [addFolderInput, setAddFolderInput] = useState("");

  fileByIdRef.current = fileById;

  const [
    generateUploadProjectFileUrl,
    { getData, isSuccess: generateUrlSuccess, loading: loadingGenerateUrl },
  ] = useGenerateUploadProjectFileUrl();
  const [addProjectFile, { loading: loadingAddProjectFile }] = useAddProjectFile();
  const [createProjectFileFolder, { isSuccess }] = useCreateProjectFileFolder();

  const pathRef = useRef("");
  const resetAddFolderInput = () => {
    setShowAddFolderInput(false);
    setAddFolderInput("");
  };
  const handleAddFolderInput = () => {
    if (!showAddFolderInput) {
      return setShowAddFolderInput(true);
    }

    createProjectFileFolder({
      variables: {
        name: addFolderInput,
        ...(lastSelectedItem && {
          parentFolderId:
            fileById?.type === ProjectFileType.FOLDER ? fileById.id : fileById?.parentFolderId,
        }),
      },
    });

    if (isSuccess) {
      resetAddFolderInput();
    }
  };

  const uppy = useUppy(() => {
    const _uppy = new Uppy({
      id: `organizationFile`,
      autoProceed: true,
      allowMultipleUploads: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf", ".heic", ".mp4", ".mov"],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const result = await generateUploadProjectFileUrl({
          variables: {
            contentType: file.type,
            filename: file.name,
          },
        });
        const success = generateUrlSuccess(result.data);
        if (success) {
          const data = getData(result);

          pathRef.current = data.path;
          return {
            method: data.method as "PUT",
            url: data.url,
            fields: {},
            headers: {
              "Content-Type": data.contentType,
              ...(data.contentDisposition && { "Content-Disposition": data.contentDisposition }),
            },
          };
        } else {
          throw new Error("Could not upload file");
        }
      },
    });

    _uppy.on("upload-success", (file, response) => {
      if (response.status === 200) {
        addProjectFile({
          variables: {
            name: file.name,
            path: pathRef.current,
            parentFolderId:
              fileByIdRef.current?.type === ProjectFileType.FOLDER
                ? fileByIdRef.current.id
                : fileByIdRef.current?.parentFolderId,
          },
        });

        _uppy.removeFile(file.id);
      }
    });

    _uppy.on("restriction-failed", (file, error) => {
      if (error) {
        //
      }
    });

    _uppy.on("upload-error", (file) => {
      if (file) {
        // setErrorMessage("An error has ocurred while uploading this file");
        _uppy.removeFile(file.id);
      }
      // setBtnColor("info");
    });

    return _uppy;
  });

  const shouldDisableAddFolderButton = showAddFolderInput && addFolderInput.length < 1;

  return (
    <>
      {loadingAddProjectFile && (
        <MDBox textAlign="center">
          <CircularProgress />
        </MDBox>
      )}
      <MDBox p={2} display={folderView ? "inline-flex" : "flex"} justifyContent="space-between">
        <MDBox>
          {showAddFolderInput && (
            <MDInput
              placeholder="Folder name"
              value={addFolderInput}
              onChange={(e) => setAddFolderInput(e.target.value)}
            />
          )}
          {folderView && (
            <>
              <Tooltip title={showAddFolderInput ? "Save" : "Create new Folder"}>
                <IconButton onClick={handleAddFolderInput} disabled={shouldDisableAddFolderButton}>
                  <Icon>{showAddFolderInput ? "save" : "create_new_folder"}</Icon>
                </IconButton>
              </Tooltip>
            </>
          )}
          {!folderView && !fileView && (
            <MDButton
              variant="gradient"
              type="button"
              color="primary"
              disabled={fileView || loading || shouldDisableAddFolderButton}
              onClick={handleAddFolderInput}
              sx={{ height: "100%" }}
            >
              {showAddFolderInput ? "Save" : "Add Folder"}
            </MDButton>
          )}
        </MDBox>
        {!folderView && (
          <MDBox
            sx={{
              ".uppy-FileInput-container": {
                marginBottom: 0,
              },
              ".uppy-FileInput-btn": {
                fontWeight: "bold",
                fontSize: "12px",
                padding: "12px 22px",
              },
            }}
          >
            <FileInput
              uppy={uppy}
              pretty
              locale={{
                strings: { chooseFiles: "ADD FILES" },
              }}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default AddFileOrFolder;
