import { Card, Grid, Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable/DataTable";
import { JobPhase } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

export default function JobCostSummarySection({ phases }: { phases: JobPhase[] }) {
  const columns = [
    {
      Header: "Phase",
      accessor: "name",
      width: "20%",
    },
    {
      Header: "",
      accessor: "type",
      width: "20%",
    },
    {
      Header: "Total",
      accessor: "total",
      width: "20%",
      Cell: (props) => (props.value ? formatCentsToUSD(props.value) : ""),
    },
    {
      Header: "Expense",
      accessor: "expense",
      width: "20%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "Profit",
      accessor: "profit",
      width: "20%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "Profit Margin",
      accessor: "profitMargin",
      width: "10%",
      Cell: (props) => `${props.value.toFixed(2)}%`,
    },
  ];

  // See https://lucid.app/lucidchart/599eabbb-9b60-4641-9461-67a3be617924/edit?invitationId=inv_c32d2ca4-69b4-4e85-856f-1518a3d35ccd&page=0_0#
  // https://app.clickup.com/t/863g1wern
  // for notes on calculation
  // Please consult the links above before making changes
  const buildTableRows = () => {
    const tableRows = [];
    phases.forEach((phase) => {
      tableRows.push({
        name: phase.proposalProduct.material.name,
        type: "Proposed",
        total: phase.proposedOverallTotal,
        expense: phase.proposedCostTotal,
        profit: phase.proposedProfit,
        profitMargin: (+phase.proposedProfit / +phase.proposedOverallTotal) * 100,
      });
      tableRows.push({
        name: "",
        type: "Actuals",
        total: "",
        expense: phase.actualCostTotal,
        profit: +phase.proposedOverallTotal - +phase.actualCostTotal,
        profitMargin:
          ((+phase.proposedOverallTotal - +phase.actualCostTotal) / +phase.proposedOverallTotal) *
          100,
      });
    });

    return tableRows;
  };

  const tableData = { columns, rows: buildTableRows() };

  return (
    <Card>
      <MDBox mt={4} p={4}>
        <MDTypography>Job Cost Summary</MDTypography>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <DataTable table={tableData} showTotalEntries={false} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
