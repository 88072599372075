import getLocalString from "constants/Localization";
import { useGenerateUploadProposalFileUrlMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import React from "react";

export default function useGenerateUploadProposalFileUrl() {
  const [mutation, { loading, error, data }] = useGenerateUploadProposalFileUrlMutation();
  const [generateUploadProposalFileUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "generateUploadProposalFileUrl",
    },
    {
      hideMessage: true,
    }
  );

  return [generateUploadProposalFileUrl, context] as const;
}
