import React, { createContext, useState } from "react";
export const SidebarContext = createContext(null);

export const SidebarProvider = ({ children }) => {
  const [clickedButton, setClickedButton] = useState("");

  const clickedButtonHandle = (button) => {
    setClickedButton(button);
    setTimeout(() => {
      setClickedButton("");
    }, 100);
  };

  return (
    <SidebarContext.Provider value={{ clickedButton, clickedButtonHandle }}>
      {children}
    </SidebarContext.Provider>
  );
};
