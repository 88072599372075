// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import Footer from "examples/Footer";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import SalesTable from "examples/Tables/SalesTable";

// Images
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";

import { useGetDashboardStats } from "hooks/dashboard/useGetDashboardStats";
import moment from "moment";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import PieChartDefault from "examples/Charts/PieChart";
import { AvailableColors, Chart } from "types/types";
import Map from "components/Dashboard/Map";
import YearToDateStats from "components/Dashboard/YearToDateStats";
import LastMonthVsThisMonthStats from "components/Dashboard/LastMonthVsThisMonthStats";
import YearToDateJobCompletion from "components/Dashboard/YearToDateJobCompletion";
import JobCostingDetails from "components/Dashboard/JobCostingDetails";
import MaterialNeededPerDay from "components/Dashboard/MaterialNeededPerDay";

export default function Dashboard(): JSX.Element {
  const {
    currentMonthData,
    previousMonthData,
    personData,
    jobScheduleByAmountData,
    lastYearData,
    yearToDateData,
    productData,
    materialNeededByDayChartData,
    getBackgroundColors,
    productMaterialUsedTableData,
    productsUsedPercentagesTableData,
    mapData,
    materialNeededByDay,
    allMaterials,
  } = useGetDashboardStats();
  const lastYearDataObj = {
    sentBids: lastYearData?.sentBids.slice().sort((a, b) => {
      if (b.year > a.year) return -1;
      if (b.year < a.year) return 1;
      if (b.month > a.month) return -1;
      if (b.month < a.month) return 1;
    }),
    closedBids: lastYearData?.closedBids.slice().sort((a, b) => {
      if (b.year > a.year) return -1;
      if (b.year < a.year) return 1;
      if (b.month > a.month) return -1;
      if (b.month < a.month) return 1;
    }),
    closedRevenue: lastYearData?.closedRevenue.slice().sort((a, b) => {
      if (b.year > a.year) return -1;
      if (b.year < a.year) return 1;
      if (b.month > a.month) return -1;
      if (b.month < a.month) return 1;
    }),
    closedRate: lastYearData?.closedRate.slice().sort((a, b) => {
      if (b.year > a.year) return -1;
      if (b.year < a.year) return 1;
      if (b.month > a.month) return -1;
      if (b.month < a.month) return 1;
    }),
  };

  const months = lastYearDataObj?.sentBids?.map((sb) => {
    return moment.monthsShort(sb.month - 1);
  });

  const bidsChartData: Chart = {
    labels: months,
    datasets: [
      {
        label: "Bids Sent",
        color: "info",
        data: lastYearDataObj?.sentBids?.map((sb) => sb.total),
      },
      {
        label: "Bids Won",
        color: "primary",
        data: lastYearDataObj?.closedBids?.map((sb) => sb.total),
      },
    ],
  };

  const revenueChartData: Chart = {
    labels: months,
    datasets: [
      {
        label: "Revenue",
        color: "blue",
        data: lastYearDataObj?.closedRevenue?.map((sb) => sb.total / 100),
      },
    ],
  };

  const closeRateChartData: Chart = {
    labels: months,
    datasets: [
      {
        label: "Close Rate (%)",
        color: "primary",
        data: lastYearDataObj?.closedRate?.map((sb) => sb.total),
      },
    ],
  };

  const jobScheduleByAmountChartData: Chart = {
    labels: jobScheduleByAmountData?.map((item) => moment(item.date).format("M/DD")),
    datasets: [
      {
        label: "Total ($)",
        color: "blue",
        data: jobScheduleByAmountData?.map((item) => item.overallTotal / 100),
      },
    ],
  };

  // recursively set background colors as count must match labels count

  const salesProductChartData = {
    labels: productData?.products?.map((p) => p.name),
    datasets: {
      label: "Products",
      backgroundColors: productData?.products?.map((_, idx) => getBackgroundColors(idx)),
      data: productData?.products?.map((p) => p.sumOverallTotal / 100),
    },
  };

  const salesPersonTableData = personData?.map((p) => {
    return {
      name: `${p.firstName} ${p.lastName}`,
      "Proposals Sent": p.sentProposalsCount,
      "Proposals Won": p.proposalsWonCount,
      "Win Rate": `${+p.winRate}%`,
      "Proposals Won Sum": formatCentsToUSD(p.proposalsWonSum),
      "% of Total Organization Proposals Won": `${p.proposalsWonPercentage}%`,
    };
  });

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={3}>
        <YearToDateStats yearToDateData={yearToDateData} />
        {currentMonthData && previousMonthData && (
          <LastMonthVsThisMonthStats
            defaultDashboardStatsData={{
              currentMonth: currentMonthData,
              previousMonth: previousMonthData,
            }}
          />
        )}

        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={4}>
              <DefaultLineChart
                title="Bids Sent vs Bids Won"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="Bids Sent" />
                      <MDBadgeDot color="primary" size="sm" badgeContent="Bids Won" />
                    </MDBox>
                  </MDBox>
                }
                chart={bidsChartData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <DefaultLineChart
                title="Year to Date Revenue Won"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      &nbsp;
                    </MDBox>
                  </MDBox>
                }
                chart={revenueChartData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <DefaultLineChart
                title="Close Rate"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      &nbsp;
                    </MDBox>
                  </MDBox>
                }
                chart={closeRateChartData}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={5}>
              <MDBox mt={3}>
                <PieChartDefault title="Sales by Product" chart={salesProductChartData} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={7}>
              <MDBox mt={3} sx={{ height: "380px", overflowY: "scroll" }}>
                <SalesTable title="Sales by Person" rows={salesPersonTableData} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={6}>
              <DefaultLineChart
                title="Amount Scheduled Per Day"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      &nbsp;
                    </MDBox>
                  </MDBox>
                }
                chart={jobScheduleByAmountChartData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <MaterialNeededPerDay data={materialNeededByDay} allMaterials={allMaterials} />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={3}>
          <Grid container spacing={3}>
            <YearToDateJobCompletion data={productData?.materialUsed} />
            <JobCostingDetails data={productData?.productTypeUsedPercentages} />
          </Grid>
        </MDBox>

        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Map data={mapData} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
