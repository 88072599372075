import {
  CustomGetJobQuery,
  CustomGetJobQueryVariables,
  CustomGetJobsQuery,
  useCustomGetJobQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetJob = ({ variables }: { variables: CustomGetJobQueryVariables }) => {
  const {
    data: graphqlData,
    error,
    loading,
  } = useCustomGetJobQuery({
    variables,
  });

  const getData = useCallback(({ data }: { data: CustomGetJobQuery }) => {
    return data?.getJob ?? null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  return { loading, data, error } as const;
};
