const attributesToSkipMap = {
  "Crack Seal": ["poundsPerGallon", "poundsPerBlock"],
  Grading: ["poundsPerCubicYard"],
  Mastic: ["poundsPerGallon", "poundsPerBlock"],
};

const getAttributesToSkip = (productTypeName: string): string[] | undefined => {
  return attributesToSkipMap[productTypeName] ?? [];
};

export default getAttributesToSkip;
