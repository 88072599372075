import { useMemo } from "react";
import MDButton from "components/MDButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ActionsCell from "modules/OrganizationAddresses/ActionsCell";
import useGetOrganizationAddresses from "./useGetOrganizationAddresses";
import useUpdateOrganizationAddress from "hooks/organization/useUpdateOrganizationAddress";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { Link } from "react-router-dom";

export default function useOrganizationAddressesTable() {
  const { offset } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo, refetch } = useGetOrganizationAddresses({
    page: offset.page,
    first: offset.first,
  });
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });
  const [updateOrganizationAddress, { isSuccess }] = useUpdateOrganizationAddress();

  const makePrimary = async (address: any) => {
    // Deep copy of address
    const updatedAddress = JSON.parse(JSON.stringify(address));

    // Strip __typename, latitude & longitude from address
    delete updatedAddress.__typename;
    delete updatedAddress.latitude;
    delete updatedAddress.longitude;

    const result = await updateOrganizationAddress({
      variables: {
        input: {
          ...updatedAddress,
          isPrimary: true,
        },
      },
    });
    const success = isSuccess(result.data);
    if (success) {
      refetch();
    }
  };

  const checkIsPrimary = ({ value, row }: { value: any; row: any }) => {
    if (value === true) {
      return <CheckCircleIcon fontSize="small" color="success"></CheckCircleIcon>;
    } else {
      return (
        <MDButton
          variant="gradient"
          color="white"
          onClick={() => {
            makePrimary(row.original);
          }}
        >
          Make primary
        </MDButton>
      );
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Address Line 1",
        accessor: "line1",
        Cell: ({ row, value }) => (
          <Link style={{ color: "inherit" }} to={`/organization/addresses/${row.original.id}`}>
            {value}
          </Link>
        ),
      },
      { Header: "Zip", accessor: "zip" },
      { Header: "Primary", accessor: "isPrimary", Cell: checkIsPrimary },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    paginatorInfo,
  } as const;
}
