import { SortDirection } from "generated/graphql";
import { useCallback, useState } from "react";
import useDebouncedSearchTerm from "./useDebouncedSearchTerm";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export interface Sorting {
  sortDirection: string;
  sortKey: string;
  setSort: React.Dispatch<
    React.SetStateAction<{
      sortDirection: string;
      sortKey: string | null;
    }>
  >;
}

export const usePaginatedVariables = ({
  initialSearchTerm,
  initialStatuses,
  initialBillingStatuses,
  initialSortDirection,
  initialSortKey,
  initialCompanyId,
  initialMultipleCompanyIds,
  initialUserID,
  initialMultipleUserIDs,
  initialCrewID,
  initialForemanUserId,
  initialContactName,
  initialDueAt,
  initialStartsAt,
  initialJobPhaseId,
  initialForDate,
  initialForDateStart,
  initialForDateEnd,
}: {
  initialSearchTerm?: string;
  initialStatuses?: any[];
  initialBillingStatuses?: any[];
  initialSortDirection?: string;
  initialSortKey?: string;
  initialCompanyId?: string;
  initialMultipleCompanyIds?: string[];
  initialUserID?: string;
  initialMultipleUserIDs?: string[];
  initialCrewID?: string;
  initialForemanUserId?: string;
  initialContactName?: string;
  initialDueAt?: string;
  initialStartsAt?: number | string;
  initialJobPhaseId?: string;
  initialForDate?: string;
  initialForDateStart?: string;
  initialForDateEnd?: string;
} = {}) => {
  const { searchTerm, setSearchTerm, debouncedSearchTerm } = useDebouncedSearchTerm(
    initialSearchTerm ?? null
  );

  const [{ sortDirection, sortKey }, setSort] = useState<{
    sortDirection: string | null;
    sortKey: string | null;
  }>({
    sortDirection: initialSortDirection ?? null,
    sortKey: initialSortKey ?? null,
  });
  const [statuses, setStatuses] = useState<string[]>(initialStatuses ?? []);
  const [billingStatuses, setBillingStatuses] = useState<string[]>(initialBillingStatuses ?? []);
  const [offset, setOffset] = useState({ page: 1, first: 50 });
  const [companyId, setCompanyID] = useState<string>(initialCompanyId ?? null);
  const [multipleCompanyIDs, setMultipleCompanyIds] = useState<string[]>(
    initialMultipleCompanyIds ?? []
  );
  const [userID, setUserID] = useState<string>(initialUserID ?? null);
  const [multipleUserIDs, setMultipleUserIDs] = useState<string[]>(initialMultipleUserIDs ?? []);
  const [crewID, setCrewID] = useState<string>(initialCrewID ?? null);
  const [foremanUserId, setForemanUserId] = useState<string>(initialForemanUserId ?? null);
  const [contactName, setContactName] = useState<string>(initialContactName ?? null);
  const [dueAt, setDueAt] = useState<string>(initialDueAt ?? null);
  const [startsAt, setStartsAt] = useState<number | string>(
    initialStartsAt ?? dayjs(Date.now()).format("YYYY-MM-DD")
  );
  const [jobPhaseId, setJobPhaseId] = useState<string>(initialJobPhaseId ?? null);
  const [forDate, setForDate] = useState<string>(initialForDate ?? null);
  const [forDateStart, setForDateStart] = useState<string>(initialForDateStart ?? null);
  const [forDateEnd, setForDateEnd] = useState<string>(initialForDateEnd ?? null);
  const paginate = useCallback(
    ({ page, first }: { page?: number; first?: number }) => {
      setOffset((prevState) => ({ page: page ?? prevState.page, first: first ?? prevState.first }));
    },
    [setOffset]
  );

  return {
    filtering: {
      statuses,
      setStatuses,
      billingStatuses,
      setBillingStatuses,
      searchTerm,
      setSearchTerm,
      debouncedSearchTerm,
      companyId,
      setCompanyID,
      multipleCompanyIDs,
      setMultipleCompanyIds,
      userID,
      setUserID,
      multipleUserIDs,
      setMultipleUserIDs,
      crewID,
      setCrewID,
      foremanUserId,
      setForemanUserId,
      contactName,
      setContactName,
      dueAt,
      setDueAt,
      setStartsAt,
      startsAt,
      jobPhaseId,
      setJobPhaseId,
      forDate,
      setForDate,
      forDateStart,
      setForDateStart,
      forDateEnd,
      setForDateEnd,
    },
    offset: {
      page: offset.page,
      first: offset.first,
      paginate,
    },
    sorting: {
      sortDirection,
      sortKey,
      setSort,
    },
  } as const;
};
