import getLocalString from "constants/Localization";
import { useCustomUpdateNotificationTriggerMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateNotificationTrigger() {
  const [mutation, { loading, error, data }] = useCustomUpdateNotificationTriggerMutation({
    refetchQueries: ["getNotificationTrigger", "customGetNotificationTrigger"],
  });

  const [updateNotificationTrigger, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateNotificationTrigger",
    },
    {
      successMessage: getLocalString("notifications.update-success"),
      failureMessage: getLocalString("notifications.update-failure"),
    }
  );

  return [updateNotificationTrigger, context] as const;
}
