import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Index from "modules/OrganizationCrews/Index";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";

const OrganizationCrewsPage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Organization crews
              </MDTypography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <MDButton
                component={Link}
                type="button"
                to={`/organization/crews/create`}
                color="blue"
              >
                Create Crew
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Index />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default OrganizationCrewsPage;
