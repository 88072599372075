import MDAlert from "components/MDAlert";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useCompaniesTable from "hooks/companies/useCompaniesTable";

export default function CompaniesList() {
  // Get the companies list
  const { loading, error, tableData, pagination, filtering, sorting } = useCompaniesTable();

  return (
    <>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && (
        <DataTable
          table={tableData}
          canSearch
          pagination={pagination}
          filtering={filtering}
          sorting={sorting}
          isSorted={true}
        />
      )}
    </>
  );
}
