import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";

export default function MDSelect({
  fullWidth,
  value,
  onChange,
  defaultValue,
  label,
  error,
  name,
  options,
  readOnly,
}: {
  fullWidth: boolean;
  defaultValue?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  label: string;
  name: string;
  readOnly?: boolean;
  error?: boolean;
  options: SelectOptions;
}) {
  const selected = value;

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={
        selected
          ? {
              "&": {
                mt: "3px",
              },
            }
          : {
              "&": {
                py: "10px",
              },
            }
      }
    >
      <InputLabel
        sx={{
          "&": {
            marginLeft: "-12px",
          },
          ...(selected
            ? {
                top: "8px",
              }
            : {}),
        }}
      >
        {label}
      </InputLabel>
      <Select
        variant={"standard"}
        error={error}
        readOnly={readOnly}
        sx={{
          "& > #mui-component-select-role": {
            padding: "0 0 5px !important",
          },
          ...(selected
            ? {
                "& > div": {
                  marginTop: "6px",
                },
              }
            : {}),
        }}
        onChange={onChange}
        defaultValue={defaultValue ?? selected}
        value={value}
      >
        {options?.map(({ label, value }) => (
          <MenuItem
            data-test-id={`select-${name}-option`}
            value={value}
            key={value}
            selected={value === selected}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
