import getLocalString from "constants/Localization";
import { useUpdateOrganizationSalesTaxesMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateOrganizationSalesTax() {
  const [mutation, { loading, data, error }] = useUpdateOrganizationSalesTaxesMutation();

  const [updateOrganizationSalesTax, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateOrganizationSalesTaxes",
    },
    {
      successMessage: getLocalString("organization.update-sales-tax-success"),
      failureMessage: getLocalString("organization.update-sales-tax-failure"),
    }
  );

  return [updateOrganizationSalesTax, context] as const;
}
