import { useGetRolesWithPermissionsQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetRolesPermissions() {
  const { data: graphqlData, loading, error } = useGetRolesWithPermissionsQuery();

  const data = useMemo(() => {
    return graphqlData?.getRolesWithPermissions ?? null;
  }, [graphqlData]);

  return { data, loading, error } as const;
}
