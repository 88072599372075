import { Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import { JobPhase } from "generated/graphql";
import useGetJobWorkOrder from "hooks/jobs/useGetJobWorkOrder";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import { useParams } from "react-router";
import WorkOrderComponent from "./WorkOrderComponent";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export default function WorkOrder() {
  const { jobPhaseId } = useParams();
  const { loading, error, data } = useGetJobWorkOrder(jobPhaseId);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        if (searchParams.get("autoPrint")) {
          window.print();
          window.close();
        }
      }, 1200);
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
    }
    return () => window.removeEventListener("load", onPageLoad);
  }, []);
  return (
    <DashboardPage>
      {!loading && data && (
        <WorkOrderComponent data={data as JobPhase}>
          {loading && <Skeleton />}
          {error && <MDAlert>{error}</MDAlert>}
        </WorkOrderComponent>
      )}
    </DashboardPage>
  );
}
