import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect/MDSelect";
import MDTypography from "components/MDTypography";
import Modal from "modules/Modal/Modal";
import { useContext, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AreaCoverageContext } from "../forms/AreaCoverage";

const updateValues = (setValue, poundsPerGallon, poundsPerBlock) => {
  setValue("attributes.poundsPerGallon", poundsPerGallon);
  setValue("attributes.poundsPerBlock", poundsPerBlock);
};

const productActions = {
  "mastic-one-box": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "15.5", "40");
    setAllowManualEdit(false);
  },
  "mastic-one-pleximelt": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "15.5", "30");
    setAllowManualEdit(false);
  },
  "mastic-patch-box": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "16.8", "40");
    setAllowManualEdit(false);
  },
  "mastic-patch-pleximelt": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "16.8", "30");
    setAllowManualEdit(false);
  },
  custom: (setValue, setAllowManualEdit) => {
    setAllowManualEdit(true);
  },
};

const productOptions = [
  { label: "Mastic One - Box", value: "mastic-one-box" },
  { label: "Mastic One - PlexiMelt", value: "mastic-one-pleximelt" },
  { label: "Mastic Patch - Box", value: "mastic-patch-box" },
  { label: "Mastic Patch - PlexiMelt", value: "mastic-patch-pleximelt" },
  { label: "Custom", value: "custom" },
];

export default function MasticCalculateAreaModal() {
  const { calculateAreaCoverage, areaProduct, setAreaProduct } = useContext(AreaCoverageContext);
  const [isAllowedToEdit, setIsAllowedToEdit] = useState(areaProduct === "custom");
  const isNotAllowedToEdit = !isAllowedToEdit;

  const {
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    calculateAreaCoverage(getValues, setValue);
  };

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <div style={{ position: "relative" }}>
          <Grid p={4} container spacing={3}>
            <Grid item xs={12}>
              <MDTypography>Calculate Area Coverage</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDSelect
                fullWidth
                label="Select Product"
                name="areaProduct"
                options={productOptions}
                onChange={(e) => {
                  setAreaProduct(e.target.value);
                  const action = productActions[e.target.value];
                  if (action) {
                    action(setValue, setIsAllowedToEdit);
                  }
                }}
                value={areaProduct}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="attributes.poundsPerGallon"
                render={({ field }) => (
                  <FormField
                    disabled={isNotAllowedToEdit}
                    type="number"
                    label="Pounds Per Gallon"
                    placeholder="Pounds Per Gallon"
                    error={errors?.attributes?.["poundsPerGallon"]}
                    shrink
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="attributes.poundsPerBlock"
                render={({ field }) => (
                  <FormField
                    disabled={isNotAllowedToEdit}
                    type="number"
                    label="Pounds Per Block"
                    placeholder="Pounds Per Block"
                    error={errors?.attributes?.["poundsPerBlock"]}
                    shrink
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton variant="gradient" color="secondary" onClick={handleClose}>
                Calculate Area Coverage
              </MDButton>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <MDButton variant="gradient" color="secondary" onClick={handleOpen}>
        Calculate Area Coverage
      </MDButton>
    </>
  );
}
