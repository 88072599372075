import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Product } from "generated/graphql";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import ProductOptions from "../ProductOptions";

export interface SelectProductValues {
  productId: Product["id"];
}

export interface SelectProductFormProps {
  onSubmit: (values: SelectProductValues) => Promise<unknown>;
  loading: boolean;
}

export const schema = z.object({
  productId: z.string().min(1, "Required"),
});

export default function SelectProductForm({ onSubmit, loading }: SelectProductFormProps) {
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      productId: "",
    },
  });

  return (
    <MDBox p={3} component="form" role="form" onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProductOptions>
            {({ productOptions, loading, search }) => {
              return (
                <Controller
                  name="productId"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Grid item xs={12} md={6}>
                        <AutoComplete
                          options={productOptions}
                          field={field}
                          loading={loading}
                          error={error?.message ? error : ""}
                          label="Select Product"
                          search={search}
                        />
                      </Grid>
                    </>
                  )}
                />
              );
            }}
          </ProductOptions>
        </Grid>
        <Grid item xs={12}>
          <MDButton type="submit" disabled={loading} color="info" variant="gradient">
            Add Product to Proposal
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}
