import getLocalString from "constants/Localization";
import { Proposal, useCustomSetProposalToWonMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSetProposalToWon({ id }: { id: Proposal["id"] }) {
  const [mutation, { loading, error, data }] = useCustomSetProposalToWonMutation({
    refetchQueries: ["getJobs"],
  });

  const [setProposalToWon, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "setProposalToWon",
    },
    {
      successMessage: getLocalString("proposals.close-proposal-success"),
      failureMessage: getLocalString("proposals.close-proposal-failure"),
      showValidationErrors: true,
    }
  );

  return [setProposalToWon, context] as const;
}
