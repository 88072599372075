import getLocalString from "constants/Localization";
import {
  CustomGetProposalDocument,
  Proposal,
  useSetProposalToDraftMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSetProposalToDraft({ id }: { id: Proposal["id"] }) {
  const [mutation, { loading, data, error }] = useSetProposalToDraftMutation();

  const [setProposalToDraft, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "setProposalToDraft",
    },
    {
      successMessage: getLocalString("proposals.set-to-draft-success"),
      failureMessage: getLocalString("proposals.set-to-draft-failure"),
    }
  );

  return [setProposalToDraft, context] as const;
}
