import getLocalString from "constants/Localization";
import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { OrganizationAddress } from "generated/graphql";
import { GlobalMessage } from "hooks/notifications/useGlobalMessage";
import useDeleteOrganizationAddress from "hooks/organization/useDeleteOrganizationAddress";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export default function Delete({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: OrganizationAddress["id"];
}) {
  const [deleteOrganizationAddress, { isSuccess }] = useDeleteOrganizationAddress();

  const acceptFn = useCallback(async (): Promise<GlobalMessage> => {
    const { data } = await deleteOrganizationAddress({ id });

    if (isSuccess(data)) {
      closeMenu();
      return { message: getLocalString("organization.delete-address-success") };
    } else {
      return {
        message: getLocalString("organization.delete-address-failure"),
        options: { variant: "error" },
      };
    }
  }, [deleteOrganizationAddress, id]);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you want to delete this address?"
      />
      <MenuItem onClick={() => setOpen(true)}>Delete Address</MenuItem>
    </>
  );
}
