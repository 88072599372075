import { Card, Grid, Icon, IconButton, Switch } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useCreatePlantProductType from "hooks/organization/plants/useCreatePlantProductType";
import { useCallback } from "react";
import { SubmitHandler, useFieldArray, useForm, Controller } from "react-hook-form";

interface AddCustomMaterialTypeProps {
  onSubmit: SubmitHandler<{ name: string }>;
  loading: boolean;
}

export default function AddCustomMaterialType({ onClose }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<{ name: string }>({ shouldUnregister: true });

  const [createPlantProductType, { loading, getData, isSuccess }] = useCreatePlantProductType();

  const onCreatePlantProductTypeSubmit = useCallback(
    async (input: { name: string }) => {
      const result = await createPlantProductType({ variables: { name: input.name } });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (success) {
        onClose();
      }
      return { success, data };
    },
    [createPlantProductType]
  );

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return handleSubmit(async (data: { name: string }) => {
            const result = await onCreatePlantProductTypeSubmit({ name: data.name });
            if (result.success) {
              reset();
            }
          })();
        }}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
