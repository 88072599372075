import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  useUpdateCompanyMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

const useUpdateCompany = () => {
  const [mutation, { loading, data: graphqlData, error }] = useUpdateCompanyMutation();
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateCompanyMutation) => {
    return !!data?.updateCompany?.id;
  }, []);

  const updateCompany = useCallback(
    withMutationAction(
      async (props: MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) => {
        return mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("companies.update-success"),
        failureMessage: getLocalString("companies.update-failure"),
      }
    ),
    [mutation]
  );

  const getData = useCallback(({ data }: { data?: UpdateCompanyMutation }) => {
    return data?.updateCompany || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  return [updateCompany, { loading, data, error, isSuccess, getData }] as const;
};

export default useUpdateCompany;
