import getLocalString from "constants/Localization";
import { useCustomCreateNotificationTriggerMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreateNotificationTrigger() {
  const [mutation, { loading, error, data }] = useCustomCreateNotificationTriggerMutation({
    refetchQueries: ["getNotificationTrigger", "customGetNotificationTrigger"],
  });

  const [createNotificationTrigger, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "createNotificationTrigger",
    },
    {
      successMessage: getLocalString("notifications.create-success"),
      failureMessage: getLocalString("notifications.create-failure"),
    }
  );

  return [createNotificationTrigger, context] as const;
}
