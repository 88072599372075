import { InputAdornment } from "@mui/material";

const minuteEndAdornment = {
  InputProps: {
    endAdornment: (
      <InputAdornment position="end" sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}>
        mins
      </InputAdornment>
    ),
  },
};

export default minuteEndAdornment;
