import getLocalString from "constants/Localization";
import { useCustomAddProposalAddressMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddProposalAddress() {
  const [mutation, { loading, data, error }] = useCustomAddProposalAddressMutation();
  const [customUpdateProposalStage, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "addProposalAddress",
    },
    {
      successMessage: getLocalString("proposals.add-stage-address-success"),
      failureMessage: getLocalString("proposals.add-stage-address-failure"),
    }
  );

  return [customUpdateProposalStage, context] as const;
}
