import "@fullcalendar/react/dist/vdom";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useGetJobLazy from "hooks/jobs/useGetJobLazy";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import JobsSchedule from "modules/jobs/JobsSchedule/JobsSchedule";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function JobsSchedulePage() {
  const [URLSearchParams] = useSearchParams();
  const jobId = URLSearchParams.get("jobId");
  const [getJob, { loading: jobLoading, error: jobError, job }] = useGetJobLazy();

  useEffect(() => {
    if (jobId) {
      getJob(jobId);
    }
  }, []);

  return (
    <DashboardPage renderNavbar={false}>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Jobs Schedule{jobId && job ? ` (Job ID ${job.externalId})` : ""}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ fontSize: "12px" }}>
          <JobsSchedule jobId={jobId} />
        </Grid>
      </Grid>
    </DashboardPage>
  );
}
