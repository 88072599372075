import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { Job, JobPhase, UpdateJobPhaseNotesInput } from "generated/graphql";
import Title from "./Title";
import useUpdateJobPhaseNotes from "hooks/jobs/job-phases/useUpdateJobPhaseNotes";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  getDefaultValues,
  schema,
  UpdateJobPhaseNotesInputSchema,
} from "DDD/action-objects/JobPhaseNotesUpdate";
import { zodResolver } from "@hookform/resolvers/zod";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Edit } from "@mui/icons-material";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";

export default function JobNotes({
  notes,
  jobPhaseId,
}: {
  notes: JobPhase["notes"];
  jobPhaseId: JobPhase["id"];
}) {
  const [updateJobPhaseNotes, { loading: loadingNotes }] = useUpdateJobPhaseNotes();
  const [editMode, setEditMode] = useState(false);
  const { handleSubmit, control } = useForm<UpdateJobPhaseNotesInputSchema>({
    defaultValues: {
      id: jobPhaseId,
      notes,
    },
    resolver: zodResolver(schema),
  });
  const onSaveNotes = useCallback(
    async (values: UpdateJobPhaseNotesInputSchema) => {
      await updateJobPhaseNotes({
        variables: {
          input: {
            id: values.id ?? jobPhaseId,
            notes: values.notes,
          },
        },
      });
      setEditMode(false);
    },
    [editMode]
  );
  return (
    <MDBox mb={2} className="no-break">
      <Card>
        <MDBox bgColor="#f5f5f5" py={2} px={4} display="flex" justifyContent="space-between">
          <MDTypography variant="h4">Job Phase Notes</MDTypography>
          <MDButton
            type="button"
            variant="text"
            color="info"
            disabled={loadingNotes}
            sx={{ maxHeight: "25px" }}
            className="no-print"
            onClick={() => setEditMode(!editMode)}
          >
            <Edit />
          </MDButton>
        </MDBox>

        {!editMode && <MDBox p={4} dangerouslySetInnerHTML={{ __html: notes }} />}
        {editMode && (
          <MDBox p={2}>
            <Grid container spacing={3} alignItems="center" p={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <WYSIWYG content={field.value} onChange={(note) => field.onChange(note)} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton
                    type="button"
                    variant="contained"
                    onClick={handleSubmit(onSaveNotes)}
                    color="success"
                    disabled={loadingNotes}
                    sx={{ maxHeight: "25px" }}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </Card>
    </MDBox>
  );
}
