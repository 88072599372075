import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateAdditionalCostMutation,
  UpdateAdditionalCostMutationVariables,
  useUpdateAdditionalCostMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export default function useUpdateAdditionalCost() {
  const [mutation, { data, loading, error }] = useUpdateAdditionalCostMutation({
    refetchQueries: ["getAdditionalCosts", "customGetAdditionalCosts"],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateAdditionalCostMutation) => {
    return (data && !!data.updateAdditionalCost?.id) || false;
  }, []);

  const getAdditionalCost = useCallback((data: UpdateAdditionalCostMutation) => {
    return data && data.updateAdditionalCost;
  }, []);

  const additionalCost = useMemo(() => {
    return getAdditionalCost(data) || null;
  }, [data]);

  const updateAdditionalCost = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          UpdateAdditionalCostMutation,
          UpdateAdditionalCostMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.update-additional-cost-success"),
        failureMessage: getLocalString("organization.update-additional-cost-failure"),
      }
    ),
    []
  );

  return [
    updateAdditionalCost,
    { data, loading, error, isSuccess, additionalCost, getAdditionalCost },
  ] as const;
}
