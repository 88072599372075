import {
  GetOrganizationUsersQueryVariables,
  useGetOrganizationUsersLazyQuery,
} from "generated/graphql";
import { useCallback } from "react";

export default function useGetOrganizationUsersLazy() {
  const [getOrganizationUsersLazyQuery, { data, loading, error }] =
    useGetOrganizationUsersLazyQuery();

  const getOrganizationUsers = useCallback(
    ({ page, first, search, status }: GetOrganizationUsersQueryVariables) => {
      getOrganizationUsersLazyQuery({
        variables: {
          page,
          first,
          search,
          status,
        },
      });
    },
    [getOrganizationUsersLazyQuery]
  );

  const users = data?.getOrganizationUsers?.data ?? [];
  const paginatorInfo = data?.getOrganizationUsers?.paginatorInfo ?? null;
  const totalUsers = data?.getOrganizationUsers?.paginatorInfo?.total || null;

  return [
    getOrganizationUsers,
    { data, loading, error, users, paginatorInfo, totalUsers },
  ] as const;
}
