import { FormHelperText, Grid, InputProps } from "@mui/material";
import FormField from "components/FormField/FormField";
import { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AreaCoverageContext } from "./AreaCoverage";
import { applicationRateMappings } from "../data/applicationRateMappings";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";

export default function CreateProductAttributesFormFields({
  fields,
}: {
  fields: { name: string; label: string; inputProps?: any; toolTip?: any }[];
}) {
  const { calculateAreaCoverage, productType } = useContext(AreaCoverageContext);
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {fields.map((field) => (
        <Grid item xs={12} md={6} key={field.name}>
          <Controller
            name={`attributes.${field.name}`}
            control={control}
            render={({ field: controlField, fieldState: { error } }) => {
              if (field.name === "applicationRate" && applicationRateMappings[productType.type]) {
                return (
                  <>
                    <CustomSelect
                      label={field.label}
                      control={control}
                      fullWidth
                      selectVariant="standard"
                      data={applicationRateMappings[productType.type]}
                      name={controlField.name}
                    />
                    {(error?.message || errors[field.name]?.message) && (
                      <FormHelperText sx={{ marginLeft: "0px" }} error>
                        {error?.message || errors[field.name]?.message}
                      </FormHelperText>
                    )}
                  </>
                );
              }
              return (
                <FormField
                  name={controlField.name}
                  inputProps={{ step: "0.01" }}
                  type="number"
                  label={field.label}
                  placeholder={field.label}
                  error={error}
                  {...controlField}
                  onChange={(e) => {
                    controlField.onChange(e);
                    calculateAreaCoverage(getValues, setValue);
                  }}
                  {...field.inputProps}
                />
              );
            }}
          />
        </Grid>
      ))}
    </>
  );
}
