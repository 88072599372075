import { FormControl, MenuItem, Select, ListSubheader } from "@mui/material";
import { contentPlaceHolderTypes, PlaceHolderTypes } from "constants/PlaceholderTypes";

interface PlaceHolderSelectProps {
  type: PlaceHolderTypes;
  value: string;
  disabled?: boolean;
  onChange: (e: any) => void;
}
export default function PlaceHolderSelect({ type, value, onChange }: PlaceHolderSelectProps) {
  const placeHolder = contentPlaceHolderTypes[type];
  if (!placeHolder) {
    return null;
  }

  return (
    <FormControl sx={{ m: "1", minWidth: 200 }}>
      <Select
        displayEmpty
        onChange={onChange}
        value={""}
        sx={{ minWidth: "100%", minHeight: "40px" }}
        autoWidth
      >
        <MenuItem value="" disabled>
          Placeholders
        </MenuItem>
        {Object.keys(placeHolder).map((type) => {
          return [
            <ListSubheader key={type}>{type.replace(/([A-Z]{1})/g, " $1").trim()}</ListSubheader>,
            Object.keys(placeHolder[type])?.map((k, i) => (
              <MenuItem key={i} value={`{{${k}}}`}>
                {placeHolder[type][k]}
              </MenuItem>
            )),
          ];
        })}
      </Select>
    </FormControl>
  );
}
