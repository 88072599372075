import GlobalLoader from "components/GlobalLoader/GlobalLoader";
import { UserPermissions } from "generated/graphql";
import { useBitumioContext } from "providers/BitumioContext";
import { Navigate } from "react-router-dom";

export default function AuthWallSubscriber({ children }: { children: JSX.Element }) {
  const [{ signedInUser, loading }] = useBitumioContext();

  if (loading) {
    return <GlobalLoader />;
  }

  if (!signedInUser?.organization?.subscribed && !signedInUser?.organization?.onTrial) {
    return <Navigate to={`/organization/subscription`} />;
  }

  return <>{children}</>;
}
