import { InputAdornment } from "@mui/material";

const lengthEndAdornment = {
  InputProps: {
    endAdornment: (
      <InputAdornment position="end" sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}>
        Inches
      </InputAdornment>
    ),
  },
};

export default lengthEndAdornment;
