import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import { CustomUpdateProposalStageMutationVariables, ProposalStage } from "generated/graphql";
import useCustomUpdateProposalStage from "hooks/proposals/useCustomUpdateProposalStage";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import humanReadableAddress from "utils/address/humanReadableAddress";
import { getDefaultValues } from "DDD/action-objects/ProposalStageUpdate";
import { useMemo } from "react";
import MDBox from "components/MDBox";

interface IProposalStageEditName {
  stage: ProposalStage;
  setEditable: (editable: boolean) => void;
}

export default function ProposalStageEditNameForm({ setEditable, stage }: IProposalStageEditName) {
  const [updateProposalStage, { isSuccess: proposalStageSuccess, getData }] =
    useCustomUpdateProposalStage();

  const handleOnSubmit = useCallback(
    async (variables: CustomUpdateProposalStageMutationVariables) => {
      const result = await updateProposalStage({ variables });
      const success = proposalStageSuccess(result?.data);
      const dt = getData(result);
      if (success) {
        setEditable(false);
      }
      return { success, data: dt };
    },
    [updateProposalStage]
  );

  const defaultValues: CustomUpdateProposalStageMutationVariables = useMemo(() => {
    return getDefaultValues({
      id: stage.id,
      name: stage.name,
      address: humanReadableAddress(JSON.stringify(stage.address)),
      mapData: stage.mapData,
    });
  }, [stage]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CustomUpdateProposalStageMutationVariables>({
    defaultValues,
  });
  return (
    <MDBox display="flex" gap="2px" alignItems="center">
      <MDBox
        mr="10px"
        sx={{
          width: {
            sm: "75%",
            md: "100%",
            lg: "45vw",
            xl: "50vw",
          },
        }}
      >
        <FormField
          {...register("name")}
          onClick={(e) => e.stopPropagation()}
          error={errors.name}
          inputProps={{
            sx: {
              fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
              fontSize: "1.25rem",
              color: "#344767",
            },
          }}
        />
      </MDBox>
      <MDButton
        color="success"
        type="button"
        onClick={async (e) => {
          e.stopPropagation();
          await handleSubmit(handleOnSubmit)();
        }}
      >
        Save
      </MDButton>
      <MDButton
        color="info"
        type="button"
        onClick={async (e) => {
          e.stopPropagation();
          setEditable(false);
        }}
      >
        Cancel
      </MDButton>
    </MDBox>
  );
}
