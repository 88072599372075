import { CircularProgress, Icon, MenuItem, Select } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import TableFilter from "components/TableFilter/TableFilter";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function SingleChoiceFilter({ id, value, loading, options, error, onChange }) {
  return (
    <TableFilter value={value} id={id}>
      {loading && (
        <MDBox>
          <CircularProgress color="inherit" size="10px" />
        </MDBox>
      )}
      {error && (
        <MDBox sx={{ maxWidth: "250px" }}>
          <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>
        </MDBox>
      )}
      {!loading && !error && (
        <MDBox sx={{ maxHeight: "250px", overflowY: "auto" }}>
          <MenuItem
            selected={value === null}
            value={null}
            onClick={() => onChange(null)}
            sx={{ pl: "20px" }}
          >
            {value === null ? <Icon sx={{ position: "absolute", left: "4px" }}>checked</Icon> : ""}
            ALL
          </MenuItem>
          {options.map((company) => {
            const selected = value === company.value;

            return (
              <MenuItem
                selected={selected}
                key={company.value}
                value={company.value}
                onClick={() => onChange(company.value)}
                sx={{ pl: "20px" }}
              >
                {selected ? <Icon sx={{ position: "absolute", left: "4px" }}>checked</Icon> : ""}

                {company.label}
              </MenuItem>
            );
          })}
        </MDBox>
      )}
    </TableFilter>
  );
}
