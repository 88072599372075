import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ProjectFilesContext } from "modules/proposals/providers/ProjectFilesContextProvider";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ProjectFile, ProjectFileType } from "generated/graphql";
import { useGetProjectFile } from "hooks/projectFiles/useGetProjectFile";
import { Card, CircularProgress } from "@mui/material";
import { sentenceCase } from "change-case";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import useDeleteProjectFile from "hooks/projectFiles/useDeleteProjectFile";
import MDButton from "components/MDButton";
import { useCreateProjectFileFolder } from "hooks/projectFiles/useCreateProjectFileFolder";
import MDInput from "components/MDInput";
import useGenerateUploadProjectFileUrl from "hooks/projectFiles/useGenerateUploadProjectFileUrl";
import { FileInput, useUppy } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import useAddProjectFile from "hooks/projectFiles/useAddProjectFile";
import isImage from "utils/images/isImage";
import FileRow from "./FileRow";
import AddFileOrFolder from "./AddFileOrFolder";

export default function FileDetails({ data, fileView, folderId }) {
  const { lastSelectedItem, setLastSelectedItem } = useContext(ProjectFilesContext);
  const [getProjectFile, { loading, graphqlData: fileById }] = useGetProjectFile();
  const [deleteProjectFile] = useDeleteProjectFile();
  const [createProjectFileFolder, { isSuccess }] = useCreateProjectFileFolder();
  const [addProjectFile, { loading: loadingAddProjectFile }] = useAddProjectFile();

  const [open, setOpen] = useState(false);
  const [showAddFolderInput, setShowAddFolderInput] = useState(false);
  const [addFolderInput, setAddFolderInput] = useState("");
  const [filePreviewPath, setFilePreviewPath] = useState(null);
  const [filePreviewId, setFilePreviewId] = useState(null);

  const pathRef = useRef("");
  const fileByIdRef = useRef<null | ProjectFile>();

  const resetAddFolderInput = () => {
    setShowAddFolderInput(false);
    setAddFolderInput("");
  };

  useEffect(() => {
    if (lastSelectedItem) {
      getProjectFile({ id: lastSelectedItem });
    }
    resetAddFolderInput();
  }, [lastSelectedItem]);

  useEffect(() => {
    if (fileView && folderId) {
      setLastSelectedItem(folderId);
    }
    resetAddFolderInput();
  }, [folderId, setLastSelectedItem]);

  useEffect(() => {
    if (fileById) {
      fileByIdRef.current = fileById;
    }
  }, [fileById]);

  const acceptFn = () => {
    deleteProjectFile({ variables: { id: filePreviewId || lastSelectedItem } });
    setOpen(false);
    if (filePreviewId) {
      setFilePreviewId(null);
    } else {
      setLastSelectedItem(null);
    }
  };

  const handleAddFolderInput = () => {
    if (!showAddFolderInput) {
      return setShowAddFolderInput(true);
    }
    createProjectFileFolder({
      variables: {
        name: addFolderInput,
        ...(lastSelectedItem && {
          parentFolderId:
            fileById?.type === ProjectFileType.FOLDER ? fileById.id : fileById?.parentFolderId,
        }),
      },
    });

    if (isSuccess) {
      resetAddFolderInput();
    }
  };

  const [
    generateUploadProjectFileUrl,
    { getData, isSuccess: generateUrlSuccess, loading: loadingGenerateUrl },
  ] = useGenerateUploadProjectFileUrl();

  const uppy = useUppy(() => {
    const _uppy = new Uppy({
      id: `organizationFile`,
      autoProceed: true,
      allowMultipleUploads: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf", ".heic", ".mp4", ".mov"],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const result = await generateUploadProjectFileUrl({
          variables: {
            contentType: file.type,
            filename: file.name,
          },
        });
        const success = generateUrlSuccess(result.data);
        if (success) {
          const data = getData(result);

          pathRef.current = data.path;
          return {
            method: data.method as "PUT",
            url: data.url,
            fields: {},
            headers: {
              "Content-Type": data.contentType,
              ...(data.contentDisposition && { "Content-Disposition": data.contentDisposition }),
            },
          };
        } else {
          throw new Error("Could not upload file");
        }
      },
    });

    _uppy.on("upload-success", (file, response) => {
      if (response.status === 200) {
        addProjectFile({
          variables: {
            name: file.name,
            path: pathRef.current,
            parentFolderId:
              fileByIdRef.current?.type === ProjectFileType.FOLDER
                ? fileByIdRef.current.id
                : fileByIdRef.current?.parentFolderId,
          },
        });

        _uppy.removeFile(file.id);
      }
    });

    _uppy.on("restriction-failed", (file, error) => {
      if (error) {
        //
      }
    });

    _uppy.on("upload-error", (file) => {
      if (file) {
        // setErrorMessage("An error has ocurred while uploading this file");
        _uppy.removeFile(file.id);
      }
      // setBtnColor("info");
    });

    return _uppy;
  });

  const FilePreview = () => (
    <>
      {fileById?.path || filePreviewPath ? (
        <>
          {isImage(filePreviewPath || fileById.path || "") ? (
            <MDBox textAlign="center">
              <MDTypography fontSize={16} fontWeight="bold">
                PREVIEW
              </MDTypography>
              <MDBox
                component="img"
                src={`${import.meta.env.VITE_CDN_BASE_URL}/${filePreviewPath || fileById.path}`}
                alt={fileById.name}
                width={{ xs: "100%" }}
                mt={1}
              />
            </MDBox>
          ) : (
            <MDBox textAlign="center">
              <MDButton
                onClick={() =>
                  window.open(
                    `${import.meta.env.VITE_CDN_BASE_URL}/${filePreviewPath || fileById.path}`,
                    "_blank"
                  )
                }
              >
                Download to preview
              </MDButton>
            </MDBox>
          )}
        </>
      ) : null}
    </>
  );

  const deleteModalConfirmationText = useMemo(() => {
    if (!fileById) return "";
    if (filePreviewId) {
      return `Delete ${fileById?.children?.find((c) => c.id === filePreviewId)?.name} File `;
    }
    return `Delete ${fileById.name} ${sentenceCase(ProjectFileType[fileById.type])}? ${
      fileById.children?.length > 0 ? "All subfiles and subfolders will also be deleted" : ""
    }`;
  }, [filePreviewId, fileById]);

  return (
    <MDBox>
      <Card
        sx={{
          maxWidth: fileView ? "90%" : "unset",
          boxShadow: "none",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <AddFileOrFolder
          fileView={fileView}
          loading={loading}
          lastSelectedItem={lastSelectedItem}
          fileById={fileById}
        />
      </Card>
      {!fileView && lastSelectedItem && fileById && fileById.type === ProjectFileType.FOLDER && (
        <MDBox
          display="flex"
          sx={{ background: "white" }}
          p={2}
          justifyContent="space-between"
          alignItems="baseline"
          borderTop="1px solid #efefef"
          borderBottom="1px solid #efefef"
        >
          <MDTypography sx={{ display: "inline-block", fontSize: "16px", fontWeight: 600 }}>
            Folder name: {fileById.name}{" "}
          </MDTypography>
          <MDButton color="error" onClick={() => setOpen(true)}>
            Delete Folder
          </MDButton>
        </MDBox>
      )}
      {lastSelectedItem && (
        <>
          <MDBox>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: fileView ? "90%" : "unset",
                maxHeight: fileView ? "500px" : "unset",
                borderTopLeftRadius: 0,
                borderBottomTopRadius: 0,
              }}
            >
              {loading && <CircularProgress />}
              {!loading && fileById && (
                <Table sx={{ minWidth: 400 }} size="small">
                  <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <TableCell width="60%">File Name</TableCell>
                      <TableCell>File Size</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {fileById.type === ProjectFileType.FILE && (
                    <>
                      <FileRow file={fileById} onDelete={() => setOpen(true)} />
                      <TableRow>
                        <TableCell colSpan={3}>
                          <FilePreview />
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  <TableBody>
                    {fileById.type === ProjectFileType.FOLDER &&
                      fileById.children
                        ?.filter((item) => item.type === ProjectFileType.FILE)
                        ?.map((file) => {
                          return (
                            <FileRow
                              key={file.id}
                              onClick={() => {
                                setFilePreviewPath(file.path);
                                setFilePreviewId(file.id);
                              }}
                              file={file}
                              onDelete={() => {
                                setFilePreviewId(file.id);
                                setOpen(true);
                              }}
                              fileView={fileView}
                              active={filePreviewId === file.id}
                            />
                          );
                        })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </MDBox>
          {fileView && (
            <MDBox maxWidth="90%" mt={2}>
              <FilePreview />
            </MDBox>
          )}
          {fileById && (
            <ActionConfirmDialog
              open={open}
              setOpen={setOpen}
              handleAccept={acceptFn}
              handleCancel={() => setOpen(false)}
              title="Confirmation"
              content={deleteModalConfirmationText}
            />
          )}
        </>
      )}
    </MDBox>
  );
}
