import { zodResolver } from "@hookform/resolvers/zod";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import timeEndAdornment from "constants/timeEndAdornment";
import dayjs, { Dayjs } from "dayjs";
import { omit } from "ramda";
import {
  schema,
  ScheduleJobPhaseInput,
  getDefaultValues,
} from "DDD/action-objects/ScheduleJobPhase";
import { JobPhaseStatus } from "generated/graphql";
import { CustomScheduleJobPhaseMutationVariables, JobPhase } from "generated/graphql";
import useGetJobPhase, { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import useScheduleJobPhase from "hooks/jobs/useScheduleJobPhase";
import useGetOrganizationSettings from "hooks/organization/useGetOrganizationSettings";
import useGetJobSchedules, { CustomJobSchedules } from "hooks/schedules/useGetJobSchedules";
import CrewOptions from "modules/crews/CrewOptions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WorkOrderModal from "../WorkOrder/WorkOrderModal";
import JobCostModal from "../WorkOrder/JobCostModal";
import JobInfoModal from "../WorkOrder/JobInfoModal";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import useUnscheduleJobPhase from "hooks/jobs/useUnscheduleJobPhase";
import useUnscheduleCustomEvent from "hooks/customEvent/useUnscheduleCustomEvent";
import { convertHexToRGB, getRandomHexColor } from "utils/object/getRandomColor";
import { ColorPickerProps } from "modules/OrganizationCrews/Create";
import SelectCrewOrIndividual from "modules/SelectCrewOrIndividual/SelectCrewOrIndividual";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

function calculateHoursTotal(blocks) {
  if (!blocks || !Array.isArray(blocks)) {
    return 0;
  }

  return blocks.reduce((acc, val) => {
    return +acc + +val.blockLength;
  }, 0);
}

export default function ScheduleJobPhase({
  id,
  onClose,
}: {
  id: JobPhase["id"];
  onClose: () => void;
}) {
  const { loading, data: jobPhase, error } = useGetJobPhase({ id });

  const {
    loading: loadingJobSchedules,
    data: jobSchedules,
    error: errorJobSchedules,
  } = useGetJobSchedules({ jobPhaseId: id });
  const {
    loading: loadingOrganizationSettings,
    data: organizationSettings,
    error: errorOrganizationSettings,
  } = useGetOrganizationSettings();

  if (loading) {
    return (
      <MDBox
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        width="100%"
        height={"100%"}
        minHeight="350px"
      >
        <CircularProgress color="inherit" />
      </MDBox>
    );
  }

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const doneWithLoadingStates = !loadingJobSchedules && !loadingOrganizationSettings;
  const notInErrorState = !error && !errorOrganizationSettings;
  const ready = doneWithLoadingStates && notInErrorState;

  return (
    <MDBox
      sx={{
        width: "90vw",
        height: "90vh",
        maxWidth: "100%",
        maxHeight: "100%",
        p: 1,
      }}
    >
      <MDBox display="flex" gap="30px" width="100%" justifyContent="center">
        <MDTypography>{jobPhase.job.company.name}</MDTypography>
        <MDTypography>{jobPhase.proposalStage.name} </MDTypography>
        <MDTypography>{jobPhase.organizationProductTypeName} </MDTypography>
      </MDBox>
      {loadingJobSchedules || (loadingOrganizationSettings && <CircularProgress />)}
      {errorJobSchedules ||
        (errorOrganizationSettings && <MDAlert>{parseGraphQLError(errorJobSchedules)}</MDAlert>)}
      {ready && (
        <JobPhaseScheduling
          jobPhase={jobPhase}
          jobSchedules={jobSchedules}
          organizationSettings={organizationSettings}
          onClose={onClose}
        />
      )}
    </MDBox>
  );
}

function HoursTotal({ jobDuration }: { jobDuration: number }) {
  const { watch } = useFormContext();
  const blocks = watch(`blocks`);

  const hoursTotal = useMemo(() => {
    return calculateHoursTotal(blocks);
  }, [blocks]);

  return (
    <MDTypography variant="body2">
      {hoursTotal} hours scheduled out of {jobDuration.toFixed(2)} hours total
    </MDTypography>
  );
}

const JobPhaseScheduling = React.memo(
  ({
    jobPhase,
    jobSchedules,
    organizationSettings,
    onClose,
  }: {
    jobPhase: CustomJobPhase;
    jobSchedules: CustomJobSchedules;
    organizationSettings: Record<string, string>;
    onClose: () => void;
  }) => {
    const [scheduleJobPhase, { isSuccess }] = useScheduleJobPhase();
    const [unscheduleJobPhase] = useUnscheduleJobPhase();

    const { show } = useGlobalMessage();
    const defaultShowTravelTime = useMemo(() => {
      if (!jobSchedules) {
        return false;
      }

      return jobSchedules.reduce((acc, val) => {
        if (val.showTravelFrom || val.showTravelTo) {
          return true;
        }

        return acc;
      }, false);
    }, [jobSchedules]);

    const [showTravelTime, setShowTravelTime] = useState(defaultShowTravelTime);

    const methods = useForm<ScheduleJobPhaseInput>({
      resolver: zodResolver(schema),
      defaultValues: {
        crewId: "",
        blocks: [], // This is a fake default Value the actual values are set in [1]
        usersColor: jobPhase?.usersColor ?? jobPhase?.crew?.color,
      },
    });

    const defaultColor = jobPhase ? `#${methods.getValues("usersColor")}` : getRandomHexColor();

    const [colorPicker, setColorPicker] = useState<ColorPickerProps>({
      displayColorPicker: false,
      color: {
        hex: defaultColor,
        rgb: convertHexToRGB(defaultColor),
      },
    });

    const {
      formState: { errors },
      register,
    } = methods;

    const [travelTimeRecalculating, setTravelTimeRecalculating] = useState(true);

    const handleShowTravelTimeChange = useCallback(
      (value) => {
        setTravelTimeRecalculating(true);
        setTimeout(() => {
          const { blocks } = methods.getValues();
          // We need to update every block and set it to the current value
          const updatedBlocks = blocks.map((block) => ({
            ...block,
            showTravelFrom: value,
            showTravelTo: value,
          }));
          methods.setValue("blocks", updatedBlocks);
          methods.setValue("travelTimeEstimate", value ? jobPhase.travelTimeEstimate : null);
          setShowTravelTime(value);
          setTravelTimeRecalculating(false);
        }, 500);
      },
      [setShowTravelTime, methods.getValues, methods.setValue]
    );

    useEffect(() => {
      const timeout = setTimeout(() => {
        // [1] Values are set here because this avoids blocking the main thread and getting a violation warning
        methods.reset(
          getDefaultValues({
            jobPhase,
            jobSchedules,
            organizationSettings,
            showTravelTime,
          })
        );
        setTravelTimeRecalculating(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }, [methods.reset, setTravelTimeRecalculating]);

    const handleUnschedule = useCallback(async () => {
      onClose();
      await unscheduleJobPhase({
        variables: {
          jobPhaseId: jobPhase.id,
        },
      });
    }, []);

    return (
      <MDBox
        sx={{
          height: "calc(100% - 40px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {travelTimeRecalculating && (
          <MDBox
            sx={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(50, 50, 50, 0.2)",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              zIndex: 2,
            }}
          >
            <CircularProgress color="inherit" size={40} thickness={4} value={99} />
          </MDBox>
        )}
        <FormProvider {...methods}>
          <MDBox display="flex" width="100%" justifyContent="center">
            <HoursTotal jobDuration={jobPhase?.jobDuration} />
          </MDBox>
          <MDBox
            display="flex"
            width="70%"
            justifyContent={"space-between"}
            mx="auto"
            mt="20px"
            sx={{
              "div > p": {
                fontSize: "16px",
                fontWeight: "bold",
              },
              ".container": {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                minWidth: "120px",
              },
            }}
          >
            <MDBox className="container">
              <MDTypography>Job ID</MDTypography>
              <MDTypography fontWeight="normal">{jobPhase.job.externalId}</MDTypography>
            </MDBox>
            <MDBox className="container">
              <MDTypography>Job Phase ID</MDTypography>
              <MDTypography>{jobPhase.externalId}</MDTypography>
            </MDBox>
            <MDBox className="container">
              <MDTypography>Foreman</MDTypography>
              <MDTypography>{jobPhase?.foremanUser?.name}</MDTypography>
            </MDBox>
            <MDBox className="container">
              <MDTypography>Address</MDTypography>
              <MDTypography width="270px" textAlign="center">
                {jobPhase?.address}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" width="50%" justifyContent={"space-between"} mx="auto" mt="20px">
            <WorkOrderModal jobPhaseId={jobPhase.id} />
            <JobCostModal jobId={jobPhase.job.id} jobPhaseId={jobPhase.id} />
            <JobInfoModal jobId={jobPhase.job.id} />
            {jobPhase?.status !== JobPhaseStatus.TO_BE_SCHEDULED && (
              <MDButton size="small" type="button" color="blue" onClick={handleUnschedule}>
                Unschedule
              </MDButton>
            )}
          </MDBox>
          <MDBox
            sx={{
              flexGrow: "1",
              display: "flex",
              flexDirection: "column",
            }}
            p={3}
            component="form"
            role="form"
            onSubmit={methods.handleSubmit(async (values: ScheduleJobPhaseInput) => {
              const formattedPayload = {
                ...omit(["assignTo"], values),
                usersColor: colorPicker.color.hex.substring(1),
                blocks: values.blocks.map((block) => {
                  return {
                    ...block,
                    scheduledAt: dayjs(block.scheduledAt).utc().format("YYYY-MM-DD HH:mm:ss"),
                  };
                }),
              };

              const result = await scheduleJobPhase({
                variables: formattedPayload as CustomScheduleJobPhaseMutationVariables,
              });

              if (result.success && isSuccess(result.data)) {
                onClose();
                show({ message: values.blocks?.length ? "Scheduled" : "Removed from calendar" });
              } else {
                let errorMessage = "Failed to Schedule";
                if ((result as any).graphQLErrors?.[0]?.message?.includes("unauthorized")) {
                  errorMessage = "Insufficient Permissions";
                }
                show({ message: errorMessage, options: { variant: "error" } });
              }
            })}
          >
            <MDBox>
              <MDBox mb={3}>
                <SelectCrewOrIndividual
                  colorPicker={colorPicker}
                  setColorPicker={setColorPicker}
                  methods={methods}
                  errors={errors}
                  key={methods.getValues("assignTo")}
                />
              </MDBox>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginLeft: 0 }}
                  control={
                    <Checkbox
                      defaultChecked={defaultShowTravelTime}
                      checked={showTravelTime}
                      onChange={(e) => handleShowTravelTimeChange(e.target.checked)}
                    />
                  }
                  label="Show Travel Time"
                />
              </FormGroup>
              {showTravelTime && (
                <MDBox
                  sx={{
                    textAlign: "center",
                    my: 3,
                    width: {
                      sx: "100%",
                      md: "50%",
                      lg: "25%",
                    },
                    mx: "auto",
                  }}
                >
                  <FormField
                    placeholder="Travel Time estimate"
                    mb={0}
                    align="right"
                    {...timeEndAdornment}
                    {...register("travelTimeEstimate", { valueAsNumber: true })}
                  />
                </MDBox>
              )}
              <Divider sx={{ width: "100%" }} />
            </MDBox>
            <MDBox
              sx={{
                flexGrow: "1",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ScheduleBlocks showTravelTime={showTravelTime} status={jobPhase.status} />
            </MDBox>
            <MDBox sx={{ textAlign: "center", mt: 2 }}>
              <MDButton variant="gradient" color="info" type="submit">
                {methods.getValues("blocks").length ? "Add To Calendar" : "Remove From Calendar"}
              </MDButton>
            </MDBox>
          </MDBox>
        </FormProvider>
      </MDBox>
    );
  }
);

JobPhaseScheduling.displayName = "JobPhaseScheduling";

const ScheduleBlocks = React.memo(
  ({ showTravelTime, status }: { showTravelTime: boolean; status: JobPhaseStatus }) => {
    const { control } = useFormContext<ScheduleJobPhaseInput>();

    const { fields, append, remove } = useFieldArray({
      control,
      name: "blocks",
    });

    const handleRemoveScheduleBlock = useCallback(
      (blockIndex) => {
        if (fields.length === 1 && status === JobPhaseStatus.TO_BE_SCHEDULED) {
          // Do not remove if there is only one field left
          return;
        }

        remove(blockIndex);
      },
      [fields]
    );

    return (
      <>
        <MDBox sx={{ flex: "1 1 auto", overflowY: "auto", maxHeight: "300px" }}>
          <Grid container py={1}>
            {fields.map((field, i) => {
              return (
                <Grid item key={field.id} xs={12}>
                  <ScheduleBlock
                    status={status}
                    index={i}
                    onRemoveScheduleBlock={handleRemoveScheduleBlock}
                    showTravelTime={showTravelTime}
                  />
                  <Divider />
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() =>
              append({
                scheduledAt: null,
                blockLength: 0,
                showTravelTo: showTravelTime,
                showTravelFrom: showTravelTime,
              })
            }
          >
            Add Date
          </MDButton>
        </MDBox>
      </>
    );
  }
);

ScheduleBlocks.displayName = "ScheduleBlocks";

const ScheduleBlock = React.memo(
  ({
    index,
    onRemoveScheduleBlock,
    showTravelTime,
    status,
  }: {
    index: number;
    onRemoveScheduleBlock: (index: number) => void;
    showTravelTime: boolean;
    status: JobPhaseStatus;
  }) => {
    const { control, getValues } = useFormContext<ScheduleJobPhaseInput>();
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [startDate, setStartDate] = useState(new Date());

    const { blocks } = getValues();

    useEffect(() => {
      const { blocks } = getValues();
      const { scheduledAt: blockScheduledAt, blockLength: blockBlockLength } = blocks[index];
      if (!blockScheduledAt || !blockBlockLength) {
        setEndDate(null);
      } else {
        setEndDate(dayjs(blockScheduledAt).add(blockBlockLength, "hours"));
      }
    }, []);

    const recalculateEndDate = useCallback(() => {
      const { blocks } = getValues();
      const { scheduledAt: blockScheduledAt, blockLength: blockBlockLength } = blocks[index];

      // This is where we calculate the end date IF the scheduledAt and blockLength are set
      if (!blockScheduledAt || !blockBlockLength) {
        setEndDate(null);
      } else {
        setEndDate(dayjs(blockScheduledAt).add(blockBlockLength, "hours"));
      }
    }, []);

    return (
      <>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={8}>
            <Controller
              control={control}
              name={`blocks.${index}.scheduledAt`}
              render={({ field, fieldState: { error } }) => {
                return (
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    sx={{
                      ".date-input": {
                        height: "32px",
                        width: "200px",
                        fontSize: "0.875rem",
                        border: "none",
                        borderRadius: 0,
                        borderBottom: "1px solid #7b809a",
                        color: "#344767",
                        padding: "0 !important",
                        "&:focus-visible": {
                          outline: "none",
                        },
                      },
                    }}
                  >
                    <label
                      htmlFor={`blocks.${index}.scheduledAt`}
                      style={{ fontSize: "0.875rem", color: "#7b809a" }}
                    >
                      Start Date
                    </label>
                    <DatePicker
                      name={`blocks.${index}.scheduledAt`}
                      {...field}
                      selected={startDate}
                      shouldCloseOnSelect={false}
                      onChange={(e) => {
                        field.onChange(dayjs(e).format("YYYY-MM-DD HH:mm:ss"));
                        setStartDate(e);
                        recalculateEndDate();
                      }}
                      ref={(ref) => {
                        field.ref({
                          focus: ref?.setFocus,
                        });
                      }}
                      showTimeSelect
                      timeIntervals={15}
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      className="date-input"
                    />
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </MDBox>
                );
              }}
            />
            <MDTypography variant="body2" my={1} fontSize="0.875rem">
              End Date: {endDate && endDate.format("LLL")}
            </MDTypography>
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name={`blocks.${index}.blockLength`}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormField
                    valueAsNumber={true}
                    label="Hours"
                    inputProps={{ type: "number" }}
                    // Change to use controller so that I can intercept the onChange
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      recalculateEndDate();
                    }}
                    error={error}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => onRemoveScheduleBlock(index)}>
              <Icon>remove_circle_outline</Icon>
            </IconButton>
          </Grid>
        </Grid>
        {showTravelTime ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`blocks.${index}.showTravelTo`}
                render={({ field }) => (
                  <FormGroup sx={{ pl: 1 }}>
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Show To Travel Time"
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`blocks.${index}.showTravelFrom`}
                render={({ field }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Show From Travel Time"
                    />
                  </FormGroup>
                )}
              />
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  }
);

ScheduleBlock.displayName = "ScheduleBlock";
