import { Crew } from "generated/graphql";
import Update from "./Update";
import Delete from "./Delete";

export default function MenuItems({ id, closeMenu }: { id: Crew["id"]; closeMenu: () => void }) {
  return (
    <>
      <Update closeMenu={closeMenu} id={id} />
      <Delete closeMenu={closeMenu} id={id} />
    </>
  );
}
