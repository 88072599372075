import { ProjectFile } from "generated/graphql";
import { useGetProjectFiles } from "hooks/projectFiles/useGetProjectFiles";
import { createContext, useEffect, useState } from "react";

export interface ProjectFilesContextValue {
  lastSelectedItem: string;
  setLastSelectedItem: React.Dispatch<React.SetStateAction<string>>;
}

export const ProjectFilesContext = createContext<ProjectFilesContextValue>(
  {} as ProjectFilesContextValue
);

export default function ProjectFilesContextProvider({ children }) {
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  return (
    <ProjectFilesContext.Provider
      value={{
        lastSelectedItem,
        setLastSelectedItem,
      }}
    >
      {children}
    </ProjectFilesContext.Provider>
  );
}
