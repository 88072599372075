import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { defaultValues, schema } from "DDD/action-objects/PlantCreate";
import { CreatePlantInput } from "generated/graphql";
import { useForm, FormProvider } from "react-hook-form";
import AddProductsSection from "./CreatePlant/AddProductsSection/AddProductsSection";
// import AttachTransferTrucksSection from "./CreatePlant/AttachTransferTrucksSection/AttachTransferTrucksSection";
import MainForm from "./CreatePlant/MainForm/MainForm";

export default function Create({ onSubmit, loading }) {
  const methods = useForm<CreatePlantInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  return (
    <FormProvider {...methods}>
      <Card>
        <MDBox
          p={3}
          component="form"
          role="form"
          id="organization-plant-create"
          onSubmit={(e) => {
            // There are forms inside modals that trigger the same event because of the vdom
            // do not submit if it's not for this form
            if ((e.target as HTMLFormElement).id === "organization-plant-create") {
              handleSubmit(async (rest: CreatePlantInput) => {
                const products = rest.products.create.map((p) => {
                  return { ...p, rate: p.rate };
                });
                // const transferTrucks = rest.transferTrucks.connect.map((t) => {
                //   return { ...t, rateHourly: t.rateHourly, rateTon: t.rateTon };
                // });
                const result = await onSubmit({
                  ...rest,
                  products: { create: products },
                  // transferTrucks: { connect: transferTrucks },
                });
                if (result.success) {
                  reset();
                }
              })(e);
            } else {
              e.preventDefault();
            }
          }}
        >
          <MainForm />
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <AddProductsSection fieldArrayName="products.create" />
              {/* <AttachTransferTrucksSection fieldArrayName="transferTrucks.connect" /> */}

              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                  Create
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </FormProvider>
  );
}
