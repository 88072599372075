import { Grid } from "@mui/material";
import FormSkeleton from "components/FormSkeleton/FormSkeleton";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import getLocalString from "constants/Localization";
import useGetOrganizationProductType from "hooks/organization/product-types/useGetOrganizationProductType";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import OrganizationProductTypeUpdate from "modules/OrganizationProductTypes/OrganizationProductTypeUpdate";
import { useParams } from "react-router";

export default function OrganizationProductTypeUpdatePage() {
  const { productTypeId } = useParams();

  const {
    data: productType,
    loading,
    error,
  } = useGetOrganizationProductType({ id: productTypeId });

  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <MDTypography variant="h4" fontWeight="medium">
              Update Organization Product Type {productType ? `- ${productType.name}` : ""}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading && <FormSkeleton rows={2} />}
          {error && <MDAlert color="error">{getLocalString("product-types.read-failure")}</MDAlert>}
          {!loading && !error && <OrganizationProductTypeUpdate productType={productType} />}
        </Grid>
      </Grid>
    </DashboardPage>
  );
}
