import { useParams, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import useFinalizeLinkedServiceAccount from "hooks/organization/linkedServices/useFinalizeLinkedServiceAccount";
import { useEffect } from "react";
import { OrganizationLinkedServiceName } from "generated/graphql";

const FinalizeOrganizationLinkedService = () => {
  const { serviceName } = useParams<{ serviceName: OrganizationLinkedServiceName }>();
  const [finalizeLinkedServiceAccount] = useFinalizeLinkedServiceAccount(serviceName);

  const [URLSearchParams] = useSearchParams();
  const code = URLSearchParams.get("code");
  const realmId = URLSearchParams.get("realmId");
  const state = URLSearchParams.get("state");

  useEffect(() => {
    finalizeLinkedServiceAccount({
      variables: {
        service: serviceName,
        code,
        realmId,
        state,
      },
    });
  }, []);

  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h4" fontWeight="medium">
                Please wait...
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardPage>
  );
};

export default FinalizeOrganizationLinkedService;
