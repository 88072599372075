import { QueryHookOptions } from "@apollo/client";
import {
  GetEquipmentPiecesQuery,
  GetEquipmentPiecesQueryVariables,
  useGetEquipmentPiecesQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetEquipmentPieces";

export default function useGetEquipmentPieces(
  { page = 1, first = 10, search, sort }: GetEquipmentPiecesQueryVariables,
  baseOptions: QueryHookOptions<
    GetEquipmentPiecesQuery,
    GetEquipmentPiecesQueryVariables
  > = undefined
) {
  const {
    data: graphqlData,
    loading,
    error,
  } = useGetEquipmentPiecesQuery({
    variables: {
      page,
      first,
      search,
      sort,
    },
    ...baseOptions,
  });

  const getData = useCallback(({ data }: { data?: GetEquipmentPiecesQuery }) => {
    return data?.getEquipmentPieces?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: GetEquipmentPiecesQuery }) => {
    return data?.getEquipmentPieces?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return {
    data,
    loading,
    error,
    paginatorInfo,
    getData,
    getPaginatorInfo,
  } as const;
}
