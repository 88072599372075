import getLocalString from "constants/Localization";
import MDAlert from "components/MDAlert";
import CrewForm from "components/organization/CrewForm";
import { UpdateCrewInput, Crew } from "generated/graphql";
import useUpdateCrew from "hooks/organization/useUpdateCrew";
import { useCallback } from "react";

export default function Update({ crew }: { crew: Crew }) {
  const [updateCrew, { loading, error, crew: updatedCrew, isSuccess, getCrew }] = useUpdateCrew();

  const handleSubmit = useCallback(async (values: UpdateCrewInput) => {
    const formattedInput = { ...values, rate: Math.floor(values.rate * 100) };
    const { data } = await updateCrew({ variables: { input: formattedInput } });
    const success = isSuccess(data);
    return { success, data: getCrew(data) };
  }, []);

  return (
    <>
      {error && (
        <MDAlert color="error">{getLocalString("organization.update-crew-failure")}</MDAlert>
      )}
      <CrewForm crew={(updatedCrew ?? crew) as Crew} loading={loading} onSubmit={handleSubmit} />
    </>
  );
}
