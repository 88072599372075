import { useGetOrganizationProposalContentQuery } from "generated/graphql";
import { useMemo } from "react";
export default function useGetOrganizationProposalContent() {
  const { data, loading, error } = useGetOrganizationProposalContentQuery();

  const proposalContent = useMemo(() => {
    return data?.getOrganizationProposalContent || null;
  }, [data]);
  return { proposalContent, loading, error } as const;
}
