import getLocalString from "constants/Localization";
import { useCreateEquipmentPieceMutation } from "generated/graphql";

import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreateEquipmentPiece() {
  const [mutation, { loading, data, error }] = useCreateEquipmentPieceMutation({
    refetchQueries: () => ["getEquipmentPieces", "customGetEquipmentPieces"],
    update(cache) {
      cache.evict({ fieldName: "getEquipmentPieces" });
      cache.evict({ fieldName: "customGetEquipmentPieces" });
    },
  });
  const [createEquipmentPiece, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createEquipmentPiece",
    },
    {
      successMessage: getLocalString("organization.create-equipment-piece-success"),
      failureMessage: getLocalString("organization.create-equipment-piece-failure"),
    }
  );

  return [createEquipmentPiece, context] as const;
}
