import { FetchPolicy, QueryOptions } from "@apollo/client";
import { GetPlantsQuery, GetPlantsQueryVariables, useGetPlantsQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetPlants";

export default function useGetPlants({
  page = 1,
  first = 10,
  search,
  fetchPolicy = "cache-first",
}: GetPlantsQueryVariables & { fetchPolicy?: FetchPolicy }) {
  const {
    data: graphqlData,
    loading,
    error,
    fetchMore: more,
  } = useGetPlantsQuery({
    variables: {
      page,
      first,
      search,
    },
    fetchPolicy,
  });

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
      });
    },
    [more]
  );

  const getData = useCallback(({ data }: { data?: GetPlantsQuery }) => {
    return data?.getPlants?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: GetPlantsQuery }) => {
    return data?.getPlants?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return { data, loading, error, paginatorInfo, fetchMore, getData, getPaginatorInfo } as const;
}
