const pluralMeasurementMaterial = {
  TON: "Tons",
  GALLON: "Gallons",
  BLOCK: "Blocks",
  POUND: "Pounds",
  YARD: "Yards",
  UNIT: "Units",
};

export default pluralMeasurementMaterial;
