import lengthEndAdornment from "constants/lenghtEndAdornment";
import tooltipLengthEndAdornment from "constants/tootipLengthEndAdornment";
import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "applicationRate",
    label: "Application Rate in Inches",
    inputProps: { ...tooltipLengthEndAdornment },
  },
];

export default function SealcoatForm() {
  return (
    <>
      <CreateProductAttributesFormFields fields={fields} />
    </>
  );
}
