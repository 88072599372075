import getLocalString from "constants/Localization";
import { useAddOrganizationDocumentMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddOrganizationDocument() {
  const [mutation, { loading, data, error }] = useAddOrganizationDocumentMutation();
  const [addOrganizationDocument, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "addOrganizationDocument",
    },
    {
      successMessage: getLocalString("organization.add-document-success"),
      failureMessage: getLocalString("organization.add-document-failure"),
    }
  );

  return [addOrganizationDocument, context] as const;
}
