import MDBox from "components/MDBox";
import { Job } from "generated/graphql";
import CompanySection from "./CompanySection";
import JobCostSummarySection from "./JobCostSummarySection";
import JobStatusStepper from "./JobStatusStepper";
import DirectCostsSummarySection from "./DirectCostsSummaryTable";
import ProductSummarySection from "./ProductSummarySection";
import StackSection from "./Stack";
import MaterialOrLabourSummarySection from "./MaterialOrLabourSummarySection";

export default function View({ job }: { job: Job }) {
  return (
    <>
      <JobStatusStepper status={job.status} />
      <MDBox my={4}>
        <StackSection
          proposalId={job.proposalId}
          proposal={job.proposal}
          jobId={job.id}
          job={job}
          shouldPushTaxesToQuickbooks={job.shouldPushTaxesToQuickbooks}
          mayShowTaxNotification={job.mayShowTaxNotification}
        />
      </MDBox>
      <MDBox my={4}>
        <CompanySection
          contact={job.contact}
          company={job.company}
          address={job.address}
          projectManager={job.userId}
          billingStatus={job.billingStatus}
          jobId={job.id}
          notes={job.notes}
          datePushedToQuickbooks={job.datePushedToQuickbooks}
          stageName={job.proposalStage?.name}
          jobNumber={job.externalId}
          proposal={job.proposal}
          changeOrders={job.changeOrders}
          projectFilesFolderId={job.projectFilesFolderId}
        />
      </MDBox>
      <MDBox my={4}>
        <ProductSummarySection phases={job.jobPhases} />
      </MDBox>
      <MDBox my={4}>
        <JobCostSummarySection phases={job.jobPhases} />
      </MDBox>
      <MDBox my={4}>
        <DirectCostsSummarySection phases={job.jobPhases} />
      </MDBox>
      <MDBox my={4}>
        <MaterialOrLabourSummarySection proposedLabourHours={1} phases={job.jobPhases} />
      </MDBox>
    </>
  );
}
