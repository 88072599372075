import { SvgIcon, Link } from "@mui/material";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ProposalStatus } from "generated/graphql";
import ViewRawProposalIcon from "../../../../assets/images/view-raw-proposal.svg";

const ViewRawProposalButton = ({ proposal }) => {
  const href =
    proposal.status === ProposalStatus.DRAFT
      ? `/proposals/${proposal.id}/draft`
      : `/proposals/${proposal.id}`;

  return (
    <Tooltip title="View Proposal Breakdown">
      <Link href={href} target="_blank" underline="none">
        <IconButton>
          <img src={ViewRawProposalIcon} alt="View Raw Proposal" />
        </IconButton>
      </Link>
    </Tooltip>
  );
};

export default ViewRawProposalButton;
