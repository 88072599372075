import { Card, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Title from "./Title";
import { Job, JobPhase, useCustomGetJobQuery } from "generated/graphql";
import { useGetJob } from "hooks/jobs/useGetJob";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { getRoute } from "utils/routing";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const JobOverviewSection: React.FC<{
  jobId: Job["id"];
  jobPhaseId: JobPhase["id"];
  isJob?: boolean;
}> = ({ isJob = false, jobId, jobPhaseId }) => {
  const {
    data: job,

    loading,
    error,
  } = useGetJob({
    variables: {
      id: jobId,
    },
  });
  const navigate = useNavigate();
  return (
    <Card sx={{ my: 2 }} className="no-break">
      <MDBox bgColor="#f5f5f5" py={2} px={4} display="flex" justifyContent="space-between">
        <MDTypography variant="h4">Job Overview</MDTypography>
        {!isJob && (
          <MDButton
            type="button"
            variant="contained"
            color="blue"
            sx={{ maxHeight: "25px" }}
            className="no-print"
            onClick={() => navigate(getRoute("jobs.full.view.workorder", [["jobId", job.id]]))}
          >
            Job Work Order
          </MDButton>
        )}
      </MDBox>
      <Card sx={{ padding: 2 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>Job Phase</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Product Name</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Job Size</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Work Order</TableCell>
            </TableRow>

            {job?.jobPhases?.map((phase) => (
              <TableRow
                key={phase.id}
                sx={phase.id === jobPhaseId ? { backgroundColor: "#f5f5f5" } : {}}
              >
                <TableCell>{phase?.proposalProduct?.material?.name}</TableCell>
                <TableCell>{phase?.organizationProductTypeName}</TableCell>
                <TableCell>{phase?.jobSize}</TableCell>
                <TableCell>
                  {phase?.startsAt &&
                    dayjs.utc(phase?.startsAt).local().format("MMMM D, YYYY: h:mmA")}
                </TableCell>
                <TableCell>
                  {phase?.endsAt && dayjs.utc(phase?.endsAt).local().format("MMMM D, YYYY: h:mmA")}
                </TableCell>
                <TableCell>
                  {phase?.id !== jobPhaseId && (
                    <MDTypography
                      as={Link}
                      to={`/jobs/${jobId}/phases/${phase?.id}/work-order`}
                      sx={{
                        color: "blue",
                      }}
                    >
                      {" "}
                      View Work Order
                    </MDTypography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </Card>
  );
};

export default JobOverviewSection;
