import {
  GetOrganizationUserQueryVariables,
  useGetOrganizationUserLazyQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetOrganizationUser() {
  const [query, { data, loading, error }] = useGetOrganizationUserLazyQuery();

  const getOrganizationUser = useCallback(
    (values: GetOrganizationUserQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const user = useMemo(() => {
    return (data && data.getOrganizationUser) || null;
  }, [data]);

  return [getOrganizationUser, { data, loading, error, user }] as const;
}
