import { RequestPasswordResetInput, useRequestPasswordResetMutation } from "generated/graphql";
import { useCallback } from "react";

export default function useRequestPasswordReset() {
  const [requestPasswordResetMutation, { data, loading, error }] =
    useRequestPasswordResetMutation();

  const requestPasswordReset = useCallback((values: RequestPasswordResetInput) => {
    requestPasswordResetMutation({ variables: { input: values } });
  }, []);

  const success = data && data.requestPasswordReset === true;
  const failure = data && data.requestPasswordReset === false;

  return [requestPasswordReset, { data, loading, error, success, failure }] as const;
}
