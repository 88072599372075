import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, FormHelperText } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Job } from "generated/graphql";
import useUpdateJobNotes from "hooks/jobs/useUpdateJobNotes";
import { useForm } from "react-hook-form";
import * as z from "zod";

export default function NotesSection({ notes, jobId }: { jobId: Job["id"]; notes: Job["notes"] }) {
  const [updateJobNotes, { loading }] = useUpdateJobNotes();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { notes },
    resolver: zodResolver(
      z.object({
        notes: z.string().min(1, { message: "Field is required" }),
      })
    ),
  });

  return (
    <MDBox
      pb={4}
      pl={4}
      component="form"
      role="form"
      onSubmit={handleSubmit((data: { id: string; notes: string }) => {
        updateJobNotes({
          variables: {
            id: jobId,
            ...data,
          },
        });
      })}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={10}>
          <MDTypography variant="h4">Notes</MDTypography>
          <MDInput
            multiline
            rows={7}
            fullWidth
            margin="normal"
            error={errors.notes}
            {...register("notes")}
          />

          {errors && errors?.notes && (
            <FormHelperText
              sx={{
                color: "red",
              }}
            >
              Field is required
            </FormHelperText>
          )}
          <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
            Save
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}
