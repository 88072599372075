import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  schema,
  getDefaultValues,
  JobPhaseCostItemInputSchema,
} from "DDD/action-objects/JobPhaseCostItemCreate";
import { useContext, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import AdditionalSection from "../AdditionalSection";
import { JobPhaseContext } from "../context/JobPhaseContext";
import EmployeeSection from "../EmployeeSection";
import EquipmentSection from "../EquipmentSection";
import MaterialSection from "../MaterialSection";
import TransferTruckSection from "../TransferTruckSection";
import MDTypography from "components/MDTypography";
import JobCostingSection from "../JobCostingSection";
import { CustomJobPhaseCostItem } from "hooks/jobs/job-phases/useGetJobPhaseCostItem";

const JobPhaseCost = ({
  saving,
  onSubmit,
}: {
  saving: boolean;
  onSubmit: SubmitHandler<JobPhaseCostItemInputSchema>;
}) => {
  const { jobPhase, jobPhaseCostItem, date } = useContext(JobPhaseContext);
  const methods = useForm<JobPhaseCostItemInputSchema>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues({ jobPhase, jobPhaseCostItem, date }),
  });

  useEffect(() => {
    methods.reset(getDefaultValues({ jobPhase, jobPhaseCostItem, date }));
  }, [jobPhase, jobPhaseCostItem]);
  return (
    <FormProvider {...methods}>
      <MDBox component="form" role="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <>
          <MDBox my={4}>
            <MaterialSection jobPhase={jobPhase} costItem={jobPhaseCostItem} />
          </MDBox>
          <MDBox my={4}>
            <EmployeeSection />
          </MDBox>
          <MDBox my={4}>
            <EquipmentSection />
          </MDBox>
          <MDBox my={4}>
            <AdditionalSection />
          </MDBox>
          <MDBox my={4}>
            <TransferTruckSection />
          </MDBox>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <MDButton type="submit" variant="contained" color="success">
              {saving ? <CircularProgress color="inherit" size={20} /> : "Save Day"}
            </MDButton>
          </Grid>
        </>
      </MDBox>
    </FormProvider>
  );
};

export default JobPhaseCost;
