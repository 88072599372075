import useDebouncedSearchTerm from "hooks/strings/useDebouncedSearchTerm";
import { useCallback, useEffect, useState } from "react";

export default function useFileSearch(files) {
  const [searchResult, setSearchResult] = useState([]);
  const { setSearchTerm, debouncedSearchTerm } = useDebouncedSearchTerm("");

  const searchFiles = useCallback((query, node, foundNodeIds = new Set([])) => {
    const lowerQuery = query.toLowerCase();
    const found = [];

    if (node.name && node.name.toLowerCase().includes(lowerQuery)) {
      found.push(node);
      foundNodeIds.add(node.id);
    }

    if (node.children && Array.isArray(node.children)) {
      node.children.forEach((child) => {
        if (!foundNodeIds.has(child.parentFolderId)) {
          found.push(...searchFiles(query, child, foundNodeIds));
        }
      });
    }

    return found;
  }, []);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setSearchResult([]);
      return;
    }

    let allResults = [];
    files.forEach((file) => {
      allResults = allResults.concat(searchFiles(debouncedSearchTerm, file));
    });
    setSearchResult(allResults);
  }, [debouncedSearchTerm]);

  const search = useCallback((query) => {
    setSearchTerm(query);
  }, []);

  return { searchResult, search };
}
