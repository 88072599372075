import useBitumioMutation from "hooks/useBitumioMutation";
import { useCreateOrganizationApiKeyMutation } from "generated/graphql";
import getLocalString from "constants/Localization";

export const useCreateOrganizationApiKey = () => {
  const [mutation, { data, loading, error }] = useCreateOrganizationApiKeyMutation();
  const [createApiKey, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createApiKey",
    },
    {
      hideMessage: true,
    }
  );
  return [createApiKey, context] as const;
};
