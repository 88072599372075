import getLocalString from "constants/Localization";
import { useUpdateStageProductsOrderingMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

const useUpdateStageProductsOrdering = () => {
  const [mutation, { loading }] = useUpdateStageProductsOrderingMutation();

  // not using useBitumioMutation here because everything needs to happen silently
  const sendRequest = (data) => {
    mutation({
      variables: {
        input: data,
      },
    });
  };

  return { sendRequest, loading };
};

export default useUpdateStageProductsOrdering;
