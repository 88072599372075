import { useMemo } from "react";
import ActionsCell from "modules/OrganizationCrews/ActionsCell";
import usePaginatedResources from "hooks/usePaginatedResources";
import useGetCrews from "./useGetCrews";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { sortDirectionMap } from "constants/sortDirectionMap";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { Link } from "react-router-dom";

export default function useCrewsTable() {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo, fetchMore, getData, getPaginatorInfo } = useGetCrews(
    {
      page: offset.page,
      first: offset.first,
      search: filtering.debouncedSearchTerm,
      sort: sortDirectionMap[sorting.sortDirection],
    }
  );
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const colorBox = ({ value }: { value: any; row: any }) => {
    return <div style={{ width: "20px", height: "20px", background: "#" + value }}>&nbsp;</div>;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => (
          <Link style={{ color: "inherit" }} to={`/organization/crews/${row.original.id}`}>
            {value}
          </Link>
        ),
      },
      { Header: "Display Name", accessor: "displayName" },
      {
        Header: "Rate",
        accessor: "rate",
        Cell: ({ value }) => formatCentsToUSD(value),
      },
      { Header: "Color", accessor: "color", Cell: colorBox },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    paginatorInfo,
    filtering,
  } as const;
}
