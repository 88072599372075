import { ApolloError } from "@apollo/client";
import * as z from "zod";

const extension = z.object({
  category: z.literal("validation"),
  validation: z.record(z.array(z.string())),
});

const errorDetail = z.object({
  extensions: extension,
});

const schema = z.object({
  graphQLErrors: z.array(errorDetail).nonempty(),
});

type ParsedError = z.infer<typeof schema>;

export default function parseGraphQLError(error: ApolloError) {
  try {
    const parsed: ParsedError = schema.parse(error);
    return parsed.graphQLErrors
      .map((graphqlError) =>
        Object.values(graphqlError.extensions.validation).map((validationError) =>
          validationError.map((verror) => verror.replace("input.", "")).join(" ")
        )
      )
      .join(" ");
  } catch (e) {
    return error.message;
  }
}
