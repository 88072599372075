import { CustomCompany } from "constants/Company";
import { z } from "zod";

export const schema = z.object({
  id: z.string().min(1, "Company is required"),
  notes: z.string(),
});

export type UpdateCompanyNotesInputSchema = z.infer<typeof schema>;

export const getDefaultValues = ({ company }: { company: CustomCompany }) => {
  return {
    id: company.id,
    notes: company.notes,
  };
};
