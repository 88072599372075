import { NotificationTriggerFragmentFragment, UserPermissions } from "generated/graphql";
import UpdateEmailTriggerMenuItem from "../UpdateEmailTriggerMenuItem/UpdateEmailTriggerMenuItem";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export default function EmailTriggerMenuItems({
  id,
  closeMenu,
}: {
  id: NotificationTriggerFragmentFragment["id"];
  closeMenu: () => void;
}) {
  const { hasPermissions } = useCheckPermissions();

  return (
    <>
      {hasPermissions(UserPermissions.MANAGE_NOTIFICATION_TRIGGERS) && (
        <UpdateEmailTriggerMenuItem closeMenu={closeMenu} id={id} />
      )}
    </>
  );
}
