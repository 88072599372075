import React, { useCallback } from "react";
import { useBitumioContext } from "providers/BitumioContext";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import getLocalString from "constants/Localization";

export default function useIncomeAccount() {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();

  const getIncomeAccountData = useCallback(async () => {
    try {
      showLoader();
      const response = await fetch(
        import.meta.env.VITE_BACKEND_API_BASE_URL + `/api/quickbooks/income-account`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.ok) {
        return data?.data;
      }

      const errorMessage = data?.message ?? "";
      show({
        message: errorMessage || getLocalString("generic.failure"),
        options: { variant: "error" },
      });
      return "";
    } catch (error) {
      show({
        message: getLocalString("generic.failure"),
        options: { variant: "error" },
      });
      return "";
    } finally {
      hideLoader();
    }
  }, []);

  const getIncomeAccount = useCallback(() => {
    return getIncomeAccountData();
  }, [getIncomeAccountData]);

  return [getIncomeAccount];
}
