import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateOrganizationAddressInput,
  UpdateOrganizationAddressMutation,
  UpdateOrganizationAddressMutationVariables,
  useUpdateOrganizationAddressMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export default function useUpdateOrganizationAddress() {
  const [mutation, { data, loading, error }] = useUpdateOrganizationAddressMutation();
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateOrganizationAddressMutation) => {
    return (data && !!data.updateOrganizationAddress?.id) || false;
  }, []);

  const getAddress = useCallback((data: UpdateOrganizationAddressMutation) => {
    return data && data.updateOrganizationAddress;
  }, []);

  const address = useMemo(() => {
    return getAddress(data) || null;
  }, [data]);

  const updateOrganizationAddress = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          UpdateOrganizationAddressMutation,
          UpdateOrganizationAddressMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.update-address-success"),
        failureMessage: getLocalString("organization.update-address-failure"),
      }
    ),
    []
  );

  return [
    updateOrganizationAddress,
    { data, loading, error, isSuccess, address, getAddress },
  ] as const;
}
