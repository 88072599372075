import { PlantProductType } from "generated/graphql";
import * as z from "zod";

export const defaultValues = {
  name: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  addressCountry: "US",
  products: {
    create: [
      {
        type: PlantProductType.ASPHALT,
        rate: 0,
      },
    ],
  },
};

export const defaultValuesOtherProduct = {
  type: PlantProductType.ASPHALT,
  rate: 0,
};

export const defaultValuesOtherTransferTruck = {
  id: "",
  tonnage: 0,
  rateHourly: 0,
  rateTon: 0,
  transferTruckId: "",
};

export const schema = z.object({
  name: z.string().min(1, "Name is Required"),
  phone: z.string().min(1, "Phone is Required"),
  addressLine1: z.string().min(1, "Address Line 1 is Required"),
  addressLine2: z.string().optional(),
  addressCity: z.string().min(1, "Address City is Required"),
  addressState: z.string().min(1, "Address State is Required"),
  addressZip: z.string().min(1, "Address Zip is Required"),
  addressCountry: z.string().min(1, "Address Country is Required"),
  tonConversionFactor: z.number().optional(),
  products: z.object({
    create: z.array(
      z.object({
        type: z.string(),
        rate: z.number().min(0, "Product rate is required"),
      })
    ),
  }),
});
