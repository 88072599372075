import getLocalString from "constants/Localization";
import { useUpdateCompanyProjectFileFolderIdMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateCompanyProjectFileFolderId() {
  const [mutation, { loading, data, error }] = useUpdateCompanyProjectFileFolderIdMutation();
  const [updateJobProjectFileFolderId, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateCompanyProjectFileFolderId",
    },
    {
      successMessage: getLocalString("companies.update-company-project-file-folder-id-success"),
      failureMessage: getLocalString("companies.update-company-project-file-folder-id-failure"),
    }
  );

  return [updateJobProjectFileFolderId, context] as const;
}
