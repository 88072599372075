export const meterConversions: {
  feet: number;
  squareFeet: number;
  yards: number;
  squareYards: number;
} = {
  feet: 3.28084,
  squareFeet: 10.764,
  yards: 0.9144,
  squareYards: 1.196,
};
