import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";

export type OptionsList = number[];

export default function createSelectOptions(list: OptionsList): SelectOptions {
  return list.map((item) => ({
    label: `${item}`,
    value: item,
  }));
}
