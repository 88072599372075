import {
  PreviewSubscriptionChangeQueryVariables,
  usePreviewSubscriptionChangeLazyQuery,
} from "generated/graphql";
import { useCallback } from "react";

export default function usePreviewSubscriptionChange() {
  const [query, { data, loading, error }] = usePreviewSubscriptionChangeLazyQuery();

  const previewSubscription = useCallback(
    (values: PreviewSubscriptionChangeQueryVariables) => {
      return query({
        variables: values,
      });
    },
    [query]
  );

  const totals = data && data.previewSubscriptionChange;
  const success = !!data;

  return [previewSubscription, { data, loading, error, totals, success }] as const;
}
