import {
  Box,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { capitalCase } from "change-case";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import ProposalActionsCell from "components/proposals/ProposalActionsCell";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import dayjs from "dayjs";
import { GetProposalsSortKey, Proposal, SortDirection, ProposalStatus } from "generated/graphql";
import useProposalsTableData from "hooks/proposals/useProposalsTableData";
import { StyledTableRow } from "modules/jobs/JobsSchedules/JobsSchedules";
import React, { useCallback, useState, useEffect } from "react";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import SingleChoiceFilter from "components/filters/SingleChoiceFilter/SingleChoiceFilter";
import MultipleChoiceFilter from "components/filters/MultipleChoiceFilter/MultipleChoiceFilter";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { equals, reject, uniq } from "ramda";

export default function ListProposals() {
  const {
    data: proposals,
    loading,
    error,
    pagination,
    sorting,
    filterControl,
    companiesData,
    companiesLoading,
    companiesError,
    filtering,
    organizationUsersLoading,
    organizationUsersData,
    organizationUsersError,
  } = useProposalsTableData();

  const [openStatus, setOpenStatus] = useState<Record<string, boolean | undefined>>({});

  useEffect(() => {
    localStorage.setItem("ListProposalsTableFilters", JSON.stringify(filtering));
  }, [filtering]);

  const handleToggle = useCallback((id: Proposal["id"]) => {
    setOpenStatus((prevState) => ({
      ...prevState,
      [id]: typeof prevState[id] === "undefined" ? true : !prevState[id],
    }));
  }, []);

  const handleStatusChecked = useCallback(
    (selectedOptions) => {
      return filtering.setStatuses(selectedOptions);
    },
    [filtering]
  );

  const handleClientChecked = useCallback(
    (selectedOptions) => {
      return filtering.setMultipleCompanyIds(selectedOptions);
    },
    [filtering]
  );

  const handleEstimatorChecked = useCallback(
    (selectedOptions) => {
      return filtering.setMultipleUserIDs(selectedOptions);
    },
    [filtering]
  );

  return (
    <>
      <MDBox mb={3}>{filterControl}</MDBox>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow sx={{ backgroundColor: "grey.300" }}>
                <TableCell
                  align="right"
                  sortDirection={
                    sorting.sortKey === GetProposalsSortKey.ID
                      ? SortDirection[sorting.sortKey]
                      : false
                  }
                >
                  <TableSortLabel
                    active={sorting.sortKey === GetProposalsSortKey.ID}
                    direction={
                      sorting.sortKey === GetProposalsSortKey.ID
                        ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                        : "asc"
                    }
                    onClick={() => {
                      const isAsc =
                        sorting.sortKey === GetProposalsSortKey.ID &&
                        sorting.sortDirection === SortDirection.ASC;
                      sorting.setSort({
                        sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                        sortKey: GetProposalsSortKey.ID,
                      });
                    }}
                  >
                    <MDBox display="flex" alignItems="center">
                      ID <Icon>sort</Icon>
                    </MDBox>
                    {sorting.sortKey === GetProposalsSortKey.ID ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sorting.sortDirection === SortDirection.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="right"
                  sortDirection={
                    sorting.sortKey === GetProposalsSortKey.COMPANY
                      ? SortDirection[sorting.sortKey]
                      : false
                  }
                >
                  <MDBox display={"flex"}>
                    <TableSortLabel
                      active={sorting.sortKey === GetProposalsSortKey.COMPANY}
                      direction={
                        sorting.sortKey === GetProposalsSortKey.COMPANY
                          ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                          : "asc"
                      }
                      onClick={() => {
                        const isAsc =
                          sorting.sortKey === GetProposalsSortKey.COMPANY &&
                          sorting.sortDirection === SortDirection.ASC;
                        sorting.setSort({
                          sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                          sortKey: GetProposalsSortKey.COMPANY,
                        });
                      }}
                    >
                      <MDBox display="flex" alignItems="center">
                        Client Name <Icon>sort</Icon>
                      </MDBox>
                      {sorting.sortKey === GetProposalsSortKey.COMPANY ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting.sortDirection === SortDirection.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>

                    <MultipleChoiceFilter
                      id={"company.name"}
                      value={filtering.multipleCompanyIDs}
                      loading={companiesLoading}
                      error={companiesError}
                      options={
                        companiesData?.map((company) => ({
                          label: company.name,
                          value: company.id,
                        })) ?? []
                      }
                      onChange={handleClientChecked}
                    />
                  </MDBox>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={
                    sorting.sortKey === GetProposalsSortKey.STATUS
                      ? SortDirection[sorting.sortKey]
                      : false
                  }
                >
                  <MDBox display={"flex"}>
                    <TableSortLabel
                      active={sorting.sortKey === GetProposalsSortKey.STATUS}
                      direction={
                        sorting.sortKey === GetProposalsSortKey.STATUS
                          ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                          : "asc"
                      }
                      onClick={() => {
                        const isAsc =
                          sorting.sortKey === GetProposalsSortKey.STATUS &&
                          sorting.sortDirection === SortDirection.ASC;
                        sorting.setSort({
                          sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                          sortKey: GetProposalsSortKey.STATUS,
                        });
                      }}
                    >
                      <MDBox display="flex" alignItems="center">
                        Status <Icon>sort</Icon>
                      </MDBox>
                      {sorting.sortKey === GetProposalsSortKey.STATUS ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting.sortDirection === SortDirection.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>

                    <MultipleChoiceFilter
                      id={"status"}
                      value={filtering.statuses}
                      options={enumToValueOptions(ProposalStatus)}
                      onChange={handleStatusChecked}
                    />
                  </MDBox>
                </TableCell>
                <TableCell>
                  <MDBox display="flex" alignItems="center">
                    Estimator
                    <MultipleChoiceFilter
                      id={"estimator"}
                      value={filtering.multipleUserIDs}
                      loading={organizationUsersLoading}
                      error={organizationUsersError}
                      options={
                        organizationUsersData?.map((user) => ({
                          label: user.name,
                          value: user.id,
                        })) ?? []
                      }
                      onChange={handleEstimatorChecked}
                    />
                  </MDBox>
                </TableCell>
                <TableCell align="left">
                  <MDBox display="flex" alignItems="center">
                    Contact Name
                  </MDBox>
                </TableCell>
                <TableCell align="left">
                  <MDBox display="flex" alignItems="center">
                    Contact Phone
                  </MDBox>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={
                    sorting.sortKey === GetProposalsSortKey.CREATED
                      ? SortDirection[sorting.sortKey]
                      : false
                  }
                >
                  <TableSortLabel
                    active={sorting.sortKey === GetProposalsSortKey.CREATED}
                    direction={
                      sorting.sortKey === GetProposalsSortKey.CREATED
                        ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                        : "asc"
                    }
                    onClick={() => {
                      const isAsc =
                        sorting.sortKey === GetProposalsSortKey.CREATED &&
                        sorting.sortDirection === SortDirection.ASC;
                      sorting.setSort({
                        sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                        sortKey: GetProposalsSortKey.CREATED,
                      });
                    }}
                  >
                    <MDBox display="flex" alignItems="center">
                      Created Date <Icon>sort</Icon>
                    </MDBox>
                    {sorting.sortKey === GetProposalsSortKey.CREATED ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sorting.sortDirection === SortDirection.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sortDirection={
                    sorting.sortKey === GetProposalsSortKey.SENT
                      ? SortDirection[sorting.sortKey]
                      : false
                  }
                >
                  <TableSortLabel
                    active={sorting.sortKey === GetProposalsSortKey.SENT}
                    direction={
                      sorting.sortKey === GetProposalsSortKey.SENT
                        ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                        : "asc"
                    }
                    onClick={() => {
                      const isAsc =
                        sorting.sortKey === GetProposalsSortKey.SENT &&
                        sorting.sortDirection === SortDirection.ASC;
                      sorting.setSort({
                        sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                        sortKey: GetProposalsSortKey.SENT,
                      });
                    }}
                  >
                    <MDBox display="flex" alignItems="center">
                      Sent Date <Icon>sort</Icon>
                    </MDBox>
                    {sorting.sortKey === GetProposalsSortKey.SENT ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sorting.sortDirection === SortDirection.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">Proposal Total</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proposals?.map((proposal, i) => {
                return (
                  <React.Fragment key={proposal.id}>
                    <StyledTableRow
                      className={i % 2 === 0 ? "outer even" : "outer odd"}
                      key={proposal.id}
                    >
                      <TableCell sx={{ position: "relative", minWidth: "100px" }}>
                        <MDBox display="flex">
                          <IconButton sx={{ p: 0 }} onClick={() => handleToggle(proposal.id)}>
                            <Icon
                              className={`chevron ${
                                openStatus[proposal.id] ? "expand_more" : "expand_less"
                              }`}
                            >
                              expand_less
                            </Icon>
                          </IconButton>
                          <MDBox>
                            <Link
                              style={{ color: "inherit" }}
                              to={`/proposals/${proposal.id}/info`}
                            >
                              {proposal.externalId}
                            </Link>
                          </MDBox>
                        </MDBox>
                      </TableCell>
                      <TableCell>{proposal.company.name}</TableCell>
                      <TableCell>{capitalCase(proposal.status)}</TableCell>
                      <TableCell>{proposal?.user?.name ?? ""}</TableCell>
                      <TableCell>
                        {proposal.contact?.firstName + " " + proposal.contact?.lastName}
                      </TableCell>
                      <TableCell>{proposal.contact?.phone}</TableCell>
                      <TableCell>
                        {proposal.createdAt ? dayjs(proposal.createdAt).format("YYYY-MM-DD") : "-"}
                      </TableCell>
                      <TableCell>
                        {proposal.sentAt ? dayjs(proposal.sentAt).format("YYYY-MM-DD") : "-"}
                      </TableCell>
                      <TableCell align="right">{formatCentsToUSD(proposal.overallTotal)}</TableCell>
                      <TableCell>
                        <ProposalActionsCell row={{ values: proposal }} />
                      </TableCell>
                    </StyledTableRow>
                    {openStatus[proposal.id] && (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <strong>Stage Name</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Address</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Products</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Stage Total</strong>
                        </TableCell>
                      </TableRow>
                    )}
                    {openStatus[proposal.id] &&
                      proposal.stages.map((proposalStage, y) => (
                        <TableRow key={proposalStage.id}>
                          <TableCell></TableCell>
                          <TableCell>{proposalStage.name ? proposalStage.name : "-"}</TableCell>
                          <TableCell>
                            {proposalStage?.address?.line1 ? proposalStage?.address?.line1 : "-"}
                          </TableCell>
                          <TableCell>
                            {proposalStage.proposalProducts.length
                              ? proposalStage.proposalProducts.map((product, index) => (
                                  <span key={index}>
                                    {product.productName}
                                    {index + 1 === proposalStage.proposalProducts.length
                                      ? ""
                                      : ", "}
                                  </span>
                                ))
                              : "-"}
                          </TableCell>
                          <TableCell>{formatCentsToUSD(proposalStage.overallTotal)}</TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={9}>
                  <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {pagination.component}
                  </MDBox>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
