import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { Proposal } from "generated/graphql";
import useDeleteProposal from "hooks/proposals/useDeleteProposal";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export default function DeleteProposalMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: Proposal["id"];
}) {
  const [deleteProposal, { isSuccess }] = useDeleteProposal();

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const { data } = await deleteProposal({ variables: { id } });

    if (isSuccess(data)) {
      closeMenu();
      return true;
    } else {
      return false;
    }
  }, [deleteProposal, id]);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });
  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you want to delete this Proposal?"
      />
      <MenuItem data-test-id="proposal-delete" onClick={() => setOpen(true)}>
        Delete
      </MenuItem>
    </>
  );
}
