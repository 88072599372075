import { Box, Icon, IconButton, Modal as MuiModal, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "80vh",
  maxWidth: "1150px",
  maxHeight: "1024px",
  scrollY: "auto",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
};

const Modal: React.FC<{ open: boolean; onClose: () => void; styles?: SxProps<Theme> }> = ({
  children,
  open,
  onClose,
  styles = {},
}) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, ...styles }} overflow="auto">
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: 4,
          }}
        >
          <Icon fontSize="large" color="secondary">
            highlight_off
          </Icon>
        </IconButton>
        {children}
      </Box>
    </MuiModal>
  );
};

export default Modal;
