import getLocalString from "constants/Localization";
import { useCustomSendProposalToSpecificRecipientsMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSendProposalToSpecificRecipients() {
  const [mutation, { loading, data, error }] = useCustomSendProposalToSpecificRecipientsMutation({
    refetchQueries: ["customGetProposal", "getProposal", "customGetProposals"],
  });
  const [sendProposalToSpecificRecipients, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "sendProposalToSpecificRecipients",
    },
    {
      successMessage: getLocalString("proposals.send-proposal-to-recipient-success"),
      failureMessage: getLocalString("proposals.send-proposal-to-recipient-failure"),
    }
  );

  return [sendProposalToSpecificRecipients, context] as const;
}
