import { Grid } from "@mui/material";
import OpportunityPageFormLoader from "components/loaders/OpportunityPageFormLoader";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import getLocalString from "constants/Localization";

import { useGetOpportunity } from "hooks/opportunities/useGetOpportunity";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import UpdateOpportunity from "modules/UpdateOpportunity/UpdateOpportunity";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useParams } from "react-router";
import { Opportunity } from "../../generated/graphql";

export default function UpdateOpportunityPage() {
  const { opportunityId } = useParams();
  const { loading, error, data: opportunity } = useGetOpportunity({ id: opportunityId });

  if (loading) {
    return <OpportunityPageFormLoader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        {error && (
          <MDAlert color="error">
            {getLocalString("opportunities.load-failure")}. {error.message}
          </MDAlert>
        )}
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Update Opportunity
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        {!error && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UpdateOpportunity opportunity={opportunity as Opportunity} />
            </Grid>
          </Grid>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
