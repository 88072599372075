import { useMemo } from "react";

const useYearToDateJobCompletionTable = (data) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Product Type",
        accessor: "productType",
      },
      {
        Header: "Job Size Estimated",
        accessor: "proposalJobSize",
      },
      {
        Header: "Job Size Completed",
        accessor: "jobSizeComplete",
      },
      { Header: "Material Estimated", accessor: "proposalMaterialNeeded" },
      {
        Header: "Material Used",
        accessor: "productMaterialUsed",
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    tableData,
  };
};

export default useYearToDateJobCompletionTable;
