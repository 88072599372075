import { GetOrganizationUsersQuery, useGetOrganizationUsersLazyQuery } from "generated/graphql";
import { useCallback, useEffect, useRef, useState } from "react";

const useGetAllOrganizationUsers = () => {
  const [status, setStatus] = useState("pending");
  const [getOrganizationUsers, { loading, error }] = useGetOrganizationUsersLazyQuery({
    fetchPolicy: "network-only",
  });
  const [data, setData] = useState<any | null>(null);
  const page = useRef(0);

  const getAllOrganizationUsers = useCallback(async () => {
    page.current += 1;
    const { data } = await getOrganizationUsers({
      variables: {
        page: page.current,
        first: 250,
      },
    });
    if (data?.getOrganizationUsers.paginatorInfo.hasMorePages) {
      setData((prevState) => [...(prevState ? prevState : []), ...data.getOrganizationUsers.data]);
      getAllOrganizationUsers();
    } else {
      setData((prevState) => [
        ...(prevState ? prevState : []),
        ...(data?.getOrganizationUsers.data ?? []),
      ]);
      setStatus("fulfilled");
    }
  }, [getOrganizationUsers]);

  useEffect(() => {
    getAllOrganizationUsers();
  }, []);

  return { loading: status === "pending" || loading, error, data } as const;
};

export default useGetAllOrganizationUsers;
