import { useUpdateCompanyNotesMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import getLocalString from "constants/Localization";

export default function useUpdateCompanyNotes() {
  const [mutation, { loading, data, error }] = useUpdateCompanyNotesMutation();

  const [updateCompanyNotes, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateCompanyNotes",
    },
    {
      successMessage: getLocalString("companies.update-notes-success"),
      failureMessage: getLocalString("companies.update-notes-failure"),
    }
  );

  return [updateCompanyNotes, context] as const;
}
