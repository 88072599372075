import { UpdateOrganizationProposalContentMutationVariables } from "generated/graphql";
import useUpdateOrganizationProposalContent from "hooks/organization/useUpdateOrganizationPropoposalContent";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import ProposalSettingsList from "modules/ProposalSettingsList/ProposalSettingsList";
import { useCallback } from "react";

export default function ProposalSettingsPage() {
  const [updateProposalSettings, { loading, error, getData, isSuccess }] =
    useUpdateOrganizationProposalContent();

  const onSubmit = useCallback(
    async (variables: UpdateOrganizationProposalContentMutationVariables) => {
      const result = await updateProposalSettings({ variables });
      const success = isSuccess(result.data);
      const data = getData(result);
      return { success, data };
    },
    [updateProposalSettings]
  );
  return (
    <DashboardLayout>
      <ProposalSettingsList onSubmit={onSubmit} loading={loading} />
    </DashboardLayout>
  );
}
