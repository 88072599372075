import {
  CustomGetProductsQuery,
  CustomGetProductsQueryVariables,
  useCustomGetProductsQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetProducts";

export default function useGetProducts({
  page = 1,
  first = 10,
  search,
  orderBy,
  organizationProductTypeId,
}: CustomGetProductsQueryVariables) {
  const {
    data: graphqlData,
    loading,
    error,
    fetchMore: more,
    refetch,
  } = useCustomGetProductsQuery({
    variables: {
      page,
      first,
      search,
      orderBy,
      organizationProductTypeId,
    },
  });

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          getProducts: {
            ...previousResult.getProducts,
            data: [...previousResult?.getProducts?.data, ...fetchMoreResult?.getProducts?.data],
          },
        }),
      });
    },
    [more]
  );

  const getData = useCallback(
    ({ data }: { data?: CustomGetProductsQuery }) => {
      return data?.getProducts?.data || null;
    },
    [graphqlData]
  );

  const getPaginatorInfo = useCallback(({ data }: { data?: CustomGetProductsQuery }) => {
    return data?.getProducts?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);
  const getAllData = useCallback(async () => {
    if (paginatorInfo && paginatorInfo?.total) {
      await refetch({
        first: paginatorInfo?.total ?? first,
        search,
        orderBy,
        organizationProductTypeId,
      });
    }
  }, [graphqlData, paginatorInfo]);

  return {
    data,
    loading,
    error,
    paginatorInfo,
    fetchMore,
    getData,
    getPaginatorInfo,
    refetch,
    getAllData,
  } as const;
}
