import { OrganizationProductType, UpdateOrganizationProductTypeInput } from "generated/graphql";
import useCreateOrganizationProductType from "hooks/organization/product-types/useCreateOrganizationProductType";
import useUpdateOrganizationProductType from "hooks/organization/product-types/useUpdateOrganizationProductType";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";
import OrganizationProductTypeUpdateForm from "./Forms/OrganizationProductTypeUpdateForm";

export default function OrganizationProductTypeUpdate({
  productType,
}: {
  productType: OrganizationProductType;
}) {
  const navigate = useNavigate();
  // mutation to create the product type
  const [updateOrganizationProductType, { loading, isSuccess, getData }] =
    useUpdateOrganizationProductType();

  // submit handler to create the product type
  const handleSubmit = useCallback(async (input: UpdateOrganizationProductTypeInput) => {
    const response = await updateOrganizationProductType({
      variables: {
        input,
      },
    });
    const success = isSuccess(response.data);
    if (success) {
      navigate(getRoute("organization.product-types.list"));
    }
    return { success, data: getData(response) };
  }, []);

  return (
    <OrganizationProductTypeUpdateForm
      loading={loading}
      onSubmit={handleSubmit}
      productType={productType}
    />
  );
}
