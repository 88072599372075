import type { FeatureCollection } from "geojson";
import MapComponent from "components/Map/Map";
import { MapOutput } from "interfaces/interfaces";
import FullScreenModal from "modules/FullScreenModal/FullScreenModal";
import { ProductMeasurementJob } from "generated/graphql";

interface MapModalProps {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
  onSave: (data: MapOutput) => void;
  guided: boolean;
  initialArea: FeatureCollection | null;
  hideLocation: boolean;
  showTools?: [keyof typeof ProductMeasurementJob];
  measureMode?: boolean;
}
const isTouchDevice = (): boolean => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
};

const useTouchControls = (): boolean => {
  const isMobile = isTouchDevice();
  return isMobile;
};

const isTouch = useTouchControls();
export default function MapModal({
  onClose,
  onSave,
  guided = false,
  open,
  initialArea,
  hideLocation,
  showTools,
  measureMode,
}: Partial<MapModalProps>) {
  const sendSaveOnClose = (data: MapOutput) => {
    if (onSave) {
      onSave(data);
      onClose();
    }
  };
  const createIslandConfirmation = (callback: () => void): boolean => {
    if (confirm("Do you want to create an island?")) {
      callback();
      return true;
    }

    return false;
  };
  return (
    <FullScreenModal onClose={onClose} open={open}>
      <MapComponent
        createIslandConfirmation={createIslandConfirmation}
        readonly={false}
        onSave={sendSaveOnClose}
        initialArea={initialArea}
        initialFeatures={null}
        initialCenter={null}
        guidedControls={guided}
        isTouch={isTouch}
        hideLocation={hideLocation}
        showTools={showTools}
        close={onClose}
      />
    </FullScreenModal>
  );
}
