import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { CustomJobSchedules } from "hooks/schedules/useGetJobSchedules";
import generateBlocksForJobPhase from "utils/jobs/generateBlocksForJobPhase";
import { z } from "zod";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const blocksSchema = z.object({
  scheduledAt: z.string().min(1, "Scheduled at is required"),
  blockLength: z.number().positive(),
  showTravelTo: z.boolean(),
  showTravelFrom: z.boolean(),
});

export const schema = z.object({
  jobPhaseId: z.string().min(1, "Job Phase ID is required"),
  crewId: z.string().optional(),
  userIDs: z.array(z.string()).optional(),
  assignTo: z.string().optional(),
  usersColor: z.string().optional(),
  travelTimeEstimate: z.null().or(z.number().optional()),
  blocks: z.array(blocksSchema),
});

export type ScheduleJobPhaseInput = z.infer<typeof schema>;

export function getDefaultValues({
  jobPhase,
  jobSchedules,
  organizationSettings,
  showTravelTime,
}: {
  jobPhase: CustomJobPhase;
  jobSchedules: CustomJobSchedules;
  organizationSettings: Record<string, string>;
  showTravelTime: boolean;
}) {
  // if there are no jobSchedules we need to return the blocks that
  // are limited to the daily block without a start date or end date
  return {
    jobPhaseId: jobPhase.id,
    crewId: jobPhase?.crew?.id ?? "",
    userIDs: jobPhase?.users?.map((x) => x.id) ?? [],
    assignTo:
      jobPhase.crew || jobPhase.users?.[0] ? (jobPhase?.crew?.id ? "crew" : "individual") : "",
    travelTimeEstimate: jobPhase.travelTimeEstimate,
    blocks:
      jobSchedules.length > 0
        ? jobSchedules.map((jobSchedule) => ({
            scheduledAt: dayjs.utc(jobSchedule.scheduledAt).local().format("YYYY-MM-DD HH:mm:ss"),
            blockLength: jobSchedule.blockLength,
            showTravelTo: jobSchedule.showTravelTo,
            showTravelFrom: jobSchedule.showTravelFrom,
          }))
        : generateBlocksForJobPhase({
            jobPhase,
            organizationSettings,
            showTravelTo: showTravelTime,
            showTravelFrom: showTravelTime,
          }),
  };
}
