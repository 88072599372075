import {
  CustomGetTimeEntriesQuery,
  CustomGetTimeEntriesQueryVariables,
  useCustomGetTimeEntriesLazyQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetTimeEntriesLazy = () => {
  const [query, { data: graphqlData, loading, error, fetchMore: more }] =
    useCustomGetTimeEntriesLazyQuery({
      fetchPolicy: "network-only",
    });

  const getTimeEntries = useCallback(
    (variables: CustomGetTimeEntriesQueryVariables) => {
      return query({
        variables,
      });
    },
    [query]
  );

  const getData = useCallback(({ data }: { data?: CustomGetTimeEntriesQuery }) => {
    return data?.getTimeEntries?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: CustomGetTimeEntriesQuery }) => {
    return data?.getTimeEntries?.paginatorInfo || null;
  }, []);

  const fetchMore = useCallback(
    (variables: CustomGetTimeEntriesQueryVariables) => {
      return more({
        variables,
      });
    },
    [more]
  );

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData, getPaginatorInfo]);

  return [getTimeEntries, { data, loading, error, paginatorInfo, fetchMore }] as const;
};
