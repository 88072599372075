import getLocalString from "constants/Localization";
import {
  useCustomScheduleCustomEventBlockMutation,
  useCustomScheduleJobPhaseBlockMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useScheduleCustomEventBlock() {
  const [mutation, { loading, data, error }] = useCustomScheduleCustomEventBlockMutation({
    refetchQueries: ["customGetCustomEventBlocks", "customGetCustomEvent"],
  });

  const [scheduleCustomEvent, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "scheduleCustomEventBlock",
    },
    {
      successMessage: getLocalString("customEvents.schedule-custom-event-block-success"),
      failureMessage: getLocalString("customEvents.schedule-custom-event-block-failure"),
    }
  );

  return [scheduleCustomEvent, context] as const;
}
