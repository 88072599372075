import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { Employee } from "generated/graphql";
import useDeleteEmployee from "hooks/organization/employees/useDeleteEmployee";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function Delete({ closeMenu, id }: { closeMenu: () => void; id: Employee["id"] }) {
  const [deleteEmployee, { isSuccess }] = useDeleteEmployee();
  const navigate = useNavigate();
  const acceptFn = useCallback(async (): Promise<boolean> => {
    const result = await deleteEmployee({ variables: { id } });
    const success = isSuccess(result.data);
    if (success) {
      closeMenu();
    }
    return success;
  }, []);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you would like to delete this employee?"
      />
      <MenuItem onClick={() => setOpen(true)}>Delete</MenuItem>
    </>
  );
}
