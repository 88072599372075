import { CustomGetProposalQueryData } from "hooks/proposals/useCustomGetProposal";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { headerCase } from "change-case";
import {
  Company,
  CustomUpdateProposalMutationVariables,
  ProposalProduct,
  ProposalSource,
} from "generated/graphql";
import ProposalFormsContextProvider from "./providers/ProposalFormsContextProvider";

import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";

import ProposalStages from "./ProposalStages";
import { Proposal } from "generated/graphql";
import PendingChanges from "modules/PendingChanges/PendingChanges";
import ProposalContext from "./providers/ProposalContextProvider";
import proposalProductFragment from "schema/fragments/proposalProductFragment";
import ProposalSummary from "./ProposalSummary";
import SendProposal from "./SendProposal";
import MDButton from "components/MDButton";
import useCustomUpdateProposal from "hooks/proposals/useCustomUpdateProposal";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import CompanyOptions from "modules/companies/CompanyOptions";
import { Grid, Icon } from "@mui/material";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import CustomSelect, { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { updateProposalSchema, getDefaultValues } from "DDD/action-objects/ProposalUpdate";

export default function UpdateProposal({
  proposal,
  editable = false,
  setEditable,
}: {
  proposal: CustomGetProposalQueryData;
  editable: boolean;
  setEditable: (editable: boolean) => void;
}) {
  const client = useApolloClient();
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [updateProposal, { isSuccess, getData }] = useCustomUpdateProposal();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CustomUpdateProposalMutationVariables>({
    defaultValues: {
      ...getDefaultValues({
        id: proposal?.id,
        companyId: proposal.company?.id,
        contactId: proposal?.contactId,
        source: proposal?.source,
      }),
    },
    resolver: zodResolver(updateProposalSchema),
  });
  const { stages, company } = proposal;
  const companyId = watch("companyId");
  let contactOptions: Record<string | number, SelectOptions>;

  const writeProposalProduct = useCallback(
    (proposalProduct: ProposalProduct) => {
      client.writeFragment({
        id: `ProposalProduct:${proposalProduct.id}`,
        fragment: proposalProductFragment,
        data: proposalProduct,
      });
    },
    [client]
  );
  const onUpdate = useCallback(async (values: CustomUpdateProposalMutationVariables) => {
    const result = await updateProposal({ variables: values });
    const data = getData(result);
    const success = isSuccess(result.data);
    if (success) {
      setEditable(false);
    }
    return { success, data };
  }, []);

  return (
    <ProposalFormsContextProvider>
      <ProposalContext.Provider
        value={{
          proposal: proposal as Proposal,
          writeProposalProduct,
          inViewMode: false,
        }}
      >
        <Grid container spacing={2} sx={{ marginBottom: 1 }}>
          <Grid item xs={12} md={4}>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography variant="h6" sx={{ textTransform: "uppercase", fontSize: "1.25rem" }}>
                Proposal Client:&nbsp;
              </MDTypography>
              {editable ? (
                <CompanyOptions
                  triggerRefetch={triggerRefetch}
                  selectedCompany={proposal?.company as Company}
                  setTriggerRefetch={(trigger) => setTriggerRefetch(trigger)}
                >
                  {({ companyOptions, companyContactOptions, loading, search, pagination }) => {
                    contactOptions = companyContactOptions;
                    return (
                      <Controller
                        name="companyId"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <MDBox sx={{ width: "50%", marginLeft: 1 }}>
                            <ScrollAsyncAutocomplete
                              {...field}
                              value={companyOptions?.find((x) => x.value === field.value)}
                              options={companyOptions}
                              loading={loading}
                              label="Select Client"
                              search={search}
                              onLoadMore={pagination.loadMore}
                              hasMore={pagination.hasNextPage}
                              error={errors?.companyId?.message}
                            />
                          </MDBox>
                        )}
                      />
                    );
                  }}
                </CompanyOptions>
              ) : (
                <MDTypography variant="body">{company.name}</MDTypography>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography variant="h6" sx={{ textTransform: "uppercase", fontSize: "1.25rem" }}>
                Proposal Contact:&nbsp;
              </MDTypography>
              {editable ? (
                <Controller
                  name="contactId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MDBox sx={{ width: "50%", marginLeft: 1 }}>
                      <AutoComplete
                        variant="outlined"
                        options={companyId ? contactOptions[companyId] : []}
                        disabled={!companyId}
                        field={field}
                        loading={false}
                        error={error}
                        label={`Select Contact ${companyId ? "" : "(Select Client First)"}`}
                      />
                    </MDBox>
                  )}
                />
              ) : (
                <MDTypography variant="body">{`${proposal?.contact?.firstName ?? ""} ${
                  proposal?.contact?.lastName ?? ""
                }`}</MDTypography>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography variant="h6" sx={{ textTransform: "uppercase", fontSize: "1.25rem" }}>
                Proposal Source:&nbsp;
              </MDTypography>
              {editable ? (
                <MDBox sx={{ width: "50%", marginLeft: 1 }}>
                  <CustomSelect
                    control={control}
                    name="source"
                    data={enumToValueOptions(ProposalSource)}
                    label={"Proposal Source"}
                    fullWidth
                  />
                </MDBox>
              ) : (
                <MDTypography variant="body">
                  {proposal?.source && headerCase(proposal.source)}
                </MDTypography>
              )}
            </MDBox>
          </Grid>
        </Grid>
        {editable && (
          <MDButton
            variant="gradient"
            color="success"
            type="button"
            onClick={handleSubmit(onUpdate)}
            sx={{ marginBottom: 1 }}
          >
            Save
          </MDButton>
        )}
        {stages?.length > 0 && <ProposalStages />}
        <ProposalSummary />
        <SendProposal />
        <PendingChanges />
      </ProposalContext.Provider>
    </ProposalFormsContextProvider>
  );
}
