import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { Proposal, Contact, useCustomGetProposalQuery } from "generated/graphql";
import React from "react";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getFullName } from "utils/people/getFullName";

export default function ProposalCompanyContactsOptions({
  children,
  proposalId,
}: {
  children: ({
    contactsData,
    proposalCompanyContactsOptions,
    loading,
  }: {
    contactsData: Contact[];
    proposalCompanyContactsOptions: SelectOptions;
    loading: boolean;
  }) => React.ReactNode;
  proposalId: Proposal["id"];
}) {
  const { loading, data, error } = useCustomGetProposalQuery({
    variables: { id: proposalId },
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const contactsData = data?.getProposal?.company?.contacts ?? [];

  const proposalCompanyContactsOptions = contactsData.map((contact) => ({
    label: getFullName(contact),
    value: contact.id,
  }));

  return <>{children({ contactsData, loading, proposalCompanyContactsOptions })}</>;
}
