import { useDeleteTimeEntryMutation } from "generated/graphql";
import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useDeleteTimeEntry = () => {
  const [mutation, { loading, error, data }] = useDeleteTimeEntryMutation({
    refetchQueries: ["getTimeEntries"],
  });

  const [deleteTimeEntry, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteTimeEntry",
    },
    {
      successMessage: getLocalString("time-tracking.delete-time-entry-success"),
      failureMessage: getLocalString("time-tracking.delete-time-entry-failure"),
    }
  );

  return [deleteTimeEntry, context] as const;
};
