import getLocalString from "constants/Localization";
import { useAddProjectFileMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddProjectFile() {
  const [mutation, { loading, error, data }] = useAddProjectFileMutation({
    refetchQueries: ["getProjectFiles"],
  });

  const [addProjectFile, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "addProjectFile",
    },
    {
      successMessage: getLocalString("organization.add-project-file-success"),
      failureMessage: getLocalString("organization.add-project-file-failure"),
      showValidationErrors: true,
    }
  );

  return [addProjectFile, context] as const;
}
