import Tree from "modules/ProjectFiles/Tree";
import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useGetProjectFiles } from "hooks/projectFiles/useGetProjectFiles";
import ProjectFilesContextProvider from "modules/proposals/providers/ProjectFilesContextProvider";
import FileDetails from "modules/ProjectFiles/FileDetails";
import ProjectFileExplorer from "modules/ProjectFiles/ProjectFileExplorer";

export default function ProjectFiles() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ProjectFileExplorer />
      <Footer />
    </DashboardLayout>
  );
}
