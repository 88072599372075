// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// EditProduct page components
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import InviteUser from "modules/InviteUser/InviteUser";
import useInviteUser from "hooks/users/useInviteUser";
import { useCallback } from "react";
import { InviteUserInput } from "generated/graphql";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router";

export default function UsersInvitePage() {
  const navigate = useNavigate();
  const [inviteUser, { loading, error, exists, isSuccess }] = useInviteUser();

  const onSubmit = useCallback(
    async (values: InviteUserInput) => {
      const { data } = await inviteUser({ variables: { input: values } });
      return { success: isSuccess(data), data };
    },
    [inviteUser]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Invite User
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        {error && (
          <MDAlert color="error">Internal Error please try again in a few minutes.</MDAlert>
        )}
        {exists && <MDAlert color="warning">User is already invited</MDAlert>}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InviteUser onSubmit={onSubmit} loading={loading} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
