import MDButton from "components/MDButton";
import { Proposal, ProposalStatus } from "generated/graphql";
import useCancelProposal from "hooks/proposals/useCancelProposal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "../../../../assets/images/cancel-proposal.svg";

export default function CancelProposal({
  id,
  status,
}: {
  id: Proposal["id"];
  status: Proposal["status"];
}) {
  const [cancelProposal] = useCancelProposal();
  const validStatuses = ["SENT", "CLOSED_WON", "CLOSED_LOST"];
  const isDisabled = !validStatuses.includes(status);

  return (
    <Tooltip title="Cancel Proposal">
      <IconButton
        disabled={isDisabled}
        onClick={async () => {
          await cancelProposal({
            variables: {
              id,
              duplicate: false,
            },
          });
        }}
      >
        <img
          src={CancelIcon}
          alt="Move to Draft and Update"
          style={{
            filter: isDisabled ? "invert(70%) hue-rotate(120deg)" : "invert(0%) hue-rotate(120deg)",
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
