import React, { useCallback, useState } from "react";

export default function useBooleanToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setValue(!value);
  }, [value, setValue]);

  return [value, toggle] as [boolean, () => void];
}
