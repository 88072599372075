import { Step, StepLabel, Stepper } from "@mui/material";
import { capitalCase } from "change-case";
import { JobStatus } from "generated/graphql";

export default function JobStatusStepper({ status }: { status: keyof typeof JobStatus }) {
  const jobStatus = Object.keys(JobStatus).reverse();

  // swap values
  const inProgress = jobStatus[3];
  jobStatus[3] = jobStatus[2];
  jobStatus[2] = inProgress;

  const activeStep = jobStatus.indexOf(status);

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {jobStatus.map((label) => (
          <Step key={label}>
            <StepLabel>{capitalCase(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}
