const getLabelForValue = (options) => (value) => {
  const byValue = options.reduce((acc, val) => {
    return {
      ...acc,
      [val.value]: val,
    };
  }, {});

  return byValue[value]?.label;
};

export default getLabelForValue;
