import MDBox from "components/MDBox";
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { ResendVerificationEmailInput } from "generated/graphql";
import useResendVerificationEmail from "hooks/signup/useResendVerificationEmail";
import isValidEmail from "utils/validations/isValidEmail";
import { useSearchParams } from "react-router-dom";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

export default function SignupResendPage() {
  // Initial Hooks
  const [getSearchParams] = useSearchParams();
  // Default values
  const initialEmailValue =
    isValidEmail(getSearchParams.get("email") ?? "") && getSearchParams.get("email");
  // Hook Form
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResendVerificationEmailInput>({
    defaultValues: {
      email: initialEmailValue || "",
    },
  });
  // Mutation
  const [onSubmit, { loading, error, failure, success }] = useResendVerificationEmail();

  return (
    <IllustrationLayout
      title={success ? "Verification Email Resent" : "Resend Verification Email"}
      description={
        success
          ? "Please check your emails to continue"
          : "Enter your email address to resend the verification email"
      }
    >
      {failure && (
        <MDAlert color="warning">
          We couldn't send the verification email. Please make sure your email is correct.
        </MDAlert>
      )}
      {error && (
        <MDAlert color="error">
          Failed to send verification email. Please try in a couple of minutes
        </MDAlert>
      )}
      {!success && (
        <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              variant="standard"
              required
              fullWidth
              {...register("email", { required: true, validate: isValidEmail })}
              error={errors.email}
            />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
              Resend
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </IllustrationLayout>
  );
}
