import { Grid, Skeleton } from "@mui/material";
import { range } from "ramda";

export default function FormSkeleton({ rows = 4 }: { rows?: number } = {}) {
  return (
    <Grid container columnSpacing={2}>
      {range(0, rows).map((row, i) => (
        <>
          <Grid item xs={12} md={6} key={i}>
            <Skeleton />
          </Grid>
          <Grid item xs={12} md={6} key={i}>
            <Skeleton />
          </Grid>
        </>
      ))}
      <Grid item xs={2} mt={2}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
    </Grid>
  );
}
