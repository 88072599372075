// @ts-nocheck

import type { Map, Layer } from "leaflet";
import * as L from "leaflet";
import * as turf from "@turf/turf";
import get from "lodash/get";
import { difference, flattenPolyline, groupToMultiLineString, intersect } from "./turfHelper";

export default function cut(map: Map, layer: Layer, allLayers: Layer[]) {
  const all = map._layers;
  // contains information about snapping points
  const _latlngInfos = layer._latlngInfos || [];

  const newLayers = [];

  // find all layers that intersect with `layer`, the just drawn cutting layer
  const layers = allLayers
    // only layers with intersections
    .filter((l) => {
      try {
        const lineInter =
          !!turf.lineIntersect(layer.toGeoJSON(15), l.toGeoJSON(15)).features.length > 0;

        if (lineInter || (l instanceof L.Polyline && !(l instanceof L.Polygon))) {
          return lineInter;
        }
        return !!intersect(layer.toGeoJSON(15), l.toGeoJSON(15));
      } catch (e) {
        if (l instanceof L.Polygon) {
          /* eslint-disable-next-line no-console */
          console.error("You can't cut polygons with self-intersections");
        }
        return false;
      }
    });

  // loop through all layers that intersect with the drawn (cutting) layer
  layers.forEach((l) => {
    let newLayer;
    if (l instanceof L.Polygon) {
      // Also for L.Rectangle
      // easiest way to clone the complete latlngs without reference
      newLayer = L.polygon(l.getLatLngs());
      const coords = newLayer.getLatLngs();

      // snapping points added to the layer, so borders are cutted correct
      _latlngInfos.forEach((info) => {
        if (info && info.snapInfo) {
          const { latlng } = info;
          // get closest layer ( == input layer) with the closest segment to the intersection point
          const closest = this._calcClosestLayer(latlng, [newLayer]);
          if (closest && closest.segment && closest.distance < this.options.snapDistance) {
            const { segment } = closest;
            if (segment && segment.length === 2) {
              const { indexPath, parentPath, newIndex } = L.PM.Utils._getIndexFromSegment(
                coords,
                segment
              );
              // define the coordsRing that is edited
              const coordsRing = indexPath.length > 1 ? get(coords, parentPath) : coords;
              coordsRing.splice(newIndex, 0, latlng);
            }
          }
        }
      });
    } else {
      // L.Polyline
      newLayer = l;
    }

    // find layer difference
    const diff = cutLayer(layer, newLayer);

    // the resulting layer after the cut
    let resultLayer = L.geoJSON(diff, l.options);
    if (resultLayer.getLayers().length === 1) {
      [resultLayer] = resultLayer.getLayers(); // prevent that a unnecessary layergroup is created
    }

    // this._setPane(resultLayer, 'layerPane');
    const resultingLayer = resultLayer.addTo(map.pm._getContainingLayer());
    // give the new layer the original options
    resultingLayer.feature = l.feature;
    resultingLayer.pm.enable(l.pm.options);
    resultingLayer.pm.disable();

    // add templayer prop so pm:remove isn't fired
    l._pmTempLayer = true;
    layer._pmTempLayer = true;

    // remove old layer and cutting layer
    l.remove();
    l.removeFrom(map.pm._getContainingLayer());
    layer.remove();
    layer.removeFrom(map.pm._getContainingLayer());

    // fire pm:cut on the cutted layer
    map.pm._fireCut(l, resultingLayer, l);

    // fire pm:cut on the map
    map.pm._fireCut(map, resultingLayer, l);

    // fire edit event after cut
    l.pm._fireEdit();

    newLayers.push(resultingLayer);
  });

  return newLayers;
}

const cutLayer = (layer: Layer, l: Layer) => {
  const fg = L.geoJSON();
  let diff;
  // cut
  if (l instanceof L.Polygon) {
    // find layer difference
    diff = difference(l.toGeoJSON(15), layer.toGeoJSON(15));
  } else {
    const features = flattenPolyline(l);

    features.forEach((feature) => {
      // get splitted line to look which line part is coverd by the cut polygon
      const lineDiff = turf.lineSplit(feature, layer.toGeoJSON(15));

      let group;
      if (lineDiff && lineDiff.features.length > 0) {
        group = L.geoJSON(lineDiff);
      } else {
        group = L.geoJSON(feature);
      }

      group.getLayers().forEach((lay) => {
        // add only parts to the map, they are not covered by the cut polygon
        if (!turf.booleanContains(layer.toGeoJSON(15), lay.toGeoJSON(15))) {
          lay.addTo(fg);
        }
      });
    });

    if (features.length > 1) {
      diff = groupToMultiLineString(fg);
    } else {
      diff = fg.toGeoJSON(15);
    }
  }
  return diff;
};
