// @ts-nocheck

import { getAddressObject } from "helpers/map";
import { MapAddress, MapMeasurementTotals } from "interfaces/interfaces";
import * as L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { MapCoords } from "./Map";
import { ColoredDivIcon } from "./Geoman";

interface Props {
  map: L.Map;
  currentDrawShape: L.PM.SUPPORTED_SHAPES | null;
  featureGroup: (featureGroup: L.FeatureGroup | null) => void;
  color: string;
  isEditing: boolean;
  onSave: (addressOverride?: string) => void;
  toggleDrawMode: (
    map: L.Map,
    drawShape: L.PM.SUPPORTED_SHAPES,
    layerColor: string,
    isEditing: boolean,
    currentDrawShape: string | null
  ) => void;
  selectPlace: (place: any, coords?: MapCoords) => void;
  clearMap: () => void;
  selectedAddress: MapAddress | null;
  total: MapMeasurementTotals;
  close?: () => void;
}

export default function GuidedControls({
  map,
  featureGroup,
  currentDrawShape,
  color,
  isEditing,
  onSave,
  toggleDrawMode,
  selectPlace,
  clearMap,
  selectedAddress,
  total,
  close,
}: Props) {
  const [step, setStep] = useState<number>(1);
  const [showOverwriteAddress, setShowOverwriteAddress] = useState(false);
  const [error, setError] = useState("");

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const options = {
    componentRestrictions: { country: ["USA", "CA"] },
    strictBounds: false,

    fields: ["address_components", "geometry", "name"],
  };
  useEffect(() => {
    if (!map) {
      return;
    }

    autoCompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, options);

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      inputRef.current.value = "";
      const location = place.geometry.location;
      const latLon = new L.LatLng(location.lat(), location.lng());
      const bounds = latLon.toBounds(100); // 500 = metres
      const addressParts = getAddressObject(place.address_components);
      const inputVal = `${addressParts.home} ${addressParts.street}`;
      map.panTo(latLon).fitBounds(bounds);
      const marker = L.marker(latLon, {
        draggable: true,
        title: inputVal,
        icon: new ColoredDivIcon({
          color,
        }),
      });
      marker.on("dragend", async (e) => {
        const geocoder = new google.maps.Geocoder();
        const latlng = marker.getLatLng();
        const response = await geocoder.geocode({
          location: {
            lat: latlng.lat,
            lng: latlng.lng,
          },
        });
        if (response.results.length) {
          selectPlace(response.results[0]);
        }
      });
      const fg = L.featureGroup().addLayer(marker);
      featureGroup(fg);
      fg.addTo(map);

      inputRef.current.value = inputVal;
      await continueToStep2();
    });
  }, [map]);
  const onEnterCoords = (e: KeyboardEvent) => {
    if (e.key === "enter") {
      const location = place.geometry.location;
      const latLon = new L.LatLng(location.lat(), location.lng());
      const bounds = latLon.toBounds(100); // 500 = metres
      map.panTo(latLon).fitBounds(bounds);
    }
  };
  const continueToStep2 = async () => {
    setError("");
    if (inputRef.current && inputRef?.current?.value) {
      const place = await autoCompleteRef.current.getPlace();
      const location = place.geometry.location;
      const coords = new L.LatLng(location.lat(), location.lng());
      selectPlace(place, coords);
    } else {
      selectPlace(null);
    }
  };
  useEffect(() => {
    if (selectedAddress) {
      setStep(2);
      return;
    }

    setStep(1);
  }, [selectedAddress]);

  return (
    <>
      <div className={"map-controls-container"}>
        <div
          style={{
            fontSize: "14px",
            color: "red",
          }}
        >
          {error && <span color="red">{error}</span>}
        </div>
        {step === 1 && (
          <div
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: "10px",
            }}
          >
            1. Enter the job address:
          </div>
        )}
        <div
          style={{
            display: step === 1 ? "block" : "none",
          }}
        >
          <div className="guided-controls-form-container">
            <div className="form-input-block">
              <label
                style={{
                  fontSize: "12px",
                  fontFamily: "Arial",
                }}
                htmlFor="street"
              >
                Street:
              </label>
              <input
                id="street"
                placeholder=""
                ref={inputRef}
                style={{ width: "100%" }}
                autoComplete="on"
              />
            </div>

            {/* <button onClick={continueToStep2} className="continue-button">
              Continue
            </button> */}
          </div>
        </div>
        {step === 2 && selectedAddress && (
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Arial",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "10px",
              }}
            >
              Confirm Job Location
            </div>
            <div className="address-stack">
              <p className="label">Address: </p>
              <p className="value">{selectedAddress.line1}</p>
            </div>
            <div className="address-stack">
              <p className="label">City: </p>
              <p className="value">{selectedAddress.city}</p>
            </div>
            <div className="address-stack">
              <p className="label">State: </p>
              <p className="value">{selectedAddress.state}</p>
            </div>
            <div className="address-stack">
              <p className="label">Postal Code: </p>
              <p className="value">{selectedAddress.zip}</p>
            </div>
            <div className="address-stack">
              <p className="label">Country: </p>
              <p className="value">{selectedAddress.country}</p>
            </div>

            <button
              onClick={() => {
                selectPlace(null);
                clearMap();
              }}
              style={{
                background: "transparent",
                paddingBlock: "10px",
                border: "1px solid black",
                fontSize: "16px",
                textTransform: "uppercase",
                borderRadius: "6px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Change Address
            </button>
            <button
              onClick={() => setShowOverwriteAddress(!showOverwriteAddress)}
              style={{
                padding: "1rem",
                background: "transparent",
                border: "1px solid black",
                fontSize: "16px",
                textTransform: "uppercase",
                borderRadius: "6px",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Overwrite Proposal Address
            </button>
            {showOverwriteAddress && (
              <div className="guided-controls-form-container">
                <div className="form-input-block">
                  <input
                    type="text"
                    id="address-override"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            )}

            <button
              onClick={() => onSave(document.querySelector("#address-override")?.value)}
              style={{
                background: "#0094c6",
                padding: "10px",
                border: "1px solid #0094c6",
                fontSize: "16px",
                textTransform: "uppercase",
                borderRadius: "6px",
                marginTop: "15px",
                width: "100%",
                color: "white",
              }}
            >
              Confirm Address
            </button>
          </div>
        )}

        {close && (
          <button
            style={{
              background: "#0094c6",
              padding: "10px",
              border: "1px solid #0094c6",
              fontSize: "16px",
              textTransform: "uppercase",
              borderRadius: "6px",
              marginTop: "15px",
              width: "100%",
              color: "white",
            }}
            onClick={close}
          >
            Close
          </button>
        )}
      </div>
    </>
  );
}
