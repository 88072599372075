import { Skeleton } from "@mui/material";
import { range } from "ramda";

export default function OpportunityPageFormLoader() {
  return (
    <div>
      {range(0, 10).map((i) => (
        <Skeleton key={i} variant="rectangular" width="100%" />
      ))}
    </div>
  );
}
