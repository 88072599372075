import {
  GetOrganizationProductTypeQuery,
  useGetOrganizationProductTypeQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";

export default function useGetOrganizationProductType({ id }: { id: string }) {
  const {
    data: graphqlData,
    loading,
    error,
  } = useGetOrganizationProductTypeQuery({ variables: { id } });

  const getData = useCallback(({ data }: { data: GetOrganizationProductTypeQuery }) => {
    return data?.getOrganizationProductType ?? null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [getData, graphqlData]);

  return { data, loading, error } as const;
}
