import MDBox from "components/MDBox";
import React from "react";

export default function Timeline({ children }: { children: React.ReactNode }) {
  return (
    <MDBox
      sx={{
        ".timeline": {
          position: "relative",
          maxWidth: "1200px",
          margin: "0 auto",
          fontSize: "14px",
        },
        ".timeline::after": {
          content: '""',
          position: "absolute",
          width: "6px",
          backgroundColor: "#000",
          top: 0,
          bottom: 0,
          left: "50%",
          marginLeft: "-3px",
        },
        ".container": {
          padding: "10px 40px",
          position: "relative",
          backgroundColor: "white",
          width: "50%",
        },
        ".container::after": {
          content: '""',
          position: "absolute",
          width: "25px",
          height: "25px",
          right: "-13px",
          backgroundColor: "#fff",
          border: "4px solid #000",
          top: "15px",
          borderRadius: "50%",
          zIndex: 1,
        },
        ".left": {
          left: 0,
        },
        ".right": {
          left: "50%",
        },
        ".left::before": {
          content: '" "',
          height: "0",
          position: "absolute",
          top: "22px",
          width: "0",
          zIndex: "1",
          right: "30px",
          border: "medium solid white",
          borderWidth: "10px 0 10px 10px",
          borderColor: "transparent transparent transparent black",
        },
        ".right::before": {
          content: '" "',
          height: "0",
          position: " absolute",
          top: "22px",
          width: "0",
          zIndex: "1",
          left: "30px",
          border: "medium solid white",
          borderWidth: "10px 10px 10px 0",
          borderColor: "transparent black transparent transparent",
        },
        ".right::after": {
          left: "-13px",
        },
        ".content": {
          padding: "20px 30px",
          backgroundColor: "white",
          position: "relative",
          borderRadius: "6px",
          border: "1px solid black",
        },
        ".left .content": {
          textAlign: "right",
        },
        ".right .content": {
          textAlign: "left",
        },
        "@media screen and (max-width: 600px)": {
          ".timeline::after": {
            left: "31px",
          },
          ".container": {
            width: "100%",
            paddingLeft: "70px",
            paddingRight: "25px",
          },
          ".container::before": {
            left: "60px",
            border: "medium solid white",
            borderWidth: "10px 10px 10px 0",
            borderColor: "transparent #000 transparent transparent",
          },
          ".left::after, .right::after": {
            left: "19px",
          },
          ".right": {
            left: "0%",
          },
        },
      }}
    >
      <MDBox className="timeline">{children}</MDBox>
    </MDBox>
  );
}
