import {
  Autocomplete,
  Box,
  CircularProgress,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { equals, F } from "ramda";
import MDTypography from "components/MDTypography";

export default forwardRef(function ScrollAsyncAutocomplete(
  {
    search,
    loading,
    options,
    onLoadMore,
    hasMore,
    label,
    value,
    onChange,
    disabled,
    variant,
    error,
  }: {
    search: (value: string) => void;
    loading: boolean;
    options: SelectOptions;
    onLoadMore: () => void;
    hasMore: boolean;
    label?: string;
    value: SelectOptions[number];
    onChange: (value: SelectOptions[number]["value"]) => void;
    disabled?: boolean;
    variant?: StandardTextFieldProps["variant"];
    error?: string;
  },
  ref: any
) {
  const [displayedOptions, setDisplayedOptions] = useState(options);
  useEffect(() => {
    search("");
  }, []);
  useEffect(() => {
    if (options && !loading) {
      setDisplayedOptions(options);
    }
  }, [options, loading]);

  const observer = useRef<IntersectionObserver>();
  const lastOption = options?.slice(-1)?.pop();
  const isLastOption = lastOption ? equals(lastOption) : F;

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          onLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  return (
    <>
      <Autocomplete
        key={value?.value ?? label ?? "autocomplete"} // adding key because there seems to be a bug with autocomplete that makes it not refresh when value changes
        filterOptions={(x) => x}
        options={displayedOptions}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        defaultValue={value}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            return false;
          }
          return true;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => search(e.target.value)}
            label={label ?? "Search"}
            variant={variant ?? "outlined"}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <>
            <Box
              key={option.value}
              component="li"
              {...(isLastOption(option) ? { ref: lastOptionElementRef } : {})}
              {...props}
            >
              {option.label}
            </Box>
            {loading && isLastOption(option) && (
              <Box component="li" sx={{ pointerEvents: "none" }}>
                <CircularProgress color="inherit" size={16} />
              </Box>
            )}
          </>
        )}
        onChange={(_, selected) =>
          typeof selected === "string" ? onChange(selected) : onChange(selected.value)
        }
      />
      {error && (
        <MDTypography color="error" variant="caption">
          {error}
        </MDTypography>
      )}
    </>
  );
});
