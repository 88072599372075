import MDAlert from "components/MDAlert";
import { AcceptUserInviteInput, UserRole } from "generated/graphql";
import useAcceptUserInvite from "hooks/signup/useAcceptUserInvite";
import AcceptInviteForm from "modules/AcceptInviteForm/AcceptInviteForm";
import { useBitumioContext } from "providers/BitumioContext";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

type AcceptInviteProps = {
  email: AcceptUserInviteInput["email"];
  token: AcceptUserInviteInput["inviteToken"];
};

export default function AcceptInvite(props: AcceptInviteProps) {
  const { email, token: inviteToken } = props;
  const [acceptUserInvite, { loading, error, success, user }] = useAcceptUserInvite();
  const navigate = useNavigate();
  const [{ setSignedInUser }] = useBitumioContext();

  useEffect(() => {
    if (success && user) {
      if (user.roles.some((x) => x === UserRole.EMPLOYEE || x === UserRole.SUBCONTRACTOR)) {
        navigate(getRoute("download-app"));
      } else {
        setSignedInUser(user);
        navigate(getRoute("dashboard"));
      }
    }
  }, [success, user]);

  return (
    <>
      {error && (
        <MDAlert color="error">There has been an error, please retry in a few minutes</MDAlert>
      )}
      <AcceptInviteForm
        onSubmit={acceptUserInvite}
        loading={loading}
        email={email}
        inviteToken={inviteToken}
      />
    </>
  );
}
