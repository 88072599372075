import React, { useCallback, useEffect, useState } from "react";
import { useBitumioContext } from "providers/BitumioContext";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import getLocalString from "constants/Localization";
import { CustomPaginatorInfo } from "generated/graphql";

export default function useQueueList(perPage, page) {
  const defaultPaginator = {
    count: 0,
    currentPage: 1,
    firstItem: 1,
    hasMorePages: false,
    lastItem: 0,
    lastPage: 0,
    perPage: perPage,
    total: 0,
  };

  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();
  const [queueListData, setQueueListData] = useState({ data: [] });
  const [paginatorInfo, setPaginatorInfo] = useState<CustomPaginatorInfo>(defaultPaginator);

  const getQueueList = useCallback(async (perPage, page) => {
    try {
      showLoader();
      const response = await fetch(
        import.meta.env.VITE_BACKEND_API_BASE_URL +
          "/api/quickbooks?per_page=" +
          perPage +
          "&page=" +
          page,
        {
          credentials: "include",
        }
      );

      const dataResponse = await response.json();

      if (dataResponse?.data) {
        setQueueListData(dataResponse.data);
      } else {
        setQueueListData(dataResponse);
      }
      if (dataResponse?.current_page) {
        getPaginatorInfo(dataResponse);
      }
    } catch (error) {
      show({
        message: getLocalString("generic.failure"),
        options: { variant: "error" },
      });
    } finally {
      hideLoader();
    }
  }, []);

  useEffect(() => {
    getQueueList(perPage, page);
  }, []);

  const getPaginatorInfo = useCallback((paginatorData) => {
    const defaultPaginator = {
      count: 0,
      currentPage: page,
      firstItem: 1,
      hasMorePages: false,
      lastItem: 0,
      lastPage: 0,
      perPage: perPage,
      total: 0,
    };
    defaultPaginator.count = paginatorData.data.length;
    defaultPaginator.currentPage = paginatorData.current_page;
    defaultPaginator.hasMorePages = paginatorData.current_page < paginatorData.last_page;
    defaultPaginator.firstItem = paginatorData.from;
    defaultPaginator.lastItem = paginatorData.to;
    defaultPaginator.lastPage = paginatorData.last_page;
    defaultPaginator.perPage = paginatorData.per_page;
    defaultPaginator.total = paginatorData.total;

    setPaginatorInfo(defaultPaginator);
  }, []);

  const refreshQueueList = useCallback(
    (perPage, page) => {
      getQueueList(perPage, page);
    },
    [getQueueList]
  );

  return [queueListData, paginatorInfo, getQueueList, refreshQueueList];
}
