// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DefaultPricingCard from "components/DefaultPricingCard/DefaultPricingCard";

import useSubscriptionUrl from "hooks/subscriptions/useSubscriptionUrl";
import MDAlert from "components/MDAlert";
import getLocalString from "constants/Localization";
import { useCallback, useState } from "react";

function PricingCards({
  priceTerm = { value: "149", period: "user/month" },
  benefits = [{ label: "Free Trial: Unlimited users for 14 days.", includes: true }],
}: {
  priceTerm?: { value: string; period: string };
  benefits?: { label: string; includes?: boolean }[];
}): JSX.Element {
  const { loading, error, label, figureOutUrl } = useSubscriptionUrl();
  const [url, setUrl] = useState("");

  const onClick = useCallback(
    async (e: any) => {
      const result = await figureOutUrl();
      if (result) {
        setUrl(result);
        window.open(result, "_blank");
      }
    },
    [figureOutUrl]
  );
  return (
    <MDBox position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={4}>
          {error && <MDAlert color="error">{getLocalString("subscription.url-failed")}</MDAlert>}
          <DefaultPricingCard
            color="dark"
            badge={{ color: "info", label: "Tiers Offered" }}
            price={{ currency: "$", value: priceTerm.value, type: priceTerm.period }}
            specifications={benefits}
            action={{
              type: "external",
              onClick,
              route: url,
              color: "info",
              label: label,
              disabled: loading || !!error,
            }}
            loading={loading}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default PricingCards;
