import { Card, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { JobPhase, JobPhaseCostItem } from "generated/graphql";
import Title from "./Title";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import PlantOptions from "modules/plants/PlantOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import CreatePlantModal from "modules/plants/CreatePlantModal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { isNilOrEmpty } from "ramda-adjunct";
import AddTransferTrucks from "modules/JobPhases/transferTrucks/AddTransferTrucks";
import { UpdateWorkOrderInputSchema } from "DDD/action-objects/WorkOrderItemsUpdate";
import TransferTruckOptions from "modules/transferTrucks/TransferTruckOptions";
import { Delete } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField/FormField";

const TruckingSection = ({ data, costItem }: { data: JobPhase; costItem?: JobPhaseCostItem }) => {
  const { proposalProduct, jobPhasePlant } = data;
  const {
    control,
    watch,
    getValues,
    register,
    formState: { errors },
  } = useFormContext<UpdateWorkOrderInputSchema>();
  const plantId = watch("plantId");
  const plantProductId = watch("plantProductId");
  const {
    append: truckAppend,
    remove: truckRemove,
    fields: truckFields,
  } = useFieldArray({
    control: control,
    name: "transferTrucks",
  });
  const plant = jobPhasePlant ?? proposalProduct?.plantProduct;
  const parseAddress = () => {
    const plant = jobPhasePlant?.plant ?? proposalProduct?.plantProduct?.plant;
    return [
      plant?.addressLine1,
      plant?.addressLine2,
      plant?.addressCity,
      plant?.addressState,
      plant?.addressZip,
    ]
      .filter((x) => !!x)
      .join(", ");
  };
  return (
    <Grid item xs={12} className="no-break">
      <Card sx={{ mb: 2 }}>
        <Title text="Trucking" />
        {plant && (
          <Table>
            <TableBody>
              <TableRow>
                <PlantOptions>
                  {({ plantOptions, loading, search, plantProductOptions }) =>
                    loading ? (
                      <div>Loading ...</div>
                    ) : (
                      <>
                        <TableCell
                          sx={{
                            display: "none",
                            "@media print": {
                              display: "table-cell",
                            },
                          }}
                        >
                          <MDBox display="flex" gap={2} justifyContent="space-between">
                            <MDBox display="flex" alignItems="center" gap={2} color="#000">
                              <MDTypography variant="p" fontWeight="bold" color="inherit">
                                Plant:{" "}
                              </MDTypography>
                              {plantOptions?.find((x) => x.value === plantId)?.label}
                            </MDBox>
                          </MDBox>
                        </TableCell>
                        <TableCell
                          sx={{
                            display: "none",
                            "@media print": {
                              display: "table-cell",
                            },
                          }}
                        >
                          <MDBox display="flex" alignItems="center" gap={2} color="#000">
                            <MDTypography variant="p" fontWeight="bold" color="inherit">
                              Material:{" "}
                            </MDTypography>
                            {plantProductOptions[plantId]?.find((x) => x.value === plantProductId)
                              ?.label ?? ""}
                          </MDBox>
                        </TableCell>
                        <TableCell className="no-print" width="45%">
                          <Controller
                            name="plantId"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <AutoComplete
                                  options={plantOptions}
                                  field={field}
                                  loading={loading}
                                  error={(error?.message ?? "") as string}
                                  label="Plant"
                                  search={search}
                                />
                              </>
                            )}
                          />
                        </TableCell>

                        <TableCell className="no-print" width="55%">
                          {" "}
                          <Controller
                            name="plantProductId"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <AutoComplete
                                  disabled={isNilOrEmpty(plantId)}
                                  options={plantProductOptions[plantId] ?? []}
                                  field={field}
                                  loading={loading}
                                  error={(error?.message ?? "") as string}
                                  label="Material"
                                  search={search}
                                />
                              </>
                            )}
                          />
                        </TableCell>
                        {plantOptions.length === 0 && (
                          <TableCell>
                            <CreatePlantModal />
                          </TableCell>
                        )}
                      </>
                    )
                  }
                </PlantOptions>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Address</TableCell>
                <TableCell colSpan={4}>{parseAddress()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <Card
          sx={{
            padding: 2,
            "@media print": {
              padding: 0,
            },
          }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Truck</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Trucks Needed</TableCell>
              </TableRow>

              {truckFields?.map((transferTruck, i) => (
                <TransferTruckOptions>
                  {({ transferTrucksById, transferTruckOptions, loading, search }) => (
                    <TableRow key={i}>
                      <TableCell
                        sx={{
                          display: "none",
                          "@media print": {
                            display: "table-cell",
                            width: "38%",
                          },
                        }}
                      >
                        {transferTruckOptions?.find(
                          (x) => x.value === getValues(`transferTrucks.${i}.id`)
                        )?.label ?? ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "none",
                          "@media print": {
                            display: "table-cell",
                            width: "33%",
                          },
                        }}
                      >
                        {transferTruck?.quantity}
                      </TableCell>
                      <TableCell className="no-print" width="45%">
                        <Controller
                          control={control}
                          name={`transferTrucks.${i}.id`}
                          render={({ field }) => (
                            <AutoComplete
                              options={transferTruckOptions}
                              field={{
                                ...field,
                                onChange: async (e) => {
                                  field.onChange(e);
                                },
                              }}
                              loading={loading}
                              error={null}
                              search={search}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell className="no-print" width="50%">
                        <FormField
                          defaultValue={transferTruck.quantity}
                          error={errors?.transferTrucks?.[i]?.quantity}
                          {...register(`transferTrucks.${i}.quantity`, { valueAsNumber: true })}
                        />
                      </TableCell>

                      <TableCell width="33%">
                        <MDBox display="flex" gap="2" justifyContent="flex-start">
                          <MDButton
                            variant="text"
                            color="error"
                            className="no-print"
                            onClick={() => truckRemove(i)}
                          >
                            <Delete />
                          </MDButton>
                        </MDBox>
                      </TableCell>
                    </TableRow>
                  )}
                </TransferTruckOptions>
              ))}
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={6} className="no-print">
                  <MDBox display="flex" justifyContent="space-between">
                    <AddTransferTrucks
                      onAdd={(truck) => {
                        truckAppend({
                          id: truck?.id,
                          quantity: 0,
                        });
                      }}
                    />
                  </MDBox>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Card>
    </Grid>
  );
};

export default TruckingSection;
