import { useGetProjectFile } from "hooks/projectFiles/useGetProjectFile";
import useModalState from "hooks/useModalState";
import { useEffect, useState } from "react";
import ProjectFileExplorer from "./ProjectFileExplorer";
import MDBox from "components/MDBox";
import { Icon, IconButton, InputLabel, Tooltip } from "@mui/material";
import Modal from "modules/Modal/Modal";
import MDTypography from "components/MDTypography";

export default function ProjectFileExplorerInput({
  submitFn,
  folderId,
  showLabel = false,
  sx,
}: {
  submitFn: (folderId: string) => void;
  folderId: string;
  showLabel?: boolean;
  sx?: React.CSSProperties;
}) {
  const [getProjectFile, { graphqlData: fileById }] = useGetProjectFile();

  useEffect(() => {
    if (folderId) {
      getProjectFile({ id: folderId });
    }
  }, [folderId]);

  const {
    open: projectFilesModalOpen,
    onClose: onCloseProjectFilesModal,
    onOpen: onOpenProjectFilesModal,
  } = useModalState();

  const [projectFileExplorerOptions, setProjectFileExplorerOptions] = useState({
    folderView: false,
    fileView: false,
    folderId: "",
  });

  const styles: React.CSSProperties = {
    borderBottom: "1px solid #d2d6da",
    width: "100%",
  };

  return (
    <>
      <MDBox fontSize={14} sx={sx || styles} py="3px">
        {showLabel && <InputLabel>Project Files</InputLabel>}
        <MDBox display="flex" justifyContent="space-between" alignItems="self-end">
          {fileById && fileById.name}
          <MDBox textAlign="right" width={fileById?.name ? "auto" : "100%"}>
            <Tooltip title="Edit Project Files Location">
              <IconButton
                onClick={() => {
                  setProjectFileExplorerOptions({
                    folderView: true,
                    fileView: false,
                    folderId: "",
                  });
                  onOpenProjectFilesModal();
                }}
                sx={{ paddingBottom: 0 }}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="View Files">
              <IconButton
                onClick={() => {
                  setProjectFileExplorerOptions({
                    folderView: false,
                    fileView: true,
                    folderId,
                  });
                  onOpenProjectFilesModal();
                }}
                disabled={!folderId}
                sx={{ paddingBottom: 0 }}
              >
                <Icon>preview</Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
        </MDBox>
      </MDBox>
      <Modal
        open={projectFilesModalOpen}
        onClose={onCloseProjectFilesModal}
        styles={{ overflow: "auto", height: "auto" }}
      >
        <MDTypography variant="h4" p={3} pb={0}>
          {projectFileExplorerOptions.fileView ? "View Files" : " Select Project Files Folder"}
        </MDTypography>
        <ProjectFileExplorer
          folderView={projectFileExplorerOptions.folderView}
          fileView={projectFileExplorerOptions.fileView}
          folderId={projectFileExplorerOptions.folderId}
          submitFn={(lastSelectedItem) => {
            submitFn(lastSelectedItem);
            onCloseProjectFilesModal();
          }}
        />
      </Modal>
    </>
  );
}
