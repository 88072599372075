import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Title from "./Title";
import getImageAssetURL from "utils/images/getImageAssetURL";
import { ProposalFile, JobPhaseFile } from "generated/graphql";
import JobPhaseFiles from "modules/JobPhases/JobPhaseFiles";
import AddFiles from "modules/JobPhases/AddFiles";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Edit } from "@mui/icons-material";
import { useState } from "react";
import isImage from "utils/images/isImage";
import Lightbox from "yet-another-react-lightbox";

export default function JobImages({ jobImages }: { jobImages: JobPhaseFile[] }) {
  const [editMode, setEditMode] = useState(false);
  const [index, setIndex] = useState(-1);
  const slides = jobImages
    .map((jobPhaseFile) => {
      if (isImage(jobPhaseFile.filename)) {
        return {
          src: `${import.meta.env.VITE_CDN_BASE_URL}/${jobPhaseFile.filename}`,
        };
      }

      return null;
    })
    .filter(Boolean);
  return (
    <MDBox mb={2} className="no-break">
      <Card>
        <MDBox bgColor="#f5f5f5" py={2} px={4} display="flex" justifyContent="space-between">
          <MDTypography variant="h4">Job Images</MDTypography>
          <MDButton
            type="button"
            variant="text"
            color="info"
            sx={{ maxHeight: "25px" }}
            className="no-print"
            onClick={() => setEditMode(!editMode)}
          >
            <Edit />
          </MDButton>
        </MDBox>
        <MDBox p={4}>
          <Grid container spacing={3} alignItems="center">
            {editMode && (
              <Grid item xs={12}>
                <JobPhaseFiles />
                <MDBox
                  sx={{
                    width: "23vw",
                    position: "relative",
                    "& .uppy-DragDrop-inner": { padding: "16px 0" },
                    "& .uppy-DragDrop-label": { fontSize: "1em" },
                  }}
                >
                  <AddFiles />
                </MDBox>
              </Grid>
            )}
            {!editMode && (
              <>
                <Lightbox
                  open={index >= 0}
                  index={index}
                  close={() => setIndex(-1)}
                  slides={slides}
                />
                {jobImages?.map((image, index) => (
                  <Grid item xs={12} lg={6} key={image.id}>
                    <MDBox
                      sx={{
                        cursor: "pointer",
                      }}
                      component="img"
                      src={getImageAssetURL(image.filename)}
                      maxWidth="100%"
                      onClick={() => setIndex(index)}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}
