import { Autocomplete } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { CountryCode } from "constants/CountriesList";
import useAddress from "hooks/address/useAddress";
import { useEffect } from "react";
import {
  Control,
  Controller,
  Path,
  PathValue,
  UseFormSetValue,
  useFormState,
  useWatch,
} from "react-hook-form";

interface StateSelectProps<TFormValues> {
  name: Path<TFormValues>;
  label: string;
  control: Control<TFormValues, any>;
  setValue: UseFormSetValue<TFormValues>;
  required?: boolean;
  countryFieldName: Path<TFormValues>;
}

const StateSelect = <TFormValues extends Record<string, unknown>>({
  name,
  label,
  control,
  required,
  countryFieldName,
  setValue,
}: StateSelectProps<TFormValues>) => {
  const countryCode = useWatch({ control, name: countryFieldName });
  const { errors } = useFormState({ control });
  const { dirtyFields } = useFormState({ control });

  const {
    provinces: { selectOptions: options },
  } = useAddress((countryCode as CountryCode) || null);

  useEffect(() => {
    const isDirty = dirtyFields[countryFieldName];
    if (isDirty && countryCode) {
      setValue(name, "" as PathValue<TFormValues, Path<TFormValues>>);
    }
  }, [dirtyFields, countryCode]);

  const error = errors[name];

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field, field: { onChange, value, ...rest } }) =>
          options && options.length > 0 ? (
            <Autocomplete
              {...rest}
              value={value as { label: string; value: string }}
              options={options}
              onChange={(e, value: (typeof options)[number]) => {
                onChange(value?.value || null);
              }}
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? options.find((o) => o.value === option)?.label || ""
                  : option.label
              }
              isOptionEqualToValue={(option, value) =>
                option.label === value.label || option.value === value
              }
              renderInput={(params) => (
                <>
                  <MDInput
                    {...params}
                    label={label}
                    required={required}
                    variant="standard"
                    error={error}
                  />
                  {error && (
                    <MDTypography color="error" variant="caption">
                      {error.message}
                    </MDTypography>
                  )}
                </>
              )}
            />
          ) : (
            <FormField
              type="text"
              label={label}
              name={name}
              required={required}
              error={error}
              {...field}
            />
          )
        }
      />
    </div>
  );
};

export default StateSelect;
