import { UserPermissions } from "generated/graphql";
import { checkPermissions } from "helpers/functions";
import { useBitumioContext } from "providers/BitumioContext";
import { useCallback, useMemo } from "react";

/**
 * Hook to check permissions
 * @param permissions
 * @returns
 */
export const useCheckPermissions = () => {
  const [{ signedInUser }] = useBitumioContext();

  const hasPermissions = useCallback(
    (permissions: UserPermissions | UserPermissions[]) => {
      return checkPermissions(signedInUser, permissions);
    },
    [signedInUser]
  );

  return { hasPermissions } as const;
};
