import GlobalLoader from "components/GlobalLoader/GlobalLoader";
import { UserPermissions, UserRole } from "generated/graphql";
import { useBitumioContext } from "providers/BitumioContext";
import { useCallback, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function AuthWallAdmin({ children }: { children: JSX.Element }) {
  const [{ signedInUser, loading }] = useBitumioContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!signedInUser.roles.some((x) => x === UserRole.ADMIN)) {
      navigate(`/not-authorized?role=${UserRole.ADMIN}`);
    }
  }, [signedInUser, navigate]);

  if (loading) {
    return <GlobalLoader />;
  }
  return <>{children}</>;
}

export function useRedirectAfterAuthentication() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleRedirectAfterSignin = useCallback(() => {
    const redirectTo = searchParams.get("redirectTo");
    if (redirectTo) {
      navigate(redirectTo);
    } else {
      navigate(getRoute("dashboard"));
    }
  }, [searchParams]);

  return { handleRedirectAfterSignin } as const;
}
