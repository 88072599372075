import Modal from "modules/Modal/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";

interface ViewLocationModalProps {
  open: boolean;
  onClose: () => void;
  latitude?: number | null;
  longitude?: number | null;
  id?: string;
}

export default function ViewLocationModal({
  open,
  onClose,
  latitude,
  longitude,
  id,
}: ViewLocationModalProps) {
  const initMap = async () => {
    const mapElement = document.getElementById(`time-entry-map-${id}`);

    if (!mapElement) return;

    const location = new google.maps.LatLng(latitude, longitude);

    const map = new google.maps.Map(mapElement, {
      center: location,
      zoom: 15,
    });

    new google.maps.Marker({
      position: location,
      map: map,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      initMap();
    }, 100);
  }, [open, latitude, longitude, id]);

  return (
    <Modal open={open} onClose={onClose}>
      <MDBox p={3}>
        <MDTypography variant="h6" mb={3}>
          Time Entry Location
        </MDTypography>
        {!latitude || !longitude ? (
          <MDTypography>No location data available</MDTypography>
        ) : (
          <div
            id={`time-entry-map-${id}`}
            style={{
              width: "100%",
              height: "400px",
              borderRadius: "10px",
            }}
          />
        )}
      </MDBox>
    </Modal>
  );
}
