import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  CreateCrewMutation,
  useCreateCrewMutation,
  CreateCrewMutationVariables,
  GetCrewsDocument,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { useCallback, useMemo } from "react";

export default function useCreateCrew() {
  const { offset, filtering } = usePaginatedVariables();
  const [mutation, values] = useCreateCrewMutation({
    refetchQueries: [
      {
        query: GetCrewsDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
        },
      },
    ],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = (data: CreateCrewMutation) => {
    return !!data?.createCrew;
  };

  const getData = useCallback(({ data }: { data?: CreateCrewMutation }) => {
    return data?.createCrew ?? null;
  }, []);

  const createCrew = useCallback(
    withMutationAction(
      async (props: MutationHookOptions<CreateCrewMutation, CreateCrewMutationVariables>) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.create-crew-success"),
        failureMessage: getLocalString("organization.create-crew-failure"),
      }
    ),
    [mutation]
  );

  const success = useMemo(() => {
    return isSuccess(values.data);
  }, [values.data]);

  const data = useMemo(() => {
    return getData({ data: values.data });
  }, [values.data]);

  return [
    createCrew,
    { data, error: values.error, loading: values.loading, success, isSuccess, getData },
  ] as const;
}
