import { MenuItem } from "@mui/material";
import { Company } from "generated/graphql";
import useMergeCompany, { MergeCompanyInput } from "hooks/companies/useMergeCompany";
import { useCallback, useState } from "react";
import CompanyMergeModal from "../CompanyMergeModal/CompanyMergeModal";

export default function MergeCompanyMenuItem(props: BitumioMenuItem<Company>) {
  const { id, closeMenu } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeMenu();
  };

  const [mergeCompany, { loading, error, getData, isSuccess }] = useMergeCompany();

  const handleSubmit = useCallback(
    async (input: MergeCompanyInput) => {
      const result = await mergeCompany({
        variables: {
          sourceCompany: input.sourceCompany,
          targetCompany: input.targetCompany,
        },
      });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (success) {
        handleClose();
      }
      return { success, data };
    },
    [mergeCompany]
  );

  return (
    <>
      <CompanyMergeModal
        open={open}
        onClose={handleClose}
        id={id}
        loading={loading}
        onSubmit={handleSubmit}
        error={error}
      />
      <MenuItem onClick={handleOpen}>Merge</MenuItem>
    </>
  );
}
