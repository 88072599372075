import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Modal from "modules/Modal/Modal";
import dayjs from "dayjs";
import { schema, TimeEntryUpdateSchema } from "DDD/action-objects/TimeEntryUpdate";
import TimeEntryModalForm from "./TimeEntryModalForm";
import { useUpdateTimeEntry } from "hooks/timeTracking/useUpdateTimeEntry";

export default function EditTimeEntryModal({ open, onClose, entry }) {
  const [updateTimeEntry] = useUpdateTimeEntry();

  const defaultValues = {
    uuid: entry.uuid,
    startTime: new Date(entry.startTime),
    endTime: new Date(entry.endTime),
    notes: entry.notes,
    jobPhaseId: entry.jobPhase?.id,
    timeBucketId: entry.timeBucket?.id,
  };

  const onSubmit = async (data: TimeEntryUpdateSchema) => {
    const result = await updateTimeEntry({
      variables: {
        input: {
          uuid: data.uuid,
          startTime: dayjs(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
          endTime: dayjs(data.endTime).format("YYYY-MM-DD HH:mm:ss"),
          notes: data.notes,
          jobPhaseId: data.jobPhaseId,
          timeBucketId: data.timeBucketId,
        },
      },
    });

    if (result.success) {
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} styles={{ overflow: "auto", maxWidth: "568px" }}>
      <MDBox p={3}>
        <MDTypography variant="h6" mb={3}>
          Edit Time Entry
        </MDTypography>

        <TimeEntryModalForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          schema={schema}
          submitButtonText="Save"
          type="edit"
        />
      </MDBox>
    </Modal>
  );
}
