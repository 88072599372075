import { User, UserRole } from "generated/graphql";
import * as z from "zod";

export const schema = z.object({
  userId: z.string().min(1),
  firstName: z.string().min(3, { message: "First Name is required" }),
  lastName: z.string().min(3, { message: "Last Name is required" }),
  email: z.string().email(),
  role: z.nativeEnum(UserRole).optional().or(z.null()),
  phone: z.string().optional().or(z.null()),
  addressLine1: z.string().optional().or(z.null()),
  addressLine2: z.string().optional().or(z.null()),
  addressCity: z.string().optional().or(z.null()),
  addressState: z.string().optional().or(z.null()),
  addressZip: z.string().optional().or(z.null()),
  addressCountry: z.string().optional().or(z.null()),
  avatar: z.instanceof(FileList).optional().or(z.null()),
  autoAssign: z.boolean(),
});

export const defaultValues = {
  firstName: "",
  lastname: "",
  email: "",
  role: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  addressCountry: "",
  autoAssign: true,
};

export function getDefaultValues(user: User) {
  return {
    ...defaultValues,
    ...user,
    userId: user.id,
    avatar: null,
    role: (user.roles.slice(0, 1)[0]?.toUpperCase() as UserRole) || null,
  };
}
