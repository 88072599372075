import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import { GetYearToDateStatsQuery } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

export default function YearToDateStats({
  yearToDateData,
}: {
  yearToDateData: GetYearToDateStatsQuery["getYearToDateStats"];
}) {
  return (
    <>
      <MDBox textAlign="center" mb={2}>
        <MDTypography variant="h4">Year to Date</MDTypography>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <DefaultStatisticsCard
                title="Total Amount Proposed"
                count={formatCentsToUSD(yearToDateData?.yearToDateTotalAmountProposed || 0)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <DefaultStatisticsCard
                title="Total Sales Won"
                count={formatCentsToUSD(yearToDateData?.yearToDateTotalSalesWon || 0)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={2.4}>
            <MDBox mb={1.5}>
              <DefaultStatisticsCard
                title="Close Rate"
                count={`${yearToDateData?.yearToDateCloseRate || 0}%`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <DefaultStatisticsCard
                title="Net Margin"
                count={`${yearToDateData?.yearToDateNetMarginCalculated || 0}%`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <DefaultStatisticsCard
                title="Job Amount Completed"
                count={formatCentsToUSD(yearToDateData?.yearToDateJobAmountCompleted || 0)}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
