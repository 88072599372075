import {
  requiresPlant as productRequiresPlant,
  requiresTrucking as productRequiresTrucking,
} from "DDD/action-objects/ProductCreate";
import { ProductType, ProposalProduct } from "generated/graphql";
import { snakeCase } from "change-case";

export default function useProposalProductDetails(proposalProduct?: ProposalProduct) {
  const productType = snakeCase(proposalProduct?.material?.type || "").toUpperCase() as ProductType;

  return {
    requiresPlant: productType ? productRequiresPlant.includes(productType) : false,
    requiresTrucking: productType ? productRequiresTrucking.includes(productType) : false,
    useWeightToCalculateTransferTruckCost:
      proposalProduct?.plantProduct?.plant?.rateOnWeight ?? false,
  };
}
