import { UserRole } from "generated/graphql";

/**
 * Roles Mapping
 */
export const roleMapping = {
  [UserRole.ADMIN]: [
    UserRole.ADMIN,
    UserRole.OFFICE_MANAGER,
    UserRole.SALES_PERSON,
    UserRole.FOREMAN,
  ],
  [UserRole.OFFICE_MANAGER]: [UserRole.OFFICE_MANAGER, UserRole.SALES_PERSON, UserRole.FOREMAN],
  [UserRole.SALES_PERSON]: [UserRole.SALES_PERSON, UserRole.FOREMAN],
  [UserRole.FOREMAN]: [UserRole.FOREMAN],
};

export const inviteUserRolesMapping = {
  [UserRole.ADMIN]: [
    UserRole.ADMIN,
    UserRole.OFFICE_MANAGER,
    UserRole.SALES_PERSON,
    UserRole.FOREMAN,
    UserRole.EMPLOYEE,
    UserRole.SUBCONTRACTOR,
  ],
  [UserRole.OFFICE_MANAGER]: [
    UserRole.OFFICE_MANAGER,
    UserRole.SALES_PERSON,
    UserRole.FOREMAN,
    UserRole.EMPLOYEE,
    UserRole.SUBCONTRACTOR,
  ],
  [UserRole.SALES_PERSON]: [
    UserRole.SALES_PERSON,
    UserRole.FOREMAN,
    UserRole.EMPLOYEE,
    UserRole.SUBCONTRACTOR,
  ],
  [UserRole.FOREMAN]: [UserRole.FOREMAN, UserRole.EMPLOYEE, UserRole.SUBCONTRACTOR],
  [UserRole.EMPLOYEE]: [UserRole.EMPLOYEE, UserRole.SUBCONTRACTOR],
  [UserRole.SUBCONTRACTOR]: [UserRole.SUBCONTRACTOR],
};
