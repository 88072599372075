import {
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import FormField from "components/FormField/FormField";
import TimeFormField from "components/TimeFormField/TimeFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";
import timeEndAdornment from "constants/timeEndAdornment";
import { UpdateProposalProductInputSchema } from "DDD/action-objects/StageProductUpdate";
import { Crew, ProductLabourCostType, Proposal, ProposalCrew } from "generated/graphql";
import useAddProposalCrew from "hooks/proposals/useAddProposalCrew";
import AddLabourCost from "modules/proposals/labourCosts/AddLabourCost";
import { ProposalContext } from "modules/proposals/providers/ProposalContextProvider";
import { useCallback, useContext, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import StageProductContext from "../StageProductContext";
import useAddProposalCrews from "hooks/proposals/useAddProposalCrews";

function LabourCostsSubForm() {
  const { inViewMode } = useContext(ProposalContext);
  const [addProposalCrews, { isSuccess }] = useAddProposalCrews();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useFormContext<UpdateProposalProductInputSchema>();
  const { product, onSubmit } = useContext(StageProductContext);
  const { fields, remove } = useFieldArray({
    control,
    name: "crews",
    keyName: "key",
  });

  const crewsById = useMemo(() => {
    return product.crews.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      };
    }, {} as Record<string, ProposalCrew | undefined>);
  }, [product.crews]);

  const onAdd = useCallback(
    async (crewIds, setCrewIds) => {
      // TODO: Find a way to update the proposal data without calling the submit
      await handleSubmit(onSubmit)();

      const result = await addProposalCrews({
        variables: { crewIds, proposalProductId: product.id },
      });

      const success = isSuccess(result.data);
      setCrewIds([]);
      // if (success) {
      //   writeProposal(result.data.addProposalCrew as Proposal);
      // }
    },
    [addProposalCrews]
  );

  // const total = crews.reduce(
  //   (acc, val) =>
  //     acc +
  //     ((val.timeOnjob
  //       ? val.timeOnjob / 3600
  //       : crewsById[val.id]?.timeOnjob
  //       ? crewsById[val.id]?.timeOnjob / 3600
  //       : 0) +
  //       (val.timeTravel
  //         ? val.timeTravel / 3600
  //         : crewsById[val.id]?.timeTravel
  //         ? crewsById[val.id].timeTravel / 3600
  //         : 0)) *
  //       (val.rate ?? crewsById[val.id]?.rate ?? 0),
  //   0
  // );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell sx={{ width: "25%" }}>Name</TableCell>
                <TableCell align="center" sx={{ width: "18.75%" }}>
                  Rate
                </TableCell>
                {product.material.labourCostType === ProductLabourCostType.PER_MAN_HOUR && (
                  <>
                    <TableCell align="center" sx={{ width: "18.75%" }}>
                      Travel Time
                    </TableCell>
                    <TableCell align="center" sx={{ width: "18.75%" }}>
                      On Stage Time
                    </TableCell>
                  </>
                )}
                <TableCell align="center" sx={{ width: "18.75%" }}>
                  Total Cost
                </TableCell>
                {!inViewMode && <TableCell align="center"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((labourCost, i) => {
                return (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 5 } }}>
                    <TableCell component="th" scope="row">
                      {crewsById[labourCost.id]?.name}
                    </TableCell>
                    <TableCell align="right">
                      <CurrencyFormField
                        placeholder={(product.crews?.[i]?.rate / 100)?.toFixed(2)}
                        disabled={inViewMode}
                        mb={0}
                        sx={{
                          "& .MuiInputBase-root": {
                            "& input": {
                              textAlign: "right",
                            },
                          },
                        }}
                        {...currencyEndAdornment}
                        control={control}
                        name={`crews.${i}.rate`}
                        sendEmptyValuesAsNull
                        key={isSubmitSuccessful}
                      />
                    </TableCell>
                    {product.material.labourCostType === ProductLabourCostType.PER_MAN_HOUR && (
                      <>
                        <TableCell align="right">
                          <FormField
                            placeholder={(product.crews?.[i]?.timeTravel / 3600)?.toFixed(2)}
                            disabled={inViewMode}
                            align="right"
                            mb={0}
                            sx={{
                              "& .MuiInputBase-root": {
                                "& input": {
                                  textAlign: "right",
                                },
                              },
                            }}
                            {...timeEndAdornment}
                            {...register(`crews.${i}.timeTravel`, { valueAsNumber: true })}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormField
                            placeholder={(product.crews?.[i]?.timeOnjob / 3600)?.toFixed(2)}
                            disabled={inViewMode}
                            align="right"
                            mb={0}
                            sx={{
                              "& .MuiInputBase-root": {
                                "& input": {
                                  textAlign: "right",
                                },
                              },
                            }}
                            {...timeEndAdornment}
                            {...register(`crews.${i}.timeOnjob`, { valueAsNumber: true })}
                          />
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center">
                      {formatCentsToUSD(product.crews?.[i]?.cost)}
                    </TableCell>
                    {!inViewMode && (
                      <TableCell>
                        <IconButton onClick={() => remove(i)}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {!inViewMode && (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell colSpan={6}>
                    <AddLabourCost onAdd={onAdd} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default LabourCostsSubForm;
