import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CreateNotificationTriggerInput } from "generated/graphql";
import useCreateNotificationTrigger from "hooks/notifications/useCreateNotificationTrigger";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import NotificationTriggerForm from "modules/NotificationTriggerForm/NotificationTriggerForm";

import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export default function NotificationsCreate() {
  const [createNotificationTrigger, { loading, isSuccess }] = useCreateNotificationTrigger();
  const navigate = useNavigate();
  const submitFunction = useCallback(
    async (input: CreateNotificationTriggerInput) => {
      const result = await createNotificationTrigger({
        variables: {
          input: {
            ...input,
          },
        },
      });

      if (result.success) {
        navigate("/email-triggers");
      }
    },
    [createNotificationTrigger]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Create Trigger
            </MDTypography>
          </MDBox>
          <NotificationTriggerForm onSubmit={submitFunction} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
