import { Box, MenuItem, Modal as MuiModal, SxProps, Typography } from "@mui/material";
import { Company } from "generated/graphql";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "4px solid rgba(25, 25, 25, 0.25)",
  borderRadius: "4px",
  boxShadow: 24,
};

const FullScreenModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ children, open, onClose }) => {
  return (
    <MuiModal
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </MuiModal>
  );
};

export default FullScreenModal;
