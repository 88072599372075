import * as React from "react";
import clsx from "clsx";
import { animated, useSpring } from "@react-spring/web";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderRounded from "@mui/icons-material/FolderRounded";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import { TreeItem2DragAndDropOverlay } from "@mui/x-tree-view/TreeItem2DragAndDropOverlay";
import { ProjectFile, ProjectFileType, SortDirection } from "generated/graphql";
import { ProjectFilesContext } from "modules/proposals/providers/ProjectFilesContextProvider";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import AddFileOrFolder from "./AddFileOrFolder";
import useFileSearch from "hooks/projectFiles/useFileSearch";
import useSortProjectFile from "hooks/projectFiles/useSortProjectFile";
import SearchAndSortFiles from "./SearchAndSortFiles";

type FileType =
  | "image"
  | "pdf"
  | "doc"
  | "video"
  | "pinned"
  | "trash"
  | ProjectFileType.FOLDER
  | ProjectFileType.FILE;

function DotIcon() {
  return (
    <Box
      sx={{
        width: 6,
        height: 6,
        borderRadius: "70%",
        bgcolor: "warning.main",
        display: "inline-block",
        verticalAlign: "middle",
        zIndex: 1,
        mx: 1,
      }}
    />
  );
}
declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.mode === "light" ? theme.palette.grey[800] : theme.palette.grey[400],
  position: "relative",
  paddingLeft: theme.spacing(2),
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
})) as unknown as typeof TreeItem2Root;

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: "row-reverse",
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: "5",
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`&.Mui-expanded `]: {
    "&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon": {
      color: theme.palette.mode === "light" ? "#aaa" : theme.palette.primary.dark,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: "16px",
      top: "44px",
      height: "calc(100% - 48px)",
      width: "1.5px",
      backgroundColor:
        theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
    },
  },
  "&:hover": {
    backgroundColor: "#e5e5e5",
    color: "#000",
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    backgroundColor: "#556",
    color: theme.palette.primary.contrastText,
  },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
  color: "inherit",
  fontWeight: 400,
}) as unknown as typeof Typography;

interface CustomLabelProps {
  children: React.ReactNode;
  icon?: React.ElementType;
  expandable?: boolean;
}

function CustomLabel({ icon: Icon, expandable, children, ...other }: CustomLabelProps) {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          className="labelIcon"
          color="inherit"
          sx={{ mr: 1, fontSize: "1.2rem" }}
        />
      )}

      <StyledTreeItemLabelText variant="body2">{children}</StyledTreeItemLabelText>
      {expandable && <DotIcon />}
    </TreeItem2Label>
  );
}

const isExpandable = (reactChildren: React.ReactNode) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const getIconFromFileType = (fileType: FileType) => {
  switch (fileType) {
    case "image":
      return ImageIcon;
    case "pdf":
      return PictureAsPdfIcon;
    case "doc":
      return ArticleIcon;
    case "video":
      return VideoCameraBackIcon;
    case ProjectFileType.FOLDER:
      return FolderRounded;
    case "pinned":
      return FolderOpenIcon;
    case "trash":
      return DeleteIcon;
    default:
      return ArticleIcon;
  }
};

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  // @ts-ignore
  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  let icon;
  if (expandable) {
    icon = FolderRounded;
  } else if (item.type) {
    icon = getIconFromFileType(item.type);
  }

  return (
    // @ts-ignore
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx("content", {
              "Mui-expanded": status.expanded,
              "Mui-selected": status.selected,
              "Mui-focused": status.focused,
              "Mui-disabled": status.disabled,
            }),
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel {...getLabelProps({ icon, expandable: expandable && status.expanded })} />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

export default function Tree({ data, submitFn, folderView }) {
  const [query, setQuery] = React.useState("");
  const { searchResult, search } = useFileSearch(data);
  const { sortedData, sortBy, sortDirection, changeSort } = useSortProjectFile(
    searchResult.length > 0 ? searchResult : data
  );
  const { lastSelectedItem, setLastSelectedItem } = React.useContext(ProjectFilesContext);
  const getItemLabel = React.useCallback((item: ProjectFile) => item.name, [data]);
  const isItemDisabled = React.useCallback(
    (item: ProjectFile) => {
      if (folderView) {
        return item.type === ProjectFileType.FILE;
      }
    },
    [data]
  );

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean
  ) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
    }
  };

  const apiRef = useTreeViewApiRef();
  const handleClearSelection = (event) => {
    // @ts-ignore
    apiRef.current?.selectItem({ event, itemId: lastSelectedItem, shouldBeSelected: false });
    setLastSelectedItem(null);
  };

  const handleSortChange = (column) => {
    const direction =
      sortBy === column && sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC;
    changeSort(column, direction);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    search(value);
  };

  return (
    <Card>
      <MDBox sx={{ width: "100%" }}>
        {data && (
          <>
            <MDBox
              textAlign="right"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={3}
            >
              <SearchAndSortFiles
                query={query}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleInputChange={handleInputChange}
                handleSortChange={handleSortChange}
              />
              {folderView && (
                <AddFileOrFolder
                  folderView
                  lastSelectedItem={lastSelectedItem}
                  fileById={{ id: lastSelectedItem, type: ProjectFileType.FOLDER, name: "" }}
                />
              )}
              {/* <Tooltip title="Clear Selection">
                <IconButton onClick={handleClearSelection}>
                  <Icon>clear</Icon>
                </IconButton>
              </Tooltip> */}
            </MDBox>

            <RichTreeView
              items={sortedData}
              defaultExpandedItems={["1", "1.1"]}
              defaultSelectedItems={["1.1"]}
              itemChildrenIndentation={24}
              sx={{ height: "fit-content", flexGrow: 1, overflowY: "auto" }}
              slots={{ item: CustomTreeItem }}
              getItemLabel={getItemLabel}
              onItemSelectionToggle={handleItemSelectionToggle}
              isItemDisabled={isItemDisabled}
              apiRef={apiRef}
            />
          </>
        )}
        {folderView && (
          <MDBox p={3} textAlign="right">
            <MDButton
              variant="gradient"
              color="success"
              disabled={!lastSelectedItem}
              onClick={() => submitFn(lastSelectedItem)}
            >
              Select
            </MDButton>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
