import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "curbWidth",
    label: "Curb Width",
  },
  {
    name: "curbHeight",
    label: "Curb Height",
  },
  {
    name: "gutterWidth",
    label: "Gutter Width",
  },
  {
    name: "gutterHeight",
    label: "Gutter Height",
  },
];

export default function ConcreteWaterwayForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
