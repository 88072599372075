import getLocalString from "constants/Localization";
import { useCustomUpdateJobNotesMutation, useUpdateEmployeeMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateEmployee() {
  const [mutation, { loading, data, error }] = useUpdateEmployeeMutation();
  const [updateEmployee, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateEmployee",
    },
    {
      successMessage: getLocalString("organization.update-employee-success"),
      failureMessage: getLocalString("organization.update-employee-failure"),
    }
  );

  return [updateEmployee, context] as const;
}
