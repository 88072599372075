import { MenuItem } from "@mui/material";
import { Product } from "generated/graphql";
import { useGetProduct } from "hooks/products/useGetProduct";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function UpdateProductMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: Product["id"];
}) {
  const { loading, error, data: product } = useGetProduct({ id });

  if (loading || error) {
    return null;
  }

  return (
    <MenuItem
      component={Link}
      onClick={closeMenu}
      to={getRoute("product-types.products.update", [
        ["productTypeId", product.organizationProductType.id],
        ["productId", id],
      ])}
    >
      Update Product
    </MenuItem>
  );
}
