import { useGenerateUploadOrganizationDocumentUrlMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useGenerateUploadOrganizationDocumentUrl() {
  const [mutation, { loading, error, data }] = useGenerateUploadOrganizationDocumentUrlMutation();
  const [generateUploadOrganizationDocumentUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "generateUploadOrganizationDocumentUrl",
    },
    {
      hideMessage: true,
    }
  );

  return [generateUploadOrganizationDocumentUrl, context] as const;
}
