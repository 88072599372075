import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateTransferTruckInput,
  UpdateTransferTruckMutation,
  UpdateTransferTruckMutationVariables,
  useUpdateTransferTruckMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export default function useUpdateTransferTruck() {
  const [mutation, { data, loading, error }] = useUpdateTransferTruckMutation();
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateTransferTruckMutation) => {
    return (data && !!data.updateTransferTruck?.id) || false;
  }, []);

  const getTransferTruck = useCallback((data: UpdateTransferTruckMutation) => {
    return data && data.updateTransferTruck;
  }, []);

  const transferTruck = useMemo(() => {
    return getTransferTruck(data) || null;
  }, [data]);

  const updateTransferTruck = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          UpdateTransferTruckMutation,
          UpdateTransferTruckMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.update-transfer-truck-success"),
        failureMessage: getLocalString("organization.update-transfer-truck-failure"),
      }
    ),
    []
  );

  return [
    updateTransferTruck,
    { data, loading, error, isSuccess, transferTruck, getTransferTruck },
  ] as const;
}
