import { useCustomScheduleJobPhaseMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import { useState } from "react";

export default function useScheduleJobPhase() {
  const [mutation, { loading, data, error }] = useCustomScheduleJobPhaseMutation({
    refetchQueries: [
      "customGetJobSchedules",
      "customGetJobPhase",
      "getJobSchedules",
      "getJobPhase",
    ],
  });

  const [scheduleJobPhase, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "scheduleJobPhase",
    },
    {
      hideMessage: true,
    }
  );

  return [scheduleJobPhase, context] as const;
}
