import { Stack } from "@mui/material";
import MDButton from "components/MDButton";
import { Job, OrganizationLinkedServiceName, Proposal, Organization } from "generated/graphql";
import useGetOrganization from "hooks/organization/useGetOrganization";
import CreateChangeOrder from "modules/proposals/buttons/CreateChangeOrder/CreateChangeOrder";
import { Link } from "react-router-dom";

import Modal from "../Modal/Modal";
import useModalState from "hooks/useModalState";
import QuickbooksConfirmationModal from "./Modals/QuickbooksConfirmationModal";

export default function StackSection({
  proposalId,
  jobId,
  job,
  shouldPushTaxesToQuickbooks,
  proposal,
  mayShowTaxNotification,
}: {
  proposalId: Proposal["id"];
  jobId: Job["id"];
  job: Job;
  shouldPushTaxesToQuickbooks?: Boolean;
  mayShowTaxNotification?: Boolean;
  proposal: Partial<Proposal>;
}) {
  const { data } = useGetOrganization();
  const { open, onClose, onOpen } = useModalState();

  const hasLinkedQuickbooksDesktop = !!data?.linkedServices?.find(
    (service) =>
      service.service === OrganizationLinkedServiceName.QUICKBOOKS_DESKTOP && service.active
  );

  const hasLinkedQuickbooksOnline = !!data?.linkedServices?.find(
    (service) => service.service === OrganizationLinkedServiceName.QUICKBOOKS && service.active
  );

  return (
    <Stack direction="row" spacing={5} justifyContent="center">
      <MDButton
        color="success"
        disabled={!hasLinkedQuickbooksOnline && !hasLinkedQuickbooksDesktop}
        onClick={onOpen}
      >
        Sync with Quickbooks
      </MDButton>
      <Modal open={open} onClose={onClose} styles={{ overflow: "auto", height: "90vh" }}>
        <QuickbooksConfirmationModal
          proposalId={proposalId}
          job={job}
          organization={data as Organization}
          shouldPushTaxesToQuickbooks={shouldPushTaxesToQuickbooks}
          mayShowTaxNotification={mayShowTaxNotification}
        />
      </Modal>
      <MDButton component={Link} to={`/proposals/${proposalId}`} color="success">
        View Proposal
      </MDButton>
      <MDButton
        component={Link}
        to={`/jobs/schedule?jobId=${jobId}`}
        variant="contained"
        color="success"
      >
        Schedule
      </MDButton>
      <CreateChangeOrder
        isChangeOrder={proposal.isChangeOrder}
        status={proposal.status}
        id={proposal.id}
        changeOrderJobId={jobId}
      />
    </Stack>
  );
}
