import { Checkbox, CircularProgress, FormControlLabel, FormGroup } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useMemo, useState } from "react";
import JobsSchedules from "../JobsSchedules/JobsSchedules";
import JobsSchedulesCalendar from "../JobsSchedulesCalendar/JobsSchedulesCalendar";
import useGetAllCrews, { CustomCrews, CustomCrew } from "hooks/crews/useGetAllCrews";
import CalendarContext from "./CalendarContext";
import { omit } from "ramda";
import { v4 as uuidv4 } from "uuid";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import CreateUpdateCustomEvent from "modules/CustomEvent/CreateUpdateCustomEvent";

export default function JobsScheduleDataLoader({ jobId }: { jobId?: string | undefined } = {}) {
  const { data: crews, loading, error } = useGetAllCrews();

  if (loading) {
    return <CircularProgress color="inherit" />;
  }

  return <>{crews ? <JobsSchedule jobId={jobId} crews={crews} /> : null}</>;
}

function JobsSchedule({ jobId, crews }: { jobId?: string | undefined; crews: CustomCrews }) {
  const [checked, setChecked] = useState<
    Record<string, (CustomCrew & { checked: boolean }) | undefined>
  >(() => {
    return crews.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: {
          ...val,
          checked: true,
        },
      };
    }, {});
  });
  const [showCustom, setShowCustom] = useState<boolean>(true);
  const { open, onClose, onOpen } = useModalState();

  const [calendarsToUpdate, setCalendarsToUpdate] = useState({});

  const subscribeCalendarToUpdates = useCallback(
    (callback: () => void) => {
      const id = uuidv4();
      setCalendarsToUpdate((prevState) => ({
        ...prevState,
        [id]: callback,
      }));
      return id;
    },
    [setCalendarsToUpdate]
  );

  const unsubscribeCalendarFromUpdates = useCallback(
    (key: string) => {
      setCalendarsToUpdate((prevState) => omit([key])(prevState));
    },
    [setCalendarsToUpdate]
  );

  const handleCrewCheckedChanged = useCallback(
    (crew, checked) => {
      setChecked((prevValues) => ({
        ...prevValues,
        [crew.value]: {
          ...prevValues[crew.value],
          checked,
        },
      }));
    },
    [setChecked]
  );

  const handleToggleAllCrews = useCallback(
    (crews, checked) => {
      crews.map((crew) => {
        setChecked((prevValues) => ({
          ...prevValues,
          [crew.value]: {
            ...prevValues[crew.value],
            checked,
          },
        }));
      });
    },
    [setChecked]
  );

  const onScheduleUpdates = useCallback(() => {
    Object.keys(calendarsToUpdate).forEach((key) => {
      calendarsToUpdate[key]?.(); //call every callback
    });
  }, [calendarsToUpdate]);

  const crewOptions = useMemo(() => {
    if (!crews) {
      return [];
    }

    return crews.map((crew) => {
      return {
        label: crew.name,
        value: crew.id,
      };
    });
  }, [crews]);

  return (
    <CalendarContext.Provider
      value={{ subscribeCalendarToUpdates, unsubscribeCalendarFromUpdates }}
    >
      <MDBox
        display="flex"
        sx={{
          justifyContent: "space-between",
        }}
      >
        <MDBox display="flex" gap={2}>
          <MDButton color="blue" onClick={() => handleToggleAllCrews(crewOptions, true)}>
            Select All
          </MDButton>
          <MDButton color="blue" onClick={() => handleToggleAllCrews(crewOptions, false)}>
            Deselect All
          </MDButton>
          <MDButton color="blue" onClick={() => setShowCustom(!showCustom)}>
            {showCustom ? "Hide Custom Events" : "Show All Events"}
          </MDButton>
        </MDBox>
        <MDButton color="dark" onClick={onOpen}>
          Add Event
        </MDButton>
      </MDBox>
      <MDBox display="flex" mb={3}>
        <MDBox display="flex" gap={2} alignItems="center">
          <MDBox display="flex" flexWrap="wrap">
            {crewOptions.map((crewOption) => {
              return (
                <FormGroup key={crewOption.value}>
                  <FormControlLabel
                    sx={{
                      position: "relative",
                      "&::after": {
                        content: "''",
                        position: "absolute",
                        left: "10px",
                        right: 0,
                        height: "2px",
                        bottom: "0",
                        backgroundColor: `#${checked[crewOption.value]?.color}`,
                      },
                    }}
                    control={
                      <Checkbox
                        checked={checked[crewOption.value]?.checked ?? false}
                        onChange={(event) => {
                          handleCrewCheckedChanged(crewOption, event.target.checked);
                        }}
                      />
                    }
                    label={crewOption.label}
                  />
                </FormGroup>
              );
            })}
          </MDBox>
        </MDBox>
      </MDBox>
      <JobsSchedulesCalendar
        showCustomEvents={showCustom}
        jobIds={jobId ? [jobId] : null}
        crewIds={Object.keys(checked).filter((key) => checked[key].checked)}
      />
      <MDBox my={3}>
        <JobsSchedules jobIds={jobId ? [jobId] : null} onSchedulesUpdate={onScheduleUpdates} />
      </MDBox>
      <Modal open={open} onClose={onClose}>
        <CreateUpdateCustomEvent action="create" onClose={onClose} />
      </Modal>
    </CalendarContext.Provider>
  );
}
