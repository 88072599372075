import { useDeleteTimeBucketMutation } from "generated/graphql";
import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useDeleteTimeBucket = () => {
  const [mutation, { loading, error, data }] = useDeleteTimeBucketMutation({
    refetchQueries: ["getTimeBuckets"],
  });

  const [deleteTimeBucket, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteTimeBucket",
    },
    {
      successMessage: getLocalString("time-tracking.delete-time-bucket-success"),
      failureMessage: getLocalString("time-tracking.delete-time-bucket-failure"),
    }
  );

  return [deleteTimeBucket, context] as const;
};
