import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import { CustomGetProposalDocument, Proposal, ProposalStage } from "generated/graphql";
import useCustomAddProductToProposalStage from "hooks/proposals/useCustomAddProductToProposalStage";
import SelectProductForm, { SelectProductValues } from "modules/products/forms/SelectProductForm";
import { useCallback } from "react";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function SelectStageProduct({
  stage,
  onSuccess,
}: {
  stage: ProposalStage;
  onSuccess: (product: Proposal) => void;
}) {
  const [addProductToProposalStage, { loading, error, isSuccess, getData }] =
    useCustomAddProductToProposalStage();

  const handleOnSubmit = useCallback(
    async (input: SelectProductValues) => {
      const result = await addProductToProposalStage({
        variables: {
          ...input,
          proposalStageId: stage.id,
        },
        refetchQueries: (result) => {
          return [
            {
              query: CustomGetProposalDocument,
              variables: {
                id: result.data.addProductToProposalStage.id,
              },
            },
          ];
        },
      });

      const success = isSuccess(result.data);
      const data = getData(result);

      if (success) {
        onSuccess(data as Proposal);
      }

      return { success, data };
    },
    [stage.id, addProductToProposalStage]
  );

  return (
    <div>
      <MDTypography>Select Product</MDTypography>
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <SelectProductForm onSubmit={handleOnSubmit} loading={loading} />
    </div>
  );
}
