import { Icon, MenuItem } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import TableFilter from "components/TableFilter/TableFilter";

const DateFilter = ({ value, id, onChange }) => {
  return (
    <TableFilter value={value} id={id}>
      <MDBox sx={{ maxHeight: "250px", overflowY: "auto" }}>
        <MenuItem
          selected={value === null}
          value={null}
          onClick={() => onChange(null)}
          sx={{ pl: "20px" }}
        >
          {value === null ? <Icon sx={{ position: "absolute", left: "4px" }}>checked</Icon> : ""}
          Clear Date
        </MenuItem>
        <FormField
          type="date"
          label="Date"
          placeholder="Date"
          onChange={(e) => onChange(e.target.value)}
        />
      </MDBox>
    </TableFilter>
  );
};

export default DateFilter;
