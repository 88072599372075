import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import MDSelect from "components/MDSelect/MDSelect";
import MDTypography from "components/MDTypography";
import Modal from "modules/Modal/Modal";
import { useContext, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AreaCoverageContext } from "../forms/AreaCoverage";

const updateValues = (setValue, poundsPerGallon, poundsPerBlock) => {
  setValue("attributes.poundsPerGallon", poundsPerGallon);
  setValue("attributes.poundsPerBlock", poundsPerBlock);
};

const productActions = {
  "crafco-polyflex-601": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "12.2", "30");
    setAllowManualEdit(false);
  },
  "crafco-roadsaver-201": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "9.7", "30");
    setAllowManualEdit(false);
  },
  "crafco-roadsaver-211": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.7", "30");
    setAllowManualEdit(false);
  },
  "crafco-roadsaver-ca-type-5": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.2", "40");
    setAllowManualEdit(false);
  },
  "crafco-roadsaver-221": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.5", "30");
    setAllowManualEdit(false);
  },
  "crafco-roadsaver-222": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.2", "40");
    setAllowManualEdit(false);
  },
  "crafco-roadsaver-231": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.5", "30");
    setAllowManualEdit(false);
  },
  "crafco-roadsaver-514": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.4", "30");
    setAllowManualEdit(false);
  },
  "roadsaver-515": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.5", "30");
    setAllowManualEdit(false);
  },
  "roadsaver-515t": (setValue, setAllowManualEdit) => {
    updateValues(setValue, "10.5", "30");
    setAllowManualEdit(false);
  },
  custom: (setValue, setAllowManualEdit) => {
    setAllowManualEdit(true);
  },
};

const productOptions = [
  { label: "Crafco PolyFlex 601", value: "crafco-polyflex-601" },
  { label: "Crafco RoadSaver 201", value: "crafco-roadsaver-201" },
  { label: "Crafco RoadSaver 211", value: "crafco-roadsaver-211" },
  { label: "Crafco RoadSaver CA Type 5", value: "crafco-roadsaver-ca-type-5" },
  { label: "Crafco RoadSaver 221", value: "crafco-roadsaver-221" },
  { label: "Crafco RoadSaver 222", value: "crafco-roadsaver-222" },
  { label: "Crafco RoadSaver 231", value: "crafco-roadsaver-231" },
  { label: "Crafco RoadSaver 514", value: "crafco-roadsaver-514" },
  { label: "RoadSaver 515", value: "roadsaver-515" },
  { label: "RoadSaver 515T", value: "roadsaver-515t" },
  { label: "Custom", value: "custom" },
];

export default function CrackSealCalculateAreaModal() {
  const { calculateAreaCoverage, areaProduct, setAreaProduct } = useContext(AreaCoverageContext);
  const [isAllowedToEdit, setIsAllowedToEdit] = useState(areaProduct === "custom");
  const isNotAllowedToEdit = !isAllowedToEdit;

  const { control, getValues, setValue } = useFormContext();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    calculateAreaCoverage(getValues, setValue);
  };
  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <div style={{ position: "relative" }}>
          <Grid p={4} container spacing={3}>
            <Grid item xs={12}>
              <MDTypography>Calculate Area Coverage</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDSelect
                fullWidth
                label="Select Product"
                name="areaProduct"
                options={productOptions}
                onChange={(e) => {
                  setAreaProduct(e.target.value);
                  const action = productActions[e.target.value];
                  if (action) {
                    action(setValue, setIsAllowedToEdit);
                  }
                }}
                value={areaProduct}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="attributes.poundsPerGallon"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField
                    disabled={isNotAllowedToEdit}
                    type="number"
                    label="Pounds Per Gallon"
                    placeholder="Pounds Per Gallon"
                    name="attributes.poundsPerGallon"
                    error={error}
                    shrink
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="attributes.poundsPerBlock"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormField
                    disabled={isNotAllowedToEdit}
                    type="number"
                    label="Pounds Per Block"
                    placeholder="Pounds Per Block"
                    name="attributes.poundsPerBlock"
                    error={error}
                    shrink
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton variant="gradient" color="secondary" onClick={handleClose}>
                Calculate Area Coverage
              </MDButton>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <MDButton onClick={handleOpen} variant="gradient" color="secondary">
        Calculate Area Coverage
      </MDButton>
    </>
  );
}
