import { MenuItem } from "@mui/material";
import { OrganizationProductType } from "generated/graphql";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function Update({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: OrganizationProductType["id"];
}) {
  return (
    <MenuItem
      component={Link}
      onClick={closeMenu}
      to={getRoute("product-types.update", [["productTypeId", id]])}
    >
      Update
    </MenuItem>
  );
}
