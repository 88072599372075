import MDButton from "components/MDButton";
import { ProposalProduct, UpdateProposalProductInput } from "generated/graphql";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import MDBox from "components/MDBox";
import useUpdateProposalProduct from "hooks/proposals/useUpdateProposalProduct";
import {
  UpdateProposalProductInputSchema,
  getDefaultValues,
} from "DDD/action-objects/StageProductUpdate";
import { useMemo } from "react";
import useGetOrganizationSettings from "hooks/organization/useGetOrganizationSettings";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";
import { Grid } from "@mui/material";

interface IProposaProductEditUnitPrice {
  product: ProposalProduct;
  setEditable: (editable: boolean) => void;
}

export default function ProposalProductEditUnitPriceForm({
  setEditable,
  product,
}: IProposaProductEditUnitPrice) {
  const [updateProposalProduct, { isSuccess: proposalProductSuccess, getData }] =
    useUpdateProposalProduct();
  const { data } = useGetOrganizationSettings();
  const averageWorkingHoursPerDay = +data?.["average_working_hours_per_day"] ?? 0;
  const handleOnSubmit = useCallback(
    async (input: UpdateProposalProductInput) => {
      const result = await updateProposalProduct({
        variables: {
          input,
        },
      });
      const success = proposalProductSuccess(result?.data);
      const dt = getData(result);
      if (success) {
        setEditable(false);
      }
      return { success, data: dt };
    },
    [updateProposalProduct, proposalProductSuccess]
  );
  const defaultValues: UpdateProposalProductInputSchema = useMemo(() => {
    return getDefaultValues({
      product,
      transferTrucks: [],
      averageWorkingHoursPerDay,
    });
  }, [product]);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UpdateProposalProductInputSchema>({
    defaultValues,
  });
  const unitPricePlaceHolder = useMemo(() => {
    const overallTotal = product.overallTotalOverride || product.overallTotal || 0;
    if (product.material.unitPrice || product.material.unitPriceOverride) {
      return product?.material?.unitPriceOverride || product?.material?.unitPrice || 0;
    }
    return product.jobSize ? (overallTotal as number) / product.jobSize : 0;
  }, [product]);
  return (
    <MDBox display="flex" gap="2px" alignItems="center">
      <Grid container spacing={10} alignItems="center" mr="10px">
        <Grid item xs={6}>
          <CurrencyFormField
            placeholder={(unitPricePlaceHolder / 100).toFixed(2)}
            name="material.unitPrice"
            onClick={(e) => e.stopPropagation()}
            control={control}
            {...currencyEndAdornment}
            inputProps={{
              sx: {
                fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                fontSize: "1.25rem",
                color: "#344767",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyFormField
            placeholder={(+(product?.overallTotalOverride ?? product?.overallTotal) / 100).toFixed(
              2
            )}
            name="overallTotalOverride"
            onClick={(e) => e.stopPropagation()}
            control={control}
            {...currencyEndAdornment}
            inputProps={{
              sx: {
                fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                fontSize: "1.25rem",
                color: "#344767",
              },
            }}
          />
        </Grid>
      </Grid>

      <MDButton
        color="success"
        type="button"
        onClick={async (e) => {
          e.stopPropagation();
          //@ts-ignore
          await handleSubmit(handleOnSubmit)();
          setEditable(false);
        }}
      >
        Save
      </MDButton>
      <MDButton
        color="info"
        type="button"
        onClick={async (e) => {
          e.stopPropagation();
          setEditable(false);
        }}
      >
        Cancel
      </MDButton>
    </MDBox>
  );
}
