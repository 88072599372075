import { useCallback, useState } from "react";

export default function useModalState() {
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return { open, onClose, onOpen, setOpen };
}
