import getLocalString from "constants/Localization";
import { useDeleteEmployeeMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteEmployee() {
  const [mutation, { loading, data, error }] = useDeleteEmployeeMutation({
    refetchQueries: ["getEmployees", "customGetEmployees"],
  });

  const [deleteEmployee, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteEmployee",
    },
    {
      successMessage: getLocalString("organization.delete-employee-success"),
      failureMessage: getLocalString("organization.delete-employee-failure"),
    }
  );

  return [deleteEmployee, context] as const;
}
