import { cacheKey as getCompaniesCacheKey } from "hooks/companies/useGetCompanies";
import { cacheKey as getTransferTrucksCacheKey } from "hooks/organization/useGetTransferTrucks";
import { cacheKey as getOrganizationAddressesCacheKey } from "hooks/organization/useGetOrganizationAddresses";
import { cacheKey as getOrganizationUsersCacheKey } from "hooks/users/useGetOrganizationUsers";
import { cacheKey as getEquipmentPiecesCacheKey } from "hooks/organization/useGetEquipmentPieces";
import { cacheKey as getAdditionalCostsCacheKey } from "hooks/organization/useGetAdditionalCosts";
import { cacheKey as getCrewsCacheKey } from "hooks/organization/useGetCrews";

import { ApolloClient, DefaultOptions, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import * as Sentry from "@sentry/react";

import {
  REFETCH_QUERY_DEFAULT_PAGE,
  REFETCH_QUERY_FIRST_VARIABLE,
} from "constants/RefetchVariables";
import { PaginatorInfo } from "generated/graphql";
import { getProposalsCacheKey } from "hooks/companies/useGetProposals";

// export const cacheKeys = ["getProposals"];

// const getKeyArgs = (key: string) => {
//   return ["search", "sort"];
// };

// const fields = cacheKeys.reduce((acc, val) => {
//   return {
//     ...acc,
//     [val]: {
//       keyArgs: getKeyArgs(val),
//       merge(existing: any[] | null, incoming: { data: any[]; paginatorInfo: PaginatorInfo }) {
//         const currentPage = incoming.paginatorInfo.currentPage - 1;
//         const perPage = incoming.paginatorInfo.perPage;

//         const merged = existing ? [...existing] : incoming.data ? [] : undefined;
//         incoming.data.forEach((record, i) => {
//           merged[currentPage * perPage + i] = record;
//         });
//         console.log({
//           merged,
//         });
//         return merged;
//       },
//       read(existing: any[] | null, args: { variables: { page: number; first: number } }) {
//         const { page = REFETCH_QUERY_DEFAULT_PAGE, first = REFETCH_QUERY_FIRST_VARIABLE } =
//           args.variables;
//         console.log({
//           page,
//           first,
//           existing: existing && existing.slice((page - 1) * first, (page - 1) * first + first),
//         });
//         return existing && existing.slice((page - 1) * first, (page - 1) * first + first);
//       },
//     },
//   };
// }, {}) as {
//   [fieldName: string]: {
//     keyArgs: false;
//     merge: (
//       existing: any[] | null,
//       incoming: { data: any[]; paginatorInfo: any[] },
//       args: any
//     ) => any[];
//     read: (existing: any[] | null, args: any) => any[];
//   };
// };

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (graphQLErrors) {
    Sentry.captureMessage("GraphQL Error", {
      level: "debug",
      extra: {
        graphqlErrors: JSON.stringify(graphQLErrors),
      },
    });

    for (let err of graphQLErrors) {
      switch (err.message) {
        case "Unauthenticated.":
          const redirectTo = encodeURIComponent(
            `${document.location.pathname}${document.location.search}`.trim()
          );
          window.location.replace(`/signin?redirectTo=${redirectTo}`);
          return;
      }
    }
  }

  if (networkError) {
    Sentry.captureMessage(`${networkError}`, {
      level: "debug",
    });
  }
});
const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "network-only",
  },
  watchQuery: {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  },
};
export const createApolloClient = () => {
  return new ApolloClient({
    link: from([
      errorLink,
      createUploadLink({
        uri: import.meta.env.VITE_GRAPHQL_API_URL,
        credentials: "include",
      }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions,
  });
};

// {
//   typePolicies: {
//     Query: {
//       fields: {
//         getJobs: {
//           keyArgs: [
//             "$search",
//             "$companyId",
//             "$userId",
//             "$organizationProductTypeIds",
//             "$statuses",
//             "$sortDirection",
//             "$sortKey",
//           ],
//           // Concatenate the incoming list items with
//           // the existing list items.
//           merge(existing, incoming, { args: { first, page = 1 } }) {
//             console.log("merge", { existing, incoming, first, page });
//             let merged = existing ? existing.data.slice(0) : [];
//             const offset = (page - 1) * first;
//             for (let i = 0; i < incoming.data.length; ++i) {
//               merged[offset + i] = incoming.data[i];
//             }
//             return {
//               ...incoming,
//               data: merged,
//             };
//           },
//           read(existing, { args: { first, page = 1 } }) {
//             console.log("reading", { existing, first, page });
//             const offset = first * (page - 1);
//             const found = existing?.data?.slice(offset, offset + first);
//             if (!existing || !found || found?.length === 0 || found?.length !== first) {
//               console.log("undefined");
//               return undefined;
//             }
//             return { ...existing, data: found };
//           },
//         },
//       },
//     },
//   },
// }),
// }
