import getLocalString from "constants/Localization";
import { CustomGetProposalDocument, Proposal, useCloseProposalMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCloseProposal({ id }: { id: Proposal["id"] }) {
  const [mutation, { loading, error, data }] = useCloseProposalMutation();

  const [closeProposal, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "closeProposal",
    },
    {
      successMessage: getLocalString("proposals.close-proposal-success"),
      failureMessage: getLocalString("proposals.close-proposal-failure"),
    }
  );

  return [closeProposal, context] as const;
}
