/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, ReactNode } from "react";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import { useBitumioContext } from "providers/BitumioContext";
import MDAlert from "components/MDAlert";
import useGetOrganization from "hooks/organization/useGetOrganization";
import dayjs from "dayjs";

function DashboardLayout({ children }: { children: ReactNode }): JSX.Element {
  const [controller, dispatch] = useMaterialUIController();
  const [{ signedInUser }] = useBitumioContext();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const { data } = useGetOrganization();
  const date1 = data?.subscription?.trialEndsAt
    ? dayjs(new Date(data?.subscription?.trialEndsAt))
    : dayjs(new Date(data?.trialEndsAt));
  const dateDiff = date1.diff(Date.now(), "day");

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      data-testid="dashboard"
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {signedInUser?.organization?.failingPayment && (
        <Link to="/organization/subscription">
          <MDBox mb={2}>
            <MDAlert color="error" variant="caption">
              Your subscription payments are failing. Please click here to manage your subscription
            </MDAlert>
          </MDBox>
        </Link>
      )}

      {data?.onTrial && (
        <div
          style={{
            width: "100%",
            paddingBlock: "10px",
            backgroundColor: "#00CC55",
            textAlign: "center",
            color: "white",
            borderRadius: "5px",
            fontSize: "16px",
          }}
        >
          {dateDiff > 0 ? dateDiff + " days remaining in your free trial. " : "Free trial ended."}
          <Link to="/organization/subscription" style={{ color: "white", fontWeight: "bold" }}>
            Subscribe
          </Link>
        </div>
      )}
      {children}
    </MDBox>
  );
}

export default DashboardLayout;
