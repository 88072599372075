import { GetCheckoutUrlQuery, useGetCheckoutUrlLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetCheckoutUrl() {
  const [query, props] = useGetCheckoutUrlLazyQuery();

  const getUrl = useCallback((data: GetCheckoutUrlQuery) => {
    return data?.getCheckoutUrl || null;
  }, []);

  const getCheckoutUrl = useCallback(async () => {
    const response = await query();
    return getUrl(response.data);
  }, []);

  const url = useMemo(() => {
    return (props.data && getUrl(props.data)) || null;
  }, [props.data]);

  return [getCheckoutUrl, { ...props, getUrl, url }] as const;
}
