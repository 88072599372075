import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ActionConfirmDialog({
  open,
  setOpen,
  handleCancel,
  handleAccept,
  title,
  content,
  isAcceptDisabled = false,
  isCancelDisabled = false,
  acceptText = "Accept",
  cancelText = "Cancel",
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancel: () => void;
  handleAccept: () => void;
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  isAcceptDisabled?: boolean;
  isCancelDisabled?: boolean;
  acceptText?: string;
  cancelText?: string;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && (
        <DialogContent>
          {typeof content == "string" ? (
            <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
          ) : (
            content
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={handleCancel}
          disabled={isCancelDisabled}
          variant="contained"
          sx={{
            backgroundColor: "#F26419",
            color: "#FFF",
            ":hover": {
              backgroundColor: "#F26419",
            },
          }}
        >
          {cancelText}
        </Button>
        <Button
          onClick={handleAccept}
          disabled={isAcceptDisabled}
          variant="contained"
          sx={{
            backgroundColor: "#66BB6A",
            color: "#FFF",
            ":hover": {
              backgroundColor: "#66BB6A",
            },
          }}
        >
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
