import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateEquipmentPieceMutation,
  UpdateEquipmentPieceMutationVariables,
  useUpdateEquipmentPieceMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export default function useUpdateEquipmentPiece() {
  const [mutation, { data, loading, error }] = useUpdateEquipmentPieceMutation({
    refetchQueries: ["getEquipmentPieces", "customGetEquipmentPieces"],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateEquipmentPieceMutation) => {
    return (data && !!data.updateEquipmentPiece?.id) || false;
  }, []);

  const getEquipmentPiece = useCallback((data: UpdateEquipmentPieceMutation) => {
    return data && data.updateEquipmentPiece;
  }, []);

  const equipmentPiece = useMemo(() => {
    return getEquipmentPiece(data) || null;
  }, [data]);

  const updateEquipmentPiece = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          UpdateEquipmentPieceMutation,
          UpdateEquipmentPieceMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.update-equipment-piece-success"),
        failureMessage: getLocalString("organization.update-equipment-piece-failure"),
      }
    ),
    []
  );

  return [
    updateEquipmentPiece,
    { data, loading, error, isSuccess, equipmentPiece, getEquipmentPiece },
  ] as const;
}
