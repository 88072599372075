import { useCustomSetProposalToWonMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSetProposalToWonSilently() {
  const [mutation, { loading, error, data }] = useCustomSetProposalToWonMutation();

  const [setProposalToWonSilently, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "setProposalToWon",
    },
    {
      hideMessage: true,
    }
  );

  return [setProposalToWonSilently, context] as const;
}
