import { useForm } from "react-hook-form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useContext, useEffect, useMemo } from "react";
import StateProductContext from "../StageProductContext";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import useGetTransferTrucksLazy from "hooks/organization/useGetTransferTrucksLazy";
import TransferTrucksSubFormTableBody from "./TransferTrucksSubFormTableBody";
import {
  ProductAttributeSlurrySeal,
  ProductMeasurementJob,
  ProductProductionRateType,
  ProductType,
} from "generated/graphql";
import useGetOrganizationSettings from "hooks/organization/useGetOrganizationSettings";
import { snakeCase } from "change-case";

function TruckingTableSubForm({
  defaultMaterialNeeded,
  costTransferTruck,
}: {
  defaultMaterialNeeded: number;
  costTransferTruck: number;
}) {
  const { product } = useContext(StateProductContext);
  const [getTransferTrucks, { transferTrucks: orgTransferTrucks, loading }] =
    useGetTransferTrucksLazy();
  const { data, error } = useGetOrganizationSettings();
  const averageWorkingHoursPerDay = +data?.["average_working_hours_per_day"] ?? 0;

  const { watch } = useForm({});
  const materialNeededInTons = watch("material.materialNeeded");

  useEffect(() => {
    getTransferTrucks();
  }, []);

  const transferTrucksFields = useMemo(() => {
    return orgTransferTrucks.map((truck) => {
      const productTruck = product.transferTrucks.find((t) => t.transferTruckId === truck.id);
      const rateColumn = !!product.plantProduct?.plant?.rateOnWeight ? "rateTon" : "rateHourly";
      const defaultRateColumn = !!product.plantProduct?.plant?.rateOnWeight
        ? "defaultRateTon"
        : "defaultRateHourly";

      const tonnage = productTruck?.tonnage || truck.defaultTonnage;
      const dailyUnitsComplete =
        product.material.dailyUnitsCompleteOverride || product.material.dailyUnitsComplete;
      let productCompletionRatePerHour =
        +dailyUnitsComplete > 0 && averageWorkingHoursPerDay > 0
          ? +dailyUnitsComplete / averageWorkingHoursPerDay
          : 0;
      if (product.material.productionRateType === ProductProductionRateType.TONS_PER_HOUR) {
        productCompletionRatePerHour = +dailyUnitsComplete;
      }
      let truckingProductionRate =
        productTruck?.truckingProductionRate || Math.round(productCompletionRatePerHour);

      if (
        !productTruck?.truckingProductionRate &&
        product.material.productionRateType !== ProductProductionRateType.TONS_PER_HOUR
      ) {
        if (product.material.measurementJob === ProductMeasurementJob.SQUARE_YARD) {
          truckingProductionRate = Math.round(
            productCompletionRatePerHour * 9 * product.material.tonConversionFactor
          );
        } else {
          truckingProductionRate = Math.round(
            productCompletionRatePerHour * product.material.tonConversionFactor
          );
        }

        // for slurry and potentially microsurfacing https://app.clickup.com/t/8692mqk9h
        if (
          product.material.type === snakeCase(ProductType.SLURRY_SEAL) ||
          product.material.type === snakeCase(ProductType.MICROSURFACING)
        ) {
          const applicationRate =
            // @ts-ignore
            product.material.attributesOverride?.applicationRate ||
            // @ts-ignore
            product.material.attributes?.applicationRate;
          truckingProductionRate =
            (+dailyUnitsComplete * applicationRate) / 2000 / (averageWorkingHoursPerDay || 1);
        }
      }
      return {
        name: truck.name,
        quantity: productTruck?.quantity || 0,
        transferTruckId: truck.id,
        rate: productTruck?.[rateColumn] || truck[defaultRateColumn],
        rateOverride: productTruck?.[`${rateColumn}Override`] || null,
        totalHaulingHours: productTruck?.totalHaulingHours || null,
        totalHaulingHoursOverride: productTruck?.totalHaulingHoursOverride || null,
        costOverride: productTruck?.costOverride || null,
        cost: productTruck?.cost || null,
        tonnage,
        truckingProductionRate,
        productTransferTruckId: productTruck?.id || null,
      };
    });
  }, [orgTransferTrucks, product]);

  const { tonsTotal, difference, sign } = useMemo(() => {
    const tonsTotal = product.transferTrucks.reduce((acc, val) => {
      return acc + (val.quantity || 0) * (val?.tonnage ?? 0);
    }, 0);

    const difference = tonsTotal - (materialNeededInTons || defaultMaterialNeeded);

    const sign = difference >= 0 ? "+" : "";

    return { tonsTotal, difference, sign };
  }, []);

  const Tons = () => (
    <>
      {_.round(tonsTotal, 1)}
      <MDTypography component="span" variant="body2" color={difference > 0 ? "success" : "error"}>
        {` (${sign}${difference})`}
      </MDTypography>
    </>
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell>Truck</TableCell>
              <TableCell align="center">Tonnage</TableCell>
              <TableCell align="center">
                Truck Rate by {product.plantProduct?.plant?.rateOnWeight ? "Ton" : "Hour"}
              </TableCell>
              <TableCell align="center">Trucks Needed</TableCell>
              <TableCell align="center">Trucking Tons Per Hour</TableCell>
              <TableCell align="center">Total Hauling Hours</TableCell>
              <TableCell align="center">Total Trips Needed</TableCell>
              <TableCell align="center">Trips Per Truck </TableCell>
              <TableCell align="center">Total Tonnage Hauled</TableCell>
              {!product.plantProduct?.plant?.rateOnWeight && (
                <TableCell align="center">Total Cost</TableCell>
              )}
              {/* {!product.plantProduct?.plant?.rateOnWeight && (
                <TableCell align="center">Cost per Ton</TableCell>
              )} */}
              {product.plantProduct?.plant?.rateOnWeight && (
                <TableCell align="center">Truck Cost Weight/Ton</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && (
              <TransferTrucksSubFormTableBody
                transferTrucksFields={transferTrucksFields}
                product={product}
                productTransferTrucks={product.transferTrucks}
              />
            )}

            <TableRow>
              <TableCell>Total Trucking Cost</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">{formatCentsToUSD(costTransferTruck)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TruckingTableSubForm;
