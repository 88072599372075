import getLocalString from "constants/Localization";
import { useDeleteProjectFileMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteProjectFile() {
  const [mutation, { loading, error, data }] = useDeleteProjectFileMutation({
    refetchQueries: ["getProjectFiles"],
  });

  const [deleteProjectFile, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteProjectFile",
    },
    {
      successMessage: getLocalString("organization.delete-project-file-success"),
      failureMessage: getLocalString("organization.delete-project-file-failure"),
      showValidationErrors: true,
    }
  );

  return [deleteProjectFile, context] as const;
}
