import getLocalString from "constants/Localization";
import { useCustomUpdateProposalPlantProductMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateProposalPlantProduct() {
  const [mutation, { loading, error, data }] = useCustomUpdateProposalPlantProductMutation();

  const [updateProposalPlanProduct, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateProposalPlantProduct",
    },
    {
      successMessage: getLocalString("proposals.update-proposal-plant-product-success"),
      failureMessage: getLocalString("proposals.update-proposal-plant-product-failure"),
    }
  );

  return [updateProposalPlanProduct, context] as const;
}
