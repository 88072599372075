import { Card, Grid, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useGetOrganizationSettings from "hooks/organization/useGetOrganizationSettings";
import useUpdateOrganizationSettings from "hooks/organization/useUpdateOrganizationSettings";
import { mapObjIndexed } from "ramda";
import { useEffect, useState } from "react";

const OrganizationSettings = () => {
  const { data: settings, loading } = useGetOrganizationSettings();
  const { updateOrganizationSettings } = useUpdateOrganizationSettings();
  const [settingsData, setSettingsData] = useState<{
    proposal_recurrent_contact: number;
    average_working_hours_per_day: number;
  }>({ proposal_recurrent_contact: 1, average_working_hours_per_day: 1 });

  useEffect(() => {
    if (settings) {
      setSettingsData((prevValue) => ({
        ...prevValue,
        ...mapObjIndexed((value: string) => JSON.parse(value))(settings),
      }));
    }
  }, [settings]);

  const changeSetting = (key, value) => {
    setSettingsData({
      ...settingsData,
      [key]: Number(value),
    });
  };

  if (loading) {
    return <MDTypography variant="h6">Please wait...</MDTypography>;
  }

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
          updateOrganizationSettings(settingsData);
        }}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12}>
              <FormField
                disabled={!settings}
                type="number"
                label="Average Working Hours"
                value={settingsData?.average_working_hours_per_day ?? 1}
                inputProps={{ min: 1, max: 24 }}
                onChange={(e) => changeSetting("average_working_hours_per_day", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!settings}
                      checked={!!settingsData?.proposal_recurrent_contact}
                      onChange={(e) =>
                        changeSetting("proposal_recurrent_contact", e.target.checked)
                      }
                    />
                  }
                  label="Use Recurrent Contact for new Proposals"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <MDButton type="submit" variant="gradient" color="success">
              Save
            </MDButton>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default OrganizationSettings;
