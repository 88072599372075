import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateCrewInput,
  UpdateCrewMutation,
  UpdateCrewMutationVariables,
  useUpdateCrewMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export default function useUpdateCrew() {
  const [mutation, { data, loading, error }] = useUpdateCrewMutation();
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: UpdateCrewMutation) => {
    return (data && !!data.updateCrew?.id) || false;
  }, []);

  const getCrew = useCallback((data: UpdateCrewMutation) => {
    return data && data.updateCrew;
  }, []);

  const crew = useMemo(() => {
    return getCrew(data) || null;
  }, [data]);

  const updateCrew = useCallback(
    withMutationAction(
      async (props: MutationHookOptions<UpdateCrewMutation, UpdateCrewMutationVariables>) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.update-crew-success"),
        failureMessage: getLocalString("organization.update-crew-failure"),
      }
    ),
    []
  );

  return [updateCrew, { data, loading, error, isSuccess, crew, getCrew }] as const;
}
