import { useSaveQuickbooksIntegrationMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSaveQuickbooksIntegration() {
  const [mutation, { loading, data, error }] = useSaveQuickbooksIntegrationMutation();
  const [useSaveQuickbooksIntegration, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "useSaveQuickbooksIntegration",
    },
    {
      hideMessage: true,
    }
  );

  return [useSaveQuickbooksIntegration, context] as const;
}
