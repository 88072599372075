import { CustomUpdateProposalMutationVariables, ProposalSource } from "generated/graphql";
import { z } from "zod";

export const updateProposalSchema = z.object({
  id: z.string().min(1, "Please provide the proposal id"),
  companyId: z.string().min(1, "Please select a company"),
  contactId: z.string().min(1, "Please select a contact"),
  source: z.nativeEnum(ProposalSource).optional(),
});

export const getDefaultValues = (
  initialValues: Partial<CustomUpdateProposalMutationVariables>
): Partial<CustomUpdateProposalMutationVariables> => ({
  companyId: "",
  contactId: "",
  id: "",
  source: undefined,
  ...initialValues,
});
