import { AddCircle } from "@mui/icons-material";
import { FormGroup, FormControlLabel, Checkbox, CircularProgress, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Employee } from "generated/graphql";
import useModalState from "hooks/useModalState";
import EmployeeOptions from "modules/employees/EmployeeOptions";
import Modal from "modules/Modal/Modal";
import { useState } from "react";

interface EmployeeObject {
  id?: string;
  name?: string;
  rate?: number;
  timeOnjob?: number;
  timeTravel?: number;
}

export default function AddEmployee({
  onAdd,
  checked,
}: {
  onAdd: (employeeIds: Array<Employee>) => void;
  checked: Array<EmployeeObject>;
}) {
  const { open, onClose, onOpen } = useModalState();
  const [employees, setEmployees] = useState<Array<Employee>>([]);

  const toggleEmployee = (employee: Employee) => {
    setEmployees(
      employees.find((em) => em.id === employee.id)
        ? employees?.filter((em) => em.id !== employee.id)
        : [...employees, employee]
    );
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <MDBox p={3}>
          <MDTypography variant="h6" py={3}>
            Add Employee
          </MDTypography>

          <Box sx={{ width: "100%" }}>
            <EmployeeOptions>
              {({ employeeOptions, employeesById, loading }) => (
                <>
                  <FormGroup>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {employeeOptions.map((option, id) => (
                      <FormControlLabel
                        key={id}
                        control={
                          <Checkbox
                            defaultChecked={
                              checked.find((e) => e.id === option.value) !== undefined
                            }
                            onChange={() => {
                              const employee = employeesById[option.value];
                              toggleEmployee(employee);
                            }}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </FormGroup>

                  <MDBox textAlign="right">
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        onAdd(employees);
                        onClose();
                      }}
                      disabled={employees?.length === 0}
                    >
                      Add
                    </MDButton>
                  </MDBox>
                </>
              )}
            </EmployeeOptions>
          </Box>
        </MDBox>
      </Modal>
      <MDButton variant="gradient" startIcon={<AddCircle />} onClick={onOpen}>
        Add Employee
      </MDButton>
    </>
  );
}
