import MDAlert from "components/MDAlert";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import { Employee } from "generated/graphql";
import useEmployeesTable from "hooks/organization/employees/useEmployeesTable";

export default function ListEmployees() {
  const { loading, error, tableData, pagination, filtering } = useEmployeesTable();

  return (
    <>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && (
        <DataTable table={tableData} canSearch pagination={pagination} filtering={filtering} />
      )}
    </>
  );
}
