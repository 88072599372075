import MDAlert from "components/MDAlert";
import { CreateOrganizationProductTypeInput } from "generated/graphql";
import useCreateOrganizationProductType from "hooks/organization/product-types/useCreateOrganizationProductType";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getRoute } from "utils/routing";
import OrganizationProductTypesCreateForm from "./Forms/OrganizationProductTypesCreateForm";

export default function OrganizationProductTypesCreate() {
  const navigate = useNavigate();
  // mutation to create the product type
  const [createOrganizationProductType, { loading, error, isSuccess, getData }] =
    useCreateOrganizationProductType();

  // submit handler to create the product type
  const handleSubmit = useCallback(async (input: CreateOrganizationProductTypeInput) => {
    const response = await createOrganizationProductType({ variables: { input } });
    const success = isSuccess(response.data);
    if (success) {
      navigate(getRoute("organization.product-types.list"));
    }
    return { success, data: getData(response) };
  }, []);

  return (
    <>
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <OrganizationProductTypesCreateForm loading={loading} onSubmit={handleSubmit} />
    </>
  );
}
