import { MenuItem } from "@mui/material";
import { TransferTruck } from "generated/graphql";
import { Link } from "react-router-dom";

export default function Update({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: TransferTruck["id"];
}) {
  return (
    <MenuItem component={Link} onClick={closeMenu} to={`/organization/transfer-trucks/${id}`}>
      Update
    </MenuItem>
  );
}
