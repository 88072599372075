import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import PhoneInput from "components/PhoneInput/PhoneInput";
import StateSelect from "components/StateSelect/StateSelect";
import { CreatePlantInput } from "generated/graphql";
import { useFormContext, Controller } from "react-hook-form";

const MainForm = () => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<CreatePlantInput>();

  return (
    <>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item mb={2} xs={12} sm={6}>
            <FormField
              type="text"
              label="Name"
              placeholder="Name"
              error={errors.name}
              {...register("name", { required: true })}
            />
          </Grid>
          <Grid item mb={2} xs={12} sm={6}>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => <PhoneInput {...field} label="Phone" error={errors.phone} />}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item mb={2} xs={12} sm={6}>
            <FormField
              type="text"
              label="Address Line 1"
              placeholder="Address Line 1"
              error={errors.addressLine1}
              {...register("addressLine1", { required: true })}
            />
          </Grid>
          <Grid item mb={2} xs={12} sm={6}>
            <FormField
              type="text"
              label="Address Line 2"
              placeholder="Address Line 2"
              error={errors.addressLine2}
              {...register("addressLine2")}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item mb={2} xs={12} sm={6}>
            <FormField
              type="text"
              label="Address City"
              error={errors.addressCity}
              {...register("addressCity", { required: true })}
            />
          </Grid>
          <Grid item mb={2} xs={12} sm={6}>
            <StateSelect
              name="addressState"
              label="Address State"
              countryFieldName="addressCountry"
              control={control}
              setValue={setValue}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item mb={2} xs={12} sm={6}>
            <FormField
              type="text"
              label="Address Zip"
              error={errors.addressZip}
              {...register("addressZip", { required: true })}
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

export default MainForm;
