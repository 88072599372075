import { MenuItem } from "@mui/material";
import { Crew } from "generated/graphql";
import { Link } from "react-router-dom";

export default function Update({ closeMenu, id }: { closeMenu: () => void; id: Crew["id"] }) {
  return (
    <MenuItem
      data-test-id="action-update"
      component={Link}
      onClick={closeMenu}
      to={`/organization/crews/${id}`}
    >
      Update
    </MenuItem>
  );
}
