import useBitumioMutation from "hooks/useBitumioMutation";
import { useDeleteOrganizationApiKeyMutation } from "generated/graphql";

export const useDeleteOrganizationApiKey = () => {
  const [mutation, { data, loading, error }] = useDeleteOrganizationApiKeyMutation();
  const [deleteApiKey, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteApiKey",
    },
    {
      hideMessage: true,
    }
  );
  return [deleteApiKey, context] as const;
};
