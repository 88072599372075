import { Fragment } from "react";
import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import MDBox from "components/MDBox";
import { JobPhase } from "generated/graphql";
import Title from "./Title";
import pluralMeasurementMaterial from "../../../constants/pluralMeasurementMaterial";
import MDButton from "components/MDButton";
import { Delete } from "@mui/icons-material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { UpdateWorkOrderInputSchema } from "DDD/action-objects/WorkOrderItemsUpdate";
import EquipmentPieceOptions from "modules/equipmentPieces/EquipmentPieceOptions";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import CrewOptions from "modules/crews/CrewOptions";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import AddLabourCost from "modules/proposals/labourCosts/AddLabourCost";
import AddEquipmentCost from "modules/proposals/equipmentCosts/AddEquipmentCost";

export default function JobDetails({ jobPhase }: { jobPhase: JobPhase }) {
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<UpdateWorkOrderInputSchema>();

  const {
    fields: equipmentPieceFields,
    remove,
    append: equipmentPieceAppend,
  } = useFieldArray({
    control,
    name: "equipmentPieces",
  });
  const {
    fields: crewFields,
    remove: removeCrew,
    append: crewsAppend,
  } = useFieldArray({
    control,
    name: "crews",
  });

  return (
    <MDBox mb={2} className="no-break">
      <Card>
        <Title text="Job Phase Details" />
        <MDBox p={4}>
          <Grid container spacing={3} alignItems="flexStart">
            <Grid item xs={12} lg={6}>
              <MDBox mb={4}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head" align="center" width={"65%"}>
                          Material
                        </TableCell>
                        <TableCell variant="head" align="center">
                          Amount
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          {jobPhase?.proposalProduct?.productName}
                        </TableCell>
                        <TableCell align="center">
                          {jobPhase?.proposedMaterialNeeded}{" "}
                          {
                            pluralMeasurementMaterial[
                              jobPhase?.proposalProduct?.measurementMaterial
                            ]
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>

              <MDBox>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head" align="center" width={"65%"} colSpan={2}>
                          Labor
                        </TableCell>
                      </TableRow>
                      {crewFields?.map((crew, index) => (
                        <Fragment key={crew.id}>
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{
                                display: "none",
                                "@media print": {
                                  display: "flex",
                                  justifyContent: "center",
                                },
                              }}
                            >
                              {getValues(`crews.${index}.name`)}
                            </TableCell>
                            <TableCell className="no-print">
                              <CrewOptions>
                                {({ crewOptions }) => (
                                  <MDBox p={2}>
                                    <CustomSelect
                                      control={control}
                                      name={`crews.${index}.id`}
                                      selectVariant="standard"
                                      data={crewOptions}
                                      fullWidth
                                      {...register(`crews.${index}.id`)}
                                    />
                                  </MDBox>
                                )}
                              </CrewOptions>
                            </TableCell>
                            <TableCell align="center" className="no-print">
                              <MDBox display="flex" gap="2" justifyContent="flex-end">
                                <MDButton
                                  variant="text"
                                  color="error"
                                  type="button"
                                  onClick={() => removeCrew(index)}
                                >
                                  <Delete />
                                </MDButton>
                              </MDBox>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell colSpan={3} className="no-print">
                          <AddLabourCost
                            submitMsg="Add Labor"
                            onAdd={(laborIds) =>
                              laborIds?.forEach((id) => {
                                crewsAppend({
                                  id,
                                });
                              })
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
            </Grid>

            <Grid item xs={12} lg={6}>
              <MDBox mb={4}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head" align="center" width={"65%"} colSpan={2}>
                          Equipment
                        </TableCell>
                      </TableRow>
                      {equipmentPieceFields?.map((equipment, index) => (
                        <>
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{
                                display: "none",
                                "@media print": {
                                  display: "flex",
                                  justifyContent: "center",
                                },
                              }}
                            >
                              {getValues(`equipmentPieces.${index}.name`)}
                            </TableCell>
                            <TableCell align="center" className="no-print">
                              <EquipmentPieceOptions>
                                {({ equipmentPieceOptions, loading, search, pagination }) => (
                                  <Controller
                                    name={`equipmentPieces.${index}.id` as const}
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                      <ScrollAsyncAutocomplete
                                        {...field}
                                        value={{
                                          label:
                                            equipmentPieceOptions.find(
                                              (x) => x.value === field.value
                                            )?.label ??
                                            getValues(`equipmentPieces.${index}.name`) ??
                                            "",
                                          value: field.value,
                                        }}
                                        options={equipmentPieceOptions}
                                        loading={loading}
                                        label="Equipment Piece"
                                        search={search}
                                        onLoadMore={pagination.loadMore}
                                        hasMore={pagination.hasNextPage}
                                      />
                                    )}
                                  />
                                )}
                              </EquipmentPieceOptions>
                            </TableCell>
                            <TableCell align="center" className="no-print">
                              <MDBox display="flex" gap="2" justifyContent="flex-end">
                                <MDButton
                                  variant="text"
                                  color="error"
                                  onClick={() => remove(index)}
                                >
                                  <Delete />
                                </MDButton>
                              </MDBox>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell colSpan={3} className="no-print">
                          <AddEquipmentCost
                            submitMsg="Add Equipment"
                            isJobCosting
                            onAdd={(equipmentIds) => {
                              equipmentIds?.forEach((ep, i) => {
                                equipmentPieceAppend({
                                  id:
                                    typeof ep === "string" || typeof ep === "number" ? ep : ep?.id,
                                  name: ep?.name,
                                });
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>

              {jobPhase?.proposalProduct?.additionalCosts.length > 0 && (
                <MDBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head" align="center" width={"65%"}>
                            Additional Cost
                          </TableCell>
                          <TableCell variant="head" align="center">
                            Quantity
                          </TableCell>
                        </TableRow>
                        {jobPhase?.proposalProduct?.additionalCosts?.map((additionalCost) => (
                          <>
                            <TableRow>
                              <TableCell align="center">{additionalCost?.name}</TableCell>
                              <TableCell align="center">
                                {additionalCost?.quantityOverride || additionalCost?.quantity}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}
