import { Card, Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import getLocalString from "constants/Localization";
import {
  OrganizationEmailTemplate,
  OrganizationEmailTemplateType,
  SendOrganizationEmailTemplatePreviewMutationVariables,
  UpdateOrganizationEmailTemplateMutationVariables,
} from "generated/graphql";
import useGetOrganizationEmailTemplate from "hooks/organization/useGetOrganizationEmailTemplate";
import useGetOrganizationEmailTemplates from "hooks/organization/useGetOrganizationEmailTemplates";
import useSendOrganizationEmailTemplatePreview from "hooks/organization/useSendOrganizationEmailTemplatePreview";
import useUpdateOrganizationEmailTemplate from "hooks/organization/useUpdateOrganizationEmailTemplate";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import OrganizationEmailTemplateForm from "modules/OrganizationEmailTemplateForm/OrganizationEmailTemplateForm";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import BitumioContext from "providers/BitumioContext";
import { useCallback, useContext } from "react";

export default function EmailSettingsPage() {
  const [updateOrganizationEmailTemplate, context1] = useUpdateOrganizationEmailTemplate();

  const [sendOrganizationEmailTemplatePreview, context2] =
    useSendOrganizationEmailTemplatePreview();

  const { signedInUser } = useContext(BitumioContext);
  const { data, loading, error } = useGetOrganizationEmailTemplate({
    type: signedInUser.organization.emailTemplate as OrganizationEmailTemplateType,
  });

  const onSubmit = useCallback(
    async (variables: UpdateOrganizationEmailTemplateMutationVariables) => {
      const result = await updateOrganizationEmailTemplate({ variables });
      const success = context1.isSuccess(result.data);
      return { success, data: context1.data };
    },
    [updateOrganizationEmailTemplate]
  );
  const onSubmitPreview = useCallback(
    async (variables: SendOrganizationEmailTemplatePreviewMutationVariables) => {
      const result = await sendOrganizationEmailTemplatePreview({ variables });
      const success = context2.isSuccess(result.data);
      return { success, data: context2.data };
    },
    [sendOrganizationEmailTemplatePreview]
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Email Settings
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Grid container spacing={2}>
        {error && (
          <Grid item xs={12}>
            <MDAlert color="error">
              {getLocalString("organization.get-email-template-failed")}
            </MDAlert>
          </Grid>
        )}
        <Grid item xs={12}>
          {!loading && (
            <OrganizationEmailTemplateForm
              emailTemplate={data}
              onSubmit={onSubmit}
              loading={context1.loading || context2.loading || loading}
              onSubmitPreview={onSubmitPreview}
            />
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
