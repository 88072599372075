import { JobPhaseCostItem } from "generated/graphql";
import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import * as z from "zod";
import { pushIfNotExists } from "helpers/functions";

export const schema = z.object({
  jobPhaseId: z.string().min(1, "Job phase id is required"),
  plantId: z.string().optional(),
  plantProductId: z.string().optional(),
  schedules: z.array(
    z.object({
      id: z.string().min(1, "Schedule id is required"),
      estimatedMaterialOverride: z.number().optional(),
    })
  ),
  equipmentPieces: z.array(
    z.object({
      id: z.string().min(1, "Equipment Piece Id is Required"),
      name: z.string().optional(),
    })
  ),
  crews: z.array(
    z.object({
      id: z.string().min(1, "Crew Id is Required"),
      name: z.string().optional(),
    })
  ),
  transferTrucks: z.array(
    z.object({
      id: z.string().min(1, "Transfer truck Id is Required"),
      quantity: z.number().optional(),
    })
  ),
});

export type UpdateWorkOrderInputSchema = z.infer<typeof schema>;

export function cleanUpdateWorkOrderInput(values: UpdateWorkOrderInputSchema) {
  const cleanParams = {
    ...values,
    jobPhaseId: values.jobPhaseId,
    crews: values.crews.map((e) => ({
      id: e.id,
      name: e.name,
    })),
    schedules: values.schedules?.map((e) => ({
      id: e.id,
      estimatedMaterialOverride: e.estimatedMaterialOverride,
    })),
    equipmentPieces: values.equipmentPieces.map((e) => ({
      id: e.id,
      name: e.name,
    })),
    transferTrucks: values.transferTrucks?.map((e) => ({
      id: e.id,
      quantity: e.quantity,
    })),
  };

  return cleanParams;
}

export const getDefaultValues = ({
  jobPhase,
  costItem,
}: {
  jobPhase: CustomJobPhase;
  costItem?: JobPhaseCostItem;
}) => {
  const product = jobPhase?.proposalProduct;
  const plantId =
    jobPhase?.jobPhasePlant?.plant?.id || jobPhase?.proposalProduct?.plantProduct?.plant?.plantId;
  const plantProductId =
    jobPhase?.jobPhasePlant?.plantProduct?.id ||
    jobPhase?.proposalProduct?.plantProduct?.plantProductId;

  const equipmentPieces = jobPhase?.workOrderEquipmentPieces?.length
    ? pushIfNotExists(
        jobPhase?.workOrderEquipmentPieces,
        costItem?.equipmentPieces ?? [],
        "equipmentPieceId"
      )
    : jobPhase?.proposalProduct?.equipmentPieces;
  const crews = jobPhase?.workOrderCrews?.length
    ? jobPhase?.workOrderCrews
    : jobPhase?.proposalProduct?.crews;
  const transferTrucks = jobPhase?.workOrderTransferTrucks?.length
    ? pushIfNotExists(
        jobPhase?.workOrderTransferTrucks,
        costItem?.transferTrucks ?? [],
        "transferTruckId"
      )
    : product?.transferTrucks;
  return {
    jobPhaseId: jobPhase.id,

    equipmentPieces: equipmentPieces?.map((equipmentPiece) => ({
      id: equipmentPiece?.equipmentPieceId ?? equipmentPiece?.id,
      name: equipmentPiece.name,
    })),
    schedules:
      jobPhase?.scheduleBlocks?.map((schedule) => ({
        id: schedule.id,
        estimatedMaterialOverride: schedule.estimatedMaterialOverride || schedule.estimatedMaterial,
      })) ?? [],
    crews:
      crews?.map((crew) => ({
        id: crew?.crewId ?? crew.id,
        name: crew.name,
      })) ?? [],
    transferTrucks:
      transferTrucks?.map((truck) => ({
        id: truck?.transferTruckId ?? truck?.id,
        quantity: truck?.trucksNeeded || truck?.quantity || 0,
      })) ?? [],
    plantId,
    plantProductId,
  };
};

// import { JobPhase, JobPhaseCostItem } from "generated/graphql";
// import * as z from "zod";

// export const JobPhaseCostItemInputResolver = z.object({
//   /* end local values */
//   jobPhaseId: z.string().min(1, "Job phase id is required"),
//   plantId: z.string(),
//   plantProductId: z.string(),
//   materialNeeded: z.literal("").or(z.number()),
//   equipmentPieces: z.array(
//     z.object({
//       id: z.string().min(1, "Equipment Piece Id is Required"),
//       name: z.string().optional(),
//       quantity: z.number(),
//       rate: z.number(),
//       timeOnjob: z.number(),
//       timeTravel: z.number(),
//     })
//   ),
//   additionalCosts: z.array(
//     z.object({
//       id: z.string().min(1, "Additional Cost Id is Required"),
//       name: z.string().optional(),
//       cost: z.number(),
//       feeAmount: z.number(),
//     })
//   ),
//   employees: z.array(
//     z.object({
//       id: z.string().min(1, "Employee Id is Required"),
//       name: z.string().optional(),
//       rate: z.number(),
//       timeOnjob: z.number(),
//       timeTravel: z.number(),
//     })
//   ),
//   transferTrucks: z.array(
//     z.object({
//       id: z.string().min(1, "Plant Transfer Truck Id is Required"),
//       name: z.string().optional(),
//       tonnage: z.number(),
//       rateHourly: z.number(),
//       rateTon: z.number(),
//       hours: z.number(),
//     })
//   ),
// });

// export type JobPhaseCostItemInputSchema = z.infer<typeof JobPhaseCostItemInputResolver>;

// export const getDefaultValues = ({
//   jobPhase,
//   jobPhaseCostItem,
// }: {
//   jobPhase: JobPhase;
//   jobPhaseCostItem: JobPhaseCostItem;
// }): JobPhaseCostItemInputSchema => {
//   const equipmentPieces = jobPhaseCostItem?.equipmentPieces ?? jobPhase?.defaultEquipmentPieces;
//   const employees = jobPhaseCostItem?.employees ?? jobPhase?.defaultEmployees;

//   return {
//     jobPhaseId: jobPhase.id,
//     plantId: jobPhaseCostItem?.plantId ?? null,
//     plantProductId: jobPhaseCostItem?.plantProductId ?? null,
//     materialNeeded: jobPhase.proposedMaterialNeeded ?? 0,
//     equipmentPieces: equipmentPieces.map((equipmentPiece) => ({
//       id: equipmentPiece?.equipmentPieceId ?? equipmentPiece?.id,
//       name: equipmentPiece.name,
//       quantity: equipmentPiece.quantity ?? 1,
//       rate: equipmentPiece.rate,
//       timeTravel: 0,
//       timeOnjob: 0,
//     })),
//     additionalCosts:
//       jobPhaseCostItem?.additionalCosts?.map((additionalCost) => ({
//         id: additionalCost?.additionalCostId,
//         name: additionalCost?.name,
//         cost: 0,
//         feeAmount: 0,
//       })) ?? [],
//     employees:
//       employees?.map((employee) => ({
//         id: employee?.employeeId ?? employee.id,
//         name: employee.name,
//         rate: employee.rate,
//         timeOnjob: 0,
//         timeTravel: 0,
//       })) ?? [],
//     transferTrucks:
//       jobPhaseCostItem?.transferTrucks?.map((jobPhasePlantTransferTruck) => {
//         return {
//           id: jobPhasePlantTransferTruck?.plantTransferTruckId,
//           name: jobPhasePlantTransferTruck?.name,
//           tonnage: jobPhasePlantTransferTruck?.tonnage,
//           rateHourly: jobPhasePlantTransferTruck?.rateHourly,
//           rateTon: jobPhasePlantTransferTruck?.rateTon,
//           hours: 0,
//         };
//       }) ?? [],
//   };
// };
