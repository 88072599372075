import getLocalString from "constants/Localization";
import MDAlert from "components/MDAlert";
import TransferTruckForm from "components/organization/TransferTruckForm";
import { UpdateTransferTruckInput, TransferTruck } from "generated/graphql";
import useUpdateTransferTruck from "hooks/organization/useUpdateTransferTruck";
import { useCallback } from "react";

export default function Update({ transferTruck }: { transferTruck: TransferTruck }) {
  const [
    updateTransferTruck,
    { loading, error, transferTruck: updatedTransferTruck, isSuccess, getTransferTruck },
  ] = useUpdateTransferTruck();

  const handleSubmit = useCallback(async (input: UpdateTransferTruckInput) => {
    const { data } = await updateTransferTruck({ variables: { input } });
    const success = isSuccess(data);
    return { success, data: getTransferTruck(data) };
  }, []);

  return (
    <>
      {error && (
        <MDAlert color="error">
          {getLocalString("organization.update-transfer-truck-failure")}
        </MDAlert>
      )}
      <TransferTruckForm
        transferTruck={(updatedTransferTruck ?? transferTruck) as TransferTruck}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </>
  );
}
