import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { EquipmentPiece } from "generated/graphql";
import useGetEquipmentPiece from "hooks/organization/useGetEquipmentPiece";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Update from "modules/OrganizationEquipmentPieces/Update";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function EquipmentPieceEditPage() {
  const { equipmentPieceId } = useParams();
  const [getEquipmentPiece, { loading, error, equipmentPiece }] = useGetEquipmentPiece();

  useEffect(() => {
    getEquipmentPiece({ id: equipmentPieceId });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={12} mb={3}>
        {loading && <>Loading</>}
        {error && (
          <MDAlert color="error">
            Could not load equipment piece details. Please refresh this page or try again later.
          </MDAlert>
        )}
        {!error && (
          <>
            <MDBox mb={6}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <MDTypography variant="h4" fontWeight="medium">
                    Updating Equipment Piece
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {equipmentPiece && <Update equipmentPiece={equipmentPiece as EquipmentPiece} />}
                {!equipmentPiece && <Skeleton />}
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
