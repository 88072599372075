import { capitalCase } from "change-case";
import { useGetOpportunityStatusesQuery, useGetPlantProductTypesQuery } from "generated/graphql";
import { snakeCase } from "lodash";
import { useMemo } from "react";

export default function useGetPlantProductTypes() {
  const { loading, data, error } = useGetPlantProductTypesQuery();

  const selectOptions = useMemo((): { label: string; value: string }[] | null => {
    return (
      data?.getPlantProductTypes?.map(({ id, name }) => ({
        label: name,
        value: name.toLowerCase(),
      })) || null
    );
  }, [data]);

  return { loading, data: data?.getPlantProductTypes || null, error, selectOptions };
}
