import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ProposalStage } from "generated/graphql";
import React from "react";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

const StageSummary = React.memo(function StageProductContainer({
  stage,
}: {
  stage: ProposalStage;
}) {
  function getStageSummary(key: string | number) {
    if (!isNaN(stage[key]) && typeof stage[key] === "number") {
      return stage[key];
    }

    const total = stage.proposalProducts.reduce((acc, product) => {
      const price = parseFloat(product[key]) || 0;
      return acc + price;
    }, 0);

    return total;
  }

  return (
    <MDBox py={3}>
      <MDBox display="flex" justifyContent="space-between" my={3}>
        <MDTypography variant="h6">Stage Summary</MDTypography>
      </MDBox>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell align="right">Direct Costs</TableCell>
              <TableCell align="right">Overhead Costs</TableCell>
              <TableCell align="right">Indirect Costs</TableCell>
              <TableCell align="right">Total Cost</TableCell>
              <TableCell align="right">Profit({getStageSummary("profitPercentage")}%)</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="right">{formatCentsToUSD(getStageSummary("costDirect"))}</TableCell>
              <TableCell align="right">
                {formatCentsToUSD(getStageSummary("costOverhead"))}
              </TableCell>
              <TableCell align="right">
                {formatCentsToUSD(getStageSummary("costIndirect"))}
              </TableCell>
              <TableCell align="right">{formatCentsToUSD(getStageSummary("costTotal"))}</TableCell>
              <TableCell align="right">{formatCentsToUSD(getStageSummary("profit"))}</TableCell>
              <TableCell align="right">
                {formatCentsToUSD(getStageSummary("overallTotal"))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );
});

export default StageSummary;
