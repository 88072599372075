import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { CustomJobPhaseCostItem } from "hooks/jobs/job-phases/useGetJobPhaseCostItem";
import React from "react";

export interface JobPhaseContextValues {
  jobPhase: CustomJobPhase;
  jobPhaseCostItem: CustomJobPhaseCostItem;
  date: string;
}

export const JobPhaseContext = React.createContext<JobPhaseContextValues>(
  {} as JobPhaseContextValues
);
