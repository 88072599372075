import { Divider } from "@mui/material";
import { InfoItem } from "components/InfoItem/InfoItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Company } from "generated/graphql";
import React from "react";
import { getSecondaryContactsInfo } from "utils/contacts/getSecondaryContactsInfo";

interface CompanyOtherContacts {
  contacts: Company["contacts"];
  icon: React.ReactNode;
}

export const CompanyOtherContacts: React.FC<CompanyOtherContacts> = ({ contacts, icon }) => {
  const details = getSecondaryContactsInfo(contacts);

  return (
    <MDBox width="100%">
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Other Contacts
        </MDTypography>
        {icon}
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {details.map((item) => (
            <InfoItem direction="column" key={item.label} item={item} />
          ))}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
