import { CountryCode } from "constants/CountriesList";
import { useMemo, useState } from "react";
import { countries, countriesByCode } from "constants/CountriesList";

export default function useAddress(countryCode: CountryCode | null) {
  const [countrySelectOptions] = useState<{ label: string; value: string }[]>(() => {
    const options = Object.keys(countries).map((country) => ({
      label: country,
      value: countries[country].code,
    }));
    return options;
  });

  const provinceSelectOptions = useMemo(() => {
    if (!countryCode) {
      return [];
    }
    const country = countriesByCode[countryCode] || countries[countryCode]; // imported companies might use country names instead of country codes. "United States" vs "US"
    if (!country) {
      return [];
    }

    const provinces = country.provinces ?? [];

    return Object.keys(provinces).map((key) => ({
      label: key,
      value: provinces[key].code,
    }));
  }, [countryCode]);

  return {
    countries: {
      selectOptions: countrySelectOptions,
    },
    provinces: {
      selectOptions: provinceSelectOptions,
    },
  };
}
