import {
  Company,
  GetCompanyQuery,
  useGetCompanyLazyQuery,
  useGetCompanyQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetCompanyLazy = () => {
  const [query, { data: graphqlData, loading, error }] = useGetCompanyLazyQuery();

  const getCompany = useCallback(
    ({ id }: { id: Company["id"] }) => {
      query({ variables: { id } });
    },
    [query]
  );

  const company = graphqlData?.getCompany || null;

  return [getCompany, { loading, error, company }] as const;
};
