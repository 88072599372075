import getLocalString from "constants/Localization";
import { useCustomUpdateJobPhaseNotesMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateJobPhaseNotes() {
  const [mutation, { loading, data, error }] = useCustomUpdateJobPhaseNotesMutation();

  const [updateJobPhaseNotes, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateJobPhaseNotes",
    },
    {
      successMessage: getLocalString("jobPhases.update-notes-success"),
      failureMessage: getLocalString("jobPhases.update-notes-failure"),
    }
  );

  return [updateJobPhaseNotes, context] as const;
}
