import { EquipmentPiece, EquipmentPieceChargeType } from "generated/graphql";
import * as z from "zod";

export const schema = z.object({
  id: z.string().optional().or(z.null()),
  name: z.string().min(1, { message: "Name is required" }),
  chargeType: z.nativeEnum(EquipmentPieceChargeType, { invalid_type_error: "Required" }),
  rate: z.coerce.number({ invalid_type_error: "Required" }).min(0, "Rate is required"),
  fuelSurcharge: z.coerce.number().min(0).optional(),
  usePercentage: z.coerce
    .number({ invalid_type_error: "Required" })
    .min(1, "Use Percentage should be greater than 0")
    .max(100, "Use Percentage maximum is 100"),
});

export const defaultValues = {
  name: "",
  chargeType: null,
  rate: 0,
  fuelSurcharge: 0,
  usePercentage: 1,
};

export function getDefaultValues(equipmentPiece: EquipmentPiece) {
  return {
    ...defaultValues,
    ...equipmentPiece,
    rate: equipmentPiece?.rate / 100,
    fuelSurcharge: equipmentPiece?.fuelSurcharge / 100,
  };
}
