import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import useGetJobPhase from "hooks/jobs/job-phases/useGetJobPhase";
import View from "modules/JobPhases/View";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useParams, useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function JobCostPage() {
  const { jobId, jobPhaseId } = useParams();
  const { loading, error, data: jobPhase } = useGetJobPhase({ id: jobPhaseId });

  const navigate = useNavigate();
  const addPhaseCostCompleted = () =>
    navigate(getRoute("jobs.view", [["jobId", jobPhase?.job?.id]]));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load job phase material detail. Please refresh this page or try again later.
        </MDAlert>
      )}
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Job #{jobPhase?.job?.externalId || ""} {">"} {jobPhase?.organizationProductTypeName}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {!loading && jobPhase && (
              <View jobPhase={jobPhase} addPhaseCostCompleted={addPhaseCostCompleted} />
            )}
            {loading && <Skeleton />}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
