import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { UpdateNotificationTriggerInput } from "generated/graphql";
import useGetNotificationTrigger from "hooks/notifications/useGetNotificationTrigger";
import useUpdateNotificationTrigger from "hooks/notifications/useUpdateNotificationTrigger";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import NotificationTriggerForm from "modules/NotificationTriggerForm/NotificationTriggerForm";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

export default function NotificationsEdit() {
  const [updateNotificationTrigger, { isSuccess }] = useUpdateNotificationTrigger();
  const { notificationId } = useParams();
  const { error, data, loading } = useGetNotificationTrigger({ id: notificationId });
  const navigate = useNavigate();

  const submitFunction = useCallback(
    async (input: UpdateNotificationTriggerInput) => {
      const result = await updateNotificationTrigger({
        variables: {
          input: {
            ...input,
            id: notificationId,
          },
        },
      });

      if (result.success) {
        navigate("/email-triggers");
      }
    },
    [updateNotificationTrigger]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Update Trigger
            </MDTypography>
          </MDBox>
          {!loading && data && (
            <NotificationTriggerForm onSubmit={submitFunction} notificationTrigger={data} />
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
