import { camelCase, capitalCase, sentenceCase } from "change-case";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DashboardMapData, Job } from "generated/graphql";
import { useEffect, useMemo } from "react";

export default function Map({ data }: { data: Job[] }) {
  const icons: Record<string, { icon: string }> = {
    complete: {
      icon: "/assets/images/complete.png",
    },
    inProgress: {
      icon: "/assets/images/in-progress.png",
    },
    jobCosting: {
      icon: "/assets/images/job-costing.png",
    },
    scheduled: {
      icon: "/assets/images/scheduled.png",
    },
    toBeScheduled: {
      icon: "/assets/images/to-be-scheduled.png",
    },
  };

  const iconMap = useMemo(() => {
    const iconMapData = [];
    for (const [key, value] of Object.entries(icons)) {
      iconMapData.push({ name: key, img: value.icon });
    }
    return iconMapData;
  }, []);

  const initMap = async () => {
    const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: new google.maps.LatLng(37.09024, -95.712891),
      zoom: 4,
    });
    // Create markers.
    for (let i = 0; i < data?.length; i++) {
      const {
        id,
        proposalStage: { name },
        jobPhases,
        address,
      } = data[i];

      const jobPhasesDetails = jobPhases.reduce((a, c) => {
        return (
          a +
          `
            <div>
              <b>Phase Name:</b> ${c.productName}<br />
              <b>Phase Status:</b> ${capitalCase(c.status)}<br />
              <b>Phase Start Date:</b> ${c.startsAt || ""}
            </div>
            <br />
        `
        );
      }, "");

      const content = `
        <div>
          <div><b>Job: [#${id}] ${name}</b></div>
          <br />
          <b>Phases</b>
          <br />
          ${jobPhasesDetails}
        </div>
      `;

      const infowindow = new google.maps.InfoWindow({
        content: content,
        maxWidth: 300,
        ariaLabel: name,
      });

      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(+address.latitude, +address.longitude),
        icon: icons[camelCase(data?.[i].status)]?.icon,
        map: map,
      });

      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
        });
      });
    }
  };

  useEffect(() => {
    initMap();
  }, [data]);

  return (
    <MDBox>
      {iconMap.map((icon) => (
        <>
          <img key={icon.name} src={icon.img} style={{ height: 15, width: 15 }} />
          <MDTypography display="inline" mr={2} ml={1} fontSize={14}>
            {capitalCase(icon.name)}
          </MDTypography>
        </>
      ))}

      <div id="map" style={{ width: "100%", height: "550px", borderRadius: "10px" }}></div>
    </MDBox>
  );
}
