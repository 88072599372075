import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import OrganizationProductTypesList from "modules/OrganizationProductTypes/OrganizationProductTypesList";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function OrganizationProductTypesPage() {
  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Organization Product Types
            </MDTypography>
          </Grid>
          <Grid item xs={12} lg={6} display="flex" justifyContent="flex-end">
            <MDButton
              component={Link}
              type="button"
              to={getRoute("product-types.create")}
              color="blue"
            >
              Create Product Type
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OrganizationProductTypesList />
        </Grid>
      </Grid>
    </DashboardPage>
  );
}
