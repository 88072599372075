import MDButton from "components/MDButton";

const ReorderStageProducts = ({
  setExpandedProduct,
  setSortMode,
  disabled,
}: {
  setExpandedProduct: (expandedProduct: string | false) => void;
  setSortMode: (mode: boolean) => void;
  disabled: boolean;
}) => {
  return (
    <MDButton
      color="success"
      onClick={() => {
        setExpandedProduct(false);
        setSortMode(true);
      }}
      disabled={disabled}
    >
      Reorder Stage Products
    </MDButton>
  );
};

export default ReorderStageProducts;
