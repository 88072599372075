import { ResetPasswordInput, useResetPasswordMutation } from "generated/graphql";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import isValidEmail from "utils/validations/isValidEmail";

export default function useResetPassword() {
  const [getSearchParams] = useSearchParams();
  const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation();
  const email = getSearchParams.get("email");
  const token = getSearchParams.get("token");

  const resetPassword = useCallback(
    (values: ResetPasswordInput) => {
      resetPasswordMutation({
        variables: {
          input: values,
        },
      });
    },
    [resetPasswordMutation]
  );

  const success = data && data.resetPassword === true;
  const failure = data && data.resetPassword === false;
  const invalid = !isValidEmail(email ?? "") || !token;

  return [
    resetPassword,
    { data, loading, error, success, failure, email, token, invalid },
  ] as const;
}
