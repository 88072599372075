import {
  GetCompaniesQuery,
  GetCompaniesQueryVariables,
  useGetCompaniesQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetCompanies";

export default function useGetCompanies(variables: GetCompaniesQueryVariables) {
  const {
    data: graphqlData,
    loading,
    error,
    fetchMore,
    refetch,
  } = useGetCompaniesQuery({
    variables,
  });

  const getData = useCallback(({ data }: { data?: GetCompaniesQuery }) => {
    return data?.getCompanies?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: GetCompaniesQuery }) => {
    return data?.getCompanies?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return {
    data,
    loading,
    error,
    paginatorInfo,
    fetchMore,
    getData,
    getPaginatorInfo,
    refetch,
  } as const;
}
