import {
  Card,
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import timeEndAdornment from "constants/timeEndAdornment";
import { JobPhaseCostItemInputSchema } from "DDD/action-objects/JobPhaseCostItemCreate";
import { Employee } from "generated/graphql";
import AddEmployee from "modules/JobPhases/employees/AddEmployee";
import { useFieldArray, useFormContext } from "react-hook-form";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

const calculateTotal = (employee: JobPhaseCostItemInputSchema["employees"][number]) => {
  if (!employee) {
    return 0;
  }
  //@ts-ignore
  const total = (parseInt(employee.timeTravel) + parseInt(employee.timeOnjob)) * employee.rate;

  return isNaN(total) ? 0 : total;
};

function EmployeeTotal({ fieldName }: { fieldName: string }) {
  const { watch } = useFormContext();
  const employee = watch(fieldName);

  return <span>{formatCentsToUSD(calculateTotal(employee))}</span>;
}

export default function EmployeeSection() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<JobPhaseCostItemInputSchema>();
  const { fields, append, remove } = useFieldArray({
    name: "employees",
    keyName: "key",
    control: control,
  });
  const onAdd = (newEmployees: Array<Employee>) => {
    const filteredNewEmployees = newEmployees.filter(
      (employee) => !fields.find((existingEmployee) => existingEmployee.id === employee.id)
    );
    filteredNewEmployees.forEach((employee) => {
      append({
        id: employee.id,
        name: employee.name,
        rate: employee.rate,
      });
    });
  };

  return (
    <Card>
      <MDBox p={4}>
        <MDTypography>Employees</MDTypography>
        {errors.employees?.message && (
          <MDBox my={2}>
            <FormHelperText error>{errors.employees.message}</FormHelperText>
          </MDBox>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell sx={{ minWidth: "150px", width: "20%" }}>Name</TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "20%" }}>
                    Travel Time
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "20%" }}>
                    On Job Time
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "20%" }}>
                    Total
                  </TableCell>
                  <TableCell sx={{ minWidth: "150px", width: "20%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields?.map((employee, i) => {
                  return (
                    <TableRow
                      key={employee.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <input type="hidden" value={employee.id} {...register(`employees.${i}.id`)} />
                      <TableCell>{employee?.name ?? ""}</TableCell>
                      <TableCell align="right">
                        <FormField
                          align="right"
                          type="number"
                          placeholder={fields[i]?.timeTravel}
                          defaultValue={0}
                          inputProps={{ step: "0.01" }}
                          mb={0}
                          sx={{
                            "& .MuiInputBase-root": {
                              "& input": {
                                textAlign: "right",
                              },
                            },
                          }}
                          {...timeEndAdornment}
                          {...register(`employees.${i}.timeTravel`)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FormField
                          align="right"
                          type="number"
                          placeholder={fields[i]?.timeOnjob}
                          defaultValue={0}
                          mb={0}
                          inputProps={{ step: "0.01" }}
                          sx={{
                            "& .MuiInputBase-root": {
                              "& input": {
                                textAlign: "right",
                              },
                            },
                          }}
                          {...timeEndAdornment}
                          {...register(`employees.${i}.timeOnjob`)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <EmployeeTotal fieldName={`employees.${i}`} />
                      </TableCell>
                      <TableCell align="right">
                        <MDButton
                          type="button"
                          variant="text"
                          color="error"
                          onClick={() => remove(i)}
                        >
                          Remove
                        </MDButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell colSpan={6}>
                    <AddEmployee onAdd={onAdd} checked={fields} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
