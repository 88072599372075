import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  UpdateOrganizationProposalContentMutation,
  UpdateOrganizationProposalContentMutationVariables,
  useUpdateOrganizationProposalContentMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";
export default function useUpdateOrganizationProposalContent() {
  const [mutation, values] = useUpdateOrganizationProposalContentMutation();

  const withMutationAction = useMutationAction();
  const isSuccess = (data: UpdateOrganizationProposalContentMutation) => {
    return !!data?.updateOrganizationProposalContent;
  };

  const getData = useCallback(({ data }: { data?: UpdateOrganizationProposalContentMutation }) => {
    return data?.updateOrganizationProposalContent ?? null;
  }, []);

  const updateProposalSettings = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          UpdateOrganizationProposalContentMutation,
          UpdateOrganizationProposalContentMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.update-proposal-content-success"),
        failureMessage: getLocalString("organization.update-proposal-content-failure"),
      }
    ),
    [mutation]
  );
  const success = useMemo(() => {
    return isSuccess(values.data);
  }, [values.data]);

  const data = useMemo(() => {
    return getData({ data: values.data });
  }, [values.data]);
  return [
    updateProposalSettings,
    { data, error: values.error, loading: values.loading, success, isSuccess, getData },
  ] as const;
}
