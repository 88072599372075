import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

export default function ActionMenuItem({
  closeMenu,
  route,
  text,
}: {
  closeMenu: () => void;
  route: string;
  text: string;
}) {
  return (
    <MenuItem component={Link} onClick={closeMenu} to={route}>
      {text}
    </MenuItem>
  );
}
