import { Grid } from "@mui/material";
import { headerCase } from "change-case";
import FormField from "components/FormField/FormField";
import { calculateAreaMappings } from "DDD/action-objects/ProductCreate";
import {
  OrganizationProductType,
  ProductMeasurementJob,
  ProductMeasurementMaterial,
  ProductType,
} from "generated/graphql";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  useFormContext,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import ProductTypeFields from "./ProductTypeFields";

interface AreaCoverageContextValues {
  calculateAreaCoverage: (
    getValues: UseFormGetValues<FieldValues>,
    setValue: UseFormSetValue<FieldValues>
  ) => void;
  productType: OrganizationProductType;
  areaProduct: string;
  setAreaProduct: React.Dispatch<React.SetStateAction<string>>;
}

export const AreaCoverageContext = React.createContext<AreaCoverageContextValues>(
  {} as AreaCoverageContextValues
);

export default function AreaCoverage({
  productType,
  action,
}: {
  productType: OrganizationProductType;
  action: "create" | "update";
}) {
  const [manuallyUpdatedAreaCoverage, setManuallyUpdatedAreaCoverage] = useState(false);
  const [areaProduct, setAreaProduct] = useState(action === "update" ? "custom" : null);

  // Let's find the right function to update the area coverage
  const calculateAreaCoverage = manuallyUpdatedAreaCoverage
    ? () => {}
    : calculateAreaMappings[productType.type]
    ? calculateAreaMappings[productType.type]
    : () => {};

  const { control, watch, getValues, setValue } = useFormContext();
  const materialCostType = watch("measurementMaterial");
  const measurementMethod = watch("measurementJob");
  const unitMeasurementCost =
    measurementMethod === ProductMeasurementJob.UNIT &&
    materialCostType === ProductMeasurementMaterial.UNIT;

  useEffect(() => {
    if (action === "update") {
      calculateAreaCoverage(getValues, setValue);
    }
  }, [action]);

  return (
    <AreaCoverageContext.Provider
      value={{ calculateAreaCoverage, productType, areaProduct, setAreaProduct }}
    >
      <ProductTypeFields productType={productType} />

      {(calculateAreaMappings[productType.type] || productType.type === ProductType.GENERIC) && (
        <Grid item xs={12} md={6}>
          <Controller
            name="areaCoverage"
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormField
                  placeholder={unitMeasurementCost ? "1" : ""}
                  label={
                    unitMeasurementCost
                      ? "Material Units per Measurement Unit"
                      : `Average Coverage in ${
                          measurementMethod
                            ? headerCase(measurementMethod)
                            : `( Measurement Method )`
                        }  per ${
                          materialCostType ? headerCase(materialCostType) : `( Material Cost Type )`
                        }`
                  }
                  inputProps={{
                    step: unitMeasurementCost ? "1" : "0.01",
                  }}
                  type="number"
                  disabled={productType.type !== ProductType.GENERIC}
                  name="areaCoverage"
                  error={error}
                  shrink={!!field.value}
                  {...field}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(+e.target.value || "");
                    setManuallyUpdatedAreaCoverage(true);
                  }}
                />
              );
            }}
          />
        </Grid>
      )}
    </AreaCoverageContext.Provider>
  );
}
