import { Accordion, AccordionDetails, AccordionSummary, Grid, Checkbox, Box } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Proposal, ProposalProduct, ProposalStage } from "generated/graphql";
import SelectStageProduct from "./SelectStageProduct";
import StageProduct from "./StageProduct";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddProductToStage from "./AddProductToStage";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ProposalContext from "./providers/ProposalContextProvider";
import useDeleteProposalProduct from "hooks/proposals/useDeleteProposalProduct";
import MDButton from "components/MDButton";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import ReorderStageProducts from "./providers/ReorderStageProducts";
import useUpdateStageProductsOrdering from "hooks/proposals/useUpdateStageProductsOrdering";
import ProposalProductEditUnitPriceForm from "./ProposalProductEditUnitPriceForm";
import DeleteStage from "./buttons/Stage/DeleteStage";
import { DefinedButtons } from "components/Sidebar";
import { SidebarContext } from "components/Sidebar/sidebarContext";
import ToggleProposalProductOptionality from "./ToggleProposalProductOptionality";
import { Height } from "@mui/icons-material";
import StageSummary from "./StageSummary";

export default function Stage({
  stage,
  expandedStage,
  onDeleteStage,
}: {
  stage: ProposalStage;
  expandedStage: ProposalStage["id"] | false;
  onDeleteStage?: (id: ProposalStage["id"]) => void;
}) {
  const { sendRequest, loading } = useUpdateStageProductsOrdering();
  const { inViewMode } = useContext(ProposalContext);
  const hasProducts = stage.proposalProducts.length > 0;
  const [expandedProduct, setExpandedProduct] = React.useState<string | false>(
    hasProducts ? stage.proposalProducts?.[0]?.id ?? false : false
  );
  const [sortedProducts, setSortedProducts] = useState<ProposalProduct[] | null>(
    stage.proposalProducts
  );

  const [editable, setEditable] = useState<{ [x: string]: boolean }>({ [stage.id]: false });

  const { clickedButton } = useContext(SidebarContext);

  useEffect(() => {
    if (clickedButton == DefinedButtons.ProposalCollapseProducts) {
      setExpandedProduct(false);
    }
  }, [clickedButton]);

  useEffect(() => {
    setSortedProducts(stage.proposalProducts);
  }, [stage.proposalProducts]);

  const [sortMode, setSortMode] = useState<boolean>(false);
  const [mode, setMode] = useState<"button" | "adding">("button");

  const [deleteProposalProduct, { isSuccess, data }] = useDeleteProposalProduct();

  const changeProduct = useCallback(
    (productId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedProduct(isExpanded ? productId : false);
    },
    [setExpandedProduct]
  );

  const onSuccess = useCallback(
    (updatedProposal: Proposal) => {
      // Here I have a proposal that just received a Product
      // Let's set the last product in the array as the expanded product
      const foundStage = updatedProposal?.stages?.find((stage) => stage.id === expandedStage);
      const lastProduct = foundStage?.proposalProducts?.slice(-1);
      setSortedProducts(foundStage.proposalProducts);
      if (lastProduct?.length > 0) {
        setExpandedProduct(lastProduct[0].id);
      }
    },
    [expandedStage]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      const result = await deleteProposalProduct({
        variables: {
          id,
        },
      });
      const success = isSuccess(result.data);
      return { success, data };
    },

    [deleteProposalProduct]
  );
  useEffect(() => {
    if (expandedStage !== stage.id && expandedProduct) {
      setExpandedProduct(false);
    }
  }, [expandedStage, stage.id]);

  const productUnitPrice = (product: ProposalProduct) => {
    const overallTotal = product.overallTotalOverride || product.overallTotal || 0;
    if (product.material.unitPrice || product.material.unitPriceOverride) {
      return product?.material?.unitPriceOverride || product?.material?.unitPrice || 0;
    }
    return product.jobSize ? (overallTotal as number) / product.jobSize : 0;
  };

  const handleOnDragEnd = (result) => {
    const items = Array.from(sortedProducts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSortedProducts(items);
    sendRequest({
      id: stage.id,
      proposalProducts: {
        update: items.map((item, index) => ({ id: item.id, ordering: index + 1 })),
      },
    });
    setSortMode(false);
  };

  const stageAddress = [
    stage?.address?.line1,
    stage?.address?.city,
    stage?.address?.state,
    stage?.address?.zip,
    stage?.address?.country,
  ]
    .filter((x) => !!x)
    .join(", ");

  return (
    <>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="h6" sx={{ fontSize: "1.25rem" }}>
          Stage Address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </MDTypography>
        <MDTypography variant="body" sx={{ fontSize: "1.25rem" }}>
          {stage?.addressOverride ?? stageAddress}
        </MDTypography>
      </MDBox>
      <MDBox
        py={1}
        display="flex"
        flexDirection="column"
        sx={{ background: !!sortMode ? "#fcfced" : "unset" }}
      >
        {!hasProducts && <SelectStageProduct stage={stage} onSuccess={onSuccess} />}
        {hasProducts && (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sortedProducts.map((product, index) => (
                    <Draggable
                      key={product.id}
                      draggableId={product.id}
                      index={index}
                      isDragDisabled={!sortMode}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <MDBox key={product.id} position="relative" py={1}>
                            <div
                              id={`panel-product-${product.id}`}
                              style={{
                                position: "absolute",
                                top: "-230px",
                                pointerEvents: "none",
                                visibility: "hidden",
                              }}
                            ></div>
                            <Accordion
                              expanded={expandedProduct === product.id}
                              elevation={2}
                              onChange={(event, isExpanded) => {
                                changeProduct(product.id)(event, isExpanded);
                                if (isExpanded) {
                                  setTimeout(() => {
                                    document
                                      .getElementById(`panel-product-${product.id}`)
                                      .scrollIntoView({
                                        behavior: "smooth",
                                      });
                                  }, 500);
                                }
                              }}
                              TransitionProps={{ unmountOnExit: true }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-product-${product.id}-content`}
                                sx={{
                                  backgroundColor:
                                    expandedProduct === product.id
                                      ? "rgba(100, 100, 150, 0.4)"
                                      : "rgba(100, 100, 150, 0.2)",
                                }}
                              >
                                <Grid container spacing={1} alignItems="center">
                                  {!!sortMode && (
                                    <Grid item xs={12} md={1}>
                                      <Icon fontSize="large" sx={{ height: "34px" }}>
                                        sort
                                      </Icon>
                                    </Grid>
                                  )}
                                  {/* <Grid item xs={12} sm={2} md={1}>
                                   
                                  </Grid> */}
                                  <Grid item xs={12} sm={6} md={8}>
                                    <Box display="flex" alignItems="center">
                                      <ToggleProposalProductOptionality
                                        id={product.id}
                                        isOptional={product.isOptional}
                                      />
                                      <MDTypography
                                        variant="body"
                                        sx={{
                                          paddingRight: 3,
                                          paddingLeft: "15px",
                                          display: "inline-block",
                                          fontSize: "1.25rem",
                                        }}
                                      >
                                        {product.material.name}{" "}
                                        {product.material.subcontracted && `- Subcontracted`}
                                      </MDTypography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={2}>
                                    <MDBox display="flex">
                                      <MDBox
                                        display="flex"
                                        gap={1}
                                        sx={{
                                          alignItems: "center",
                                        }}
                                      >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          <MDTypography
                                            variant="h6"
                                            style={{ marginRight: "5px", fontSize: "1.25rem" }}
                                          >
                                            Unit Price:
                                          </MDTypography>
                                          {!editable[product.id] && (
                                            <MDTypography
                                              variant="body"
                                              sx={{ minWidth: "80px", fontSize: "1.25rem" }}
                                            >
                                              {formatCentsToUSD(productUnitPrice(product))}
                                            </MDTypography>
                                          )}
                                        </div>
                                        {!editable[product.id] &&
                                          !product.material.unitPriceProduct && (
                                            <MDButton
                                              size="small"
                                              color="white"
                                              iconOnly
                                              type="button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditable((prevState) => ({
                                                  ...prevState,
                                                  [product.id]: !editable[product.id],
                                                }));
                                              }}
                                            >
                                              <Icon>edit</Icon>
                                            </MDButton>
                                          )}
                                      </MDBox>
                                    </MDBox>
                                  </Grid>
                                  <Grid item xs={12} md={2}>
                                    <MDBox display="flex">
                                      <MDBox display="flex" sx={{ alignItems: "center" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          <MDTypography
                                            variant="h6"
                                            style={{ fontSize: "1.25rem" }}
                                          >
                                            Total:
                                          </MDTypography>
                                          {!editable[product.id] && (
                                            <MDTypography
                                              variant="body"
                                              sx={{
                                                minWidth: "150px",
                                                marginLeft: "3px",
                                                fontSize: "1.25rem",
                                              }}
                                            >
                                              {formatCentsToUSD(
                                                product.overallTotalOverride ?? product.overallTotal
                                              )}
                                            </MDTypography>
                                          )}
                                        </div>
                                        {!editable[product.id] &&
                                          !product.material.unitPriceProduct && (
                                            <MDButton
                                              size="small"
                                              color="white"
                                              iconOnly
                                              type="button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditable((prevState) => ({
                                                  ...prevState,
                                                  [product.id]: !editable[product.id],
                                                }));
                                              }}
                                            >
                                              <Icon>edit</Icon>
                                            </MDButton>
                                          )}
                                      </MDBox>
                                    </MDBox>
                                  </Grid>
                                  {editable[product.id] && !product.material.unitPriceProduct && (
                                    <>
                                      <Grid item xs={5} />
                                      <Grid item xs={6}>
                                        <ProposalProductEditUnitPriceForm
                                          product={product}
                                          setEditable={(state) =>
                                            setEditable((prevState) => ({
                                              ...prevState,
                                              [product.id]: state,
                                            }))
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <StageProduct
                                  stage={stage}
                                  expandedProduct={expandedProduct}
                                  product={product as ProposalProduct}
                                  transferTrucks={[]}
                                  mapData={stage.mapData}
                                  handleDelete={handleDelete}
                                />
                              </AccordionDetails>
                            </Accordion>
                          </MDBox>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {hasProducts && !inViewMode && (
          <MDBox
            pt={3}
            sx={{
              display: { xs: "block", md: mode === "button" ? "flex" : "block" },
              flexDirection: "column",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            <MDBox display="flex" sx={{ alignItems: "center", gap: 2 }}>
              <AddProductToStage
                stage={stage}
                onSuccess={onSuccess}
                setExpandedProduct={setExpandedProduct}
                mode={mode}
                setMode={setMode}
              />
            </MDBox>
            <StageSummary stage={stage} />
            <MDBox
              sx={{ display: { xs: "block", md: "flex" }, justifyContent: "flex-end", gap: 1 }}
            >
              {mode === "button" && sortedProducts.length > 1 && (
                <ReorderStageProducts
                  setSortMode={setSortMode}
                  disabled={sortMode || loading}
                  setExpandedProduct={setExpandedProduct}
                />
              )}
              <MDBox display="flex" sx={{ alignItems: "center", gap: 2 }}>
                <DeleteStage acceptFn={() => (onDeleteStage ? onDeleteStage(stage.id) : "")} />
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
