import { useGetOrganizationApiKeysQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetOrganizationApiKeys() {
  const { data, loading: queryLoading, error, refetch } = useGetOrganizationApiKeysQuery();

  const organizationApiKeys = useMemo(() => {
    if (!data) return [];

    return data.GetOrganizationApiKeys.map((apiKey) => ({
      id: apiKey.id,
      description: apiKey.description,
      apiKey: apiKey.api_key,
    }));
  }, [data]);

  return { organizationApiKeys, loading: queryLoading, error, refetch } as const;
}
