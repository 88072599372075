import { OrganizationAddress } from "generated/graphql";
import * as z from "zod";

export const schema = z.object({
  id: z.string().optional().or(z.null()),
  line1: z.string().min(1, { message: "Address Line 1 is required" }),
  line2: z.string().optional().or(z.null()),
  city: z.string().min(1, { message: "Address City is required" }),
  state: z.string().min(1, { message: "Address State is required" }),
  zip: z.string().min(1, { message: "Address Zip is required" }),
  country: z.string().min(1, { message: "Address Country is required" }),
  isPrimary: z.boolean(),
});

export const defaultValues = {
  line1: "",
  line2: null,
  city: "",
  state: "",
  zip: "",
  country: "US",
  isPrimary: false,
};

export function getDefaultValues(address: OrganizationAddress) {
  return {
    ...defaultValues,
    ...address,
  };
}
