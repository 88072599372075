import { useGenerateOrganizationDataUploadUrlMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useGenerateUploadOrganizationDataUploadUrl() {
  const [mutation, { loading, error, data }] = useGenerateOrganizationDataUploadUrlMutation();
  const [generateUploadOrganizationDataUploadUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "generateOrganizationDataUploadUrl",
    },
    {
      hideMessage: true,
    }
  );

  return [generateUploadOrganizationDataUploadUrl, context] as const;
}
