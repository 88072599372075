import { useCallback, useContext, useEffect, useState } from "react";
import StageProductContext from "./forms/StageProductContext";
import { CircularProgress, Grid, Icon, IconButton } from "@mui/material";
import useDeleteProposalFile from "hooks/proposals/useDeleteProposalFile";
import { Reference, useApolloClient } from "@apollo/client";
import {
  DeleteProposalFileMutationVariables,
  DeleteProposalMapImageMutationVariables,
} from "generated/graphql";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import isImage from "utils/images/isImage";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import useDeleteProposalMapImage from "hooks/proposals/useProposalMapImage";

export default function ProposalProductFiles() {
  const client = useApolloClient();
  const { product } = useContext(StageProductContext);
  const [deleteProposalFile, { getData }] = useDeleteProposalFile();
  const [deleteProposalMapImage, { getData: mapImageGetData }] = useDeleteProposalMapImage();
  const [index, setIndex] = useState(-1);

  const slides = product.files
    .map((productFile) => {
      if (isImage(productFile.filename)) {
        return {
          src: `${import.meta.env.VITE_CDN_BASE_URL}/${productFile.filename}`,
        };
      }

      return null;
    })
    .filter(Boolean);

  if (product?.mapImage && !product?.mapImageDirty) {
    slides.push({ src: `${import.meta.env.VITE_CDN_BASE_URL}/${product.mapImage}` });
  }
  const regularFiles = product.files
    .map((productFile) => {
      if (isImage(productFile.filename)) {
        return null;
      }

      return {
        href: `${import.meta.env.VITE_CDN_BASE_URL}/${productFile.filename}`,
      };
    })
    .filter(Boolean);
  const onDeleteMapImage = useCallback(
    async (variables: DeleteProposalMapImageMutationVariables) => {
      const result = await deleteProposalMapImage({
        variables,
      });
      const success = mapImageGetData(result);
      if (success) {
        const idToRemove = variables.id;
        client.cache.modify({
          id: client.cache.identify(product),
          fields: {
            mapImage(mapImageRef: Reference, { readField }) {
              if (idToRemove === readField("mapImage", mapImageRef)) {
                return null;
              }
              return readField("mapImage", mapImageRef);
            },
          },
        });
      }
    },
    [deleteProposalMapImage]
  );

  const onDelete = useCallback(
    async (variables: DeleteProposalFileMutationVariables) => {
      const result = await deleteProposalFile({
        variables,
      });
      const success = getData(result);
      if (success) {
        const idToRemove = variables.id;
        client.cache.modify({
          id: client.cache.identify(product),
          fields: {
            files(existingFilesRef: Reference[], { readField }) {
              return existingFilesRef.filter((fileRef) => {
                return idToRemove !== readField("id", fileRef);
              });
            },
          },
        });
      }
    },
    [client, deleteProposalFile]
  );
  return (
    <div>
      <Lightbox open={index >= 0} index={index} close={() => setIndex(-1)} slides={slides} />
      {product.files.map((productFile, i) => (
        <div
          key={productFile.id}
          style={{
            display: "inline-flex",
            marginRight: "1em",
            marginBottom: "1em",
            position: "relative",
          }}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              setIndex(i);
            }}
            href={`${import.meta.env.VITE_CDN_BASE_URL}/${productFile.filename}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <img
              style={{
                borderRadius: "4px",
                width: "23vw",
                height: "auto",
              }}
              src={`${import.meta.env.VITE_CDN_BASE_URL}/${productFile.filename}`}
            />
          </a>
          <a
            style={{
              display: "block",
              position: "absolute",
              top: "0.5em",
              right: "0.5em",
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: "4px",
            }}
          >
            <IconButton
              onClick={() => {
                onDelete({
                  id: productFile.id,
                });
              }}
            >
              <Icon fontSize="medium" color="error">
                delete
              </Icon>
            </IconButton>
          </a>
        </div>
      ))}

      {product?.mapImage && !product?.mapImageDirty && (
        <div
          style={{
            display: "inline-flex",
            marginRight: "1em",
            marginBottom: "1em",
            position: "relative",
          }}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              setIndex(slides.length - 1);
            }}
            href={`${import.meta.env.VITE_CDN_BASE_URL}/${product.mapImage}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <img
              style={{
                borderRadius: "4px",
                width: "23vw",
                height: "auto",
              }}
              src={`${import.meta.env.VITE_CDN_BASE_URL}/${product.mapImage}`}
            />
          </a>
          <a
            style={{
              display: "block",
              position: "absolute",
              top: "0.5em",
              right: "0.5em",
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: "4px",
            }}
          >
            <IconButton
              onClick={() => {
                onDeleteMapImage({
                  id: product.id,
                });
              }}
            >
              <Icon fontSize="medium" color="error">
                delete
              </Icon>
            </IconButton>
          </a>
        </div>
      )}
      {product?.mapImageDirty && !product?.mapImage && (
        <MDBox display="flex" gap={5} alignItems="center">
          <MDTypography variant="h6">
            Generating Map Image... (Takes up to 5 mins, but you may continue working)
          </MDTypography>
          <CircularProgress color="info" />
        </MDBox>
      )}
      {regularFiles.map((regularFile, i) => (
        <MDBox key={i} py={1}>
          <MDTypography variant="h6">
            <a
              style={{ textDecoration: "none", color: "#344767" }}
              href={regularFile.href}
              target="_blank"
              rel="noreferrer nofollow"
            >
              {regularFile.href}
            </a>
          </MDTypography>
        </MDBox>
      ))}
    </div>
  );
}
