import {
  UpdateOrganizationSettingsInput,
  useUpdateOrganizationSettingsMutation,
} from "generated/graphql";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import { useBitumioContext } from "providers/BitumioContext";

const useUpdateOrganizationSettings = () => {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();

  const [mutation, { loading }] = useUpdateOrganizationSettingsMutation({
    onCompleted: () => {
      show({ message: "Settings updated successfully!" });
      hideLoader();
    },
    onError: () => {
      show({ message: "Something went wrong.", options: { variant: "error" } });
      hideLoader();
    },
    refetchQueries: ["getOrganizationSettings", "customGetOrganizationSettings"],
  });

  loading && showLoader();

  const updateOrganizationSettings = (input: { [key: string]: any }) => {
    const updateOrganizationSettingsInput: UpdateOrganizationSettingsInput[] = [];

    for (const [key, value] of Object.entries(input)) {
      updateOrganizationSettingsInput.push({ key, value: JSON.stringify(value) });
    }

    mutation({
      variables: {
        settings: updateOrganizationSettingsInput,
      },
    });
  };

  return { updateOrganizationSettings };
};

export default useUpdateOrganizationSettings;
