import { zodResolver } from "@hookform/resolvers/zod";
import {
  OrganizationProductType,
  Product,
  ProductProductionRateType,
  ProductType,
} from "generated/graphql";
import { FormProvider, useForm } from "react-hook-form";
import {
  getDefaultValues,
  getProductUpdateSchema,
  getProductUpdateSchemaSubcontracted,
  getProductUpdateSchemaUnitprice,
} from "DDD/action-objects/ProductUpdate";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { evolve } from "ramda";
import MDButton from "components/MDButton";
import { cleanNullValues } from "utils/object/cleanNullValues";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";
import ProductFormFields from "./ProductFormFields";
import { z } from "zod";
import { concreteProducts, requiresPlant } from "DDD/action-objects/ProductCreate";
import MDTypography from "components/MDTypography";

export default function OrganizationProductTypeProductUpdateForm({
  onSubmit,
  loading,
  materialCostTypeOptions,
  jobMeasurementMethodOptions,
  labourCostTypeOptions,
  productType,
  product,
  isUnitPrice,
  setIsUnitPrice,
  isSubcontracted,
  setIsSubcontracted,
}: {
  onSubmit: any;
  loading: any;
  materialCostTypeOptions: SelectOptions;
  jobMeasurementMethodOptions: SelectOptions;
  labourCostTypeOptions: SelectOptions;
  productType: OrganizationProductType;
  product: Product;
  isSubcontracted: boolean;
  setIsSubcontracted: React.Dispatch<React.SetStateAction<boolean>>;
  isUnitPrice: boolean;
  setIsUnitPrice: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const productRequiresPlant = requiresPlant.includes(ProductType[productType.type]);
  const isConcreteProduct = concreteProducts.includes(productType.type as ProductType);
  const canBeMeasuredInTons = productRequiresPlant && !isConcreteProduct;

  const navigate = useNavigate();
  const schema = useMemo(() => {
    if (isSubcontracted) {
      return getProductUpdateSchemaSubcontracted;
    }
    if (isUnitPrice) {
      return getProductUpdateSchemaUnitprice;
    }
    return getProductUpdateSchema({ productType: productType.type as ProductType });
  }, [isSubcontracted, isUnitPrice]);
  const methods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues({
      ...product,
      organizationProductTypeId: productType.id,
      productType: productType.type as ProductType,
      ...(canBeMeasuredInTons && {
        productionRateType: product.productionRateType === ProductProductionRateType.TONS_PER_HOUR, // casting this to boolean for the switch component
      }),
    }),
  });

  const {
    handleSubmit: zodHandleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const handleSubmit = useCallback(
    async ({ plantId, ...values }) => {
      // Series of updates to make the contents match to what is expected
      const preparedValues = evolve({ attributes: cleanNullValues })({
        ...values,
        subcontracted: values?.subcontracted ?? false,
        unitPriceProduct: values?.unitPriceProduct ?? false,
        ...(productRequiresPlant && {
          productionRateType: values?.productionRateType
            ? ProductProductionRateType.TONS_PER_HOUR
            : ProductProductionRateType.SQ_FT_SQ_YD_PER_HOUR,
        }),
        equipmentPieces: {
          sync: values?.equipmentPieces?.sync?.map(({ id, quantity }) => ({ id, quantity })),
        },
        additionalCosts: {
          sync: values?.additionalCosts?.sync?.map(({ id, quantity }) => ({ id, quantity })),
        },
      });
      if (isSubcontracted || isUnitPrice) {
        delete preparedValues.equipmentPieces;
        delete preparedValues.additionalCosts;
      }
      const result = await onSubmit(preparedValues);
      if (result.success) {
        reset();
        // Navigate to the recently created Product
        navigate(getRoute("product-types.products.list", [["productTypeId", productType.id]]));
      }
    },
    [isSubcontracted, isUnitPrice]
  );

  return (
    <Card>
      <FormProvider {...methods}>
        <MDBox
          p={3}
          component="form"
          role="form"
          id="product-update-form"
          onSubmit={(e) => {
            // There are forms inside modals that trigger the same event because of the vdom
            // do not submit if it's not for this form
            if ((e.target as HTMLFormElement).id === "product-update-form") {
              zodHandleSubmit(handleSubmit)(e);
            } else {
              e.preventDefault();
            }
          }}
        >
          <ProductFormFields
            action="update"
            materialCostTypeOptions={materialCostTypeOptions}
            jobMeasurementMethodOptions={jobMeasurementMethodOptions}
            labourCostTypeOptions={labourCostTypeOptions}
            productType={productType}
            isSubcontracted={isSubcontracted}
            isUnitPrice={isUnitPrice}
            setIsUnitPrice={setIsUnitPrice}
            setIsSubcontracted={setIsSubcontracted}
            canBeMeasuredInTons={canBeMeasuredInTons}
          />
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <MDButton
              type="submit"
              variant="gradient"
              color="success"
              disabled={loading}
              form="product-update-form"
              name="product-update-form"
            >
              Update Product
            </MDButton>
          </Grid>
        </MDBox>
      </FormProvider>
    </Card>
  );
}
