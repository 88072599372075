import { useApolloClient } from "@apollo/client";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLogoutMutation, User } from "generated/graphql";
import useMe from "hooks/authentication/useMe";
import BitumioContext from "providers/BitumioContext";
import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

const BitumioProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [signedInUser, setSignedInUser] = useState<User | null>(null);
  const [initialRequestsDone, setInitialRequestsDone] = useState(false);
  const [loaderIsVisible, setLoaderIsVisible] = useState(false);
  const [logout] = useLogoutMutation();
  const client = useApolloClient();

  const showLoader = useCallback(() => setLoaderIsVisible(true), []);
  const hideLoader = useCallback(() => setLoaderIsVisible(false), []);

  const signOff = useCallback(async () => {
    setLoading(true);
    // logout the user from backend
    try {
      await logout();
    } catch (e) {
      //
    }
    // Clear the signed in user to redirect to the login
    setSignedInUser(null);
    // do a full cache clear
    client.clearStore();
    // stop the loading
    setLoading(false);
  }, [setSignedInUser]);

  // Let's check initially if we have a valid user
  const [me, { loading: meLoading, user }] = useMe();

  useEffect(() => {
    // Run actions that are required for the global app context
    // Find the user
    me();
    setInitialRequestsDone(true);
  }, []);

  useEffect(() => {
    // The user was automatically found
    if (user) {
      setSignedInUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (signedInUser) {
      Sentry.setUser({
        id: signedInUser.id,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [signedInUser]);

  useEffect(() => {
    // Currently we are only loading the user in the app, so the sync is unique
    if (initialRequestsDone) {
      setLoading(meLoading);
    }
  }, [meLoading, initialRequestsDone]);

  return (
    <BitumioContext.Provider
      value={{ signedInUser, setSignedInUser, signOff, loading, showLoader, hideLoader }}
    >
      {children}
      {showLoader && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderIsVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </BitumioContext.Provider>
  );
};

export default BitumioProvider;
