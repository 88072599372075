import { EquipmentPiece, UserPermissions } from "generated/graphql";
import Update from "./Update";
import Delete from "./Delete";
import CreateProduct from "./CreateProduct";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export default function MenuItems({
  id,
  closeMenu,
}: {
  id: EquipmentPiece["id"];
  closeMenu: () => void;
}) {
  const { hasPermissions } = useCheckPermissions();
  if (!hasPermissions(UserPermissions.MANAGE_PRODUCT_SETTINGS)) {
    return <></>;
  }
  return (
    <>
      <CreateProduct closeMenu={closeMenu} id={id} />
      <Update closeMenu={closeMenu} id={id} />
      <Delete closeMenu={closeMenu} id={id} />
    </>
  );
}
