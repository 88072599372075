import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useCustomGetProposal from "hooks/proposals/useCustomGetProposal";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import UpdateProposal from "modules/proposals/UpdateProposal";
import { useState } from "react";
import { useParams } from "react-router";
import Sidebar, { DefinedButtons } from "components/Sidebar";

export default function ProposalDraftPage() {
  const { proposalId } = useParams();
  const { loading, data: proposal, error } = useCustomGetProposal({ id: proposalId });
  const [editable, setEditable] = useState(false);

  return (
    <DashboardPage renderNavbar={false}>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDBox display="flex" alignItems="center" gap="5px">
              <MDTypography variant="h4" fontWeight="medium">
                Create Proposal (Draft - {loading ? "..." : proposal?.externalId})
              </MDTypography>
              {!editable && (
                <MDButton
                  variant="outlined"
                  size="small"
                  color="info"
                  iconOnly
                  type="button"
                  onClick={() => setEditable(true)}
                >
                  <Icon>edit</Icon>
                </MDButton>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        {!loading && !error && (
          <>
            <Grid item xs={12}>
              <UpdateProposal proposal={proposal} editable={editable} setEditable={setEditable} />
            </Grid>
            <Sidebar
              buttons={[
                DefinedButtons.ProposalCollapseProducts,
                DefinedButtons.ProposalSummaryView,
                DefinedButtons.ProposalPreview,
              ]}
            />
          </>
        )}
      </Grid>
    </DashboardPage>
  );
}
