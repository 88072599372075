import { Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import { User } from "generated/graphql";
import useGetOrganizationUser from "hooks/users/useGetOrganizationUser";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import UpdateUser from "modules/users/UpdateUser/UpdateUser";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function UserEditPage() {
  const { userId } = useParams();
  const [getOrganizationUser, { loading, error, user }] = useGetOrganizationUser();

  useEffect(() => {
    getOrganizationUser({ id: userId });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load user details. Please refresh this page or try again later.
        </MDAlert>
      )}
      {!error && (
        <MDBox my={3}>
          {user && <UpdateUser user={user as User} />}
          {!user && <Skeleton />}
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
