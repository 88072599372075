// react-table components
import MDAlert from "components/MDAlert";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useOrganizationUsersTable from "hooks/users/useOrganizationUsersTable";

export default function OrganizationUsersList() {
  const { loading, error, tableData, pagination, filtering } = useOrganizationUsersTable();

  return (
    <>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && (
        <DataTable table={tableData} canSearch pagination={pagination} filtering={filtering} />
      )}
    </>
  );
}
