import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Grid } from "@mui/material";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { schema, defaultValues } from "DDD/action-objects/TransferTruckEdit";
import { CreateTransferTruckInput } from "generated/graphql";
import { useForm } from "react-hook-form";

export default function Create({ onSubmit, loading }) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<CreateTransferTruckInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async ({ ...rest }: CreateTransferTruckInput) => {
          const result = await onSubmit({
            ...rest,
            defaultTonnage: Number(rest.defaultTonnage),
            defaultRateHourly: Number(rest.defaultRateHourly),
            defaultRateTon: Number(rest.defaultRateTon),
          });
          if (result.success) {
            reset();
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="number"
                label="Hauling Capacity in Tons"
                // inputProps={{ min: 1, max: 255 }}
                error={errors.defaultTonnage}
                {...register("defaultTonnage", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyFormField
                control={control}
                name="defaultRateHourly"
                inputProps={{ min: "0" }}
                label="Default Hourly Rate"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyFormField
                control={control}
                inputProps={{ min: "0" }}
                name="defaultRateTon"
                label="Default Rate per Ton"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="info" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
