import getLocalString from "constants/Localization";
import {
  CustomCreateCustomEventMutationVariables,
  useCustomCreateCustomEventMutation,
  useCustomUpdateCustomEventMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useUpdateCustomEvent = () => {
  const [mutation, { loading, data, error }] = useCustomUpdateCustomEventMutation();
  const [updateCustomEvent, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateCustomEvent",
    },
    {
      successMessage: getLocalString("customEvents.update-custom-event-success"),
      failureMessage: getLocalString("customEvents.update-custom-event-failure"),
    }
  );
  return [updateCustomEvent, context] as const;
};
