import GlobalLoader from "components/GlobalLoader/GlobalLoader";
import { UserPermissions } from "generated/graphql";
import { checkPermissions } from "helpers/functions";
import { useBitumioContext } from "providers/BitumioContext";
import { useCallback, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function AuthWall({
  children,
  permission,
}: {
  children: JSX.Element;
  permission?: UserPermissions | UserPermissions[];
}) {
  const [{ signedInUser, loading }] = useBitumioContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (signedInUser) {
      if (permission && !checkPermissions(signedInUser, permission)) {
        navigate(`/not-authorized?permission=${permission}`);
      }
    }
  }, [signedInUser, navigate]);

  if (loading) {
    return <GlobalLoader />;
  }

  if (!signedInUser) {
    const redirectTo = encodeURIComponent(
      `${document.location.pathname}${document.location.search}`.trim()
    );
    return <Navigate to={`/signin?redirectTo=${redirectTo}`} />;
  }

  return <>{children}</>;
}

export function useRedirectAfterAuthentication() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleRedirectAfterSignin = useCallback(() => {
    const redirectTo = searchParams.get("redirectTo");
    if (redirectTo) {
      navigate(redirectTo);
    } else {
      navigate(getRoute("dashboard"));
    }
  }, [searchParams]);

  return { handleRedirectAfterSignin } as const;
}
