import getLocalString from "constants/Localization";
import { useUpdateJobBillingStatusMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateJobBillingStatus() {
  const [mutation, { loading, data, error }] = useUpdateJobBillingStatusMutation();
  const [updateJobBillingStatus, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateJobBillingStatus",
    },
    {
      successMessage: getLocalString("jobs.update-job-billing-status-success"),
      failureMessage: getLocalString("jobs.update-job-billing-status-failure"),
    }
  );

  return [updateJobBillingStatus, context] as const;
}
