import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";

export interface GlobalMessage {
  message: SnackbarMessage;
  options?: OptionsObject;
}

export default function useGlobalMessage() {
  const { enqueueSnackbar } = useSnackbar();

  const show = (message: GlobalMessage) => {
    enqueueSnackbar(message.message, {
      ...(message.options ?? {}),
      variant: message.options?.variant ?? "success",
    });
  };

  return { show } as const;
}
