import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import { JobPhase } from "generated/graphql";
import { jobMeasurementPlurals } from "utils/product-types/getJobMeasurementMethodOptions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export default function ProductDetails({ phase }: { phase: JobPhase }) {
  const {
    address,
    jobSize,
    scheduleBlocks,
    startsAt,
    endsAt,
    organizationProductTypeName,
    proposalProduct,
  } = phase;

  const scheduleBlock = scheduleBlocks?.find(
    (x) => x.scheduledAt === startsAt && x.endsAt === endsAt
  );
  const totalHours = scheduleBlocks?.length
    ? scheduleBlocks?.map((x) => x.blockLength)?.reduce((a, b) => a + b)
    : 0;

  return (
    <Grid item xs={12} lg={6} className="no-break">
      <MDBox>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>Product Category</TableCell>
                <TableCell align="right">{organizationProductTypeName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell align="right">{proposalProduct?.productName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Job Address</TableCell>
                <TableCell align="right">{address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Job Size</TableCell>
                <TableCell align="right">
                  {jobSize} {jobMeasurementPlurals[proposalProduct?.measurementJob]}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Job Hours</TableCell>
                <TableCell align="right">
                  {(totalHours ?? 0).toFixed(2)} hour
                  {totalHours > 1 && "s"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>
    </Grid>
  );
}
