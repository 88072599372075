import React, { useContext } from "react";

interface CalendarContextValues {
  subscribeCalendarToUpdates: (callback: () => void) => string;
  unsubscribeCalendarFromUpdates: (idToRemove: string) => void;
}

const CalendarContext = React.createContext<CalendarContextValues>({} as CalendarContextValues);

export const useCalendarContext = () => {
  const calendarContext = useContext(CalendarContext);

  return calendarContext;
};

export default CalendarContext;
