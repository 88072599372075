import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import {
  REFETCH_QUERY_DEFAULT_PAGE,
  REFETCH_QUERY_FIRST_VARIABLE,
} from "constants/RefetchVariables";
import { QueryGetProductsOrderByColumn, SortOrder } from "generated/graphql";
import useGetProducts from "hooks/products/useGetProducts";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { capitalCase } from "change-case";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
export default function ProductOptions({
  children,
}: {
  children: ({
    productOptions,
    loading,
    search,
  }: {
    productOptions: SelectOptions;
    loading: boolean;
    search: (v: string) => void;
  }) => React.ReactNode;
}) {
  const { filtering } = usePaginatedVariables();

  const { loading, data, error, getAllData } = useGetProducts({
    first: REFETCH_QUERY_FIRST_VARIABLE,
    search: filtering.debouncedSearchTerm,
    page: REFETCH_QUERY_DEFAULT_PAGE,
    orderBy: [{ column: QueryGetProductsOrderByColumn.NAME, order: SortOrder.ASC }],
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }
  getAllData();
  const products = data ?? [];

  const productOptions = products
    .map((product) => ({
      category: product?.organizationProductType.name
        ? capitalCase(product.organizationProductType.name)
        : "",
      label: product.subcontracted
        ? `[Subcontracted] ${product.name}`
        : product.unitPriceProduct
        ? `[Unit Priced] ${product.name}`
        : product.name,
      value: product.id,
    }))
    .sort((a, b) => (a?.category && b?.category ? a.category.localeCompare(b.category) : -1));

  return <>{children({ productOptions, loading, search: filtering.setSearchTerm })}</>;
}
