import {
  DeleteUserInviteMutation,
  DeleteUserInviteMutationVariables,
  useDeleteUserInviteMutation,
} from "generated/graphql";
import { useCallback } from "react";

export default function useDeleteUserInvite() {
  const [mutation, { data, loading, error }] = useDeleteUserInviteMutation({
    refetchQueries: ["getOrganizationUsers", "customGetOrganizationUsers"],
  });

  const resendUserInvite = useCallback(
    (values: DeleteUserInviteMutationVariables) => {
      return mutation({ variables: values });
    },
    [mutation]
  );

  const isSuccess = useCallback((data: DeleteUserInviteMutation) => {
    return data && data.deleteUserInvite === true;
  }, []);

  const success = isSuccess(data);
  const failure = data && data.deleteUserInvite === false;

  return [resendUserInvite, { data, loading, error, success, failure, isSuccess }] as const;
}
