import getLocalString from "constants/Localization";
import MDAlert from "components/MDAlert";
import AdditionalCostForm from "components/organization/AdditionalCostForm";
import { UpdateAdditionalCostInput, AdditionalCost } from "generated/graphql";
import useUpdateAdditionalCost from "hooks/organization/useUpdateAdditionalCost";
import { useCallback } from "react";

export default function Update({ additionalCost }: { additionalCost: AdditionalCost }) {
  const [
    updateAdditionalCost,
    { loading, error, additionalCost: updatedAdditionalCost, isSuccess, getAdditionalCost },
  ] = useUpdateAdditionalCost();

  const handleSubmit = useCallback(async (values: UpdateAdditionalCostInput) => {
    const formattedInput = {
      ...values,
      feeAmount: +values.feeAmount.toFixed(2), // dont multiply this by 100, only currency values should be multiplied by 100
      cost: Math.floor(values.cost * 100),
    };
    const { data } = await updateAdditionalCost({
      variables: {
        input: formattedInput,
      },
    });
    const success = isSuccess(data);
    return { success, user: getAdditionalCost(data) };
  }, []);

  return (
    <>
      {error && (
        <MDAlert color="error">
          {getLocalString("organization.update-additional-cost-failure")}
        </MDAlert>
      )}
      <AdditionalCostForm
        additionalCost={(updatedAdditionalCost ?? additionalCost) as AdditionalCost}
        loading={loading}
        onSubmit={handleSubmit}
        action={"upsert"}
      />
    </>
  );
}
