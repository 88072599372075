import { MenuItem } from "@mui/material";
import { EquipmentPiece } from "generated/graphql";
import { Link } from "react-router-dom";

export default function Update({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: EquipmentPiece["id"];
}) {
  return (
    <MenuItem component={Link} onClick={closeMenu} to={`/organization/equipment/${id}`}>
      Update
    </MenuItem>
  );
}
