import {
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables,
  useResendUserInviteMutation,
} from "generated/graphql";
import { useCallback } from "react";

export default function useResendUserInvite() {
  const [resendUserInviteMutation, { data, loading, error }] = useResendUserInviteMutation();

  const resendUserInvite = useCallback(
    (values: ResendUserInviteMutationVariables) => {
      return resendUserInviteMutation({ variables: values });
    },
    [resendUserInviteMutation]
  );

  const isSuccess = useCallback((data: ResendUserInviteMutation) => {
    return data && data.resendUserInvite === true;
  }, []);

  const success = isSuccess(data);
  const failure = data && data.resendUserInvite === false;

  return [resendUserInvite, { data, loading, error, success, failure, isSuccess }] as const;
}
