import ActionMenuItem from "components/Shared/ActionMenuItem/ActionMenuItem";
import { Plant } from "generated/graphql";
import Delete from "./Delete";

export default function MenuItems({
  id,
  closeMenu,
}: {
  status: string;
  id: Plant["id"];
  closeMenu: () => void;
}) {
  return (
    <>
      <ActionMenuItem closeMenu={closeMenu} route={`/organization/plants/${id}`} text="Update" />
      <Delete closeMenu={closeMenu} id={id} />
    </>
  );
}
