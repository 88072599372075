import getLocalString from "constants/Localization";
import { useUpdateJobProjectFileFolderIdMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateJobProjectFileFolderId() {
  const [mutation, { loading, data, error }] = useUpdateJobProjectFileFolderIdMutation();
  const [updateJobProjectFileFolderId, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateJobProjectFileFolderId",
    },
    {
      successMessage: getLocalString("jobs.update-job-project-file-folder-id-success"),
      failureMessage: getLocalString("jobs.update-job-project-file-folder-id-failure"),
    }
  );

  return [updateJobProjectFileFolderId, context] as const;
}
