import { contentPlaceholders } from "../generated/contentPlaceholders";

export const contentPlaceHolderTypes: {
  [k: string]: {
    [k: string]: {
      [k: string]: string;
    };
  };
} = contentPlaceholders;
export type PlaceHolderTypes = keyof typeof contentPlaceholders;
