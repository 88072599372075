import getLocalString from "constants/Localization";
import { usePreviewProposalMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function usePreviewProposal() {
  const [mutation, { loading, data, error }] = usePreviewProposalMutation();
  const [previewProposal, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "previewProposal",
    },
    {
      successMessage: getLocalString("proposals.preview-proposal-success"),
      failureMessage: getLocalString("proposals.preview-proposal-failure"),
      showValidationErrors: true,
      hideSpecificError: "Please input a job size.",
    }
  );

  return [previewProposal, context] as const;
}
