import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import useGetCompanies from "hooks/companies/useGetCompanies";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import React, { useCallback, useEffect, useMemo } from "react";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import usePaginatedResources, { Pagination } from "hooks/usePaginatedResources";
import {
  companiesInitialSortKeyValue,
  companiesSortKeyMap,
} from "hooks/companies/useCompaniesTable";
import { Company, Contact } from "../../generated/graphql";

export default function CompanyOptions({
  children,
  triggerRefetch,
  setTriggerRefetch,
  selectedCompany = null,
}: {
  triggerRefetch: boolean;
  fetchAll?: boolean;
  setTriggerRefetch: (trigger: boolean) => void;
  selectedCompany?: Company | null;
  children: ({
    companyOptions,
    companyContactOptions,
    loading,
    search,
    pagination,
    selectedContactDetails,
  }: {
    companyOptions: SelectOptions;
    companyContactOptions: Record<SelectOptions[number]["value"], SelectOptions>;
    loading: boolean;
    search: (v: string) => void;
    pagination: Pagination;
    selectedContactDetails: (companyId: Company["id"], contactId: Contact["id"]) => Contact;
  }) => React.ReactNode;
}) {
  const { filtering, offset, sorting } = usePaginatedVariables();

  const { loading, data, error, paginatorInfo, fetchMore } = useGetCompanies({
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sortKey: companiesSortKeyMap[sorting.sortKey] || companiesInitialSortKeyValue,
    searchByName: true,
  });

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const companies = useMemo(() => {
    let companies = [];

    const selectedCompanyExists =
      selectedCompany &&
      (data ?? []).filter((company) => company.id === selectedCompany.id).length > 0;

    if (!selectedCompanyExists && selectedCompany && !filtering.debouncedSearchTerm) {
      companies.push(selectedCompany);
    }

    return [...companies, ...(data ?? [])];
  }, [data, selectedCompany, filtering]);

  const companyOptions = useMemo(() => {
    return companies?.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  }, [companies]);

  const companyContactOptions = useMemo(() => {
    return companies?.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val.contacts.map((companyContact) => ({
          label: `${companyContact.firstName} ${companyContact.lastName}`,
          value: companyContact.id,
        })),
      };
    }, {});
  }, [companies]);

  const selectedContactDetails = useCallback(
    (companyId, contactId): Contact => {
      return companies?.find((c) => c.id === companyId)?.contacts?.find((c) => c.id === contactId);
    },
    [companies]
  );

  const refetch = async (quantity = offset.first) => {
    await fetchMore({
      variables: {
        first: quantity,
        search: filtering.debouncedSearchTerm,
      },
    });
  };

  useEffect(() => {
    if (triggerRefetch) {
      refetch().then(() => {
        setTriggerRefetch(false);
      });
    }
  }, [triggerRefetch]);

  return (
    <>
      {children({
        companyOptions,
        loading,
        search: filtering.setSearchTerm,
        companyContactOptions,
        pagination,
        selectedContactDetails,
      })}
    </>
  );
}
