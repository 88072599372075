import { Proposal, UpdateProposalInfoInput } from "generated/graphql";
import useUpdateProposalInfo from "hooks/proposals/useUpdateProposalInfo";
import { useCallback } from "react";
import ProposalInfoForm from "./forms/ProposalInfoForm";

export default function ProposalInfo({ proposal }: { proposal: Proposal }) {
  const [updateProposalInfo, { loading }] = useUpdateProposalInfo();

  const onSubmit = useCallback(async (input: UpdateProposalInfoInput) => {
    await updateProposalInfo({
      variables: {
        input,
      },
    });
  }, []);

  return (
    <>
      <ProposalInfoForm proposal={proposal} onSubmit={onSubmit} loading={loading} />
    </>
  );
}
