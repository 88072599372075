import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Grid, FormControl, InputLabel, FormHelperText } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { schema, defaultValues } from "DDD/action-objects/CrewEdit";
import { CreateCrewInput } from "generated/graphql";
import useGetOrganizationUsers from "hooks/users/useGetOrganizationUsers";
import { useState } from "react";
import reactCSS from "reactcss";
import { CompactPicker } from "react-color";
import { useForm } from "react-hook-form";
import currencyEndAdornment from "constants/currencyEndAdornment";
import { convertHexToRGB, getRandomHexColor } from "utils/object/getRandomColor";

export const colors = [
  "#4D4D4D",
  "#999999",
  "#F44E3B",
  "#FE9200",
  "#FCDC00",
  "#DBDF00",
  "#A4DD00",
  "#68CCCA",
  "#73D8FF",
  "#AEA1FF",
  "#FDA1FF",
  "#333333",
  "#808080",
  "#cccccc",
  "#D33115",
  "#E27300",
  "#FCC400",
  "#B0BC00",
  "#68BC00",
  "#16A5A5",
  "#009CE0",
  "#7B64FF",
  "#FA28FF",
  "#000000",
  "#666666",
  "#B3B3B3",
  "#9F0500",
  "#C45100",
  "#FB9E00",
  "#808900",
  "#194D33",
  "#0C797D",
  "#0062B1",
  "#653294",
  "#AB149E",
];
export interface ColorPickerProps {
  displayColorPicker: boolean;
  color?: IColor;
}

interface IColor {
  hex: string;
  rgb: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
}

export default function Create({ onSubmit, loading }) {
  const randomColor = getRandomHexColor();

  const [colorPicker, setColorPicker] = useState<ColorPickerProps>({
    displayColorPicker: false,
    color: {
      hex: randomColor,
      rgb: convertHexToRGB(randomColor),
    },
  });

  const handleClick = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: !colorPicker.displayColorPicker });
  };

  const handleClose = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: false });
  };

  const handleChange = (color) => {
    setColorPicker({
      ...colorPicker,
      color: {
        hex: color.hex,
        rgb: convertHexToRGB(color.hex),
      },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm<CreateCrewInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${colorPicker.color?.rgb?.r}, ${colorPicker.color?.rgb?.g}, ${colorPicker.color?.rgb?.b}, 1)`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        maxWidth: "45px",
        marginTop: "20px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const { data } = useGetOrganizationUsers({ page: 1, first: 1000 });
  const usersSelectData = data?.map(({ firstName, lastName, id }) => ({
    label: `${firstName} ${lastName}`,
    value: id,
  }));

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async ({ ...rest }: CreateCrewInput) => {
          const result = await onSubmit({
            ...rest,
            color: colorPicker.color.hex.substring(1),
          });
          if (result.success) {
            reset();
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Internal Crew Name ( This will appear in Work Orders and Scheduling)"
                placeholder="Internal Crew Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Client Facing Crew Name ( This will appear in Proposals)"
                placeholder="Client Facing Crew Name"
                error={errors.displayName}
                {...register("displayName")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                label="Default Foreman"
                control={control}
                fullWidth
                selectVariant="standard"
                data={usersSelectData}
                {...register("foremanUserId")}
              />
              <FormHelperText sx={{ marginLeft: "0px" }}>
                {errors?.foremanUserId?.message}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                align="right"
                type="number"
                label="Rate"
                inputProps={{ min: 0, step: 0.01 }}
                error={errors.rate}
                {...register("rate", { valueAsNumber: true })}
                {...currencyEndAdornment}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                sx={{ m: 1.2, minWidth: "100%", marginLeft: "-3px", marginTop: 0 }}
                error={"color" in errors}
                fullWidth={true}
              >
                <InputLabel
                  id="colorpicker-label"
                  sx={{ marginLeft: "-12px" }}
                  className={"MuiInputLabel-filled"}
                  shrink={true}
                >
                  Color
                </InputLabel>
                <div style={styles.swatch} onClick={handleClick} id="colorpicker">
                  <div style={styles.color} />
                </div>
                {colorPicker.displayColorPicker ? (
                  // @ts-expect-error
                  <div style={styles.popover}>
                    {/* @ts-expect-error */}
                    <div style={styles.cover} onClick={handleClose} />
                    <CompactPicker
                      color={colorPicker.color?.hex}
                      onChange={handleChange}
                      colors={colors}
                    />
                  </div>
                ) : null}
                <FormHelperText sx={{ marginLeft: "0px" }}>{errors?.color?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
