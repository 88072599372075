import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import { useCallback } from "react";

import MDAlert from "components/MDAlert";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import MDTypography from "components/MDTypography";
import useCreateAdditionalCost from "hooks/organization/useCreateAdditionalCost";
import { CreateAdditionalCostInput } from "generated/graphql";
import AdditionalCostForm from "../../components/organization/AdditionalCostForm";

export default function CreateAdditionalCostModal() {
  const { open, onClose, onOpen } = useModalState();
  const [createAdditionalCost, { loading, error, isSuccess, getData }] = useCreateAdditionalCost();

  const onSubmit = useCallback(
    async (values: CreateAdditionalCostInput) => {
      const formattedInput = {
        ...values,
        feeAmount: +values.feeAmount.toFixed(2), // dont multiply this by 100, only currency values should be multiplied by 100
        cost: Math.floor(values.cost * 100),
      };
      const result = await createAdditionalCost({
        variables: {
          input: formattedInput,
        },
      });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (isSuccess) {
        onClose();
      }
      return { success, data };
    },
    [createAdditionalCost]
  );

  return (
    <>
      <MDBox my={4}>
        <MDButton
          type="button"
          variant="gradient"
          color="secondary"
          onClick={() => {
            onOpen();
          }}
        >
          Create Additional Cost
        </MDButton>
      </MDBox>
      <Modal open={open} onClose={onClose}>
        <MDTypography variant="h6" p={3}>
          Create Additional Cost
        </MDTypography>
        {error && <MDAlert>{parseGraphQLError(error)}</MDAlert>}
        <AdditionalCostForm loading={loading} onSubmit={onSubmit} action={"create"} isOnModal />
      </Modal>
    </>
  );
}
