import { useIsOpportunitySearchableLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useIsOpportunitySearchable = () => {
  const [query, { loading, error, data: graphQLData }] = useIsOpportunitySearchableLazyQuery();

  const getOpportunityIsSearchable = useCallback(async (id: string) => {
    return await query({
      variables: { id },
    });
  }, []);
  const data = useMemo(() => {
    return graphQLData?.isOpportunitySearchable || null;
  }, [graphQLData]);

  return [getOpportunityIsSearchable, { loading, error, data }] as const;
};
