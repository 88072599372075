import { useRegisterMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useRegister() {
  const [mutation, { data, loading, error }] = useRegisterMutation();

  const [registerMutation, context] = useBitumioMutation(
    {
      loading,
      error,
      data,
      mutation,
      key: "register",
    },
    {
      hideMessage: true,
    }
  );

  const isUnverified = error && error.message === "unverified";
  const exists = error && error.message === "exists";
  const success = context.isSuccess(data);

  return [registerMutation, { ...context, isUnverified, success, exists }] as const;
}
