import {
  Card,
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import feeTypeMapper from "constants/feeTypeMapper";
import { JobPhaseCostItemInputSchema } from "DDD/action-objects/JobPhaseCostItemCreate";
import { AdditionalCost, AdditionalCostFeeType } from "generated/graphql";
import AddAdditionalCost from "modules/proposals/additionalCosts/AddAditionalCost";
import { useFieldArray, useFormContext } from "react-hook-form";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import HelpIcon from "@mui/icons-material/Help";

function AdditionalCostTotal({
  fieldName,
  initialQty,
  costTotal,
}: {
  fieldName: string;
  initialQty: number;
  costTotal: number;
}) {
  const { watch } = useFormContext();
  const additionalCost = watch(fieldName);
  let total = "Please save to see the total cost";

  if (additionalCost.costTotal) {
    total = formatCentsToUSD(additionalCost.costTotal);
  }

  if (additionalCost.feeType === AdditionalCostFeeType.FLAT) {
    total = formatCentsToUSD(additionalCost.feeAmount * additionalCost.cost);
  }

  return <span>{total}</span>;
}
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function AdditionalSection() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<JobPhaseCostItemInputSchema>();
  const { fields, append, remove } = useFieldArray({
    name: "additionalCosts",
    keyName: "key",
    control: control,
  });

  const onAdd = (additionalCosts: AdditionalCost[]) => {
    additionalCosts.forEach((ac) => {
      const { id, name, cost, feeType, feeAmount } = ac;
      const foundAdditionalCost = fields.find(
        (existingAdditionalCost) => existingAdditionalCost.id === id
      );
      if (!foundAdditionalCost) {
        const newAdditionalCost = {
          id: id,
          name: name,
          feeType: feeType,
          feeAmount: feeAmount,
          quantity: 0,
          cost: cost,
        };
        append(newAdditionalCost);
      }
    });
  };

  return (
    <Card>
      <MDBox p={4}>
        <MDTypography>Additional Costs</MDTypography>
        {errors.additionalCosts?.message && (
          <MDBox my={2}>
            <FormHelperText error>{errors.additionalCosts.message}</FormHelperText>
          </MDBox>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell sx={{ minWidth: "150px", width: "40%" }}>Name</TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "30%" }}>
                    Quantity / Percent
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "30%" }}>
                    <MDBox display="flex" justifyContent="right" alignItems="center" width="100%">
                      Fee Type
                      <CustomWidthTooltip
                        title={
                          <>
                            <p>Calculation of Job Size = Cost * Job Size from Proposal</p>
                            <p>Percent of Total Material Needed = Percent * Material Used * Cost</p>
                            <p>Flat Fee = Quantity * Cost</p>
                            <p>Multiple of Days on Job = Quantity from Proposal * Cost</p>
                            <p>Percent of Total Costs = Total Cost entered * Cost</p>
                          </>
                        }
                        placement="top"
                      >
                        <HelpIcon />
                      </CustomWidthTooltip>
                    </MDBox>
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "30%" }}>
                    Cost
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "30%" }}>
                    Total
                  </TableCell>
                  <TableCell sx={{ minWidth: "150px", width: "30%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((additionalCost, i) => {
                  return (
                    <TableRow
                      key={additionalCost.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <input
                        type="hidden"
                        value={additionalCost.id}
                        {...register(`additionalCosts.${i}.id`)}
                      />
                      <TableCell>{additionalCost.name}</TableCell>
                      <TableCell align="right">
                        <FormField
                          align="right"
                          type="number"
                          inputProps={{ step: "0.01" }}
                          placeholder={additionalCost.feeAmount}
                          disabled={[AdditionalCostFeeType.JOB_COST].includes(
                            additionalCost.feeType as AdditionalCostFeeType
                          )}
                          mb={0}
                          sx={{
                            "& .MuiInputBase-root": {
                              "& input": {
                                textAlign: "right",
                              },
                            },
                          }}
                          {...register(`additionalCosts.${i}.feeAmount`)}
                        />
                      </TableCell>

                      <TableCell align="right">{feeTypeMapper[additionalCost.feeType]}</TableCell>
                      <TableCell align="right">
                        <span>{formatCentsToUSD(additionalCost.cost)}</span>
                      </TableCell>
                      <TableCell align="right">
                        <AdditionalCostTotal
                          fieldName={`additionalCosts.${i}`}
                          initialQty={additionalCost.feeAmount}
                          costTotal={additionalCost.costTotal}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <MDButton
                          type="button"
                          variant="text"
                          color="error"
                          onClick={() => remove(i)}
                        >
                          Remove
                        </MDButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell colSpan={6}>
                    <AddAdditionalCost onAdd={onAdd} isJobCosting allowCreate={false} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
