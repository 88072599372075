import {
  CustomGetJobPhaseCostItemQuery,
  useCustomGetJobPhaseCostItemQuery,
} from "generated/graphql";

export type CustomJobPhaseCostItem = CustomGetJobPhaseCostItemQuery["getJobPhaseCostItem"];

export default function useGetJobPhaseCostItem({
  jobPhaseId,
  date,
}: {
  jobPhaseId: string;
  date: string;
}) {
  const { loading, data, error } = useCustomGetJobPhaseCostItemQuery({
    variables: { jobPhaseId, date },
    fetchPolicy: "no-cache",
  });

  return { loading, data: data?.getJobPhaseCostItem ?? null, error };
}
