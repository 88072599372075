import { useUpdateTimeBucketMutation } from "generated/graphql";
import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useUpdateTimeBucket = () => {
  const [mutation, { loading, error, data }] = useUpdateTimeBucketMutation({
    refetchQueries: ["getTimeBuckets"],
  });

  const [updateTimeBucket, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateTimeBucket",
    },
    {
      successMessage: getLocalString("time-tracking.update-time-bucket-success"),
      failureMessage: getLocalString("time-tracking.update-time-bucket-failure"),
      showValidationErrors: true,
    }
  );

  return [updateTimeBucket, context] as const;
};
