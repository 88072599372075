import MDAlert from "components/MDAlert";
import useRequestPasswordReset from "hooks/signup/useRequestPasswordReset";
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import ForgotPasswordForm from "modules/ForgotPasswordForm/ForgotPasswordForm";

export default function ForgotPasswordPage() {
  const [requestPasswordReset, { error, success, failure, loading }] = useRequestPasswordReset();

  return (
    <IllustrationLayout
      title={"Reset Password"}
      description={
        success ? "Please check your emails to continue" : "Enter your email address to continue"
      }
    >
      {error && (
        <MDAlert color="error">There has been an error. Please try again in a few minutes.</MDAlert>
      )}
      {failure && (
        <MDAlert color="warning">
          We could not initiate a password reset request. Please make sure you are using the correct
          email.
        </MDAlert>
      )}
      {!success && <ForgotPasswordForm onSubmit={requestPasswordReset} loading={loading} />}
    </IllustrationLayout>
  );
}
