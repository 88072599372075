import getLocalString from "constants/Localization";
import { OrganizationProductType, useUpdateProductMutation } from "generated/graphql";

import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateProduct({
  productTypeId,
}: {
  productTypeId?: OrganizationProductType["id"];
}) {
  const [mutation, { data, loading, error }] = useUpdateProductMutation({
    refetchQueries: ["getProducts", "customGetProducts"],
  });

  const [updateProduct, context] = useBitumioMutation(
    {
      mutation,
      data,
      loading,
      error,
      key: "updateProduct",
    },
    {
      successMessage: getLocalString("products.update-success"),
      failureMessage: getLocalString("products.update-failure"),
    }
  );

  return [updateProduct, context] as const;
}
