import { Product, ProductType, ProductMeasurementJob } from "generated/graphql";
import * as z from "zod";
import { baseProductSchemaSubcontracted, baseSchemaMap } from "./ProductCreate";

export const getProductUpdateSchemaSubcontracted = z.object({
  id: z.string().min(1, "Product ID is required"),
  name: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  externalProductName: z.string().optional(),
  indirectPercentage: z.number({ invalid_type_error: "Indirect Percentage is required" }).min(0),
  overheadPercentage: z.number({ invalid_type_error: "Overhead Percentage is required" }).min(0),
  markupPercentage: z.number({ invalid_type_error: "Markup Percentage is required" }).min(0),
  statementOfWork: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  subcontracted: z.boolean(),
  measurementJob: z.nativeEnum(ProductMeasurementJob),
});
export const getProductUpdateSchemaUnitprice = z.object({
  id: z.string().min(1, "Product ID is required"),
  name: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  externalProductName: z.string().optional(),
  indirectPercentage: z.number({ invalid_type_error: "Indirect Percentage is required" }).min(0),
  overheadPercentage: z.number({ invalid_type_error: "Overhead Percentage is required" }).min(0),
  markupPercentage: z.number({ invalid_type_error: "Markup Percentage is required" }).min(0),
  statementOfWork: z.string({ invalid_type_error: "Required" }).min(1, "Required"),
  unitPriceProduct: z.boolean(),
  unitPrice: z.number(),
  dailyUnitsComplete: z.number(),
  measurementJob: z.nativeEnum(ProductMeasurementJob),
});

export const getProductUpdateSchema = ({ productType }: { productType: ProductType }) =>
  baseSchemaMap[productType].merge(
    z.object({
      id: z.string().min(1, "Product ID is required"),
      equipmentPieces: z
        .object({
          sync: z.array(
            z.object({ id: z.string().min(1), quantity: z.number().positive(), name: z.string() })
          ),
        })
        .optional(),
      additionalCosts: z.object({
        sync: z.array(
          z.object({
            id: z.string().min(1),
            name: z.string(),
            // type: z.nativeEnum(ProductAdditionalCostType),
          })
        ),
      }),
    })
  );

export const defaultValues = {};

export const getDefaultValues = (initialDefaultValues) => {
  const calculatedValues = {
    crewId: initialDefaultValues.crew?.id,
    plantId: initialDefaultValues.plant?.id,
    plantProductId:
      initialDefaultValues?.plantProductId || initialDefaultValues.plant?.products?.[0]?.id,
    equipmentPieces: {
      sync: initialDefaultValues?.productEquipmentPieces?.map((productEquipmentPiece) => ({
        id: productEquipmentPiece.equipmentPiece.id,
        quantity: productEquipmentPiece.quantity,
        name: productEquipmentPiece.equipmentPiece.name,
      })),
    },
    additionalCosts: {
      sync: initialDefaultValues?.productAdditionalCosts?.map((productAdditionalCost) => ({
        id: productAdditionalCost.additionalCost.id,
        type: productAdditionalCost.type,
        name: productAdditionalCost.additionalCost.name,
      })),
    },
  };

  return {
    ...defaultValues,
    ...initialDefaultValues,
    ...calculatedValues,
  };
};
