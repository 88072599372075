import { Card, Grid, Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable/DataTable";
import { JobPhase } from "generated/graphql";

export default function MaterialOrLabourSummarySection({
  phases,
  proposedLabourHours,
}: {
  phases: JobPhase[];
  proposedLabourHours: number;
}) {
  const columns = [
    {
      Header: "Phase",
      accessor: "name",
      width: "20%",
    },
    {
      Header: "",
      accessor: "type",
      width: "20%",
    },
    {
      Header: "Material",
      accessor: "material",
      width: "20%",
    },
    {
      Header: "Labor Hours",
      accessor: "labourHours",
      width: "20%",
    },
    {
      Header: "Average Unit Completion Rate",
      accessor: "averageUnitCompletionRate",
      width: "10%",
    },
    {
      Header: "Job Size/Labor Hours",
      accessor: "jobSizePerLaborHours",
      width: "10%",
    },
  ];

  const buildTableRows = () => {
    const tableRows = [];

    const formatPerLaborHours = (numerator, denominator, inSeconds = true) => {
      const divisor = inSeconds ? 3600 : 1;
      return !!+denominator ? (+numerator / (+denominator / divisor)).toFixed(1) : 0;
    };

    phases.forEach((phase) => {
      // actualEmployeeHours is in hours so pass in 'false' to formatPerLaborHours()
      tableRows.push({
        name: phase.proposalProduct.material.name,
        type: "Proposed",
        material: phase.proposedMaterialNeeded,
        labourHours: (parseInt(phase.proposedEmployeeHours) / 3600).toFixed(2),
        averageUnitCompletionRate: formatPerLaborHours(
          phase.proposedMaterialNeeded,
          phase.proposedEmployeeHours
        ),
        jobSizePerLaborHours: formatPerLaborHours(phase.jobSize, phase.proposedEmployeeHours),
      });
      tableRows.push({
        name: "",
        type: "Actuals",
        material: phase.actualMaterialNeeded,
        labourHours: parseInt(phase.actualEmployeeHours).toFixed(2),
        averageUnitCompletionRate: formatPerLaborHours(
          phase.actualMaterialNeeded,
          phase.actualEmployeeHours,
          false
        ),
        jobSizePerLaborHours: formatPerLaborHours(phase.jobSize, phase.actualEmployeeHours, false),
      });
    });

    return tableRows;
  };

  const tableData = { columns, rows: buildTableRows() };

  return (
    <Card>
      <MDBox mt={4} p={4}>
        <MDTypography>Material / Labor Summary</MDTypography>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <DataTable table={tableData} showTotalEntries={false} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
