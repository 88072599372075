import { CustomUpdateProposalStageMutationVariables } from "generated/graphql";
import { z } from "zod";

export const schema = z.object({
  id: z.string().min(1, "Stage ID is Required"),
  name: z.string().min(1, "Stage Name is Required"),
  mapData: z.string().min(1, "Stage Address is Required"),
  address: z.string().min(1, "Address is required"),
  addressOverride: z.string().optional(),
});

export const getDefaultValues = (
  initialValues: Partial<CustomUpdateProposalStageMutationVariables & { address?: string }> &
    Pick<CustomUpdateProposalStageMutationVariables & { address: string }, "id">
): CustomUpdateProposalStageMutationVariables & { address: string } => ({
  name: "",
  mapData: "",
  address: "",
  addressOverride: null,
  ...initialValues,
});
