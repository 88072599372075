/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import getRoutesKeys from "utils/routing/getRoutesKeys";

import organizationRoutes from "routes/organization";
import productRoutes from "routes/product";
import { authRoutes } from "routes/auth";
import userRoutes from "routes/user";
import companyRoutes from "routes/company";
import opportunityRoutes from "routes/opportunity";
import proposalRoutes from "routes/proposal";
import jobRoutes from "routes/job";
import generalRoutes from "routes/general";
import { Icon } from "@mui/material";
import MapPage from "pages/map/map";
import { UserPermissions } from "generated/graphql";
import timeTrackingRoutes from "routes/timeTracking";

export interface Route {
  name?: string;
  key?: string;
  route?: string;
  title?: string;
  permission?: UserPermissions | UserPermissions[];
  type?: "collapse" | "divider" | "title" | "internal";
  component?: React.ReactNode;
  icon?: React.ReactNode;
  collapse?: Route[];
  href?: string;
}

function getDividerKey() {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

const routes = [
  ...authRoutes,
  ...generalRoutes,
  ...companyRoutes,
  ...opportunityRoutes,
  ...proposalRoutes,
  ...productRoutes,
  ...jobRoutes,
  ...organizationRoutes,
  ...userRoutes,
  ...timeTrackingRoutes,
  {
    type: "link",
    permission: UserPermissions.MANAGE_KNOWLEDGE_BASE,
    icon: <Icon fontSize="medium">library_books</Icon>,
    name: "Knowledge Base",
    key: "knowledge-base",
    route: "https://bitumio.freshdesk.com/support/solutions",
    href: "https://bitumio.freshdesk.com/support/solutions",
  },
] as const;

type AllKeys<T> = T extends {
  type: "collapse";
  key: string;
  collapse: { [index: number]: unknown };
}
  ? `${T["key"]}.${AllKeys<T["collapse"][number]>}`
  : T extends { key: string }
  ? T["key"]
  : never;

export type RoutesKeys = AllKeys<(typeof routes)[number]>;

export const routesKeys = getRoutesKeys(routes as unknown as Route[]);

export default routes;
