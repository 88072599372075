import Logo from "assets/images/Black-Bitumio_Full@4x.png";

const ErrorFallback = ({ error }) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Roboto",
      }}
    >
      <img src={Logo} width="130" height="130" alt="Brand" />
      <p
        style={{
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
          color: "#344767",
        }}
      >
        OOps! An Error Occured
      </p>
      <div
        style={{
          fontSize: "16px",
          textAlign: "center",
          color: "#344767",
          width: "500px",
          lineHeight: "150%",
        }}
      >
        <p>
          The page you are trying to visit caused an error. Our technical team has been notified of
          this problem automatically and will actively work to resolve as quickly as possible.
        </p>
        <p>
          {` In the meantime, please try one of the following: Click the "Back" button and refresh your
          page to navigate to your previous screen. Please reach out to support@bitumio.com with
          urgent items or any questions and our Support Team will be happy to assist.`}
        </p>
        <p>We apologize for the inconvenience. We will have this back up in no time.</p>
      </div>
      <p style={{ textTransform: "uppercase", fontWeight: "500", fontSize: "10px" }}>
        <code>{error.toString()}</code>
      </p>
    </div>
  );
};

export default ErrorFallback;
