import getLocalString from "constants/Localization";
import { useUpdateOrganizationEmailTemplateMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateOrganizationEmailTemplate() {
  const [mutation, { loading, error, data }] = useUpdateOrganizationEmailTemplateMutation();
  const [updateOrganizationEmailTemplate, context] = useBitumioMutation(
    {
      mutation,
      data,
      loading,
      error,
      key: "updateOrganizationEmailTemplate",
    },
    {
      successMessage: getLocalString("organization.update-email-template-success"),
      failureMessage: getLocalString("organization.update-email-template-failed"),
    }
  );
  return [updateOrganizationEmailTemplate, context] as const;
}
