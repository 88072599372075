import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AddFiles from "../AddFiles";
import JobPhaseFiles from "../JobPhaseFiles";

import {
  getDefaultValues,
  schema,
  UpdateJobPhaseNotesInputSchema,
} from "DDD/action-objects/JobPhaseNotesUpdate";
import { JobPhaseContext } from "../context/JobPhaseContext";
import { useContext } from "react";

const JobPhaseNotesAndFiles = ({
  onSubmit,
  saving,
}: {
  onSubmit: SubmitHandler<UpdateJobPhaseNotesInputSchema>;
  saving: boolean;
}) => {
  const { jobPhase } = useContext(JobPhaseContext);

  const methods = useForm<UpdateJobPhaseNotesInputSchema>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues({ jobPhase }),
  });

  return (
    <FormProvider {...methods}>
      <MDBox my={4} component="form" role="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <MDBox p={4}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Controller
                  control={methods.control}
                  name="notes"
                  render={({ field }) => (
                    <WYSIWYG
                      content={field.value}
                      onChange={(note) => field.onChange(note)}
                      title="Note"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <JobPhaseFiles />
                <MDBox
                  sx={{
                    width: "23vw",
                    position: "relative",
                    "& .uppy-DragDrop-inner": { padding: "16px 0" },
                    "& .uppy-DragDrop-label": { fontSize: "1em" },
                  }}
                >
                  <AddFiles />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="success">
                {saving ? <CircularProgress color="inherit" size={20} /> : "Save"}
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </FormProvider>
  );
};

export default JobPhaseNotesAndFiles;
