import { AdditionalCost, AdditionalCostFeeType } from "generated/graphql";
import * as z from "zod";

export const schema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  feeType: z.nativeEnum(AdditionalCostFeeType),
  feeAmount: z.coerce.number().min(0, { message: "Fee Amount is required" }),
  cost: z.number().min(0, { message: "Cost is required" }),
});

export const defaultValues = {
  name: "",
  feeType: null,
  feeAmount: 0,
  cost: 0,
};

export function getDefaultValues(additionalCost: AdditionalCost) {
  return defaultValues;
}
