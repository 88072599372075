import { Icon } from "@mui/material";
import AuthWall from "modules/AuthWall/AuthWall";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";

import UpdateOpportunityPage from "pages/opportunities/update";
import OpportunitiesPage from "../pages/opportunities";
import CreateOpportunityPage from "../pages/opportunities/create";
import Opportunity from "../../public/assets/images/opportunities.png";
import { UserPermissions } from "generated/graphql";

const opportunityRoutes = [
  {
    type: "link",
    name: "Opportunities",
    key: "opportunities.list",
    route: "/opportunities",
    permission: UserPermissions.VIEW_OPPORTUNITIES,
    icon: <Icon fontSize="medium">emoji_events</Icon>,
    component: (
      <AuthWall permission={UserPermissions.VIEW_OPPORTUNITIES}>
        <AuthWallSubscriber>
          <OpportunitiesPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Create Opportunity",
    key: "opportunities.create",
    route: "/opportunities/create",
    permission: UserPermissions.MANAGE_OPPORTUNITIES,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_OPPORTUNITIES}>
        <AuthWallSubscriber>
          <CreateOpportunityPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "internal",
    name: "Update",
    key: "opportunities.update",
    route: "/opportunities/:opportunityId/update",
    permission: UserPermissions.MANAGE_OPPORTUNITIES,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_OPPORTUNITIES}>
        <AuthWallSubscriber>
          <UpdateOpportunityPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
] as const;

export default opportunityRoutes;
