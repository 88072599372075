import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { AcceptUserInviteInput } from "generated/graphql";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodPasswordValidator } from "utils/validations/isValidPassword";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import MDTypography from "components/MDTypography";
import { zodEmailValidator } from "utils/validations/isValidEmail";
import MDPasswordInput from "components/MDPasswordInput/MDPasswordInput";
import MDInput from "components/MDInput";

export interface AcceptInviteFormProps {
  onSubmit: SubmitHandler<AcceptUserInviteInput>;
  loading: boolean;
  email: AcceptUserInviteInput["email"];
  inviteToken: AcceptUserInviteInput["inviteToken"];
}

const schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  password: zodPasswordValidator,
  email: zodEmailValidator,
  inviteToken: z.string(),
});

export default function AcceptInviteForm({
  onSubmit,
  loading,
  email,
  inviteToken,
}: AcceptInviteFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AcceptUserInviteInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email,
      inviteToken,
      password: "",
    },
  });

  return (
    <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
      <MDBox mb={2}>
        <input type="hidden" {...register("inviteToken")} />
        <MDBox mb={2}>
          <MDInput
            type="firstName"
            label="First Name"
            variant="standard"
            fullWidth
            required
            {...register("firstName")}
            errror={errors.firstName}
          />
          {errors.firstName && (
            <MDTypography color="error" variant="caption">
              {errors.firstName.message}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="lastName"
            label="Last Name"
            variant="standard"
            fullWidth
            required
            {...register("lastName")}
            errror={errors.lastName}
          />
          {errors.lastName && (
            <MDTypography color="error" variant="caption">
              {errors.lastName.message}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            variant="standard"
            fullWidth
            required
            disabled
            {...register("email")}
            errror={errors.email}
          />
          {errors.email && (
            <MDTypography color="error" variant="caption">
              {errors.email.message}
            </MDTypography>
          )}
        </MDBox>
        <MDPasswordInput
          type="password"
          label="Password"
          variant="standard"
          placeholder="Password"
          fullWidth
          required
          {...register("password")}
          errror={errors.password}
        />
        {errors.password && (
          <MDTypography color="error" variant="caption">
            {errors.password.message}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
          Accept
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
