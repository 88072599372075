import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";

const GlobalLoader = () => {
  return (
    <MDBox
      data-testid="global-loader"
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      right={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="inherit" />
    </MDBox>
  );
};

export default GlobalLoader;
