import { useCallback, useContext, useState } from "react";
import { Grid, Icon, IconButton } from "@mui/material";
import { Reference, useApolloClient } from "@apollo/client";
import { DeleteJobPhaseFileMutationVariables } from "generated/graphql";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import isImage from "utils/images/isImage";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { JobPhaseContext } from "./context/JobPhaseContext";
import useDeleteJobPhaseFile from "hooks/jobs/useDeleteJobPhaseFile";

export default function JobPhaseFiles() {
  const client = useApolloClient();
  const { jobPhase } = useContext(JobPhaseContext);
  const [deleteJobPhaseFile, { getData }] = useDeleteJobPhaseFile();
  const [index, setIndex] = useState(-1);

  const slides = jobPhase?.files
    .map((jobPhaseFile) => {
      if (isImage(jobPhaseFile.filename)) {
        return {
          src: `${import.meta.env.VITE_CDN_BASE_URL}/${jobPhaseFile.filename}`,
        };
      }

      return null;
    })
    .filter(Boolean);

  const regularFiles = jobPhase?.files
    .map((jobPhaseFile) => {
      if (isImage(jobPhaseFile.filename)) {
        return null;
      }

      return {
        href: `${import.meta.env.VITE_CDN_BASE_URL}/${jobPhaseFile.filename}`,
      };
    })
    .filter(Boolean);

  const onDelete = useCallback(
    async (variables: DeleteJobPhaseFileMutationVariables) => {
      const result = await deleteJobPhaseFile({
        variables,
      });
      const success = getData(result);
      if (success) {
        const idToRemove = variables.id;
        client.cache.modify({
          id: client.cache.identify(jobPhase),
          fields: {
            files(existingFilesRef: Reference[], { readField }) {
              return existingFilesRef.filter((fileRef) => {
                return idToRemove !== readField("id", fileRef);
              });
            },
          },
        });
      }
    },
    [client, deleteJobPhaseFile]
  );

  return (
    <div>
      <Lightbox open={index >= 0} index={index} close={() => setIndex(-1)} slides={slides} />
      {jobPhase?.files.map((jobPhaseFile, i) => (
        <div
          key={jobPhaseFile.id}
          style={{
            display: "inline-flex",
            marginRight: "1em",
            marginBottom: "1em",
            position: "relative",
          }}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              setIndex(i);
            }}
            href={`${import.meta.env.VITE_CDN_BASE_URL}/${jobPhaseFile.filename}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <img
              style={{
                borderRadius: "4px",
                width: "23vw",
                height: "auto",
              }}
              src={`${import.meta.env.VITE_CDN_BASE_URL}/${jobPhaseFile.filename}`}
            />
          </a>
          <a
            style={{
              display: "block",
              position: "absolute",
              top: "0.5em",
              right: "0.5em",
              background: "rgba(255, 255, 255, 0.6)",
              borderRadius: "4px",
            }}
          >
            <IconButton
              onClick={() => {
                onDelete({
                  id: jobPhaseFile.id,
                });
              }}
            >
              <Icon fontSize="medium" color="error">
                delete
              </Icon>
            </IconButton>
          </a>
        </div>
      ))}
      {regularFiles?.map((regularFile, i) => (
        <MDBox key={i} py={1}>
          <MDTypography variant="h6">
            <a
              style={{ textDecoration: "none", color: "#344767" }}
              href={regularFile.href}
              target="_blank"
              rel="noreferrer nofollow"
            >
              {regularFile.href}
            </a>
          </MDTypography>
        </MDBox>
      ))}
    </div>
  );
}
