import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { OrganizationProductType } from "generated/graphql";
import useGetOrganizationProductTypes from "hooks/organization/product-types/useGetOrganizationProductTypes";
import React from "react";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

const productTypeMap = (productType: OrganizationProductType) => ({
  label: productType.name,
  value: productType.id,
});

export default function OrganizationProductTypeIdsOptions({
  sortByName = false,
  children,
}: {
  sortByName?: boolean;
  children: ({
    organizationProductTypeIdsOptions,
    loading,
  }: {
    organizationProductTypeIdsOptions: SelectOptions;
    loading: boolean;
  }) => React.ReactNode;
}) {
  const { loading, data, error } = useGetOrganizationProductTypes();

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const organizationProductTypeIds = data ?? [];

  const organizationProductTypeIdsOptions = organizationProductTypeIds.map(productTypeMap);

  if (sortByName) {
    organizationProductTypeIdsOptions.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <>
      {children({
        organizationProductTypeIdsOptions,
        loading,
      })}
    </>
  );
}
