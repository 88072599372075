import {
  useCustomUnscheduleCustomEventMutation,
  useCustomUnscheduleJobPhaseMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUnscheduleCustomEvent() {
  const [mutation, { loading, data, error }] = useCustomUnscheduleCustomEventMutation({
    refetchQueries: ["customGetCustomEventBlocks", "customGetCustomEvent"],
  });

  const [unscheduleCustomEvent, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "unscheduleCustomEvent",
    },
    {
      successMessage: "Removed from calendar",
      failureMessage: "Failure to unschedule",
    }
  );

  return [unscheduleCustomEvent, context] as const;
}
