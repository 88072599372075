import { CustomCompany } from "constants/Company";
import {
  CustomGetCompaniesQuery,
  useCustomGetCompaniesForFilterLazyQuery,
  useCustomGetCompaniesLazyQuery,
} from "generated/graphql";
import { useCallback, useEffect, useRef, useState } from "react";

const useGetAllCompanies = (isFilter = false) => {
  const [status, setStatus] = useState("pending");
  const fn = isFilter ? useCustomGetCompaniesForFilterLazyQuery : useCustomGetCompaniesLazyQuery;
  const [getCompanies, { loading, error }] = fn({
    fetchPolicy: "network-only",
  });
  const [data, setData] = useState<Partial<CustomCompany>[] | null>(null);
  const page = useRef(0);

  const getAllCompanies = useCallback(async () => {
    page.current += 1;
    const { data } = await getCompanies({
      variables: {
        page: page.current,
        first: 250,
      },
    });
    if (data?.getCompanies.paginatorInfo.hasMorePages) {
      setData((prevState) => [...(prevState ? prevState : []), ...data.getCompanies.data]);
      getAllCompanies();
    } else {
      setData((prevState) => [...(prevState ? prevState : []), ...(data?.getCompanies.data ?? [])]);
      setStatus("fulfilled");
    }
  }, [getCompanies]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  return { loading: status === "pending" || loading, error, data } as const;
};

export default useGetAllCompanies;
