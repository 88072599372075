import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable/DataTable";
import { ProductTypeUsed } from "generated/graphql";
import useJobCostingDetailsTable from "hooks/dashboard/useJobCostingDetailsTable";

const JobCostingDetails = ({ data }: { data: ProductTypeUsed }) => {
  const { tableData } = useJobCostingDetailsTable(data);

  return (
    <Grid item xs={12} md={12} lg={6}>
      <MDBox overflow="hidden" borderRadius="xl" mt={3}>
        <MDBox sx={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }} bgColor="white">
          <MDTypography variant="h6" component="td" p={3} mt={3}>
            Job Costing Details
          </MDTypography>
          {data && <DataTable table={tableData} showTotalEntries={false} />}
        </MDBox>
      </MDBox>
    </Grid>
  );
};

export default JobCostingDetails;
