import { useUpdateProposalStagesOrderingMutation } from "generated/graphql";

const useUpdateProposalStagesOrdering = () => {
  const [mutation, { loading }] = useUpdateProposalStagesOrderingMutation();

  // not using useBitumioMutation here because everything needs to happen silently
  const sendRequest = (data) => {
    mutation({
      variables: {
        input: data,
      },
    });
  };

  return { sendRequest, loading };
};

export default useUpdateProposalStagesOrdering;
