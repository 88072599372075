import MDBox from "components/MDBox";
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import useValidateRegistrationToken from "hooks/signup/useValidateRegistrationToken";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import getRoute, { getVerificationResendRoute } from "utils/routing/getRoute";
import CompleteSignUpForm from "modules/CompleteSignUpForm/CompleteSignUpForm";
import { FinishRegistrationInput } from "generated/graphql";
import useFinishRegistration from "hooks/signup/useFinishRegistration";
import MDAlert from "components/MDAlert";
import { useBitumioContext } from "providers/BitumioContext";
import getLocalString from "constants/Localization";

type CompleteSignUpProps = {
  email: FinishRegistrationInput["email"];
  token: FinishRegistrationInput["token"];
};

const CompleteSignUp: React.FC<CompleteSignUpProps> = (props) => {
  const { email, token } = props;
  const [finishRegistration, { loading, error, success, user }] = useFinishRegistration();
  const navigate = useNavigate();
  const [{ setSignedInUser }] = useBitumioContext();

  useEffect(() => {
    if (success && user) {
      // It means that the registration was a success
      // save the user to state
      setSignedInUser(user);
      // and redirect to home
      navigate(getRoute("dashboard"));
    }
  }, [success, user]);

  return (
    <>
      {error && (
        <MDAlert color="error">{error.message ?? getLocalString("generic.failure")}</MDAlert>
      )}
      <CompleteSignUpForm
        onSubmit={finishRegistration}
        loading={loading}
        email={email}
        token={token}
      />
    </>
  );
};

export default function SignupVerifyPage() {
  const [validateRegistrationToken, { loading, error, email, token, success, failure }] =
    useValidateRegistrationToken();

  const resendRoute = getVerificationResendRoute(email);

  useEffect(() => {
    // It was requested that the token is validated immediately
    validateRegistrationToken();
  }, []);

  return (
    <IllustrationLayout
      title={"Complete Sign Up"}
      description={
        ((error || failure) && "Verification Link Invalid") ||
        (success && "Enter your password below to start using the platform") ||
        "Verifying Sign Up"
      }
    >
      {loading && (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="rectangular" width={210} height={118} />
        </>
      )}
      {(error || failure) && (
        <MDBox>
          <MDTypography textAlign="center">
            Having problems with your verification link?{" "}
            <MDTypography
              component={Link}
              to={resendRoute}
              fontWeight="bold"
              color="info"
              textGradient
            >
              Resend it
            </MDTypography>
          </MDTypography>
        </MDBox>
      )}
      {success && <CompleteSignUp email={email as string} token={token as string} />}
    </IllustrationLayout>
  );
}
