import getLocalString from "constants/Localization";
import { JobPhase, useUpdateJobPhaseStatusMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateJobPhaseStatus({ id }: { id: JobPhase["id"] }) {
  const [mutation, { loading, data, error }] = useUpdateJobPhaseStatusMutation({
    refetchQueries: ["getJobPhase", "customGetJobPhase"],
  });
  const [updateJobPhaseStatus, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateJobPhaseStatus",
    },
    {
      successMessage: getLocalString("jobPhases.update-job-phase-status-success"),
      failureMessage: getLocalString("jobPhases.update-job-phase-status-failure"),
    }
  );

  return [updateJobPhaseStatus, context] as const;
}
