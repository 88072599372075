import {
  ResendVerificationEmailInput,
  useResendVerificationEmailMutation,
} from "generated/graphql";
import { useCallback } from "react";
import { SubmitHandler } from "react-hook-form";

export default function useResendVerificationEmail() {
  const [resendVerificationEmailMutation, { data, loading, error }] =
    useResendVerificationEmailMutation();

  const resendVerificationEmail: SubmitHandler<ResendVerificationEmailInput> = useCallback(
    (values: ResendVerificationEmailInput) => {
      resendVerificationEmailMutation({ variables: { input: values } });
    },
    [resendVerificationEmailMutation]
  );

  const failure = data && data.resendVerificationEmail === false;
  const success = data && data.resendVerificationEmail === true;

  return [resendVerificationEmail, { data, loading, error, failure, success }] as const;
}
