import React, { useEffect, useState, useMemo } from "react";
import { Card, Grid } from "@mui/material";
import DataTable from "components/Tables/DataTable/DataTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useQueueList from "hooks/quickbooksDesktop/useQueueList";
import useRemoveFromQueueList from "hooks/quickbooksDesktop/useRemoveFromQueueList";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { CustomPaginatorInfo } from "generated/graphql";

const QuickbooksDesktopQueueList = () => {
  const { offset } = usePaginatedVariables();

  const [queueListData, paginatorInfo, refreshQueueList] = useQueueList(
    offset.first,
    offset.page
  ) as [any, CustomPaginatorInfo, (p1, p2) => void];
  const [removeJobFromQueueList] = useRemoveFromQueueList();
  const [deleted, setDeleted] = useState(false);
  const [queueData, setQueueData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(offset.first);
  const [currentPage, setCurrentPage] = useState(offset.page);
  const [rowId, setRowId] = useState("");
  const [open, setOpen] = useState(false);

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  useEffect(() => {
    setQueueData([]);
    if (queueListData.length) {
      setQueueData(queueListData);
    }
  }, [queueListData]);

  useEffect(() => {
    if (deleted) {
      refreshQueueList(offset.first, offset.page);
      setDeleted(false);
    }
  }, [deleted]);

  useEffect(() => {
    const perPage = offset.first;
    let page = offset.page;

    if (perPage != itemsPerPage || page != currentPage) {
      if (perPage != itemsPerPage && page != 1) {
        offset.paginate({ page: 1, first: perPage });
      }
      setCurrentPage(page);
      setItemsPerPage(perPage);
      refreshQueueList(perPage, page);
    }
  }, [offset]);

  const dateFormatter = useMemo(() => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }, []);

  const acceptFn = () => {
    removeJobFromQueueList(rowId);
    setDeleted(true);
    setRowId("");
    setOpen(false);
  };

  const cancelFn = () => {
    setRowId("");
    setOpen(false);
  };

  const handleActionButtonClick = async (row) => {
    setRowId(row.id);
    setOpen(true);
  };

  const columns = [
    {
      Header: "Queue Date/Time",
      accessor: "converted_created_at",
      width: "10%",
      Cell: ({ value }) => dateFormatter.format(new Date(value)),
    },
    {
      Header: "Job",
      accessor: "job_id",
      width: "10%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "20%",
    },
    {
      Header: "Action",
      accessor: "step",
      width: "20%",
    },
    {
      Header: "Details",
      accessor: "details",
      width: "30%",
    },
    {
      Header: "Edit",
      width: "10%",
      Cell: ({ row }) =>
        row.original.status === "QUEUED" && (
          <>
            <MDButton
              size="small"
              variant="gradient"
              color="error"
              onClick={() => handleActionButtonClick(row.original)}
            >
              Delete
            </MDButton>
          </>
        ),
    },
  ];
  const tableData = { columns, rows: queueData };

  return (
    <DashboardPage>
      <MDTypography variant="h4">Quickbooks Desktop Queue</MDTypography>
      <Card>
        <MDBox mt={4} p={4}>
          <MDTypography>Job Status Summary</MDTypography>
          <ActionConfirmDialog
            open={open}
            setOpen={setOpen}
            handleAccept={acceptFn}
            handleCancel={cancelFn}
            title="Confirmation"
            content={`Delete Job from the queue list?`}
          />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <DataTable table={tableData} pagination={pagination} />
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardPage>
  );
};

export default QuickbooksDesktopQueueList;
