import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  SubmitHandler,
  useForm,
  UseFormRegister,
  UseFormGetValues,
} from "react-hook-form";
import { useNavigate } from "react-router";
import { getDefaultValues, schema } from "DDD/action-objects/CrewEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateCrewInput, Crew } from "generated/graphql";
import { Card, Grid, InputLabel, FormControl, FormHelperText } from "@mui/material";
import MDBox from "components/MDBox";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import useGetOrganizationUsers from "hooks/users/useGetOrganizationUsers";
import { useState } from "react";
import reactCSS from "reactcss";
import { CompactPicker } from "react-color";
import { getRoute } from "utils/routing";
import currencyEndAdornment from "constants/currencyEndAdornment";
import { ColorPickerProps, colors } from "modules/OrganizationCrews/Create";
import { convertHexToRGB } from "utils/object/getRandomColor";

export interface CrewFormProps {
  onSubmit: SubmitHandler<UpdateCrewInput>;
  loading: boolean;
  crew: Crew;
}

export default function CrewForm({ onSubmit, loading, crew }: CrewFormProps) {
  const [pickedColor, setPickedColor] = useState("#" + crew.color);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    getValues,
  } = useForm<UpdateCrewInput>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(crew),
  });
  const navigate = useNavigate();

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (values: UpdateCrewInput) => {
          // @ts-expect-error: FIX update types
          const { success } = await onSubmit({
            ...values,
            color: pickedColor.substring(1),
          });
          if (success) {
            navigate(getRoute("organization.crews"));
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <FormFields
              register={register}
              errors={errors}
              getValues={getValues}
              control={control}
              setPickedColor={setPickedColor}
            />
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

function FormFields({
  register,
  control,
  errors,
  getValues,
  setPickedColor,
}: {
  register: UseFormRegister<UpdateCrewInput>;
  control: Control<UpdateCrewInput, any>;
  errors: FieldErrorsImpl<DeepRequired<UpdateCrewInput>>;
  getValues: UseFormGetValues<UpdateCrewInput>;
  setPickedColor;
}) {
  const [colorPicker, setColorPicker] = useState<ColorPickerProps>({
    displayColorPicker: false,
    color: {
      hex: "#" + getValues("color"),
      rgb: convertHexToRGB("#" + getValues("color")),
    },
  });

  const handleClick = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: !colorPicker.displayColorPicker });
  };

  const handleClose = () => {
    setColorPicker({ ...colorPicker, displayColorPicker: false });
  };

  const handleChange = (color) => {
    setPickedColor(color.hex);
    setColorPicker({
      ...colorPicker,
      color: {
        hex: color.hex,
        rgb: convertHexToRGB(color.hex),
      },
    });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `rgba(${colorPicker.color?.rgb?.r}, ${colorPicker.color?.rgb?.g}, ${colorPicker.color?.rgb?.b}, 1)`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        maxWidth: "45px",
        marginTop: "20px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const { data } = useGetOrganizationUsers({ page: 1, first: 1000 });
  const usersSelectData = data?.map(({ firstName, lastName, id }) => ({
    label: `${firstName} ${lastName}`,
    value: id,
  }));

  return (
    <>
      <input type="hidden" {...register("id")} />
      <Grid item xs={12} sm={6}>
        <FormField
          type="text"
          label="Internal Crew Name ( This will appear in Work Orders and Scheduling)"
          placeholder="Internal Crew Name"
          error={errors.name}
          {...register("name")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          type="text"
          label="Client Facing Crew Name ( This will appear in Proposals)"
          placeholder="Client Facing Crew Name"
          error={errors.displayName}
          {...register("displayName")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomSelect
          label="Default Foreman"
          control={control}
          fullWidth
          selectVariant="standard"
          data={usersSelectData}
          {...register("foremanUserId")}
        />
        <FormHelperText sx={{ marginLeft: "0px" }}>{errors?.foremanUserId?.message}</FormHelperText>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          align="right"
          type="number"
          label="Rate"
          inputProps={{ min: 0, step: 0.01 }}
          error={errors.rate}
          {...register("rate", { valueAsNumber: true })}
          {...currencyEndAdornment}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          sx={{ m: 1.2, minWidth: "100%", marginLeft: "-3px", marginTop: 0 }}
          error={"color" in errors}
          fullWidth={true}
        >
          <InputLabel
            id="colorpicker-label"
            sx={{ marginLeft: "-12px" }}
            className={"MuiInputLabel-filled"}
            shrink={true}
          >
            Color
          </InputLabel>
          <div style={styles.swatch} onClick={handleClick} id="colorpicker">
            <div style={styles.color} />
          </div>
          {colorPicker.displayColorPicker ? (
            // @ts-expect-error
            <div style={styles.popover}>
              {/* @ts-expect-error */}
              <div style={styles.cover} onClick={handleClose} />
              <CompactPicker
                color={colorPicker.color.hex}
                onChange={handleChange}
                colors={colors}
              />
            </div>
          ) : null}
          <FormHelperText sx={{ marginLeft: "0px" }}>{errors?.color?.message}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
}
