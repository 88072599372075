import {
  Card,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { sentenceCase } from "change-case";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AddJobPhaseCostsInput } from "generated/graphql";
import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { CustomJobPhaseCostItem } from "hooks/jobs/job-phases/useGetJobPhaseCostItem";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import PlantOptions from "modules/plants/PlantOptions";
import { isNilOrEmpty } from "ramda-adjunct";
import { Controller, useFormContext } from "react-hook-form";

export default function MaterialSection({
  jobPhase,
  costItem,
}: {
  jobPhase: CustomJobPhase;
  costItem: CustomJobPhaseCostItem;
}) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<AddJobPhaseCostsInput>();

  return (
    <Card>
      <MDBox sx={{ p: 4, overflow: "auto", maxWidth: "100%" }}>
        <MDTypography>Job Costs - {jobPhase?.organizationProductTypeName}</MDTypography>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell sx={{ minWidth: "200px", width: "33%" }}>Material</TableCell>
              <TableCell sx={{ minWidth: "200px", width: "33%" }}>
                {/**  Fallback string since subcontracted products have measurementMaterial null*/}
                {sentenceCase(jobPhase?.proposalProduct?.measurementMaterial || "Material")} Used
              </TableCell>
              <TableCell sx={{ minWidth: "200px", width: "33%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={jobPhase.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{jobPhase?.proposalProduct?.productName}</TableCell>
              <TableCell>
                <FormField
                  placeholder={jobPhase?.proposedMaterialNeeded}
                  mb={0}
                  defaultValue={costItem?.material?.materialNeeded}
                  sx={{
                    "& .MuiInputBase-root": {
                      "& input": {
                        textAlign: "right",
                      },
                    },
                  }}
                  {...register(`materialNeeded`)}
                  error={errors.materialNeeded}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </MDBox>
    </Card>
  );
}
