import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ProjectFile } from "generated/graphql";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { formatBytes } from "helpers/turfHelper";
import isImage from "utils/images/isImage";

const FileRow = ({
  fileView,
  onClick,
  file,
  onDelete,
  active,
}: {
  fileView?: boolean;
  onClick?: () => void;
  file: ProjectFile;
  onDelete: () => void;
  active?: boolean;
}) => {
  const isPreviewable = isImage(file.path || "");

  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      style={{ background: active ? "#efefef" : "unset" }}
    >
      {fileView ? (
        <TableCell>
          <span onClick={onClick} style={{ cursor: "pointer" }}>
            {file.name}
          </span>
        </TableCell>
      ) : (
        <TableCell>{file.name}</TableCell>
      )}
      <TableCell>{formatBytes(file.fileSizeBytes)}</TableCell>
      <TableCell>
        <Tooltip title={isPreviewable ? "Download" : "View in browser"}>
          <IconButton
            onClick={() => {
              window.open(`${import.meta.env.VITE_CDN_BASE_URL}/${file.path}`, "_blank");
            }}
            disabled={!file.path}
          >
            <Icon>{isPreviewable ? "download" : "visibility"}</Icon>
          </IconButton>
        </Tooltip>

        <IconButton onClick={onDelete}>
          <Icon>delete</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default FileRow;
