import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable/DataTable";
import { ProductMaterialUsed } from "generated/graphql";
import useYearToDateJobCompletionTable from "hooks/dashboard/useYearToDateJobCompletionTable";

const YearToDateJobCompletion = ({ data }: { data: ProductMaterialUsed }) => {
  const { tableData } = useYearToDateJobCompletionTable(data);

  return (
    <Grid item xs={12} md={12} lg={6}>
      <MDBox overflow="hidden" borderRadius="xl" mt={3}>
        <MDBox sx={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }} bgColor="white">
          <MDTypography variant="h6" component="td" p={3} mt={3}>
            Year To Date Job Completion
          </MDTypography>
          {data && <DataTable table={tableData} showTotalEntries={false} />}
        </MDBox>
      </MDBox>
    </Grid>
  );
};

export default YearToDateJobCompletion;
