import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  Company,
  MergeCompanyMutation,
  MergeCompanyMutationVariables,
  useMergeCompanyMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export interface MergeCompanyInput {
  sourceCompany: Company["id"];
  targetCompany: Company["id"];
}

const useMergeCompany = () => {
  const [mutation, { loading, error, data: graphqlData }] = useMergeCompanyMutation({
    refetchQueries: ["getCompanies", "customGetCompanies"],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: MergeCompanyMutation) => {
    return !!data?.mergeCompany;
  }, []);

  const getData = useCallback(({ data }: { data?: MergeCompanyMutation }) => {
    return data?.mergeCompany ?? null;
  }, []);

  const mergeCompany = useCallback(
    withMutationAction(
      async (props: MutationHookOptions<MergeCompanyMutation, MergeCompanyMutationVariables>) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("companies.merge-success"),
        failureMessage: getLocalString("companies.merge-failure"),
      }
    ),
    [mutation]
  );

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  return [mergeCompany, { loading, data, error, getData, isSuccess }] as const;
};

export default useMergeCompany;
