import InfoProposalMenuItem from "components/proposals/MenuItems/InfoProposalMenuItem";
import ViewProposalMenuItem from "components/proposals/MenuItems/ViewProposalMenuItem";
import { Proposal, ProposalStatus } from "generated/graphql";

export default function ChangeOrderMenuItems({
  proposal,
  closeMenu,
}: {
  proposal: Proposal;
  closeMenu: () => void;
}) {
  return (
    <>
      <ViewProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      <InfoProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
    </>
  );
}
