import getLocalString from "constants/Localization";
import { Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CreatePlantInput, CreatePlantMutationResult } from "generated/graphql";
import useCreatePlant from "hooks/organization/plants/useCreatePlant";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Create from "modules/OrganizationPlants/Create";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function PlantCreatePage() {
  const [createPlant, { loading, error, isSuccess, getData }] = useCreatePlant();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (values: CreatePlantInput) => {
      const result = await createPlant({ variables: { input: values } });

      const success = isSuccess(result.data);
      const data = getData(result);

      if (success) {
        navigate(getRoute("organization.plants"));
      }
      return { success, data };
    },
    [createPlant]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {error && (
        <MDAlert color="error">{getLocalString("organization.create-plant-failure")}</MDAlert>
      )}
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Create Plant
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Create onSubmit={onSubmit} loading={loading} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
