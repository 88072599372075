import { capitalCase } from "change-case";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Job } from "generated/graphql";
import useJobsBillingStatuses from "hooks/jobs/useJobsBillingStatuses";
import useUpdateJobBillingStatus from "hooks/jobs/useUpdateJobBillingStatus";

export default function BillingStatusSelection({
  billingStatus,
  jobId,
}: {
  billingStatus: Job["billingStatus"];
  jobId: Job["id"];
}) {
  const { data: enumBillingStatus } = useJobsBillingStatuses();
  const [updateJobBillingStatus, { loading }] = useUpdateJobBillingStatus();

  const handleSubmit = (billingStatusSelected) => {
    updateJobBillingStatus({ variables: { id: jobId, billingStatus: billingStatusSelected } });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "10vw" }}>Billing Status </div>
        <FormControl
          sx={{
            "&": {
              pb: "10px",
              minWidth: "7vw",
            },
          }}
          variant="filled"
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={billingStatus}
            variant="standard"
            label=""
            disabled={loading}
            onChange={(e) => {
              handleSubmit(e.target.value);
            }}
            sx={{
              "& > #mui-component-select-role": {
                padding: "0 0 5px !important",
              },
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {enumBillingStatus?.map((value) => (
              <MenuItem key={value} value={value}>
                {value ? capitalCase(value) : ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}
