import FormField from "components/FormField/FormField";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useCallback, useMemo, useState } from "react";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import currencyEndAdornment from "constants/currencyEndAdornment";
import _ from "lodash";
import MDButton from "components/MDButton";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";

const TransferTruckRow = ({
  row,
  idx,
  productTransferTrucks,
  product,
  inViewMode,
  handleSubmit,
  handleRemoveTruck,
}) => {
  const roundTripTime =
    product.transferTruckRoundTripTimeOverride || product.transferTruckRoundTripTime;
  const totalTime =
    roundTripTime + product.transferTruckLoadTimeBuffer + product.transferTruckUnloadTimeBuffer;
  const dailyUnitsComplete =
    product.material.dailyUnitsCompleteOverride || product.material.dailyUnitsComplete;

  const roundUpTo1 = (value) => (value < 1 ? 1 : value);

  const materialNeeded = product.material.materialNeededOverride || product.material.materialNeeded;
  const totalTripsNeeded = Math.ceil(roundUpTo1(materialNeeded / row.tonnage));

  const trucksNeededDefault = useMemo(() => {
    // If the total material needed is less than the hauling capacity of the truck, set trucksNeeded to 1.
    if (row.tonnage > materialNeeded) {
      return 1;
    }
    const number = (
      (row.trucksNeeded || row.truckingProductionRate / row.tonnage) * totalTime
    ).toFixed(1);
    return +number > totalTripsNeeded ? totalTripsNeeded : number;
  }, [row.tonnage, materialNeeded, row.trucksNeeded, row.truckingProductionRate, totalTime]);

  const [trucksNeeded, setTrucksNeeded] = useState(roundUpTo1(trucksNeededDefault));
  const [totalHaulingHoursOverride, setTotalHaulingHoursOverride] = useState(
    row.totalHaulingHoursOverride
  );
  const [totalCost, setTotalCost] = useState(row.costOverride ? row.costOverride / 100 : null);

  // If our "trucks needed" calculation equates to less than 1 truck, set trucksNeeded to 1.
  const handleSetTrucksNeeded = useCallback((value) => setTrucksNeeded(roundUpTo1(value)), []);

  const [rateOverride, setRateOverride] = useState(
    row.rateOverride ? row.rateOverride / 100 : null
  );

  const [truckingProductionRate, setTruckingProductionRate] = useState(row.truckingProductionRate);
  const handleProductionRateChange = useCallback(
    (value) => {
      const computedValue = value || row.truckingProductionRate;
      setTruckingProductionRate(computedValue);
      handleSetTrucksNeeded(((+computedValue / row.tonnage) * totalTime).toFixed(1));
    },
    [truckingProductionRate]
  );

  const handleTrucksNeededChange = useCallback(
    (value) => {
      const computedValue = value || trucksNeededDefault;
      handleSetTrucksNeeded(computedValue);
      setTruckingProductionRate(Math.round((+computedValue * row.tonnage) / totalTime));
    },
    [trucksNeeded]
  );
  const hasBeenAdded = !!productTransferTrucks.find(
    (pt) => pt.transferTruckId === row.transferTruckId
  );

  const tripsPerTruck = roundUpTo1(+totalTripsNeeded / +trucksNeeded);

  const totalHaulingHoursCalc = useMemo(() => {
    return (
      totalHaulingHoursOverride ||
      (+trucksNeeded * +totalTime * +tripsPerTruck.toFixed(1)).toFixed(2)
    );
  }, [totalHaulingHoursOverride, trucksNeeded]);
  const totalTonnageHauled = +tripsPerTruck * +trucksNeeded * row.tonnage;
  const totalHourlyCost = useMemo(() => {
    return (+totalHaulingHoursCalc * (rateOverride ? rateOverride * 100 : row.rate)).toFixed(2);
  }, [totalHaulingHoursCalc]);

  const rightAlign = {
    "& .MuiInputBase-root": {
      "& input": {
        textAlign: "right",
      },
    },
  };

  return (
    <TableRow
      key={row.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 }, height: "auto" }}
    >
      <TableCell component="th" scope="row" style={{ minWidth: 200 }}>
        {row.name}
      </TableCell>
      <TableCell align="right" sx={{ textAlign: "center" }}>
        {row.tonnage}
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 130, padding: "1px", px: "15px" }}>
        <FormField
          disabled={inViewMode}
          align="right"
          mb={0}
          type="number"
          inputProps={{ min: 0, step: 1 }}
          placeholder={row.rate / 100}
          value={rateOverride}
          {...currencyEndAdornment}
          sx={{ rightAlign, padding: "1px", px: "15px" }}
          onChange={(e) => setRateOverride(e.target.value)}
        />
      </TableCell>
      <TableCell sx={{ padding: "1px", px: "15px" }}>
        <FormField
          disabled={inViewMode}
          align="right"
          mb={0}
          type="text"
          placeholder={trucksNeeded}
          inputProps={{ min: 0, step: 0.1 }}
          sx={{ rightAlign, padding: "1px", px: "15px" }}
          onChange={(e) => {
            handleTrucksNeededChange(e.target.value);
          }}
        />
      </TableCell>
      <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
        <FormField
          disabled={inViewMode}
          align="right"
          mb={0}
          type="number"
          inputProps={{ min: 0, step: 0.1 }}
          placeholder={truckingProductionRate}
          sx={{ rightAlign, padding: "1px", px: "15px" }}
          onChange={(e) => {
            handleProductionRateChange(e.target.value);
          }}
        />
      </TableCell>
      <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
        {" "}
        <FormField
          disabled={inViewMode}
          align="right"
          mb={0}
          type="number"
          placeholder={row.totalHaulingHours ?? totalHaulingHoursCalc}
          inputProps={{ min: 0, step: 0.1 }}
          sx={{ rightAlign, padding: "1px", px: "15px" }}
          value={totalHaulingHoursOverride}
          onChange={(e) => {
            setTotalHaulingHoursOverride(e.target.value);
          }}
        />
      </TableCell>
      <TableCell align="right" sx={{ padding: "1px", textAlign: "center" }}>
        {+totalTripsNeeded}
      </TableCell>
      <TableCell align="right" sx={{ padding: "1px", textAlign: "center" }}>
        {(+tripsPerTruck).toFixed(1)}
      </TableCell>
      <TableCell align="right" sx={{ padding: "1px", textAlign: "center" }}>
        {(+totalTonnageHauled).toFixed(1)}
      </TableCell>
      {!product.plantProduct?.plant?.rateOnWeight && (
        <TableCell align="right" sx={{ minWidth: 200, padding: "1px", px: "15px" }}>
          <FormField
            type="text"
            mb={0}
            fullWidth
            disabled={inViewMode}
            align="right"
            placeholder={(+totalHourlyCost / 100).toFixed(2)}
            sx={{ rightAlign, padding: "20px" }}
            value={totalCost}
            {...currencyEndAdornment}
            onChange={(e) => setTotalCost(e.target.value)}
            inputProps={{ step: "0.01", min: "0" }}
          />
        </TableCell>
      )}
      {/* {!product.plantProduct?.plant?.rateOnWeight && (
        <TableCell align="right">
          {formatCentsToUSD((+totalHourlyCost / materialNeeded).toFixed(2))}
        </TableCell>
      )} */}
      {product.plantProduct?.plant?.rateOnWeight && (
        <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
          {formatCentsToUSD(
            +tripsPerTruck.toFixed(1) *
              row.tonnage *
              +trucksNeeded *
              +(rateOverride || row.rate / 100) *
              100
          )}
        </TableCell>
      )}

      <TableCell align="right" sx={{ padding: "1px", px: "15px" }}>
        <MDButton
          sx={{
            minHeight: "5px",
            padding: "2px 15px",
            minWidth: "5px",
            margin: "5px",
            fontSize: "20px",
          }}
          color="blue"
          size="small"
          onClick={() =>
            handleSubmit({
              transferTruckId: row.transferTruckId,
              proposalProductId: product.id,
              rate: rateOverride ? +rateOverride * 100 : null,
              truckingProductionRate: +truckingProductionRate,
              quantity: +trucksNeeded,
              totalHaulingHoursOverride: totalHaulingHoursOverride
                ? +totalHaulingHoursOverride
                : null,
              costOverride: totalCost ? +totalCost * 100 : null,
            })
          }
          disabled={!trucksNeeded || !truckingProductionRate}
        >
          {hasBeenAdded ? "Save" : "+"}
        </MDButton>
        {hasBeenAdded && (
          <MDButton
            color="error"
            variant="text"
            size="small"
            onClick={() => handleRemoveTruck(row.productTransferTruckId)}
          >
            Remove
          </MDButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default TransferTruckRow;
