import ActionsCell from "modules/OrganizationProductTypes/ActionsCell";
import ProductTypeNameCell from "modules/OrganizationProductTypes/ProductTypeNameCell";
import ProductTypeProductsLink from "modules/OrganizationProductTypes/ProductTypeProductsLink";
import ProductTypeTypeCell from "modules/OrganizationProductTypes/ProductTypeTypeCell";
import { useMemo } from "react";
import useGetOrganizationProductTypes from "./useGetOrganizationProductTypes";

export default function useGetOrganizationProductTypesTable() {
  const { loading, data, error } = useGetOrganizationProductTypes();

  const columns = useMemo(() => {
    return [
      { Header: "Name", accessor: "name", Cell: ProductTypeNameCell },
      { Header: "Type", accessor: "type", Cell: ProductTypeTypeCell },
      { Header: "Markup %", accessor: "markupPercentage" },
      { Header: "Indirect %", accessor: "indirectPercentage" },
      { Header: "Overhead %", accessor: "overheadPercentage" },
      { Header: "Products", Cell: ProductTypeProductsLink },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return { loading, data, error, columns, tableData } as const;
}
