import getLocalString from "constants/Localization";
import { useAddJobPhaseFileMutation, useDeleteJobPhaseFileMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteJobPhaseFile() {
  const [mutation, { loading, data, error }] = useDeleteJobPhaseFileMutation();
  const [deleteJobPhaseFile, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteJobPhaseFile",
    },
    {
      successMessage: getLocalString("jobPhases.delete-job-phase-file-success"),
      failureMessage: getLocalString("jobPhases.delete-job-phase-file-failure"),
    }
  );

  return [deleteJobPhaseFile, context] as const;
}
