import { useMemo } from "react";
import { AdditionalCostFeeType } from "generated/graphql";
import ActionsCell from "modules/OrganizationAdditionalCosts/ActionsCell";
import usePaginatedResources from "hooks/usePaginatedResources";
import useGetAdditionalCosts from "./useGetAdditionalCosts";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import feeTypeMapper from "constants/feeTypeMapper";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { Link } from "react-router-dom";

export default function useAdditionalCostsTable() {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo } = useGetAdditionalCosts({
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sort: sortDirectionMap[sorting.sortDirection],
  });
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const showType = ({ value }: { value: any; row: any }) => {
    return feeTypeMapper[value] ?? "";
  };

  const showFee = ({ value, row }: { value: any; row: any }) => {
    // dont divide value by 100
    return `${value}${row.original.feeType == AdditionalCostFeeType.FLAT ? "" : "%"}`;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => (
          <Link
            style={{ color: "inherit" }}
            to={`/organization/additional-costs/${row.original.id}`}
          >
            {value}
          </Link>
        ),
      },
      { Header: "Fee Type", accessor: "feeType", Cell: showType },
      { Header: "Fee Amount", accessor: "feeAmount", align: "right", Cell: showFee },
      {
        Header: "Cost",
        accessor: "cost",
        align: "right",
        Cell: ({ value }) => formatCentsToUSD(value),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    paginatorInfo,
    filtering,
  } as const;
}
