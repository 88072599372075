import getLocalString from "constants/Localization";
import { useUpdateOrganizationProductTypeMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateOrganizationProductType() {
  const [mutation, { loading, error, data }] = useUpdateOrganizationProductTypeMutation({
    refetchQueries: ["getOrganizationProductTypes", "customGetOrganizationProductTypes"],
  });

  const [updateOrganizationProductType, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateOrganizationProductType",
    },
    {
      successMessage: getLocalString("product-types.update-success"),
      failureMessage: getLocalString("product-types.update-failure"),
    }
  );

  return [updateOrganizationProductType, context] as const;
}
