const organizationDataImportTypeMapper = {
  ADDITIONAL_COSTS: "Additional Costs",
  COMPANIES: "Companies",
  CONTACTS: "Contacts",
  CREWS: "Crews",
  EQUIPMENT_PIECES: "Equipment Pieces",
  PLANTS: "Plants",
  TRANSFER_TRUCKS: "Transfer Trucks",
};
export default organizationDataImportTypeMapper;
