import { ResetPasswordInput } from "generated/graphql";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodPasswordValidator } from "utils/validations/isValidPassword";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { zodEmailValidator } from "utils/validations/isValidEmail";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDPasswordInput from "components/MDPasswordInput/MDPasswordInput";

interface ResetPasswordFormProps {
  onSubmit: SubmitHandler<ResetPasswordInput>;
  loading: boolean;
  email: ResetPasswordInput["email"];
  token: ResetPasswordInput["token"];
}

const schema = z.object({
  password: zodPasswordValidator,
  email: zodEmailValidator,
  token: z.string(),
});

export default function ResetPasswordForm({
  onSubmit,
  loading,
  token,
  email,
}: ResetPasswordFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      token,
      email,
      password: "",
    },
  });

  return (
    <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
      <MDBox mb={2}>
        <input type="hidden" {...register("email")} />
        <input type="hidden" {...register("token")} />
        <MDPasswordInput
          type="password"
          label="Password"
          variant="standard"
          placeholder="Password"
          fullWidth
          required
          {...register("password")}
          errror={errors.password}
        />
        {errors.password && (
          <MDTypography color="error" variant="caption">
            {errors.password.message}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
          Reset Password
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
