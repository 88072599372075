import { useMemo } from "react";
import ActionsCell from "modules/OrganizationEquipmentPieces/ActionsCell";
import usePaginatedResources from "hooks/usePaginatedResources";
import useGetEquipmentPieces from "./useGetEquipmentPieces";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { capitalCase } from "change-case";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { Link } from "react-router-dom";
import { EquipmentPieceChargeType } from "generated/graphql";

export default function useEquipmentPiecesesTable() {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo, getData, getPaginatorInfo } = useGetEquipmentPieces({
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sort: sortDirectionMap[sorting.sortDirection],
  });
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => (
          <Link style={{ color: "inherit" }} to={`/organization/equipment/${row.original.id}`}>
            {value}
          </Link>
        ),
      },
      {
        Header: "Charge Type",
        accessor: "chargeType",
        Cell: ({ value }) => <>{capitalCase(value)}</>,
      },
      {
        Header: "Rate",
        accessor: "rate",
        align: "right",
        Cell: ({ row, value }) => <>{formatCentsToUSD(value)}</>,
      },
      {
        Header: "Fuel Surcharge",
        align: "right",
        accessor: "fuelSurcharge",
        Cell: ({ value }) => <>{formatCentsToUSD(value)}</>,
      },
      {
        Header: "Percentage",
        accessor: "usePercentage",
        align: "right",
        Cell: ({ value }) => <>{value}%</>,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ActionsCell,
        align: "right",
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    paginatorInfo,
    filtering,
  } as const;
}
