import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Colors } from "constants/Colors";
import moment from "moment";
import { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

export const getListOfDays = () => {
  const list = [];
  const date = new Date();
  const from = moment(date);
  const to = moment(date).add(28, "days");
  for (let m = moment(from); m.isBefore(to); m.add(1, "days")) {
    list.push(m.format("YYYY-MM-DD"));
  }
  return list;
};

const MaterialNeededPerDay = ({ data, allMaterials }) => {
  const chartData = useMemo(() => {
    const emptyMaterialObject = {
      materialNeeded: 0,
      amount: 0,
    };

    // fill in empty dates and afterwards fill in empty materials
    return getListOfDays()
      .map((date) => {
        const daySchedule = data?.find((item) => item.date === date);
        return daySchedule
          ? { ...daySchedule, date: moment(daySchedule.date).format("M/DD") }
          : { date: moment(date).format("M/DD") };
      })
      ?.map((element) => {
        const elementCopy = { ...element };
        allMaterials.forEach((item) => {
          if (!elementCopy[item]) {
            elementCopy[item] = emptyMaterialObject;
          }
        });
        return elementCopy;
      });
  }, [data]);

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <MDBox
          width={300}
          height="auto"
          sx={{ background: "rgba(0,0,0,0.8)" }}
          fontSize={12}
          p={1}
          borderRadius="5px"
        >
          <MDBox color="white" mb={1} fontWeight="bold">
            {payload[0].payload.date}
          </MDBox>
          {payload.map((material, idx) => {
            //@ts-ignore
            const materialName = material.name.replace(".materialNeeded", "");
            return (
              <MDBox key={material.name}>
                <MDBox
                  width={10}
                  height={10}
                  sx={{ background: Colors[idx] || Colors[Colors.length % idx] }}
                  display="inline-block"
                  mr={1}
                />
                <MDBox
                  display="inline-block"
                  color="white"
                  key={material.name}
                >{`${materialName} (${formatCentsToUSD(
                  material.payload[materialName].amount
                )}): ${material.payload[materialName].materialNeeded.toFixed(2)}`}</MDBox>
              </MDBox>
            );
          })}
        </MDBox>
      );
    }

    return null;
  };

  return (
    <MDBox bgColor="white" borderRadius="xl" p={3} sx={{ boxShadow: "3px 2px 2px #ddd" }}>
      <MDTypography variant="h6" mb={3}>
        Material Needed Per Day
      </MDTypography>
      <ResponsiveContainer width="100%" height={312}>
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" interval={7} fontSize={13} tickMargin={20} stroke="#aaa" />
          <YAxis fontSize={13} stroke="#aaa" />
          <Tooltip content={<CustomTooltip />} />

          {allMaterials.map((material, idx) => (
            <Line
              key={idx}
              type="monotone"
              dataKey={`${material}.materialNeeded`}
              stroke={Colors[idx] || Colors[Colors.length % idx]}
              strokeWidth={4}
              activeDot={{ r: 8 }}
              dot={{ fill: Colors[idx] || Colors[Colors.length % idx], r: 4 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </MDBox>
  );
};

export default MaterialNeededPerDay;
