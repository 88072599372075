import * as z from "zod";

export const errorMessages = {
  min: "Must be at least 8 characters long",
  "uppercase-lowercase": "Must contain upper and lowercase characters",
  number: "Must contain at least 1 number",
  symbol: "Must contain at least 1 symbol",
};

export const isValidPasswordWithReason = (password: string): [boolean, string] => {
  // 8 chars long
  if (password.length < 8) {
    return [false, errorMessages.min];
  }
  // Must contain uppercase and lowercase
  if (!password.match(/(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u)) {
    return [false, errorMessages["uppercase-lowercase"]];
  }

  // Must contain at least 1 number
  if (!password.match(/\d/)) {
    return [false, errorMessages.number];
  }

  // Must contain at least 1 symbol
  if (!password.match(/\p{Z}|\p{S}|\p{P}/u)) {
    return [false, errorMessages.symbol];
  }

  return [true, "valid"];
};

export const isValidPassword = (password: string): boolean => {
  return isValidPasswordWithReason(password)[0];
};

export const zodPasswordValidator = z
  .string()
  .min(8, errorMessages.min)
  .regex(/(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u, errorMessages["uppercase-lowercase"])
  .regex(/\d/, errorMessages.number)
  .regex(/\p{Z}|\p{S}|\p{P}/u, errorMessages.symbol);

export default isValidPassword;
