import { useMemo } from "react";
import ActionsCell from "modules/OrganizationTransferTrucks/ActionsCell";
import useGetTransferTrucks from "./useGetTransferTrucks";
import { Link } from "react-router-dom";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

export default function useTransferTrucksTable() {
  const { data, loading, error } = useGetTransferTrucks();

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => (
          <Link
            style={{ color: "inherit" }}
            to={`/organization/transfer-trucks/${row.original.id}`}
          >
            {value}
          </Link>
        ),
      },
      { Header: "Tonnage", accessor: "defaultTonnage" },
      {
        Header: "Hourly Rate",
        accessor: "defaultRateHourly",
        Cell: ({ value }) => formatCentsToUSD(value),
      },
      {
        Header: "Rate per Ton",
        accessor: "defaultRateTon",
        Cell: ({ value }) => formatCentsToUSD(value),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
  } as const;
}
