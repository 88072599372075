import { AdditionalCost } from "generated/graphql";
import Delete from "./Delete";
import Update from "./Update";

export default function MenuItems({
  id,
  closeMenu,
}: {
  id: AdditionalCost["id"];
  closeMenu: () => void;
}) {
  return (
    <>
      <Update closeMenu={closeMenu} id={id} />
      <Delete closeMenu={closeMenu} id={id} />
    </>
  );
}
