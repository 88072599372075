import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { TransferTruck } from "generated/graphql";
import useGetTransferTruck from "hooks/organization/useGetTransferTruck";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Update from "modules/OrganizationTransferTrucks/Update";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function TransferTruckEditPage() {
  const { truckId } = useParams();
  const [getTransferTruck, { loading, error, data }] = useGetTransferTruck();

  useEffect(() => {
    getTransferTruck({ id: truckId });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load transfer truck details. Please refresh this page or try again later.
        </MDAlert>
      )}
      {!error && (
        <MDBox my={3}>
          <MDBox mb={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h4" fontWeight="medium">
                  Updating Transfer Truck
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {data && <Update transferTruck={data} />}
              {!data && <Skeleton />}
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
