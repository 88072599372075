import { CustomEvent } from "generated/graphql";
import { custom, z } from "zod";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const blocksSchema = z.object({
  id: z.string().optional(),
  scheduledAt: z.string().min(1, "Scheduled at is required"),
  blockLength: z.number().positive(),
});

export const createSchema = z.object({
  name: z.string().min(1, "Name is required"),
  assignTo: z.string().optional(),
  notes: z.string().optional(),
  crewId: z.string().optional(),
  userIDs: z.array(z.string()).optional(),
  color: z.string().optional(),
  blocks: z.array(blocksSchema),
});

export const updateSchema = z.object({
  id: z.string().min(1, "id is required"),
  assignTo: z.string().optional(),
  name: z.string().min(1, "Name is required"),
  notes: z.string().optional(),
  crewId: z.string().optional(),
  userIDs: z.array(z.string()).optional(),
  color: z.string().optional(),
  blocks: z.array(blocksSchema),
});

export function getDefaultValues(customEvent?: CustomEvent) {
  // if there are no jobSchedules we need to return the blocks that
  // are limited to the daily block without a start date or end date
  if (customEvent) {
    return {
      id: customEvent.id,
      assignTo: customEvent?.crew?.id ? "crew" : "individual",
      name: customEvent?.name,
      crewId: customEvent?.crew?.id ?? "",
      userIDs: customEvent?.customEventsToUsers?.map((x) => x.user?.id) ?? [],
      color: customEvent?.color,
      notes: customEvent?.notes,
      blocks:
        customEvent.customEventBlocks?.length > 0
          ? customEvent.customEventBlocks.map((block) => ({
              id: block.id,
              scheduledAt: dayjs.utc(block.scheduledAt).local().format("YYYY-MM-DD HH:mm:ss"),
              blockLength: block.blockLength,
            }))
          : [
              {
                scheduledAt: "",
                blockLength: 0,
              },
            ],
    };
  } else {
    return {
      id: "",
      name: "",
      assignTo: "",
      crewId: "",
      userIDs: [],
      blocks: [
        {
          scheduledAt: "",
          blockLength: 0,
        },
      ],
      color: "",
    };
  }
}
