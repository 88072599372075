import getLocalString from "constants/Localization";
import {
  CustomGetProductsDocument,
  useCustomDuplicateProductMutation,
  QueryGetProductsOrderByColumn,
  SortOrder,
  CustomGetProductsQueryVariables,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { sortDirectionMap } from "constants/sortDirectionMap";

import useBitumioMutation from "hooks/useBitumioMutation";

const productsInitialSortDirectionValue = SortOrder.ASC;

export default function useDuplicateProduct({
  organizationProductTypeId,
}: Partial<CustomGetProductsQueryVariables>) {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const [mutation, { loading, data, error }] = useCustomDuplicateProductMutation({
    refetchQueries: [
      {
        query: CustomGetProductsDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
          orderBy: [
            {
              column: QueryGetProductsOrderByColumn.NAME,
              order: sortDirectionMap[sorting.sortDirection] || productsInitialSortDirectionValue,
            },
          ],
          organizationProductTypeId: organizationProductTypeId,
        },
      },
    ],
  });

  const [duplicateProduct, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "duplicateProduct",
    },
    {
      successMessage: getLocalString("products.duplicate-success"),
      failureMessage: getLocalString("products.duplicate-failure"),
    }
  );

  return [duplicateProduct, context] as const;
}
