import { Card, Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import { useGetTimeBuckets } from "hooks/timeTracking/useGetTimeBuckets";
import TimeBucketItem from "./TimeBucketItem";
import CreateTimeBucket from "./CreateTimeBucket";

export default function TimeBucketsList() {
  const { loading, error, data } = useGetTimeBuckets();

  return (
    <Card>
      <MDBox p={3}>
        <MDBox
          mb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxWidth={650}
        >
          <MDTypography variant="h4" fontWeight="medium">
            Time Buckets
          </MDTypography>
          <CreateTimeBucket />
        </MDBox>
        {loading && <TableSkeleton numberOfLines={5} />}
        {error && <MDAlert color="error">{error.message}</MDAlert>}
        {!loading && !error && (
          <Grid container maxWidth={650}>
            {data.map((bucket) => (
              <TimeBucketItem key={bucket.id} bucket={bucket} />
            ))}
          </Grid>
        )}
      </MDBox>
    </Card>
  );
}
