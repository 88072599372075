import { AddCircleOutlineOutlined } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  CustomAddProposalStageMutationVariables,
  Proposal,
  ProposalStage,
} from "generated/graphql";
import useCustomAddProposalStage from "hooks/proposals/useCustomAddProposalStage";
import { useCallback } from "react";

export default function AddStage({
  proposal,
  onSuccess,
  disabled,
}: {
  proposal: Proposal;
  onSuccess: (addedStage: ProposalStage) => void;
  disabled: boolean;
}) {
  const [addProposalStage, { isSuccess, getData }] = useCustomAddProposalStage({
    proposalId: proposal.id,
  });

  const handleAddProposalStage = useCallback(
    async (values: CustomAddProposalStageMutationVariables) => {
      const result = await addProposalStage({
        variables: values,
      });
      const success = isSuccess(result.data);
      const data = getData(result);

      if (success) {
        onSuccess(data as ProposalStage);
      }

      return { success, data };
    },
    [addProposalStage, onSuccess]
  );

  return (
    <div>
      <MDButton
        type="button"
        color="blue"
        onClick={() => {
          handleAddProposalStage({ proposalId: proposal.id });
        }}
        disabled={disabled}
      >
        <AddCircleOutlineOutlined />{" "}
        <MDTypography variant="body2" ml={2} color="#FFF">
          Add Stage
        </MDTypography>
      </MDButton>
    </div>
  );
}
