import ActionMenuItem from "components/Shared/ActionMenuItem/ActionMenuItem";
import { Employee, UserPermissions } from "generated/graphql";
import Delete from "./Delete";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export default function MenuItems({
  id,
  closeMenu,
}: {
  id: Employee["id"];
  closeMenu: () => void;
}) {
  const closeDelete = () => {
    closeMenu();
    location.reload();
  };
  const { hasPermissions } = useCheckPermissions();
  if (!hasPermissions(UserPermissions.MANAGE_EMPLOYEES)) {
    return <></>;
  }
  return (
    <>
      <ActionMenuItem
        closeMenu={closeMenu}
        route={`/organization/employees/${id}/update`}
        text="Edit"
      />
      <Delete closeMenu={closeDelete} id={id} />
    </>
  );
}
