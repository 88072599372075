import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  CreateAdditionalCostMutation,
  useCreateAdditionalCostMutation,
  CreateAdditionalCostMutationResult,
  CreateAdditionalCostMutationVariables,
  GetAdditionalCostsDocument,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { useCallback, useMemo } from "react";

export default function useCreateAdditionalCost() {
  const { offset, filtering } = usePaginatedVariables();
  const [mutation, values] = useCreateAdditionalCostMutation({
    refetchQueries: [
      {
        query: GetAdditionalCostsDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
        },
      },
    ],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = (data: CreateAdditionalCostMutation) => {
    return (data && !!data.createAdditionalCost) || false;
  };

  const getData = ({ data }: { data?: CreateAdditionalCostMutation }) => {
    return (data && data.createAdditionalCost) || null;
  };

  const createAdditionalCost = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          CreateAdditionalCostMutation,
          CreateAdditionalCostMutationVariables
        >
      ) => {
        return (await mutation({
          variables: {
            input: {
              ...props.variables.input,
              feeAmount: Number(props.variables.input.feeAmount),
              cost: Number(props.variables.input.cost),
            },
          },
        })) as CreateAdditionalCostMutationResult;
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.create-additional-cost-success"),
        failureMessage: getLocalString("organization.create-additional-cost-failure"),
      }
    ),
    [mutation]
  );

  const success = useMemo(() => {
    return isSuccess(values.data);
  }, [values.data]);

  const data = useMemo(() => {
    return (values.data && values.data.createAdditionalCost) || null;
  }, [values.data]);

  return [
    createAdditionalCost,
    { data, error: values.error, loading: values.loading, success, isSuccess, getData },
  ] as const;
}
