import getLocalString from "constants/Localization";
import { GetTransferTrucksDocument, useCreateTransferTruckMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreateTransferTruck() {
  const [mutation, { loading, data, error }] = useCreateTransferTruckMutation({
    refetchQueries: [
      {
        query: GetTransferTrucksDocument,
        variables: {},
      },
    ],
  });

  const [createTransferTruck, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createTransferTruck",
    },
    {
      successMessage: getLocalString("organization.create-transfer-truck-success"),
      failureMessage: getLocalString("organization.create-transfer-truck-failure"),
    }
  );

  return [createTransferTruck, context] as const;
}
