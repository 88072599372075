import { sortDirectionMap } from "constants/sortDirectionMap";
import {
  OrganizationProductType,
  QueryGetProductsOrderByColumn,
  SortOrder,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import ProductActionsCell from "modules/products/ProductActionsCell/ProductActionsCell";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";
import useGetProducts from "./useGetProducts";

const productsInitialSortDirectionValue = SortOrder.ASC;

export default function useProductsTable({
  productTypeId,
}: {
  productTypeId: OrganizationProductType["id"];
}) {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo } = useGetProducts({
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    orderBy: [
      {
        column: QueryGetProductsOrderByColumn.NAME,
        order: sortDirectionMap[sorting.sortDirection] || productsInitialSortDirectionValue,
      },
    ],
    organizationProductTypeId: productTypeId,
  });
  const pagination = usePaginatedResources({
    paginatorInfo,
    paginate: offset.paginate,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Product Name",
        accessor: "name",
        width: "80%",
        Cell: ({ value, row }) => (
          <Link
            style={{ color: "inherit" }}
            to={getRoute("product-types.products.update", [
              ["productTypeId", productTypeId],
              ["productId", row.values.id],
            ])}
          >
            {row.original.subcontracted && `[Subcontracted]`}{" "}
            {row.original.unitPriceProduct && `[Unit Priced]`} {value}
          </Link>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ProductActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
  } as const;
}
