import "components/Map/Map.css";
import "leaflet/dist/leaflet.css";
import Map from "components/Map/Map";
import PageLayout from "layouts/PageLayout/PageLayout";

const isTouchDevice = (): boolean => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
};

const useTouchControls = (): boolean => {
  const isMobile = isTouchDevice();
  return isMobile;
};

const isTouch = useTouchControls();

export default function MapPage() {
  const createIslandConfirmation = (callback: () => void): boolean => {
    if (confirm("Do you want to create an island?")) {
      callback();
      return true;
    }

    return false;
  };

  return (
    <PageLayout>
      <Map
        onSave={null}
        readonly={false}
        createIslandConfirmation={createIslandConfirmation}
        initialArea={null}
        initialFeatures={null}
        initialCenter={null}
        guidedControls={window.location.search.indexOf("guided") !== -1}
        isTouch={isTouch}
        goBack
      />
    </PageLayout>
  );
}
