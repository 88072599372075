import {
  GetManageSubscriptionUrlQuery,
  useGetManageSubscriptionUrlLazyQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetManageSubscriptionUrl() {
  const [query, { data, loading, error }] = useGetManageSubscriptionUrlLazyQuery();

  const getUrl = useCallback((data: GetManageSubscriptionUrlQuery) => {
    return data.getManageSubscriptionUrl;
  }, []);

  const url = useMemo(() => {
    return (data && getUrl(data)) || null;
  }, [data]);

  const getManageSubscriptionUrl = useCallback(async () => {
    const response = await query();
    return getUrl(response.data);
  }, [query]);

  return [getManageSubscriptionUrl, { data, loading, error, url }] as const;
}
