import lengthEndAdornment from "constants/lenghtEndAdornment";
import tooltipEndAdornment from "constants/tootipEndAdornment";

type ProductAttributeNames = {
  [index: string]: { label: string; valueAsNumber: boolean; endAdornment?: any };
};

const productAttributesNames: ProductAttributeNames = {
  applicationRate: {
    label: "Application Rate",
    valueAsNumber: true,
    endAdornment: { ...tooltipEndAdornment },
  },
  asphaltDepth: {
    label: "Asphalt Depth In Inches",
    valueAsNumber: true,
    endAdornment: { ...lengthEndAdornment },
  },
  crackDepth: {
    label: "Crack Depth In Inches",
    valueAsNumber: true,
  },
  crackWidth: {
    label: "Crack Width In Inches",
    valueAsNumber: true,
  },
  curbHeight: {
    label: "Curb Height",
    valueAsNumber: true,
  },
  curbWidth: {
    label: "Curb Width",
    valueAsNumber: true,
  },
  gutterHeight: {
    label: "Gutter Height",
    valueAsNumber: true,
  },
  gutterWidth: {
    label: "Gutter Width",
    valueAsNumber: true,
  },
  lineWidth: {
    label: "Line Width In Inches",
    valueAsNumber: true,
  },
  overbandThickness: {
    label: "Overband Thickness In Inches",
    valueAsNumber: true,
    endAdornment: { ...tooltipEndAdornment },
  },
  overbandWidth: {
    label: "Overband Width In Inches",
    valueAsNumber: true,
  },
  poundsPerBlock: {
    label: "Pounds Per Block",
    valueAsNumber: true,
  },
  poundsPerGallon: {
    label: "Pounds Per Gallon",
    valueAsNumber: true,
  },
  transferTruckLoadTime: {
    label: "Transfer Truck Load Time",
    valueAsNumber: true,
  },
  transferTruckUnloadTime: {
    label: "Transfer Truck Unload Time",
    valueAsNumber: true,
  },
  poundsPerCubicYard: {
    label: "Pounds Per Cubic Yard",
    valueAsNumber: true,
  },
};

export default productAttributesNames;
