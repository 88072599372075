import { QueryHookOptions } from "@apollo/client";
import { GetPlantQuery, GetPlantQueryVariables, useGetPlantQuery } from "generated/graphql";

export default function useGetPlant(
  options: QueryHookOptions<GetPlantQuery, GetPlantQueryVariables>
) {
  const { loading, data: graphqlData, error } = useGetPlantQuery(options);

  return {
    loading,
    data: graphqlData?.getPlant ?? null,
    error,
  };
}
