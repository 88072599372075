import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { range } from "ramda";

export default function generateBlocksForJobPhase({
  jobPhase,
  organizationSettings,
  showTravelTo,
  showTravelFrom,
}: {
  jobPhase: CustomJobPhase;
  organizationSettings: Record<string, string>;
  showTravelTo: boolean;
  showTravelFrom: boolean;
}) {
  const { average_working_hours_per_day } = organizationSettings;
  const daysRequired = jobPhase.jobDuration / +average_working_hours_per_day;
  const blocksRequired = Math.ceil(daysRequired);

  const blocks = range(1, blocksRequired + 1).map((blockIndex) => {
    return {
      scheduledAt: "",
      blockLength:
        blockIndex > daysRequired
          ? +((daysRequired % Math.floor(daysRequired)) * +average_working_hours_per_day).toFixed(2)
          : +average_working_hours_per_day,
      showTravelTo,
      showTravelFrom,
    };
  });

  return blocks;
}
