import getLocalString from "constants/Localization";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { GetOpportunitiesDocument, useCreateOpportunityMutation } from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";

import useBitumioMutation from "hooks/useBitumioMutation";
import {
  opportunitiesInitialSortDirectionValue,
  opportunitiesInitialSortKeyValue,
  opportunitiesSortKeyMap,
} from "./useOpportunitiesTable";

export default function useCreateOpportunity() {
  const { offset, filtering, sorting } = usePaginatedVariables();
  const [mutation, { loading, data, error }] = useCreateOpportunityMutation({
    refetchQueries: [
      {
        query: GetOpportunitiesDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
          sortKey: opportunitiesSortKeyMap[sorting.sortKey] || opportunitiesInitialSortKeyValue,
          sortDirection:
            sortDirectionMap[sorting.sortDirection] || opportunitiesInitialSortDirectionValue,
        },
      },
    ],
  });

  const [createOpportunity, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "createOpportunity",
    },
    {
      successMessage: getLocalString("opportunities.create-success"),
      failureMessage: getLocalString("opportunities.create-failure"),
    }
  );

  return [createOpportunity, context] as const;
}
