import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { Crew } from "generated/graphql";
import useDeleteCrew from "hooks/organization/useDeleteCrew";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export default function Delete({ closeMenu, id }: { closeMenu: () => void; id: Crew["id"] }) {
  const [deleteCrew, { isSuccess }] = useDeleteCrew();

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const { data } = await deleteCrew({ variables: { id } });

    if (isSuccess(data)) {
      closeMenu();
      return true;
    } else {
      return false;
    }
  }, [deleteCrew, id]);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you want to remove this crew?"
      />
      <MenuItem data-test-id="action-delete" onClick={() => setOpen(true)}>
        Remove
      </MenuItem>
    </>
  );
}
