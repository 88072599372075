import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useGetOrganization from "hooks/organization/useGetOrganization";
import useUpdateOrganizationProposalDefaults from "hooks/organization/useUpdateOrganizationProposalDefaults";
import {
  ProposalFormsContext,
  ProposalFormsContextValue,
} from "modules/proposals/providers/ProposalFormsContextProvider";
import { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import secondsLeft from "utils/dates/secondsLeft";

const PendingChangesContent = () => {
  const { productsFormsStatus, productsSubmitFunctions, autoSave, proposalDefaultsId } =
    useContext(ProposalFormsContext);
  const productsWithPendingChanges = Object.keys(productsFormsStatus).filter(
    (productId) => productsFormsStatus[productId].isDirty
  );

  return (
    <>
      {productsWithPendingChanges.map((productId, i) => {
        const isDirty = productsFormsStatus[productId].isDirty;
        const isRejected = productsFormsStatus[productId].status === "rejected";
        const isPending = isDirty || isRejected;

        return isPending ? (
          <MDBox
            key={productId}
            sx={{
              width: "100%",
              display: "flex",
              background: "#fcfcfc",
              height: `${64 + productsWithPendingChanges.length * 2 - i * 2}px`,
              borderTopLeftRadius: "32px",
              borderTopRightRadius: "32px",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: 3,
              zIndex: 200000 + productsWithPendingChanges.length - i,
            }}
          >
            {isRejected ? (
              <>
                <MDTypography>Failed to automatically save. Please save manually</MDTypography>
                &nbsp;&nbsp;
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => {
                    productsSubmitFunctions[productId]?.();
                  }}
                >
                  Save
                </MDButton>
              </>
            ) : (
              <PendingChangesCountdown
                attemptedToSaveAt={productsFormsStatus[productId].attemptToSaveAt}
                productsSubmitFunctions={productsSubmitFunctions}
                productId={productId}
                autoSave={autoSave}
                proposalDefaultsId={proposalDefaultsId}
              />
            )}
          </MDBox>
        ) : null;
      })}
    </>
  );
};

function PendingChangesCountdown({
  attemptedToSaveAt,
  productsSubmitFunctions,
  productId,
  autoSave,
  proposalDefaultsId,
}: {
  attemptedToSaveAt: number;
  productsSubmitFunctions: ProposalFormsContextValue["productsSubmitFunctions"];
  productId: string;
  autoSave: boolean;
  proposalDefaultsId: string;
}) {
  const [remainingSeconds, setRemainingSeconds] = useState(secondsLeft(attemptedToSaveAt));
  const interval = useRef<NodeJS.Timer | null>(null);
  const [updateOrganizationProposalDefaults] = useUpdateOrganizationProposalDefaults();

  useEffect(() => {
    if (autoSave) {
      interval.current = setInterval(() => {
        setRemainingSeconds(secondsLeft(attemptedToSaveAt));
      }, 1000);
      // @ts-ignore
      return () => interval.current && clearInterval(interval.current);
    }
  }, [attemptedToSaveAt, autoSave]);

  return (
    <>
      <MDTypography>
        {autoSave
          ? `Pending Changes saving in ${remainingSeconds > 0 ? remainingSeconds : 0} seconds... `
          : "You have unsaved changes"}
        &nbsp;&nbsp;
      </MDTypography>
      <MDButton
        variant="gradient"
        color="success"
        onClick={() => {
          productsSubmitFunctions[productId]?.();
        }}
      >
        Save
      </MDButton>

      <MDBox ml={5}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={autoSave}
                onChange={(e) => {
                  productsSubmitFunctions[productId]?.();
                  updateOrganizationProposalDefaults({
                    variables: {
                      input: {
                        id: proposalDefaultsId,
                        autoSave: e.target.checked,
                      },
                    },
                  });
                }}
              />
            }
            label="Auto-Save"
          />
        </FormGroup>
      </MDBox>
    </>
  );
}

export default function PendingChanges() {
  const pendingChangesRoot = document.getElementById("pending-changes");

  return ReactDOM.createPortal(<PendingChangesContent />, pendingChangesRoot);
}
