import getLocalString from "constants/Localization";
import { useAddJobPhaseCostsMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddJobPhaseCosts(onCompleted: () => void) {
  const [mutation, { loading, data, error }] = useAddJobPhaseCostsMutation({
    refetchQueries: ["getJobPhase", "customGetJobPhase", "customGetJobPhaseCostItem"],
    onCompleted: onCompleted,
    fetchPolicy: "no-cache",
  });
  const [addJobPhaseCosts, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "addJobPhaseCosts",
    },
    {
      successMessage: getLocalString("jobPhases.add-job-phase-costs-success"),
      failureMessage: getLocalString("jobPhases.add-job-phase-costs-failure"),
    }
  );

  return [addJobPhaseCosts, context] as const;
}
