import {
  DeleteAdditionalCostMutation,
  DeleteAdditionalCostMutationVariables,
  useDeleteAdditionalCostMutation,
} from "generated/graphql";
import { useCallback } from "react";

export default function useDeleteAdditionalCost() {
  const [mutation, { data, loading, error }] = useDeleteAdditionalCostMutation({
    refetchQueries: ["getAdditionalCosts", "customGetAdditionalCosts"],
  });

  const deleteAdditionalCost = useCallback((values: DeleteAdditionalCostMutationVariables) => {
    try {
      return mutation({ variables: values });
    } catch (error) {
      return;
    }
  }, []);

  const isSuccess = useCallback((data: DeleteAdditionalCostMutation) => {
    return data && data.deleteAdditionalCost === true;
  }, []);

  const success = isSuccess(data);
  const failure = data && data.deleteAdditionalCost === false;

  return [deleteAdditionalCost, { data, loading, error, success, failure, isSuccess }] as const;
}
