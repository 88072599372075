import { Card } from "@mui/material";
import MDAlert from "components/MDAlert";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import useGetOrganizationUsersLazy from "hooks/users/useGetOrganizationUsersLazy";
import { useEffect } from "react";

export default function OrganizationTotalUsersCard() {
  const [getOrganizationUsers, { loading, error, totalUsers }] = useGetOrganizationUsersLazy();

  useEffect(() => {
    getOrganizationUsers({ first: 1 });
  }, []);

  if (error) {
    return (
      <Card>
        <MDAlert>Failed to load organization total users.</MDAlert>
      </Card>
    );
  }

  return (
    <DefaultInfoCard
      icon="group"
      title="Total Users"
      description="Total Users in Organization"
      value={loading ? "Loading..." : totalUsers}
    />
  );
}
