import {
  Opportunity,
  CustomGetOpportunityQuery,
  useCustomGetOpportunityQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetOpportunity = ({ id }: { id: Opportunity["id"] }) => {
  const {
    loading,
    error,
    data: graphqlData,
  } = useCustomGetOpportunityQuery({
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });

  const getData = useCallback(({ data }: { data: CustomGetOpportunityQuery }) => {
    return data?.getOpportunity || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  return { loading, error, data, getData };
};
