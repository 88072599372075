import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateOrganizationProductTypeInput } from "generated/graphql";
import { useForm } from "react-hook-form";
import { updateSchema, getDefaultValues } from "DDD/action-objects/OrganizationProductType";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

export default function OrganizationProductTypeUpdateForm({ onSubmit, loading, productType }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<UpdateOrganizationProductTypeInput>({
    resolver: zodResolver(updateSchema),
    defaultValues: getDefaultValues(productType),
  });

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (values) => {
          const result = await onSubmit(values);
          if (result.success) {
            reset();
          }
        })}
      >
        <MDTypography variant="h5" mb={4}>
          Product Type Information
        </MDTypography>
        <input type="hidden" {...register("id")} />
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="number"
                label="Indirect Percentage"
                placeholder="0"
                error={errors.indirectPercentage}
                {...register("indirectPercentage", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Markup Percentage"
                placeholder="0"
                error={errors.markupPercentage}
                {...register("markupPercentage", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Overhead Percentage"
                placeholder="0"
                error={errors.overheadPercentage}
                {...register("overheadPercentage", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Update
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
