import { AddCircle } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CreateCrewInput } from "generated/graphql";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import React, { useCallback, useState } from "react";
import MDAlert from "components/MDAlert";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { Box, Card, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import useCreateCrew from "hooks/organization/useCreateCrew";
import Create from "modules/OrganizationCrews/Create";
import CrewOptions from "modules/crews/CrewOptions";
import MultiSelectExtension from "components/Shared/MultiSelectExtension/MultiSelectExtension";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddLabourCost({
  onAdd,
  submitMsg = "Add Labor Cost",
}: {
  onAdd: (
    ids: string[] | number[],
    setCrewIds: React.Dispatch<React.SetStateAction<any[]>>
  ) => void;
  submitMsg?: string;
}) {
  const { open, onClose, onOpen } = useModalState();
  const [createCrew, { loading, error, isSuccess, getData }] = useCreateCrew();
  const [crewId, setCrewId] = useState<string | null>(null);
  const [openTab, setOpenTab] = useState(0);
  const [crewIds, setCrewIds] = useState([]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setOpenTab(newValue);
  };

  const onSubmit = useCallback(
    async (values: CreateCrewInput) => {
      const formattedInput = {
        ...values,
        rate: Math.floor(values.rate * 100),
      };
      const result = await createCrew({ variables: { input: formattedInput } });
      const data = getData(result);
      const success = isSuccess(result.data);
      if (isSuccess) {
        onClose();
        onAdd([data.id], setCrewIds);
      }
      return { success, data };
    },
    [createCrew]
  );

  const handleTagSelect = (tagId: string | number): void => {
    if (!crewIds.includes(tagId as never)) {
      setCrewIds([...crewIds, tagId]);
    }
  };

  const handleRemoveTag = (tagId: string | number) => {
    const crewIndex = crewIds.indexOf(tagId as never);
    if (crewIndex === -1) return;
    const crewIdsCopy = [...crewIds];
    crewIdsCopy.splice(crewIndex, 1);
    setCrewIds(crewIdsCopy);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <MDBox p={3}>
          {error && <MDAlert>{parseGraphQLError(error)}</MDAlert>}
          {openTab === 0 ? (
            <MDTypography variant="h6" py={3}>
              Add Labor Cost
            </MDTypography>
          ) : (
            <MDTypography variant="h6" py={3}>
              Create Labor Cost to add
            </MDTypography>
          )}

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={openTab} onChange={handleChangeTab} aria-label="add labor cost tabs">
                <Tab label="Add Labor Cost" {...a11yProps(0)} />
                <Tab label="Create Labor Cost to add" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={openTab} index={0}>
              <CrewOptions>
                {({ crewOptions, crewsById, loading, search, pagination }) => (
                  <Card>
                    <MDBox p={3}>
                      <MDBox minWidth="160px">
                        <MDTypography variant="h6">Crew(s)</MDTypography>
                      </MDBox>
                      <MultiSelectExtension
                        tags={crewIds}
                        setTags={setCrewIds}
                        options={crewOptions}
                        onChange={(value) => setCrewId(value as string)}
                        loading={loading}
                        pagination={pagination}
                        search={search}
                        id={crewId}
                        label="Crew"
                        nameCheckFn={crewsById}
                      />
                      <MDBox textAlign="right">
                        <MDButton
                          variant="gradient"
                          color="info"
                          onClick={() => {
                            if (crewIds.length > 0) {
                              onAdd(crewIds, setCrewIds);
                              onClose();
                            }
                          }}
                        >
                          Add
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                )}
              </CrewOptions>
            </TabPanel>
            <TabPanel value={openTab} index={1}>
              <Create onSubmit={onSubmit} loading={loading} />
            </TabPanel>
          </Box>
        </MDBox>
      </Modal>
      <MDButton variant="gradient" startIcon={<AddCircle />} onClick={onOpen}>
        {submitMsg}
      </MDButton>
    </>
  );
}
