import getLocalString from "constants/Localization";
import MDAlert from "components/MDAlert";
import EquipmentPieceForm from "components/organization/EquipmentPieceForm";
import {
  UpdateEquipmentPieceInput,
  EquipmentPiece,
  EquipmentPieceChargeType,
} from "generated/graphql";
import useUpdateEquipmentPiece from "hooks/organization/useUpdateEquipmentPiece";
import { useCallback } from "react";

export default function Update({ equipmentPiece }: { equipmentPiece: EquipmentPiece }) {
  const [
    updateEquipmentPiece,
    { loading, error, equipmentPiece: updatedEquipmentPiece, isSuccess, getEquipmentPiece },
  ] = useUpdateEquipmentPiece();

  const handleSubmit = useCallback(async (values: UpdateEquipmentPieceInput) => {
    const formattedInput = {
      ...values,
      rate: Math.floor(values.rate * 100),
      fuelSurcharge: Math.floor(values.fuelSurcharge * 100),
    };
    const { data } = await updateEquipmentPiece({
      variables: { input: formattedInput },
    });
    const success = isSuccess(data);
    return { success, data: getEquipmentPiece(data) };
  }, []);

  return (
    <>
      {error && (
        <MDAlert color="error">
          {getLocalString("organization.update-equipment-piece-failure")}
        </MDAlert>
      )}
      <EquipmentPieceForm
        equipmentPiece={(updatedEquipmentPiece ?? equipmentPiece) as EquipmentPiece}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </>
  );
}
