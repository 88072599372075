import { z } from "zod";
import dayjs from "dayjs";

export const schema = z
  .object({
    startTime: z
      .date()
      .or(z.string())
      .refine((date) => dayjs(date).isValid(), {
        message: "Invalid start time",
      })
      .refine((date) => dayjs(date).isBefore(dayjs()), {
        message: "Start time cannot be in the future",
      }),
    endTime: z
      .date()
      .or(z.string())
      .refine((date) => dayjs(date).isValid(), {
        message: "Invalid end time",
      })
      .refine((date) => dayjs(date).isBefore(dayjs()), {
        message: "End time cannot be in the future",
      }),
    jobPhaseId: z.string().nullable().optional(),
    timeBucketId: z.string().nullable().optional(),
    userId: z.string({
      required_error: "User is required",
    }),
    notes: z.string().optional(),
  })
  .refine(
    (data) => {
      const start = dayjs(data.startTime);
      const end = dayjs(data.endTime);
      return end.isAfter(start);
    },
    {
      message: "End time must be after start time",
      path: ["endTime"],
    }
  )
  .refine(
    (data) => {
      const start = dayjs(data.startTime);
      const end = dayjs(data.endTime);
      return end.diff(start, "hours") <= 24;
    },
    {
      message: "Max duration is 24 hours",
      path: ["endTime"],
    }
  );

export type TimeEntryCreateSchema = z.infer<typeof schema>;
