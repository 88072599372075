import { useGetJobBillingStatusesQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useJobsBillingStatuses() {
  const { data, loading, error } = useGetJobBillingStatusesQuery();

  const billingStatuses = useMemo(() => {
    return data?.getJobBillingStatuses?.statuses || [];
  }, [data]);

  return { data: billingStatuses, loading, error } as const;
}
