import getLocalString from "constants/Localization";
import { useGetLinkedServiceAuthUrlMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

const useGetLinkedServiceAuthUrl = () => {
  const [mutation, { loading, data, error }] = useGetLinkedServiceAuthUrlMutation({
    onCompleted: (res) => (window.location.href = res.getLinkedServiceAuthUrl),
  });

  const [getLinkedServiceAuthUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "getLinkedServiceAuthUrl",
    },
    {
      successMessage: getLocalString("organization.get-linked-service-auth-url-success"),
      failureMessage: getLocalString("organization.get-linked-service-auth-url-failure"),
    }
  );
  return [getLinkedServiceAuthUrl, context] as const;
};

export default useGetLinkedServiceAuthUrl;
