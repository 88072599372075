import {
  DeleteOrganizationUserMutation,
  DeleteOrganizationUserMutationVariables,
  useDeleteOrganizationUserMutation,
} from "generated/graphql";
import { useCallback } from "react";

export default function useDeleteOrganizationUser() {
  const [mutation, { data, loading, error }] = useDeleteOrganizationUserMutation();

  const deleteOrganizationUser = useCallback((values: DeleteOrganizationUserMutationVariables) => {
    return mutation({ variables: values });
  }, []);

  const isSuccess = useCallback((data: DeleteOrganizationUserMutation) => {
    return data && data.deleteOrganizationUser === true;
  }, []);

  const success = isSuccess(data);
  const failure = data && data.deleteOrganizationUser === false;

  return [deleteOrganizationUser, { data, loading, error, success, failure, isSuccess }] as const;
}
