import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Checkbox, FormControlLabel, Grid } from "@mui/material";
import CountrySelect from "components/CountrySelect/CountrySelect";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import StateSelect from "components/StateSelect/StateSelect";
import { schema, defaultValues } from "DDD/action-objects/AddressEdit";
import { CreateOrganizationAddressInput } from "generated/graphql";
import { useForm } from "react-hook-form";

export default function Create({ onSubmit, loading }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<CreateOrganizationAddressInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (rest: CreateOrganizationAddressInput) => {
          const result = await onSubmit({ ...rest });
          if (result.success) {
            reset();
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Line 1"
                placeholder="Address Line 1"
                error={errors.line1}
                {...register("line1")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Line 2"
                placeholder="Address Line 2"
                error={errors.line2}
                {...register("line2")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address City"
                placeholder="Address City"
                error={errors.city}
                {...register("city")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StateSelect
                name="state"
                label="Address State"
                control={control}
                countryFieldName="country"
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Zip"
                placeholder="Address Zip"
                error={errors.zip}
                {...register("zip")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CountrySelect
                name="country"
                label="Address Country"
                defaultValue="US"
                required
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={<Checkbox {...register("isPrimary")} />}
                label="Primary Address"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
