import {
  DeleteOrganizationDocumentMutation,
  DeleteOrganizationDocumentMutationVariables,
  GetUserOrganizationDocument,
  useDeleteOrganizationDocumentMutation,
} from "generated/graphql";
import { useCallback } from "react";

export default function useDeleteOrganizationDocument() {
  const [mutation, { data, loading, error }] = useDeleteOrganizationDocumentMutation({
    refetchQueries: [
      {
        query: GetUserOrganizationDocument,
      },
    ],
  });

  const deleteOrganizationDocument = useCallback(
    (values: DeleteOrganizationDocumentMutationVariables) => {
      try {
        return mutation({ variables: values });
      } catch (error) {
        return;
      }
    },
    []
  );

  const isSuccess = useCallback((data: DeleteOrganizationDocumentMutation) => {
    return data && data.deleteOrganizationDocument === true;
  }, []);

  const success = isSuccess(data);
  const failure = data && data.deleteOrganizationDocument === false;

  return [
    deleteOrganizationDocument,
    { data, loading, error, success, failure, isSuccess },
  ] as const;
}
