import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import Logo from "assets/images/Black-Bitumio_Full@4x.png";
import { Link } from "@mui/material";

export default function DownloadAppPage() {
  return (
    <IllustrationLayout
      header={
        <>
          <img src={Logo} width="130" height="130" alt="Brand" />
          <MDTypography as="h2" fontWeight={"bold"}>
            You&apos;re ready to use the Bitumio App!
          </MDTypography>
        </>
      }
    >
      <MDTypography variant="body2" textAlign="center">
        <Link href="#" target="_blank">
          Download iOS from the App Store here
        </Link>
      </MDTypography>
      <MDTypography variant="body2" textAlign="center">
        <Link href="#" target="_blank">
          Download Android from Google Play here
        </Link>
      </MDTypography>
    </IllustrationLayout>
  );
}
