import { Icon, Menu } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useCallback, useState } from "react";
import ProposalMenuItems from "./MenuItems/ProposalMenuItems";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export default function ProposalActionsCell({ row }) {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const closeMenu = useCallback(() => {
    setAnchorElement(null);
  }, []);

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorElement)}
        onClose={closeMenu}
        keepMounted
      >
        <ProposalMenuItems proposal={row?.values} closeMenu={closeMenu} />
      </Menu>
      <MDTypography
        color="secondary"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          setAnchorElement(e.currentTarget)
        }
      >
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }}>more_vert</Icon>
      </MDTypography>
    </>
  );
}
