import ActionMenuItem from "components/Shared/ActionMenuItem/ActionMenuItem";
import { Job } from "generated/graphql";

export default function MenuItems({ id, closeMenu }: { id: Job["id"]; closeMenu: () => void }) {
  return (
    <>
      <ActionMenuItem closeMenu={closeMenu} route={`/jobs/${id}`} text="View" />
    </>
  );
}
