import { Grid } from "@mui/material";
import FormSkeleton from "components/FormSkeleton/FormSkeleton";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useGetOrganizationProductType from "hooks/organization/product-types/useGetOrganizationProductType";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import OrganizationProductTypeProductCreate from "modules/products/OrganizationProductTypeProductCreate";
import { useParams } from "react-router";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function OrganizationProductTypeProductCreatePage() {
  const { productTypeId } = useParams();

  const {
    data: productType,
    loading,
    error,
  } = useGetOrganizationProductType({ id: productTypeId });

  return (
    <DashboardPage>
      {loading && <FormSkeleton rows={4} />}
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      {!loading && !error && (
        <>
          <MDBox mb={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h4" fontWeight="medium">
                  Create Product
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OrganizationProductTypeProductCreate productType={productType} />
            </Grid>
          </Grid>
        </>
      )}
    </DashboardPage>
  );
}
