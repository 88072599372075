import { OrganizationProductType } from "generated/graphql";
import { capitalCase } from "change-case";

export default function ProductTypeTypeCell({
  value,
}: {
  value: string;
  row: { values: OrganizationProductType };
}) {
  return <span>{capitalCase(value)}</span>;
}
