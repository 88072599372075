import { Divider } from "@mui/material";
import { InfoItem } from "components/InfoItem/InfoItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Company } from "generated/graphql";
import { getContactInfo } from "utils/contacts/getContactInfo";

interface CompanyPrimaryContactProps {
  contact: Company["contacts"][number];
  icon: React.ReactNode;
}

export const CompanyPrimaryContact: React.FC<CompanyPrimaryContactProps> = ({ contact, icon }) => {
  const details = getContactInfo(contact);

  return (
    <MDBox width="100%">
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Primary Contact
        </MDTypography>
        {icon}
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {details.map((item) => (
            <InfoItem key={item.label} item={item} />
          ))}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
