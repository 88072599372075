import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import MDBox from "components/MDBox";
import { GetJobsSortKey, Job, JobStatus, useCustomGetJobsQuery } from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import React, { useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import OrganizationProductTypeIdsOptions from "modules/OrganizationProductTypes/OrganizationProductTypeIdsOptions/OrganizationProductTypeIdsOptions";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { capitalCase } from "change-case";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Filters = ({ filters, searchTerm, onQueryChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <MDBox position="relative" zIndex={2}>
      <MDBox display="flex" alignItems="center" flexWrap="wrap">
        <Paper
          component="form"
          sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, mr: 2 }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Jobs"
            value={searchTerm}
            inputProps={{ "aria-label": "search jobs" }}
            onChange={({ currentTarget }: any) => {
              onQueryChange(currentTarget.value);
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </MDBox>
      {filters.map((filter, i) => (
        <MDBox key={i}>{filter.filter}</MDBox>
      ))}
    </MDBox>
  );
};

export default function useJobsTableData({ jobIds }: { jobIds?: Job["id"][] } = {}) {
  const savedFilters = JSON.parse(localStorage.getItem("JobsSchedulesFilters")) ?? null;
  const defaultStatuses = [JobStatus.SCHEDULED, JobStatus.TO_BE_SCHEDULED];

  const theme = useTheme();
  const { filtering, sorting, offset } = usePaginatedVariables({
    initialStatuses: savedFilters?.statuses ?? defaultStatuses,
    initialSearchTerm: savedFilters?.searchTerm ?? null,
  });

  const [organizationProductTypeIds, setOrganizationProductTypeIds] = useState<SelectOptions>(
    savedFilters?.organizationProductTypeIds ?? []
  );

  const {
    loading,
    data: graphqlData,
    error,
  } = useCustomGetJobsQuery({
    variables: {
      search: filtering.debouncedSearchTerm,
      sortDirection: sortDirectionMap[sorting.sortDirection] || "DESC",
      sortKey: (sorting.sortKey || "ID") as GetJobsSortKey,
      statuses: filtering.statuses as JobStatus[],
      companyId: filtering.companyId,
      multipleCompanyIDs: filtering.multipleCompanyIDs,
      userId: filtering.userID,
      jobIds,
      organizationProductTypeIds: organizationProductTypeIds
        ? organizationProductTypeIds.map((checkedProductTypeId) => `${checkedProductTypeId.value}`)
        : null,
      first: offset.first,
      page: offset.page,
    },
  });
  const data = graphqlData?.getJobs?.data;

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo: graphqlData?.getJobs?.paginatorInfo,
  });

  const filters = [
    {
      key: "status",
      label: "Job Status",
      filter: (
        <MDBox sx={{ my: 2 }} key="status">
          <FormControl sx={{ m: 0, width: "50%", minWidth: "500px", p: 0 }}>
            <InputLabel id="status-filter-label" sx={{ background: "white" }}>
              Select a Status
            </InputLabel>
            <Select
              sx={{
                "#status-filter": {
                  minHeight: "48px",
                },
              }}
              labelId="status-filter-label"
              id="status-filter"
              multiple
              value={filtering.statuses}
              onChange={(e) => filtering.setStatuses(e.target.value as string[])}
              input={<OutlinedInput id="select-status-filter" label="Status" />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select a Status</em>;
                }

                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, p: 1 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={capitalCase(value)} />
                    ))}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {enumToValueOptions(JobStatus).map((status) => (
                <MenuItem
                  sx={{ my: 1 }}
                  key={status.value}
                  value={status.value}
                  style={getStyles(status.value, filtering.statuses, theme)}
                >
                  {capitalCase(status.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      ),
    },
    {
      key: "organizationProductTypeIds",
      label: "Organization Product Type IDs",
      filter: (
        <OrganizationProductTypeIdsOptions>
          {({ organizationProductTypeIdsOptions }) => (
            <>
              <MDBox sx={{ my: 2 }} key="organizationProductTypeIds">
                <FormControl sx={{ m: 0, width: "50%", minWidth: "500px", p: 0 }}>
                  <InputLabel id="organization-product-type-id-label" sx={{ background: "white" }}>
                    Select a Product Type ID
                  </InputLabel>
                  <Select
                    labelId="organization-product-type-id-label"
                    id="organization-product-type-id"
                    sx={{
                      "#organization-product-type-id": {
                        minHeight: "48px",
                      },
                    }}
                    multiple
                    value={organizationProductTypeIds.map((typeId) => typeId.value)}
                    onChange={(e) => {
                      const result = organizationProductTypeIdsOptions.filter((option) =>
                        e.target.value.includes(`${option.value}`)
                      );
                      setOrganizationProductTypeIds(result);
                    }}
                    input={
                      <OutlinedInput
                        id="select-organization-product-type-id"
                        label="Product Type ID"
                      />
                    }
                    renderValue={(selected) => {
                      return (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, p: 1 }}>
                          {organizationProductTypeIdsOptions.length &&
                            selected.map((selectOption) => {
                              const resultOption = organizationProductTypeIdsOptions.find(
                                (option) => option.value == selectOption
                              );
                              return <Chip key={resultOption.value} label={resultOption.label} />;
                            })}
                        </Box>
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {organizationProductTypeIdsOptions.map((productTypeIdOption) => (
                      <MenuItem
                        key={productTypeIdOption.value}
                        sx={{ my: 1 }}
                        value={productTypeIdOption.value}
                        style={getStyles(
                          `${productTypeIdOption.value}`,
                          organizationProductTypeIds.map((option) => `${option.value}`),
                          theme
                        )}
                      >
                        {capitalCase(productTypeIdOption.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </>
          )}
        </OrganizationProductTypeIdsOptions>
      ),
    },
  ];

  const appliedFilters = [];

  const filterControl = (
    <Filters
      searchTerm={filtering.searchTerm}
      onQueryChange={filtering.setSearchTerm}
      filters={filters}
    />
  );

  const columns = useMemo(() => {}, []);

  const renderRowSubComponent = (row: any) => <pre>{JSON.stringify(row, undefined, 2)}</pre>;

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    renderRowSubComponent,
    filterControl,
    appliedFilters,
    pagination,
    filtering,
    organizationProductTypeIds,
    sorting,
    paginatorInfo: pagination.paginatorInfo,
  } as const;
}
