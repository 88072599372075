import { capitalCase } from "change-case";
import { useGetOpportunityStatusesQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetOpportunityStatuses() {
  const { loading, data, error } = useGetOpportunityStatusesQuery();

  const selectOptions = useMemo((): { label: string; value: string }[] | null => {
    return (
      data?.getOpportunityStatuses?.map(({ id, name }) => ({
        label: capitalCase(name),
        value: id,
      })) || null
    );
  }, [data]);

  return { loading, data: data?.getOpportunityStatuses || null, error, selectOptions };
}
