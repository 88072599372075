import {
  Card,
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import timeEndAdornment from "constants/timeEndAdornment";
import { JobPhaseCostItemInputSchema } from "DDD/action-objects/JobPhaseCostItemCreate";
import { TransferTruck } from "generated/graphql";
import { useFieldArray, useFormContext } from "react-hook-form";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import AddTransferTrucks from "./transferTrucks/AddTransferTrucks";

const calculateTotal = ({
  hours = 0,
  rateHourly,
}: JobPhaseCostItemInputSchema["transferTrucks"][number]) => {
  const total = hours * rateHourly;

  return isNaN(total) ? 0 : total;
};

function TransferTruckTotal({ fieldName }: { fieldName: string }) {
  const { watch } = useFormContext();
  const transferTruck = watch(fieldName);

  return <span>{formatCentsToUSD(calculateTotal(transferTruck))}</span>;
}

export default function TransferTruckSection() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<JobPhaseCostItemInputSchema>();
  const { fields, append, remove } = useFieldArray({
    name: "transferTrucks",
    control: control,
    keyName: "key",
  });
  const onAdd = ({
    id,
    name,
    defaultTonnage,
    defaultRateHourly,
    defaultRateTon,
  }: TransferTruck) => {
    const foundTruck = fields.find((existingTruck) => existingTruck.id === id);

    if (!foundTruck) {
      const newTruck = {
        id: id,
        name: name,
        tonnage: defaultTonnage,
        rateHourly: defaultRateHourly,
        rateTon: defaultRateTon,
        hours: 0,
      };

      append(newTruck);
    }
  };

  return (
    <Card>
      <MDBox p={4}>
        <MDTypography>Transfer Trucks</MDTypography>
        {errors.transferTrucks?.message && (
          <MDBox my={2}>
            <FormHelperText error>{errors.transferTrucks.message}</FormHelperText>
          </MDBox>
        )}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell sx={{ minWidth: "150px", width: "30%" }}>Name</TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "30%" }}>
                    Hours
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "30%" }}>
                    Total
                  </TableCell>
                  <TableCell sx={{ minWidth: "150px", width: "10%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((transferTruck, i) => {
                  return (
                    <TableRow
                      key={transferTruck.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <input
                        type="hidden"
                        value={transferTruck.id}
                        {...register(`transferTrucks.${i}.id`)}
                      />
                      <TableCell>{transferTruck.name ?? ""}</TableCell>
                      <TableCell align="right">
                        <FormField
                          align="right"
                          placeholder={transferTruck.hours}
                          mb={0}
                          sx={{
                            "& .MuiInputBase-root": {
                              "& input": {
                                textAlign: "right",
                              },
                            },
                          }}
                          {...timeEndAdornment}
                          {...register(`transferTrucks.${i}.hours`)}
                        />
                      </TableCell>{" "}
                      <TableCell align="right">
                        <TransferTruckTotal fieldName={`transferTrucks.${i}`} />
                      </TableCell>
                      <TableCell align="right">
                        <MDButton
                          type="button"
                          variant="text"
                          color="error"
                          onClick={() => remove(i)}
                        >
                          Remove
                        </MDButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell colSpan={6}>
                    <AddTransferTrucks onAdd={onAdd} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
