import { MenuItem } from "@mui/material";
import { User } from "generated/graphql";
import { Link } from "react-router-dom";

export default function UpdateAccountMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: User["id"];
}) {
  return (
    <MenuItem component={Link} onClick={closeMenu} to={`/users/${id}`}>
      Update Account
    </MenuItem>
  );
}
