import { Box, Checkbox } from "@mui/material";
import MDTypography from "components/MDTypography";
import { ProposalProduct } from "generated/graphql";
import useToggleProposalProductOptionality from "hooks/proposals/useToggleProposalProductOptionality";
import useBooleanToggle from "hooks/useBooleanToggle";
import { ChangeEvent } from "react";

const ToggleProposalProductOptionality = ({
  id,
  isOptional,
}: {
  id: ProposalProduct["id"];
  isOptional: boolean;
}) => {
  const [value, toggle] = useBooleanToggle(isOptional);
  const [toggleProposalProductOptionality] = useToggleProposalProductOptionality();

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    toggle();
    toggleProposalProductOptionality({ variables: { id, isOptional: e.target.checked } });
  };

  return (
    <Box textAlign="left" width={112} display="inline-block">
      <MDTypography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>Optional</MDTypography>
      <Checkbox
        sx={{
          background: "white",
          borderRadius: 2,
          padding: 0,
          marginLeft: "27px",
        }}
        checked={value}
        onChange={handleToggle}
        onClick={(e) => e.stopPropagation()}
      />
    </Box>
  );
};

export default ToggleProposalProductOptionality;
