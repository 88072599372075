import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import { sortDirectionMap } from "constants/sortDirectionMap";
import {
  CustomCancelOpportunityMutation,
  CustomCancelOpportunityMutationVariables,
  useCustomCancelOpportunityMutation,
  GetOpportunitiesDocument,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { useCallback, useMemo } from "react";
import {
  opportunitiesInitialSortDirectionValue,
  opportunitiesInitialSortKeyValue,
  opportunitiesSortKeyMap,
} from "./useOpportunitiesTable";

export default function useCancelOpportunity() {
  const { offset, filtering, sorting } = usePaginatedVariables();
  const [mutation, { data: graphqlData, loading, error }] = useCustomCancelOpportunityMutation({
    refetchQueries: [
      {
        query: GetOpportunitiesDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
          sortKey: opportunitiesSortKeyMap[sorting.sortKey] || opportunitiesInitialSortKeyValue,
          sortDirection:
            sortDirectionMap[sorting.sortDirection] || opportunitiesInitialSortDirectionValue,
        },
      },
    ],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = (data: CustomCancelOpportunityMutation) => {
    return !!data?.cancelOpportunity;
  };

  const getData = ({ data }: { data?: CustomCancelOpportunityMutation }) => {
    return data?.cancelOpportunity ?? null;
  };

  const cancelOpportunity = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          CustomCancelOpportunityMutation,
          CustomCancelOpportunityMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("opportunities.cancel-success"),
        failureMessage: getLocalString("opportunities.cancel-failure"),
      }
    ),
    [mutation]
  );

  const data = useMemo(() => {
    return (graphqlData && graphqlData.cancelOpportunity) || null;
  }, [graphqlData]);

  const success = useMemo(() => {
    return isSuccess(graphqlData);
  }, [graphqlData]);

  return [cancelOpportunity, { data, loading, error, success, isSuccess }] as const;
}
