import { GetCrewsQuery, GetCrewsQueryVariables, useGetCrewsQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetCrews";

export default function useGetCrews({
  page = 1,
  first = 10,
  search,
  sort,
}: GetCrewsQueryVariables) {
  const {
    data: graphqlData,
    loading,
    error,
    fetchMore: more,
  } = useGetCrewsQuery({
    variables: {
      page,
      first,
      search,
      sort,
    },
  });

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
      });
    },
    [more]
  );

  const getData = useCallback(({ data }: { data?: GetCrewsQuery }) => {
    return data?.getCrews?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: GetCrewsQuery }) => {
    return data?.getCrews?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return { data, loading, error, paginatorInfo, fetchMore, getData, getPaginatorInfo } as const;
}
