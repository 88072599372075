import { Proposal, ProposalStatus, UserPermissions } from "generated/graphql";
import InfoProposalMenuItem from "./InfoProposalMenuItem";
import UpdateProposalMenuItem from "./UpdateProposalMenuItem";
import ViewProposalMenuItem from "./ViewProposalMenuItem";
import DeleteProposalMenuItem from "./DeleteProposalMenuItem";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export default function ProposalMenuItems({
  proposal,
  closeMenu,
}: {
  proposal: Proposal;
  closeMenu: () => void;
}) {
  const { hasPermissions } = useCheckPermissions();
  return (
    <>
      {proposal.status === ProposalStatus.DRAFT &&
        hasPermissions(UserPermissions.MANAGE_PROPOSALS) && (
          <UpdateProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
        )}

      <ViewProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      <InfoProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
      {(proposal.status === ProposalStatus.DRAFT || proposal.status === ProposalStatus.CANCELED) &&
        hasPermissions(UserPermissions.MANAGE_PROPOSALS) && (
          <DeleteProposalMenuItem closeMenu={closeMenu} id={proposal.id} />
        )}
    </>
  );
}
