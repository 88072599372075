import { GetEquipmentPieceQueryVariables, useGetEquipmentPieceLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetEquipmentPiece() {
  const [query, { data, loading, error }] = useGetEquipmentPieceLazyQuery();

  const getEquipmentPiece = useCallback(
    (values: GetEquipmentPieceQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const equipmentPiece = useMemo(() => {
    return (data && data.getEquipmentPiece) || null;
  }, [data]);

  return [getEquipmentPiece, { data, loading, error, equipmentPiece }] as const;
}
