import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { UserPermissions } from "generated/graphql";
import useGetRolesPermissions from "hooks/organization/permissions/useGetRolesPermissions";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import UserPermissionsTable from "modules/UserPermissions/UserPermissionsTable/UserPermissionsTable";

export default function UserPermissionsPage() {
  const { data, loading } = useGetRolesPermissions();
  return (
    <DashboardPage>
      <MDBox mb={6}>
        <MDTypography variant="h4">User Permissions</MDTypography>
        {!loading && data && <UserPermissionsTable data={data} />}
      </MDBox>
    </DashboardPage>
  );
}
