import React, { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React TS routes
import routes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/blue-b.png";
import brandDark from "assets/images/blue-b.png";
import Logo from "assets/images/logo.png";
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";

import BitumioProvider from "providers/Bitumio";
import { SnackbarProvider } from "notistack";
import "styles/global.css";
import PendingChangesRoot from "components/PendingChangesRoot/PendingChangesRoot";
import { SidebarProvider } from "components/Sidebar/sidebarContext";
import AppRoutes from "AppRoutes";
import breakpoints from "assets/theme/base/breakpoints";

export default React.memo(function App({
  apolloClient,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>;
}) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [mobileNavbar, setMobileNavbar] = useState<boolean>(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    setMobileNavbar(window.innerWidth < breakpoints.values.xl);
  }, []);

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <BitumioProvider>
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
              <SidebarProvider>
                <CssBaseline />
                {layout === "dashboard" && (
                  <>
                    <Sidenav
                      color={sidenavColor}
                      brand={
                        (transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite
                      }
                      brandLogo={Logo}
                      routes={routes as unknown as any[]}
                      onMouseEnter={mobileNavbar ? null : handleOnMouseEnter}
                      onMouseLeave={mobileNavbar ? null : handleOnMouseLeave}
                      mobileNavbar={mobileNavbar}
                    />
                    {/*<Configurator />*/}
                    {/*{configsButton}*/}
                  </>
                )}
                {/*{layout === "vr" && <Configurator />}*/}
                <AppRoutes />
                <PendingChangesRoot />
              </SidebarProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </BitumioProvider>
      </ApolloProvider>
    </>
  );
});
