import dayjs from "dayjs";
import {
  CustomGetCustomEventQueryVariables,
  useCustomGetCustomEventQuery,
} from "generated/graphql";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { useMemo } from "react";
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const useGetCustomEvent = (variables: CustomGetCustomEventQueryVariables) => {
  const {
    loading,
    data: graphQLData,
    error,
  } = useCustomGetCustomEventQuery({
    variables,
  });
  const data = useMemo(() => {
    return graphQLData?.getCustomEvent || null;
  }, [graphQLData]);

  return { loading, data, error } as const;
};
