import React, { useCallback } from "react";
import { useBitumioContext } from "providers/BitumioContext";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import getLocalString from "constants/Localization";

export default function useAddJobToQueueList() {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();

  const addJobToQueue = useCallback(async (jobId) => {
    try {
      showLoader();
      const response = await fetch(import.meta.env.VITE_BACKEND_API_BASE_URL + `/api/quickbooks`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_id: parseInt(jobId),
        }),
      });

      if (response.ok) {
        show({
          message: getLocalString("organization.create-job-invoice-on-linked-service-success"),
        });
        return true;
      }
      const data = await response.json();
      const errorMessage = data?.message ?? "";
      show({
        message:
          errorMessage ||
          getLocalString("organization.create-job-invoice-on-linked-service-failure"),
        options: { variant: "error" },
      });
      return false;
    } catch (error) {
      show({
        message: getLocalString("generic.failure"),
        options: { variant: "error" },
      });
      return false;
    } finally {
      hideLoader();
    }
  }, []);

  const addJobToQueueList = useCallback(
    (jobId) => {
      addJobToQueue(jobId);
    },
    [addJobToQueue]
  );

  return [addJobToQueueList];
}
