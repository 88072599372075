import { Grid, FormHelperText } from "@mui/material";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import { useUppy, FileInput } from "@uppy/react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "components/FormField/FormField";
import { AddOrganizationDocumentMutationVariables } from "generated/graphql";
import useGenerateUploadOrganizationDocumentUrl from "hooks/organization/useGenerateUploadOrganizationDocumentUrl";
import useAddOrganizationDocument from "hooks/organization/useAddOrganizationDocument";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "@uppy/core/dist/style.css";
import "@uppy/file-input/dist/style.css";

export default function DocumentInput({ index }: { index: number }) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitted },
  } = useForm<AddOrganizationDocumentMutationVariables>({});

  const [addOrganizationDocument] = useAddOrganizationDocument();

  const [generateUploadOrganizationDocumentUrl, { getData, isSuccess }] =
    useGenerateUploadOrganizationDocumentUrl();

  const [errorMessage, setErrorMessage] = useState("");
  const [documentPath, setDocumentPath] = useState<null | string>(null);
  const [btnColor, setBtnColor] = useState("info");
  const [filename, setFilename] = useState("");
  const pathRef = useRef("");

  const onSubmit = (data) => {
    if (!documentPath) {
      return;
    }
    addOrganizationDocument({
      variables: {
        ...data,
        path: documentPath,
      },
    });
    reset();
    setFilename("");
    setDocumentPath("");
    setBtnColor("info");
  };

  const uppy = useUppy(() => {
    const _uppy = new Uppy({
      id: `organizationDocument${index}`,
      autoProceed: true,
      allowMultipleUploads: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".pdf"],
      },
    }).use(AwsS3, {
      async getUploadParameters(file) {
        const result = await generateUploadOrganizationDocumentUrl({
          variables: {
            contentType: file.type,
            filename: file.name,
          },
        });
        const success = isSuccess(result.data);
        setFilename(file.name);
        if (success) {
          setBtnColor("success");
          const data = getData(result);
          pathRef.current = data.path;
          return {
            method: data.method as "PUT",
            url: data.url,
            fields: {},
            headers: {
              "Content-Type": file.type,
            },
          };
        } else {
          setBtnColor("info");
          throw new Error("Could not upload document");
        }
      },
    });

    _uppy.on("upload-success", (file, response) => {
      if (response.status === 200) {
        setDocumentPath(pathRef.current);
        _uppy.removeFile(file.id);
      }
      setBtnColor("success");
    });

    _uppy.on("restriction-failed", (file, error) => {
      if (error) {
        setErrorMessage(error.message);
      }
      setBtnColor("info");
    });

    _uppy.on("upload-error", (file) => {
      if (file) {
        setErrorMessage("An error has ocurred while uploading this file");
        _uppy.removeFile(file.id);
      }
      setBtnColor("info");
    });

    return _uppy;
  });

  return (
    <MDBox mb={1} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={4} lg={4}>
          <MDBox>
            <FormField {...register("name")} value={filename} />
          </MDBox>
        </Grid>
        <Grid item xs={6} lg={6}>
          <MDBox display="flex" alignItems="center" ml={-1}>
            <Grid
              xs={4}
              lg={4}
              sx={{
                ".uppy-FileInput-container": {
                  marginBottom: 0,
                },
              }}
            >
              <FileInput uppy={uppy} pretty />
            </Grid>
            <Grid xs={6} lg={6}>
              <MDBox>
                <MDButton
                  variant="contained"
                  //@ts-ignore
                  color={btnColor}
                  type="submit"
                >
                  Upload
                </MDButton>
              </MDBox>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
      {isSubmitted && !documentPath && (
        <FormHelperText
          sx={{
            color: "red",
          }}
        >
          Please choose a file to upload
        </FormHelperText>
      )}
    </MDBox>
  );
}
