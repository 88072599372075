import getLocalString from "constants/Localization";
import { useCustomCreateProposalMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCustomCreateProposal() {
  const [mutation, { loading, error, data }] = useCustomCreateProposalMutation({
    refetchQueries: ["getProposal", "customGetProposals"],
  });
  const [createProposal, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "createProposal",
    },
    {
      successMessage: getLocalString("proposals.create-success"),
      failureMessage: getLocalString("proposals.create-failure"),
    }
  );

  return [createProposal, context] as const;
}
