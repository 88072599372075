import dayjs from "dayjs";
import { useCustomGetCustomEventBlocksLazyQuery } from "generated/graphql";
import { pick } from "ramda";
import { useCallback, useState } from "react";
import { CalendarEvent } from "utils/events/transformSchedulesToEvents";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const useGetCustomEventBlocksLazy = () => {
  const [query, { loading, data, error }] = useCustomGetCustomEventBlocksLazyQuery();
  const [events, setEvents] = useState<CalendarEvent[] | null>(null);
  const getCustomEventsBlock = useCallback(
    async (options: Parameters<typeof query>[0]) => {
      const result = await query(options);

      const _events = result?.data?.getCustomEventBlocks.map((customEventBlock): CalendarEvent => {
        const event = {
          title: customEventBlock.customEvent?.name,
          start: dayjs.utc(customEventBlock.scheduledAt).toISOString(),
          end: dayjs
            .utc(
              customEventBlock.blockLength ? customEventBlock.scheduledAt : customEventBlock.endsAt
            )
            .add(customEventBlock.blockLength ?? 0, "hours")
            .toISOString(),
          color: `#${
            customEventBlock?.customEvent?.crew?.color ?? customEventBlock?.customEvent?.color
          }`,
          textColor:
            customEventBlock?.customEvent?.crew?.color === "000000" ||
            customEventBlock?.customEvent?.color === "000000"
              ? "white"
              : "black",
          block: pick(["id"], customEventBlock),
          customEventId: customEventBlock?.customEvent?.id,
          userIds: customEventBlock?.customEvent?.customEventsToUsers?.map((x) => x.user?.id),
          crewId: customEventBlock?.customEvent?.crew?.id,
        };
        return event;
      });

      setEvents(_events);

      return {
        ...result,
        events: _events,
      };
    },
    [query, setEvents]
  );
  return [getCustomEventsBlock, { loading, data, error, events }] as const;
};
