import { CreateAdditionalCostInput } from "generated/graphql";
import { useCallback } from "react";
import AdditionalCostForm from "../../components/organization/AdditionalCostForm";
import useCreateAdditionalCost from "../../hooks/organization/useCreateAdditionalCost";

export default function Create() {
  const [createAdditionalCost, { loading, isSuccess }] = useCreateAdditionalCost();

  const handleSubmit = useCallback(async (values: CreateAdditionalCostInput) => {
    const formattedInput = {
      ...values,
      feeAmount: +values.feeAmount.toFixed(2), // dont multiply this by 100, only currency values should be multiplied by 100
      cost: Math.floor(values.cost * 100),
    };
    const { data } = await createAdditionalCost({
      variables: {
        input: formattedInput,
      },
    });
    const success = isSuccess(data);
    return { success };
  }, []);

  return <AdditionalCostForm loading={loading} onSubmit={handleSubmit} action={"create"} />;
}
