import { AcceptUserInviteInput, useAcceptUserInviteMutation, User } from "generated/graphql";
import { useCallback } from "react";

export default function useAcceptUserInvite() {
  const [acceptUserInviteMutation, { data, loading, error }] = useAcceptUserInviteMutation();

  const acceptUserInvite = useCallback(
    (values: AcceptUserInviteInput) => {
      acceptUserInviteMutation({ variables: { input: values } });
    },
    [acceptUserInviteMutation]
  );

  const success = data && typeof data.acceptUserInvite?.email !== "undefined";
  const user = ((data && data.acceptUserInvite) || null) as User | null;

  return [acceptUserInvite, { data, loading, error, success, user }] as const;
}
