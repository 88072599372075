import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  SubmitHandler,
  useForm,
  UseFormRegister,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import { useNavigate } from "react-router";
import { getDefaultValues, schema } from "DDD/action-objects/EquipmentPieceEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateEquipmentPieceInput,
  EquipmentPiece,
  EquipmentPieceChargeType,
} from "generated/graphql";
import {
  Card,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import MDBox from "components/MDBox";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import { getRoute } from "utils/routing";
import currencyEndAdornment from "constants/currencyEndAdornment";

export interface EquipmentPieceFormProps {
  onSubmit: SubmitHandler<UpdateEquipmentPieceInput>;
  loading: boolean;
  equipmentPiece: EquipmentPiece;
}

export default function EquipmentPieceForm({
  onSubmit,
  loading,
  equipmentPiece,
}: EquipmentPieceFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    getValues,
    watch,
  } = useForm<UpdateEquipmentPieceInput>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(equipmentPiece),
  });
  const navigate = useNavigate();

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (values) => {
          // @ts-expect-error: FIX update types
          const { success } = await onSubmit(values);
          if (success) {
            navigate(getRoute("organization.equipment-pieces"));
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <FormFields
              control={control}
              register={register}
              errors={errors}
              getValues={getValues}
              watch={watch}
            />
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

function FormFields({
  register,
  errors,
  getValues,
  watch,
  control,
}: {
  register: UseFormRegister<UpdateEquipmentPieceInput>;
  errors: FieldErrorsImpl<DeepRequired<UpdateEquipmentPieceInput>>;
  getValues: UseFormGetValues<UpdateEquipmentPieceInput>;
  watch: UseFormWatch<UpdateEquipmentPieceInput>;
  control: Control<UpdateEquipmentPieceInput, any>;
}) {
  const chargeTypeWatch = watch("chargeType");

  return (
    <>
      <input type="hidden" {...register("id")} />
      <Grid item xs={12} sm={6}>
        <FormField
          type="text"
          label="Name"
          placeholder="Name"
          error={errors.name}
          {...register("name")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          sx={{ m: 1.2, minWidth: "100%", marginLeft: "-3px" }}
          error={"chargeType" in errors}
        >
          <InputLabel id="charge-type-select-label" sx={{ marginLeft: "-12px" }}>
            Charge Type
          </InputLabel>
          <Select
            labelId="charge-type-select-label"
            label="Charge Type"
            placeholder="Please select"
            variant="standard"
            fullWidth
            defaultValue={getValues("chargeType")}
            sx={{
              "&": {
                marginTop: "13px",
              },
            }}
            {...register("chargeType")}
          >
            <MenuItem value={EquipmentPieceChargeType.HOURLY}>Hourly</MenuItem>
            <MenuItem value={EquipmentPieceChargeType.MILEAGE}>Mileage</MenuItem>
          </Select>
          <FormHelperText sx={{ marginLeft: "0px" }}>{errors?.chargeType?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          align="right"
          type="number"
          label={
            chargeTypeWatch
              ? chargeTypeWatch == EquipmentPieceChargeType.HOURLY
                ? "Hourly cost"
                : "Cost per mile"
              : "Rate"
          }
          inputProps={{ min: 0, step: 0.01 }}
          {...register("rate", { valueAsNumber: true })}
          {...currencyEndAdornment}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          align="right"
          type="number"
          label="Fuel Surcharge"
          inputProps={{ min: 0, step: 0.01 }}
          {...register("fuelSurcharge", { valueAsNumber: true })}
          {...currencyEndAdornment}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          type="number"
          label="On job use rate"
          suffix="%"
          helperText="What percentage is this equipment used on a job?"
          align="right"
          inputProps={{ min: 1, max: 100 }}
          error={errors.usePercentage}
          {...register("usePercentage", { valueAsNumber: true })}
        />
      </Grid>
    </>
  );
}
