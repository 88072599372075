import { UpdateOrganizationProductTypeInput } from "generated/graphql";
import { mergeAll } from "ramda";
import * as z from "zod";

export const defaultValues = {
  name: "",
  indirectPercentage: 0,
  markupPercentage: 0,
  overheadPercentage: 0,
  productType: "",
};

export const schema = z.object({
  name: z.string().min(1, "Name required"),
  indirectPercentage: z.number(),
  markupPercentage: z.number(),
  overheadPercentage: z.number(),
  type: z.string({ required_error: "Type required" }).min(1, "Type required"),
});

export const updateSchema = z.object({
  id: z.string(),
  name: z.string().min(1, "Name is a Required Field"),
  indirectPercentage: z.number(),
  markupPercentage: z.number(),
  overheadPercentage: z.number(),
});

export const getDefaultValues = (initialValues: UpdateOrganizationProductTypeInput) => {
  return mergeAll([defaultValues, initialValues]);
};
