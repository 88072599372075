import { OptionsObject, SnackbarMessage } from "notistack";
import { useCallback } from "react";
import { useState } from "react";
import useGlobalMessage from "./notifications/useGlobalMessage";

export default function useConfirmationDialog({
  initialOpenState = false,
  acceptFn,
  cancelFn,
}: {
  initialOpenState?: boolean;
  acceptFn: () => Promise<{ message: SnackbarMessage; options?: OptionsObject }> | Promise<boolean>;
  cancelFn: () => Promise<boolean>;
}) {
  const [open, setOpen] = useState(initialOpenState);
  const { show } = useGlobalMessage();

  const onAccept = useCallback(async () => {
    const result = await acceptFn();

    if (typeof result === "boolean") {
      setOpen(!result); // If true invert and close, if false keep it open
      return result;
    }

    if (!result.options?.variant || result.options?.variant === "success") {
      setOpen(false);
    }

    // Let's dispatch a message related to
    if (result?.message) {
      show(result);
    }

    return result;
  }, [acceptFn]);

  const onCancel = useCallback(async () => {
    const result = await cancelFn();
    if (result) {
      setOpen(false);
    }
    return result;
  }, [cancelFn]);

  return { open, setOpen, onAccept, onCancel };
}
