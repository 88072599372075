import { GetOrganizationDataUploadsQuery, GetOrganizationDataUploadsQueryVariables, useGetOrganizationDataUploadsQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetOrganizationDataUploads";

export default function useGetDataUploads({ page = 1, first = 10 }: GetOrganizationDataUploadsQueryVariables) {
  const {
    data: graphqlData,
    loading,
    error,
    fetchMore: more,
  } = useGetOrganizationDataUploadsQuery({
    variables: {
      page,
      first,
    },
  });

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
      });
    },
    [more]
  );

  const getData = useCallback(({ data }: { data?: GetOrganizationDataUploadsQuery }) => {
    return data?.getOrganizationDataUploads?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: GetOrganizationDataUploadsQuery }) => {
    return data?.getOrganizationDataUploads?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return { data, loading, error, paginatorInfo, fetchMore, getData, getPaginatorInfo } as const;
}
