import { Card, Grid, Icon, IconButton, Switch } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { SubmitHandler, useFieldArray, useForm, Controller } from "react-hook-form";

interface CreateOpportunityStatusProps {
  onSubmit: SubmitHandler<{ name: string }>;
  loading: boolean;
}

export default function CreateOpportunityStatus({
  onSubmit,
  loading,
}: CreateOpportunityStatusProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<{ name: string }>();

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (data: { name: string }) => {
          const result = await onSubmit({ name: data.name });
          // @ts-expect-error: FIX update types
          if (result.success) {
            reset();
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
