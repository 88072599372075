import {
  DeleteEquipmentPieceMutation,
  DeleteEquipmentPieceMutationVariables,
  useDeleteEquipmentPieceMutation,
} from "generated/graphql";
import { useCallback } from "react";

export default function useDeleteEquipmentPiece() {
  const [mutation, { data, loading, error }] = useDeleteEquipmentPieceMutation({
    refetchQueries: ["getEquipmentPieces", "customGetEquipmentPieces"],
  });

  const deleteEquipmentPiece = useCallback((values: DeleteEquipmentPieceMutationVariables) => {
    try {
      return mutation({ variables: values });
    } catch (error) {
      return;
    }
  }, []);

  const isSuccess = useCallback((data: DeleteEquipmentPieceMutation) => {
    return data && data.deleteEquipmentPiece === true;
  }, []);

  const success = isSuccess(data);
  const failure = data && data.deleteEquipmentPiece === false;

  return [deleteEquipmentPiece, { data, loading, error, success, failure, isSuccess }] as const;
}
