import { InviteUserMutation, useInviteUserMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import { useCallback, useMemo } from "react";

export default function useInviteUser() {
  const [mutation, { data: rawData, loading, error }] = useInviteUserMutation({
    refetchQueries: ["getOrganizationUsers", "customGetOrganizationUsers"],
  });

  const isSuccess = useCallback((data: InviteUserMutation) => {
    return data && data.inviteUser === true;
  }, []);

  const [inviteUser] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data: rawData,
      key: "inviteUser",
    },
    {
      hideMessage: true,
    }
  );

  const data = useMemo(() => {
    return (rawData && rawData.inviteUser) || null;
  }, [rawData]);

  const success = useMemo(() => {
    return isSuccess(rawData);
  }, [rawData]);

  const exists = useMemo(() => {
    return error && error.message === "exists";
  }, [error]);

  const realError = useMemo(() => {
    return !exists ? error : null;
  }, [error, exists]);

  return [inviteUser, { data, loading, error: realError, success, exists, isSuccess }] as const;
}
