import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { Employee, SortDirection } from "generated/graphql";
import useGetEmployees from "hooks/organization/useGetEmployees";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function EmployeeOptions({
  children,
}: {
  children: ({
    employeeOptions,
    employeesById,
    loading,
  }: {
    employeeOptions: SelectOptions;
    employeesById: Record<string, Employee | undefined>;
    loading: boolean;
  }) => React.ReactNode;
}) {
  const { loading, data, error } = useGetEmployees({
    first: 100,
    page: 1,
    sort: SortDirection.ASC,
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const employees = data ?? [];

  const employeeOptions = employees.map((employee) => ({
    label: employee.name,
    value: employee.id,
  }));

  const employeesById = employees.reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val,
    };
  }, {});

  return <>{children({ employeeOptions, employeesById, loading })}</>;
}
