import { useMemo } from "react";

const useJobCostingDetailsTable = (data) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Product Type",
        accessor: "productType",
      },
      {
        Header: "% Equipment Used",
        accessor: "equipmentUsedPercentage",
        Cell: ({ value }) => `${value || 0}%`,
      },
      {
        Header: "% Material Used",
        accessor: "materialUsedPercentage",
        Cell: ({ value }) => `${value || 0}%`,
      },
      {
        Header: "% Labour Used",
        accessor: "labourUsedPercentage",
        Cell: ({ value }) => `${value || 0}%`,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    tableData,
  };
};

export default useJobCostingDetailsTable;
