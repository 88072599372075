import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useCallback } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { array } from "zod";
import { Company, NotificationTriggerType } from "generated/graphql";
import ProjectFileExplorerInput from "modules/ProjectFiles/ProjectFileExplorerInput";
import useUpdateCompanyProjectFileFolderId from "hooks/companies/useUpdateCompanyProjectFileFolderId";

interface InfoItemProps {
  item: { label: string; value: string };
  company?: Company;
  direction?: "row" | "column";
}

const notificationTypes = Object.values(NotificationTriggerType);

export const InfoItem: React.FC<InfoItemProps> = ({ item, direction = "row", company }) => {
  const { label, value } = item;
  const [updateCompanyProjectFileFolderId] = useUpdateCompanyProjectFileFolderId();

  const modifiedPhoneNumber = useCallback(
    (phone) => {
      const prependCode = phone.substring(0, 2) === "+1" ? phone : `+1${phone}`;
      return formatPhoneNumber(prependCode);
    },
    [item]
  );

  const updateObjToArray = (object) => {
    let notificationsTypeOptions = [];
    if (object) {
      const entriesArray = Object.entries(object);
      const filteredArray = entriesArray.filter(([key]) => key !== "__typename");
      notificationsTypeOptions = filteredArray;
    }
    return notificationsTypeOptions;
  };
  const handleProjectFilesFolderSelect = async (folderId: string) => {
    await updateCompanyProjectFileFolderId({
      variables: {
        id: company?.id,
        projectFilesFolderId: folderId,
      },
    });
  };

  const renderCheckboxes = () => {
    const notificationsTypeOptions = updateObjToArray(value[0]);
    if (notificationsTypeOptions.length === 0) {
      return notificationTypes.map((type) => (
        <FormControlLabel
          key={type}
          control={<Checkbox name={type.toLowerCase()} checked={false} />}
          label={type
            .replace("_", " ")
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}
        />
      ));
    } else {
      return notificationsTypeOptions.map((value) => (
        <FormControlLabel
          key={value[0]}
          control={<Checkbox name={value[0]} checked={value[1] == true ? true : false} />}
          label={value[0]}
        />
      ));
    }
  };

  return (
    <MDBox display="flex" py={1} pr={2} flexDirection={direction}>
      {label !== "Project files folder id" && (
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {label}
          {direction === "row" ? ":" : ""}&nbsp;
        </MDTypography>
      )}
      {label !== "Notification types" && label !== "Project files folder id" && (
        <MDTypography variant="button" fontWeight="regular" color="text">
          {label === "Phone" ? modifiedPhoneNumber(value[0]) : value}
        </MDTypography>
      )}{" "}
      {label === "Project files folder id" && (
        <ProjectFileExplorerInput
          submitFn={handleProjectFilesFolderSelect}
          folderId={company.projectFilesFolderId}
          showLabel
        />
      )}
      {label === "Notification types" && (
        <Grid item xs={12}>
          <MDBox display="flex" gap={2} mt={2}>
            {renderCheckboxes()}
          </MDBox>
        </Grid>
      )}
    </MDBox>
  );
};
