import getLocalString from "constants/Localization";
import { useAddProposalAdditionalCostsMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddProposalAdditionalCosts() {
  const [mutation, { loading, data, error }] = useAddProposalAdditionalCostsMutation({
    refetchQueries: ["customGetProposal"],
  });
  const [addProposalAdditionalCosts, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "addProposalAdditionalCosts",
    },
    {
      successMessage: getLocalString("proposals.add-proposal-additional-costs-success"),
      failureMessage: getLocalString("proposals.add-proposal-additional-costs-failure"),
    }
  );

  return [addProposalAdditionalCosts, context] as const;
}
