import getExtensionFilename from "utils/strings/getExtensionFromFilename";

const isImage = (filename: string) => {
  const extensionfromFileName = filename?.slice(-3)?.toLowerCase();
  const extension = getExtensionFilename(filename)?.toLowerCase();
  const imagesExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
  return imagesExtensions.some((x) => x.includes(extension) || x.includes(extensionfromFileName));
};

export default isImage;
