import { routesKeys, RoutesKeys } from "routes";
import isValidEmail from "utils/validations/isValidEmail";

export const getRoute = (routeKey: RoutesKeys, params: [string, string][] = []) => {
  const foundRoute = routesKeys[routeKey];
  if (foundRoute) {
    const resultingRoute = params.reduce((acc, val) => {
      return acc.replace(`:${val[0]}`, val[1]);
    }, foundRoute);
    return resultingRoute;
  } else {
    return "/not-found";
  }
};

export const getVerificationResendRoute = (email: string | null) => {
  if (email) {
    return `${getRoute("signup.resend")}${
      isValidEmail(email ?? "") ? `?email=${encodeURIComponent(email)}` : ""
    }`;
  }

  return getRoute("signup.resend");
};

export const getLoginRoute = (email: string | null) => {
  if (email) {
    return `${getRoute("signin")}${
      isValidEmail(email ?? "") ? `?email=${encodeURIComponent(email)}` : ""
    }`;
  }

  return getRoute("signin");
};

export default getRoute;
