import {
  Card,
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import timeEndAdornment from "constants/timeEndAdornment";
import { JobPhaseCostItemInputSchema } from "DDD/action-objects/JobPhaseCostItemCreate";
import { EquipmentPiece } from "generated/graphql";
import AddEquipmentCost from "modules/proposals/equipmentCosts/AddEquipmentCost";
import { useFieldArray, useFormContext } from "react-hook-form";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

const calculateTotal = (equipmentPiece: JobPhaseCostItemInputSchema["equipmentPieces"][number]) => {
  if (!equipmentPiece) {
    return 0;
  }
  const total =
    //@ts-ignore
    (parseInt(equipmentPiece.timeTravel) + parseInt(equipmentPiece.timeOnjob)) *
    equipmentPiece.quantity *
    equipmentPiece.rate;

  return isNaN(total) ? 0 : total;
};

function EquipmentPieceTotal({ fieldName }: { fieldName: string }) {
  const { watch } = useFormContext();
  const equipmentPiece = watch(fieldName);

  return <span>{formatCentsToUSD(calculateTotal(equipmentPiece))}</span>;
}

export default function EquipmentSection() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<JobPhaseCostItemInputSchema>();

  const { fields, append, remove } = useFieldArray({
    name: "equipmentPieces",
    keyName: "key",
    control: control,
  });

  const onAdd = (eps: EquipmentPiece[]) => {
    eps.forEach((ep) => {
      const { id, name, rate } = ep;
      const foundSameEquipment = fields.find((existingEquipment) => existingEquipment.id === id);

      if (!foundSameEquipment) {
        const newEquipment = {
          id: id,
          name: name,
          quantity: 1,
          rate: rate,
        };
        append(newEquipment);
      }
    });
  };

  return (
    <Card>
      <MDBox p={4}>
        <MDTypography>Equipment Pieces</MDTypography>
        {errors.equipmentPieces?.message && (
          <MDBox my={2}>
            <FormHelperText error>{errors.equipmentPieces.message}</FormHelperText>
          </MDBox>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell sx={{ minWidth: "150px", width: "18%" }}>Name</TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "18%" }}>
                    Quantity
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "18%" }}>
                    Travel Time
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "18%" }}>
                    On Job Time
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px", width: "18%" }}>
                    Total
                  </TableCell>
                  <TableCell sx={{ minWidth: "150px", width: "10%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields?.map((equipmentPiece, i) => {
                  return (
                    <TableRow
                      key={equipmentPiece.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <input
                        type="hidden"
                        value={equipmentPiece.id}
                        {...register(`equipmentPieces.${i}.id`)}
                      />
                      <TableCell>{equipmentPiece.name ?? ""}</TableCell>
                      <TableCell align="right">
                        <FormField
                          align="right"
                          type="number"
                          placeholder={equipmentPiece.quantity}
                          defaultValue={1}
                          mb={0}
                          sx={{
                            "& .MuiInputBase-root": {
                              "& input": {
                                textAlign: "right",
                              },
                            },
                          }}
                          {...register(`equipmentPieces.${i}.quantity`)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FormField
                          align="right"
                          placeholder={equipmentPiece.timeTravel}
                          defaultValue={0}
                          mb={0}
                          sx={{
                            "& .MuiInputBase-root": {
                              "& input": {
                                textAlign: "right",
                              },
                            },
                          }}
                          {...timeEndAdornment}
                          {...register(`equipmentPieces.${i}.timeTravel`)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FormField
                          align="right"
                          placeholder={equipmentPiece.timeOnjob}
                          defaultValue={0}
                          mb={0}
                          sx={{
                            "& .MuiInputBase-root": {
                              "& input": {
                                textAlign: "right",
                              },
                            },
                          }}
                          {...timeEndAdornment}
                          {...register(`equipmentPieces.${i}.timeOnjob`)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <EquipmentPieceTotal fieldName={`equipmentPieces.${i}`} />
                      </TableCell>
                      <TableCell align="right">
                        <MDButton
                          type="button"
                          variant="text"
                          color="error"
                          onClick={() => remove(i)}
                        >
                          Remove
                        </MDButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell colSpan={6}>
                    <AddEquipmentCost onAdd={onAdd} isJobCosting allowCreate={false} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
