import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  DeleteCrewMutation,
  DeleteCrewMutationVariables,
  useDeleteCrewMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback } from "react";

export default function useDeleteCrew() {
  const [mutation, { data, loading, error }] = useDeleteCrewMutation({
    refetchQueries: ["getCrews", "customGetCrews"],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = useCallback((data: DeleteCrewMutation) => {
    return data && data.deleteCrew === true;
  }, []);

  const deleteCrew = useCallback(
    withMutationAction(
      async (props: MutationHookOptions<DeleteCrewMutation, DeleteCrewMutationVariables>) => {
        return mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("organization.delete-crew-success"),
        failureMessage: getLocalString("organization.delete-crew-failure"),
        showValidationErrors: true,
      }
    ),
    []
  );

  const success = isSuccess(data);
  const failure = data && data.deleteCrew === false;

  return [deleteCrew, { data, loading, error, success, failure, isSuccess }] as const;
}
