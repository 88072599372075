import React, { useContext } from "react";
import { Grid, IconButton, Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import { SidebarContext } from "./sidebarContext";

export enum DefinedButtons {
  ProposalCollapseProducts = "proposal.collapse_products",
  ProposalSummaryView = "proposal.summary_view",
  ProposalPreview = "proposal.preview",
}

enum DefinedButtonsTitles {
  ProposalCollapseProducts = "Collapse All Products",
  ProposalSummaryView = "Proposal Summary View",
  ProposalPreview = "Preview Proposal",
}

enum DefinedButtonsIcons {
  ProposalCollapseProducts = "unfold_less_icon",
  ProposalSummaryView = "fact_check_icon",
  ProposalPreview = "plagiarism_icon",
}

interface SidebarProps {
  buttons?: DefinedButtons[];
}

const Sidebar: React.FC<SidebarProps> = ({ buttons }) => {
  const { clickedButtonHandle } = useContext(SidebarContext);

  const setScrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const setScrollDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const handleButtonClick = (buttonType: DefinedButtons) => {
    clickedButtonHandle(buttonType);
  };

  const findTitle = (buttonValue) => {
    const key = Object.keys(DefinedButtons).find(
      (key) => DefinedButtons[key as keyof typeof DefinedButtons] === buttonValue
    ) as DefinedButtons | undefined;
    return DefinedButtonsTitles[key];
  };

  const findIcon = (buttonValue) => {
    const key = Object.keys(DefinedButtons).find(
      (key) => DefinedButtons[key as keyof typeof DefinedButtons] === buttonValue
    ) as DefinedButtons | undefined;
    return DefinedButtonsIcons[key];
  };

  return (
    <Grid className="float-sidebar">
      <MDBox sx={{ width: "46px", height: "46px" }}>
        <Tooltip title="Scroll to Top" placement="left">
          <IconButton onClick={() => setScrollUp()} sx={{ color: "#ffffff" }}>
            <Icon sx={{ fontWeight: "bolder" }}>north_icon</Icon>
          </IconButton>
        </Tooltip>
      </MDBox>
      {buttons.map((button) => (
        <MDBox
          key={button}
          sx={{ width: "46px", height: "46px", alignItems: "center", display: "flex" }}
        >
          <Tooltip title={findTitle(button)} placement="left">
            <IconButton onClick={() => handleButtonClick(button)} sx={{ color: "#ffffff" }}>
              <Icon sx={{ fontWeight: "bolder" }}>{findIcon(button)}</Icon>
            </IconButton>
          </Tooltip>
        </MDBox>
      ))}
      <MDBox sx={{ width: "46px", height: "46px" }}>
        <Tooltip title="Scroll to Bottom" placement="left">
          <IconButton onClick={() => setScrollDown()} sx={{ color: "#ffffff" }}>
            <Icon sx={{ fontWeight: "bolder" }}>south_icon</Icon>
          </IconButton>
        </Tooltip>
      </MDBox>
    </Grid>
  );
};

export default Sidebar;
