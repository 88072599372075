import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AdditionalCost } from "generated/graphql";
import useGetAdditionalCost from "hooks/organization/useGetAdditionalCost";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Update from "modules/OrganizationAdditionalCosts/Update";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function AdditionalCostEditPage() {
  const { additionalCostId } = useParams();
  const [getAdditionalCost, { loading, error, additionalCost }] = useGetAdditionalCost();

  useEffect(() => {
    getAdditionalCost({ id: additionalCostId });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={12} mb={3}>
        {loading && <>Loading</>}
        {error && (
          <MDAlert color="error">
            Could not load additional cost details. Please refresh this page or try again later.
          </MDAlert>
        )}
        {!error && (
          <>
            <MDBox mb={6}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <MDTypography variant="h4" fontWeight="medium">
                    Updating Additional Cost
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {additionalCost && <Update additionalCost={additionalCost as AdditionalCost} />}
                {!additionalCost && <Skeleton />}
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
