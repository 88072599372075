import {
  CustomGetJobSchedulesQuery,
  GetJobSchedulesQueryVariables,
  useCustomGetJobSchedulesQuery,
} from "generated/graphql";

export type CustomJobSchedules = CustomGetJobSchedulesQuery["getJobSchedules"];
export type CustomJobSchedule = CustomJobSchedules[number];

export default function useGetJobSchedules(variables: GetJobSchedulesQueryVariables) {
  const { loading, data: graphqlData, error } = useCustomGetJobSchedulesQuery({ variables });

  return {
    loading,
    data: graphqlData?.getJobSchedules || null,
    error,
  } as const;
}
