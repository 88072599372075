import { JobPhase, useCustomGetJobPhaseQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetJobWorkOrder(phaseId: JobPhase["id"]) {
  const { data, loading, error } = useCustomGetJobPhaseQuery({
    variables: {
      id: phaseId,
    },
  });

  const jobWorkOrder = useMemo(() => {
    return data?.getJobPhase || null;
  }, [data]);

  return { data: jobWorkOrder, loading, error } as const;
}
