import getLocalString from "constants/Localization";
import { DeletePlantMutation, useDeletePlantMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import { useCallback, useMemo } from "react";

export default function useDeletePlant() {
  const [mutation, { loading, error, data }] = useDeletePlantMutation({
    refetchQueries: ["getPlants", "customGetPlants"],
  });

  const [deletePlant, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deletePlant",
    },
    {
      successMessage: getLocalString("organization.delete-plant-success"),
      failureMessage: getLocalString("organization.delete-plant-failure"),
      showValidationErrors: true,
    }
  );

  return [deletePlant, context] as const;
}
