import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import getImageAssetURL from "utils/images/getImageAssetURL";

export default function OrganizationAndForemanDetails({
  organization,
  contact,
  foreman,
}: {
  organization: any;
  contact: any;
  foreman: any;
}) {
  return (
    <Grid item xs={12} lg={6} className="no-break">
      <MDBox>
        {/* <MDBox textAlign="center" mb={3}>
          <MDBox
            component="img"
            src={getImageAssetURL(organization?.logo)}
            maxWidth="100%"
            maxHeight={365}
            alt="logo"
          />
        </MDBox> */}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>Client name</TableCell>
                <TableCell align="right">
                  {contact?.title} {contact?.firstName} {contact?.lastName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Client phone</TableCell>
                <TableCell align="right">{contact?.phone}</TableCell>
              </TableRow>
              {foreman && (
                <>
                  <TableRow>
                    <TableCell>Foreman name</TableCell>
                    <TableCell align="right">{foreman?.name}</TableCell>
                  </TableRow>
                  {foreman.phone && (
                    <TableRow>
                      <TableCell>Foreman phone</TableCell>
                      <TableCell align="right">{foreman?.phone}</TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>
    </Grid>
  );
}
