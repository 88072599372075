import { useCustomGetJobSchedulesLazyQuery } from "generated/graphql";
import { useCallback, useState } from "react";
import { CalendarEvent } from "utils/events/transformSchedulesToEvents";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { jobStatusValueMapping } from "utils/jobs/jobStatusValueMapping";
import { pick } from "ramda";
import { capitalCase } from "change-case";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export default function useGetJobSchedulesLazy() {
  const [query, { loading, data, error }] = useCustomGetJobSchedulesLazyQuery();
  const [events, setEvents] = useState<CalendarEvent[] | null>(null);

  const getJobsSchedules = useCallback(
    async (options: Parameters<typeof query>[0]) => {
      const result = await query(options);

      const _events = result?.data?.getJobSchedules.map((jobSchedule): CalendarEvent => {
        const event = {
          title: `${jobSchedule.job.company.name},<br />${
            jobSchedule.jobPhase.proposalStage.name
          }, <br /> ${
            jobSchedule.jobPhase?.organizationProductType?.name ?? ""
          },<br/> ${capitalCase(jobSchedule.jobPhase.status)}`,
          start: dayjs
            .utc(jobSchedule.scheduledAt)
            .subtract(jobSchedule.showTravelFrom ? jobSchedule.travelTime : 0, "hours")
            .toISOString(),
          end: dayjs
            .utc(jobSchedule.blockLength ? jobSchedule.scheduledAt : jobSchedule.endsAt)
            .add(
              (jobSchedule.showTravelTo ? jobSchedule.travelTime : 0) +
                (jobSchedule.blockLength ?? 0),
              "hours"
            )
            .toISOString(),
          color: `#${jobSchedule.crew?.color || jobSchedule.jobPhase.usersColor}`,
          textColor: jobSchedule.crew?.color === "000000" ? "white" : "black",
          block: pick(["id", "showTravelTo", "travelTime", "showTravelFrom"], jobSchedule),
          crewId: jobSchedule.crew?.id,
          jobId: jobSchedule.job.id,
          jobPhaseId: jobSchedule.jobPhase.id,
          pushedToQuickBook: jobSchedule.job.datePushedToQuickbooks,
          billingStatus: jobSchedule.job.billingStatus,
        };
        return event;
      });

      setEvents(_events);

      return {
        ...result,
        events: _events,
      };
    },
    [query, setEvents]
  );

  return [getJobsSchedules, { loading, data, error, events }] as const;
}
