import MDButton from "components/MDButton";

const ReorderProposalStages = ({
  setSortMode,
  disabled,
}: {
  setSortMode: (mode: boolean) => void;
  disabled: boolean;
}) => {
  return (
    <MDButton
      color="success"
      onClick={() => {
        setSortMode(true);
      }}
      disabled={disabled}
      sx={{ marginLeft: 1 }}
    >
      Reorder Proposal Stages
    </MDButton>
  );
};

export default ReorderProposalStages;
