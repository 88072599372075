import getLocalString from "constants/Localization";
import MDAlert from "components/MDAlert";
import { Employee, UpdateEmployeeInput, UserStatus } from "generated/graphql";
import useUpdateEmployee from "hooks/organization/employees/useUpdateEmployee";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";
import MDTypography from "components/MDTypography";
import ClearIcon from "@mui/icons-material/Clear";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";

export default function Update({ employee }: { employee: Employee }) {
  const [updateEmployee, { loading, error, isSuccess }] = useUpdateEmployee();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<UpdateEmployeeInput>({
    defaultValues: {
      ...employee,
      rate: +employee.rate / 100,
      userId: employee.userId,
    },
  });

  const onSubmit = async ({ id, firstName, lastName, rate, userId }) => {
    await updateEmployee({
      variables: {
        input: {
          id,
          firstName,
          lastName,
          rate: rate * 100,
          userId,
        },
      },
    });
    if (isSuccess) {
      navigate(getRoute("organization-settings.employees"));
    }
  };

  return (
    <>
      {error && (
        <MDAlert color="error">{getLocalString("organization.update-crew-failure")}</MDAlert>
      )}
      <Card>
        <MDBox pb={4} pl={4} component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={4}>
              <MDInput
                fullWidth
                margin="normal"
                error={errors.firstName}
                {...register("firstName", { required: true })}
                label="First name"
              />
              <MDInput
                fullWidth
                margin="normal"
                error={errors.lastName}
                {...register("lastName", { required: true })}
                label="Last name"
              />
              <MDBox>
                <OrganizationUsersOptions
                  queryOverride={{ first: 1000, status: UserStatus.ACTIVE }}
                >
                  {({ organizationUsersOptions, loading }) => (
                    <Controller
                      control={control}
                      name="userId"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <AutoComplete
                            options={organizationUsersOptions}
                            loading={loading}
                            variant="outlined"
                            field={{
                              onChange: field.onChange,
                              onBlur: field.onBlur,
                              value: field.value,
                              name: field.name,
                              ref: field.ref,
                            }}
                            getOptionDisabled={(option) => option.hasEmployee}
                            error={error}
                            label="Select User"
                          />
                        </>
                      )}
                    />
                  )}
                </OrganizationUsersOptions>
              </MDBox>
              <MDInput
                type="number"
                fullWidth
                margin="normal"
                error={errors.rate}
                {...register("rate", { required: true, min: 1, valueAsNumber: true })}
                label="rate"
              />
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Update
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  );
}
