import {
  CustomGetDashboardMapDataQuery,
  DateWithMaterialAmount,
  GetCurrentMonthStatsQuery,
  GetJobScheduleByAmountStatsQuery,
  GetLastYearStatsQuery,
  GetMaterialNeededByDayStatsQuery,
  GetPersonsStatsQuery,
  GetPreviousMonthStatsQuery,
  GetProductStatsQuery,
  GetYearToDateStatsQuery,
  useCustomGetDashboardMapDataQuery,
  useGetCurrentMonthStatsQuery,
  useGetJobScheduleByAmountStatsQuery,
  useGetLastYearStatsQuery,
  useGetMaterialNeededByDayStatsQuery,
  useGetPersonsStatsQuery,
  useGetPreviousMonthStatsQuery,
  useGetProductStatsQuery,
  useGetYearToDateStatsQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";
import { Chart } from "types/types";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

export const useGetDashboardStats = () => {
  // const {
  //   loading: loadingDefaultDashboardStats,
  //   error,
  //   data: graphqlData,
  // } = useGetDefaultDashboardStatsQuery();

  const {
    loading: loadingGetCurrentMonthStats,
    error: currentMonthStatsError,
    data: currentMonthStatsData,
  } = useGetCurrentMonthStatsQuery();

  const {
    loading: loadingGetPreviousMonthStats,
    error: previousMonthStatsError,
    data: previousMonthStatsData,
  } = useGetPreviousMonthStatsQuery();

  const {
    loading: loadingLastYearStats,
    error: lastYearStatsError,
    data: lastYearStatsData,
  } = useGetLastYearStatsQuery();

  const {
    loading: loadingPersonsStats,
    error: personsStatsError,
    data: personsStatsData,
  } = useGetPersonsStatsQuery();

  const {
    loading: loadingJobScheduleByAmountStats,
    error: jobScheduleByAmountStatsError,
    data: jobScheduleByAmountStatsData,
  } = useGetJobScheduleByAmountStatsQuery();

  const {
    loading: loadingMaterialNeededByDay,
    error: materialNeededByDayError,
    data: materialNeededByDayStatsData,
  } = useGetMaterialNeededByDayStatsQuery();

  const { loading: loadingYearToDateStats, data: yearToDateStatsData } =
    useGetYearToDateStatsQuery();

  const { loading: loadingProductStatsData, data: productStatsData } = useGetProductStatsQuery();
  const { loading: loadingProductMapData, data: mapStatsData } =
    useCustomGetDashboardMapDataQuery();

  const getData = useCallback(
    ({
      data,
      key,
    }: {
      data:
        | GetCurrentMonthStatsQuery
        | GetPreviousMonthStatsQuery
        | GetLastYearStatsQuery
        | GetPersonsStatsQuery
        | GetJobScheduleByAmountStatsQuery
        | GetMaterialNeededByDayStatsQuery
        | GetYearToDateStatsQuery
        | GetProductStatsQuery
        | CustomGetDashboardMapDataQuery;
      key:
        | "getCurrentMonthStats"
        | "getPreviousMonthStats"
        | "getLastYearStats"
        | "getPersonsStats"
        | "getJobScheduleByAmountStats"
        | "getMaterialNeededByDayStats"
        | "getYearToDateStats"
        | "getProductStats"
        | "getDashboardMapData";
    }) => {
      return data?.[key] || null;
    },
    []
  );

  const currentMonthData = useMemo(() => {
    return getData({ data: currentMonthStatsData, key: "getCurrentMonthStats" });
  }, [currentMonthStatsData]);

  const previousMonthData = useMemo(() => {
    return getData({ data: previousMonthStatsData, key: "getPreviousMonthStats" });
  }, [previousMonthStatsData]);

  const lastYearData = useMemo(() => {
    return getData({ data: lastYearStatsData, key: "getLastYearStats" });
  }, [lastYearStatsData]);

  const personData = useMemo(() => {
    return getData({ data: personsStatsData, key: "getPersonsStats" });
  }, [personsStatsData]);

  const jobScheduleByAmountData = useMemo(() => {
    return getData({ data: jobScheduleByAmountStatsData, key: "getJobScheduleByAmountStats" });
  }, [jobScheduleByAmountStatsData]);

  const materialNeededByDayData = useMemo(() => {
    return getData({ data: materialNeededByDayStatsData, key: "getMaterialNeededByDayStats" });
  }, [materialNeededByDayStatsData]);

  const yearToDateData = useMemo<GetYearToDateStatsQuery["getYearToDateStats"]>(() => {
    return getData({ data: yearToDateStatsData, key: "getYearToDateStats" });
  }, [yearToDateStatsData]);

  const productData = useMemo(() => {
    return getData({ data: productStatsData, key: "getProductStats" });
  }, [productStatsData]);

  const mapData = useMemo(() => {
    return getData({ data: mapStatsData, key: "getDashboardMapData" });
  }, [mapStatsData]);

  const availableColors = [
    "dark",
    "primary",
    "success",
    "warning",
    "error",
    "secondary",
    "negative",
    "light",
    "info",
  ];

  const getBackgroundColors = useCallback(
    (idx) => availableColors[idx] ?? getBackgroundColors(idx - availableColors.length),
    []
  );

  type ChartObject = {
    [key: string]:
      | {
          materialNeeded: number;
          amount: number;
        }
      | string;
  };

  const buildChartObject = (row: DateWithMaterialAmount, index: number): ChartObject => {
    return {
      date: row[index].date,
      [row[index].material]: {
        materialNeeded: row[index].jobPhaseMaterialNeededInDay,
        amount: row[index].JobPhaseAmountScheduledInDay,
      },
    };
  };

  const materialNeededByDay = useMemo(() => {
    const materialNeeded = materialNeededByDayData;
    if (materialNeeded) {
      const newArr = [];
      for (let i = 0; i < materialNeeded.length; i++) {
        if (i === 0) {
          newArr[i] = buildChartObject(materialNeeded, i);
          continue;
        }

        if (materialNeeded[i].date === materialNeeded[i - 1].date) {
          const lastElement = newArr.at(-1);
          const { material } = materialNeeded[i];
          const lastElementCopy = {
            ...lastElement,
          };

          if (lastElement[material]) {
            lastElementCopy[material] = {
              materialNeeded:
                lastElement[material].materialNeeded +
                materialNeeded[i].jobPhaseMaterialNeededInDay,
              amount: lastElement[material].amount + materialNeeded[i].JobPhaseAmountScheduledInDay,
            };
          } else {
            lastElementCopy[material] = {
              materialNeeded: materialNeeded[i].jobPhaseMaterialNeededInDay,
              amount: materialNeeded[i].JobPhaseAmountScheduledInDay,
            };
          }

          newArr[newArr.length - 1] = lastElementCopy;
        } else {
          newArr.push(buildChartObject(materialNeeded, i));
        }
      }
      return newArr;
    }
    return null;
  }, [materialNeededByDayData]);

  const allMaterials = useMemo(() => {
    return [...new Set(materialNeededByDayData?.map((i) => i.material))];
  }, [materialNeededByDayData]);

  const materialNeededByDayChartData: {
    data: Chart;
    colors: { material: string; color: string }[];
  } = useMemo(() => {
    if (materialNeededByDay) {
      const uniqueDates = [...new Set(materialNeededByDay.map((i) => i.date))];
      const uniqueMaterials = [...new Set(materialNeededByDay.map((i) => i.material))];

      const colors = uniqueMaterials.map((material, idx) => ({
        material,
        color: getBackgroundColors(idx),
      }));

      const findMaterial = (material, date) =>
        materialNeededByDay.find(
          (product) => product.date === date && product.material === material
        );
      const datasets = uniqueMaterials.map((material, idx) => ({
        label: `${material} (${formatCentsToUSD(
          findMaterial(material, uniqueDates[idx])?.JobPhaseAmountScheduledInDay || 0
        )})`,
        color: colors.find((color) => color.material === material).color,
        data: uniqueDates.map(
          (date) =>
            materialNeededByDay
              .find((product) => product.date === date && product.material === material)
              ?.jobPhaseMaterialNeededInDay?.toFixed(2) || 0
        ),
      }));

      return {
        colors,
        data: {
          labels: uniqueDates,
          datasets,
        },
      };
    }
  }, [materialNeededByDay]);

  const productsUsedPercentagesTableData = useMemo(() => {
    return productData?.productTypeUsedPercentages?.map((p) => ({
      "Product Type": p.productType,
      "% Equipment Used": `${p.materialUsedPercentage || 0}%`,
      "% Material Used": `${p.equipmentUsedPercentage || 0}%`,
      "% Labour Used": `${p.labourUsedPercentage || 0}%`,
    }));
  }, [productData]);

  const productMaterialUsedTableData = useMemo(() => {
    return productData?.materialUsed?.map((p) => ({
      "Product Type": p.productType,
      "Job Size": p.proposalJobSize,
      "Job Size Complete": p.jobSizeComplete,
      "Material Estimated From Proposals": p.proposalMaterialNeeded,
      "Product Material Used (From Job Costing)": p.productMaterialUsed,
    }));
  }, [productData]);

  return {
    loadingYearToDateStats,
    loadingProductStatsData,
    lastYearData,
    jobScheduleByAmountData,
    personData,
    currentMonthData,
    previousMonthData,
    yearToDateData,
    productData,
    getData,
    materialNeededByDayChartData,
    materialNeededByDay,
    getBackgroundColors,
    productMaterialUsedTableData,
    productsUsedPercentagesTableData,
    mapData,
    allMaterials,
  };
};
