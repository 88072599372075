import { Checkbox, FormControlLabel, FormGroup, Grid, CircularProgress } from "@mui/material";
import TableFilter from "components/TableFilter/TableFilter";
import { capitalCase } from "change-case";
import { useState } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import { equals, reject, uniq } from "ramda";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function MultipleChoiceFilter<T extends string | number>({
  id,
  value,
  options,
  loading,
  error,
  onChange,
}: {
  id: string;
  value: T[];
  options: { label: string; value: T }[];
  loading?: boolean;
  error?: any;
  onChange: (value: T[]) => void;
}) {
  const [selectedOptions, setSelectedOptions] = useState<T[]>(value ?? []);

  const handleOnchange = (optionId, checked) => {
    setSelectedOptions((prevState) =>
      checked ? uniq([...prevState, optionId]) : reject(equals(optionId), prevState)
    );
  };

  const clearFilter = () => {
    setSelectedOptions([]);
  };

  return (
    <>
      <TableFilter value={value} id={id}>
        {loading && (
          <MDBox>
            <CircularProgress color="inherit" size="10px" />
          </MDBox>
        )}
        {error && (
          <MDBox sx={{ maxWidth: "250px" }}>
            <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>
          </MDBox>
        )}
        {!loading && !error && (
          <>
            <div className="multi-select">
              <FormGroup>
                {options.map((choice) => (
                  <FormControlLabel
                    key={choice.value}
                    control={
                      <Checkbox
                        checked={selectedOptions?.includes(choice.value) ?? false}
                        onChange={(e, checked) => handleOnchange(choice.value, checked)}
                      />
                    }
                    label={capitalCase(choice.label)}
                  />
                ))}
              </FormGroup>
            </div>

            <MDButton
              type="button"
              variant="gradient"
              color="secondary"
              onClick={() => {
                clearFilter();
              }}
              sx={{ margin: "10px" }}
            >
              Clear
            </MDButton>
            <MDButton type="button" color="blue" onClick={() => onChange(selectedOptions)}>
              Apply
            </MDButton>
          </>
        )}
      </TableFilter>
    </>
  );
}
