import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Employee } from "generated/graphql";
import useGetEmployeeLazy from "hooks/organization/employees/useGetEmployeeLazy";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Update from "modules/OrganizationEmployees/Update";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function EditEmployeePage() {
  const { employeeId } = useParams();
  const [getEmployee, { loading, error, employee }] = useGetEmployeeLazy();

  useEffect(() => {
    getEmployee({ id: employeeId });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load employee details. Please refresh this page or try again later.
        </MDAlert>
      )}
      {!error && (
        <MDBox my={3}>
          <MDBox mb={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h4" fontWeight="medium">
                  Update Employee
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {employee && <Update employee={employee as Employee} />}
              {!employee && <Skeleton />}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}
