import { Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  CreateOrganizationAddressInput,
  CreateOrganizationAddressMutationResult,
} from "generated/graphql";
import useCreateOrganizationAddress from "hooks/organization/useCreateOrganizationAddress";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Create from "modules/OrganizationAddresses/Create";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";

export default function OrganizationAddressCreatePage() {
  const [createOrganizationAddress, { loading, error, isSuccess, getData }] =
    useCreateOrganizationAddress();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (input: CreateOrganizationAddressInput) => {
      const result = await createOrganizationAddress({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      // Let's send the user to the address list
      if (isSuccess) {
        navigate(getRoute("organization-settings.organization.settings"));
      }
      return { success, data };
    },
    [createOrganizationAddress]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {error && <MDAlert color="error">Failed to create address</MDAlert>}
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Create Address
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Create onSubmit={onSubmit} loading={loading} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
