import { GetProjectFilesQuery, ProjectFileType, useGetProjectFilesQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetProjectFiles = () => {
  const { loading, error, data: graphqlData } = useGetProjectFilesQuery();

  const getData = useCallback(({ data }: { data: GetProjectFilesQuery }) => {
    return data?.getProjectFiles || null;
  }, []);

  const data = useMemo(() => {
    const rawData = getData({ data: graphqlData });
    if (rawData) {
      return [...rawData]?.sort((a, b) => {
        return a.type === ProjectFileType.FOLDER ? -1 : 1;
      });
    }
    return null;
  }, [graphqlData]);

  return { loading, error, data, getData };
};
