import { GetTransferTrucksQuery, useGetTransferTrucksQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetTransferTrucks";

export default function useGetTransferTrucks() {
  const { data: graphqlData, loading, error } = useGetTransferTrucksQuery();

  const getData = useCallback(({ data }: { data?: GetTransferTrucksQuery }) => {
    return data?.getTransferTrucks || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const total = data?.length || null;

  return { data, loading, error, total } as const;
}
