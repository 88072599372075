import { Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useValidateUserInviteToken from "hooks/signup/useValidateUserInviteToken";
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import AcceptInvite from "modules/AcceptInvite/AcceptInvite";
import { useEffect } from "react";

export default function AcceptInvitePage() {
  const [validateUserInviteToken, { loading, error, success, failure, email, token }] =
    useValidateUserInviteToken();

  useEffect(() => {
    // Validate the token immediately
    validateUserInviteToken();
  }, []);

  const definitelyFailed = failure || error;

  return (
    <IllustrationLayout
      title={definitelyFailed ? "Invite Expired" : "Accept Invitation"}
      description={
        definitelyFailed
          ? "Your invite has expired Please ask for it to be resent"
          : "Enter your details below"
      }
    >
      {loading && (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="rectangular" width={210} height={118} />
        </>
      )}
      {definitelyFailed && (
        <MDBox>
          <MDTypography textAlign="center">
            Your invite has expired. Please ask for it to be resent.
          </MDTypography>
        </MDBox>
      )}
      {success && <AcceptInvite email={email as string} token={token as string} />}
    </IllustrationLayout>
  );
}
