import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  SubmitHandler,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { useNavigate } from "react-router";
import { getDefaultValues, schema } from "DDD/action-objects/TransferTruckEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateTransferTruckInput, TransferTruck } from "generated/graphql";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import { getRoute } from "utils/routing";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";

export interface TransferTruckFormProps {
  onSubmit: SubmitHandler<UpdateTransferTruckInput>;
  loading: boolean;
  transferTruck: TransferTruck;
}

export default function TransferTruckForm({
  onSubmit,
  loading,
  transferTruck,
}: TransferTruckFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<UpdateTransferTruckInput>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(transferTruck),
  });
  const navigate = useNavigate();

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (values: UpdateTransferTruckInput) => {
          // @ts-expect-error: FIX update types
          const { success } = await onSubmit({
            ...values,
            defaultRateHourly: Number(values.defaultRateHourly),
            defaultRateTon: Number(values.defaultRateTon),
            defaultTonnage: Number(values.defaultTonnage),
          });
          if (success) {
            navigate(getRoute("organization.transfer-trucks"));
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <FormFields register={register} errors={errors} control={control} />
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

function FormFields({
  register,
  errors,
  control,
}: {
  control: Control<UpdateTransferTruckInput, any>;
  register: UseFormRegister<UpdateTransferTruckInput>;
  errors: FieldErrorsImpl<DeepRequired<UpdateTransferTruckInput>>;
}) {
  return (
    <>
      <input type="hidden" {...register("id")} />
      <Grid item xs={12} sm={6}>
        <FormField
          type="text"
          label="Name"
          placeholder="Name"
          error={errors.name}
          {...register("name")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          type="number"
          label="Default Tonnage"
          inputProps={{ min: 1, max: 255 }}
          error={errors.defaultTonnage}
          {...register("defaultTonnage", { valueAsNumber: true })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CurrencyFormField
          control={control}
          name="defaultRateHourly"
          label="Default Hourly Rate"
          inputProps={{ min: "0" }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CurrencyFormField
          control={control}
          name="defaultRateTon"
          label="Default Rate per Ton"
          inputProps={{ min: "0" }}
        />
      </Grid>
    </>
  );
}
