// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { UpdateOrganizationUserInput } from "generated/graphql";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import getImageAssetURL from "utils/images/getImageAssetURL";
import { useMemo } from "react";
import MDAlert from "components/MDAlert";

function UserAvatarFormField({
  register,
  watch,
  currentAvatarUrl,
}: {
  register: UseFormRegister<UpdateOrganizationUserInput>;
  watch: UseFormWatch<UpdateOrganizationUserInput>;
  currentAvatarUrl: string;
}): JSX.Element {
  const watchAvatar = watch("avatar");

  const previewImage = useMemo(() => {
    return watchAvatar?.length > 0 ? URL.createObjectURL(watchAvatar[0]) : "";
  }, [watchAvatar]);

  return (
    <Card
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)",
        },
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <Avatar previewImage={previewImage} image={currentAvatarUrl} />
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-11}
          position="relative"
          zIndex={1}
        >
          <MDBox mr={1}>
            <MDButton variant="gradient" color="success" size="small" component="label">
              Update
              <input hidden accept="image/png, image/jpeg" type="file" {...register("avatar")} />
            </MDButton>
          </MDBox>
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          User Avatar
        </MDTypography>
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          Images can be a maximum of 2mb, and 1000x1000
        </MDTypography>
        {watchAvatar && <AvatarValidation avatar={watchAvatar} />}
      </MDBox>
    </Card>
  );
}

function Avatar({ previewImage, image }: { previewImage: string; image: string }) {
  return (
    <MDBox
      component="img"
      src={
        previewImage
          ? previewImage
          : image
          ? getImageAssetURL(image)
          : getImageAssetURL("avatar-placeholder.png", false)
      }
      alt="User Avatar"
      borderRadius="lg"
      shadow="sm"
      width="100%"
      height="100%"
      position="relative"
      zIndex={10}
      mb={2}
    />
  );
}

function AvatarValidation({ avatar }: { avatar: FileList }) {
  const sizeStatus = useMemo(() => {
    const fileSize = avatar[0].size / 1024 / 1024;
    if (fileSize > 2) {
      return { valid: false, size: fileSize };
    } else {
      return { valid: true, size: fileSize };
    }
  }, [avatar]);

  if (sizeStatus.valid) {
    return null;
  }

  return <MDAlert color="error">File size of {sizeStatus.size.toFixed(2)}mb is not valid</MDAlert>;
}

export default UserAvatarFormField;
