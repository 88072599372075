import { pushIfNotExists } from "helpers/functions";
import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { CustomJobPhaseCostItem } from "hooks/jobs/job-phases/useGetJobPhaseCostItem";
import * as z from "zod";

export const schema = z.object({
  jobPhaseId: z.string().min(1, "Job phase id is required"),
  date: z.string().min(1, "Date is Required"),
  materialNeeded: z.coerce.number().min(0).optional(),
  equipmentPieces: z.array(
    z.object({
      id: z.string().min(1, "Equipment Piece Id is Required"),
      name: z.string().optional(),
      quantity: z.coerce.number().min(0),
      rate: z.coerce.number().min(0),
      timeOnjob: z.coerce.number().min(0),
      timeTravel: z.coerce.number().min(0),
    })
  ),
  additionalCosts: z.array(
    z.object({
      id: z.string().min(1, "Additional Cost Id is Required"),
      name: z.string().optional(),
      cost: z.coerce.number().min(0),
      feeAmount: z.coerce.number().min(0),
      feeType: z.string().optional(),
      costTotal: z.coerce.number().optional().or(z.null()),
    })
  ),
  employees: z.array(
    z.object({
      id: z.string().min(1, "Employee Id is Required"),
      name: z.string().optional(),
      rate: z.coerce.number().min(0),
      timeOnjob: z.coerce.number().min(0),
      timeTravel: z.coerce.number().min(0),
    })
  ),
  transferTrucks: z.array(
    z.object({
      id: z.string().min(1, "Plant Transfer Truck Id is Required"),
      name: z.string().optional(),
      tonnage: z.coerce.number().min(0),
      rateHourly: z.coerce.number().min(0),
      rateTon: z.coerce.number().min(0),
      hours: z.coerce.number().min(0),
    })
  ),
});

export type JobPhaseCostItemInputSchema = z.infer<typeof schema>;

export function cleanAddJobPhaseCostsInput(values: JobPhaseCostItemInputSchema) {
  const cleanParams = {
    ...values,
    employees: values.employees.map((e) => ({
      id: e.id,
      timeOnjob: e.timeOnjob,
      timeTravel: e.timeTravel,
    })),
    equipmentPieces: values.equipmentPieces.map((e) => ({
      id: e.id,
      quantity: e.quantity,
      timeOnjob: e.timeOnjob,
      timeTravel: e.timeTravel,
    })),
    additionalCosts: values.additionalCosts.map((a) => ({
      id: a.id,
      feeAmount: a.feeAmount,
    })),
    transferTrucks: values.transferTrucks.map((t) => ({
      id: t.id,
      hours: t.hours,
    })),
  };

  return cleanParams;
}

export const getDefaultValues = ({
  jobPhase,
  jobPhaseCostItem,
  date,
}: {
  jobPhase: CustomJobPhase;
  jobPhaseCostItem: CustomJobPhaseCostItem;
  date: string;
}) => {
  const equipmentPieces = jobPhaseCostItem?.equipmentPieces?.length
    ? pushIfNotExists(
        jobPhaseCostItem?.equipmentPieces,
        jobPhase?.workOrderEquipmentPieces ?? [],
        "equipmentPieceId"
      )
    : jobPhase?.defaultEquipmentPieces;
  const employees = jobPhaseCostItem?.employees ?? jobPhase?.defaultEmployees;
  const additionalCosts = jobPhaseCostItem?.additionalCosts ?? jobPhase?.defaultAdditionalCosts;
  const transferTrucks = pushIfNotExists(
    jobPhaseCostItem?.transferTrucks ?? [],
    jobPhase?.workOrderTransferTrucks ?? [],
    "transferTruckId"
  );

  return {
    date,
    jobPhaseId: jobPhase.id,
    materialNeeded: jobPhaseCostItem?.material?.materialNeeded ?? +jobPhase.proposedMaterialNeeded,
    equipmentPieces: equipmentPieces.map((equipmentPiece) => ({
      id: equipmentPiece.equipmentPieceId ?? equipmentPiece.id,
      name: equipmentPiece.name,
      quantity: equipmentPiece.quantity,
      timeTravel: equipmentPiece.timeTravel,
      timeOnjob: equipmentPiece.timeOnjob,
      rate: equipmentPiece.rate,
    })),
    additionalCosts:
      additionalCosts?.map((additionalCost) => ({
        id: additionalCost?.additionalCostId ?? additionalCost?.id,
        name: additionalCost.name,
        cost: additionalCost.cost,
        quantity: additionalCost?.quantity ?? 0,
        costTotal: additionalCost.costTotal,
        feeAmount: additionalCost.feeAmount ?? 0,
        feeType: additionalCost.feeType,
      })) ?? [],
    employees:
      employees?.map((employee) => ({
        id: employee.employeeId ?? employee.id,
        name: employee.name,
        rate: employee.rate,
        timeOnjob: employee.timeOnjob,
        timeTravel: employee.timeTravel,
      })) ?? [],
    transferTrucks:
      transferTrucks?.map((jobPhasePlantTransferTruck) => ({
        id: jobPhasePlantTransferTruck?.transferTruckId ?? jobPhasePlantTransferTruck?.id,
        name: jobPhasePlantTransferTruck.name,
        tonnage: jobPhasePlantTransferTruck.tonnage,
        rateHourly: jobPhasePlantTransferTruck.rateHourly,
        rateTon: jobPhasePlantTransferTruck.rateTon,
        hours: jobPhasePlantTransferTruck?.hours,
      })) ?? [],
  };
};

// import { JobPhase, JobPhaseCostItem } from "generated/graphql";
// import * as z from "zod";

// export const JobPhaseCostItemInputResolver = z.object({
//   /* end local values */
//   jobPhaseId: z.string().min(1, "Job phase id is required"),
//   plantId: z.string(),
//   plantProductId: z.string(),
//   materialNeeded: z.literal("").or(z.number()),
//   equipmentPieces: z.array(
//     z.object({
//       id: z.string().min(1, "Equipment Piece Id is Required"),
//       name: z.string().optional(),
//       quantity: z.number(),
//       rate: z.number(),
//       timeOnjob: z.number(),
//       timeTravel: z.number(),
//     })
//   ),
//   additionalCosts: z.array(
//     z.object({
//       id: z.string().min(1, "Additional Cost Id is Required"),
//       name: z.string().optional(),
//       cost: z.number(),
//       feeAmount: z.number(),
//     })
//   ),
//   employees: z.array(
//     z.object({
//       id: z.string().min(1, "Employee Id is Required"),
//       name: z.string().optional(),
//       rate: z.number(),
//       timeOnjob: z.number(),
//       timeTravel: z.number(),
//     })
//   ),
//   transferTrucks: z.array(
//     z.object({
//       id: z.string().min(1, "Plant Transfer Truck Id is Required"),
//       name: z.string().optional(),
//       tonnage: z.number(),
//       rateHourly: z.number(),
//       rateTon: z.number(),
//       hours: z.number(),
//     })
//   ),
// });

// export type JobPhaseCostItemInputSchema = z.infer<typeof JobPhaseCostItemInputResolver>;

// export const getDefaultValues = ({
//   jobPhase,
//   jobPhaseCostItem,
// }: {
//   jobPhase: JobPhase;
//   jobPhaseCostItem: JobPhaseCostItem;
// }): JobPhaseCostItemInputSchema => {
//   const equipmentPieces = jobPhaseCostItem?.equipmentPieces ?? jobPhase?.defaultEquipmentPieces;
//   const employees = jobPhaseCostItem?.employees ?? jobPhase?.defaultEmployees;

//   return {
//     jobPhaseId: jobPhase.id,
//     plantId: jobPhaseCostItem?.plantId ?? null,
//     plantProductId: jobPhaseCostItem?.plantProductId ?? null,
//     materialNeeded: jobPhase.proposedMaterialNeeded ?? 0,
//     equipmentPieces: equipmentPieces.map((equipmentPiece) => ({
//       id: equipmentPiece?.equipmentPieceId ?? equipmentPiece?.id,
//       name: equipmentPiece.name,
//       quantity: equipmentPiece.quantity ?? 1,
//       rate: equipmentPiece.rate,
//       timeTravel: 0,
//       timeOnjob: 0,
//     })),
//     additionalCosts:
//       jobPhaseCostItem?.additionalCosts?.map((additionalCost) => ({
//         id: additionalCost?.additionalCostId,
//         name: additionalCost?.name,
//         cost: 0,
//         feeAmount: 0,
//       })) ?? [],
//     employees:
//       employees?.map((employee) => ({
//         id: employee?.employeeId ?? employee.id,
//         name: employee.name,
//         rate: employee.rate,
//         timeOnjob: 0,
//         timeTravel: 0,
//       })) ?? [],
//     transferTrucks:
//       jobPhaseCostItem?.transferTrucks?.map((jobPhasePlantTransferTruck) => {
//         return {
//           id: jobPhasePlantTransferTruck?.plantTransferTruckId,
//           name: jobPhasePlantTransferTruck?.name,
//           tonnage: jobPhasePlantTransferTruck?.tonnage,
//           rateHourly: jobPhasePlantTransferTruck?.rateHourly,
//           rateTon: jobPhasePlantTransferTruck?.rateTon,
//           hours: 0,
//         };
//       }) ?? [],
//   };
// };
