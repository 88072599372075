import { Product } from "generated/graphql";
import DeleteProductMenuItem from "./DeleteProductMenuItem";
import UpdateProductMenuItem from "./UpdateProductMenuItem";

export default function ProductMenuItems({
  id,
  closeMenu,
}: {
  id: Product["id"];
  closeMenu: () => void;
}) {
  return (
    <>
      <UpdateProductMenuItem closeMenu={closeMenu} id={id} />
      <DeleteProductMenuItem closeMenu={closeMenu} id={id} />
    </>
  );
}
