import React, { useContext, useState, useMemo, useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ProposalContext from "./providers/ProposalContextProvider";
import useGetOrganizationSettings from "hooks/organization/useGetOrganizationSettings";
import DataTable from "components/Tables/DataTable/DataTable";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import MDBox from "components/MDBox";
import TransferTruckRow from "./forms/subforms/TransferTruckRow";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Typography,
  Button,
  TableSortLabel,
} from "@mui/material";

export enum DefinedColumns {
  Product = "Product",
  JobSIZE = "Job Size",
  Hours = "Hours",
  Days = "Days",
  MaterialNeeded = "Material Needed",
  Material = "Material",
  Trucking = "Trucking",
  Equipment = "Equipment",
  LaborCost = "Labor Cost",
  Additional = "Additional",
  TotalCost = "Total Cost",
  Profit = "Profit",
  Total = "Total",
  UnitPrice = "Unit Price",
}

interface SummaryModalProps {
  onCloseButton?: () => void;
  displayColumns?: DefinedColumns[];
  showSummaryTable?: boolean;
}

const ProposalStageSummary: React.FC<SummaryModalProps> = ({
  onCloseButton,
  displayColumns,
  showSummaryTable = true,
}) => {
  const { loading, data, error } = useGetOrganizationSettings();
  const { proposal } = useContext(ProposalContext);
  const [stages, setStages] = useState(proposal?.stages);
  const [groupedData, setGroupedData] = useState({});
  let averageWorkingHoursPerDay = 0;

  const getStageAddress = (addressObject) => {
    if (!addressObject) return "";
    const { line1, line2, city, state, zip } = addressObject;
    return [line1, line2?.trim(), city, state, zip].filter(Boolean).join(", ");
  };

  const getAverageWorkingHoursPerDay = () => {
    averageWorkingHoursPerDay = +data?.["average_working_hours_per_day"] ?? 0;
  };

  useEffect(() => {
    if (data) {
      getAverageWorkingHoursPerDay();
    }
  }, [data]);

  useEffect(() => {
    const groupedByMaterial = stages.reduce((acc, stage) => {
      stage.proposalProducts.forEach((curr) => {
        const materialName = curr.material?.name;
        if (!materialName) return;

        if (!acc[materialName]) {
          acc[materialName] = {
            product_name: materialName,
            job_size: 0,
            hours: 0,
            days: 0,
            material_needed: 0,
            material: 0,
            trucking: 0,
            equipment: 0,
            labor_cost: 0,
            additional: 0,
            total_cost: 0,
            profit: 0,
            total: 0,
            unit_price: 0,
          };
        }

        acc[materialName].job_size += curr.jobSize || 0;
        acc[materialName].hours += curr.jobDurationOverride || curr.jobDuration || 0;
        acc[materialName].days += getDays(curr) || 0;
        acc[materialName].material_needed += curr.material?.materialNeeded || 0;
        acc[materialName].material += parseFloat(curr.costMaterial) || 0;
        acc[materialName].trucking += parseFloat(curr.costTransferTruck) || 0;
        acc[materialName].equipment += parseFloat(curr.costEquipment) || 0;
        acc[materialName].labor_cost += parseFloat(curr.costLabour) || 0;
        acc[materialName].additional += parseFloat(curr.costAdditional) || 0;
        acc[materialName].total_cost += parseFloat(curr.costTotal) || 0;
        acc[materialName].profit += parseFloat(curr.profit) || 0;
        acc[materialName].total +=
          parseFloat(curr.overallTotalOverride) || parseFloat(curr.overallTotal) || 0;
        acc[materialName].unit_price =
          acc[materialName].job_size !== 0
            ? acc[materialName].total / acc[materialName].job_size
            : "";
      });
      return acc;
    }, []);

    const totalRow = {
      product_name: "Total",
      job_size: 0,
      hours: 0,
      days: 0,
      material_needed: 0,
      material: 0,
      trucking: 0,
      equipment: 0,
      labor_cost: 0,
      additional: 0,
      total_cost: 0,
      profit: 0,
      total: 0,
      unit_price: 0,
    };

    Object.values(groupedByMaterial).forEach((material: any) => {
      totalRow.job_size += material.job_size;
      totalRow.hours += material.hours;
      totalRow.days += material.days;
      totalRow.material_needed += material.material_needed;
      totalRow.material += material.material;
      totalRow.trucking += material.trucking;
      totalRow.equipment += material.equipment;
      totalRow.labor_cost += material.labor_cost;
      totalRow.additional += material.additional;
      totalRow.total_cost += material.total_cost;
      totalRow.profit += material.profit;
      totalRow.total += material.total;
    });

    totalRow.unit_price = totalRow.total / totalRow.job_size;

    groupedByMaterial["totalRow"] = totalRow;

    setGroupedData(groupedByMaterial);
  }, [stages, data]);

  const renderSummaryRows = (groupedData) => {
    return Object.values(groupedData).map((group, index) => (
      <TableRow key={index}>
        {allColumns.map((column) => {
          const cellValue = group[column.accessor];
          const formattedValue =
            cellValue !== undefined
              ? column.Cell
                ? column.Cell({ value: cellValue })
                : cellValue
              : "";

          return <TableCell key={column.accessor}>{formattedValue}</TableCell>;
        })}
      </TableRow>
    ));
  };

  const allColumns = useMemo(
    () => [
      { Header: "Product", accessor: "product_name", width: "30%" },
      { Header: "Job Size", accessor: "job_size", width: "7%" },
      { Header: "Hours", accessor: "hours", width: "7%", Cell: ({ value }) => value?.toFixed(1) },
      { Header: "Days", accessor: "days", width: "7%", Cell: ({ value }) => value?.toFixed(1) },
      {
        Header: "Material Needed",
        accessor: "material_needed",
        width: "7%",
        Cell: ({ value }) => value?.toFixed(2),
      },
      {
        Header: "Material",
        accessor: "material",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Trucking",
        accessor: "trucking",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Equipment",
        accessor: "equipment",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Labor Cost",
        accessor: "labor_cost",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Additional",
        accessor: "additional",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Total Cost",
        accessor: "total_cost",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Profit",
        accessor: "profit",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Total",
        accessor: "total",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
      {
        Header: "Unit Price",
        accessor: "unit_price",
        width: "7%",
        Cell: ({ value }) => formatCentsToUSD(value || 0),
      },
    ],
    []
  );

  const selectedColumns = useMemo(() => {
    if (!displayColumns || displayColumns.length === 0) return allColumns;
    return allColumns.filter((column) => displayColumns.includes(column.Header as DefinedColumns));
  }, [displayColumns, allColumns]);

  const getDays = (product) => {
    const duration = product?.jobDurationOverride || product.jobDuration;
    if (!averageWorkingHoursPerDay) {
      getAverageWorkingHoursPerDay();
    }

    return duration > 0 && averageWorkingHoursPerDay > 0 ? duration / averageWorkingHoursPerDay : 0;
  };

  const getTableData = (stage) => {
    const totalRow = {
      product_name: "Total",
      job_size: 0,
      hours: 0,
      days: 0,
      material_needed: 0,
      material: 0,
      trucking: 0,
      equipment: 0,
      labor_cost: 0,
      additional: 0,
      total_cost: 0,
      profit: 0,
      total: 0,
      unit_price: 0,
    };

    const rows = stage?.proposalProducts.map((product) => {
      const job_size = product?.jobSize;
      const hours = product?.jobDurationOverride || product?.jobDuration;
      const days = getDays(product);
      const material_needed = product?.material.materialNeeded;
      const material = product.costMaterial;
      const trucking = product.costTransferTruck;
      const equipment = product.costEquipment;
      const labor_cost = product.costLabour;
      const additional = product.costAdditional;
      const total_cost = product.costTotal;
      const profit = product.profit;
      const total = product.overallTotalOverride
        ? product.overallTotalOverride
        : product.overallTotal;
      const unit_price = job_size !== 0 ? total / job_size : "";

      totalRow.job_size += +job_size;
      totalRow.hours += +hours;
      totalRow.days += +days;
      totalRow.material_needed += +material_needed;
      totalRow.material += +material;
      totalRow.trucking += +trucking;
      totalRow.equipment += +equipment;
      totalRow.labor_cost += +labor_cost;
      totalRow.additional += +additional;
      totalRow.total_cost += +total_cost;
      totalRow.profit += +profit;
      totalRow.total += +total;
      totalRow.unit_price = totalRow.total / totalRow.job_size;

      return {
        product_name: product?.material?.name,
        job_size: job_size,
        hours: hours,
        days: days,
        material_needed: material_needed,
        material: material,
        trucking: trucking,
        equipment: equipment,
        labor_cost: labor_cost,
        additional: additional,
        total_cost: total_cost,
        profit: profit,
        total: total,
        unit_price: unit_price,
      };
    });

    rows.push(totalRow);

    return { columns: selectedColumns, rows };
  };

  const renderRows = (rows) => {
    return rows.map((row, index) => (
      <TableRow key={index}>
        {selectedColumns.map((column) => {
          const cellValue = row[column.accessor];
          const formattedValue = column.Cell ? column.Cell({ value: cellValue }) : cellValue;

          return <TableCell key={column.accessor}>{formattedValue}</TableCell>;
        })}
      </TableRow>
    ));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <MDBox p={8}>
      <TableContainer>
        {!loading &&
          stages.map((stage, index) => {
            const { columns, rows } = getTableData(stage);
            const stageAddress = getStageAddress(stage.address);
            return (
              <React.Fragment key={index}>
                <Table aria-label="simple table">
                  <TableHead
                    sx={{ display: "table-header-group", width: "100%", backgroundColor: "#666" }}
                  >
                    <TableRow>
                      <TableCell colSpan={columns.length} sx={{ color: "#fff" }}>
                        Stage: {stage?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={columns.length} sx={{ color: "#fff" }}>
                        Address: {getStageAddress(stage?.address)}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: "grey.300" }}>
                      {columns.map((column) => (
                        <TableCell key={column.Header} align="left" sx={{ width: column.width }}>
                          {column.Header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderRows(rows)}</TableBody>
                </Table>
              </React.Fragment>
            );
          })}
      </TableContainer>

      {showSummaryTable && (
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table aria-label="simple table">
            <TableHead sx={{ display: "table-header-group", width: "100%" }}>
              <TableRow>
                <TableCell colSpan={allColumns.length}>Summary by Product</TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "grey.300" }}>
                {allColumns.map((column) => (
                  <TableCell key={column.Header} align="left" sx={{ width: column.width }}>
                    {column.Header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderSummaryRows(groupedData)}</TableBody>
          </Table>
        </TableContainer>
      )}

      {onCloseButton && (
        <Table>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  variant="contained"
                  onClick={onCloseButton}
                  sx={{
                    background: "linear-gradient(195deg, #747b8a, #495361)",
                    color: "#ffffff",
                    mt: 3,
                    "&:hover": {
                      background: "linear-gradient(195deg, #747b8a, #495361)",
                      color: "#ffffff",
                    },
                  }}
                >
                  Close
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </MDBox>
  );
};

export default ProposalStageSummary;
