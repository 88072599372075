import { CircularProgress } from "@mui/material";
import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { AdditionalCost, SortDirection } from "generated/graphql";
import useGetAdditionalCosts from "hooks/organization/useGetAdditionalCosts";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources, { Pagination } from "hooks/usePaginatedResources";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

export default function AdditionalCostOptions({
  children,
}: {
  children: ({
    additionalCostsById,
    additionalCostOptions,
    loading,
    search,
    pagination,
  }: {
    additionalCostsById: Record<string, AdditionalCost | undefined>;
    additionalCostOptions: SelectOptions;
    loading: boolean;
    search: (v: string) => void;
    pagination: Pagination;
  }) => React.ReactNode;
}) {
  const { filtering, offset } = usePaginatedVariables();

  const { loading, data, error, paginatorInfo } = useGetAdditionalCosts(
    {
      first: offset.first,
      search: filtering.debouncedSearchTerm,
      sort: SortDirection.ASC,
      page: offset.page,
    },
    { fetchPolicy: "network-only" }
  );
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const additionalCosts = data ?? [];

  const additionalCostOptions = additionalCosts.map((additionalCost) => ({
    label: additionalCost.name,
    value: additionalCost.id,
  }));

  const additionalCostsById = additionalCosts.reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val,
    };
  }, {} as Record<string, AdditionalCost | undefined>);

  return (
    <>
      {children({
        additionalCostsById,
        additionalCostOptions,
        loading,
        search: filtering.setSearchTerm,
        pagination,
      })}
    </>
  );
}
