import { useGetQuickbooksTaxCodesLazyQuery } from "generated/graphql";
import { useCallback } from "react";

export default function useGetQuickbooksTaxCodesLazy() {
  const [getQuickbooksTaxCodesLazy, { data, loading, error }] = useGetQuickbooksTaxCodesLazyQuery();

  const getQuickbooksTaxCodes = useCallback(() => {
    getQuickbooksTaxCodesLazy();
  }, [getQuickbooksTaxCodesLazy]);

  const taxCodes = data?.getQuickbooksTaxCodes ?? [];

  return [getQuickbooksTaxCodes, { data, loading, error, taxCodes }] as const;
}
