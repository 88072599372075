import getLocalString from "constants/Localization";
import { useDeleteProductMutation } from "generated/graphql";

import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteProduct() {
  const [mutation, { loading, data, error }] = useDeleteProductMutation({
    refetchQueries: ["getProducts", "customGetProducts"],
  });

  const [deleteProduct, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteProduct",
    },
    {
      successMessage: getLocalString("products.delete-success"),
      failureMessage: getLocalString("products.delete-failure"),
    }
  );

  return [deleteProduct, context] as const;
}
