import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import {
  DeleteOrganizationAddressMutationVariables,
  DeleteOrganizationProductTypeMutation,
  useDeleteOrganizationProductTypeMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { useCallback, useMemo } from "react";

export default function useDeleteOrganizationProductType() {
  const withMutationAction = useMutationAction();
  const [mutation, { data: graphqlData, loading, error }] =
    useDeleteOrganizationProductTypeMutation({
      refetchQueries: ["getOrganizationProductTypes", "customGetOrganizationProductTypes"],
    });

  const getData = useCallback(({ data }: { data: DeleteOrganizationProductTypeMutation }) => {
    return (data && data.deleteOrganizationProductType) || null;
  }, []);

  const isSuccess = useCallback((data: DeleteOrganizationProductTypeMutation) => {
    return (data && !!data.deleteOrganizationProductType) || null;
  }, []);

  const deleteOrganizationProductType = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<
          DeleteOrganizationProductTypeMutation,
          DeleteOrganizationAddressMutationVariables
        >
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("product-types.delete-success"),
        failureMessage: getLocalString("product-types.delete-failure"),
      }
    ),
    [mutation]
  );

  const data = useMemo(() => {
    return (graphqlData && graphqlData.deleteOrganizationProductType) || null;
  }, []);

  return [deleteOrganizationProductType, { loading, data, error, getData, isSuccess }] as const;
}
