import getLocalString from "constants/Localization";
import { OrganizationLinkedServiceName, useUnlinkServiceMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import useGetLinkedServiceAuthUrl from "./useGetLinkedServiceAuthUrl";

const useUnlinkService = () => {
  const [getLinkedServiceAuthUrl] = useGetLinkedServiceAuthUrl();

  const [mutation, { loading, data, error }] = useUnlinkServiceMutation({
    refetchQueries: ["getUserOrganization"],
    onError: (e) => {
      if (e.message === "auth_needed") {
        getLinkedServiceAuthUrl({
          variables: {
            service: OrganizationLinkedServiceName.QUICKBOOKS,
          },
        });
      }
    },
  });

  const [unlinkService, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "unlinkService",
    },
    {
      successMessage: getLocalString("organization.unlink-service-success"),
      failureMessage: getLocalString("organization.unlink-service-failure"),
    }
  );
  return [unlinkService, context] as const;
};

export default useUnlinkService;
