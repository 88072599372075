import {
  GetOpportunitiesQuery,
  GetOpportunitiesQueryVariables,
  useGetOpportunitiesQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export const cacheKey = "GetOpportunities";

export default function useGetOpportunities(variables: GetOpportunitiesQueryVariables) {
  const {
    data: graphqlData,
    loading,
    error,
    fetchMore,
    refetch,
  } = useGetOpportunitiesQuery({
    variables,
    fetchPolicy: "no-cache",
  });

  const getData = useCallback(({ data }: { data?: GetOpportunitiesQuery }) => {
    return data?.getOpportunities?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: GetOpportunitiesQuery }) => {
    return data?.getOpportunities?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return {
    data,
    loading,
    error,
    paginatorInfo,
    fetchMore,
    getData,
    getPaginatorInfo,
    refetch,
  } as const;
}
