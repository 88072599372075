import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import getLocalString from "constants/Localization";
import { Plant } from "generated/graphql";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import useDeletePlant from "hooks/organization/plants/useDeletePlant";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useBitumioContext } from "providers/BitumioContext";
import { useCallback } from "react";

export default function Delete({ closeMenu, id }: { closeMenu: () => void; id: Plant["id"] }) {
  const [deletePlant, { loading, error, isSuccess, getData }] = useDeletePlant();

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const result = await deletePlant({ variables: { id } });
    const success = isSuccess(result.data);
    return success;
  }, []);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you would like to delete this plant?"
      />
      <MenuItem onClick={() => setOpen(true)}>Delete</MenuItem>
    </>
  );
}
