import getLocalString from "constants/Localization";
import {
  GetOrganizationProductTypesDocument,
  useCreateOrganizationProductTypeMutation,
} from "generated/graphql";

import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreateOrganizationProductType() {
  const [mutation, { loading, data, error }] = useCreateOrganizationProductTypeMutation({
    refetchQueries: [
      {
        query: GetOrganizationProductTypesDocument,
        variables: {},
      },
    ],
  });

  const [createOrganizationProductType, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createOrganizationProductType",
    },
    {
      successMessage: getLocalString("product-types.create-success"),
      failureMessage: getLocalString("product-types.create-failure"),
    }
  );

  return [createOrganizationProductType, context] as const;
}
