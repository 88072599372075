import { RolesWithPermissions, UserPermissions } from "generated/graphql";
import * as z from "zod";

export const schema = z.object({
  admin: z.array(z.nativeEnum(UserPermissions)).optional(),
  officeManager: z.array(z.nativeEnum(UserPermissions)).optional(),
  salesperson: z.array(z.nativeEnum(UserPermissions)).optional(),
  foreman: z.array(z.nativeEnum(UserPermissions)).optional(),
  employee: z.array(z.nativeEnum(UserPermissions)).optional(),
  subcontractor: z.array(z.nativeEnum(UserPermissions)).optional(),
});

export type UpdateRolesWithPermissionsInputSchema = z.infer<typeof schema>;

export function cleanRolesWithPermissionsUpdateInput(
  values: UpdateRolesWithPermissionsInputSchema
) {
  const cleanParams = {
    officeManager: values.officeManager,
    salesperson: values.salesperson,
    foreman: values.foreman,
    employee: values.employee,
    subcontractor: values.subcontractor,
  };

  return cleanParams;
}

export const getDefaultValues = ({
  rolesWithPermissions,
}: {
  rolesWithPermissions: RolesWithPermissions;
}) => {
  return {
    admin: rolesWithPermissions?.admin?.map((x) => x.toString()) ?? [],
    salesperson: rolesWithPermissions?.salesperson ?? [],
    officeManager: rolesWithPermissions?.officeManager ?? [],
    foreman: rolesWithPermissions?.foreman ?? [],
    employee: rolesWithPermissions?.employee ?? [],
    subcontractor: rolesWithPermissions?.subcontractor ?? [],
  };
};
