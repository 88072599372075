import getLocalString from "constants/Localization";
import { useCreateProjectFileFolderMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useCreateProjectFileFolder = () => {
  const [mutation, { loading, error, data }] = useCreateProjectFileFolderMutation({
    refetchQueries: ["getProjectFiles"],
  });

  const [createProjectFileFolder, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createProjectFileFolder",
    },
    {
      successMessage: getLocalString("organization.create-project-file-folder-success"),
      failureMessage: getLocalString("organization.create-project-file-folder-failure"),
      showValidationErrors: true,
    }
  );

  return [createProjectFileFolder, context] as const;
};
