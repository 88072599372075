import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import { SelectOptions } from "../CustomSelect/CustomSelect";
import { Pagination } from "hooks/usePaginatedResources";
import { AdditionalCost, Crew, EquipmentPiece } from "generated/graphql";

const MultiSelectExtension = ({
  tags,
  setTags,
  options,
  onChange,
  loading,
  pagination,
  search,
  id,
  label,
  nameCheckFn,
}: {
  tags: string[] | number[];
  setTags: React.Dispatch<React.SetStateAction<any[]>>;
  options: SelectOptions;
  onChange: (value: any) => void;
  loading: boolean;
  pagination: Pagination;
  search: (v: string) => void;
  id: string | number;
  label: string;
  nameCheckFn: Record<string | number, EquipmentPiece | AdditionalCost | Crew>;
}) => {
  const handleTagSelect = (tagId: string | number): void => {
    if (!tags.includes(tagId as never)) {
      setTags([...tags, tagId]);
    }
  };

  const handleRemoveTag = (tagId: string | number) => {
    const tagIndex = tags.indexOf(tagId as never);
    if (tagIndex === -1) return;
    const tagsCopy = [...tags];
    tagsCopy.splice(tagIndex, 1);
    setTags(tagsCopy);
  };

  return (
    <>
      {tags.map((tag) => (
        <MDBox
          key={tag}
          pl={1}
          m={1}
          mb={3}
          borderRadius="10px"
          sx={{ background: "#eee", display: "inline-block" }}
        >
          <MDTypography variant="body2" sx={{ fontsize: 10, display: "inline" }}>
            {nameCheckFn?.[tag]?.name}
          </MDTypography>
          <IconButton
            onClick={() => {
              handleRemoveTag(tag);
            }}
          >
            <Icon fontSize="small" color="error">
              delete
            </Icon>
          </IconButton>
        </MDBox>
      ))}
      <ScrollAsyncAutocomplete
        options={options}
        onChange={(value) => {
          handleTagSelect(value);
          onChange(value);
        }}
        value={options.find((x) => x.value === id)}
        // onBlur={() => {}}
        // name="equipmentPieceId"
        // ref={null}
        loading={loading}
        // error={null}
        label={label}
        onLoadMore={pagination.loadMore}
        hasMore={pagination.hasNextPage}
        search={search}
      />
    </>
  );
};

export default MultiSelectExtension;
