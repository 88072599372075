import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useOpportunitiesTable from "hooks/opportunities/useOpportunitiesTable";

export default function OpportunitiesList() {
  // Get the companies list
  const { loading, error, tableData, pagination, filtering, sorting, searchControl } =
    useOpportunitiesTable();

  return (
    <>
      <MDBox mb={3}>{searchControl}</MDBox>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && (
        <DataTable
          table={tableData}
          pagination={pagination}
          filtering={filtering}
          sorting={sorting}
          isSorted={true}
          displayScrollTop={true}
        />
      )}
    </>
  );
}
