import { useIsCompanySearchableLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useIsCompanySearchable = () => {
  const [query, { loading, data: graphQLData, error }] = useIsCompanySearchableLazyQuery();

  const isCompanySearchable = useCallback(async (id: string) => {
    return await query({ variables: { id } });
  }, []);

  const data = useMemo(() => {
    return graphQLData?.isCompanySearchable || null;
  }, [graphQLData]);

  return [isCompanySearchable, { loading, data, error }] as const;
};
