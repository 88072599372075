import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import MDTypography from "components/MDTypography";
import { DeleteOpenProposalAction, User } from "generated/graphql";
import { GlobalMessage } from "hooks/notifications/useGlobalMessage";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import useDeleteOrganizationUser from "hooks/users/useDeleteOrganizationUser";
import useGetOrganizationUser from "hooks/users/useGetOrganizationUser";
import PickActiveOrganizationUser from "modules/PickActiveOrganizationUser/PickActiveOrganizationUser";
import { useCallback, useEffect, useState } from "react";

export default function DeleteAccountMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: User["id"];
}) {
  // As soon as this module loads we need to figure out if this user has any proposal count
  const [getOrganizationUser, { user, loading, error }] = useGetOrganizationUser();

  useEffect(() => {
    getOrganizationUser({ id });
  }, []);

  const [deleteOrganizationUser, { isSuccess }] = useDeleteOrganizationUser();
  const [openProposalAction, setOpenProposalAction] = useState<DeleteOpenProposalAction>(
    DeleteOpenProposalAction.CANCEL
  );

  type ReassignType = { id?: User["id"]; label?: string };
  const [reassignUserId, setReassignUserId] = useState<ReassignType>({});
  const [reassignJobUser, setReassignJobUser] = useState<ReassignType>({});
  const [reassignJobPhaseForemanUser, setReassignJobPhaseForemanUser] = useState<ReassignType>({});

  const acceptFn = useCallback(async (): Promise<GlobalMessage> => {
    const { data } = await deleteOrganizationUser({
      id,
      openProposalAction,
      ...(reassignUserId.id && { reassignUserId: reassignUserId.id }),
      ...(reassignJobUser.id && { reassignJobUserId: reassignJobUser.id }),
      ...(reassignJobPhaseForemanUser.id && {
        reassignJobPhaseForemanUserId: reassignJobPhaseForemanUser.id,
      }),
    });
    if (isSuccess(data)) {
      closeMenu();
      return { message: "Successfully deleted the user" };
    } else {
      return {
        message: "Failed to delete the user. Please try again in a few minutes.",
        options: { variant: "error" },
      };
    }
  }, [
    deleteOrganizationUser,
    id,
    openProposalAction,
    reassignJobUser,
    reassignJobPhaseForemanUser,
  ]);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  const reassignUserMissing = reassignUserId === null;
  const wantsToReassign = openProposalAction === DeleteOpenProposalAction.REASSIGN;
  const requiresProposalAction = user && user.proposalCount > 0;
  const requiresJobAction = user?.jobsCount > 0;
  const requiresJobPhaseAction = user?.jobPhasesCount > 0;
  const missingProposalAction = openProposalAction === null;
  const isAcceptDisabled =
    loading ||
    (requiresProposalAction && missingProposalAction) ||
    (requiresProposalAction && wantsToReassign && reassignUserMissing) ||
    (requiresJobAction && !reassignJobUser.id) ||
    (requiresJobPhaseAction && !reassignJobPhaseForemanUser.id);
  const isCancelDisabled = false;

  const requiresProposalOrJobAction =
    requiresProposalAction || requiresJobAction || requiresJobPhaseAction;

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        isAcceptDisabled={isAcceptDisabled}
        isCancelDisabled={isCancelDisabled}
        content={
          requiresProposalOrJobAction ? (
            <>
              {requiresProposalAction && (
                <>
                  <MDTypography mb={4}>
                    This user has {user?.proposalCount} proposals assigned to them. Please specify
                    what to do with them:
                  </MDTypography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="open-proposals-actions"
                      name="open-proposals-action"
                      onChange={(e, value: DeleteOpenProposalAction) =>
                        setOpenProposalAction(value)
                      }
                    >
                      <FormControlLabel
                        value={DeleteOpenProposalAction.UNASSIGN}
                        control={<Radio />}
                        label="Unassign"
                      />
                      <FormControlLabel
                        value={DeleteOpenProposalAction.REASSIGN}
                        control={<Radio />}
                        label="Reassign"
                      />
                      <FormControlLabel
                        value={DeleteOpenProposalAction.CANCEL}
                        control={<Radio />}
                        label="Cancel"
                      />
                    </RadioGroup>
                  </FormControl>
                  {wantsToReassign && (
                    <PickActiveOrganizationUser
                      onChange={(e, value) => setReassignUserId(value)}
                      value={reassignUserId}
                    />
                  )}
                </>
              )}
              {requiresJobAction && (
                <>
                  <MDTypography mt={5}>
                    This user has {user?.jobsCount} job(s) assigned to them. Please specify what to
                    do with them:
                  </MDTypography>
                  <PickActiveOrganizationUser
                    onChange={(e, value) => setReassignJobUser(value)}
                    value={reassignJobUser}
                    label="Select a user to reassign the jobs to"
                  />
                </>
              )}
              {requiresJobPhaseAction && (
                <>
                  <MDTypography mt={5}>
                    This user has {user?.jobPhasesCount} job phase(s) assigned to them. Please
                    specify what to do with them:
                  </MDTypography>
                  <PickActiveOrganizationUser
                    onChange={(e, value) => setReassignJobPhaseForemanUser(value)}
                    value={reassignJobPhaseForemanUser}
                    label="Select a user to reassign the job phases to"
                  />
                </>
              )}
            </>
          ) : (
            <>Are you sure you want to delete this user?</>
          )
        }
      />
      <MenuItem onClick={() => setOpen(true)}>Delete Account</MenuItem>
    </>
  );
}
