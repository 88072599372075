import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Plant } from "generated/graphql";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Update from "modules/OrganizationPlants/Update";
import Footer from "partials/Footer/Footer";
import { useParams } from "react-router";
import useGetPlant from "hooks/organization/plants/useGetPlant";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";

export default function PlantEditPage() {
  const { plantId } = useParams();
  const { loading, error, data: plant } = useGetPlant({ variables: { id: plantId } });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load plant details. Please refresh this page or try again later.
        </MDAlert>
      )}
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Update Plant
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {plant && <Update plant={plant as Plant} />}
            {!plant && <Skeleton />}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
