import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import useGetQuickbooksIncomeAccounts from "hooks/organization/linkedServices/useGetQuickbooksIncomeAccounts";
import useSaveQuickbooksIncomeAccount from "hooks/organization/linkedServices/useSaveQuickbooksIncomeAccount";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import { useForm } from "react-hook-form";

const QuickbooksSettings = () => {
  const { incomeAccounts, loading, error } = useGetQuickbooksIncomeAccounts();
  const [saveQuickbooksIncomeAccount] = useSaveQuickbooksIncomeAccount();

  const { control, handleSubmit, register } = useForm();

  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h4" fontWeight="medium">
                Quickbooks settings
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {!loading && !error && (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={8}>
              <MDBox
                component="form"
                role="form"
                p={3}
                onSubmit={handleSubmit((values: { id: string }) =>
                  saveQuickbooksIncomeAccount({ variables: values })
                )}
              >
                <MDTypography>Select account</MDTypography>
                <MDTypography variant="body2" mt={3}>
                  This is the account where we will assign any items we create
                </MDTypography>
                <CustomSelect
                  name="id"
                  data={incomeAccounts}
                  label="Select account"
                  control={control}
                  fullWidth
                  {...register("id", { required: true })}
                />
                <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                  Save
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardPage>
  );
};

export default QuickbooksSettings;
