import getLocalString from "constants/Localization";
import { useSendOrganizationEmailTemplatePreviewMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useSendOrganizationEmailTemplatePreview() {
  const [mutation, { loading, error, data }] = useSendOrganizationEmailTemplatePreviewMutation();
  const [sendOrganizationEmailTemplatePreview, context] = useBitumioMutation(
    {
      mutation,
      data,
      loading,
      error,
      key: "sendOrganizationEmailTemplatePreview",
    },
    {
      successMessage: getLocalString("organization.update-email-template-preview-success"),
      failureMessage: getLocalString("organization.update-email-template-preview-failed"),
    }
  );
  return [sendOrganizationEmailTemplatePreview, context] as const;
}
