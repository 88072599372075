import getLocalString from "constants/Localization";
import { useRemoveTruckFromProposalProductMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

const useRemoveTruckFromProposalProduct = () => {
  const [mutation, { loading, data, error }] = useRemoveTruckFromProposalProductMutation({
    refetchQueries: ["customGetProposal"],
  });

  const [removeTruckFromProposalProduct, context] = useBitumioMutation(
    {
      mutation,
      data,
      error,
      loading,
      key: "removeTruckFromProposalProduct",
    },
    {
      successMessage: getLocalString("proposals.remove-truck-from-proposal-product-success"),
      failureMessage: getLocalString("proposals.remove-truck-from-proposal-product-failure"),
    }
  );

  return [removeTruckFromProposalProduct, context] as const;
};

export default useRemoveTruckFromProposalProduct;
