import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AddOrganizationDataUploadInput, CreateNotificationTriggerInput } from "generated/graphql";
import useCreateNotificationTrigger from "hooks/notifications/useCreateNotificationTrigger";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Import from "modules/OrganizationDataUploads/Import";

import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import useAddOrganizationDataUpload from "../../../hooks/organization/dataUploads/useAddOrganizationDataUpload";

export default function DataUploadsCreate() {
  const [addOrganizationDataUpload, { loading, getData, error }] = useAddOrganizationDataUpload();
  const [fileError, setFileError] = useState<boolean>(false);

  const navigate = useNavigate();
  const submitFunction = useCallback(
    async (input: AddOrganizationDataUploadInput) => {
      const result = await addOrganizationDataUpload({
        variables: {
          input: {
            ...input,
          },
        },
      });

      // const data = getData(result)
      //
      // console.log(data)
      if (!result.success) {
        if (JSON.stringify(result).indexOf("Invalid columns") > -1) {
          setFileError(true);
        }
      }

      // if (result.success) {
      //   navigate("/data-uploads");
      // }
    },
    [addOrganizationDataUpload]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Create Import
            </MDTypography>
          </MDBox>

          <MDBox mb={{ xs: 3, sm: 0 }} p={3}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Select your import type from the dropdown below, and you will be provided with a
              template to download & fill in.
            </MDTypography>
          </MDBox>

          <Import onSubmit={submitFunction} loading={loading} fileError={fileError} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
