import getLocalString from "constants/Localization";
import { useUpdatePlantMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdatePlant() {
  const [mutation, { data, loading, error }] = useUpdatePlantMutation({
    refetchQueries: ["getPlants", "customGetPlants"],
  });

  const [updatePlant, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updatePlant",
    },
    {
      successMessage: getLocalString("organization.update-plant-success"),
      failureMessage: getLocalString("organization.update-plant-failure"),
      showValidationErrors: true,
    }
  );

  return [updatePlant, context] as const;
}
