import getLocalString from "constants/Localization";
import { useCreateOpportunityStatusMutation } from "generated/graphql";

import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreateOpportunityStatus() {
  const [mutation, { loading, data, error }] = useCreateOpportunityStatusMutation({
    refetchQueries: ["getOpportunityStatuses"],
  });

  const [createOpportunityStatus, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "createOpportunityStatus",
    },
    {
      successMessage: getLocalString("opportunities.create-status-success"),
      failureMessage: getLocalString("opportunities.create-status-failure"),
    }
  );

  return [createOpportunityStatus, context] as const;
}
