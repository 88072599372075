import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useGetOrganizationProductTypesTable from "hooks/organization/product-types/useGetOrganizationProductTypesTable";

export default function OrganizationProductTypesList() {
  const { loading, error, tableData } = useGetOrganizationProductTypesTable();

  return (
    <>
      {loading && <TableSkeleton numberOfLines={10} />}
      {!loading && !error && <DataTable table={tableData} />}
    </>
  );
}
