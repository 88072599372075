import { FormControl, MenuItem, Select } from "@mui/material";
import { Job } from "generated/graphql";
import useUpdateJobProjectManager from "hooks/jobs/useUpdateJobProjectManager";
import useGetOrganizationUsers from "hooks/users/useGetOrganizationUsers";

export default function ProjectManagerSelection({
  projectManager,
  jobId,
}: {
  projectManager: Job["userId"];
  jobId: Job["id"];
}) {
  const { data } = useGetOrganizationUsers({ page: 1, first: 1000 });
  const [updateJobProjectManager, { loading, isSuccess, getData }] = useUpdateJobProjectManager();

  const usersSelectData = data?.map(({ firstName, lastName, id }) => ({
    label: `${firstName} ${lastName}`,
    value: id,
  }));

  const handleSubmit = (userId: Job["userId"]) => {
    updateJobProjectManager({ variables: { id: jobId, userId } });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "10vw" }}>Project Manager </div>
        <FormControl
          sx={{
            "&": {
              pb: "10px",
              minWidth: "7vw",
            },
          }}
          variant="filled"
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={projectManager}
            variant="standard"
            label=""
            disabled={loading}
            onChange={(e) => {
              handleSubmit(e.target.value);
            }}
            sx={{
              "& > #mui-component-select-role": {
                padding: "0 0 5px !important",
              },
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {usersSelectData?.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}
