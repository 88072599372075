import { GetEmployeeQueryVariables, useGetEmployeeLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetEmployeeLazy() {
  const [query, { data, loading, error }] = useGetEmployeeLazyQuery();

  const getEmployee = useCallback(
    (values: GetEmployeeQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const employee = useMemo(() => {
    return (data && data.getEmployee) || null;
  }, [data]);

  return [getEmployee, { data, loading, error, employee }] as const;
}
