import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import Card from "@mui/material/Card";

// Data
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";
import OrganizationUsersList from "modules/OrganizationUsersList/OrganizationUsersList";
import { useBitumioContext } from "providers/BitumioContext";
import { UserRole } from "generated/graphql";

export default function UsersPage() {
  const [{ signedInUser }] = useBitumioContext();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Users
            </MDTypography>
            <MDBox display="flex" gap={2}>
              {signedInUser && signedInUser?.roles?.some((x) => x === UserRole.ADMIN) && (
                <MDTypography as={Link} to={getRoute("organization-settings.userPermissions")}>
                  <MDButton color="blue">Manage Permissions</MDButton>
                </MDTypography>
              )}
              <MDTypography as={Link} to={getRoute("users.invite")}>
                <MDButton color="blue">
                  <Icon>add</Icon>&nbsp; Invite User
                </MDButton>
              </MDTypography>
            </MDBox>
          </MDBox>
          <OrganizationUsersList />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
