import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { z } from "zod";

export const schema = z.object({
  id: z.string().min(1, "Job Phase is Required"),
  notes: z.string(),
});

export type UpdateJobPhaseNotesInputSchema = z.infer<typeof schema>;

export const getDefaultValues = ({ jobPhase }: { jobPhase: CustomJobPhase }) => {
  return {
    id: jobPhase.id,
    notes: jobPhase.notes,
  };
};
