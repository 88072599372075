import React, { useCallback, useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Accordion, AccordionDetails, AccordionSummary, Icon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Proposal, ProposalStage } from "generated/graphql";
import Stage from "./Stage";
import AddStage from "./AddStage";
import { ProposalContext } from "./providers/ProposalContextProvider";
import PendingStage from "./PendingStage";
import MDButton from "components/MDButton";
import useDeleteProposalStage from "hooks/proposals/useDeleteProposalStage";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import useUpdateProposalStagesOrdering from "hooks/proposals/useUpdateProposalStagesOrdering";
import ReorderProposalStages from "./ReorderProposalStages";
import ProposalStageEditNameForm from "./ProposalStageEditNameForm";

export default function ProposalStages() {
  const { sendRequest, loading } = useUpdateProposalStagesOrdering();
  const { proposal, inViewMode } = useContext(ProposalContext);
  const { stages } = proposal;
  const [deleteProposalStage, { data, isSuccess }] = useDeleteProposalStage();
  // Find a stage missing name
  const pendingStage = stages.find((stage) => !stage.name);
  const [expandedStage, setExpandedStage] = React.useState<string | false>(
    pendingStage ? pendingStage.id : stages?.[0]?.id ?? false
  );
  const [editable, setEditable] = useState<{ [x: string]: boolean }>({});

  const [sortedStages, setSortedStages] = useState<ProposalStage[]>(stages);
  useEffect(() => {
    setSortedStages(stages);
  }, [stages]);
  const [sortMode, setSortMode] = useState<boolean>(false);

  const changeStage = useCallback(
    (stageId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedStage(isExpanded ? stageId : false);
    },
    [setExpandedStage]
  );
  const handleDelete = useCallback(
    async (id: ProposalStage["id"]) => {
      const result = await deleteProposalStage({
        variables: {
          id,
        },
      });
      const success = isSuccess(result.data);
      return { success, data };
    },
    [deleteProposalStage]
  );

  const handleOnDragEnd = (result) => {
    const items = Array.from(sortedStages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSortedStages(items);
    sendRequest({
      id: proposal.id,
      stages: {
        update: items.map((item, index) => ({ id: item.id, ordering: index + 1 })),
      },
    });
    setSortMode(false);
  };

  return (
    <MDBox className="proposal-stages">
      <MDBox
        sx={{ background: !!sortMode ? "#fcfced" : "unset", padding: !!sortMode ? "15px" : 0 }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortedStages.map((stage, index) => (
                  <Draggable
                    key={stage.id}
                    draggableId={stage.id}
                    index={index}
                    isDragDisabled={!sortMode}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {stage.name ? (
                          <Accordion
                            key={stage.id}
                            expanded={expandedStage === stage.id}
                            elevation={1}
                            onChange={changeStage(stage.id)}
                            TransitionProps={{ unmountOnExit: true }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`${stage.id}-content`}
                              id={`${stage.id}-header`}
                              sx={{ backgroundColor: "rgba(75, 75, 125, 0.3)" }}
                            >
                              <MDBox
                                display="flex"
                                alignItems="center"
                                sx={{
                                  flexDirection: {
                                    xs: "column",
                                    md: "row",
                                  },
                                }}
                                justifyContent="space-between"
                                width="100%"
                              >
                                <MDBox
                                  display="flex"
                                  alignItems="center"
                                  gap="5px"
                                  sx={{
                                    flexDirection: {
                                      xs: "column",
                                      md: "row",
                                    },
                                  }}
                                >
                                  {!!sortMode && (
                                    <Icon
                                      fontSize="large"
                                      sx={{ height: "34px", marginRight: "15px" }}
                                    >
                                      sort
                                    </Icon>
                                  )}
                                  <MDTypography variant="h5">
                                    Stage Name:&nbsp;&nbsp;&nbsp;&nbsp;
                                  </MDTypography>
                                  {editable[index] ? (
                                    <>
                                      <ProposalStageEditNameForm
                                        stage={stage}
                                        setEditable={(editable) =>
                                          setEditable((prevState) => ({
                                            ...prevState,
                                            [index]: editable,
                                          }))
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <MDTypography variant="body">
                                        {stage.name ? stage.name : stage.id}
                                      </MDTypography>
                                      <MDButton
                                        variant="outlined"
                                        size="small"
                                        color="white"
                                        iconOnly
                                        type="button"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setEditable((prevState) => ({
                                            ...prevState,
                                            [index]: true,
                                          }));
                                        }}
                                      >
                                        <Icon>edit</Icon>
                                      </MDButton>
                                    </>
                                  )}
                                </MDBox>
                                <MDTypography variant="body">
                                  {formatCentsToUSD(stage.overallTotal ?? 0)}
                                </MDTypography>
                              </MDBox>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stage
                                stage={stage as ProposalStage}
                                expandedStage={expandedStage}
                                onDeleteStage={stages.length > 1 ? handleDelete : null}
                              />
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <Accordion
                            key={stage.id}
                            expanded={expandedStage === stage.id}
                            elevation={1}
                            onChange={changeStage(stage.id)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`${stage.id}-content`}
                              id={`${stage.id}-header`}
                              sx={{ backgroundColor: "rgba(75, 75, 125, 0.3)" }}
                            >
                              <MDBox display="flex" alignItems="center">
                                {!!sortMode && (
                                  <Icon fontSize="large" sx={{ height: "34px" }}>
                                    sort
                                  </Icon>
                                )}
                                <MDTypography variant="h5">New Stage</MDTypography>
                              </MDBox>
                            </AccordionSummary>
                            <AccordionDetails>
                              <PendingStage
                                key={stage.id}
                                stage={stage as ProposalStage}
                                onDeleteStage={stages.length > 1 ? handleDelete : null}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </MDBox>
      {!pendingStage && !inViewMode && (
        <MDBox my={3} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <AddStage
            proposal={proposal as Proposal}
            disabled={sortMode || loading}
            onSuccess={(addedStage: ProposalStage) => {
              setExpandedStage(addedStage.id);
            }}
          />
          {sortedStages.length > 1 && (
            <ReorderProposalStages setSortMode={setSortMode} disabled={sortMode || loading} />
          )}
        </MDBox>
      )}
    </MDBox>
  );
}
