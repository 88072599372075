import * as z from "zod";

export const schema = z.object({
  firstName: z.string().min(1, "First Name is required."),
  lastName: z.string().min(1, "Last Name is required."),
  companyName: z.string().min(3, "Company Name is required."),
  phone: z.string().min(9, "Phone number is required."),
  email: z.string().email(),
  agree: z
    .boolean()
    .refine((value) => value === true, "Check to agree to the Terms and Conditions"),
  token: z.string().min(1, "Please complete the captcha"),
});

export const defaultValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  email: "",
  token: "",
  agree: false,
};
