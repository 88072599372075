import { Card, Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import { useTimeEntriesData } from "hooks/timeTracking/useTimeEntriesData";

export default function TimeEntriesTable() {
  const {
    loading,
    error,
    tableData,
    pagination,
    filtering,
    sorting,
    JobPhaseFilter,
    UserFilter,
    DateFilter,
  } = useTimeEntriesData();

  return (
    <Card>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4}>
          <JobPhaseFilter />
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <UserFilter />
        </Grid>
        <Grid item xs={12} sm={4.5}>
          <DateFilter />
        </Grid>
      </Grid>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      <MDBox p={3}>
        {!loading && !error && (
          <DataTable
            table={tableData}
            showTotalEntries={false}
            isSorted={true}
            noEndBorder
            pagination={pagination}
            filtering={filtering}
            sorting={sorting}
          />
        )}
      </MDBox>
    </Card>
  );
}
