import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  styled,
  tableCellClasses,
  tooltipClasses,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import { JobPhase, JobPhaseScheduleBlock } from "generated/graphql";
import { Fragment } from "react";
import HelpIcon from "@mui/icons-material/Help";
import Title from "./Title";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useFieldArray, useFormContext } from "react-hook-form";
import { UpdateWorkOrderInputSchema } from "DDD/action-objects/WorkOrderItemsUpdate";
import FormField from "components/FormField/FormField";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const StyledTHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#CCC",
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
}));
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});
const JobPhaseScheduleSection = ({ data }: { data: JobPhase }) => {
  const { scheduleBlocks } = data;
  const { control, register } = useFormContext<UpdateWorkOrderInputSchema>();

  const { fields: scheduleFields } = useFieldArray({
    control,
    name: "schedules",
  });

  return (
    <Grid item xs={12} className="no-break">
      <Title text="Job Phase Schedule" />
      <Table>
        <TableBody>
          <TableRow></TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Start Time</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Hours</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              <MDBox display="flex" gap={2} alignItems="center" color="#000">
                Estimated Material
                <CustomWidthTooltip
                  title={
                    <>
                      <p>
                        Estimated Material is calculated based on the number of hours scheduled to a
                        specific day compared to the overall job hours.
                      </p>
                    </>
                  }
                  placement="top"
                >
                  <HelpIcon />
                </CustomWidthTooltip>
              </MDBox>
            </TableCell>
          </TableRow>
          {scheduleFields?.map((field, i) => {
            const block = scheduleBlocks[i];
            console.log("block", block);
            return (
              <TableRow key={i}>
                <TableCell>
                  {dayjs.utc(block?.scheduledAt).local().format("MMMM D, YYYY")}
                </TableCell>
                <TableCell>{dayjs.utc(block?.scheduledAt).local().format("h:mmA")}</TableCell>
                <TableCell>{block?.blockLength}</TableCell>
                <TableCell
                  sx={{
                    display: "none",
                    "@media print": {
                      display: "table-cell",
                    },
                  }}
                >
                  {(block?.estimatedMaterialOverride || block?.estimatedMaterial)?.toFixed(1)}
                </TableCell>
                <TableCell className="no-print">
                  <FormField
                    {...register(`schedules.${i}.estimatedMaterialOverride`, {
                      valueAsNumber: true,
                    })}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default JobPhaseScheduleSection;
