import MDAlert from "components/MDAlert";
import UserForm from "components/users/UserForm/UserForm";
import getLocalString from "constants/Localization";
import { UpdateOrganizationUserInput, User } from "generated/graphql";
import useUpdateOrganizationUser from "hooks/users/useUpdateOrganizationUser";
import { useCallback } from "react";

export default function UpdateUser({ user }: { user: User }) {
  // We have the user details, let's prepare the functions to update data
  const [updateOrganizationUser, { loading, error, data: updatedUser, isSuccess, getData }] =
    useUpdateOrganizationUser();

  const handleSubmit = useCallback(async (input: UpdateOrganizationUserInput) => {
    const result = await updateOrganizationUser({
      variables: { input },
    });
    const success = isSuccess(result.data);
    const data = getData(result);
    return { success, data };
  }, []);

  return (
    <>
      {error && <MDAlert color="error">{getLocalString("users.update-error")}</MDAlert>}
      <UserForm user={(updatedUser as User) ?? user} loading={loading} onSubmit={handleSubmit} />
    </>
  );
}
