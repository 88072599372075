import { LoginInput, useLoginMutation } from "generated/graphql";
import { useCallback } from "react";

export default function useLogin() {
  const [loginMutation, { loading, error, data }] = useLoginMutation({
    refetchQueries: ["me", "customMe"],
  });

  const login = useCallback(
    (values: LoginInput) => {
      loginMutation({
        variables: {
          input: values,
        },
      });
    },
    [loginMutation]
  );

  const success = data && typeof data?.login?.id !== "undefined";
  const user = data && success && data.login;

  return [login, { data, loading, error, success, user }] as const;
}
