import { zodResolver } from "@hookform/resolvers/zod";
import { CreateOrganizationProductTypeInput, ProductType } from "generated/graphql";
import { useForm } from "react-hook-form";
import { schema, defaultValues } from "DDD/action-objects/OrganizationProductType";
import { Card, FormHelperText, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";

export default function OrganizationProductTypesCreateForm({ onSubmit, loading }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm<CreateOrganizationProductTypeInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (values) => {
          const result = await onSubmit(values);
          if (result.success) {
            reset();
          }
        })}
      >
        <MDTypography variant="h5">Product Type Information</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Name ( Your Organization's preferred Name for this type of Product \(i.e. Crackfill vs Crackseal) )"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelect
                label="Type"
                control={control}
                fullWidth
                selectVariant="standard"
                name="type"
                data={enumToValueOptions(ProductType)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="number"
                label="Indirect Percentage"
                placeholder="0"
                error={errors.indirectPercentage}
                {...register("indirectPercentage", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Markup Percentage"
                placeholder="0"
                error={errors.markupPercentage}
                {...register("markupPercentage", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                type="text"
                label="Overhead Percentage"
                placeholder="0"
                error={errors.overheadPercentage}
                {...register("overheadPercentage", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
