import getLocalString from "constants/Localization";
import { useUpdateProposalSalesTaxesMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useUpdateProposalSalesTaxes() {
  const [mutation, { loading, data, error }] = useUpdateProposalSalesTaxesMutation({
    refetchQueries: ["customGetProposal"],
  });
  const [updateProposalSalesTaxes, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateProposalSalesTaxes",
    },
    {
      successMessage: getLocalString("proposals.update-proposal-sales-taxes-success"),
      failureMessage: getLocalString("proposals.update-proposal-sales-taxes-failure"),
    }
  );

  return [updateProposalSalesTaxes, context] as const;
}
