import { useMemo } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Pricing page components
import PageLayout from "layouts/PageLayout/PageLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

import Logo from "assets/images/logo.png";
import Bg1 from "/assets/images/bg5.jpg";

import PricingCards from "components/PricingCards/PricingCards";
import OrganizationTotalUsersCard from "modules/OrganizationTotallUsersCard/OrganizationTotalUsersCard";
import TrialEndsSubscriptionRenewsCard from "modules/TrialEndsSubscriptionRenewsCard/TrialEndsSubscriptionRenewsCard";
import useGetOrganizationUsers from "hooks/users/useGetOrganizationUsers";
import Footer from "partials/Footer/Footer";
import { Link } from "react-router-dom";
import useGetOrganization from "hooks/organization/useGetOrganization";

function SubscriptionPage(): JSX.Element {
  const { paginatorInfo } = useGetOrganizationUsers({
    first: 1,
  });
  const { data } = useGetOrganization();
  const totalPrice = "149";

  return (
    <PageLayout>
      <MDBox
        position="relative"
        minHeight="50vh"
        height="50vh"
        borderRadius="xl"
        mx={2}
        my={1}
        pt={2}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { black } }) =>
            `${linearGradient(rgba(black.main, 0.25), rgba(black.main, 0.25))}, url(${Bg1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <MDBox as={Link} sx={{ py: "15px", px: "25px" }} to="/">
          <MDBox component="img" src={Logo} alt="Brand" />
        </MDBox>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ position: "relative", py: 22, textAlign: "center" }}
        >
          <Grid item xs={11} lg={5}>
            <MDBox mb={1}>
              <MDTypography variant="h2" color="white" fontWeight="bold">
                {!data?.subscribed ? "Subscribe Now!" : "Your Subscription"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container sx={{ px: 6, my: 8 }}>
        <Grid item xs={12}>
          <Card sx={{ mt: -16, pt: 16, pb: 16 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={4} lg={4} zIndex={10}>
                <OrganizationTotalUsersCard />
              </Grid>
              <Grid item xs={4} lg={4} zIndex={10}>
                <TrialEndsSubscriptionRenewsCard />
              </Grid>
            </Grid>
            <Container>
              <PricingCards priceTerm={{ value: totalPrice, period: "user/month" }} />
            </Container>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  );
}

export default SubscriptionPage;
