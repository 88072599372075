import { OrganizationProductType } from "generated/graphql";
import React from "react";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function ProductTypeNameCell({
  value,
  row: { values },
}: {
  value: string;
  row: { values: OrganizationProductType };
}) {
  return <Link to={getRoute("product-types.view", [["productTypeId", values.id]])}>{value}</Link>;
}
