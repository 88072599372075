import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Company } from "generated/graphql";
import { schema, UpdateCompanyNotesInputSchema } from "DDD/action-objects/CompanyNotesUpdate";
import useUpdateCompanyNotes from "hooks/companies/useUpdateCompanyNotes";

export default function CompanyNotes({
  companyId,
  notes,
}: {
  companyId: Company["id"];
  notes: Company["notes"];
}) {
  const [updateCompanyNotes, { loading: loadingNotes }] = useUpdateCompanyNotes();
  const { handleSubmit, control } = useForm<UpdateCompanyNotesInputSchema>({
    defaultValues: {
      id: companyId,
      notes,
    },
    resolver: zodResolver(schema),
  });
  const onSaveNotes = useCallback(async (values: UpdateCompanyNotesInputSchema) => {
    await updateCompanyNotes({
      variables: {
        input: {
          id: values.id ?? companyId,
          notes: values.notes,
        },
      },
    });
  }, []);
  return (
    <>
      <MDBox px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Notes
        </MDTypography>
        <MDBox mb={1}>
          <MDTypography variant="button" color="text">
            Company Notes
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={3}>
        <Grid container spacing={2} alignItems="center" px={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="notes"
              render={({ field }) => (
                <WYSIWYG content={field.value} onChange={(note) => field.onChange(note)} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                type="button"
                variant="contained"
                onClick={handleSubmit(onSaveNotes)}
                color="success"
                disabled={loadingNotes}
                sx={{ maxHeight: "25px" }}
              >
                Save
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
