import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { RequestPasswordResetInput } from "generated/graphql";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import MDTypography from "components/MDTypography";
import { zodEmailValidator } from "utils/validations/isValidEmail";

export interface ForgotPasswordFormProps {
  onSubmit: SubmitHandler<RequestPasswordResetInput>;
  loading: boolean;
}

const schema = z.object({
  email: zodEmailValidator,
});

export default function ForgotPasswordForm({ onSubmit, loading }: ForgotPasswordFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RequestPasswordResetInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  return (
    <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
      <MDBox mb={2}>
        <MDInput
          type="text"
          label="Email"
          variant="standard"
          placeholder="Email"
          fullWidth
          {...register("email", { required: "Email required" })}
          errror={!!errors.email}
        />
        {errors.email && (
          <MDTypography color="error" variant="caption">
            {errors.email.message}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
          Reset Password
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
