import FullCalendar, { EventClickArg, EventDropArg, EventInput } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import useGetJobSchedulesLazy from "hooks/jobs/useGetJobSchedulesLazy";
import { CalendarEvent, CalendarInfo } from "utils/events/transformSchedulesToEvents";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { CustomCrew } from "hooks/crews/useGetAllCrews";
import Modal from "modules/Modal/Modal";
import ScheduleJobPhase from "../ScheduleJobPhase/ScheduleJobPhase";
import { Job } from "generated/graphql";
import { useCalendarContext } from "../JobsSchedule/CalendarContext";
import QuickBookIcon from "../../../assets/images/quickbooks.svg";
import BilledIcon from "../../../assets/images/billed.svg";
import DollarIcon from "../../../assets/images/dollar.svg";
import interactionPlugin, { EventDragStopArg } from "@fullcalendar/interaction";
import useScheduleJobPhaseBlock from "hooks/jobs/useScheduleJobPhaseBlock";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useGetCustomEventBlocksLazy } from "hooks/customEvent/useGetCustomEventBlocksLazy";
import CreateUpdateCustomEvent from "modules/CustomEvent/CreateUpdateCustomEvent";
import useScheduleCustomEventBlock from "hooks/customEvent/useScheduleCustomEventBlock";
import { JobBillingStatus } from "generated/graphql";
import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export default function JobsSchedulesCalendar({
  showCustomEvents = true,
  crewIds,
  jobIds = [],
}: {
  crewIds: CustomCrew["id"][];
  jobIds?: Job["id"][];
  showCustomEvents?: boolean;
}) {
  const calendarRef = useRef<any>(null);
  const { subscribeCalendarToUpdates, unsubscribeCalendarFromUpdates } = useCalendarContext();
  const [getJobSchedulesLazy, { loading }] = useGetJobSchedulesLazy();
  const [getCustomEventBlocksLazy] = useGetCustomEventBlocksLazy();
  const [scheduleJobPhaseBlock, { isSuccess }] = useScheduleJobPhaseBlock();
  const [scheduleCustomEventBlock, { isSuccess: customEventSuccess }] =
    useScheduleCustomEventBlock();
  const eventsRef = useRef({});
  const loadingRef = useRef(false);
  const pendingSuccessCallbacks = useRef<
    Record<string, ((e: CalendarEvent[]) => void)[] | undefined>
  >({});
  const [selectedJobPhaseId, setSelectedJobPhaseId] = useState<string>(null);
  const [selectedCustomEventId, setSelectedCustomEventId] = useState<string>(null);

  const handleEventClick = useCallback((block: EventClickArg) => {
    if (block.event.extendedProps?.customEventId) {
      setSelectedCustomEventId(block.event.extendedProps.customEventId);
    } else {
      setSelectedJobPhaseId(block.event.extendedProps.jobPhaseId);
    }
  }, []);

  const handleDropEvent = useCallback(async (event: EventDropArg) => {
    const {
      start,
      end,
      extendedProps: { block },
    } = event.event;
    const hours = dayjs(end).diff(dayjs(start), "h");

    if (event.event.extendedProps.customEventId) {
      const result = await scheduleCustomEventBlock({
        variables: {
          input: {
            blockId: block.id,
            scheduledAt: dayjs.utc(start).format("YYYY-MM-DD HH:mm:ss"),
            blockLength: hours,
          },
        },
      });

      if (!customEventSuccess(result?.data)) {
        event.revert();
      }
    } else {
      const result = await scheduleJobPhaseBlock({
        variables: {
          input: {
            showTravelFrom: block.showTravelFrom,
            showTravelTo: block.showTravelTo,
            travelTimeEstimate: block.travelTime,
            blockId: block.id,
            scheduledAt: dayjs.utc(start).format("YYYY-MM-DD HH:mm:ss"),
            blockLength: hours,
          },
        },
      });

      if (!isSuccess(result?.data)) {
        event.revert();
      }
    }
  }, []);

  const onClose = useCallback(() => {
    setSelectedJobPhaseId(null);
  }, [setSelectedJobPhaseId]);

  const onCloseCustomEvent = useCallback(() => {
    setSelectedCustomEventId(null);
  }, [setSelectedCustomEventId]);

  useEffect(() => {
    const subscriptionId = subscribeCalendarToUpdates(() => {
      const calendarApi = calendarRef.current && calendarRef.current.getApi();
      if (calendarApi) {
        calendarApi.refetchEvents();
      }
    });

    return () => unsubscribeCalendarFromUpdates(subscriptionId);
  }, []);

  const fullCalendarEvents = async (
    info: CalendarInfo,
    successCallback: (events: EventInput) => void,
    failureCallback: (error: any) => void
  ) => {
    const dateFrom = dayjs(info.start).format("YYYY-MM-DD HH:mm:ss");
    const dateUntil = dayjs(info.end).format("YYYY-MM-DD HH:mm:ss");

    let customEventFilteredEvents = [];
    if (!loadingRef.current) {
      try {
        loadingRef.current = true;

        const scheduleResult = await getJobSchedulesLazy({
          variables: { dateFrom, dateUntil, jobIds },
          fetchPolicy: "network-only",
        });
        if (showCustomEvents) {
          const customEventResult = await getCustomEventBlocksLazy({
            variables: {
              dateFrom,
              dateUntil,
            },
            fetchPolicy: "network-only",
          });
          customEventFilteredEvents = customEventResult?.events?.filter((event) => {
            if (event.crewId) {
              return crewIds.includes(event.crewId);
            }
            return true;
          });
        }

        const scheduledFilteredEvents = scheduleResult.events.filter(
          (event) => crewIds.includes(event.crewId) || !event.crewId // selected crews or all individuals
        );

        eventsRef.current[`${dateFrom}${dateUntil}`] = [
          ...scheduledFilteredEvents,
          ...customEventFilteredEvents,
        ];

        successCallback(eventsRef.current[`${dateFrom}${dateUntil}`]);

        let callback = pendingSuccessCallbacks.current[`${dateFrom}${dateUntil}`]?.pop();

        if (callback) {
          callback(eventsRef.current[`${dateFrom}${dateUntil}`]);
        }
      } catch (error) {
        eventsRef.current[`${dateFrom}${dateUntil}`] = [];
        failureCallback(error);
      } finally {
        loadingRef.current = false;
      }
    } else {
      pendingSuccessCallbacks.current[`${dateFrom}${dateUntil}`] = [
        ...(pendingSuccessCallbacks.current[`${dateFrom}${dateUntil}`] ?? []),
        successCallback,
      ];
    }
  };

  return (
    <>
      {loading && (
        <MDBox textAlign="center" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </MDBox>
      )}
      <MDBox sx={{ opacity: loading ? 0 : 100 }}>
        <FullCalendar
          allDaySlot={false}
          droppable
          ref={calendarRef as any}
          timeZone="local"
          editable
          eventTimeFormat={{ hour: "numeric", minute: "2-digit", timeZoneName: "short" }}
          eventClick={handleEventClick}
          eventDrop={handleDropEvent}
          slotDuration={"00:15:00"}
          snapDuration={"00:15:00"}
          eventDisplay="block"
          events={(info: CalendarInfo, successCallback, failureCallback) => {
            fullCalendarEvents(info, successCallback, failureCallback);
          }}
          eventContent={function (arg) {
            let icon = document.createElement("img");
            let timeText = document.createElement("p");
            let body = document.createElement("div");

            body.innerHTML =
              arg.view.type === "dayGridMonth"
                ? arg.event.title.split(",").shift()
                : arg.event.title;
            timeText.innerHTML = arg.timeText;
            icon.src = QuickBookIcon;
            icon.style.textAlign = "right";
            icon.style.position = "absolute";
            icon.style.right = "0";
            icon.width = 16;
            timeText.style.fontSize = "9px";
            if (arg.event.backgroundColor !== "#000000") {
              icon.style.filter = "invert(1)";
            }
            if (!arg.event.extendedProps?.pushedToQuickBook) {
              icon.style.display = "none";
            }
            if (
              arg.event.extendedProps?.billingStatus == JobBillingStatus.PARTIAL_BILLED ||
              arg.event.extendedProps?.billingStatus == JobBillingStatus.BILLED
            ) {
              icon.src = BilledIcon;
              icon.style.display = "";
            }
            if (arg.event.extendedProps?.billingStatus == JobBillingStatus.PAID) {
              icon.src = DollarIcon;
              icon.style.display = "";
            }
            let arrayOfDomNodes = [icon, timeText, body];
            return {
              domNodes: arrayOfDomNodes,
            };
          }}
          headerToolbar={{
            left: "prev,next,today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,timeGridThreeDay",
          }}
          views={{
            timeGridThreeDay: {
              type: "timeGrid",
              duration: { days: 3 },
              buttonText: "3 day",
            },
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          height={700}
        />
      </MDBox>

      <Modal open={!!selectedJobPhaseId} onClose={onClose}>
        <ScheduleJobPhase id={selectedJobPhaseId} onClose={onClose} />
      </Modal>
      <Modal open={!!selectedCustomEventId} onClose={onCloseCustomEvent}>
        <CreateUpdateCustomEvent
          id={selectedCustomEventId}
          action="update"
          onClose={onCloseCustomEvent}
        />
      </Modal>
    </>
  );
}
