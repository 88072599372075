import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableBody,
  IconButton,
  TableFooter,
  Icon,
  Grid,
} from "@mui/material";
import { capitalCase } from "change-case";
import SingleChoiceFilter from "components/filters/SingleChoiceFilter/SingleChoiceFilter";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import dayjs from "dayjs";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { StyledTableRow } from "./JobsSchedules/JobsSchedules";
import { visuallyHidden } from "@mui/utils";
import useJobPhasesTableData from "hooks/jobs/useJobPhasesTableData";
import { GetJobsSortKey, Proposal, SortDirection } from "generated/graphql";
import MDTypography from "components/MDTypography";
import WorkOrderModal from "./WorkOrder/WorkOrderModal";
import JobCostModal from "./WorkOrder/JobCostModal";
import MDButton from "components/MDButton";
import Modal from "modules/Modal/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import useGetJobLazy from "hooks/jobs/useGetJobLazy";
import View from "modules/jobs/View";
import { Job } from "generated/graphql";
import MultipleChoiceFilter from "components/filters/MultipleChoiceFilter/MultipleChoiceFilter";
import { enumToValueAsKeyOptions } from "utils/enums/enumToValueAsKeyOptions";
import useJobsBillingStatuses from "hooks/jobs/useJobsBillingStatuses";
import { equals, reject, uniq } from "ramda";

export default function ListJobPhases() {
  const {
    queryLoading,
    queryError,
    pagination,
    sorting,
    companiesData,
    companiesLoading,
    companiesError,
    filterControl,
    filtering,
    jobPhasesData: jobPhases,
    serachBox,
  } = useJobPhasesTableData();

  const [openStatus, setOpenStatus] = useState<Record<string, boolean | undefined>>({});
  const [jobPhasesIds, setJobPhasesID] = useState<Array<Proposal["id"]>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [jobIdSelected, setJobIdSelected] = useState<string>("");
  const [jobSelected, setJobSelected] = useState<Job | null>(null);
  const { data: enumBillingStatus } = useJobsBillingStatuses();
  const [getJob] = useGetJobLazy();

  const handleToggle = useCallback((id: Proposal["id"]) => {
    setOpenStatus((prevState) => ({
      ...prevState,
      [id]: typeof prevState[id] === "undefined" ? true : !prevState[id],
    }));
  }, []);

  const handleExpandAll = useCallback((ids: Array<Proposal["id"]>) => {
    ids.forEach((id) => {
      setOpenStatus((prevState) => ({
        ...prevState,
        [id]: typeof prevState[id] === "undefined" ? true : !prevState[id],
      }));
    });
  }, []);

  const handleClickJobId = (jobId: string) => {
    setJobIdSelected(jobId);
    setOpenModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const jobInfo = await getJob(jobIdSelected);
      setJobSelected(jobInfo?.data?.getJob as Job);
    };

    if (jobIdSelected) {
      fetchData();
    }
  }, [jobIdSelected]);

  const onClose = () => {
    setJobIdSelected("");
    setJobSelected(null);
    setOpenModal(false);
  };

  useEffect(() => {
    const jobIds = jobPhases?.map((jobPhase) => {
      return jobPhase?.id;
    });
    setJobPhasesID(jobIds);
  }, [jobPhases]);

  const getJobPhaseStartsAt = (filterDate, scheduledBlocks, jobPhaseDate): string => {
    if (filterDate && scheduledBlocks?.length > 0) {
      return scheduledBlocks
        ?.map((block) => dayjs.utc(block.scheduledAt).local().format("MM-DD-YYYY h:mm A"))
        .join("\n");
    }

    if (jobPhaseDate) {
      return dayjs.utc(jobPhaseDate).local().format("MM-DD-YYYY h:mm A");
    }

    return "N/A";
  };

  const handleBillingStatusChecked = useCallback(
    (selectedOptions) => {
      return filtering.setBillingStatuses(selectedOptions);
    },
    [filtering]
  );

  return (
    <>
      <Modal open={openModal} onClose={onClose} styles={{ maxWidth: "auto" }}>
        <Box sx={{ p: 3, mx: "auto" }}>
          <MDTypography variant="h4" fontWeight="medium" mb={1}>
            <a
              href={`/jobs/${jobIdSelected}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit" }}
            >
              Update Job # {jobIdSelected}
            </a>
          </MDTypography>
          {jobSelected ? (
            <View job={jobSelected as Job} />
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="65vh">
              <CircularProgress color="secondary" size={110} />
            </Box>
          )}
        </Box>
      </Modal>
      <Grid container spacing={2} mb={3} alignItems="center">
        <Grid item xs={12} md={7}>
          {filterControl}
        </Grid>
        <Grid item xs={12} md={5}>
          <MDBox display="flex" gap={2}>
            <MDButton color="blue" onClick={() => handleExpandAll(jobPhasesIds)}>
              Expand All
            </MDButton>
            <MDButton color="blue" onClick={() => setOpenStatus({})}>
              Collapse All
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
      {serachBox}
      {(queryLoading || queryError) && (
        <div style={{ padding: "16px" }}>
          {queryLoading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {queryError && <MDAlert color="error">{queryError.message}</MDAlert>}
        </div>
      )}
      {!queryLoading && !queryError && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow sx={{ backgroundColor: "grey.300" }}>
                <TableCell
                  align="left"
                  sortDirection={
                    sorting.sortKey === GetJobsSortKey.ID ? SortDirection[sorting.sortKey] : false
                  }
                  sx={{ fontSize: "14px" }}
                >
                  <TableSortLabel
                    active={sorting.sortKey === GetJobsSortKey.ID}
                    direction={
                      sorting.sortKey === GetJobsSortKey.ID
                        ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                        : "asc"
                    }
                    onClick={() => {
                      const isAsc =
                        sorting.sortKey === GetJobsSortKey.ID &&
                        sorting.sortDirection === SortDirection.ASC;
                      sorting.setSort({
                        sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                        sortKey: GetJobsSortKey.ID,
                      });
                    }}
                  >
                    <MDBox display="flex" alignItems="center">
                      Job ID
                    </MDBox>
                    {sorting.sortKey === GetJobsSortKey.ID ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sorting.sortDirection === SortDirection.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell sx={{ fontSize: "14px" }} align="left">
                  <MDBox display="flex" alignItems="center">
                    Stage Name
                  </MDBox>
                </TableCell>
                <TableCell sx={{ fontSize: "14px" }}>
                  <MDBox display={"flex"}>
                    <TableSortLabel>
                      <MDBox display="flex" alignItems="center">
                        Phase
                      </MDBox>
                    </TableSortLabel>
                  </MDBox>
                </TableCell>
                <TableCell sx={{ fontSize: "14px" }}>
                  <MDBox display="flex" alignItems="center">
                    Foreman
                  </MDBox>
                </TableCell>
                <TableCell
                  sx={{ fontSize: "14px" }}
                  align="left"
                  sortDirection={
                    sorting.sortKey === GetJobsSortKey.COMPANY
                      ? SortDirection[sorting.sortKey]
                      : false
                  }
                >
                  <MDBox display={"flex"}>
                    <TableSortLabel
                      active={sorting.sortKey === GetJobsSortKey.COMPANY}
                      direction={
                        sorting.sortKey === GetJobsSortKey.COMPANY
                          ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                          : "asc"
                      }
                      onClick={() => {
                        const isAsc =
                          sorting.sortKey === GetJobsSortKey.COMPANY &&
                          sorting.sortDirection === SortDirection.ASC;
                        sorting.setSort({
                          sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                          sortKey: GetJobsSortKey.COMPANY,
                        });
                      }}
                    >
                      <MDBox display="flex" alignItems="center">
                        Company Name
                      </MDBox>
                      {sorting.sortKey === GetJobsSortKey.COMPANY ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting.sortDirection === SortDirection.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>

                    <SingleChoiceFilter
                      id={"company.name"}
                      value={filtering.companyId}
                      loading={companiesLoading}
                      error={companiesError}
                      options={
                        companiesData?.map((company) => ({
                          label: company.name,
                          value: company.id,
                        })) ?? []
                      }
                      onChange={(selectedCompanyId) =>
                        filtering.setCompanyID((prevState) =>
                          prevState == selectedCompanyId ? null : selectedCompanyId
                        )
                      }
                    />
                  </MDBox>
                </TableCell>
                <TableCell sx={{ fontSize: "14px" }} align="left">
                  <MDBox display="flex" alignItems="center">
                    Address
                  </MDBox>
                </TableCell>
                <TableCell sx={{ fontSize: "14px" }} align="left">
                  <MDBox display="flex" alignItems="center">
                    Billing Status
                    <MultipleChoiceFilter
                      id={"job.billing_status"}
                      value={filtering.billingStatuses}
                      options={enumToValueAsKeyOptions(enumBillingStatus)}
                      onChange={handleBillingStatusChecked}
                    />
                  </MDBox>
                </TableCell>
                <TableCell
                  sx={{ fontSize: "14px" }}
                  align="left"
                  sortDirection={
                    sorting.sortKey === GetJobsSortKey.START
                      ? SortDirection[sorting.sortKey]
                      : false
                  }
                >
                  <TableSortLabel
                    active={sorting.sortKey === GetJobsSortKey.START}
                    direction={
                      sorting.sortKey === GetJobsSortKey.START
                        ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                        : "asc"
                    }
                    onClick={() => {
                      const isAsc =
                        sorting.sortKey === GetJobsSortKey.START &&
                        sorting.sortDirection === SortDirection.ASC;
                      sorting.setSort({
                        sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                        sortKey: GetJobsSortKey.START,
                      });
                    }}
                  >
                    <MDBox display="flex" alignItems="center">
                      Start Date / Time
                    </MDBox>
                    {sorting.sortKey === GetJobsSortKey.START ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sorting.sortDirection === SortDirection.DESC
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobPhases?.map((jobPhase, i) => {
                return (
                  <React.Fragment key={jobPhase.id}>
                    <StyledTableRow
                      className={i % 2 === 0 ? "outer even" : "outer odd"}
                      key={jobPhase.id}
                    >
                      <TableCell sx={{ position: "relative", minWidth: "100px", fontSize: "14px" }}>
                        <MDBox display="flex">
                          <IconButton sx={{ p: 0 }} onClick={() => handleToggle(jobPhase.id)}>
                            <Icon
                              className={`chevron ${
                                openStatus[jobPhase.id] ? "expand_more" : "expand_less"
                              }`}
                            >
                              expand_less
                            </Icon>
                          </IconButton>
                          <MDBox>
                            <Link
                              to="#"
                              style={{ color: "inherit" }}
                              onClick={() => handleClickJobId(jobPhase.job.id)}
                            >
                              {jobPhase.job.externalId}
                            </Link>
                          </MDBox>
                        </MDBox>
                      </TableCell>
                      <TableCell sx={{ fontSize: "14px" }}>{jobPhase.proposalStage.name}</TableCell>
                      <TableCell sx={{ fontSize: "14px" }}>
                        <MDBox width={{ xs: "100%", md: "120px", xl: "140px" }}>
                          {jobPhase.productName}
                        </MDBox>
                      </TableCell>
                      <TableCell sx={{ fontSize: "14px" }}>
                        {jobPhase?.foremanUser?.name ?? "N/A"}
                      </TableCell>
                      <TableCell sx={{ fontSize: "14px" }}>
                        <MDBox width={{ xs: "100%", md: "70px", xl: "140px" }}>
                          {jobPhase.job.company.name}
                        </MDBox>
                      </TableCell>
                      <TableCell sx={{ fontSize: "14px" }}>{jobPhase.job.address.line1}</TableCell>
                      <TableCell sx={{ fontSize: "14px" }}>
                        {jobPhase.job?.billingStatus
                          ? capitalCase(jobPhase.job?.billingStatus)
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "14px", textAlign: "center", whiteSpace: "pre-line" }}
                      >
                        {getJobPhaseStartsAt(
                          filtering.startsAt,
                          jobPhase.scheduledBlockForDate,
                          jobPhase.startsAt
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <MDBox display={"flex"} alignItems="center" flexDirection="column" gap={1}>
                          <WorkOrderModal jobPhaseId={jobPhase.id} />
                          <JobCostModal jobId={jobPhase.job.id} jobPhaseId={jobPhase.id} />
                        </MDBox>
                      </TableCell>
                    </StyledTableRow>
                    {openStatus[jobPhase.id] && (
                      <TableRow>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Phase Hours</strong>
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Crew Needed</strong>
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Material Type</strong>
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Material Needed</strong>
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Equipment Needed</strong>
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Travel Time (One Way)</strong>
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Job Size</strong>
                          </MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography fontSize={"14px"}>
                            <strong>Contact Details</strong>
                          </MDTypography>
                        </TableCell>
                      </TableRow>
                    )}
                    {openStatus[jobPhase.id] && (
                      <TableRow key={jobPhase.id}>
                        <TableCell sx={{ fontSize: "14px" }}>
                          {filtering.startsAt
                            ? jobPhase.scheduledBlockForDate.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.blockLength,
                                0
                              )
                            : jobPhase.jobDuration}
                        </TableCell>
                        <TableCell sx={{ fontSize: "14px" }}>
                          {jobPhase?.crew?.name ?? "N/A"}
                        </TableCell>
                        <TableCell sx={{ fontSize: "14px" }}>
                          {jobPhase.organizationProductTypeName}
                        </TableCell>
                        <TableCell sx={{ fontSize: "14px" }}>
                          {jobPhase.proposedMaterialNeeded}
                        </TableCell>
                        <TableCell sx={{ fontSize: "14px" }}>
                          <ul>
                            {jobPhase.defaultEquipmentPieces.map((equipment) => (
                              <li key={equipment.id}>
                                <MDTypography fontSize="14px">{equipment.name}</MDTypography>
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                        <TableCell>
                          {jobPhase.proposalStage.distanceSeconds
                            ? (jobPhase.proposalStage.distanceSeconds / 3600).toFixed(2) +
                              " Hour(s)"
                            : "-"}{" "}
                        </TableCell>
                        <TableCell>{jobPhase.jobSize}</TableCell>

                        <TableCell sx={{ fontSize: "14px" }}>
                          <b>Name: </b>
                          {jobPhase.job.contact?.firstName +
                            " " +
                            jobPhase.job.contact?.lastName}{" "}
                          <br />
                          <b>Phone: </b>
                          {jobPhase.job.contact.phone}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={9}>
                  <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {pagination.component}
                  </MDBox>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
