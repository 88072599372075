import { zodResolver } from "@hookform/resolvers/zod";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect, { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import dayjs, { Dayjs } from "dayjs";
import { CreateCustomEventInput, CustomEvent, UpdateCustomEventInput } from "generated/graphql";
import CrewOptions from "modules/crews/CrewOptions";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGetCustomEvent } from "hooks/customEvent/useGetCustomEvent";
import { useCreateCustomEvent } from "hooks/customEvent/useCreateCustomEvent";
import {
  createSchema,
  updateSchema,
  getDefaultValues,
} from "DDD/action-objects/ScheduleCustomEvent";
import { useUpdateCustomEvent } from "hooks/customEvent/useUpdateCustomEvent";
import { omit } from "ramda";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import { convertHexToRGB, getRandomHexColor } from "utils/object/getRandomColor";
import { ColorPickerProps, colors } from "modules/OrganizationCrews/Create";
import reactCSS from "reactcss";
import { CompactPicker } from "react-color";
import useUnscheduleCustomEvent from "hooks/customEvent/useUnscheduleCustomEvent";
import CustomMultipleSelect from "components/Shared/CustomMultipleSelect/CustomMultipleSelect";
import { Control } from "leaflet";
import SelectCrewOrIndividual from "modules/SelectCrewOrIndividual/SelectCrewOrIndividual";

dayjs.extend(utc);
dayjs.extend(localizedFormat);
type CustomEventAction = {
  create: CreateCustomEventInput;
  update: UpdateCustomEventInput;
};
const schemas = {
  create: createSchema,
  update: updateSchema,
};

export default function CreateUpdateCustomEvent({
  id,
  action = "create",
  onClose,
}: {
  id?: CustomEvent["id"];
  action: keyof CustomEventAction;
  onClose: () => void;
}) {
  const { loading, data: customEvent, error } = useGetCustomEvent({ id: id ?? "" });

  if (loading) {
    return (
      <MDBox
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        width="100%"
        height={"100%"}
        minHeight="350px"
      >
        <CircularProgress color="inherit" />
      </MDBox>
    );
  }

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  return (
    <MDBox
      sx={{
        width: "90vw",
        height: "90vh",
        maxWidth: "100%",
        maxHeight: "100%",
        p: 1,
      }}
    >
      <CustomEventScheduling
        customEvent={(customEvent as CustomEvent) ?? null}
        action={action}
        onClose={onClose}
      />
    </MDBox>
  );
}
type AssignTo = "crew" | "individual";
const CustomEventScheduling = React.memo(
  ({
    customEvent,
    action = "create",
    onClose,
  }: {
    customEvent?: CustomEvent;
    action: keyof CustomEventAction;
    onClose: () => void;
  }) => {
    const [createCustomEvent, { isSuccess }] = useCreateCustomEvent();
    const [updateCustomEvent, { isSuccess: isSuccessUpdate }] = useUpdateCustomEvent();
    const [unscheduleCustomEvent] = useUnscheduleCustomEvent();

    const methods = useForm<CustomEventAction[typeof action] & { assignTo?: string }>({
      resolver: zodResolver(schemas[action]),
      defaultValues: getDefaultValues(customEvent),
    });
    // const [assignTo, setAssignTo] = useState<AssignTo | null>(
    //   (methods.getValues("assignTo") as AssignTo) || null
    // );
    const defaultColor = customEvent ? `#${methods.getValues("color")}` : getRandomHexColor();
    const [colorPicker, setColorPicker] = useState<ColorPickerProps>({
      displayColorPicker: false,
      color: {
        hex: defaultColor,
        rgb: convertHexToRGB(defaultColor),
      },
    });
    // const getRecipientsLabel = (id: string, organizationUserOptions: SelectOptions) => {
    //   const organizationUser = organizationUserOptions.find((x) => x.value === id);
    //   return organizationUser?.label;
    // };

    // const handleClick = () => {
    //   setColorPicker({ ...colorPicker, displayColorPicker: !colorPicker.displayColorPicker });
    // };

    // const handleClose = () => {
    //   setColorPicker({ ...colorPicker, displayColorPicker: false });
    // };

    // const handleChange = (color) => {
    //   setColorPicker({
    //     ...colorPicker,
    //     color: {
    //       hex: color.hex,
    //       rgb: convertHexToRGB(color.hex),
    //     },
    //   });
    // };

    // const styles = reactCSS({
    //   default: {
    //     color: {
    //       width: "36px",
    //       height: "14px",
    //       borderRadius: "2px",
    //       background: `rgba(${colorPicker.color?.rgb?.r}, ${colorPicker.color?.rgb?.g}, ${colorPicker.color?.rgb?.b}, 1)`,
    //     },
    //     swatch: {
    //       padding: "5px",
    //       background: "#fff",
    //       borderRadius: "1px",
    //       boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    //       display: "inline-block",
    //       cursor: "pointer",
    //       maxWidth: "45px",
    //       marginTop: "20px",
    //     },
    //     popover: {
    //       position: "absolute",
    //       zIndex: "2",
    //     },
    //     cover: {
    //       position: "fixed",
    //       top: "0px",
    //       right: "0px",
    //       bottom: "0px",
    //       left: "0px",
    //     },
    //   },
    // });

    const {
      formState: { errors },
      register,
    } = methods;
    const handleUnschedule = useCallback(async () => {
      onClose();
      await unscheduleCustomEvent({
        variables: {
          id: customEvent?.id,
        },
      });
    }, []);
    return (
      <MDBox
        sx={{
          height: "calc(100% - 40px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormProvider {...methods}>
          <MDBox
            display="flex"
            width="50%"
            justifyContent={"space-between"}
            mx="auto"
            mt="20px"
          ></MDBox>
          <MDBox
            sx={{
              flexGrow: "1",
              display: "flex",
              flexDirection: "column",
            }}
            p={3}
            component="form"
            role="form"
            onSubmit={methods.handleSubmit(async (values: CustomEventAction[typeof action]) => {
              const formattedPayload = {
                ...omit(["assignTo"], values),
                color: colorPicker.color.hex.substring(1),
                blocks: values.blocks.map((block) => {
                  return {
                    ...block,
                    scheduledAt: dayjs(block.scheduledAt).utc().format("YYYY-MM-DD HH:mm:ss"),
                  };
                }),
              };
              if (action === "create") {
                const result = await createCustomEvent({
                  variables: {
                    input: {
                      ...formattedPayload,
                    },
                  },
                });
                if (result.success && isSuccess(result.data)) {
                  onClose();
                }
              } else {
                const result = await updateCustomEvent({
                  variables: {
                    input: {
                      ...formattedPayload,
                    },
                  },
                });
                if (result.success && isSuccessUpdate(result.data)) {
                  onClose();
                }
              }
            })}
          >
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <MDBox
                mb={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2.5,
                }}
              >
                <MDBox display="flex" justifyContent="flex-start" py={2}>
                  {!!customEvent?.id && (
                    <MDButton size="small" type="button" color="dark" onClick={handleUnschedule}>
                      Unschedule
                    </MDButton>
                  )}
                </MDBox>
                <Grid container spacing={1} alignItems={"center"} width="100%">
                  <Grid item xs={2}>
                    <FormLabel>Event Name</FormLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <FormField {...methods.register("name")} />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems={"center"} width="100%">
                  <Grid item xs={2}>
                    <FormLabel>Event Notes</FormLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <TextareaAutosize
                      {...methods.register("notes")}
                      minRows={10}
                      style={{ minWidth: "100%", padding: "5px" }}
                      id="notes-text-area"
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={1} alignItems="center" width="100%">
                  <Grid item xs={2}>
                    <FormLabel>Assign To</FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomSelect
                      control={methods.control}
                      placeholder="Assign to"
                      fullWidth
                      selectVariant="outlined"
                      data={[
                        { label: "Crew", value: "crew" },
                        { label: "Individual", value: "individual" },
                      ]}
                      name="assignTo"
                      onChange={(e) => setAssignTo(e.target.value as AssignTo)}
                    />
                  </Grid>
                </Grid> */}
                <SelectCrewOrIndividual
                  colorPicker={colorPicker}
                  setColorPicker={setColorPicker}
                  methods={methods}
                  errors={errors}
                />
                {/* {assignTo === "crew" && (
                  <Grid container spacing={1} alignItems="center" width="100%">
                    <Grid item xs={2}>
                      <FormLabel>Select Crew</FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                      <CrewOptions>
                        {({ crewOptions }) => (
                          <CustomSelect
                            placeholder="Select Crew"
                            control={methods.control}
                            fullWidth
                            selectVariant="outlined"
                            data={crewOptions}
                            name="crewID"
                            {...methods.register("crewID")}
                          />
                        )}
                      </CrewOptions>
                    </Grid>
                  </Grid>
                )} */}

                {/* {errors?.crewID?.message && (
                  <FormHelperText error sx={{ marginLeft: "0px" }}>
                    {errors?.crewID?.message}
                  </FormHelperText>
                )} */}
              </MDBox>

              <Divider sx={{ width: "100%" }} />
            </MDBox>
            <MDBox
              sx={{
                flexGrow: "1",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CustomEventBlocks action={action} />
            </MDBox>
            <MDBox sx={{ textAlign: "center", mt: 2 }}>
              <MDButton variant="gradient" color="info" type="submit">
                {methods.getValues("blocks").length ? "Add To Calendar" : "Remove From Calendar"}
              </MDButton>
            </MDBox>
          </MDBox>
        </FormProvider>
      </MDBox>
    );
  }
);

CustomEventScheduling.displayName = "CustomEventScheduling";

const CustomEventBlocks = React.memo(
  ({ action = "create" }: { action: keyof CustomEventAction }) => {
    const { control } = useFormContext<CustomEventAction[typeof action]>();

    const { fields, append, remove } = useFieldArray({
      control,
      name: "blocks",
    });

    const handleRemoveScheduleBlock = useCallback(
      (blockIndex) => {
        if (fields.length === 1) {
          // Do not remove if there is only one field left
          return;
        }

        remove(blockIndex);
      },
      [fields]
    );

    return (
      <>
        <MDBox sx={{ flex: "1 1 auto", overflowY: "auto", maxHeight: "300px" }}>
          <Grid container py={1}>
            {fields.map((field, i) => {
              return (
                <Grid item key={field.id} xs={12}>
                  <CustomEventBlock
                    index={i}
                    action={action}
                    onRemoveScheduleBlock={handleRemoveScheduleBlock}
                  />
                  <Divider />
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() =>
              append({
                scheduledAt: null,
                blockLength: 0,
              })
            }
          >
            Add Date
          </MDButton>
        </MDBox>
      </>
    );
  }
);

CustomEventBlocks.displayName = "CustomEventBlocks";

const CustomEventBlock = React.memo(
  ({
    index,
    action,
    onRemoveScheduleBlock,
  }: {
    index: number;
    onRemoveScheduleBlock: (index: number) => void;
    action: keyof CustomEventAction;
  }) => {
    const { control, getValues } = useFormContext<CustomEventAction[typeof action]>();
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [startDate, setStartDate] = useState(new Date());

    const { blocks } = getValues();

    useEffect(() => {
      const { blocks } = getValues();
      const { scheduledAt: blockScheduledAt, blockLength: blockBlockLength } = blocks[index];
      if (!blockScheduledAt || !blockBlockLength) {
        setEndDate(null);
      } else {
        setEndDate(dayjs(blockScheduledAt).add(blockBlockLength, "hours"));
      }
    }, []);

    const recalculateEndDate = useCallback(() => {
      const { blocks } = getValues();
      const { scheduledAt: blockScheduledAt, blockLength: blockBlockLength } = blocks[index];

      // This is where we calculate the end date IF the scheduledAt and blockLength are set
      if (!blockScheduledAt || !blockBlockLength) {
        setEndDate(null);
      } else {
        setEndDate(dayjs(blockScheduledAt).add(blockBlockLength, "hours"));
      }
    }, []);

    return (
      <>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={8}>
            <Controller
              control={control}
              name={`blocks.${index}.scheduledAt`}
              render={({ field, fieldState: { error } }) => {
                return (
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    sx={{
                      ".date-input": {
                        height: "32px",
                        width: "200px",
                        fontSize: "0.875rem",
                        border: "none",
                        borderRadius: 0,
                        borderBottom: "1px solid #7b809a",
                        color: "#344767",
                        padding: "0 !important",
                        "&:focus-visible": {
                          outline: "none",
                        },
                      },
                    }}
                  >
                    <label
                      htmlFor={`blocks.${index}.scheduledAt`}
                      style={{ fontSize: "0.875rem", color: "#7b809a" }}
                    >
                      Start Date
                    </label>
                    <DatePicker
                      name={`blocks.${index}.scheduledAt`}
                      {...field}
                      selected={startDate}
                      shouldCloseOnSelect={false}
                      onChange={(e) => {
                        field.onChange(dayjs(e).format("YYYY-MM-DD HH:mm:ss"));
                        setStartDate(e);
                        recalculateEndDate();
                      }}
                      ref={(ref) => {
                        field.ref({
                          focus: ref?.setFocus,
                        });
                      }}
                      showTimeSelect
                      timeIntervals={15}
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      className="date-input"
                    />
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </MDBox>
                );
              }}
            />
            <MDTypography variant="body2" my={1} fontSize="0.875rem">
              End Date: {endDate && endDate.format("LLL")}
            </MDTypography>
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name={`blocks.${index}.blockLength`}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormField
                    valueAsNumber={true}
                    label="Hours"
                    inputProps={{ type: "number" }}
                    // Change to use controller so that I can intercept the onChange
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      recalculateEndDate();
                    }}
                    error={error}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => onRemoveScheduleBlock(index)}>
              <Icon>remove_circle_outline</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  }
);

CustomEventBlock.displayName = "CustomEventBlock";
