import getLocalString from "constants/Localization";
import { useCustomDeleteProposalProductMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteProposalProduct() {
  const [mutation, { loading, data, error }] = useCustomDeleteProposalProductMutation();
  const [deleteProposalProduct, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteProposalProduct",
    },
    {
      failureMessage: getLocalString("proposals.delete-proposal-product-failure"),
      successMessage: getLocalString("proposals.delete-proposal-product-success"),
    }
  );
  return [deleteProposalProduct, context] as const;
}
