export const contentPlaceholders = {
  "job": {
    "Company": {
      "company.name": "Name",
      "company.phone": "Phone",
      "company.email": "Email",
      "company.address_line_1": "Address Line 1",
      "company.address_line_2": "Address Line 2",
      "company.address_city": "Address City",
      "company.address_state": "Address State",
      "company.address_zip": "Address Zip",
      "company.address_country": "Address Country",
      "company.website": "Website"
    },
    "Contact": {
      "contact.firstname": "First Name",
      "contact.lastname": "Last Name",
      "contact.phone": "Phone",
      "contact.email": "Email"
    },
    "Estimator": {
      "estimator.name": "Name",
      "estimator.phone": "Phone"
    },
    "Job": {
      "id": "Id",
      "schedule_link": "Schedule Link",
      "info_link": "Info Link"
    }
  },
  "proposal": {
    "Organization": {
      "organization.name": "Name",
      "organization.address.line_1": "Address Line 1",
      "organization.address.line_2": "Address Line 2",
      "organization.address.city": "Address City",
      "organization.address.state": "Address State",
      "organization.address.zip": "Address Zip",
      "organization.address.country": "Address Country",
      "organization.phone": "Phone",
      "organization.email": "Email",
      "organization.logo": "Logo"
    },
    "Company": {
      "company.name": "Name",
      "company.phone": "Phone",
      "company.email": "Email",
      "company.address_line_1": "Address Line 1",
      "company.address_line_2": "Address Line 2",
      "company.address_city": "Address City",
      "company.address_state": "Address State",
      "company.address_zip": "Address Zip",
      "company.address_country": "Address Country",
      "company.website": "Website"
    },
    "Contact": {
      "contact.firstname": "First Name",
      "contact.lastname": "Last Name",
      "contact.phone": "Phone",
      "contact.email": "Email"
    },
    "Estimator": {
      "estimator.name": "Name",
      "estimator.phone": "Phone"
    },
    "Proposal": {
      "id": "Id",
      "link": "Link",
      "internal_link": "Internal Link",
      "sent_date": "Sent Date",
      "signed_date": "Signed Date",
      "total": "Total"
    }
  },
  "proposalStage": {
    "ProposalStage": {
      "name": "Name"
    },
    "Address": {
      "address.line_1": "Line 1",
      "address.line_2": "Line 2",
      "address.city": "City",
      "address.state": "State",
      "address.zip": "Zip",
      "address.country": "Country"
    }
  },
  "proposalProduct": {
    "ProposalProduct": {
      "job_measurement": "Job Measurement",
      "job_duration": "Job Duration",
      "measurement_method": "Measurement Method",
      "measurement_type": "Measurement Type",
      "application_rate": "Application Rate",
      "application_rate_inches": "Application Rate Inches",
      "material_amount": "Material Amount",
      "material_amount_tons": "Material Amount Tons",
      "cost_per_area": "Cost Per Area",
      "equipment_needed": "Equipment Needed",
      "labour_needed": "Labour Needed",
      "additional_costs": "Additional Costs",
      "transfer_trucks": "Transfer Trucks",
      "proposal_product_name": "Proposal Product Name",
      "plant_name": "Plant Name",
      "plant_product_name": "Plant Product Name"
    }
  },
  "organization": {
    "Organization": {
      "name": "Name",
      "phone": "Phone",
      "email": "Email",
      "logo": "Logo"
    },
    "Address": {
      "address.line_1": "Line 1",
      "address.line_2": "Line 2",
      "address.city": "City",
      "address.state": "State",
      "address.zip": "Zip",
      "address.country": "Country"
    }
  },
  "opportunity": {
    "Opportunity": {
      "name": "Name",
      "address_line_1": "Address Line 1",
      "address_line_2": "Address Line 2",
      "address_city": "Address City",
      "address_state": "Address State",
      "address_zip": "Address Zip",
      "address_country": "Address Country"
    },
    "Company": {
      "company.name": "Name",
      "company.phone": "Phone",
      "company.email": "Email",
      "company.address_line_1": "Address Line 1",
      "company.address_line_2": "Address Line 2",
      "company.address_city": "Address City",
      "company.address_state": "Address State",
      "company.address_zip": "Address Zip",
      "company.address_country": "Address Country",
      "company.website": "Website"
    },
    "Contact": {
      "contact.firstname": "First Name",
      "contact.lastname": "Last Name",
      "contact.phone": "Phone",
      "contact.email": "Email"
    }
  },
  "jobPhase": {
    "Organization": {
      "organization.name": "Name",
      "organization.address.line_1": "Address Line 1",
      "organization.address.line_2": "Address Line 2",
      "organization.address.city": "Address City",
      "organization.address.state": "Address State",
      "organization.address.zip": "Address Zip",
      "organization.address.country": "Address Country",
      "organization.phone": "Phone",
      "organization.email": "Email",
      "organization.logo": "Logo"
    },
    "Job": {
      "job.company.name": "Company Name",
      "job.company.phone": "Company Phone",
      "job.company.email": "Company Email",
      "job.company.address_line_1": "Company Address Line 1",
      "job.company.address_line_2": "Company Address Line 2",
      "job.company.address_city": "Company Address City",
      "job.company.address_state": "Company Address State",
      "job.company.address_zip": "Company Address Zip",
      "job.company.address_country": "Company Address Country",
      "job.company.website": "Company Website",
      "job.contact.firstname": "Contact First Name",
      "job.contact.lastname": "Contact Last Name",
      "job.contact.phone": "Contact Phone",
      "job.contact.email": "Contact Email",
      "job.estimator.name": "Estimator Name",
      "job.estimator.phone": "Estimator Phone",
      "job.id": "Id",
      "job.schedule_link": "Schedule Link",
      "job.info_link": "Info Link"
    },
    "ProposalProduct": {
      "proposalProduct.job_measurement": "Job Measurement",
      "proposalProduct.job_duration": "Job Duration",
      "proposalProduct.measurement_method": "Measurement Method",
      "proposalProduct.measurement_type": "Measurement Type",
      "proposalProduct.application_rate": "Application Rate",
      "proposalProduct.application_rate_inches": "Application Rate Inches",
      "proposalProduct.material_amount": "Material Amount",
      "proposalProduct.material_amount_tons": "Material Amount Tons",
      "proposalProduct.cost_per_area": "Cost Per Area",
      "proposalProduct.equipment_needed": "Equipment Needed",
      "proposalProduct.labour_needed": "Labour Needed",
      "proposalProduct.additional_costs": "Additional Costs",
      "proposalProduct.transfer_trucks": "Transfer Trucks",
      "proposalProduct.proposal_product_name": "Proposal Product Name",
      "proposalProduct.plant_name": "Plant Name",
      "proposalProduct.plant_product_name": "Plant Product Name"
    },
    "ProposalStage": {
      "proposalStage.name": "Name",
      "proposalStage.address.line_1": "Address Line 1",
      "proposalStage.address.line_2": "Address Line 2",
      "proposalStage.address.city": "Address City",
      "proposalStage.address.state": "Address State",
      "proposalStage.address.zip": "Address Zip",
      "proposalStage.address.country": "Address Country"
    },
    "JobPhase": {
      "start_date": "Start Date",
      "job_costs_link": "Job Costs Link",
      "work_order_link": "Work Order Link"
    }
  },
  "contact": {
    "Contact": {
      "firstname": "First Name",
      "lastname": "Last Name",
      "phone": "Phone",
      "email": "Email"
    },
    "Company": {
      "company.name": "Name",
      "company.phone": "Phone",
      "company.email": "Email",
      "company.address_line_1": "Address Line 1",
      "company.address_line_2": "Address Line 2",
      "company.address_city": "Address City",
      "company.address_state": "Address State",
      "company.address_zip": "Address Zip",
      "company.address_country": "Address Country",
      "company.website": "Website"
    }
  }
};