import lengthEndAdornment from "constants/lenghtEndAdornment";
import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "crackDepth",
    label: "Crack Depth",
    inputProps: { ...lengthEndAdornment },
  },
  {
    name: "crackWidth",
    label: "Crack Width",
    inputProps: { ...lengthEndAdornment },
  },
  {
    name: "overbandWidth",
    label: "Overband Width",
    inputProps: { ...lengthEndAdornment },
  },
  {
    name: "overbandThickness",
    label: "Overband Thickness",
    inputProps: { ...lengthEndAdornment },
  },
];

export default function CrackSealForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
