import MDAlert from "components/MDAlert";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { Crew, SortDirection } from "generated/graphql";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import useGetCrews from "hooks/organization/useGetCrews";
import usePaginatedResources, { Pagination } from "hooks/usePaginatedResources";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";

export default function CrewOptions({
  children,
}: {
  children: ({
    crewOptions,
    crewsById,
    loading,
    search,
  }: {
    crewOptions: SelectOptions;
    crewsById: Record<string, Crew | undefined>;
    loading: boolean;
    search: (v: string) => void;
    pagination: Pagination;
  }) => React.ReactNode;
}) {
  const { filtering, offset } = usePaginatedVariables();

  const { loading, data, error, paginatorInfo } = useGetCrews({
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sort: SortDirection.ASC,
    page: offset.page,
  });

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }

  const crews = data ?? [];

  const crewOptions = crews.map((crew) => ({
    label: crew.name,
    value: crew.id,
  }));

  const crewsById = crews.reduce((acc, val) => {
    return {
      ...acc,
      [val.id]: val as Crew,
    };
  }, {} as Record<string, Crew | undefined>);

  return (
    <>
      {children({ crewOptions, crewsById, loading, search: filtering.setSearchTerm, pagination })}
    </>
  );
}
