import { CustomUpdateProposalStageMutationVariables } from "generated/graphql";
import { useCallback } from "react";
import useAddProposalAddress from "./useAddProposalAddress";
import useCustomUpdateProposalStage from "./useCustomUpdateProposalStage";

export default function useUpdateProposalStageAddress() {
  const [mutation, customUpdateProposalStageContext] = useCustomUpdateProposalStage();
  const [customAddProposalAddress, customAddProposalAddressContext] = useAddProposalAddress();

  const customUpdateProposalStage = useCallback(
    async (variables: CustomUpdateProposalStageMutationVariables & { address?: string }) => {
      const result = await mutation({
        variables,
      });
      const address = JSON.parse(variables.address || "{}");
      const success = customUpdateProposalStageContext.isSuccess(result.data);

      const data = customUpdateProposalStageContext.getData(result);
      if (success) {
        await customAddProposalAddress({
          variables: {
            input: {
              addressCity: address.city,
              addressCountry: address.country,
              addressLine1: address.line1,
              addressLine2: address?.line2,
              addressState: address.state,
              addressZip: address.zip,
              latitude: address.lat,
              longitude: address.lng,
              proposalStageId: variables.id,
            },
          },
        });
      }
      return { success, data };
    },
    [mutation]
  );

  return [customUpdateProposalStage, customUpdateProposalStageContext] as const;
}
