import getLocalString from "constants/Localization";
import { useCustomDeleteProposalStageMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteProposalStage() {
  const [mutation, { data, loading, error }] = useCustomDeleteProposalStageMutation();

  const [deleteProposalStage, context] = useBitumioMutation(
    {
      loading,
      data,
      error,
      mutation,
      key: "deleteProposalStage",
    },
    {
      failureMessage: getLocalString("proposals.delete-proposal-stage-failure"),
      successMessage: getLocalString("proposals.delete-proposal-stage-success"),
    }
  );
  return [deleteProposalStage, context] as const;
}
