import { gql, Reference, useApolloClient } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Proposal, ProposalRecipient } from "generated/graphql";
import useDeleteProposalRecipient from "hooks/proposals/useDeleteProposalRecipient";
import useSendProposalToSpecificRecipients from "hooks/proposals/useSendProposalToSpecificRecipients";
import { isNilOrEmpty } from "ramda-adjunct";
import { useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

type AddRecipientsEmails = { emails: { email: string }[] };

export default function ProposalRecipients({ proposal }: { proposal: Proposal }) {
  const client = useApolloClient();

  const [sendProposalToSpecificRecipients, { getData, isSuccess }] =
    useSendProposalToSpecificRecipients();
  const [deleteProposalRecipient, { isSuccess: isDeleteSuccess, getData: getDeleteData }] =
    useDeleteProposalRecipient();

  const onResend = useCallback(
    async (email: string) => {
      await sendProposalToSpecificRecipients({
        variables: {
          proposalId: proposal.id,
          emails: [email],
        },
      });
    },
    [sendProposalToSpecificRecipients]
  );

  const onSend = useCallback(
    async ({ emails }: AddRecipientsEmails) => {
      const _emails = emails.map(({ email }) => email);
      const result = await sendProposalToSpecificRecipients({
        variables: { emails: _emails, proposalId: proposal.id },
      });
      const success = isSuccess(result.data);
      const data = getData(result);

      if (success) {
        // Manually insert the email in the contacts
        client.cache.modify({
          id: client.cache.identify(proposal),
          fields: {
            recipients() {
              return data.map((recipient) => {
                return client.cache.writeFragment({
                  data: recipient,
                  fragment: gql`
                    fragment NewRecipient on ProposalRecipient {
                      id
                      contactId
                      email
                    }
                  `,
                });
              });
            },
          },
        });
      }

      return { success, data };
    },
    [sendProposalToSpecificRecipients]
  );

  const onDelete = useCallback(async (recipient: ProposalRecipient) => {
    const result = await deleteProposalRecipient({
      variables: {
        proposalId: proposal.id,
        email: recipient.email,
      },
    });
    const success = isDeleteSuccess(result.data);
    const data = getDeleteData(result);

    if (success) {
      const idToRemove = recipient.id;
      client.cache.modify({
        id: client.cache.identify(proposal),
        fields: {
          recipients(existingRecipientsRef: Reference[], { readField }) {
            return existingRecipientsRef.filter((recipientRef) => {
              return idToRemove !== readField("id", recipientRef);
            });
          },
        },
      });
    }

    return { success, data };
  }, []);

  return (
    <>
      <MDBox mb={4}>
        <MDTypography variant="h5" mb={5}>
          Proposal Recipients
        </MDTypography>
        <ProposalRecipientsList
          recipients={proposal.recipients}
          onResend={onResend}
          onDelete={onDelete}
        />
      </MDBox>
      <MDBox mb={3}>
        <MDTypography variant="h5">Add Proposal Recipients</MDTypography>
        <AddProposalRecipientsForm onSend={onSend} />
      </MDBox>
    </>
  );
}

function AddProposalRecipientsForm({
  onSend,
}: {
  onSend: (emails: AddRecipientsEmails) => Promise<{ success: boolean; data: any }>;
}) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<AddRecipientsEmails>({
    resolver: zodResolver(z.object({ emails: z.array(z.object({ email: z.string().email() })) })),
    defaultValues: { emails: [{ email: "" }] },
  });

  const { fields, append, remove } = useFieldArray({ control: control, name: "emails" });

  return (
    <MDBox
      p={3}
      component="form"
      role="form"
      onSubmit={handleSubmit(async (values) => {
        const result = await onSend(values);
        if (result.success) {
          reset({ emails: [{ email: "" }] });
        }
      })}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox display="flex" width="100%" alignItems="center" mt={1}>
            <MDTypography variant="h6">Additional Recipients</MDTypography>
            <IconButton onClick={() => append({ email: "" })}>
              <Icon>add_circle</Icon>
            </IconButton>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          {fields.map((field, i) => (
            <MDBox key={i} display="flex" width="100%" alignItems="center" mb={2}>
              <FormField
                label="Additional Recipient"
                {...register(`emails.${i}.email`)}
                error={errors["emails"]?.[i]}
              />
              <IconButton onClick={() => remove(i)}>
                <Icon color="error">delete</Icon>
              </IconButton>
            </MDBox>
          ))}
        </Grid>
        <Grid item xs={12} textAlign="right">
          <MDButton variant="gradient" color="success" sx={{ minWidth: "160px" }} type="submit">
            Send
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

function ProposalRecipientsList({
  recipients,
  onResend,
  onDelete,
}: {
  recipients: ProposalRecipient[];
  onResend: (email: string) => Promise<void>;
  onDelete: (recipient: ProposalRecipient) => Promise<{ success: boolean; data: boolean }>;
}) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow
            sx={{
              "& th": {
                textAlign: "center",
                color: "#dfdfdf",
              },
              backgroundColor: "#666",
            }}
          >
            <TableCell>Email Address</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipients.map((recipient) => (
            <TableRow
              key={recipient.id}
              sx={{
                "& td": {
                  textAlign: "center",
                },
              }}
            >
              <TableCell>{recipient.email}</TableCell>
              <TableCell>
                <MDBox display="flex" flexDirection="column">
                  <MDButton
                    variant="text"
                    color="info"
                    onClick={() => {
                      onResend(recipient.email);
                    }}
                  >
                    Resend
                  </MDButton>
                  {isNilOrEmpty(recipient.contactId) && (
                    <MDButton
                      variant="text"
                      color="info"
                      onClick={() => {
                        onDelete(recipient);
                      }}
                    >
                      Delete
                    </MDButton>
                  )}
                </MDBox>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
