import getLocalString from "constants/Localization";
import { useFinalizeLinkedServiceAccountMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import { useNavigate } from "react-router";

const useFinalizeLinkedServiceAccount = (serviceName) => {
  const navigate = useNavigate();

  const [mutation, { loading, data, error }] = useFinalizeLinkedServiceAccountMutation({
    onCompleted: () =>
      navigate(`/organization/linked-services/${serviceName.toLowerCase()}/settings`),
  });

  const [finalizeLinkedServiceAccount, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "finalizeLinkedServiceAccount",
    },
    {
      successMessage: getLocalString("organization.finalize-linked-service-account-success"),
      failureMessage: getLocalString("organization.finalize-linked-service-account-failure"),
    }
  );
  return [finalizeLinkedServiceAccount, context] as const;
};

export default useFinalizeLinkedServiceAccount;
