import { useCustomGetNotificationTriggersQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetNotificationTriggers() {
  const { data, error, loading, refetch, called } = useCustomGetNotificationTriggersQuery();
  const refetchData = useCallback(async () => {
    if (called) {
      await refetch();
    }
  }, [refetch, called]);
  const notificationTriggers = useMemo(() => {
    return data?.getNotificationTriggers || null;
  }, [data]);

  return { notificationTriggers, error, loading, refetchData } as const;
}
