import getLocalString from "constants/Localization";
import {
  useGetLinkedServiceAuthUrlMutation,
  useSaveQuickbooksIncomeAccountMutation,
} from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

const useSaveQuickbooksIncomeAccount = () => {
  const [mutation, { loading, data, error }] = useSaveQuickbooksIncomeAccountMutation();

  const [saveQuickbooksIncomeAccount, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "saveQuickbooksIncomeAccount",
    },
    {
      successMessage: getLocalString("organization.save-quickbooks-income-account-success"),
      failureMessage: getLocalString("organization.save-quickbooks-income-account-failure"),
    }
  );
  return [saveQuickbooksIncomeAccount, context] as const;
};

export default useSaveQuickbooksIncomeAccount;
