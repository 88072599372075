import { TextareaAutosize, FormHelperText, Tabs, Tab } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { JobPhaseAutocomplete } from "components/JobPhaseScrollAsyncAutocomplete/JobPhaseAutocomplete";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import useGetJobPhaseLazy from "hooks/jobs/job-phases/useGetJobPhaseLazy";
import ClearIcon from "@mui/icons-material/Clear";
import { useGetTimeBuckets } from "hooks/timeTracking/useGetTimeBuckets";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import dayjs from "dayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { dateInputContainerStyles, dateInputFormat } from "./CreateTimeEntryModal";
import { JobPhase, UserStatus } from "generated/graphql";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";

type AssociationType = "jobPhase" | "timeBucket";

interface TimeEntryFormProps {
  onSubmit: (data: any) => Promise<void>;
  defaultValues: any;
  schema: any;
  submitButtonText: string;
  type: "create" | "edit";
}

export default function TimeEntryModalForm({
  onSubmit,
  defaultValues,
  schema,
  submitButtonText,
  type,
}: TimeEntryFormProps) {
  const [associationType, setAssociationType] = useState<AssociationType>(
    defaultValues.timeBucketId ? "timeBucket" : "jobPhase"
  );
  const [getJobPhase, { data: jobPhaseData, loading: jobPhaseLoading }] = useGetJobPhaseLazy(true);
  const { data: timeBuckets, loading: timeBucketsLoading } = useGetTimeBuckets();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const jobPhaseId = watch("jobPhaseId");

  useEffect(() => {
    if (jobPhaseId) {
      getJobPhase(jobPhaseId);
    }
  }, [jobPhaseId]);

  useEffect(() => {
    if (associationType === "jobPhase") {
      setValue("timeBucketId", null);
    } else if (associationType === "timeBucket") {
      setValue("jobPhaseId", null);
    }
  }, [associationType, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MDTypography variant="body2" mb={1}>
        Associate Time Entry To:
      </MDTypography>

      <Tabs
        value={associationType}
        onChange={(_, newValue) => setAssociationType(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Job Phase" value="jobPhase" />
        <Tab label="Time Bucket" value="timeBucket" />
      </Tabs>

      {associationType === "jobPhase" && (
        <>
          <MDBox mb={2}>
            <JobPhaseAutocomplete selectedJobPhase={jobPhaseData?.getJobPhase as JobPhase}>
              {({ options, queryLoading, pagination, handleSearch }) => (
                <Controller
                  control={control}
                  name="jobPhaseId"
                  render={({ field: { onChange, value } }) => (
                    <ScrollAsyncAutocomplete
                      label="Select Job Phase"
                      value={options.find((x) => x.value === value)}
                      onChange={onChange}
                      options={options}
                      loading={queryLoading}
                      hasMore={pagination.hasNextPage}
                      search={handleSearch}
                      onLoadMore={pagination.loadMore}
                    />
                  )}
                />
              )}
            </JobPhaseAutocomplete>
            {jobPhaseId && (
              <MDBox mt={1}>
                <MDTypography
                  variant="button"
                  color="info"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    lineHeight: 1,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => {
                    setValue("jobPhaseId", null);
                  }}
                >
                  <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                  Clear Selection
                </MDTypography>
              </MDBox>
            )}
          </MDBox>

          <MDBox mb={3}>
            <MDTypography variant="body3">Selected Job Phase Details:</MDTypography>
            {jobPhaseId && jobPhaseData?.getJobPhase && (
              <MDBox>
                <MDTypography variant="body3" display="block" color="text">
                  ID: {jobPhaseData.getJobPhase.id}
                </MDTypography>
                <MDTypography variant="body3" display="block" color="text">
                  Phase: {jobPhaseData.getJobPhase.proposalStage?.name}
                </MDTypography>
                <MDTypography variant="body3" display="block" color="text">
                  Product Type: {jobPhaseData.getJobPhase.organizationProductTypeName}
                </MDTypography>
                <MDTypography variant="body3" display="block" color="text">
                  Address: {jobPhaseData.getJobPhase.address}
                </MDTypography>
                {jobPhaseData.getJobPhase.startsAt && (
                  <MDTypography variant="body3" display="block" color="text">
                    Start Time: {dayjs(jobPhaseData.getJobPhase.startsAt).format("LLL")}
                  </MDTypography>
                )}
                {jobPhaseData.getJobPhase.endsAt && (
                  <MDTypography variant="body3" display="block" color="text">
                    End Time: {dayjs(jobPhaseData.getJobPhase.endsAt).format("LLL")}
                  </MDTypography>
                )}
              </MDBox>
            )}
          </MDBox>
        </>
      )}

      {associationType === "timeBucket" && (
        <MDBox mb={3}>
          <Controller
            control={control}
            name="timeBucketId"
            render={({ field }) => (
              <>
                <CustomSelect
                  label="Time Bucket"
                  control={control}
                  disabled={timeBucketsLoading}
                  selectVariant="outlined"
                  fullWidth
                  data={
                    timeBuckets?.map((bucket) => ({
                      value: bucket.id,
                      label: bucket.name,
                    })) || []
                  }
                  {...field}
                />
                {field.value && (
                  <MDBox mt={1}>
                    <MDTypography
                      variant="button"
                      color="info"
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        lineHeight: 1,
                        "&:hover": {
                          color: "primary.main",
                        },
                      }}
                      onClick={() => {
                        field.onChange(null);
                      }}
                    >
                      <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                      Clear Selection
                    </MDTypography>
                  </MDBox>
                )}
              </>
            )}
          />
        </MDBox>
      )}

      <MDBox display="flex" flexDirection="column" sx={dateInputContainerStyles}>
        <label style={{ fontSize: "0.875rem", color: "#7b809a" }}>Start Time</label>
        <Controller
          control={control}
          name="startTime"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              showTimeSelect
              timeIntervals={15}
              dateFormat={dateInputFormat}
              className="date-input"
            />
          )}
        />
        <FormHelperText error>{errors.startTime?.message}</FormHelperText>
      </MDBox>

      <MDBox display="flex" flexDirection="column" sx={dateInputContainerStyles}>
        <label style={{ fontSize: "0.875rem", color: "#7b809a" }}>End Time</label>
        <Controller
          control={control}
          name="endTime"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              showTimeSelect
              timeIntervals={15}
              dateFormat={dateInputFormat}
              className="date-input"
            />
          )}
        />
        <FormHelperText error>{errors.endTime?.message}</FormHelperText>
      </MDBox>

      {type === "create" && (
        <MDBox mb={3}>
          <OrganizationUsersOptions queryOverride={{ first: 1000, status: UserStatus.ACTIVE }}>
            {({ organizationUsersOptions, loading }) => (
              <Controller
                control={control}
                name="userId"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <AutoComplete
                      options={organizationUsersOptions}
                      loading={loading}
                      variant="outlined"
                      field={{
                        onChange: field.onChange,
                        onBlur: field.onBlur,
                        value: field.value,
                        name: field.name,
                        ref: field.ref,
                      }}
                      error={error}
                      label="Select User"
                    />
                    {field.value && (
                      <MDBox mt={1}>
                        <MDTypography
                          variant="button"
                          color="info"
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                            lineHeight: 1,
                            "&:hover": {
                              color: "primary.main",
                            },
                          }}
                          onClick={() => {
                            field.onChange(null);
                          }}
                        >
                          <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                          Clear Selection
                        </MDTypography>
                      </MDBox>
                    )}
                  </>
                )}
              />
            )}
          </OrganizationUsersOptions>
        </MDBox>
      )}

      <TextareaAutosize
        minRows={5}
        style={{
          width: "100%",
          borderRadius: "10px",
          border: "1px solid #ccc",
          padding: "10px",
          marginBottom: "16px",
        }}
        {...register("notes")}
      />

      <MDButton type="submit" variant="gradient" color="success">
        {submitButtonText}
      </MDButton>
    </form>
  );
}
