import { Grid, Icon, IconButton } from "@mui/material";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import currencyEndAdornment from "constants/currencyEndAdornment";
import { plantProductTypesSelectOptions } from "constants/PlantProductTypes";
import { defaultValuesOtherProduct } from "DDD/action-objects/PlantCreate";
import { CreatePlantInput, UpdatePlantInput } from "generated/graphql";
import useCreatePlantProductType from "hooks/organization/plants/useCreatePlantProductType";
import useGetPlantProductTypes from "hooks/organization/plants/useGetPlantProductTypes";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import { useCallback, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import AddCustomMaterialType from "../AddCustomMaterialType";

type Props = {
  fieldArrayName: "products.create" | "products.upsert";
};

const AddProductsSection = ({ fieldArrayName }: Props) => {
  const { control } = useFormContext<CreatePlantInput | UpdatePlantInput>();

  const { open, onClose, onOpen } = useModalState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const productKey = fieldArrayName === "products.create" ? "create" : "upsert";

  // get custom materials created in organization and merge them with the default ones
  const { selectOptions } = useGetPlantProductTypes();
  const plantProductTypesSelectOptionsMerged = useMemo(() => {
    return [...plantProductTypesSelectOptions, ...(selectOptions ?? [])].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }, [plantProductTypesSelectOptions, selectOptions]);

  return (
    <Grid item xs={12}>
      <MDBox p={3}>
        {fields.map((field, index) => (
          <Grid container spacing={3} key={field.id} alignItems="flex-end">
            <Grid item xs={12} display="flex" alignItems="center">
              {index === 0 && (
                <MDTypography variant="h6">
                  What Material does this Plant supply? (Required)
                </MDTypography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomSelect
                control={control}
                name={`products.${productKey}.${index}.type`}
                label="Type"
                fullWidth
                selectVariant="standard"
                data={plantProductTypesSelectOptionsMerged}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <CurrencyFormField
                control={control}
                inputProps={{ min: "0" }}
                name={`products.${productKey}.${index}.rate`}
                label="Rate"
                {...currencyEndAdornment}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              {index > 0 ? (
                <IconButton
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <Icon fontSize="medium" color="error">
                    delete
                  </Icon>
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
        ))}
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDButton
                type="button"
                color="blue"
                onClick={() => {
                  append(defaultValuesOtherProduct);
                }}
                sx={{
                  marginBottom: "5px",
                }}
              >
                Add Material To Plant
              </MDButton>{" "}
              <MDButton
                type="button"
                color="blue"
                onClick={() => {
                  onOpen();
                }}
                sx={{
                  marginBottom: "5px",
                }}
              >
                Add Custom Material Type
              </MDButton>
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Modal
        open={open}
        onClose={onClose}
        styles={{ overflow: "auto", height: "auto", maxWidth: "500px" }}
      >
        <MDTypography variant="h6" p={3}>
          Add Custom Material Type
        </MDTypography>
        <AddCustomMaterialType onClose={onClose} />
      </Modal>
    </Grid>
  );
};

export default AddProductsSection;
