import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Modal from "modules/Modal/Modal";
import dayjs from "dayjs";
import { schema, TimeEntryCreateSchema } from "DDD/action-objects/TimeEntryCreate";
import useModalState from "hooks/useModalState";
import { AddCircle } from "@mui/icons-material";
import { useCallback } from "react";
import { useCreateTimeEntry } from "hooks/timeTracking/useCreateTimeEntry";
import { v4 as uuidv4 } from "uuid";
import { useGetTimeEntriesLazy } from "hooks/timeTracking/useGetTimeEntriesLazy";
import TimeEntryModalForm from "./TimeEntryModalForm";
import { UserPermissions } from "generated/graphql";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

const datePickerStyles = {
  height: "32px",
  width: "200px",
  fontSize: "0.875rem",
  borderRadius: 2,
  border: "1px solid #ccc",
  color: "#344767",
  padding: "25px 13px",
  "&:focus-visible": {
    outline: "none",
  },
};

export const dateInputContainerStyles = {
  ".date-input": datePickerStyles,
  mb: 2,
  ".react-datepicker-popper": {
    zIndex: 2,
  },
};

export const dateInputFormat = "yyyy-MM-dd hh:mm a";

export default function CreateTimeEntryModal() {
  const { open, onClose, onOpen } = useModalState();
  const [createTimeEntry] = useCreateTimeEntry();
  const [getTimeEntries] = useGetTimeEntriesLazy();
  const { hasPermissions } = useCheckPermissions();

  const defaultValues = {
    startTime: new Date(),
    endTime: new Date(),
    notes: "",
    jobPhaseId: null,
    timeBucketId: null,
    userId: null,
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = async (data: TimeEntryCreateSchema) => {
    const result = await createTimeEntry({
      variables: {
        input: {
          uuid: uuidv4(),
          forDate: dayjs(data.startTime).format("YYYY-MM-DD"),
          startTime: dayjs(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
          endTime: dayjs(data.endTime).format("YYYY-MM-DD HH:mm:ss"),
          notes: data.notes,
          jobPhaseId: data.jobPhaseId,
          timeBucketId: data.timeBucketId,
          userId: data.userId,
        },
      },
    });

    if (result.success) {
      // sometimes the elasticsearch index is not updated immediately, so we retry a few times
      const interval = setInterval(() => {
        getTimeEntries({ page: 1, first: 50 });
      }, 2000);

      setTimeout(() => {
        clearInterval(interval);
      }, 10000);

      handleClose();
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} styles={{ overflow: "auto", maxWidth: "568px" }}>
        <MDBox p={3}>
          <MDTypography variant="h6" mb={3}>
            Create Time Entry
          </MDTypography>

          <TimeEntryModalForm
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            schema={schema}
            submitButtonText="Create"
            type="create"
          />
        </MDBox>
      </Modal>
      {hasPermissions(UserPermissions.MANAGE_TIME_TRACKING) && (
        <MDButton variant="gradient" color="success" startIcon={<AddCircle />} onClick={onOpen}>
          Create Time Entry
        </MDButton>
      )}
    </>
  );
}
