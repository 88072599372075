import { zodResolver } from "@hookform/resolvers/zod";
import {
  Card,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import currencyEndAdornment from "constants/currencyEndAdornment";
import percentageEndAdornment from "constants/percentageEndAdornment";
import { schema, defaultValues } from "DDD/action-objects/EquipmentPieceEdit";
import { CreateEquipmentPieceInput, EquipmentPieceChargeType } from "generated/graphql";
import { useForm } from "react-hook-form";

export default function Create({ onSubmit, loading }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<CreateEquipmentPieceInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const chargeTypeWatch = watch("chargeType");

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (values) => {
          const result = await onSubmit(values);
          if (result.success) {
            reset();
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                sx={{ m: 1.2, minWidth: "100%", marginLeft: "-3px" }}
                error={"chargeType" in errors}
              >
                <InputLabel id="charge-type-select-label" sx={{ marginLeft: "-12px" }}>
                  Charge Type
                </InputLabel>
                <Select
                  labelId="charge-type-select-label"
                  label="Charge Type"
                  placeholder="Please select"
                  variant="standard"
                  fullWidth
                  sx={{
                    "&": {
                      marginTop: "13px",
                    },
                  }}
                  {...register("chargeType")}
                >
                  <MenuItem value={EquipmentPieceChargeType.HOURLY}>Hourly</MenuItem>
                  <MenuItem value={EquipmentPieceChargeType.MILEAGE}>Mileage</MenuItem>
                </Select>
                <FormHelperText sx={{ marginLeft: "0px" }}>
                  {errors?.chargeType?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                align="right"
                type="number"
                label={
                  chargeTypeWatch
                    ? chargeTypeWatch == EquipmentPieceChargeType.HOURLY
                      ? "Hourly cost"
                      : "Cost per mile"
                    : "Rate"
                }
                inputProps={{ min: 0, step: 0.01 }}
                {...register("rate", { valueAsNumber: true })}
                {...currencyEndAdornment}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                align="right"
                type="number"
                label="Fuel Surcharge"
                defaultValues={0}
                inputProps={{ min: 0, step: 0.01 }}
                {...register("fuelSurcharge", { valueAsNumber: true })}
                {...currencyEndAdornment}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                align="right"
                type="number"
                label="On job use rate"
                suffix="%"
                helperText="What percentage is this equipment used on a job?"
                defaultValues={1}
                inputProps={{ min: 1, max: 100 }}
                error={errors.usePercentage}
                {...register("usePercentage", { valueAsNumber: true })}
                {...percentageEndAdornment}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                Create
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
