import { capitalCase } from "change-case";
import { NotificationTriggerEvent, NotificationTriggerType } from "generated/graphql";
import EmailTriggerActionsCell from "modules/email-triggers/EmailTriggerActionsCell/EmailTriggerActionsCell";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import useGetNotificationTriggers from "./useGetNotificationTriggers";

const mapEvent = (props: any) => {
  return props.value ? capitalCase(props.value) : "";
};
export default function useEmailTriggersTable() {
  const { notificationTriggers, loading, error, refetchData } = useGetNotificationTriggers();
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }) => (
          <Link style={{ color: "inherit" }} to={`/email-triggers/edit/${row.original.id}`}>
            {value}
          </Link>
        ),
      },
      { Header: "Active", accessor: "active", Cell: (props) => (props.value ? "Yes" : "No") },
      {
        Header: "Event",
        accessor: "event",
        Cell: mapEvent,
      },
      { Header: "Type", accessor: "type", Cell: mapEvent },
      { Header: "Actions", accessor: "id", Cell: EmailTriggerActionsCell },
    ];
  }, []);

  const tableData = useMemo(() => {
    refetchData();
    return { columns, rows: notificationTriggers };
  }, [columns, notificationTriggers]);

  return {
    loading,
    error,
    notificationTriggers,
    columns,
    tableData,
  } as const;
}
