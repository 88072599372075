import { Card, Grid, Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable/DataTable";
import { JobPhase } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { Cell } from "react-table";

const PercentageDifference = ({ value }) => {
  if (!value) return null;

  const positiveDifference = value >= 0;

  const valueMap = {
    className: positiveDifference ? "success" : "error",
    sign: positiveDifference ? "+" : "",
  };

  return (
    <MDTypography color={valueMap.className} component="span" variant="body2">
      {valueMap.sign} {value}%
    </MDTypography>
  );
};

export default function DirectCostsSummarySection({ phases }: { phases: JobPhase[] }) {
  const columns = [
    {
      Header: "Phase",
      accessor: "name",
      width: "20%",
    },
    {
      Header: "",
      accessor: "type",
      width: "20%",
    },
    {
      Header: "Material",
      accessor: "materialCost",
      width: "20%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "Equipment",
      accessor: "equipmentCost",
      width: "20%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "Labour",
      accessor: "labourCost",
      width: "10%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "Other",
      accessor: "additionalCost",
      width: "10%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "Transfer Trucks",
      accessor: "transferTrucksCost",
      width: "10%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "Total",
      accessor: "totalCost",
      width: "10%",
      Cell: (props) => formatCentsToUSD(props.value),
    },
    {
      Header: "% Difference",
      accessor: "percentageDifference",
      width: "10%",
      Cell: ({ value }) => <PercentageDifference value={value} />,
    },
  ];

  // TODO: add transfer trucks cost
  const buildTableRows = () => {
    const tableRows = [];
    phases.forEach((phase) => {
      tableRows.push({
        name: phase.proposalProduct.material.name,
        type: "Proposed",
        materialCost: phase.proposedCostMaterial,
        equipmentCost: phase.proposedCostEquipment,
        labourCost: phase.proposedCostLabour,
        additionalCost: phase.proposedCostAdditional,
        transferTrucksCost: phase.proposedCostTransferTruck,
        totalCost: phase.proposedCostTotal,
        percentageDifference: "",
      });
      tableRows.push({
        name: "",
        type: "Actuals",
        materialCost: phase.actualCostMaterial,
        equipmentCost: phase.actualCostEquipment,
        labourCost: phase.actualCostLabour,
        additionalCost: phase.actualCostAdditional,
        transferTrucksCost: phase.actualCostTransferTruck,
        totalCost: phase.actualCostTotal,
        percentageDifference: Math.round(
          ((+phase.proposedCostTotal - +phase.actualCostTotal) / +phase.proposedCostTotal) * 100
        ),
      });
    });

    return tableRows;
  };

  const tableData = { columns, rows: buildTableRows() };

  return (
    <Card>
      <MDBox mt={4} p={4}>
        <MDTypography>Direct Costs Summary</MDTypography>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <DataTable table={tableData} showTotalEntries={false} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
