import { Grid, Skeleton } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { OrganizationAddress } from "generated/graphql";
import useGetOrganizationAddress from "hooks/organization/useGetOrganizationAddress";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Update from "modules/OrganizationAddresses/Update";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useEffect } from "react";
import { useParams } from "react-router";

export default function OrganizationAddressEditPage() {
  const { addressId } = useParams();
  const [getOrganizationAddress, { loading, error, address }] = useGetOrganizationAddress();

  useEffect(() => {
    getOrganizationAddress({ id: addressId });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <>Loading</>}
      {error && (
        <MDAlert color="error">
          Could not load address details. Please refresh this page or try again later.
        </MDAlert>
      )}
      {!error && (
        <MDBox my={3}>
          <MDBox mb={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h4" fontWeight="medium">
                  Updating Address
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {address && <Update address={address as OrganizationAddress} />}
              {!address && <Skeleton />}
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
