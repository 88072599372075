import { cleanedConcat } from "utils/strings/cleanedConcat";

export interface Person {
  firstName?: string;
  lastName?: string;
}

export const getFullName = (person: Person) => {
  const { firstName, lastName } = person;
  return cleanedConcat(firstName, lastName);
};
