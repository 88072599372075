// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Bitumio layout components
import { getRoute } from "utils/routing";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { RegisterInput } from "generated/graphql";
import useBooleanToggle from "hooks/useBooleanToggle";
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import useRegister from "hooks/signup/useRegister";
import MDAlert from "components/MDAlert";
import { useState } from "react";
import { getLoginRoute, getVerificationResendRoute } from "utils/routing/getRoute";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema, defaultValues } from "DDD/action-objects/UserSignup";
import { Turnstile } from "@marsidev/react-turnstile";
import PhoneInput from "components/PhoneInput/PhoneInput";

const SignUpForm: React.FC<{ onSubmit: SubmitHandler<RegisterInput>; loading: boolean }> = ({
  onSubmit,
  loading,
}) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm<RegisterInput & { agree: boolean }>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return (
    <MDBox
      component="form"
      role="form"
      onSubmit={handleSubmit(({ agree, ...rest }: RegisterInput & { agree: boolean }) =>
        onSubmit(rest)
      )}
    >
      <MDBox mb={2}>
        <MDInput
          type="text"
          label="First Name"
          variant="standard"
          fullWidth
          {...register("firstName")}
          error={errors.firstName}
        />
        {errors.firstName && (
          <MDTypography color="error" variant="caption">
            {errors.firstName.message}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="text"
          label="Last Name"
          variant="standard"
          fullWidth
          {...register("lastName")}
          error={errors.lastName}
        />
        {errors.lastName && (
          <MDTypography color="error" variant="caption">
            {errors.lastName.message}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="text"
          label="Company Name"
          variant="standard"
          fullWidth
          {...register("companyName")}
          error={errors.companyName}
        />
        {errors.companyName && (
          <MDTypography color="error" variant="caption">
            {errors.companyName.message}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mb={2}>
        <Controller
          control={control}
          name="phone"
          render={({ field }) => <PhoneInput {...field} label="Phone" error={errors.phone} />}
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          type="email"
          label="Email"
          variant="standard"
          fullWidth
          {...register("email")}
          errror={errors.email}
        />
        {errors.email && (
          <MDTypography color="error" variant="caption">
            {errors.email.message}
          </MDTypography>
        )}
      </MDBox>
      <div>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Checkbox name="agree" {...register("agree")} />
          <MDTypography
            component="p"
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none" }}
            ml={1}
          >
            I agree to the &nbsp;
            <MDTypography
              component={Link}
              to={getRoute("terms-and-conditions")}
              variant="button"
              fontWeight="bold"
              color="info"
              textGradient
              target="_blank"
            >
              Terms and Conditions
            </MDTypography>
          </MDTypography>
        </MDBox>
        {errors.agree && (
          <MDTypography color="error" variant="caption">
            {errors.agree.message}
          </MDTypography>
        )}
      </div>
      <MDBox mt={4} mb={1}>
        <Turnstile siteKey="0x4AAAAAAAFhDaapJwLVU1aG" onSuccess={(key) => setValue("token", key)} />
        {errors.token && (
          <MDTypography color="error" variant="caption">
            {errors.token.message}
          </MDTypography>
        )}
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
          Sign Up
        </MDButton>
      </MDBox>
      <MDBox mt={3} mb={1} textAlign="center">
        <MDTypography variant="button" color="text">
          Already have an account?{" "}
          <MDTypography
            component={Link}
            to={getRoute("signin")}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Sign In
          </MDTypography>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default function SignUpPage() {
  const [registerMutation, { loading, isUnverified, success, exists }] = useRegister();
  const [attemptedEmail, setAttemptedEmail] = useState<string | null>(null);

  const onSubmit: SubmitHandler<RegisterInput> = (values) => {
    setAttemptedEmail(values.email);
    registerMutation({ variables: { input: values } });
  };

  const resendRoute =
    (isUnverified || success) && attemptedEmail ? getVerificationResendRoute(attemptedEmail) : null;
  const loginRoute = exists && attemptedEmail ? getLoginRoute(attemptedEmail) : null;

  return (
    <IllustrationLayout
      title={success ? "Thank You" : "Sign Up"}
      description={
        success
          ? "Please check your emails to continue"
          : "Enter your details below to create an account"
      }
    >
      {isUnverified && (
        <MDAlert color="warning">
          <MDBox>
            <div>It looks like you already have an account.</div>
            <MDTypography
              component={Link}
              to={resendRoute}
              variant="button"
              color="info"
              fontWeight="medium"
            >
              Resend verification token?
            </MDTypography>
          </MDBox>
        </MDAlert>
      )}
      {exists && (
        <MDAlert color="warning">
          <MDBox>
            <div>It looks like you already have an account.</div>
            <MDTypography
              component={Link}
              to={loginRoute}
              variant="button"
              color="info"
              fontWeight="medium"
            >
              Sign In?
            </MDTypography>
          </MDBox>
        </MDAlert>
      )}
      {success && (
        <MDTypography variant="body2" color="text" textAlign="center">
          Haven&apos;t received your verification email?{" "}
          <MDTypography
            component={Link}
            to={resendRoute}
            variant="text2"
            color="info"
            fontWeight="medium"
          >
            Resend it
          </MDTypography>
        </MDTypography>
      )}
      {!success && <SignUpForm onSubmit={onSubmit} loading={loading} />}
    </IllustrationLayout>
  );
}
