import { Crew } from "generated/graphql";
import * as z from "zod";

export const schema = z.object({
  id: z.string().optional().or(z.null()),
  name: z.string().min(1, { message: "Name is required" }),
  displayName: z.string().optional().or(z.null()),
  foremanUserId: z.string().min(1, { message: "Default Foreman is required" }),
  rate: z.number().min(0, { message: "Rate is required" }),
  color: z.string().optional(),
});

export const defaultValues = {
  name: "",
  displayName: "",
  foremanUserId: "",
  rate: 0,
  color: "",
};

export function getDefaultValues(crew) {
  return {
    ...defaultValues,
    ...crew,
    foremanUserId: crew?.foremanUser?.id ?? defaultValues.foremanUserId,
    rate: crew?.rate / 100,
  };
}
