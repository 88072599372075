import {
  GetProposalsSortKey,
  ProposalStatus,
  SortDirection,
  useCustomGetProposalsQuery,
  useGetProposalsQuery,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { useMemo } from "react";
import { sortDirectionMap } from "constants/sortDirectionMap";
import MDBox from "components/MDBox";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useGetAllCompanies from "hooks/companies/useGetAllCompanies";
import useGetAllOrganizationUsersNonEmployee from "hooks/users/useGetAllOrganizationUsersNonEmployee";

export const proposalsSortKeyMap = {
  id: GetProposalsSortKey.ID,
  company: GetProposalsSortKey.COMPANY,
  status: GetProposalsSortKey.STATUS,
  createdAt: GetProposalsSortKey.CREATED,
  sentAt: GetProposalsSortKey.SENT,
  overallTotal: GetProposalsSortKey.TOTAL,
  default: GetProposalsSortKey.CREATED,
};

export const proposalsInitialSortDirectionKey = "desc";
export const proposalsInitialSortDirectionValue = SortDirection.DESC;
export const proposalsInitialSortKeyKey = "createdAt";
export const proposalsInitialSortKeyValue = GetProposalsSortKey.CREATED;

const Filters = ({ defaultValue, onQueryChange, sortKeyChanged, setSort, sortDirection }) => {
  return (
    <MDBox position="relative" zIndex={2}>
      <MDBox display="flex" alignItems="center" flexWrap="wrap">
        <Paper
          component="form"
          sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, mr: 2 }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "14px" }}
            placeholder="Search Proposals"
            value={defaultValue}
            inputProps={{ "aria-label": "search proposals" }}
            onChange={({ currentTarget }: any) => {
              onQueryChange(currentTarget.value);
              if (!sortKeyChanged) {
                setSort({ sortDirection, sortKey: null });
              }
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </MDBox>
    </MDBox>
  );
};

export default function useProposalsTableData() {
  const savedFilters = JSON.parse(localStorage.getItem("ListProposalsTableFilters")) ?? null;
  const { filtering, sorting, offset } = usePaginatedVariables({
    initialSortDirection: proposalsInitialSortDirectionKey,
    initialSortKey: proposalsInitialSortKeyKey,
    initialStatuses: savedFilters?.statuses,
    initialMultipleCompanyIds: savedFilters?.multipleCompanyIDs,
    initialMultipleUserIDs: savedFilters?.multipleUserIDs,
    initialSearchTerm: savedFilters?.searchTerm ?? null,
  });

  const sortKeyChanged = useMemo(() => {
    return (
      proposalsInitialSortDirectionKey !== sorting.sortDirection &&
      proposalsInitialSortKeyKey !== sorting.sortKey
    );
  }, [
    proposalsInitialSortDirectionKey,
    proposalsInitialSortKeyKey,
    sorting.sortKey,
    sorting.sortDirection,
  ]);

  const {
    loading: companiesLoading,
    data: companiesData,
    error: companiesError,
  } = useGetAllCompanies(true);

  const {
    loading: organizationUsersLoading,
    data: organizationUsersData,
    error: organizationUsersError,
  } = useGetAllOrganizationUsersNonEmployee();

  const {
    loading,
    data: graphqlData,
    error,
  } = useCustomGetProposalsQuery({
    variables: {
      search: filtering.debouncedSearchTerm,
      sortDirection: sortDirectionMap[sorting.sortDirection],
      sortKey: sorting.sortKey
        ? proposalsSortKeyMap[sorting.sortKey.toLowerCase()] || proposalsInitialSortKeyValue
        : null,
      statuses: filtering.statuses as ProposalStatus[],
      first: offset.first,
      page: offset.page,
      companyId: filtering.companyId ?? null,
      userId: filtering.userID ?? null,
      multipleCompanyIDs: filtering.multipleCompanyIDs ?? null,
      multipleUserIDs: filtering.multipleUserIDs ?? null,
    },
    fetchPolicy: "no-cache",
  });

  const data = graphqlData?.getProposals.data;

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo: graphqlData?.getProposals?.paginatorInfo,
  });

  const columns = useMemo(() => {}, []);

  const filterControl = (
    <Filters
      defaultValue={filtering.searchTerm}
      onQueryChange={filtering.setSearchTerm}
      sortKeyChanged={sortKeyChanged}
      setSort={sorting.setSort}
      sortDirection={sorting.sortDirection}
    />
  );

  const renderRowSubComponent = (row: any) => <pre>{JSON.stringify(row, undefined, 2)}</pre>;

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    filterControl,
    renderRowSubComponent,
    pagination,
    filtering,
    sorting,
    paginatorInfo: pagination.paginatorInfo,
    companiesData,
    companiesLoading,
    companiesError,
    organizationUsersLoading,
    organizationUsersData,
    organizationUsersError,
  } as const;
}
