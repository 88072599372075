import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function InvalidEmail() {
  return (
    <MDBox>
      <MDTypography textAlign="center">There is no account with this email address.</MDTypography>
    </MDBox>
  );
}
