import getLocalString from "constants/Localization";
import { useAddProposalCrewMutation, useAddProposalCrewsMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useAddProposalCrews() {
  const [mutation, { loading, data, error }] = useAddProposalCrewsMutation({
    refetchQueries: ["customGetProposal"],
  });
  const [addProposalCrews, context] = useBitumioMutation(
    {
      mutation,
      data,
      error,
      loading,
      key: "addProposalCrews",
    },
    {
      successMessage: getLocalString("proposals.add-proposal-crews-success"),
      failureMessage: getLocalString("proposals.add-proposal-crews-failure"),
    }
  );

  return [addProposalCrews, context] as const;
}
