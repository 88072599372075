import getLocalString from "constants/Localization";
import { useDeleteTransferTruckMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useDeleteTransferTruck() {
  const [mutation, { data, loading, error }] = useDeleteTransferTruckMutation({
    refetchQueries: ["getTransferTrucks", "customGetTransferTrucks"],
  });

  const [deleteTransferTruck, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteTransferTruck",
    },
    {
      successMessage: getLocalString("organization.delete-transfer-truck-success"),
      failureMessage: getLocalString("organization.delete-transfer-truck-failure"),
    }
  );

  return [deleteTransferTruck, context] as const;
}
