import { sentenceCase } from "change-case";
import { Company } from "generated/graphql";
import { assoc, compose, converge, flatten, head, map, pick, tail, toPairs } from "ramda";
import { renameKeysWith } from "ramda-adjunct";
import { getFullName } from "utils/people/getFullName";

export const getSecondaryContactsInfo = compose(
  flatten,
  map(
    compose(
      map(converge((label: string, value: string) => ({ label, value }), [head, tail])),
      toPairs,
      renameKeysWith(sentenceCase),
      pick(["name", "role"]),
      // create Name
      (o: Company["contacts"][number]) => assoc("name", getFullName(o))(o)
    )
  )
);
