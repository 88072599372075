import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { UpdatePlantInput, Plant } from "generated/graphql";
import useUpdatePlant from "hooks/organization/plants/useUpdatePlant";
import AddProductsSection from "modules/OrganizationPlants/CreatePlant/AddProductsSection/AddProductsSection";
// import AttachTransferTrucksSection from "modules/OrganizationPlants/CreatePlant/AttachTransferTrucksSection/AttachTransferTrucksSection";
import MainForm from "modules/OrganizationPlants/CreatePlant/MainForm/MainForm";
import { useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { getRoute } from "utils/routing";

export default function Update({ plant }: { plant: Plant }) {
  const navigate = useNavigate();
  const [updatePlant, { isSuccess }] = useUpdatePlant();
  const methods = useForm<UpdatePlantInput>({
    defaultValues: {
      ...plant,
      products: {
        upsert: plant.products.map((p) => {
          return {
            ...p,
            rate: p.rate,
          };
        }),
      },
    },
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Card>
        <MDBox
          p={3}
          component="form"
          role="form"
          onSubmit={handleSubmit(async (values: UpdatePlantInput) => {
            const data = JSON.parse(JSON.stringify(values));
            delete data.__typename;
            // delete data.transferTrucks;
            const products = values.products.upsert.map((p) => {
              const product = JSON.parse(JSON.stringify(p));
              delete product.__typename;
              return { ...product, rate: p.rate };
            });
            // const transferTrucks = values.plantTransferTrucks.upsert.map((t) => {
            //   const truck = JSON.parse(JSON.stringify(t));
            //   delete truck.__typename;
            //   return {
            //     ...truck,
            //     rateHourly: t.rateHourly,
            //     rateTon: t.rateTon,
            //   };
            // });
            const result = await updatePlant({
              variables: {
                input: {
                  ...data,
                  products: { upsert: products },
                },
              },
            });
            const success = isSuccess(result.data);
            if (success) {
              navigate(getRoute("organization.plants"));
            }
          })}
        >
          <MainForm />
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <AddProductsSection fieldArrayName="products.upsert" />
              {/* <AttachTransferTrucksSection fieldArrayName="plantTransferTrucks.upsert" /> */}

              <Grid item xs={12} display="flex" justifyContent="space-between">
                <MDButton type="submit" variant="gradient" color="success">
                  Update
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </FormProvider>
  );
}
