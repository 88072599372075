import { GetJobQuery, Job, useCustomGetJobLazyQuery, useGetJobLazyQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetJobLazy() {
  const [query, { data, loading, error }] = useCustomGetJobLazyQuery();

  const getData = ({ data }: { data?: GetJobQuery }) => {
    return data?.getJob ?? null;
  };

  const getJob = async (id: Job["id"]) => {
    return await query({ variables: { id } });
  };

  const job = useMemo(() => {
    return (data && data.getJob) || null;
  }, [data]);

  return [getJob, { data, loading, error, job, getData }] as const;
}
