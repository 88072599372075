import getLocalString from "constants/Localization";
import { useCustomCancelProposalMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCancelProposal() {
  const [mutation, { loading, data, error }] = useCustomCancelProposalMutation();
  const [cancelProposal, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "cancelProposal",
    },
    {
      successMessage: getLocalString("proposals.cancel-proposal-success"),
      failureMessage: getLocalString("proposals.cancel-proposal-failure"),
    }
  );

  return [cancelProposal, context] as const;
}
