import { useGetOrganizationProductTypesQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetOrganizationProductTypes() {
  const {
    data: graphqlData,
    loading,
    error,
  } = useGetOrganizationProductTypesQuery({
    notifyOnNetworkStatusChange: true,
  });

  const data = useMemo(() => {
    return graphqlData?.getOrganizationProductTypes || null;
  }, [graphqlData]);

  return { data, loading, error } as const;
}
