import { Grid, Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { ChangeEvent, FC, useState, useCallback } from "react";
import { TimeBucket } from "generated/graphql";
import { useUpdateTimeBucket } from "hooks/timeTracking/useUpdateTimeBucket";
import { useDeleteTimeBucket } from "hooks/timeTracking/useDeleteTimeBucket";
import useConfirmationDialog from "hooks/useConfirmationDialog";

interface TimeBucketItemProps {
  bucket: TimeBucket;
}

const TimeBucketItem: FC<TimeBucketItemProps> = ({ bucket }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>("");
  const [updateTimeBucket] = useUpdateTimeBucket();
  const [deleteTimeBucket, { isSuccess }] = useDeleteTimeBucket();

  const handleEdit = (): void => {
    setIsEditing(true);
    setEditValue(bucket.name);
  };

  const resetEdit = (): void => {
    setIsEditing(false);
    setEditValue("");
  };

  const handleSave = async (): Promise<void> => {
    if (editValue.trim() === "") return;

    const result = await updateTimeBucket({
      variables: {
        input: {
          id: bucket.id,
          name: editValue.trim(),
        },
      },
    });

    if (result.success) {
      resetEdit();
    }
  };

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const { data } = await deleteTimeBucket({
      variables: { id: bucket.id },
    });

    return isSuccess(data);
  }, [deleteTimeBucket, bucket.id]);

  const cancelFn = useCallback(async () => {
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditValue(e.target.value);
  };

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you want to delete this time bucket?"
      />
      <Grid
        item
        xs={12}
        borderBottom={1}
        borderColor="divider"
        display="flex"
        py={0.5}
        alignItems="center"
        justifyContent="space-between"
      >
        <MDBox display="flex" gap={1} alignItems="center">
          <Icon>schedule</Icon>
          {isEditing ? (
            <MDInput value={editValue} onChange={handleInputChange} autoFocus />
          ) : (
            <MDTypography variant="h6">{bucket.name}</MDTypography>
          )}
        </MDBox>
        <MDBox>
          {isEditing ? (
            <>
              <Tooltip title="Save">
                <IconButton onClick={handleSave} color="success">
                  <Icon>save</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton onClick={resetEdit} color="error">
                  <Icon>close</Icon>
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Rename">
                <IconButton onClick={handleEdit} color="info">
                  <Icon>edit</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Time Bucket">
                <IconButton onClick={() => setOpen(true)} color="error">
                  <Icon>delete</Icon>
                </IconButton>
              </Tooltip>
            </>
          )}
        </MDBox>
      </Grid>
    </>
  );
};

export default TimeBucketItem;
