import { SortDirection } from "generated/graphql";
import { useMemo, useState } from "react";

export default function useSortProjectFile(
  data,
  defaultSortBy = "name",
  defaultDirection = SortDirection.ASC
) {
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortDirection, setSortDirection] = useState(defaultDirection);

  const sortedData = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    const sorted = [...data];

    sorted.sort((a, b) => {
      const columnA = a[sortBy];
      const columnB = b[sortBy];

      if (columnA < columnB) {
        return sortDirection === SortDirection.ASC ? -1 : 1;
      }

      if (columnA > columnB) {
        return sortDirection === SortDirection.ASC ? 1 : -1;
      }

      return 0;
    });

    return sorted;
  }, [data, sortBy, sortDirection]);

  const changeSort = (column, direction) => {
    setSortBy(column);
    setSortDirection(direction);
  };

  return { sortedData, sortBy, sortDirection, changeSort };
}
