import { Icon, Menu } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useCallback, useState } from "react";
import VersionMenuItems from "./VersionMenuItems";

export default function VersionActionsCell({ row }) {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const closeMenu = useCallback(() => {
    setAnchorElement(null);
  }, []);

  return (
    <>
      <Menu anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={closeMenu} keepMounted>
        <VersionMenuItems proposal={row?.values} closeMenu={closeMenu} />
      </Menu>
      <MDTypography
        color="secondary"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          setAnchorElement(e.currentTarget)
        }
      >
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }}>more_vert</Icon>
      </MDTypography>
    </>
  );
}
