import { zodResolver } from "@hookform/resolvers/zod";
import {
  Card,
  CardContent,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from "@mui/material";
import { capitalCase } from "change-case";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PlaceHolderSelect from "components/PlaceHolderSelect/PlaceHolderSelect";
import {
  BitumioOrganizationEmailTemplateFragment,
  OrganizationEmailTemplate,
  OrganizationEmailTemplateType,
  SendOrganizationEmailTemplatePreviewMutationVariables,
  UpdateOrganizationEmailTemplateMutationVariables,
} from "generated/graphql";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
interface OrganizationTemplateFormProps {
  loading?: boolean;
  emailTemplate?: BitumioOrganizationEmailTemplateFragment;
  onSubmit: (data: UpdateOrganizationEmailTemplateMutationVariables) => void;
  onSubmitPreview: (data: SendOrganizationEmailTemplatePreviewMutationVariables) => void;
}

const emailTemplateSchema = z
  .object({
    type: z.string().min(1, "Email template type is required"),
    html: z.string().nullable(),
  })
  .superRefine(({ type, html }, ctx) => {
    if (type === OrganizationEmailTemplateType.CUSTOM && !html.trim()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["html"],
        message: "Html content is required",
      });
    }
  });

export default function OrganizationEmailTemplateForm({
  onSubmit,
  onSubmitPreview,
  loading,
  emailTemplate,
}: OrganizationTemplateFormProps) {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<UpdateOrganizationEmailTemplateMutationVariables>({
    resolver: zodResolver(emailTemplateSchema),
    defaultValues: {
      type:
        (emailTemplate?.type as OrganizationEmailTemplateType) ??
        OrganizationEmailTemplateType.DEFAULT,
      html: emailTemplate?.html ?? null,
    },
  });
  const [emailTemplateType, setEmailTemplateType] = useState<OrganizationEmailTemplateType>(
    emailTemplate?.type
  );
  const setTextAreaValue = (e: any) => {
    const tArea = document.querySelector("#email-text-area") as any;
    const pos = tArea.selectionStart;
    const textAreaValue = getValues("html");
    setValue("html", `${textAreaValue.slice(0, pos)}${e.target.value}${textAreaValue.slice(pos)}`);
  };
  return (
    <Card sx={{ px: "25px", py: "30px" }}>
      <MDBox
        role="form"
        component="form"
        onSubmit={handleSubmit(async (data) => {
          await onSubmit(data);
        })}
      >
        <RadioGroup {...register("type")}>
          <FormLabel sx={{ mb: "25px" }}>Template Type</FormLabel>
          {errors?.type?.message && (
            <MDTypography variant="caption" color="error">
              {errors.type?.message}
            </MDTypography>
          )}
          <Grid container spacing={2}>
            {Object.keys(OrganizationEmailTemplateType)
              .sort((a, b) =>
                a === OrganizationEmailTemplateType.DEFAULT ||
                b === OrganizationEmailTemplateType.DEFAULT
                  ? -1
                  : 0
              )
              .map((type, i) => (
                <Card sx={{ width: "25%", mr: "10px" }} key={i} variant="outlined">
                  <CardContent>
                    <FormControlLabel
                      label={capitalCase(type)}
                      control={
                        <Radio
                          inputRef={register("type").ref}
                          checked={getValues("type") === type}
                        />
                      }
                      value={type}
                      onChange={(e: any) => {
                        setEmailTemplateType(e.currentTarget?.value);
                      }}
                    />
                  </CardContent>
                </Card>
              ))}
          </Grid>
        </RadioGroup>
        {emailTemplateType === OrganizationEmailTemplateType.CUSTOM && (
          <Grid container spacing={2} sx={{ pt: "10px" }}>
            <Grid item xs={12}>
              <MDBox sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <FormLabel sx={{ my: "25px" }}>Custom Email HTML.</FormLabel>
                <PlaceHolderSelect type="organization" value="" onChange={setTextAreaValue} />
              </MDBox>
            </Grid>
            {errors?.html?.message && (
              <Grid item xs={12}>
                <MDTypography variant="caption" color="error">
                  {errors.html?.message}
                </MDTypography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextareaAutosize
                {...register("html")}
                minRows={20}
                style={{ minWidth: "100%" }}
                id="email-text-area"
              />
            </Grid>
          </Grid>
        )}
        <MDBox sx={{ display: "flex", justifyContent: "space-between", py: "10px" }}>
          <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
            Save
          </MDButton>
          {emailTemplateType === OrganizationEmailTemplateType.CUSTOM && (
            <MDButton
              variant="gradient"
              color="success"
              type="button"
              disabled={loading}
              onClick={handleSubmit(
                async (data: UpdateOrganizationEmailTemplateMutationVariables) =>
                  onSubmitPreview({ html: data.html })
              )}
            >
              Send Preview
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}
