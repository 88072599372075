import getLocalString from "constants/Localization";
import { useCreatePlantProductTypeMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreatePlantProductType() {
  const [mutation, { loading, data, error }] = useCreatePlantProductTypeMutation({
    refetchQueries: ["getPlantProductTypes"],
  });

  const [createPlantProductType, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createPlantProductType",
    },
    {
      successMessage: getLocalString("organization.create-plant-product-type-success"),
      failureMessage: getLocalString("organization.create-plant-product-type-failure"),
    }
  );

  return [createPlantProductType, context] as const;
}
