import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { Product } from "generated/graphql";
import useDeleteProduct from "hooks/products/useDeleteProduct";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";

export default function DeleteProductMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: Product["id"];
}) {
  const [deleteProduct, { isSuccess }] = useDeleteProduct();

  const acceptFn = useCallback(async () => {
    const { data } = await deleteProduct({
      variables: { id },
    });
    const success = isSuccess(data);

    if (success) {
      closeMenu();
    }

    return success;
  }, []);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you would like to delete this product?"
      />
      <MenuItem onClick={() => setOpen(true)}>Delete Product</MenuItem>
    </>
  );
}
