import { MenuItem } from "@mui/material";
import { NotificationTriggerFragmentFragment } from "generated/graphql";
import useGetNotificationTrigger from "hooks/notifications/useGetNotificationTrigger";
import { Link } from "react-router-dom";

export default function UpdateEmailTriggerMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: NotificationTriggerFragmentFragment["id"];
}) {
  return (
    <MenuItem component={Link} onClick={closeMenu} to={`/email-triggers/edit/${id}`}>
      Update Notification Trigger
    </MenuItem>
  );
}
