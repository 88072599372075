import { useGetTimeBucketsQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetTimeBuckets = () => {
  const { data: graphqlData, error, loading } = useGetTimeBucketsQuery();

  const getData = useCallback((data) => {
    return data?.getTimeBuckets || [];
  }, []);

  const data = useMemo(() => {
    return getData(graphqlData);
  }, [graphqlData, getData]);

  return { loading, data, error } as const;
};
