import { MenuItem } from "@mui/material";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import { User } from "generated/graphql";
import useResendUserInvite from "hooks/users/useResendUserInvite";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";
import { GlobalMessage } from "hooks/notifications/useGlobalMessage";

export default function ResendInviteMenuItem({
  closeMenu,
  id,
}: {
  closeMenu: () => void;
  id: User["id"];
}) {
  const [resendUserInvite, { isSuccess }] = useResendUserInvite();

  const acceptFn = useCallback(async (): Promise<GlobalMessage> => {
    const { data } = await resendUserInvite({ id });
    if (isSuccess(data)) {
      closeMenu();
      return { message: "Successfully resent invite to user." };
    } else {
      return {
        message: "Failed to resend invite to user. Please try again in a few minutes.",
        options: { variant: "error" },
      };
    }
  }, [resendUserInvite, id]);

  const cancelFn = useCallback(async () => {
    closeMenu();
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you would like to resend this invite?"
      />
      <MenuItem onClick={() => setOpen(true)}>Resend Invite</MenuItem>
    </>
  );
}
