import {
  GetOrganizationAddressQueryVariables,
  useGetOrganizationAddressLazyQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetOrganizationAddress() {
  const [query, { data, loading, error }] = useGetOrganizationAddressLazyQuery();

  const getOrganizationAddress = useCallback(
    (values: GetOrganizationAddressQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const address = useMemo(() => {
    return (data && data.getOrganizationAddress) || null;
  }, [data]);

  return [getOrganizationAddress, { data, loading, error, address }] as const;
}
