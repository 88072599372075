import { CustomCreateProposalMutationVariables, ProposalSource } from "generated/graphql";
import { z } from "zod";

export const createProposalSchema = z.object({
  companyId: z.string().min(1, "Please select a company"),
  contactId: z.string().min(1, "Please select a contact"),
  source: z.nativeEnum(ProposalSource).optional(),
});

export const getDefaultValues = (
  initialValues: Partial<CustomCreateProposalMutationVariables>
): Partial<CustomCreateProposalMutationVariables> => ({
  companyId: "",
  contactId: "",
  source: undefined,
  ...initialValues,
});
