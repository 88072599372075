import {
  Typography,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Proposal, Job } from "generated/graphql";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import { StyledTableRow } from "modules/jobs/JobsSchedules/JobsSchedules";

const jobDetailsLabels = [
  "Customer Name",
  "Customer Phone",
  "Customer Email",
  "Customer Billing Address",
  "Contact Firstname",
  "Contact Lastname",
  "Contact Phone",
  "Contact Email",
  "Proposal ID",
  "Job ID",
  "Internal ID",
  "Job Name",
  "Job Address",
  "Job Total",
];

export default function JobsDetailsSummary({
  proposalId,
  job,
}: {
  proposalId: Proposal["id"];
  job: Job;
}) {
  const [jobDetails, setJobDetails] = useState(Array(14).fill(""));

  useEffect(() => {
    const fetchJobDetails = async () => {
      const data = [
        job?.company?.name,
        job?.company?.phone,
        job?.company?.email,
        job?.company?.addressLine1,
        job?.contact?.firstName,
        job?.contact?.lastName,
        job?.contact?.phone,
        job?.contact?.email,
        proposalId,
        job?.id,
        job?.externalId,
        job?.proposalStage?.name,
        job?.address?.line1,
        formatCentsToUSD(job?.proposedOverallTotal) || "",
      ];
      setJobDetails(data);
    };
    fetchJobDetails();
  }, [job, proposalId]);

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow sx={{ backgroundColor: "grey.300" }}>
            <TableCell>Job Details</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {jobDetailsLabels.map((label, index) => (
            <StyledTableRow className={index % 2 === 0 ? "outer even" : "outer odd"}>
              <TableCell sx={{ width: "40%" }}>
                <Typography
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.875em",
                  }}
                >
                  {label}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {jobDetails[index] || ""}
                </Typography>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ margin: "1em" }}>
        <MDButton
          component={Link}
          color="default"
          to={`/proposals/${proposalId}`}
          target="_blank"
          style={{
            border: "1px solid #000",
            padding: "0.5em",
            width: "100%",
            textAlign: "center",
            color: "black",
            marginTop: "-2px",
          }}
        >
          View Proposal
        </MDButton>
      </div>
    </TableContainer>
  );
}
