import { CustomCreateProposalMutationVariables } from "generated/graphql";
import useCustomCreateProposal from "hooks/proposals/useCustomCreateProposal";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";
import CreateProposalForm from "./forms/CreateProposalForm";

const CreateProposal: React.FC<{ companyId?: string }> = ({ companyId }) => {
  const navigate = useNavigate();
  const [customCreateProposal, { isSuccess, getData }] = useCustomCreateProposal();

  const handleSubmitCreateProposal = useCallback(
    async (values: CustomCreateProposalMutationVariables) => {
      const result = await customCreateProposal({ variables: values });
      const success = isSuccess(result.data);
      const data = getData(result);

      if (success) {
        navigate(getRoute("proposals.draft", [["proposalId", data.id]]));
      }

      return { success, data };
    },
    []
  );

  return (
    <div>
      <CreateProposalForm onSubmit={handleSubmitCreateProposal} companyInitialId={companyId} />
    </div>
  );
};

export default CreateProposal;
