import Logo from "assets/images/Black-Bitumio_Full@4x.png";
import { capitalCase } from "change-case";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useSearchParams } from "react-router-dom";

const NotAuthorizedPage = () => {
  const [params] = useSearchParams();
  return (
    <DashboardLayout>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Roboto",
          gap: "10px",
        }}
      >
        <img src={Logo} width="130" height="130" alt="Brand" />
        <p
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "24px",
            textAlign: "center",
            color: "#344767",
          }}
        ></p>
        <div
          style={{
            fontSize: "16px",
            textAlign: "center",
            color: "#344767",
            width: "500px",
            lineHeight: "150%",
          }}
        >
          <p
            style={{
              padding: "15px 0",
            }}
          >
            {" "}
            You do not have permission to view the dashboard. Please select the location you want to
            view from the navigation bar on the left.
          </p>
          <p>{` In order to be able to see this page you need the following ${
            params.get("permission") ? "permission(s)" : "role(s)"
          }`}</p>
          <p style={{ fontWeight: "bold", fontSize: "16px", padding: "15px 0" }}>
            <code>{capitalCase(params.get("permission") || params.get("role"))}</code>
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NotAuthorizedPage;
