import getLocalString from "constants/Localization";
import { useCustomUpdateProposalMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCustomUpdateProposal() {
  const [mutation, { loading, error, data }] = useCustomUpdateProposalMutation({
    refetchQueries: ["getProposal", "customGetProposals"],
  });
  const [updateProposal, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "updateProposal",
    },
    {
      successMessage: getLocalString("proposals.update-success"),
      failureMessage: getLocalString("proposals.update-failure"),
    }
  );

  return [updateProposal, context] as const;
}
