import MDButton from "components/MDButton";
import { Proposal, SetProposalToWonMutationVariables } from "generated/graphql";
import useSetProposalToWon from "hooks/proposals/useSetProposalToWon";
import useSetProposalToWonSilently from "hooks/proposals/useSetProposalToWonSilently";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useCallback } from "react";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MoveToClosedWinIcon from "../../../../assets/images/movetoclosedwin-proposal.svg";

export default function MoveProposalToClosedWon({ id }: { id: Proposal["id"] }) {
  const [setProposalToWon, { isSuccess }] = useSetProposalToWon({ id });
  const [setProposalToWonSilently, { isSuccess: isSuccessSilently }] =
    useSetProposalToWonSilently();

  const handleMoveProposalToClosedWon = useCallback(
    async (variables: SetProposalToWonMutationVariables) => {
      const result = await setProposalToWonSilently({
        variables,
      });

      if (isSuccessSilently(result.data)) {
        return true;
      }
      // TODO Make sure error is related to proposal not being sendable before triggering modal
      setOpen(true);
    },
    [setProposalToWon]
  );

  const acceptFn = useCallback(async (): Promise<boolean> => {
    const { data } = await setProposalToWon({ variables: { id, forceSend: true } });

    if (isSuccess(data)) {
      return true;
    } else {
      return false;
    }
  }, [setProposalToWon, id]);

  const cancelFn = useCallback(async () => {
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Confirmation"
        content="Are you sure you want to mark as closed/won with an empty job size?"
        acceptText="Move to Closed/Won"
      />
      <Tooltip title="Move to Closed/Win">
        <IconButton onClick={() => handleMoveProposalToClosedWon({ id })}>
          <img src={MoveToClosedWinIcon} alt="Move To Closed/Win" />
        </IconButton>
      </Tooltip>
    </>
  );
}
