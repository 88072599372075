import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import { useCallback } from "react";

import MDAlert from "components/MDAlert";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import MDTypography from "components/MDTypography";
import useCreatePlant from "hooks/organization/plants/useCreatePlant";
import Create from "modules/OrganizationPlants/Create";
import { CreatePlantInput } from "generated/graphql";

export default function CreatePlantModal() {
  const { open, onClose, onOpen } = useModalState();

  const [createPlant, { loading, error, isSuccess, getData }] = useCreatePlant();

  const onSubmit = useCallback(
    async (input: CreatePlantInput) => {
      const result = await createPlant({ variables: { input } });
      const data = getData(result);
      const success = isSuccess(result.data);
      if (isSuccess) {
        onClose();
      }
      return { success, data };
    },
    [createPlant]
  );

  return (
    <>
      <MDButton
        type="button"
        variant="gradient"
        color="secondary"
        onClick={() => {
          onOpen();
        }}
      >
        Create Plant
      </MDButton>
      <Modal open={open} onClose={onClose}>
        <MDTypography variant="h6" p={3}>
          Create Plant
        </MDTypography>
        {error && <MDAlert>{parseGraphQLError(error)}</MDAlert>}
        <Create onSubmit={onSubmit} loading={loading} />
      </Modal>
    </>
  );
}
