import { useGenerateUploadJobPhaseCostItemFileUrlMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useGenerateUploadJobPhaseCostItemFileUrl() {
  const [mutation, { loading, error, data }] = useGenerateUploadJobPhaseCostItemFileUrlMutation();
  const [generateUploadJobPhaseCostItemFileUrl, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "generateUploadJobPhaseCostItemFileUrl",
    },
    {
      hideMessage: true,
    }
  );

  return [generateUploadJobPhaseCostItemFileUrl, context] as const;
}
