import { Autocomplete, Skeleton } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDAlert from "components/MDAlert";
import { User, UserStatus } from "generated/graphql";
import useGetOrganizationUsersLazy from "hooks/users/useGetOrganizationUsersLazy";
import { useEffect } from "react";

export default function PickActiveOrganizationUser({
  onChange,
  value,
  label,
}: {
  onChange: (e: any, value: { id: User["id"]; label: string }) => void;
  value: { id?: User["id"]; label?: string };
  label?: string;
}) {
  const [getOrganizationUsers, { users, loading, error, paginatorInfo }] =
    useGetOrganizationUsersLazy();

  useEffect(() => {
    // TODO: Add server search
    getOrganizationUsers({ status: UserStatus.ACTIVE, first: 1000 });
  }, []);

  if (loading) {
    <Skeleton />;
  }

  if (error) {
    return <MDAlert color="error">Could not load active users. Please refresh this page.</MDAlert>;
  }

  const usersOptions = users?.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    id: user.id,
  }));

  return (
    <div style={{ margin: "16px 0" }}>
      <Autocomplete
        // @ts-expect-error
        options={usersOptions}
        defaultValue={value.label}
        // @ts-expect-error
        onChange={onChange}
        renderInput={(params) => (
          <FormField
            {...params}
            label={label || "Select a user to reassign the open proposals to"}
          />
        )}
      />
    </div>
  );
}
