import { Grid } from "@mui/material";
import CompanyPageFormLoader from "components/loaders/CompanyPageFormLoader";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import getLocalString from "constants/Localization";

import { useGetCompany } from "hooks/companies/useGetCompany";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import UpdateCompany from "modules/UpdateCompany/UpdateCompany";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useParams } from "react-router";

export default function UpdateCompanyPage() {
  const { companyId } = useParams();
  const { loading, error, data: company } = useGetCompany({ id: companyId });

  if (loading) {
    return <CompanyPageFormLoader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        {error && (
          <MDAlert color="error">
            {getLocalString("companies.load-failure")}. {error.message}
          </MDAlert>
        )}
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Update Contact
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        {!error && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UpdateCompany company={company} />
            </Grid>
          </Grid>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
