import {
  CustomGetProposalsQuery,
  CustomGetProposalsQueryVariables,
  useCustomGetProposalsQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export type CustomProposals = CustomGetProposalsQuery["getProposals"];

export const getProposalsCacheKey = "GetProposals";

export const useGetProposals = (variables: CustomGetProposalsQueryVariables) => {
  const {
    loading,
    error,
    data: graphqlData,
    fetchMore: more,
  } = useCustomGetProposalsQuery({
    variables,
  });

  const fetchMore = useCallback(
    async ({ page }: { page: number }) => {
      return more({
        variables: {
          page,
        },
      });
    },
    [more]
  );

  const getData = useCallback(({ data }: { data?: CustomGetProposalsQuery }) => {
    return data?.getProposals?.data || null;
  }, []);

  const getPaginatorInfo = useCallback(({ data }: { data?: CustomGetProposalsQuery }) => {
    return data?.getProposals?.paginatorInfo || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData, getData]);

  const paginatorInfo = useMemo(() => {
    return getPaginatorInfo({ data: graphqlData });
  }, [graphqlData]);

  return { data, loading, error, paginatorInfo, fetchMore, getData, getPaginatorInfo } as const;
};
