import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import { GetYearToDateStatsQuery } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";

export default function LastMonthVsThisMonthStats({ defaultDashboardStatsData }) {
  const getPercentageDifference = (base, comparator) => {
    const sign = base > comparator ? "+" : "";
    // return 0 if all values are 0 to avoid NaN
    // return 100 if previous value is a zero to avoid Infinity
    let value =
      !!!base && !!!comparator ? 0 : (((base - comparator) / comparator) * 100).toFixed(2);
    value = value === "Infinity" ? 100 : value;

    return `${sign}${value}%`;
  };

  return (
    <MDBox>
      <MDBox textAlign="center" my={2}>
        <MDTypography variant="h4">Month to Date</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DefaultStatisticsCard
              title="Bids Sent"
              count={defaultDashboardStatsData?.currentMonth.sentBids}
              percentage={{
                color:
                  defaultDashboardStatsData?.currentMonth.sentBids >
                  defaultDashboardStatsData?.previousMonth.sentBids
                    ? "blue"
                    : "error",
                value: getPercentageDifference(
                  defaultDashboardStatsData?.currentMonth.sentBids,
                  defaultDashboardStatsData?.previousMonth.sentBids
                ),
                label: "than last month",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DefaultStatisticsCard
              title="Bids Closed"
              count={defaultDashboardStatsData?.currentMonth.closedBids}
              percentage={{
                color:
                  defaultDashboardStatsData?.currentMonth.closedBids >
                  defaultDashboardStatsData?.previousMonth.closedBids
                    ? "blue"
                    : "error",
                value: getPercentageDifference(
                  defaultDashboardStatsData?.currentMonth.closedBids,
                  defaultDashboardStatsData?.previousMonth.closedBids
                ),
                label: "than last month",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DefaultStatisticsCard
              title="Revenue Closed"
              count={formatCentsToUSD(defaultDashboardStatsData?.currentMonth.closedRevenue)}
              percentage={{
                color:
                  defaultDashboardStatsData?.currentMonth.closedRevenue >
                  defaultDashboardStatsData?.previousMonth.closedRevenue
                    ? "blue"
                    : "error",
                value: getPercentageDifference(
                  defaultDashboardStatsData?.currentMonth.closedRevenue,
                  defaultDashboardStatsData?.previousMonth.closedRevenue
                ),
                label: "than last month",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DefaultStatisticsCard
              title="Closing Rate"
              count={defaultDashboardStatsData?.currentMonth.closedRate.toFixed(2)}
              percentage={{
                color:
                  defaultDashboardStatsData?.currentMonth.closedRate >
                  defaultDashboardStatsData?.previousMonth.closedRate
                    ? "blue"
                    : "error",
                value: getPercentageDifference(
                  defaultDashboardStatsData?.currentMonth.closedRate,
                  defaultDashboardStatsData?.previousMonth.closedRate
                ),
                label: "than last month",
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}
