// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Images
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import useValidateLogin from "hooks/signin/useValidateLogin";
import MDInput from "components/MDInput";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import MDTypography from "components/MDTypography";
import { zodEmailValidator } from "utils/validations/isValidEmail";
import { ValidateLoginInput } from "generated/graphql";
import MDButton from "components/MDButton";
import EmailUnverified from "components/EmailUnverified/EmailUnverified";
import InvalidEmail from "components/InvalidEmail/InvalidEmail";
import { useForm } from "react-hook-form";
import InvitedEmail from "components/InvitedEmail/InvitedEmail";
import SignInForm from "modules/SignInForm/SignInForm";
import MDAlert from "components/MDAlert";
import { Link } from "react-router-dom";

const schema = z.object({
  email: zodEmailValidator,
});

export default function SignInPage(): JSX.Element {
  const [validateLogin, { error, loading, status, email }] = useValidateLogin();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ValidateLoginInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: email ?? "",
    },
  });

  const showInfo = ["unverified", "invalid", "invited"].includes(status);

  return (
    <IllustrationLayout title="Sign In" description="Type your email and hit continue to validate">
      {error && (
        <MDAlert color="error">
          There has been an error. Please refresh this page and try again.
        </MDAlert>
      )}
      {showInfo && (
        <MDBox mb={2}>
          {status === "unverified" && <EmailUnverified email={email as string} />}
          {status === "invalid" && <InvalidEmail />}
          {status === "invited" && <InvitedEmail />}
        </MDBox>
      )}
      {status === "validated" && <SignInForm email={email as string} />}
      {status !== "validated" && (
        <div data-testid="sign-in-form">
          <MDBox component="form" role="form" onSubmit={handleSubmit(validateLogin)}>
            <MDInput
              variant="standard"
              placeholder="Email"
              fullWidth
              {...register("email")}
              error={errors.email}
            />
            {errors.email && (
              <MDTypography color="error" variant="caption">
                {errors.email.message}
              </MDTypography>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                Continue
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox mt={4} textAlign="center">
            <MDTypography variant="body2" component={Link} to="/forgot-password">
              Forgot password?
            </MDTypography>
          </MDBox>
        </div>
      )}
    </IllustrationLayout>
  );
}
