import MDAlert from "components/MDAlert";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useEmailTriggersTable from "hooks/notifications/useEmailTriggersTable";

export default function NotificationList() {
  // Get the notification Triggers list
  const { loading, error, tableData } = useEmailTriggersTable();

  return (
    <>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={10} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && <DataTable table={tableData} pagination={null} filtering={null} />}
    </>
  );
}
