import { OrganizationProductType } from "generated/graphql";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";

export default function ProductTypeProductsLink({
  row: { values },
}: {
  row: { values: OrganizationProductType };
}) {
  return (
    <Link to={getRoute("product-types.view", [["productTypeId", values.id]])}>View Products</Link>
  );
}
