import getLocalString from "constants/Localization";
import { useGenerateReportMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

const useGenerateReport = () => {
  const [mutation, { loading, data, error }] = useGenerateReportMutation();
  const [generateReport, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "generateReport",
    },
    {
      successMessage: getLocalString("organization.generate-report-success"),
      failureMessage: getLocalString("organization.generate-report-failure"),
    }
  );

  return [generateReport, { ...context, data }] as const;
};

export default useGenerateReport;
