import getLocalString from "constants/Localization";
import { useDeleteProposalFileMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";
import React from "react";

export default function useDeleteProposalFile() {
  const [mutation, { loading, data, error }] = useDeleteProposalFileMutation();

  const [deleteProposalFile, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "deleteProposalFile",
    },
    {
      successMessage: getLocalString("proposals.delete-proposal-file-success"),
      failureMessage: getLocalString("proposals.delete-proposal-file-failure"),
    }
  );

  return [deleteProposalFile, context] as const;
}
