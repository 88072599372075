import { FinishRegistrationInput, useFinishRegistrationMutation, User } from "generated/graphql";
import { useCallback } from "react";

export default function useFinishRegistration() {
  const [finishRegistrationMutation, { data, loading, error }] = useFinishRegistrationMutation();

  const finishRegistration = useCallback(
    (values: FinishRegistrationInput) => {
      finishRegistrationMutation({ variables: { input: values } });
    },
    [finishRegistrationMutation]
  );

  const success = data && typeof data.finishRegistration?.email !== "undefined";
  const user = ((data && data.finishRegistration) || null) as User | null;

  return [finishRegistration, { data, loading, error, success, user }] as const;
}
