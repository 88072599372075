import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import PlantActionsCell from "modules/OrganizationPlants/ActionsCell";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import useGetPlants from "./useGetPlants";

export default function usePlantsTable() {
  const { filtering, offset, sorting } = usePaginatedVariables();
  const { data, loading, error, paginatorInfo, fetchMore, getData, getPaginatorInfo } =
    useGetPlants({
      page: offset.page,
      first: offset.first,
      search: filtering.debouncedSearchTerm,
    });
  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Plant Name",
        accessor: "name",
        Cell: ({ row, value }) => (
          <Link style={{ color: "inherit" }} to={`/organization/plants/${row.original.id}`}>
            {value}
          </Link>
        ),
        width: "30%",
      },
      { Header: "Address Line 1", accessor: "addressLine1", width: "30%" },
      { Header: "Zip", accessor: "addressZip" },
      {
        Header: "Actions",
        accessor: "id",
        Cell: PlantActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
  } as const;
}
