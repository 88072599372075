import getLocalString from "constants/Localization";
import {
  CustomGetProductsDocument,
  GetProductsDocument,
  OrganizationProductType,
  useCustomCreateProductMutation,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";

import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCreateProduct({
  productTypeId,
}: {
  productTypeId?: OrganizationProductType["id"];
} = {}) {
  const { offset } = usePaginatedVariables();
  const [mutation, { data, loading, error }] = useCustomCreateProductMutation({
    refetchQueries: [
      {
        query: GetProductsDocument,
        variables: {
          page: offset.page,
          first: offset.first,
        },
      },
      {
        query: CustomGetProductsDocument,
        variables: {
          page: offset.page,
          first: offset.first,
        },
      },
    ],
  });

  const [createProduct, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "createProduct",
    },
    {
      successMessage: getLocalString("products.create-success"),
      failureMessage: getLocalString("products.create-failure"),
    }
  );

  return [createProduct, context] as const;
}
