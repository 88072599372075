import MDButton from "components/MDButton";
import { Proposal, ProposalStage } from "generated/graphql";
import { useCallback, useState } from "react";
import SelectStageProduct from "./SelectStageProduct";

export default function AddProductToStage({
  stage,
  onSuccess,
  setExpandedProduct,
  mode,
  setMode,
}: {
  stage: ProposalStage;
  onSuccess: (proposal: Proposal) => void;
  setExpandedProduct: (expandedProduct: string | false) => void;
  mode: "button" | "adding";
  setMode: (mode: "button" | "adding") => void;
}) {
  const handleSuccess = useCallback(
    (props) => {
      setMode("button");
      onSuccess(props);
    },
    [onSuccess, setMode]
  );

  if (mode === "button") {
    return (
      <MDButton
        color="blue"
        onClick={() => {
          setMode("adding");
          setExpandedProduct(false);
        }}
      >
        Add a Product to Stage
      </MDButton>
    );
  }

  return <SelectStageProduct stage={stage} onSuccess={handleSuccess} />;
}
