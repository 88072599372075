import { GetCrewQueryVariables, useGetCrewLazyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetCrew() {
  const [query, { data, loading, error }] = useGetCrewLazyQuery();

  const getCrew = useCallback(
    (values: GetCrewQueryVariables) => {
      query({
        variables: values,
      });
    },
    [query]
  );

  const crew = useMemo(() => {
    return (data && data.getCrew) || null;
  }, [data]);

  return [getCrew, { data, loading, error, crew }] as const;
}
