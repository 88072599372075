import { useFieldArray, useForm } from "react-hook-form";

import { useContext, useEffect } from "react";

import ProposalContext from "modules/proposals/providers/ProposalContextProvider";

import _ from "lodash";
import TransferTruckRow from "./TransferTruckRow";
import useAddTruckToProposalProduct from "hooks/proposals/useAddTruckToProposalProduct";
import useRemoveTruckFromProposalProduct from "hooks/proposals/useRemoveTruckFromProposalProduct";

const TransferTrucksSubFormTableBody = ({
  transferTrucksFields,
  product,
  productTransferTrucks,
}) => {
  const { control, reset } = useForm({
    defaultValues: {
      transferTrucks: transferTrucksFields,
    },
  });

  useEffect(() => reset({ transferTrucks: transferTrucksFields }), [transferTrucksFields]);

  const [addTruckToProposalProduct] = useAddTruckToProposalProduct();
  const [removeTruckFromProposalProduct] = useRemoveTruckFromProposalProduct();

  const handleSubmit = (input) => {
    addTruckToProposalProduct({
      variables: { input },
    });
  };

  const handleRemoveTruck = (id) => removeTruckFromProposalProduct({ variables: { id } });

  const { fields } = useFieldArray({
    control,
    name: "transferTrucks",
  });

  const { inViewMode } = useContext(ProposalContext);

  return (
    <>
      {fields.map((row, i) => (
        <TransferTruckRow
          row={row}
          idx={i}
          productTransferTrucks={productTransferTrucks}
          product={product}
          key={row.id}
          inViewMode={inViewMode}
          handleSubmit={handleSubmit}
          handleRemoveTruck={handleRemoveTruck}
        />
      ))}
    </>
  );
};

export default TransferTrucksSubFormTableBody;
