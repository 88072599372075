import Timeline from "components/Timeline/Timeline";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Proposal } from "generated/graphql";
import { useMemo, useContext } from "react";
import { capitalCase } from "change-case";
import BitumioContext from "providers/BitumioContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function ProposalActivityLog({ proposal }: { proposal: Proposal }) {
  const { signedInUser } = useContext(BitumioContext);

  const timeline = useMemo(() => {
    const _merged = [...proposal.events, ...proposal.statusChanges];
    const _sorted = _merged.sort(function compareFn(a, b) {
      const aTime = new Date(a.__typename == "ProposalEvent" ? a.createdAt : a.changeAt).getTime();
      const bTime = new Date(b.__typename == "ProposalEvent" ? b.createdAt : b.changeAt).getTime();

      return aTime === bTime ? 0 : aTime > bTime ? -1 : 1;
    });
    return _sorted.map((value) => {
      return {
        ...value,
        side: value.__typename === "ProposalEvent" ? "right" : "left",
      };
    });
  }, [proposal.events, proposal.statusChanges]);

  const timezone = useMemo(() => {
    return signedInUser.organization?.timezone ?? dayjs.tz.guess() ?? "America/Chicago";
  }, [signedInUser]);

  return (
    <MDBox>
      <MDTypography variant="h5" mb={5}>
        Activity Log
      </MDTypography>
      <Timeline>
        {timeline.map((timelineItem, i) => (
          <div className={`container ${timelineItem.side}`} key={i}>
            <div className="content">
              <h2>
                {capitalCase(
                  timelineItem.__typename == "ProposalEvent"
                    ? timelineItem.action
                    : timelineItem.status
                )}
              </h2>
              <p>
                {timelineItem.__typename == "ProposalEvent"
                  ? dayjs.utc(timelineItem.createdAt).tz(timezone).format("MMMM D, YYYY [at] h:mma")
                  : dayjs.utc(timelineItem.changeAt).tz(timezone).format("MMMM D, YYYY [at] h:mma")}
              </p>
            </div>
          </div>
        ))}
      </Timeline>
    </MDBox>
  );
}
