import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";

export const USTimezones: SelectOptions = [
  {
    label: "Hawaii-Aleutian Time UTC-10",
    value: "America/Adak",
  },
  {
    value: "America/Anchorage",
    label: "Alaska Time UTC-9",
  },
  {
    value: "America/Los_Angeles",
    label: "Pacific Time UTC-8",
  },
  {
    value: "America/Denver",
    label: "Mountain Time UTC-7",
  },
  {
    value: "America/Chicago",
    label: "Central Time UTC-6",
  },
  {
    value: "America/New_York",
    label: "Eastern Time UTC-5",
  },
];
