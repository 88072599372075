import getLocalString from "constants/Localization";
import { useCustomUpdateProposalStageMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCustomUpdateProposalStage() {
  const [mutation, { loading, data, error }] = useCustomUpdateProposalStageMutation();
  const [customUpdateProposalStage, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "updateProposalStage",
    },
    {
      successMessage: getLocalString("proposals.add-stage-success"),
      failureMessage: getLocalString("proposals.add-stage-failure"),
    }
  );

  return [customUpdateProposalStage, context] as const;
}
