import MDBox from "components/MDBox";
import {
  OrganizationProposalContent,
  UpdateOrganizationProposalContentMutationVariables,
} from "generated/graphql";
import useGetOrganizationProposalContent from "hooks/organization/useGetorganizationProposalContent";
import FormInputWYSIWYG from "./FormInputWYSIWYG";
import useGetOrganization from "hooks/organization/useGetOrganization";
import ProposalDefaults from "./ProposalDefaults";

interface ProposalSettingsListProps {
  loading: boolean;
  onSubmit: (values: UpdateOrganizationProposalContentMutationVariables) => void;
}

export default function ProposalSettingsList({
  onSubmit,
  loading,
}: Partial<ProposalSettingsListProps>) {
  const { proposalContent, loading: getContentLoading } = useGetOrganizationProposalContent();
  const { data } = useGetOrganization();

  if (getContentLoading) {
    return null;
  }

  const proposalContentByType: {
    [key: string]: OrganizationProposalContent;
  } = {};

  proposalContent.forEach((content) => (proposalContentByType[content.type] = content));

  return (
    <MDBox p={3} maxWidth="1150px" mx="auto" mt={1}>
      {data?.proposalDefaults && <ProposalDefaults proposalDefaults={data.proposalDefaults} />}
      {["ACCEPTANCE", "STATEMENT_OF_WORK", "TERMS", "TITLE_PAGE", "PROJECT_SUMMARY"].map((type) => {
        const content = proposalContentByType[type];
        return (
          <FormInputWYSIWYG
            key={type}
            type={type}
            content={content}
            onSubmit={onSubmit}
            loading={loading}
          />
        );
      })}
    </MDBox>
  );
}
