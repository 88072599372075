import getLocalString from "constants/Localization";
import { useCustomAddProductToProposalStageMutation } from "generated/graphql";
import useBitumioMutation from "hooks/useBitumioMutation";

export default function useCustomAddProductToProposalStage() {
  const [mutation, { loading, error, data }] = useCustomAddProductToProposalStageMutation();
  const [customAddProductToProposalStage, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "addProductToProposalStage",
    },
    {
      successMessage: getLocalString("proposals.add-product-to-proposal-stage-success"),
      failureMessage: getLocalString("proposals.add-product-to-proposal-stage-failure"),
    }
  );

  return [customAddProductToProposalStage, context] as const;
}
