import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MDBox from "components/MDBox";
import useGetOrganizationApiKeys from "hooks/products/useGetOrganizationApiKeys";
import { useCreateOrganizationApiKey } from "hooks/organization/useCreateOrganizationApiKey";
import { useDeleteOrganizationApiKey } from "hooks/organization/useDeleteOrganizationApiKey";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";

interface ApiKey {
  id: string;
  description: string;
  apiKey: string;
}

interface ApiKeyPops {
  apiKeys?: ApiKey[];
  orderBy?: string;
  order?: "asc" | "desc";
  handleRequestSort?: (property: string) => void;
  sortedApiKeys?: ApiKey[];
}

const ApiKeys: React.FC<ApiKeyPops> = () => {
  const { organizationApiKeys, loading, error, refetch } = useGetOrganizationApiKeys();
  const [deleteApiKey] = useDeleteOrganizationApiKey();

  const [createApiKey] = useCreateOrganizationApiKey();

  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [sortedApiKeys, setSortedApiKeys] = React.useState<ApiKey[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [description, setApiKeyDescription] = useState("");

  useEffect(() => {
    const sortKeys = (array: ApiKey[]) => {
      return array.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return order === "asc" ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    };
    setSortedApiKeys(sortKeys(organizationApiKeys));
  }, [organizationApiKeys, order, orderBy]);

  const handleAddKey = async () => {
    try {
      const result = await createApiKey({ variables: { description } });
      if (result.data && result.data.CreateOrganizationApiKey.success) {
        await refetch();
      } else {
        console.error("Failed to create API key");
      }
    } catch (error) {
      console.error("Error creating API key", error);
    }
    setModalOpen(false);
    setApiKeyDescription("");
  };

  const handleRequestSort = (property: string) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleDelete = async (id: string) => {
    try {
      const result = await deleteApiKey({ variables: { id } });
      if (result.data && result.data.DeleteOrganizationApiKey.success) {
        console.log("API key deleted successfully");
        setSortedApiKeys((prevApiKeys) => prevApiKeys.filter((apiKey) => apiKey.id !== id));
      }
    } catch (error) {
      console.error("Error deleting API key", error);
    }
  };

  const handleCopy = (apiKey: string) => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        console.log("API key copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy API key", error);
      });
  };

  const formatApiKey = (apiKey) => {
    if (apiKey.length <= 6) {
      return apiKey;
    }
    const lastSix = apiKey.slice(-6);
    const maskedPart = "X".repeat(apiKey.length - 6);

    return maskedPart + lastSix;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Grid container my={2} px={2} sx={{ justifyContent: "space-between" }}>
        <Grid item xs={12} lg={7.7} mt={2}>
          <MDTypography variant="h4">API Keys</MDTypography>
        </Grid>
        <Grid item xs={12} lg={3} mt={2} sx={{ textAlign: "end" }}>
          <MDButton color="blue" onClick={() => setModalOpen(true)}>
            Add Keys
          </MDButton>
          <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle>Add API Key</DialogTitle>
            <DialogContent sx={{ display: "flex" }}>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                value={description}
                onChange={(e) => setApiKeyDescription(e.target.value)}
              />
              <DialogActions sx={{ marginLeft: 3 }}>
                <MDButton onClick={handleAddKey} color="blue">
                  Add
                </MDButton>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
      <Table aria-label="simple table">
        <TableHead sx={{ display: "table-header-group", backgroundColor: "#666" }}>
          <TableRow>
            <TableCell align="left" sx={{ fontSize: "14px" }}>
              <TableSortLabel
                active={orderBy === "description"}
                direction={orderBy === "description" ? order : "asc"}
                onClick={() => handleRequestSort("description")}
              >
                <MDBox display="flex" alignItems="center" sx={{ color: "#fff" }}>
                  API Key Description
                </MDBox>
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ fontSize: "14px" }}>
              <TableSortLabel
                active={orderBy === "apiKey"}
                direction={orderBy === "apiKey" ? order : "asc"}
                onClick={() => handleRequestSort("apiKey")}
              >
                <MDBox display="flex" sx={{ color: "#fff" }}>
                  Key Value
                </MDBox>
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={{ fontSize: "14px" }}>
              <MDBox display="flex" sx={{ color: "#fff", justifyContent: "end" }}>
                Delete Key
              </MDBox>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ color: "#fff" }}>
          {sortedApiKeys.map((apiKey) => (
            <TableRow key={apiKey.id} sx={{ backgroundColor: "grey.300" }}>
              <TableCell>{apiKey.description}</TableCell>
              <TableCell>
                <MDBox display="flex" sx={{ justifyContent: "center" }}>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {formatApiKey(apiKey.apiKey)}
                  </Typography>
                  <Tooltip title="Copy API Key">
                    <IconButton
                      color="default"
                      onClick={() => handleCopy(apiKey.apiKey)}
                      sx={{ p: 0, fontSize: 17, marginLeft: 0.5 }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </TableCell>
              <TableCell sx={{ textAlign: "end" }}>
                <IconButton color="error" onClick={() => handleDelete(apiKey.id.toString())}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApiKeys;
