import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Generate from "modules/Reports/Generate";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import Footer from "partials/Footer/Footer";
import { useForm } from "react-hook-form";

const Reports = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Download Reports
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Generate />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Reports;
