import { Company, CustomGetCompanyQuery, useCustomGetCompanyQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useGetCompany = ({ id }: { id: Company["id"] }) => {
  const {
    loading,
    error,
    data: graphqlData,
  } = useCustomGetCompanyQuery({
    variables: {
      id,
    },
  });

  const getData = useCallback(({ data }: { data: CustomGetCompanyQuery }) => {
    return data?.getCompany || null;
  }, []);

  const data = useMemo(() => {
    return getData({ data: graphqlData });
  }, [graphqlData]);

  return { loading, error, data, getData };
};
