import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { Job, Proposal } from "generated/graphql";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import ChangeOrderActionsCell from "./ChangeOrderActionsCell";
import MDTypography from "components/MDTypography";

export default function ChangeOrders({
  proposal,
  changeOrders,
}: {
  proposal?: Proposal;
  changeOrders?: Job["changeOrders"];
}) {
  const changeOrdersField = changeOrders || proposal.changeOrders;
  return (
    <>
      <MDTypography variant="h5" my={2}>
        Change Orders
      </MDTypography>
      <TableContainer component={Paper}>
        <Table
          size="small"
          sx={{
            [`& .${tableCellClasses.head}`]: {
              backgroundColor: "#666",
              color: "#fff",
            },
            [`& .${tableCellClasses.body}`]: {
              fontSize: 14,
            },
            maxWidth: "100%",
          }}
        >
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {changeOrdersField &&
              changeOrdersField.map((changeOrder) => (
                <TableRow key={changeOrder.id}>
                  <TableCell>{changeOrder.externalId}</TableCell>
                  <TableCell>{changeOrder.status}</TableCell>
                  <TableCell>{formatCentsToUSD(changeOrder.overallTotalAfterTaxes)}</TableCell>
                  <TableCell>
                    <ChangeOrderActionsCell row={{ values: changeOrder }} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
