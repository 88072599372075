import lengthEndAdornment from "constants/lenghtEndAdornment";
import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "applicationRate",
    label: "Asphalt Depth in Inches",
    inputProps: { ...lengthEndAdornment },
  },
];

export default function AsphaltAttributesForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
