import getLocalString from "constants/Localization";
import { sortDirectionMap } from "constants/sortDirectionMap";
import { GetCompaniesDocument, useCreateCompanyMutation } from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";

import useBitumioMutation from "hooks/useBitumioMutation";
import {
  companiesInitialSortDirectionValue,
  companiesInitialSortKeyValue,
  companiesSortKeyMap,
} from "./useCompaniesTable";

export default function useCreateCompany() {
  const { offset, filtering, sorting } = usePaginatedVariables();
  const [mutation, { loading, data, error }] = useCreateCompanyMutation({
    refetchQueries: [
      {
        query: GetCompaniesDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
          sortKey: companiesSortKeyMap[sorting.sortKey] || companiesInitialSortKeyValue,
          sortDirection:
            sortDirectionMap[sorting.sortDirection] || companiesInitialSortDirectionValue,
        },
      },
    ],
  });

  const [createCompany, context] = useBitumioMutation(
    {
      mutation,
      loading,
      error,
      data,
      key: "createCompany",
    },
    {
      successMessage: getLocalString("companies.create-success"),
      failureMessage: getLocalString("companies.create-failure"),
      showValidationErrors: true,
    }
  );

  return [createCompany, context] as const;
}
