import {
  CustomGetNotificationTriggerQueryVariables,
  useCustomGetNotificationTriggerLazyQuery,
  useCustomGetNotificationTriggerQuery,
} from "generated/graphql";
import { useCallback, useMemo } from "react";

export default function useGetNotificationTrigger(
  variables: CustomGetNotificationTriggerQueryVariables
) {
  const {
    loading,
    error,
    data: graphQLData,
  } = useCustomGetNotificationTriggerQuery({
    variables,
  });

  const data = useMemo(() => {
    return graphQLData?.getNotificationTrigger || null;
  }, [graphQLData]);

  return { error, loading, data } as const;
}
