import React, { useCallback } from "react";
import { useBitumioContext } from "providers/BitumioContext";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import getLocalString from "constants/Localization";

export default function useDownloadQbcFile() {
  const [{ showLoader, hideLoader }] = useBitumioContext();
  const { show } = useGlobalMessage();

  const getQbcFile = useCallback(async () => {
    try {
      showLoader();
      const response = await fetch(
        import.meta.env.VITE_BACKEND_API_BASE_URL + `/api/quickbooks/qbc-file`,
        {
          credentials: "include",
        }
      );

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "qbc-file.qwc";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      show({
        message: getLocalString("generic.failure"),
        options: { variant: "error" },
      });
      return "";
    } finally {
      hideLoader();
    }
  }, []);

  const downloadQbcFile = useCallback(() => {
    return getQbcFile();
  }, [getQbcFile]);

  return [downloadQbcFile];
}
