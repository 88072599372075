export const shortenLongDescription = (text: string, limit: number): string => {
  if (text?.length > limit) {
    return `${text.substr(0, limit - 4)}...`;
  }
  return text;
};

export const stripHtml = (text: string | null): string | null => {
  if (!text) return null;
  const div = document.createElement("DIV");
  div.innerHTML = text;
  return div.textContent || div.innerText || "";
};
