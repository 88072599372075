import React from "react";
import { getRoutes } from "utils/routing";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes";

export default React.memo(function AppRoutes() {
  return (
    <Routes>
      {getRoutes(routes as unknown as any[])}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});
