import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import View from "modules/jobs/View";
import useGetJobLazy from "hooks/jobs/useGetJobLazy";
import { Grid, Skeleton } from "@mui/material";
import { useEffect } from "react";
import MDTypography from "components/MDTypography";
import { Job } from "generated/graphql";

export default function JobInfoModal({ jobId }: { jobId: string }) {
  const { open, onClose, onOpen } = useModalState();
  const [getJob, { loading: jobLoading, error: jobError, job }] = useGetJobLazy();

  useEffect(() => {
    getJob(jobId);
  }, [jobId]);

  return (
    <>
      <MDBox
        sx={{
          button: {
            padding: "6px",
            width: "120px",
          },
        }}
      >
        <MDButton type="button" color="blue" onClick={onOpen}>
          Job Info
        </MDButton>
      </MDBox>
      <Modal open={open} onClose={onClose}>
        <MDBox my={3} px="20px" overflow={"auto"}>
          <MDBox mb={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h4" fontWeight="medium">
                  Update Job {job && `#${job.externalId}`}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {job && <View job={job as Job} />}
              {!job && <Skeleton />}
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
    </>
  );
}
