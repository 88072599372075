import MDButton from "components/MDButton";
import { Proposal, ProposalStatus } from "generated/graphql";
import useSetProposalToDraft from "hooks/proposals/useSetProposalToDraft";
import { useNavigate } from "react-router";
import { getRoute } from "utils/routing";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ProposalIcon from "../../../../assets/images/update-proposal.svg";

export default function MoveProposalToDraft({
  id,
  status,
}: {
  id: Proposal["id"];
  status: Proposal["status"];
}) {
  const navigate = useNavigate();
  const [setProposalToDraft, { isSuccess }] = useSetProposalToDraft({ id });
  const isDisabled = status !== ProposalStatus.SENT && status !== ProposalStatus.DRAFT;

  const tooltipTitle =
    status === ProposalStatus.DRAFT ? "Update Proposal" : "Move to Draft and Update Proposal";

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        disabled={isDisabled}
        onClick={async () => {
          if (status === ProposalStatus.DRAFT) {
            navigate(getRoute("proposals.draft", [["proposalId", id]]));
          }
          if (status === ProposalStatus.SENT) {
            const result = await setProposalToDraft({
              variables: { id },
            });
            const success = isSuccess(result.data);
            if (success) {
              navigate(getRoute("proposals.draft", [["proposalId", id]]));
            }
          }
        }}
      >
        <img
          src={ProposalIcon}
          alt="Move to Draft and Update"
          style={{
            filter: isDisabled ? "invert(70%) hue-rotate(120deg)" : "invert(0%) hue-rotate(120deg)",
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
