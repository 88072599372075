import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import useResetPassword from "hooks/signup/useResetPassword";
import IllustrationLayout from "layouts/IllustrationLayout/IllustrationLayout";
import ResetPasswordForm from "modules/ResetPasswordForm/ResetPasswordForm";
import { getRoute } from "utils/routing";
import { Link } from "react-router-dom";

export default function ResetPasswordPage() {
  const [resetPassword, { loading, error, failure, success, email, token, invalid }] =
    useResetPassword();

  return (
    <IllustrationLayout
      title={"Reset Password"}
      description={success ? "Your password has been reset" : "Enter your new password"}
    >
      {error && (
        <MDAlert color="error">There has been an error. Please try again in a few minutes.</MDAlert>
      )}
      {invalid && (
        <MDAlert color="warning">
          Invalid link. Please check your email and follow the link again.
        </MDAlert>
      )}
      {failure && (
        <MDAlert color="warning">
          Failed to reset password. Please try again in a few minutes.
        </MDAlert>
      )}
      {success && (
        <MDTypography textAlign="center">
          <MDTypography
            component={Link}
            to={getRoute("signin")}
            fontWeight="bold"
            color="info"
            textGradient
          >
            Sign in
          </MDTypography>{" "}
          to continue
        </MDTypography>
      )}
      {!success && (
        <ResetPasswordForm
          loading={loading}
          onSubmit={resetPassword}
          email={email as string}
          token={token as string}
        />
      )}
    </IllustrationLayout>
  );
}
