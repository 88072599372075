import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useCreateEquipmentPiece from "hooks/organization/useCreateEquipmentPiece";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import { useCallback } from "react";
import Create from "modules/OrganizationEquipmentPieces/Create";
import MDAlert from "components/MDAlert";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import MDTypography from "components/MDTypography";
import { CreateEquipmentPieceInput, EquipmentPieceChargeType } from "generated/graphql";

export default function CreateEquipmentPieceModal({
  title = "Create Equipment Piece",
}: {
  title?: string;
}) {
  const { open, onClose, onOpen } = useModalState();
  const [createEquipmentPiece, { loading, error, isSuccess, getData }] = useCreateEquipmentPiece();

  const onSubmit = useCallback(
    async (input: CreateEquipmentPieceInput) => {
      const result = await createEquipmentPiece({
        variables: {
          input: {
            ...input,
            rate: Math.floor(input.rate * 100),
            fuelSurcharge: Math.floor(input.fuelSurcharge * 100),
          },
        },
      });
      const data = getData(result);
      const success = isSuccess(result.data);
      if (isSuccess) {
        onClose();
      }
      return { success, data };
    },
    [createEquipmentPiece]
  );

  return (
    <>
      <MDBox my={4}>
        <MDButton type="button" variant="gradient" color="secondary" onClick={onOpen}>
          {title}
        </MDButton>
      </MDBox>
      <Modal open={open} onClose={onClose}>
        <MDTypography variant="h6" p={3}>
          Create Equipment Piece
        </MDTypography>
        {error && <MDAlert>{parseGraphQLError(error)}</MDAlert>}
        <Create onSubmit={onSubmit} loading={loading} />
      </Modal>
    </>
  );
}
