import FormField from "components/FormField/FormField";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { UpdateProposalProductInputSchema } from "DDD/action-objects/StageProductUpdate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCallback, useContext, useMemo } from "react";
import {
  AdditionalCost,
  AdditionalCostFeeType,
  Proposal,
  ProposalAdditionalCost,
} from "generated/graphql";
import { Checkbox, Grid, Icon, IconButton, TableFooter } from "@mui/material";
import StateProductContext from "../StageProductContext";
import useAddProposalAdditionalCost from "hooks/proposals/useAddProposalAdditionalCost";
import AddAdditionalCost from "modules/proposals/additionalCosts/AddAditionalCost";
import { ProposalContext } from "modules/proposals/providers/ProposalContextProvider";
import { capitalCase } from "change-case";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import CurrencyFormField from "components/CurrencyFormField/CurrencyFormField";
import currencyEndAdornment from "constants/currencyEndAdornment";
import useAddProposalAdditionalCosts from "hooks/proposals/useAddProposalAdditionalCosts";

function AdditionalCostsSubForm() {
  const { inViewMode } = useContext(ProposalContext);
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useFormContext<UpdateProposalProductInputSchema>();
  const { product, onSubmit } = useContext(StateProductContext);
  const { fields, remove } = useFieldArray({
    control,
    name: "additionalCosts",
    keyName: "key",
  });

  const [addProposalAdditionalCosts, { isSuccess }] = useAddProposalAdditionalCosts();

  const additionalCostsById = useMemo(() => {
    return product.additionalCosts.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      };
    }, {} as Record<string, ProposalAdditionalCost | undefined>);
  }, [product.additionalCosts]);

  const onAdd = useCallback(
    async (ids, setAdditionalCosts) => {
      // TODO: Find a way to update the proposal data without calling the submit
      await handleSubmit(onSubmit)();
      // First step is to add the Equipment Piece to the Proposal Product
      // Place the resulting proposal data directly in the cache
      const result = await addProposalAdditionalCosts({
        variables: {
          additionalCosts: ids,
          proposalProductId: product.id,
        },
      });
      const success = isSuccess(result.data);
      setAdditionalCosts([]);
      // if (success) {
      //   writeProposal(result.data.addProposalAdditionalCost as Proposal);
      // }
    },
    [product]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell sx={{ width: "25%" }}>Cost</TableCell>
                <TableCell sx={{ width: "12.5%" }} align="center">
                  Rate Type
                </TableCell>
                <TableCell sx={{ width: "12.5%" }} align="center">
                  Percent/Fee
                </TableCell>
                <TableCell sx={{ width: "12.5%" }} align="center">
                  Quantity
                </TableCell>
                <TableCell sx={{ width: "12.5%" }} align="center">
                  Total Amount
                </TableCell>
                <TableCell sx={{ width: "12.5%" }} align="center">
                  Total Cost
                </TableCell>
                <TableCell sx={{ width: "12.5%" }} align="center">
                  Show on Proposal
                </TableCell>
                {!inViewMode && <TableCell sx={{ width: "12.5%" }}></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((additionalCost, i) => (
                <TableRow
                  key={additionalCost.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{additionalCostsById[additionalCost.id]?.name ?? ""}</TableCell>
                  <TableCell align="center">
                    {capitalCase(additionalCostsById[additionalCost.id]?.feeType ?? "")}
                  </TableCell>
                  <TableCell align="right">
                    <FormField
                      placeholder={product.additionalCosts[i]?.feeAmount} // don't divide by 100
                      disabled={
                        inViewMode ||
                        [AdditionalCostFeeType.JOB_LENGTH, AdditionalCostFeeType.FLAT].includes(
                          product.additionalCosts[i]?.feeType as AdditionalCostFeeType
                        )
                      }
                      align="right"
                      mb={0}
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": {
                            textAlign: "center",
                          },
                        },
                      }}
                      {...register(`additionalCosts.${i}.feeAmount`, { valueAsNumber: true })}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <FormField
                      disabled={inViewMode}
                      align="right"
                      mb={0}
                      placeholder={product.additionalCosts[i]?.quantity}
                      {...register(`additionalCosts.${i}.quantity`, { valueAsNumber: true })}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <CurrencyFormField
                      placeholder={(product.additionalCosts[i]?.cost / 100).toFixed(2)}
                      disabled={
                        inViewMode ||
                        product.additionalCosts[i]?.feeType === AdditionalCostFeeType.JOB_COST
                      }
                      align="right"
                      mb={0}
                      {...currencyEndAdornment}
                      sx={{
                        "& .MuiInputBase-root": {
                          "& input": {
                            textAlign: "center",
                          },
                        },
                      }}
                      {...register(`additionalCosts.${i}.cost`, { valueAsNumber: true })}
                      sendEmptyValuesAsNull
                      key={isSubmitSuccessful}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {formatCentsToUSD(+additionalCostsById[additionalCost.id]?.costTotal ?? 0)}
                  </TableCell>
                  <TableCell align="center">
                    <Controller
                      control={control}
                      name={`additionalCosts.${i}.showOnProposal`}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  </TableCell>

                  {!inViewMode && (
                    <TableCell>
                      <IconButton onClick={() => remove(i)}>
                        <Icon>delete</Icon>
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {!inViewMode && (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell colSpan={6}>
                    <AddAdditionalCost onAdd={onAdd} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default AdditionalCostsSubForm;
