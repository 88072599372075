import { Link } from "react-router-dom";
import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Index from "modules/OrganizationDataUploads/Index";
import { getRoute } from "utils/routing";
import DashboardPage from "layouts/DashboardPage/DashboardPage";

export default function DataUploadsPage() {
  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Card>
              <MDBox
                p={3}
                lineHeight={1}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <MDTypography variant="h4" fontWeight="medium">
                  Organization Data Imports
                </MDTypography>
                <MDTypography
                  as={Link}
                  to={getRoute("organization-settings.data-uploads.create-data-upload")}
                >
                  <MDButton color="blue">
                    <Icon>add</Icon>Import Data
                  </MDButton>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox mb={{ xs: 3, sm: 0 }} p={3}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              The section allows you to populate Bitumio with all your Organization information
              based on values in a spreadsheet instead of needing to manual input all the
              information manually.
              <br />
              This includes items like Companies and Contacts, Crews, Equipment, Plants, and
              Transfer Trucks.
            </MDTypography>
          </MDBox>

          <Index />
        </Grid>
      </Grid>
    </DashboardPage>
  );
}
