import MDAlert from "components/MDAlert";
import {
  CreateProductInput,
  OrganizationProductType,
  Product,
  ProductLabourCostType,
  ProductType,
  UpdateProductInput,
} from "generated/graphql";
import useUpdateProduct from "hooks/products/useUpdateProduct";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import getJobMeasurementMethodOptions from "utils/product-types/getJobMeasurementMethodOptions";
import { getMaterialCostTypeOptions } from "utils/product-types/getMaterialCostTypeOptions";
import { getRoute } from "utils/routing";
import OrganizationProductTypeProductUpdateForm from "./forms/OrganizationProductTypeProductUpdateForm";

export default function OrganizationProductTypeProductUpdate({
  productType,
  product,
}: {
  productType: OrganizationProductType;
  product: Product;
}) {
  const [isUnitPrice, setIsUnitPrice] = useState<boolean>(product.unitPriceProduct);
  const [isSubcontracted, setIsSubcontracted] = useState<boolean>(product.subcontracted);
  const [updateProduct, { loading, error, isSuccess, getData }] = useUpdateProduct({
    productTypeId: productType.id,
  });
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (input: UpdateProductInput) => {
      const result = await updateProduct({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      return { success, data };
    },
    [updateProduct]
  );

  const materialCostTypeOptions = useMemo(() => {
    return getMaterialCostTypeOptions(productType.type as ProductType); // Unexpected type issue because of codegen generating an extra enum field
  }, [productType]);

  const jobMeasurementMethodOptions = useMemo(() => {
    return getJobMeasurementMethodOptions(productType.type as ProductType);
  }, [productType]);

  const labourCostTypeOptions = useMemo(() => {
    return enumToValueOptions(ProductLabourCostType);
  }, []);

  return (
    <div>
      {error && <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>}
      <OrganizationProductTypeProductUpdateForm
        product={product}
        productType={productType}
        onSubmit={handleSubmit}
        loading={loading}
        isSubcontracted={isSubcontracted}
        setIsSubcontracted={setIsSubcontracted}
        isUnitPrice={isUnitPrice}
        setIsUnitPrice={setIsUnitPrice}
        materialCostTypeOptions={materialCostTypeOptions}
        jobMeasurementMethodOptions={jobMeasurementMethodOptions}
        labourCostTypeOptions={labourCostTypeOptions}
      />
    </div>
  );
}
