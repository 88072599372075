import {
  DeleteOrganizationAddressMutation,
  DeleteOrganizationAddressMutationVariables,
  GetOrganizationAddressesDocument,
  useDeleteOrganizationAddressMutation,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { useCallback } from "react";

export default function useDeleteOrganizationAddress() {
  const { offset } = usePaginatedVariables();
  const [mutation, { data, loading, error }] = useDeleteOrganizationAddressMutation({
    refetchQueries: [
      {
        query: GetOrganizationAddressesDocument,
        variables: {
          page: offset.page,
          first: offset.first,
        },
      },
    ],
  });

  const deleteOrganizationAddress = useCallback(
    (values: DeleteOrganizationAddressMutationVariables) => {
      try {
        return mutation({ variables: values });
      } catch (error) {
        return;
      }
    },
    []
  );

  const isSuccess = useCallback((data: DeleteOrganizationAddressMutation) => {
    return data && data.deleteOrganizationAddress === true;
  }, []);

  const success = isSuccess(data);
  const failure = data && data.deleteOrganizationAddress === false;

  return [
    deleteOrganizationAddress,
    { data, loading, error, success, failure, isSuccess },
  ] as const;
}
