import { MutationHookOptions } from "@apollo/client";
import getLocalString from "constants/Localization";
import { sortDirectionMap } from "constants/sortDirectionMap";
import {
  ArchiveCompanyMutation,
  ArchiveCompanyMutationVariables,
  GetCompaniesDocument,
  useArchiveCompanyMutation,
} from "generated/graphql";
import useMutationAction from "hooks/app/useMutationAction";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import { useCallback, useMemo } from "react";
import {
  companiesInitialSortDirectionValue,
  companiesInitialSortKeyValue,
  companiesSortKeyMap,
} from "./useCompaniesTable";

export default function useArchiveCompany() {
  const { offset, filtering, sorting } = usePaginatedVariables();
  const [mutation, { data: graphqlData, loading, error }] = useArchiveCompanyMutation({
    refetchQueries: [
      {
        query: GetCompaniesDocument,
        variables: {
          page: offset.page,
          first: offset.first,
          search: filtering.debouncedSearchTerm,
          sortKey: companiesSortKeyMap[sorting.sortKey] || companiesInitialSortKeyValue,
          sortDirection:
            sortDirectionMap[sorting.sortDirection] || companiesInitialSortDirectionValue,
        },
      },
    ],
  });
  const withMutationAction = useMutationAction();

  const isSuccess = (data: ArchiveCompanyMutation) => {
    return !!data?.archiveCompany;
  };

  const getData = ({ data }: { data?: ArchiveCompanyMutation }) => {
    return data?.archiveCompany ?? null;
  };

  const archiveCompany = useCallback(
    withMutationAction(
      async (
        props: MutationHookOptions<ArchiveCompanyMutation, ArchiveCompanyMutationVariables>
      ) => {
        return await mutation(props);
      },
      {
        isSuccess,
        successMessage: getLocalString("companies.archive-success"),
        failureMessage: getLocalString("companies.archive-failure"),
      }
    ),
    [mutation]
  );

  const data = useMemo(() => {
    return (graphqlData && graphqlData.archiveCompany) || null;
  }, [graphqlData]);

  const success = useMemo(() => {
    return isSuccess(graphqlData);
  }, [graphqlData]);

  return [archiveCompany, { data, loading, error, success, isSuccess }] as const;
}
