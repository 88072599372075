import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Proposal } from "generated/graphql";
import useCustomGetProposal from "hooks/proposals/useCustomGetProposal";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import UpdateProposal from "modules/proposals/UpdateProposal";
import ViewProposal from "modules/proposals/ViewProposal";
import { useParams } from "react-router";

export default function ProposalViewPage() {
  const { proposalId } = useParams();
  const { loading, data: proposal, error } = useCustomGetProposal({ id: proposalId });

  return (
    <DashboardPage>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              View Proposal
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        {!loading && !error && (
          <Grid item xs={12}>
            <ViewProposal proposal={proposal as Proposal} />
          </Grid>
        )}
      </Grid>
    </DashboardPage>
  );
}
