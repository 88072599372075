import AuthWall from "modules/AuthWall/AuthWall";
import OrganizationProductTypeCreatePage from "pages/organization/product-types/create";
import OrganizationProductTypeUpdatePage from "pages/organization/product-types/update";
import OrganizationProductTypePage from "pages/organization/product-types/view";
import OrganizationProductTypeProductCreatePage from "pages/organization/product-types/product/create";
import OrganizationProductTypeProductUpdatePage from "pages/organization/product-types/product/update";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";
import { UserPermissions } from "generated/graphql";

const productRoutes = [
  {
    name: "Create",
    key: "product-types.create",
    route: "/product-types/create",
    permission: UserPermissions.MANAGE_PRODUCT_SETTINGS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PRODUCT_SETTINGS}>
        <AuthWallSubscriber>
          <OrganizationProductTypeCreatePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Update",
    key: "product-types.update",
    type: "internal",
    route: "/product-types/:productTypeId/update",
    permission: UserPermissions.MANAGE_PRODUCT_SETTINGS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PRODUCT_SETTINGS}>
        <AuthWallSubscriber>
          <OrganizationProductTypeUpdatePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Product Type",
    key: "product-types.view",
    type: "internal",
    route: "/product-types/:productTypeId",
    permission: UserPermissions.MANAGE_PRODUCT_SETTINGS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PRODUCT_SETTINGS}>
        <AuthWallSubscriber>
          <OrganizationProductTypePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Product Type Products",
    key: "product-types.products.list",
    type: "internal",
    route: "/product-types/:productTypeId/products",
    permission: UserPermissions.MANAGE_PRODUCT_SETTINGS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PRODUCT_SETTINGS}>
        <AuthWallSubscriber>
          <OrganizationProductTypePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Create Product",
    key: "product-types.products.create",
    type: "internal",
    route: "/product-types/:productTypeId/products/create",
    permission: UserPermissions.MANAGE_PRODUCT_SETTINGS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PRODUCT_SETTINGS}>
        <AuthWallSubscriber>
          <OrganizationProductTypeProductCreatePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Update Product",
    key: "product-types.products.update",
    type: "internal",
    route: "/product-types/:productTypeId/products/:productId/update",
    permission: UserPermissions.MANAGE_PRODUCT_SETTINGS,
    component: (
      <AuthWall permission={UserPermissions.MANAGE_PRODUCT_SETTINGS}>
        <AuthWallSubscriber>
          <OrganizationProductTypeProductUpdatePage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
] as const;

export default productRoutes;
