import { zodResolver } from "@hookform/resolvers/zod";
import { FormHelperText, Grid, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import {
  proposalContentTypeName,
  proposalContentTypeTooltip,
} from "constants/proposalSettingsDisplayType";
import {
  Exact,
  OrganizationProposalContent,
  ProposalContentType,
  UpdateOrganizationProposalContentMutationVariables,
} from "generated/graphql";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";

const FormInputWYSIWYG: React.FC<{
  type: string;
  content: OrganizationProposalContent;
  loading: boolean;
  onSubmit: (
    values: Exact<{
      content: string;
      type: ProposalContentType;
    }>
  ) => void;
}> = ({ type, content, loading, onSubmit }) => {
  const methods = useForm<UpdateOrganizationProposalContentMutationVariables>({
    resolver: zodResolver(
      z.object({
        content: z
          .string({ invalid_type_error: "Field cannot be empty" })
          .min(1, "Field cannot be empty"),
      })
    ),
    defaultValues: { content: "" },
  });
  return (
    <Paper key={type} sx={{ p: "25px", mb: "20px" }}>
      <MDBox
        role="form"
        component="form"
        onSubmit={methods.handleSubmit(
          async ({ ...rest }: UpdateOrganizationProposalContentMutationVariables) => {
            await onSubmit({ ...rest, type: content.type as ProposalContentType });
            methods.reset();
          }
        )}
      >
        <Controller
          control={methods.control}
          name="content"
          render={({ field, fieldState: { error } }) => (
            <>
              <WYSIWYG
                type={content.type === "PROJECT_SUMMARY" ? "proposalStage" : "proposal"}
                content={content.content}
                onChange={(content) => field.onChange(content)}
                title={proposalContentTypeName[content.type]}
                tooltip={proposalContentTypeTooltip[content.type]}
              />
              {error && error?.message && (
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {error.message}
                </FormHelperText>
              )}
            </>
          )}
        />
        <Grid item xs={12} lg={6}>
          <MDBox display="flex" justifyContent="flex-end" mt={2}>
            <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
              Save
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Paper>
  );
};

export default FormInputWYSIWYG;
