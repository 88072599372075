import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import TimeBucketsList from "modules/TimeEntries/TimeBucketsList/TimeBucketsList";

export default function TimeBucketsPage() {
  return (
    <DashboardPage>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TimeBucketsList />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardPage>
  );
}
