import MDAlert from "components/MDAlert";
import DataTable from "components/Tables/DataTable/DataTable";
import TableSkeleton from "components/TableSkeleton/TableSkeleton";
import useJobsTable from "hooks/jobs/useJobsTable";
import { useState } from "react";

export default function ListJobs() {
  const [statuses, setStatuses] = useState([]);
  const { loading, error, tableData, pagination, filtering, sorting } = useJobsTable({ statuses });

  const changeStatus = (e: any) => {
    const status = e.target.name;
    const index = statuses.indexOf(status);

    if (index === -1) {
      setStatuses([...statuses, status]);
    } else {
      setStatuses(statuses.splice(index, 1));
    }
  };

  return (
    <>
      {(loading || error) && (
        <div style={{ padding: "16px" }}>
          {loading && <TableSkeleton numberOfLines={pagination.pageSize} />}
          {error && <MDAlert color="error">{error.message}</MDAlert>}
        </div>
      )}
      {!loading && !error && (
        <DataTable
          table={tableData}
          canSearch
          pagination={pagination}
          filtering={filtering}
          sorting={sorting}
          isSorted={true}
        />
      )}
    </>
  );
}
